import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Paper,
  Tabs,
  Tab,
  ClickAwayListener,
  IconButton,
  alpha,
} from '@material-ui/core'

import { Search } from '@material-ui/icons'
import SearchType from 'state/enums/search.enum'
import { colors } from 'theme/index.theme'

import useSearchBar from './use-search-bar.hook'
import FiltersPlayer from './filters-player.component'
import FiltersTransaction from './filters-transaction.component'

import TabPanel from './tab-panel.component'
import InputsPlayerComponent from './inputs-player.component'
import InputsTransactionComponent from './inputs-transaction.component'

function TabProps(index: number) {
  return {
    id: `search-tab-${index}`,
    'aria-controls': `search-tabpanel-${index}`,
  }
}

const SearchBar = () => {
  const { t } = useTranslation()

  const {
    playerSearchProps,
    transactionSearchProps,
    tab,
    stateUI,
    maxDate,
    minDate,
    handleChangePlayerSearch,
    setFiltersTransactions,
    handleChangeTab,
    handleChangeFilter,
    handleChangeExact,
    handleChangeDate,
    handleSearch,
    handleToggleFilters,
    handleTogglePicker,
    filtersTransactions,
    clearSearch,
    onKeyDown,
    inputRef,
  } = useSearchBar()

  useEffect(() => {
    return () => {
      clearSearch()
    }
  }, []) // eslint-disable-line

  return (
    <ClickAwayListener onClickAway={() => handleToggleFilters(false)}>
      <Box flexShrink={0} width={400} position="relative">
        <Box
          display="flex"
          border={2}
          px={1}
          borderRadius={4}
          borderColor={alpha(colors.white, 0.5)}
        >
          {tab === SearchType.PLAYER && (
            <InputsPlayerComponent
              playerSearchProps={playerSearchProps}
              stateUI={stateUI}
              maxDate={maxDate}
              minDate={minDate}
              handleChangePlayerSearch={handleChangePlayerSearch}
              handleChangeDate={handleChangeDate}
              handleToggleFilters={handleToggleFilters}
              handleTogglePicker={handleTogglePicker}
              onKeyDown={onKeyDown}
              inputRef={inputRef}
            />
          )}

          {tab === SearchType.TRANSACTION && (
            <InputsTransactionComponent
              transactionSearchProps={transactionSearchProps}
              setFiltersTransactions={setFiltersTransactions}
              handleToggleFilters={handleToggleFilters}
              filtersTransactions={filtersTransactions}
              onKeyDown={onKeyDown}
              inputRef={inputRef}
            />
          )}

          <IconButton aria-label="search" onClick={handleSearch}>
            <Search />
          </IconButton>
        </Box>

        <Box
          display={stateUI.openFilters ? 'block' : 'none'}
          position="absolute"
          width="100%"
          minWidth={500}
          mt="15px"
          padding={2}
          component={Paper}
          className="bg-blueNav"
        >
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            aria-label="search tabs"
          >
            <Tab
              label={t('player')}
              {...TabProps(1)}
              value={SearchType.PLAYER}
            />
            <Tab
              label={t('transactions')}
              {...TabProps(2)}
              value={SearchType.TRANSACTION}
            />
            {/* 
            // TODO paycard search will be implemented later
            <Tab
              label={t('paycard')}
              {...TabProps(3)}
              value={SearchType.PAYCARD}
            /> */}
          </Tabs>
          <TabPanel value={tab} index={SearchType.PLAYER}>
            <FiltersPlayer
              handleChangeFilter={handleChangeFilter}
              handleChangeExact={handleChangeExact}
              exact={playerSearchProps.exact}
              filter={playerSearchProps.filter}
            />
          </TabPanel>
          <TabPanel value={tab} index={SearchType.TRANSACTION}>
            <FiltersTransaction
              filter={transactionSearchProps.filter}
              handleChangeFilter={handleChangeFilter}
            />
          </TabPanel>
          {/* 
          // TODO paycard search will be implemented later
          <TabPanel value={tab} index={SearchType.PAYCARD}>
            <FiltersPaycard
              filter={playerSearchProps.filter}
              handleChangeFilter={handleChangeFilter}
            />
          </TabPanel> */}
        </Box>
      </Box>
    </ClickAwayListener>
  )
}

export default SearchBar
