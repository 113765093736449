import { GgrReportState, GgrReportActions } from '../models'
import ActionTypes from '../constants'

const ggrHandler = (
  state: GgrReportState,
  action: GgrReportActions,
): GgrReportState => {
  switch (action.type) {
    case ActionTypes.GET_GGR_REPORTS:
      return {
        ...state,
        isLoading: true,
        allReports: [],
        reports: [],
        paginationGGRTable: {
          total: 0,
          limit: 10,
          page: 1,
          pages: 0,
        },
        paginationConsolidationTable: {
          total: 0,
          limit: 10,
          page: 1,
          pages: 0,
        },
        paginationGGRTotalsTable: {
          total: 0,
          limit: 10,
          page: 1,
          pages: 0,
        },
      }

    case ActionTypes.GET_GGR_REPORTS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        allReports: action.payload.data || [],
        reports: action.payload.data || [],
        paginationGGRTable: {
          ...state.paginationGGRTable,
          page: 1,
          total: action.payload.data.length,
          pages: Math.ceil(
            action.payload.data.length / state.paginationGGRTable.limit,
          ),
        },
        paginationConsolidationTable: {
          ...state.paginationConsolidationTable,
          page: 1,
          total: action.payload.data.length,
          pages: Math.ceil(
            action.payload.data.length / state.paginationGGRTable.limit,
          ),
        },
        paginationGGRTotalsTable: {
          ...state.paginationGGRTotalsTable,
          page: 1,
          total: action.payload.data.length,
          pages: Math.ceil(
            action.payload.data.length / state.paginationGGRTable.limit,
          ),
        },
      }

    case ActionTypes.SET_GGR_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }

    case ActionTypes.SET_REPORTS_BY_PERIOD:
      return {
        ...state,
        reports: [...action.payload],
        paginationGGRTable: {
          ...state.paginationGGRTable,
          page: 1,
          total: action.payload.length,
          pages: Math.ceil(
            action.payload.length / state.paginationConsolidationTable.limit,
          ),
        },
        paginationConsolidationTable: {
          ...state.paginationConsolidationTable,
          page: 1,
          total: action.payload.length,
          pages: Math.ceil(
            action.payload.length / state.paginationConsolidationTable.limit,
          ),
        },
        paginationGGRTotalsTable: {
          ...state.paginationGGRTotalsTable,
          page: 1,
          total: action.payload.length,
          pages: Math.ceil(
            action.payload.length / state.paginationConsolidationTable.limit,
          ),
        },
      }

    case ActionTypes.SET_NEW_GGR_PAGE:
      return {
        ...state,
        paginationGGRTable: {
          ...state.paginationGGRTable,
          page: action.payload,
        },
      }

    case ActionTypes.SET_NEW_CONSOLIDATION_PAGE:
      return {
        ...state,
        paginationConsolidationTable: {
          ...state.paginationConsolidationTable,
          page: action.payload,
        },
      }

    case ActionTypes.SET_NEW_GGR_TOTALS_PAGE:
      return {
        ...state,
        paginationGGRTotalsTable: {
          ...state.paginationGGRTotalsTable,
          page: action.payload,
        },
      }

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case ActionTypes.SET_ROWS_AMOUNT_GGR: {
      return {
        ...state,
        paginationGGRTable: {
          ...state.paginationGGRTable,
          limit: action.payload,
          page: 1,
          pages: Math.ceil(state.reports.length / action.payload),
        },
      }
    }

    case ActionTypes.SET_ROWS_AMOUNT_NEW_CONSOLIDATION: {
      return {
        ...state,
        paginationConsolidationTable: {
          ...state.paginationConsolidationTable,
          limit: action.payload,
          page: 1,
          pages: Math.ceil(state.reports.length / action.payload),
        },
      }
    }
    case ActionTypes.SET_ROWS_AMOUNT_NEW_GGR_TOTALS: {
      return {
        ...state,
        paginationGGRTotalsTable: {
          ...state.paginationGGRTotalsTable,
          limit: action.payload,
          page: 1,
          pages: Math.ceil(state.reports.length / action.payload),
        },
      }
    }

    default:
      return state
  }
}

export default ggrHandler
