import { takeLatest, takeEvery } from 'redux-saga/effects'
import {
  getOneAnalyticsAction,
  getAllChannelActivityAction,
  getChannelActivityAction,
  getAllAnalyticsAction,
  getCampaignDateAction,
  getChannelEventsAction,
  getCampaignsByPlayerAction,
  getOneAnalyticsGroupAction,
  getEventsDetailAction,
  getTemplatePreviewAction,
} from '../actions/index.actions'
import getAllAnalyticsWorker from './get-all.saga'

import getOneAnalyticsWorker from './get-one.saga'
import getChannelActivityWorker from './get-channel-activity.saga'
import getChannelEventsWorker from './get-channel-events.saga'

import getEventsDetailWorker from './get-events-detail.saga'
import getOneCampaignDateWorker from './get-one-campaign-date.saga'
import getCampaignsByPlayerWorker from './get-campaigns-by-player.saga'

import getOneAnalyticsGroupWorker from './get-one-group.saga'
import getAllChannelActivityWorker from './get-all-channel-activity.saga'
import getPreviewTemplateWorker from './get-preview-template.saga'

/**
 * Main Saga for watching saga workers  in the Analytics state shape
 *
 * @generator
 */
function* analyticsSagasWatcher() {
  yield [
    yield takeLatest(getAllAnalyticsAction.request, getAllAnalyticsWorker),
    yield takeLatest(getOneAnalyticsAction.request, getOneAnalyticsWorker),
    yield takeLatest(
      getChannelActivityAction.request,
      getChannelActivityWorker,
    ),
    yield takeLatest(getChannelEventsAction.request, getChannelEventsWorker),
    yield takeEvery(getTemplatePreviewAction.request, getPreviewTemplateWorker),
    yield takeLatest(getEventsDetailAction.request, getEventsDetailWorker),
    yield takeLatest(getCampaignDateAction.request, getOneCampaignDateWorker),
    yield takeLatest(
      getCampaignsByPlayerAction.request,
      getCampaignsByPlayerWorker,
    ),
    yield takeLatest(
      getOneAnalyticsGroupAction.request,
      getOneAnalyticsGroupWorker,
    ),
    yield takeLatest(
      getAllChannelActivityAction.request,
      getAllChannelActivityWorker,
    ),
  ]
}

export default analyticsSagasWatcher
