import { select, call, put } from 'redux-saga/effects'
import i18n from 'i18n'
import Container from 'typedi'

import ApiService from 'services/api/index.service'
import {
  setSnackbarAction,
  setDialogAction,
  setLoadingFullAction,
} from 'state/ui/global/actions'
import { SAVE_COLUMNS_ID } from 'views/withdrawals/hooks/dynamic-columns/dialogs.constants'

import HTTPStatusCode from 'state/enums/http-status-code.enum'
import { createUserSettings } from '../actions/rest.actions'
import { CreateUserSettingsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga creates the user settings
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createUserSettings.request} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createUserSettingsWorker(
  action: ReturnType<typeof createUserSettings.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'users/settings/create',
      body: action.payload,
    }

    const { auth } = yield select()
    const response: CreateUserSettingsResponse = yield call(
      apiService.sendData,
      auth.token,
    )

    const hasError = [
      HTTPStatusCode.BAD_REQUEST,
      HTTPStatusCode.NOT_FOUND,
      HTTPStatusCode.INTERNAL_SERVER_ERROR,
    ].includes((response.status as unknown) as HTTPStatusCode)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: `${response.name} ${i18n.t('labels.settings')}`,
      what: i18n.t('messages.created'),
    })

    if (response._id) {
      yield put(createUserSettings.success(response))

      yield put(setDialogAction({ open: false, id: SAVE_COLUMNS_ID }))
    }

    if (hasError) {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(createUserSettings.failure(response.message))
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createUserSettings.failure(error))
  }
}

export default createUserSettingsWorker
