const analytics = {
  analytics: 'Analytics',
  allGroups: 'All Groups',
  filterByDate: 'Filter by Date',
  filterByTags: 'Filter by Tags',
  filterByTag: 'Filter by Tag',
  backToAnalytics: 'Back to Analytics',
  loadMoreWeeks: 'Load More Weeks',
  statsByWeek: 'Stats by Week',
  orderWeek: 'Order Week',
  week: 'Week',
  asc: 'Asc',
  desc: 'Desc',
  generalStats: 'General Stats',
  goToCampaignDetails: 'GO TO CAMPAIGN DETAILS',
  goToRuleDetails: 'GO TO RULE DETAILS',
  behavioralKPIs: 'Behavioral KPIs',
  additionalKPIs: 'Additional KPIs',
  additionalKPIsbyGroups: 'Additional KPIs by Groups',
  messageInformation: 'Message Information',
  playerDetails: 'Player Details',
  eventHistory: 'Event History',
  allCampaigns: 'All Campaigns',
  allRules: 'All Rules Engine',
  currentMonth: 'Current Month',
  currentWeek: 'Current Week',
  lastMonth: 'Last Month',
  dateRange: 'Date Range',
  noTargetGroups: 'No Target Groups',
  noDataWeek: 'There is no data',
  noEventsWeek: 'No events have been registered during this time.',
  freeRoundsAmount: 'Free Rounds Amount',
  analyticsLabels: {
    campaignName: 'Name',
    players: 'Players',
    login: 'Login',
    deposit: 'Deposit',
    repeats: 'Repeats',
    depositTotal: 'Deposit Total',
    ggr: 'GGR',
    bonusCount: 'Bonus Count',
    bonusTotal: 'Bonus Total',
    dcpu: 'DCPU',
    adpu: 'ADPU',
    startDate: 'Start Date',
    endDate: 'End Date',
    requests: 'Requests',
    clicks: 'Clicks',
    spent: 'Spent',
    sent: 'Sent',
    delivered: 'Delivered',
    opened: 'Opened',
    clicked: 'Clicked',
    hardBounce: 'Hard Bounce',
    softBounce: 'Soft Bounce',
    unsubscribe: 'Unsubscribed',
    smsError: 'SMS Error',
    bonus: 'Bonus',
    others: 'Others',
  },
  analyticsEvents: {
    requestCount: 'Sent',
    loginCount: 'Login',
    depositCount: 'Deposit',
    deliveredCount: 'Delivered',
    repeatsCount: 'Repeats',
    opensCount: 'Opened',
    clicksCount: 'Clicked',
    bonusCount: 'Bonus',
    hardBounceCount: 'Hard Bounce',
    softBounceCount: 'Soft Bounce',
    unsubscribeCount: 'Unsubscribed',
    smsErrorCount: 'SMS Error',
  },
  analyticsStatus: {
    processed: 'Processed',
    dropped: 'Dropped',
    delivered: 'Delivered',
    deferred: 'Deferred',
    bounce: 'Bounce',
    hardBounce: 'Hard Bounce',
    softBounce: 'Soft Bounce',
    opened: 'Opened',
    open: 'Open',
    click: 'Click',
    spamReport: 'Spam report',
    unsubscribe: 'Unsubscribe',
    notDelivered: 'Not Delivered',
    login: 'Login',
    deposit: 'Deposit',
    bonus: 'Bonus',
    emailError: 'Email Error',
    sent: 'Sent',
    queued: 'Queued',
    undelivered: 'Undelivered',
    unsent: 'Unsent',
    failed: 'Failed',
    smsError: 'Sms Error',
    frequencyCap: 'Frequency Cap',
  },
  analyticsChannels: {
    email: 'Email',
    sms: 'SMS',
    on_site_notification: 'On Site Notification', // eslint-disable-line
    push_notification: 'Push Notification', // eslint-disable-line
  },
  analyticsOthers: {
    bounce: 'Bounce',
    unsubscribe: 'Unsubscribed',
    frequencyCap: 'Frequency Cap',
    undelivered: 'Undelivered',
  },
  segmentHasPlayers: 'The segment has',
  segmentOnlyPlayerIds: 'Only available for records with playerId',
  ggrCalculationTooltip:
    'The GGR value cannot be real time due to performance. Its calculation is made at the end of each day.',
  immediateSend: 'Immediate Send',
}

export default analytics
