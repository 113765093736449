const LOG = {
  GET_TAG_LOGS: 'getHistorics' as 'getHistorics',
  GET_PLAYER_LOGS: 'get-player-timeline' as 'get-player-timeline',
  GET_TELEDATA_LOGS: 'risk/limits-new-teledata-history' as 'risk/limits-new-teledata-history',
  GET_AML_STATUS_LOGS: 'risk/aml-admin-status-history' as 'risk/aml-admin-status-history',
  GET_AML_ANSWER_LOGS: 'risk/aml-admin-answers-history' as 'risk/aml-admin-answers-history',
  GET_COOLOFF_LOGS: 'risk/cooloffs-admin-history-get' as 'risk/cooloffs-admin-history-get',
  GET_LIMIT_LOGS: 'risk/limits-get-history-report' as 'risk/limits-get-history-report',
  GET_LIMIT_CHANGES_REPORT: 'risk/limits-get-changes-report' as 'risk/limits-get-changes-report',
  GET_NOTE_LOGS: 'getHistorics' as 'getHistorics',
}

export default LOG
