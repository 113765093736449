/**
 *
 * CompanyCreate reducers
 *
 */

import ActionTypes from '../constants'
import { CompanyStateUI, CompanyUiActions } from '../models'

const companyUiFormHandler = (
  state: CompanyStateUI,
  action: CompanyUiActions,
): CompanyStateUI => {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL_FORM:
      return { ...state, isFormOpen: action.payload.isFormOpen }

    case ActionTypes.SET_MODAL_FORM_TITLE:
      return { ...state, formTitle: action.payload.modalFormTitle }

    default:
      return state
  }
}

export default companyUiFormHandler
