import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { WSErrorException } from 'state/root.models'
import { TransactionsActions } from 'state/wallet/actions/index.action'
import WS_ROUTES from 'state/websocket.routes'

import {
  TransactionsFilters,
  GetTransactionsResponse,
  GetOneTransactionResponse,
} from 'state/wallet/models/transactions.model'
import { setSnackbarAction } from 'state/ui/global/actions'

const useSearchTransactionActions = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const getAllTransactionsAction = useCallback(
    (newFilters: TransactionsFilters) =>
      dispatch(
        TransactionsActions.getAllTransactions(
          newFilters,
          WS_ROUTES.WALLET.GET_ALL_TRANSACTIONS,
          (response: GetTransactionsResponse) => {
            dispatch(TransactionsActions.getAllTransactionsSubscribe(response))
          },
        ),
      ),
    [dispatch],
  )

  const getAllDepositTransactionsAction = useCallback(
    (newFilters: TransactionsFilters) =>
      dispatch(
        TransactionsActions.getAllDepositTransactions(
          newFilters,
          WS_ROUTES.WALLET.GET_ALL_TRANSACTIONS,
          (response: GetTransactionsResponse) => {
            dispatch(
              TransactionsActions.getAllDepositTransactionsSubscribe(response),
            )
          },
        ),
      ),
    [dispatch],
  )

  const getOneTransactionAction = useCallback(
    (id: number) =>
      dispatch(
        TransactionsActions.getOneTransaction(
          { transactionId: id },
          WS_ROUTES.WALLET.GET_ONE_TRANSACTION,
          (response: GetOneTransactionResponse & WSErrorException) => {
            if (response.name) {
              dispatch(TransactionsActions.getOneTransactionSubscribe(response))
            } else {
              dispatch(
                TransactionsActions.getTransactionExceptionSubscribe(response),
              )
              dispatch(
                setSnackbarAction({
                  message: response.message || t('messages.errorOccurred'),
                  open: true,
                  variant: 'error',
                }),
              )
            }
          },
        ),
      ),
    [dispatch], // eslint-disable-line
  )

  const setFilters = (filtersNew: TransactionsFilters) => {
    dispatch(TransactionsActions.setTransactionFilter(filtersNew))
  }

  const setTransactionPlayerFiltersAction = (
    filtersNew: TransactionsFilters,
  ) => {
    dispatch(TransactionsActions.setTransactionPlayerFilters(filtersNew))
  }

  return {
    getAllTransactionsAction,
    getAllDepositTransactionsAction,
    getOneTransactionAction,
    setFilters,
    setTransactionPlayerFiltersAction,
  }
}

export default useSearchTransactionActions
