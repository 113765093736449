import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18next'

import { push } from 'connected-react-router'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import FormStatusType from 'state/enums/form-status.enum'
import {
  setDialogAction,
  setFormModeAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { createSegmentCsvAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the creation of a single segment
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createSegmentCsvAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createSegmentCsvWorker(
  action: ReturnType<typeof createSegmentCsvAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    const formData = new FormData()
    formData.append('name', action.payload.name)
    formData.append('description', action.payload.description)
    formData.append('filterType', 'and')
    formData.append('csvChar', ',')
    formData.append('file', action.payload.file)

    apiService.requestParams = {
      method: 'POST',
      endpoint: 'segmentations/create/csv',
      body: formData,
    }
    const { auth } = yield select()
    const response = yield call(apiService.sendDataMultipart, auth.token)

    yield put(setLoadingFullAction(false))

    if (response._id) {
      yield put(createSegmentCsvAction.success(response))
      yield put(setFormModeAction(FormStatusType.EDIT))
      yield put(push(`${routes.segments.EDIT}/${response._id}`))
      yield put(setDialogAction({ open: true, id: 'createSegmentSuccess' }))
    } else {
      const message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')
      yield put(
        createSegmentCsvAction.failure({
          name: response.status || '',
          message,
        }),
      )
      yield put(
        setSnackbarAction({
          message,
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createSegmentCsvAction.failure(error))
  }
}

export default createSegmentCsvWorker
