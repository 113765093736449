import GGRDate from 'state/enums/ggr-date.enum'
import GGRPeriod from 'state/enums/ggr-period.enum'
import { GgrReportState } from '../models'

const ggrReportInitialState: GgrReportState = {
  allReports: [],
  reports: [],
  isLoading: false,
  filters: {
    period: GGRPeriod.DAY,
    date: GGRDate.CURRENT_MONTH,
  },
  paginationGGRTable: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  paginationConsolidationTable: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  paginationGGRTotalsTable: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default ggrReportInitialState
