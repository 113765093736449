import { colors } from './colors.theme'

const { blueNav } = colors

export const popoverTheme = {
  root: {
    '&.small': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '350px',
      },
    },
    '&.xsmall': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '220px',
        padding: '0 12px 10px 12px',
      },
    },
  },
  paper: {
    backgroundColor: blueNav,
    '& .MuiPickersCalendar-week': {
      margin: '2px 0',
    },
    '&.MuiMenu-paper': {
      maxHeight: 224,
      '& .MuiMenu-list': {
        '& .MuiMenuItem-root': {
          '&:first-child': {
            borderRadius: '4px 4px 0 0',
          },
          '&:last-child': {
            borderRadius: '0 0 4px 4px',
          },
        },
      },
      '&.MuiMenu-paper-select': {
        '& .MuiMenuItem-root': {
          '& > .MuiSvgIcon-root': {
            opacity: 0,
            marginLeft: 32,
          },
          '&:hover': {
            '& > .MuiSvgIcon-root': {
              opacity: 1,
            },
          },
        },
      },
    },
  },
}
