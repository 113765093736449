import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const campaignGeneralHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }
    default:
      return state
  }
}

export default campaignGeneralHandler
