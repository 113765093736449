import ActionTypes from '../constants'
import {
  PersonalizationEngineState,
  PersonalizationEngineActions,
} from '../models/personalization-engine.model'

const getGameCategoriesByPlayerHandler = (
  state: PersonalizationEngineState,
  action: PersonalizationEngineActions,
): PersonalizationEngineState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER:
      return {
        ...state,
        isLoadingCategories: true,
      }

    case ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_SUBSCRIBER:
      return {
        ...state,
        isLoadingCategories: false,
        gameCategories: action.payload.gameCategories,
      }

    case ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoadingCategories: false,
      }

    default:
      return state
  }
}

export default getGameCategoriesByPlayerHandler
