import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white } = colors

const formHelperTheme = {
  root: {
    margin: '4px 0 0 !important',
    color: alpha(white, 0.6),
    '&.Mui-disabled': {
      color: alpha(white, 0.6),
    },
  },
}

export default formHelperTheme
