/**
 *
 * Wallet constants
 *
 */

enum ActionTypes {
  GET_WALLET = '@wallet/GET_WALLET',
  GET_WALLET_SUBSCRIBER = '@wallet/GET_WALLET_SUBSCRIBER',

  ADD_MANUAL_DEPOSIT = '@wallet/ADD_MANUAL_DEPOSIT',
  ADD_MANUAL_DEPOSIT_SUBSCRIBER = '@wallet/ADD_MANUAL_DEPOSIT_SUBSCRIBER',

  ADD_MANUAL_WITHDRAWAL = '@wallet/ADD_MANUAL_WITHDRAWAL',
  ADD_MANUAL_WITHDRAWAL_SUBSCRIBER = '@wallet/ADD_MANUAL_WITHDRAWAL_SUBSCRIBER',

  SET_TRANSACTION_FILTERS = '@wallet/SET_TRANSACTION_FILTERS',

  GET_ALL_TRANSACTIONS = '@wallet/GET_ALL_TRANSACTIONS',
  GET_ALL_TRANSACTIONS_SUBSCRIBER = '@wallet/GET_ALL_TRANSACTIONS_SUBSCRIBER',

  GET_ALL_DEPOSIT_TRANSACTIONS = '@wallet/GET_ALL_DEPOSIT_TRANSACTIONS',
  GET_ALL_DEPOSIT_TRANSACTIONS_SUBSCRIBER = '@wallet/GET_ALL_DEPOSIT_TRANSACTIONS_SUBSCRIBER',

  GET_ONE_TRANSACTION = '@wallet/GET_ONE_TRANSACTION',
  GET_ONE_TRANSACTION_SUBSCRIBER = '@wallet/GET_ONE_TRANSACTION_SUBSCRIBER',

  GET_TRANSACTION_EXCEPTION_SUBSCRIBER = '@wallet/GET_TRANSACTION_EXCEPTION_SUBSCRIBER',

  GET_WITHDRAWALS = '@wallet/GET_WITHDRAWALS',
  GET_WITHDRAWALS_SUBSCRIBER = '@wallet/GET_WITHDRAWALS_SUBSCRIBER',

  GET_WITHDRAWALS_EXCEPTION = '@wallet/GET_WITHDRAWALS_EXCEPTION',

  GET_WITHDRAWAL_PAYMENTS = '@wallet/GET_WITHDRAWAL_PAYMENTS',
  GET_WITHDRAWAL_PAYMENTS_SUBSCRIBER = '@wallet/GET_WITHDRAWAL_PAYMENTS_SUBSCRIBER',

  GET_SETTINGS = '@wallet/GET_SETTINGS',
  GET_SETTINGS_SUBSCRIBER = '@wallet/GET_SETTINGS_SUBSCRIBER',

  CREATE_PAYMENT_FILE = '@wallet/CREATE_PAYMENT_FILE',
  CREATE_PAYMENT_FILE_SUBSCRIBER = '@wallet/CREATE_PAYMENT_FILE_SUBSCRIBER',

  UPDATE_PAYMENT_FILE = '@wallet/UPDATE_PAYMENT_FILE',
  UPDATE_PAYMENT_FILE_SUBSCRIBER = '@wallet/UPDATE_PAYMENT_FILE_SUBSCRIBER',

  COMPLETE_WITHDRAWALS_IN_PAYMENT = '@wallet/COMPLETE_WITHDRAWALS_IN_PAYMENT',
  COMPLETE_WITHDRAWALS_IN_PAYMENT_SUBSCRIBER = '@wallet/COMPLETE_WITHDRAWALS_IN_PAYMENT_SUBSCRIBER',

  FILTER_PAYMENTS = '@wallet/FILTER_PAYMENTS',

  FILTER_WITHDRAWALS = '@wallet/FILTER_WITHDRAWALS',

  WALLET_RESET = '@wallet/WALLET_RESET',

  UPDATE_WITHDRAWALS_STATUS = '@wallet/UPDATE_WITHDRAWALS_STATUS',
  UPDATE_WITHDRAWALS_STATUS_SUBSCRIBER = '@wallet/UPDATE_WITHDRAWALS_STATUS_SUBSCRIBER',

  SELECT_WITHDRAWALS = '@wallet/SELECT_WITHDRAWALS',

  SET_TRANSACTION_PLAYER_FILTERS = '@wallet/SET_TRANSACTION_PLAYER_FILTERS',

  SET_DOWNLOAD_TRANSACTION_PLAYER = '@wallet/SET_DOWNLOAD_TRANSACTION_PLAYER',

  SET_IS_DOWNLOADING = '@wallet/SET_IS_DOWNLOADING',

  GET_ALL_GAMES_PLAYER = '@wallet/GET_ALL_GAMES_PLAYER',
  GET_ALL_GAMES_PLAYER_SUBSCRIBER = '@wallet/GET_ALL_GAMES_PLAYER_SUBSCRIBER',
  GET_ALL_GAMES_PLAYER_EXCEPTION_SUBSCRIBER = '@wallet/GET_ALL_GAMES_PLAYER_EXCEPTION_SUBSCRIBER',

  GET_ALL_GAMES_CATEGORIES_PLAYER = '@wallet/GET_ALL_GAMES_CATEGORIES_PLAYER',
  GET_ALL_GAMES_CATEGORIES_PLAYER_SUBSCRIBER = '@wallet/GET_ALL_GAMES_CATEGORIES_PLAYER_SUBSCRIBER',
  GET_ALL_GAMES_CATEGORIES_PLAYER_EXCEPTION_SUBSCRIBER = '@wallet/GET_ALL_GAMES_CATEGORIES_PLAYER_EXCEPTION_SUBSCRIBER',

  GET_ALL_GAMES_PROVIDERS_PLAYER = '@wallet/GET_ALL_GAMES_PROVIDERS_PLAYER',
  GET_ALL_GAMES_PROVIDERS_PLAYER_SUBSCRIBER = '@wallet/GET_ALL_GAMES_PROVIDERS_PLAYER_SUBSCRIBER',
  GET_ALL_GAMES_PROVIDERS_PLAYER_EXCEPTION_SUBSCRIBER = '@wallet/GET_ALL_GAMES_PROVIDERS_PLAYER_EXCEPTION_SUBSCRIBER',

  SET_PERSONALIZATION_ENGINE_FILTERS = '@wallet/SET_PERSONALIZATION_ENGINE_FILTERS',

  COMPLETE_LATE_BONUS_WIN = '@wallet/COMPLETE_LATE_BONUS_WIN',

  GET_ALL_WITHDRAWALS = '@wallet/GET_ALL_WITHDRAWALS',
  GET_ALL_WITHDRAWALS_SUBSCRIBER = '@wallet/GET_ALL_WITHDRAWALS_SUBSCRIBER',
}

export default ActionTypes
