import { createAsyncAction, createAction } from 'typesafe-actions'
import SearchType from 'state/enums/search.enum'
import ActionTypes from '../constants'

import { PlayerSearchProps, TransactionSearchProps } from '../models'

export const getAllPaycardAction = createAsyncAction(
  ActionTypes.GET_ALL_PAYCARD_REQUEST,
  ActionTypes.GET_ALL_PAYCARD_SUCCESS,
  ActionTypes.GET_ALL_PAYCARD_FAILURE,
)<any, any, Error>()

export const setPlayerSearchPropsAction = createAction(
  ActionTypes.SET_PLAYER_SEARCH_PROPS,
  action => {
    return (searchPlayer: PlayerSearchProps) => action(searchPlayer)
  },
)

export const setTransactionSearchPropsAction = createAction(
  ActionTypes.SET_TRANSACTION_SEARCH_PROPS,
  action => {
    return (searchTransaction: TransactionSearchProps) =>
      action(searchTransaction)
  },
)

export const setSearchTabAction = createAction(
  ActionTypes.SET_SEARCH_TAB,
  action => {
    return (tab: SearchType) => action(tab)
  },
)
