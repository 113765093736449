/**
 *
 * Blacklist constants
 *
 */

enum ActionTypes {
  SET_FILTERS = '@blacklist/SET_FILTERS',
  SET_PAGINATION = '@blacklist/SET_PAGINATION',

  GET_BLACKLIST_REPORT = '@blacklist/GET_BLACKLIST_REPORT',
  GET_BLACKLIST_REPORT_SUBSCRIBER = '@blacklist/GET_BLACKLIST_REPORT_SUBSCRIBER',

  GET_BLACKLIST_AUDIT_REPORT = '@blacklist/GET_BLACKLIST_AUDIT_REPORT',
  GET_BLACKLIST_AUDIT_REPORT_SUBSCRIBER = '@blacklist/GET_BLACKLIST_AUDIT_REPORT_SUBSCRIBER',

  DELETE_BLACKLIST_RECORD = '@blacklist/DELETE_BLACKLIST_RECORD',
  DELETE_BLACKLIST_RECORD_SUBSCRIBER = '@blacklist/DELETE_BLACKLIST_RECORD_SUBSCRIBER',
}

export default ActionTypes
