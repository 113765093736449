import { max, isEqual } from 'date-fns'
import { ApplicationState } from 'state/root.models'
import RiskLevelAlerts from 'state/enums/risk-level-alerts.enum'

import { PlayerCardState } from './models'

/**
 *
 * * PlayerCardPersonalInfo selectors
 *
 *
 */
export const root = ({ playerCard }: ApplicationState) => playerCard

export const lastAccountStatus = ({ playerCard }: ApplicationState) => {
  return playerCard?.accountStatusLogs[0]
}

export const playerInfo = ({ player }: PlayerCardState) => player

export const currentAccountStatus = ({ playerCard }: ApplicationState) => {
  const dates = playerCard.accountStatusLogs?.map(log => {
    return new Date(log.createdAt)
  })

  // get recent date
  const mostRecentDate = max(dates)

  // find the log that matches with the recent date
  const lastLog = playerCard.accountStatusLogs?.find(log =>
    isEqual(mostRecentDate, new Date(log?.createdAt)),
  )

  const last = lastLog?.accountStatus

  if (last === playerCard?.player?.accountStatus) {
    return lastLog
  }

  return {
    accountStatus: playerCard?.player?.accountStatus,
    playerId: playerCard?.player?.playerId,
    reason: '',
    subReason: '',
    issueRisklevel: RiskLevelAlerts[RiskLevelAlerts.UNDEFINED],
    comments: '',
  }
}
