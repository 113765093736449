import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { getTemplatePreviewAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a preview template for email and sms channels
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getTemplatePreviewAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getPreviewTemplateWorker(
  action: ReturnType<typeof getTemplatePreviewAction.request>,
) {
  try {
    const { payload } = action

    const { auth } = yield select()

    apiService.requestParams = {
      method: 'GET',
      endpoint: 'analytics/get/template-preview',
      queries: jsonToQueryString({
        ...payload,
        messageId: payload.messageId || 'n-a',
      }),
    }

    const response = yield call(apiService.getData, auth.token)

    yield put(
      getTemplatePreviewAction.success({
        messageId: payload.messageId || 'n-a',
        playerId: payload.playerId,
        campaignId: payload.campaignId || '',
        ruleId: payload.ruleId || '',
        ...response,
      }),
    )
  } catch (error) {
    yield put(getTemplatePreviewAction.failure(error))
  }
}

export default getPreviewTemplateWorker
