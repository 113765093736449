import { TemplatePushState } from '../models'

const initialState: TemplatePushState = {
  error: '',
  isLoading: false,
  templates: [],
  pagination: {
    total: 0,
    pages: 1,
    page: 1,
    limit: 25,
  },
  currentTemplate: {
    createdAt: '',
    createdBy: '',
    description: '',
    isDeleted: false,
    name: '',
    languageTemplates: [],
    updatedAt: '',
    _id: '',
  },
  filters: {
    page: 1,
    limit: 25,
    isArchive: false,
  },
}

export default initialState
