import * as LimitModels from '../models'
import ActionTypes from '../constants'

const cancelPendingLimitHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.CANCEL_PENDING_LIMIT_SUBSCRIBER:
      return {
        ...state,
        pendingLimits: state.pendingLimits.filter(
          // eslint-disable-next-line no-underscore-dangle
          limit => limit.id !== action.payload,
        ),
      }

    default:
      return state
  }
}

export default cancelPendingLimitHandler
