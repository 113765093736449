const commonLabels = {
  labels: {
    abbreviation: 'Abbreviation',
    aborted: 'Aborted',
    account: 'Account',
    accountStatus: 'Account Status',
    accountStore: 'Account Store',
    action: 'Action',
    actions: 'Actions',
    active: 'Active',
    add: 'Add',
    addCard: 'Add Card',
    addEmails: 'Add Emails',
    addFilter: 'Add Filter',
    addFlow: 'Add Flow',
    addPhones: 'Add Phones',
    addPlaceholder: 'Add Placeholder',
    address: 'Address',
    addressCity: 'Address City',
    addressCountry: 'Address Country',
    addressNumber: 'Address Number',
    addressStreet: 'Address Street',
    admin: 'Admin',
    adminTags: 'Admin tags',
    affiliateToken: 'Affiliate Token',
    age: 'Age',
    agent: 'Agent',
    alert: 'Alert',
    alertCode: 'Alert Code',
    alertDetails: 'Alert Details',
    alertId: 'Alert Id',
    alerts: 'Alerts',
    all: 'All',
    allCategories: 'All Categories',
    aml: 'AML',
    amount: 'Amount',
    and: 'And',
    android: 'Android',
    any: 'Any',
    apiKeyProvider: 'API Key Provider',
    apply: 'Apply',
    applyAndSave: 'Apply and Save',
    approve: 'Approve',
    approved: 'Approved',
    archive: 'Archive',
    assign: 'Assign',
    at: 'At',
    back: 'Back',
    backTo: 'Back to {{name}}',
    balanceAfter: 'Balance After',
    balanceBefore: 'Balance Before',
    balancesPayments: 'Balances & Payments',
    beneficiary: 'Beneficiary',
    beneficiaryName: 'Beneficiary Name',
    bic: 'BIC',
    birthdate: 'Birth Date',
    birthday: 'Birthday',
    body: 'Body',
    bonus: 'Bonus',
    bonusExcluded: 'Excluded from Bonuses',
    bonusMoney: 'Bonus Money',
    button2: 'Button #2',
    by: 'By',
    campaign: 'Campaign',
    campaigns: 'Campaigns',
    cancel: 'Cancel',
    cannotDelete: 'Cannot Delete',
    cannotEdit: 'Cannot Edit',
    cannotRemove: 'Cannot Remove',
    cardNumber: 'Card Number',
    category: 'Category',
    certificateId: 'Certificate ID',
    certificateName: 'Certificate Name',
    change: 'Change',
    channel: 'Channel',
    check: 'Check',
    checkAgain: 'Check Again',
    choices: 'Choices',
    chosen: 'Chosen',
    city: 'City',
    clear: 'Clear',
    clearSelection: 'Clear Selection',
    close: 'Close',
    color: 'Color',
    comment: 'Comment',
    comments: 'Comments',
    company: 'Company',
    configuration: 'Configuration',
    compliance: 'Compliance',
    confirm: 'Confirm',
    confirmPassword: 'Confirm Password',
    connectionIps: 'Connections IPs:',
    copyLink: 'Copy Link',
    country: 'Country',
    create: 'Create',
    created: 'Created',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    currentMaxLimit: 'Current Max Limit',
    currentStatus: 'Current Status',
    currentSettings: 'Current Settings',
    daily: 'Daily',
    data: 'Data',
    date: 'Date',
    dateFrom: 'Date From',
    dateRange: 'Date Range',
    dateTo: 'Date To',
    dayOfTheMonth: 'Day of the Month',
    default: 'Default',
    delete: 'Delete',
    deleted: 'Deleted',
    deposit: 'Deposit',
    depositFunds: 'Source of funds used for deposit?',
    description: 'Description',
    desktop: 'Desktop',
    detail: 'Detail',
    details: 'Details',
    device: 'Device',
    dialCode: 'Dial Code',
    domain: 'Domain',
    download: 'Download',
    downloadAsXLS: 'Download as XLS',
    downloadCSV: 'Download CSV',
    duplicate: 'Duplicate',
    edit: 'Edit',
    email: 'Email',
    emails: 'Email',
    endDate: 'End Date',
    endDateUndefined: 'End Date Undefined',
    ended: 'Ended',
    endPeriod: 'End Period',
    event: 'Event',
    execute: 'Execute',
    false: 'False',
    field: 'Field',
    file: 'File',
    fileName: 'File Name',
    filter: 'Filter',
    filterBy: 'Filter By',
    filterByCategory: 'Filter By Category',
    filterByField: 'Filter by {{field}}',
    filterByLanguage: 'Filter By Language',
    filterByPaymentStatus: 'Filter By Payment Status',
    filterByStatus: 'Filter By Status',
    filterByWhat: 'Filter by {{what}}',
    filters: 'Filters',
    findRole: 'Find Role',
    firstName: 'First Name',
    flow: 'Flow',
    flows: 'Flows',
    folder: 'Folder',
    folders: 'Folders',
    forfeit: 'Forfeit',
    from: 'From',
    fromEmail: 'From Email',
    fromName: 'From Name',
    fullName: 'Full Name',
    funMode: 'Fun Mode',
    game: 'Game',
    gameCategories: 'Game Categories',
    gameCategory: 'Game Category',
    gameId: 'Game ID',
    gameProvider: 'Game Provider',
    games: 'Games',
    gender: 'Gender',
    goToPage: 'Go to page',
    history: 'History',
    iban: 'IBAN',
    id: 'ID',
    if: 'If',
    ignore: 'Ignore',
    image: 'Image',
    inactive: 'Inactive',
    indefinite: 'Indefinite',
    inDesign: 'In Design',
    insert: 'Insert',
    isActive: 'Is Active',
    isTested: 'Is Tested',
    kyc: 'KYC',
    label: 'Label',
    language: 'Language',
    languages: 'Languages',
    lastName: 'Last Name',
    link: 'Link',
    load: 'Load',
    loadAll: 'Load all',
    loadCount: 'Load Count',
    loading: 'Loading',
    location: 'Location',
    log: 'Log',
    logHistory: 'Log History',
    logId: 'Log Id',
    longURL: 'Long URL',
    loyalty: 'Loyalty',
    loyaltyCard: 'Loyalty Card',
    mac: 'Mac',
    manage: 'Manage',
    manageColumns: 'Manage Columns',
    maxBet: 'Max Bet',
    message: 'Message',
    minBet: 'Min Bet',
    mobile: 'Mobile',
    more: 'More',
    moreDetails: 'More Details',
    moreOptions: 'More options',
    name: 'Name',
    nationality: 'Nationality',
    new: 'New',
    newMaxLimit: 'New Max Limit',
    next: 'Next',
    no: 'No',
    note: 'Note',
    noteContent: 'Note Content',
    notepad: 'Notepad',
    notFound: 'Not Found',
    number: 'Number',
    occupation: 'Occupation',
    of: 'Of',
    old: 'Old',
    optional: 'Optional',
    options: 'Options',
    or: 'Or',
    other: 'Other',
    password: 'Password',
    paused: 'Paused',
    payment: 'Payment',
    perDay: 'Per Day',
    performedBy: 'Performed By',
    period: 'Period',
    personalInformation: 'Personal Information',
    phoneNumber: 'Mobile Number',
    phones: 'Phones',
    playerCount: 'Player Count',
    playerId: 'Player ID',
    playerIds: 'Player IDs',
    playerList: 'Player List',
    playerLogins: 'Player logins',
    players: 'Players',
    playerTags: 'Player tags',
    postalCode: 'Postal Code',
    preview: 'Preview',
    priority: 'Priority',
    product: 'Product',
    products: 'Products',
    provider: 'Provider',
    providerLogo: 'Provider Logo',
    providerName: 'Provider Name',
    realMoney: 'Real Money',
    reason: 'Reason',
    recipient: 'Recipient',
    recover: 'Recover',
    reject: 'Reject',
    remove: 'Remove',
    removeColumn: 'Remove Column',
    removeLanguage: 'Remove Language',
    rename: 'Rename',
    replyTo: 'Reply to',
    resolution: 'Resolution',
    resolve: 'Resolve',
    resolved: 'Resolved',
    riskLevel: 'Risk Level',
    roles: 'Roles',
    rowsPerPage: 'Rows per page',
    rule: 'Rule',
    rules: 'Rules',
    salary: 'Salary',
    save: 'Save',
    scheduled: 'Scheduled',
    search: 'Search',
    searchById: 'Search by ID',
    searchByName: 'Search By Name',
    searchByPlayerId: 'Search by Player ID',
    searchByTitle: 'Search By Title',
    searchByWhat: 'Search by {{what}}',
    searchForColumn: 'Search for column',
    searchRules: 'Search Rules',
    security: 'Security',
    segment: 'Segment',
    segmentation: 'Segmentation',
    select: 'Select',
    selectAColumn: 'Select a Column',
    selectAgent: 'Select Agent',
    selectAll: 'Select All',
    selectBonusTrigger: 'Select Bonus Trigger',
    selectCategory: 'Select Category',
    selectCompany: 'Select a company',
    selected: 'Selected',
    selectEmail: 'Select an email',
    selectItem: 'Select {{item}}',
    selectLimitType: 'Select Limit Type',
    selectPriority: 'Select Priority',
    selectReason: 'Select Reason',
    selectRiskLevel: 'Select Risk Level',
    selectRole: 'Select a role',
    selectRoles: 'Select Roles',
    selectStatus: 'Select Status',
    selectSubreason: 'Select Subreason',
    selectTemplate: 'Select Template',
    selectTime: 'Select Time',
    selectType: 'Select Type',
    selectUnit: 'Select Unit',
    send: 'Send',
    sendCode: 'Send Code',
    sendEmail: 'Send Email',
    sendTo: 'Send To',
    setting: 'Setting',
    settings: 'Settings',
    showColumns: 'Show Columns',
    showElements: 'Show Elements',
    size: 'Size',
    startDate: 'Start Date',
    startPeriod: 'Start Period',
    status: 'Status',
    statusCampaign: 'Campaign Status',
    stop: 'Stop {{elem}}',
    stopCount: 'Stop Count',
    streetNumber: 'Street Number',
    style: 'Style',
    subject: 'Subject',
    subproduct: 'SubProduct',
    subProducts: 'SubProducts',
    subreason: 'Subreason',
    subtypes: 'Subtypes',
    summary: 'Summary',
    supportedDevices: 'Supported Devices',
    tag: 'Tag',
    tags: 'Tags',
    template: 'Template',
    test: 'Test',
    testAccount: 'Test Account',
    tested: 'Tested',
    text: 'Text',
    then: 'then',
    time: 'Time',
    timestamp: 'Timestamp',
    timezone: 'Time Zone',
    title: 'Title',
    to: 'To',
    total: 'Total',
    totalWager: 'Total Wager',
    totalWin: 'Total Win',
    transactionId: 'Transaction ID',
    trigger: 'Trigger',
    triggers: 'Triggers',
    true: 'True',
    turnover: 'Turnover',
    type: 'Type',
    types: 'Types',
    unlink: 'Unlink',
    unknown: 'Unknown',
    update: 'Update',
    updated: 'Updated',
    uploadedBy: 'Uploaded by',
    url: 'URL',
    user: 'User',
    username: 'Username',
    users: 'Users',
    value: 'Value',
    valueMax: 'Value Max',
    valueMin: 'Value Min',
    version: 'Version',
    versionStartDate: 'Version Start Date',
    view: 'View',
    viewWhat: 'View {{what}}',
    visits: 'Visits',
    weekday: 'Weekday',
    when: 'When',
    whichVersion: '{{which}} version',
    windows: 'Windows',
    withdrawal: 'Withdrawal',
    with: 'With',
    yearsOld: 'years old',
    yes: 'Yes',
    zipCode: 'Zip Code',
  },
}

export default commonLabels
