import { Reducer } from 'redux'
import ActionTypes from './constants'
import { WebsocketsState, WebsocketsActions } from './models'

export const initialState: WebsocketsState = {
  wsocket: null,
  isConnecting: false,
  isDisconnecting: false,
  events: [''],
  gateways: [
    { isIO: false, namespace: '', isConnected: false, error: '', port: '' },
  ],
}

const websocketsReducer: Reducer = (
  state: WebsocketsState = initialState,
  action: WebsocketsActions,
): WebsocketsState => {
  switch (action.type) {
    case ActionTypes.WS_REGISTER_GATEWAY:
      return {
        ...state,
        gateways: [
          ...state.gateways,
          { ...action.payload, ...{ error: '', isConnected: false } },
        ],
      }

    case ActionTypes.WS_CONNECT:
      return {
        ...state,
        isConnecting: true,
      }

    case ActionTypes.WS_GATEWAY_CONNECTED:
      return {
        ...state,
        gateways: state.gateways.map(gateway => {
          if (gateway.namespace === action.payload.namespace) {
            return {
              ...gateway,
              isConnected: action.payload.isConnected,
              error: action.payload.error,
            }
          }
          return gateway
        }),
      }

    case ActionTypes.WS_GATEWAY_DISCONNECTED:
      return {
        ...state,
        gateways: state.gateways.map(gateway => {
          if (gateway.namespace === action.payload.namespace) {
            return {
              ...gateway,
              isConnected: false,
              error: action.payload.error,
            }
          }
          return gateway
        }),
      }

    case ActionTypes.WS_ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      }

    case ActionTypes.WS_DISCONNECT:
      return {
        ...state,
        isDisconnecting: true,
      }

    case ActionTypes.WS_SET_SOCKET:
      return {
        ...state,
        wsocket: action.payload,
      }

    case ActionTypes.WS_DISCONNECTED:
      return {
        ...state,
        isConnecting: false,
        isDisconnecting: false,
      }
    default:
      return state
  }
}

export default websocketsReducer
