import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const getPaymentsHandler = (
  state: WalletModels.State,
  action: WalletModels.SocketActions | WalletModels.Actions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.GET_WITHDRAWAL_PAYMENTS:
      return {
        ...state,
        isLoading: !state.isDownloading,
      }

    case ActionTypes.GET_WITHDRAWAL_PAYMENTS_SUBSCRIBER: {
      const newState = state.isDownloading
        ? {}
        : {
            paymentFiles: action.payload.message,
            resolved: action.payload.resolved,
            pagination: {
              total: action.payload.totalItems || 0,
              limit: action.payload.limit || 25,
              page: action.payload.page || 1,
              pages: action.payload.totalPages || 0,
            },
          }
      return {
        ...state,
        ...newState,
        isLoading: false,
      }
    }

    case ActionTypes.FILTER_PAYMENTS:
      return {
        ...state,
        filtersPayments: action.payload,
      }

    default:
      return state
  }
}

export default getPaymentsHandler
