/**
 *
 * Custom Triggers constants
 *
 */

enum ActionTypes {
  SET_CUSTOM_TRIGGER_FILTERS = '@custom-trigger/SET_CUSTOM_TRIGGER_FILTERS',

  RESET_CURRENT_CUSTOM_TRIGGER = '@custom-trigger/RESET_CURRENT_CUSTOM_TRIGGER',
  RESET_CUSTOM_TRIGGERS = '@custom-trigger/RESET_CUSTOM_TRIGGERS',

  GET_ALL_CUSTOM_TRIGGER_REQUEST = '@custom-trigger/GET_ALL_CUSTOM_TRIGGER_REQUEST',
  GET_ALL_CUSTOM_TRIGGER_SUCCESS = '@custom-trigger/GET_ALL_CUSTOM_TRIGGER_SUCCESS',
  GET_ALL_CUSTOM_TRIGGER_FAILURE = '@custom-trigger/GET_ALL_CUSTOM_TRIGGER_FAILURE',

  GET_ONE_CUSTOM_TRIGGER_REQUEST = '@custom-trigger/GET_ONE_CUSTOM_TRIGGER_REQUEST',
  GET_ONE_CUSTOM_TRIGGER_SUCCESS = '@custom-trigger/GET_ONE_CUSTOM_TRIGGER_SUCCESS',
  GET_ONE_CUSTOM_TRIGGER_FAILURE = '@custom-trigger/GET_ONE_CUSTOM_TRIGGER_FAILURE',

  CREATE_REQUEST = '@custom-trigger/CREATE_REQUEST',
  CREATE_SUCCESS = '@custom-trigger/CREATE_SUCCESS',
  CREATE_FAILURE = '@custom-trigger/CREATE_FAILURE',

  UPDATE_REQUEST = '@custom-trigger/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@custom-trigger/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@custom-trigger/UPDATE_FAILURE',

  ARCHIVE_REQUEST = '@custom-trigger/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@custom-trigger/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@custom-trigger/ARCHIVE_FAILURE',

  DUPLICATE_REQUEST = '@custom-trigger/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@custom-trigger/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@custom-trigger/DUPLICATE_FAILURE',
}

export default ActionTypes
