import ActionTypes from '../constants'
import { SegmentActions, SegmentState } from '../models'

const segmentGeneralHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }

    case ActionTypes.SET_PLAYER_COUNT:
      return {
        ...state,
        players: action.payload || [],
        playersCount: action.payload?.length || 0,
      }

    default:
      return state
  }
}

export default segmentGeneralHandler
