import ActionTypes from '../constants'
import {
  PersonalizationEngineState,
  PersonalizationEngineActions,
} from '../models/personalization-engine.model'

const getGameProvidersByPlayerHandler = (
  state: PersonalizationEngineState,
  action: PersonalizationEngineActions,
): PersonalizationEngineState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER:
      return {
        ...state,
        isLoadingProviders: true,
      }

    case ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_SUBSCRIBER:
      return {
        ...state,
        isLoadingProviders: false,
        gameProviders: action.payload.gameProviders,
      }

    case ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoadingProviders: false,
      }

    default:
      return state
  }
}

export default getGameProvidersByPlayerHandler
