import Container from 'typedi'
import { EventEmitter } from 'events'
import i18n from 'i18n'

import store from 'state'
import { v4 as uuidv4 } from 'uuid'
import { SocketActions } from 'state/middleware/sockets'

import QueueMessages from './queue-messages.service'
import WSStatusMessages from './enums/ws-status-messages.enum'
import WSStatusCode from './enums/ws-status-closed-code.enum'

import PartialGatewayOptions from './gateway-options.model'
import WebSocketStatus from './enums/ws-connection-status.enum'

const queueMessages: QueueMessages = Container.get(QueueMessages)

class WebSocketService extends EventEmitter {
  host?: string

  port?: string

  namespace?: string

  token?: string

  socket: WebSocket | null

  private url: string

  private reconnectInterval: any = 0

  private timeout = 250

  private retryTimes = 0

  private requestTimeout: any

  private retryMessageTimeout: number

  constructor({ host, port, namespace, token }: PartialGatewayOptions) {
    super()

    this.host = host
    this.port = port
    this.namespace = namespace
    this.token = token

    this.connect()
  }

  buildUrl() {
    if (this.host) {
      if (this.token) {
        this.url = `${this.host}:${this.port}/?token=${this.token}`
      } else {
        this.url =
          this.host && this.port
            ? `${this.host}:${this.port}/`
            : (process.env.REACT_APP_ADMIN_LEGACY_API as string)
      }
    }
  }

  connect = () => {
    this.buildUrl()
    this.socket = new WebSocket(this.url)
    this.attachListeners()
  }

  getWebSocket() {
    return this.socket
  }

  /**
   * @description
   *
   * @param {string} path
   * @param {object} input
   * @param {string} [meta]
   * @param {(data: any) => void} [handler]
   * @returns
   * @memberof WebSocketService
   */
  send = (
    path: string,
    input: object,
    meta?: string,
    handler?: (data: any) => void,
    // eslint-disable-next-line
  ) => {
    if (meta === this.namespace) {
      window.clearTimeout(this.retryMessageTimeout)
      window.clearTimeout(this.requestTimeout)

      if (this.retryTimes === 0)
        queueMessages?.add({ path, input, meta, handler })

      // wait until the connection is ready
      if (
        this.socket?.readyState === WebSocketStatus.CONNECTING ||
        this.socket?.readyState === WebSocketStatus.CLOSING
      ) {
        this.retryMessageTimeout = window.setTimeout(() => {
          // try to send the message again
          this.send(path, input, meta, handler)
        }, 1500)
        return
      }

      // continue with the expected workflow
      if (this.socket?.readyState === WebSocketStatus.OPEN) {
        if (handler) {
          this.once(path, handler)
        }

        const params: { path: string; input: object; meta?: object } = {
          path,
          input,
          meta: {
            eventId: uuidv4(),
          },
        }

        const payload = JSON.stringify(params)

        this.socket?.send(payload)

        // perform a request timeout
        this.requestTimeout = window.setTimeout(() => {
          if (queueMessages.queue.length > 0) {
            this.socket = null
            this.reconnect()
          }
        }, 30000)
      }

      if (this.socket?.readyState === WebSocketStatus.CLOSED) {
        this.reconnect()
      }
    }
  }

  /**
   * @description Receives the messages from the server
   * @private
   * @param {MessageEvent} event
   * @memberof WebSocketService
   */
  private onMessage(event: MessageEvent) {
    const message = JSON.parse(event.data)

    const { input, path } = message

    if (input && path) {
      this.emit(path, input)
      queueMessages?.clean()
    }
  }

  private reconnect = () => {
    this.socket = null
    this.connect()
  }

  /**
   * @description When connection is closed, a reconnect feature is triggered
   * @private
   * @param {CloseEvent} close
   * @memberof WebSocketService
   */
  private onClose(close: Event) {
    window.clearTimeout(this.requestTimeout)

    this.retryTimes = this.retryTimes + 1

    store.dispatch(
      SocketActions.gatewayDisconnected({
        error: i18n.t('connection.closed'),
        namespace: this.namespace,
      }),
    )

    this.reconnectInterval = window.setTimeout(
      this.reconnect,
      Math.min(10000, (this.timeout += this.timeout)),
    )
  }

  /**
   * @description Search for pending messages when the connection is open
   *
   * @private
   * @param {Event} open
   * @memberof WebSocketService
   */
  private onOpen(open: Event) {
    window.clearTimeout(this.reconnectInterval)

    if (queueMessages?.queue.length === 0) {
      this.retryTimes = 0
    }
    // if there are failed message request, send them
    if (queueMessages?.queue.length > 0) {
      this.sendPendingMessages()
    }

    this.timeout = 250

    if (this.namespace) {
      store.dispatch(
        SocketActions.gatewayConnected({
          isIO: false,
          isConnected: true,
          namespace: this.namespace,
        }),
      )
    }
  }

  private onError(error: Event) {
    this.socket?.close(
      WSStatusCode.AnErrorHasOcurred,
      WSStatusMessages.AnErrorHasOcurred,
    )

    store.dispatch(
      SocketActions.gatewayDisconnected({
        error: WSStatusMessages.AnErrorHasOcurred,
        namespace: this.namespace,
      }),
    )
  }

  private sendPendingMessages() {
    const { queue } = queueMessages

    queue.forEach(q => {
      this.send(q.path, q.input, q.meta, q.handler)
    })
    this.retryTimes = 0
  }

  private attachListeners() {
    this.socket?.addEventListener('open', event => this.onOpen(event))
    this.socket?.addEventListener('message', event => this.onMessage(event))
    this.socket?.addEventListener('error', error => this.onError(error))
    this.socket?.addEventListener('close', event => this.onClose(event))
  }
}
export default WebSocketService
