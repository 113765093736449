import ActionTypes from '../constants'
import { TemplateActions, TemplateState } from '../models'

const templateUIHandler = (
  state: TemplateState,
  action: TemplateActions,
): TemplateState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }
    default:
      return state
  }
}

export default templateUIHandler
