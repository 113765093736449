import { put, select, call } from 'redux-saga/effects'
import i18n from 'i18n'
import { Container } from 'typedi'

import ApiService from 'services/api/index.service'
import objectToQueryString from 'utils/query-string'
import removeEmptyProp from 'utils/state/remove-empty-prop.util'

import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import {
  archiveSegmentAction,
  getAllSegmentAction,
  getAllSegmentsArchiveAction,
} from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the archival of a single segment
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof archiveSegmentAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
export function* archiveSegmentWorker(
  action: ReturnType<typeof archiveSegmentAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'segmentations/archive/one',
      queries: objectToQueryString(action.payload),
    }

    const { auth, segment } = yield select()
    const response = yield call(apiService.deleteData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('segment'),
      what: i18n.t(
        action.payload.isDeleted ? 'messages.deleted' : 'messages.recovered',
      ),
    })

    if (response._id) {
      yield put(archiveSegmentAction.success(response))
      if (action.payload.isDeleted) {
        yield put(getAllSegmentAction.request(removeEmptyProp(segment.filters)))
      } else {
        yield put(
          getAllSegmentsArchiveAction.request(removeEmptyProp(segment.filters)),
        )
      }
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        archiveSegmentAction.failure({
          name: response.status || '',
          message,
        }),
      )
    }
    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(archiveSegmentAction.failure(error))
  }
}

export default archiveSegmentWorker
