import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const campaignUpdateStatusHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.UPDATE_STATUS_REQUEST:
      return { ...state }

    case ActionTypes.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        currentCampaign: action.payload,
      }

    case ActionTypes.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default campaignUpdateStatusHandler
