import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18next'

import ApiService from 'services/api/index.service'
import {
  setDialogAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import removeEmptyProp from 'utils/state/remove-empty-prop.util'

import {
  createUserAction,
  createResetPassTokenAction,
  getAllUserAction,
} from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the creation of a single user
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createUserAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createUserWorker(
  action: ReturnType<typeof createUserAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'users/create',
      body: action.payload,
    }

    const { auth, user } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: `${i18n.t('user')} ${action.payload.username}`,
      what: i18n.t('created'),
    })

    if (response.username) {
      yield put(createUserAction.success(response))

      yield put(
        createResetPassTokenAction.request({
          email: response.email,
        }),
      )

      yield put(setDialogAction({ open: false }))

      yield put(getAllUserAction.request(removeEmptyProp(user.filters)))
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        createUserAction.failure({
          name: response.status,
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response?.username ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createUserAction.failure(error))
  }
}

export default createUserWorker
