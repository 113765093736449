import { PlayerCardState, PlayerCardActions } from '../models'
import ActionTypes from '../constants'

const getAccountStatusLogsHandler = (
  state: PlayerCardState,
  action: PlayerCardActions,
): PlayerCardState => {
  switch (action.type) {
    case ActionTypes.GET_ACCOUNT_STATUS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ACCOUNT_STATUS_SUBSCRIBER:
      return {
        ...state,
        accountStatusLogs: action.payload?.docs,
        isLoading: false,
      }

    default:
      return state
  }
}

export default getAccountStatusLogsHandler
