/**
 * Template on site notification actions
 */
enum ActionTypes {
  CREATE_REQUEST = '@template-onsite/CREATE_REQUEST',
  CREATE_SUCCESS = '@template-onsite/CREATE_SUCCESS',
  CREATE_FAILURE = '@template-onsite/CREATE_FAILURE',

  UPDATE_REQUEST = '@template-onsite/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@template-onsite/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@template-onsite/UPDATE_FAILURE',

  GET_ONE_REQUEST = '@template-onsite/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@template-onsite/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@template-onsite/GET_ONE_FAILURE',

  SEND_TEST_REQUEST = '@template-onsite/SEND_TEST_REQUEST',
  SEND_TEST_SUCCESS = '@template-onsite/SEND_TEST_SUCCESS',
  SEND_TEST_FAILURE = '@template-onsite/SEND_TEST_FAILURE',

  SET_FILTERS = '@template-onsite/SET_FILTERS',

  GET_ALL_REQUEST = '@template-onsite/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@template-onsite/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@template-onsite/GET_ALL_FAILURE',

  DELETE_REQUEST = '@template-onsite/DELETE_REQUEST',
  DELETE_SUCCESS = '@template-onsite/DELETE_SUCCESS',
  DELETE_FAILURE = '@template-onsite/DELETE_FAILURE',

  DUPLICATE_REQUEST = '@template-onsite/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@template-onsite/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@template-onsite/DUPLICATE_FAILURE',

  ARCHIVE_REQUEST = '@template-onsite/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@template-onsite/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@template-onsite/ARCHIVE_FAILURE',
}

export default ActionTypes
