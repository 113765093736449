import TimelineEntryType from 'state/enums/timeline-entry-type.enum'
import TimelineEntrySubType from 'state/enums/timeline-entry-subtype.enum'
import ActionTypes from '../constants'

import { LogState, LogActions } from '../models'

const timelineLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER_TIMELINE:
      return {
        ...state,
        isLoadingTimeline: true,
      }

    case ActionTypes.GET_PLAYER_TIMELINE_SUBSCRIBER: {
      const timelineFiltered =
        action.payload?.message?.filter((log: any) => {
          return !(
            log.entryType === TimelineEntryType.TRANSACTION &&
            (log.entrySubType === TimelineEntrySubType.MANUAL_DEPOSIT ||
              log.entrySubType === TimelineEntrySubType.MANUAL_WITHDRAW)
          )
        }) || []
      return {
        ...state,
        isLoadingTimeline: false,
        timeline: timelineFiltered,
      }
    }

    default:
      return state
  }
}

export default timelineLogHandler
