import { createAction, createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import { TemplateModels } from '..'

export const createTemplateAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<
  { payload: TemplateModels.CreateTemplatePayload; sideEffect: any },
  TemplateModels.TemplateOnSiteType,
  Error
>()

export const updateTemplateAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<
  TemplateModels.UpdateTemplatePayload,
  TemplateModels.TemplateOnSiteType,
  Error
>()

export const getOneTemplateAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<
  TemplateModels.GetOneTemplatePayload,
  TemplateModels.TemplateOnSiteType,
  Error
>()

export const sendTestTemplateAction = createAsyncAction(
  ActionTypes.SEND_TEST_REQUEST,
  ActionTypes.SEND_TEST_SUCCESS,
  ActionTypes.SEND_TEST_FAILURE,
)<any, any, any>()

export const setFilterTemplateAction = createAction(
  ActionTypes.SET_FILTERS,
  action => {
    return (filters: TemplateModels.TemplateFilterProps) => action(filters)
  },
)

export const getAllTemplatesAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<
  TemplateModels.GetAllTemplatePayload,
  TemplateModels.TemplatesResponse,
  Error
>()

export const archiveTemplateAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<
  TemplateModels.ArchiveTemplatePayload,
  TemplateModels.CurrentTemplate,
  Error
>()

export const duplicateTemplateAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<
  TemplateModels.DuplicateTemplatePayload,
  TemplateModels.TemplateOnSiteType,
  Error
>()
