const TAGS = {
  GET_ALL: 'getTags' as 'getTags',
  GET_ALL_CRM: 'getTags' as 'getTags',
  GET_ONE: 'getTagsById' as 'getTagsById',
  CREATE: 'createTag' as 'createTag',
  UPDATE: 'updateTag' as 'updateTag',
  DELETE: 'deleteTag' as 'deleteTag',
  GET_ALL_CATEGORIES: 'getCategories' as 'getCategories',
  GET_ALL_CRM_CATEGORIES: 'getCategories' as 'getCategories',
  GET_ONE_CATEGORY: 'getCategoryById' as 'getCategoryById',
  GET_ALL_TAGS_CATEGORY: 'getTagsByCategory' as 'getTagsByCategory',
  SET_TAGS_CRM: 'createCrmTagAssigned' as 'createCrmTagAssigned',
  REMOVE_TAGS_CRM: 'removeCrmTagAssigned' as 'removeCrmTagAssigned',
  GET_TAGS_CRM: 'getCrmTagsAssigned' as 'getCrmTagsAssigned',
  CREATE_CATEGORY: 'createCategory' as 'createCategory',
  UPDATE_CATEGORY: 'updateCategory' as 'updateCategory',
  DELETE_CATEGORY: 'deleteCategory' as 'deleteCategory',
  GET_CRM_TAGS_EXCEPTION: 'exception' as 'exception',
  GET_TAGS_EXCEPTION: 'exception' as 'exception',

  GET_ASSIGNED: 'getTagsAssigned' as 'getTagsAssigned',
  ASSIGN_TAG: 'createMultipleTagAssigned' as 'createMultipleTagAssigned',
  REMOVE_TAG: 'removeTagAssignedToPlayer' as 'removeTagAssignedToPlayer',

  BULK_ASSIGN_TAG: 'bulkAssignment' as 'bulkAssignment',
  BULK_REMOVE_TAG: 'bulkUnnasignment' as 'bulkUnnasignment',

  GET_LINKED_TAG_PLAYERS: 'getPlayersLinkedToTag' as 'getPlayersLinkedToTag',
  GET_PLAYERS_BY_IDS: 'playersByIds' as 'playersByIds',
}

export default TAGS
