import { createReducer } from 'typesafe-actions'
import * as AssessmentModels from '../models'
import AssessmentInitialState from './initial-state'

import ActionTypes from '../constants'
import getAssessmentsHandler from './get-assessments.handler'
import getAssessmentsQuestionsHandler from './get-assessments-questions.handler'

/**
 *
 * Assessment main reducer
 *
 */

const assessmentReducer = createReducer<
  AssessmentModels.AssessmentState,
  AssessmentModels.SocketActions | AssessmentModels.AssessmentActions
>(AssessmentInitialState).handleAction(
  [
    ActionTypes.GET_ASSESSMENTS,
    ActionTypes.GET_ASSESSMENTS_SUBSCRIBER,
    ActionTypes.GET_ASSESSMENTS_EXCEPTION_SUBSCRIBER,
  ],
  getAssessmentsHandler,
)
.handleAction(
  [
    ActionTypes.GET_ASSESSMENTS_QUESTIONS,
    ActionTypes.GET_ASSESSMENTS_QUESTIONS_SUBSCRIBER,
    ActionTypes.GET_ASSESSMENTS_QUESTIONS_EXCEPTION_SUBSCRIBER,
  ],
  getAssessmentsQuestionsHandler,
)

export default assessmentReducer
