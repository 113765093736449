import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import {
  getAllCampaignsArchiveAction,
  getAllSegmentNamesAction,
} from '../actions/index.actions'
import { CampaignsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets all archived campaigns
 *
 * * Default pagination:
 *
 *  . limit 17
 *  . page  1
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllCampaignsArchiveAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllCampaignsArchiveWorker(
  action: ReturnType<typeof getAllCampaignsArchiveAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'campaigns/get/archived',
      queries: jsonToQueryString(action.payload),
    }

    const { auth, campaign } = yield select()
    const response: CampaignsResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllCampaignsArchiveAction.success(response))

    if (campaign.segmentNames.length === 0) {
      yield put(getAllSegmentNamesAction.request())
    }
  } catch (error) {
    yield put(getAllCampaignsArchiveAction.failure(error))
  }
}

export default getAllCampaignsArchiveWorker
