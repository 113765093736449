/**
 *
 * Company Action constants
 *
 * @action CREATE
 * @action UPDATE
 * @action DELETE
 * @action GET_ALL
 * @action GET_ONE
 */

enum ActionTypes {
  UPDATE_REQUEST = '@company/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@company/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@company/UPDATE_FAILURE',

  GET_ONE_REQUEST = '@company/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@company/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@company/GET_ONE_FAILURE',

  CREATE_SUBSCRIBER = '@company/CREATE_SUBSCRIBER',
  DELETE_SUBSCRIBER = '@company/DELETE_SUBSCRIBER',
  UPDATE_SUBSCRIBER = '@company/UPDATE_SUBSCRIBER',
  UPDATE_STATUS_SUBSCRIBER = '@company/UPDATE_STATUS_SUBSCRIBER',

  GET_SETTINGS = '@company/GET_SETTINGS',
  GET_SETTINGS_SUBSCRIBER = '@company/GET_SETTINGS_SUBSCRIBER',

  UPDATE_SETTINGS = '@company/UPDATE_SETTINGS',
  UPDATE_SETTINGS_SUBSCRIBER = '@company/UPDATE_SETTINGS_SUBSCRIBER',

  GET_BONUS_EXPIRY_NOTIFICATION = '@company/GET_BONUS_EXPIRY_NOTIFICATION',
  GET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER = '@company/GET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER',

  SET_BONUS_EXPIRY_NOTIFICATION = '@company/SET_BONUS_EXPIRY_NOTIFICATION',
  SET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER = '@company/SET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER',

  GET_VISITS_CONFIG = '@company/GET_VISITS_CONFIG',
  GET_VISITS_CONFIG_SUBSCRIBER = '@company/GET_VISITS_CONFIG_SUBSCRIBER',

  SET_VISITS_CONFIG = '@company/SET_VISITS_CONFIG',
  SET_VISITS_CONFIG_SUBSCRIBER = '@company/SET_VISITS_CONFIG_SUBSCRIBER',

  SAVE_EVALUATION = '@company/SAVE_EVALUATION',
  SAVE_EVALUATION_SUBSCRIBER = '@company/SAVE_EVALUATION_SUBSCRIBER',

  GET_EVALUATION = '@company/GET_EVALUATION',
  GET_EVALUATION_SUBSCRIBER = '@company/GET_EVALUATION_SUBSCRIBER',
}

export default ActionTypes
