import ActionTypes from '../constants'
import {
  GamingActivityState,
  GamingActivitySocketActions,
  GamingActivityActions,
} from '../models'

const getSessionsHandler = (
  state: GamingActivityState,
  action: GamingActivitySocketActions | GamingActivityActions,
): GamingActivityState => {
  switch (action.type) {
    case ActionTypes.GET_GAME_LIST_ADMIN_SUBSCRIBER:
      return {
        ...state,
        gameNames: action.payload.gameList,
      }

    case ActionTypes.GET_GAME_CATEGORY_LIST_SUBSCRIBER:
      return {
        ...state,
        // TODO set state
      }

    case ActionTypes.GET_GAME_PROVIDER_LIST_SUBSCRIBER:
      return {
        ...state,
        // TODO set state
      }

    case ActionTypes.GET_GAME_SESSIONS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_GAME_SESSIONS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        gameSessions: action.payload.gameSessions || [],
        paginationGameSessions: {
          ...state.paginationGameSessions,
          total: action.payload.totalNumOfItems || 0,
          pages: Math.ceil(
            action.payload.totalNumOfItems / state.paginationGameSessions.limit,
          ),
        },
      }

    case ActionTypes.GET_GAME_TRANSACTIONS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_GAME_TRANSACTIONS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        gameTransactions: action.payload.gameTransactions || [],
        paginationGameTransaction: {
          totalNumOfItems: action.payload.totalNumOfItems || 0,
        },
      }

    default:
      return state
  }
}

export default getSessionsHandler
