const campaigns = {
  INDEX: '/campaigns',
  ARCHIVE: '/campaigns/archive',
  CREATE: '/campaigns/create',
  EDIT: '/campaigns/edit',
  EDITCAMPAIGN: '/campaigns/edit/:campaignId',
  ANALYTICS: '/campaigns/analytics',
  ANALYTICS_HISTORY: '/campaigns/analytics-history',
  ANALYTICS_DETAIL: '/campaigns/analytics',
  ANALYTICS_ACTIVITY: '/campaigns/email-activity',
}

export default campaigns
