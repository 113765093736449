import { select, call, put } from 'redux-saga/effects'
import i18n from 'i18n'
import { Container } from 'typedi'

import ApiService from 'services/api/index.service'
import { setSnackbarAction } from 'state/ui/global/actions'
import jsonToQueryString from 'utils/query-string'

import { deleteRoleAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga deletes one role
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof deleteRoleAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* deleteRoleWorker(
  action: ReturnType<typeof deleteRoleAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'users/roles/delete',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(deleteRoleAction.success(response))
    yield put(
      setSnackbarAction({
        message: i18n.t('messages.successfully', {
          item: i18n.t('role'),
          what: i18n.t('deleted'),
        }),
        open: true,
        variant: 'success',
      }),
    )
  } catch (error) {
    yield put(deleteRoleAction.failure(error))
  }
}

export default deleteRoleWorker
