import { takeLatest } from 'redux-saga/effects'
import {
  createFrequencyCapAction,
  deleteFrequencyCapAction,
  getAllFrequencyCapAction,
  updateFrequencyCapAction,
} from '../actions/index.actions'
import getAllFrequencyCapWorker from './get-all.saga'

import createFrequencyCapWorker from './create.saga'
import updateFrequencyCapWorker from './update.saga'
import deleteFrequencyCapWorker from './delete.saga'

/**
 * Main Saga for watching saga workers  in the Frequency Cap state shape
 *
 * @generator
 */
function* frequencyCapSagasWatcher() {
  yield [
    yield takeLatest(
      getAllFrequencyCapAction.request,
      getAllFrequencyCapWorker,
    ),
    yield takeLatest(
      createFrequencyCapAction.request,
      createFrequencyCapWorker,
    ),
    yield takeLatest(
      updateFrequencyCapAction.request,
      updateFrequencyCapWorker,
    ),
    yield takeLatest(
      deleteFrequencyCapAction.request,
      deleteFrequencyCapWorker,
    ),
  ]
}

export default frequencyCapSagasWatcher
