import { createReducer } from 'typesafe-actions'
import { PlayerCardSummaryState, PlayerCardSummaryActions } from '../models'
import initialState from './initial-state'

import playerNotesHandler from './notes.handler'
import ActionTypes from '../constants'

/**
 * Creates a PlayerCard Summary Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardSummaryReducer = createReducer<
  PlayerCardSummaryState,
  PlayerCardSummaryActions
>(initialState).handleAction(
  [
    ActionTypes.CREATE_PLAYER_NOTE,
    ActionTypes.CREATE_PLAYER_NOTE_SUBSCRIBER,
    ActionTypes.GET_PLAYER_NOTES,
    ActionTypes.GET_PLAYER_NOTES_SUBSCRIBER,
    ActionTypes.UPDATE_PLAYER_NOTE,
    ActionTypes.UPDATE_PLAYER_NOTE_SUBSCRIBER,
    ActionTypes.DELETE_PLAYER_NOTE_SUBSCRIBER,
  ],
  playerNotesHandler,
)

export default playerCardSummaryReducer
