import { ActionType } from 'typesafe-actions'
import { PaginationResponse } from '../root.models'
import * as actions from './actions/index.actions'

/**
 * =====================================
 * D A T A  T Y P E S
 * =====================================
 */

export enum TemplateOnSiteContentTypeOptions {
  DEFAULT = 'DEFAULT',
  IMAGE = 'IMAGE',
}

export type TemplateOnSiteType = {
  _id: string
  name: string
  description: string
  templates: TemplateOnSiteContentType[]
  isDeleted: boolean
  tags?: string[]
  updatedAt: string
  createdAt: string
  createdBy: string
}

export type TemplateOnSiteContentType = {
  language: string
  title: string
  type: TemplateOnSiteContentTypeOptions
  text: string
  ctaLabel?: string
  ctaUrl?: string
  imageUrl?: string
}

export interface TemplateTableColumnProps extends TemplateOnSiteType {
  language: string[]
  createdAtNumber: string | number
  tagsText: string
}

/**
 * ==========================
 * P A Y L O A D   T Y P E S
 * ==========================
 */

export type CreateTemplatePayload = Omit<
  TemplateOnSiteType,
  '_id' | 'updatedAt' | 'createdAt' | 'isDeleted'
>

export interface UpdateTemplatePayload
  extends Partial<
    Omit<TemplateOnSiteType, '_id' | 'updatedAt' | 'createdAt' | 'active'>
  > {
  id: string
}

export type GetOneTemplatePayload = {
  id: string
}

export type SendTestTemplatePayload = {
  playerIds: string[]
  template: TemplateOnSiteContentType
}

export type CurrentTemplate = TemplateOnSiteType

export type TemplateFilterProps = {
  page: number
  limit: number
  language?: string[]
  tags?: string[]
  createdBy?: string[]
}

export type GetAllTemplatePayload = TemplateFilterProps

export type ArchiveTemplatePayload = {
  id: string
  isDeleted: boolean
}

export type DuplicateTemplatePayload = {
  id: string
  createdBy: string
}

/**
 * ==========================
 * S T A T E   T Y P E
 * ==========================
 */

export type TemplateState = {
  readonly isLoading: boolean
  readonly currentTemplate: TemplateOnSiteType
  readonly templates: TemplateOnSiteType[]
  readonly pagination: PaginationResponse
  readonly filters: TemplateFilterProps
}

/**
 * ===============
 *  A C T I O N S
 * ===============
 */

export type TemplateActions = ActionType<typeof actions>

/**
 * ===========================
 * R E S P O N S E   T Y P E S
 * ===========================
 */

export interface TemplatesResponse extends PaginationResponse {
  docs: TemplateOnSiteType[]
}
