import { call, put } from 'redux-saga/effects'
import Container from 'typedi'
import FileStorageService from 'services/image-issue/index.service'

import { setLoadingFullAction } from 'state/ui/global/actions'
import DocumentFileFormat from 'state/enums/document-file-format.enum'
import { downloadDocumentSecondImageAction } from '../actions/rest.actions'

/**
 * @instance FileStorageService
 */
const fileStorageService: FileStorageService = Container.get(FileStorageService)

/**
 *
 * This saga get an image from a Issue Document
 *
 * @requires FileStorageService
 * @param {ReturnType<typeof downloadDocumentSecondImageAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* downloadSecondImageDocumentWorker(
  action: ReturnType<typeof downloadDocumentSecondImageAction.request>,
) {
  try {
    const url = '/download-document'
    const name = action.payload.fileName

    fileStorageService.requestParams = {
      method: 'POST',
      endpoint: url,
      body: action.payload,
      responseType: 'blob',
    }
    const type = DocumentFileFormat.IMG

    const response: string = yield call(fileStorageService.sendData, 'burek')
    yield put(setLoadingFullAction(false))

    if (typeof response === 'string' && response.length > 0) {
      yield put(
        downloadDocumentSecondImageAction.success({
          url: response,
          type,
          name,
        }),
      )
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(downloadDocumentSecondImageAction.failure(error))
  }
}

export default downloadSecondImageDocumentWorker
