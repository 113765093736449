import ActionTypes from '../constants'
import { LevelDefinitionActions, LevelsState } from '../models'

const updateLevelDefinitionHandler = (
  state: LevelsState,
  action: LevelDefinitionActions,
): LevelsState => {
  switch (action.type) {
    case ActionTypes.UPDATE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default updateLevelDefinitionHandler
