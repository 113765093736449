import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'

const analyticGeneralHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }
    case ActionTypes.SET_FILTERS_EVENTS:
      return {
        ...state,
        filtersEvents: { ...state.filtersEvents, ...action.payload },
      }
    case ActionTypes.SET_FILTERS_CAMPAIGN_BY_PLAYER:
      return {
        ...state,
        filtersCampaignByPlayer: { ...action.payload },
      }
    case ActionTypes.SET_TARGETS_GROUP:
      return {
        ...state,
        targets: action.payload,
      }
    default:
      return state
  }
}

export default analyticGeneralHandler
