const websockets = {
  connection: {
    refused: `We couldn't establish connection with the server. Please try again.`,
    closed: 'Connection closed with the server.',
    closing: 'Closing connection',
    error: 'There was an error.',
  },
}

export default websockets
