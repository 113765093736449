import { PlayerCardNoteState } from '../models'

const initialState: PlayerCardNoteState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  playerNotes: [],
}

export default initialState
