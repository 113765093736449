import { BonusEngineActions, BonusEngineState } from '../models'
import ActionTypes from '../constants'

const campaignHandler = (
  state: BonusEngineState,
  action: BonusEngineActions,
): BonusEngineState => {
  switch (action.type) {
    case ActionTypes.SET_CHOSEN_BONUSES:
      return {
        ...state,
        chosenBonuses: action.payload,
      }

    case ActionTypes.GET_CAMPAIGNS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_CAMPAIGNS_SUBSCRIBER:
      let bonusesIds: number[] = [] // eslint-disable-line
      action.payload.resolved &&
        action.payload.payload.data &&
        action.payload.payload.data.forEach(campaign => {
          bonusesIds = [...bonusesIds, ...campaign.bonusIds]
        })
      const totalCampaigns = action.payload.payload.data // eslint-disable-line
        ? action.payload.payload.data.length
        : 0
      return {
        ...state,
        isLoading: false,
        campaigns:
          action.payload.resolved && action.payload.payload.data
            ? action.payload.payload.data
            : [],
        chosenBonuses: bonusesIds,
        campaignsPagination: {
          ...state.campaignsPagination,
          total: totalCampaigns,
          pages: Math.ceil(totalCampaigns / state.campaignsPagination.limit),
        },
      }

    case ActionTypes.CREATE_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.CREATE_CAMPAIGN_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.GET_ONE_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_CAMPAIGN_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentCampaign: action.payload.payload.data[0],
      }

    case ActionTypes.UPDATE_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_CAMPAIGN_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.FORMAT_CURRENT_CAMPAIGN:
      return {
        ...state,
        currentCampaign: {
          id: 0,
          campaignName: '',
          startDate: new Date().toISOString(),
          endDate: new Date().toISOString(),
          priority: 0,
          combinable: false,
          bonusIds: [],
          active: false,
        },
      }

    case ActionTypes.DELETE_CAMPAIGN_SUBSCRIBER:
      return {
        ...state,
        campaigns: state.campaigns.filter(
          campaign => campaign.id !== action.payload.payload.data.id,
        ),
      }

    case ActionTypes.SET_CAMPAIGNS_PAGINATION: {
      return {
        ...state,
        campaignsPagination: {
          ...state.campaignsPagination,
          ...action.payload,
        },
      }
    }

    default:
      return state
  }
}

export default campaignHandler
