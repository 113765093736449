/**
 *
 * Teledata constants
 *
 */

enum ActionTypes {
  SET_FILTERS = '@teledata/SET_FILTERS',

  GET_TELEDATA_REPORT = '@teledata/GET_TELEDATA_REPORT',
  GET_TELEDATA_REPORT_SUBSCRIBER = '@teledata/GET_TELEDATA_REPORT_SUBSCRIBER',

  DOWNLOAD_TELEDATA_REPORT = '@teledata/DOWNLOAD_TELEDATA_REPORT',
}

export default ActionTypes
