import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'

import rootReducer from './root.reducer'
import socketMiddleware from './middleware/sockets/middleware'
import cthunkMiddleware from './middleware/cthunk/middleware'

import rootSaga from './root.saga'
import history from '../routes/browser-history'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'settings'],
}

const pReducer = persistReducer(persistConfig, rootReducer(history))

const sagaMiddleware = createSagaMiddleware()

const middleware = [
  sagaMiddleware,
  cthunkMiddleware,
  socketMiddleware(),
  routerMiddleware(history),
]

// const enhancer = composeWithDevTools(applyMiddleware(...middleware))
// uncomment this to add trace to react dev tools
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
})

const preloadedState = {}

// const store = createStore(pReducer, preloadedState, enhancer)
// uncomment this to add trace to react dev tools
const store = createStore(
  pReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(...middleware)),
)

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
