import BonusEngineBonusType from 'state/enums/be-bonus-type.enum'
import BonusEngineTriggerType from 'state/enums/be-trigger-type.enum'
import BonusEngineWageringType from 'state/enums/be-wagering-type.enum'

import CurrencyCode from 'state/enums/currency-code.enum'
import TimeUnits from 'state/enums/time-units.enum'
import { BonusEngineState } from '../models'

const bonusEngineInitialState: BonusEngineState = {
  isLoading: true,
  bonuses: [],
  campaigns: [],
  currentCampaign: {
    id: 0,
    campaignName: '',
    startDate: '',
    endDate: '',
    priority: 0,
    combinable: false,
    bonusIds: [],
    active: false,
  },
  currentBonus: {
    bonusName: '',
    id: 0,
    bonusId: 0,
    createdBy: 0,
    bonusType: BonusEngineBonusType.MONEY,
    defaultCurrency: CurrencyCode.CHF,
    maxGrantedAmount: 0,
    maxGrantedCount: 0,
    triggerType: BonusEngineTriggerType.DEPOSIT,
    wageringType: BonusEngineWageringType.ROUNDS,
    wageringRequirement: 0,
    optIn: false,
    repeat: 0,
    bonusRules: [],
    bonusGameInclude: [],
    gameCategoryContribution: [],
    gameContribution: [],
    bonusCodes: [],
    showTc: false,
    tcId: '',
    allPlayersEligible: false,
    senderId: 'admin',
    reusableCodes: false,
    timeFromEventValue: 0,
    timeFromEventUnit: TimeUnits.DAYS,
    timeToConsumeValue: 0,
    timeToConsumeUnit: TimeUnits.DAYS,
    timeToConsumeDate: '',
    timeToActivateValue: 0,
    timeToActivateUnit: TimeUnits.DAYS,
    timeToConsumeFreeGamesValue: 0,
    timeToConsumeFreeGamesUnit: TimeUnits.DAYS,
    priority: 0,
    includedTags: [],
    excludedTags: [],
    freeGamesSettings: [],
    active: false,
    dependentBonusIds: [],
    freeGameProvider: 0,
    localization: [],
  },
  chosenBonuses: [],
  gamesList: [],
  freeGamesList: [],
  categoriesList: [],
  providersList: [],
  offersPagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 0,
  },
  campaignsPagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  defaultFreeGamesSettings: [],
  languagesSelected: [],
}

export default bonusEngineInitialState
