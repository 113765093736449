import { DialogModel } from './models'

/**
 * `processDialogs`
 *
 * @description Allow to manage multiple dialog states in the same page
 * @param {DialogModel} dialog
 * @param {DialogModel[]} dialogsState
 */
const processDialogs = (
  dialog: DialogModel,
  dialogsState: DialogModel[],
): DialogModel[] => {
  let newDialogState = []
  if (dialog.open) {
    newDialogState = [...dialogsState, dialog]
    return newDialogState
  }
  return dialogsState.filter(d => d.id !== dialog.id)
}

export default processDialogs
