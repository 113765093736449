import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const createUserHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return { ...state }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload.message,
      }

    case ActionTypes.CREATE_CANCEL:
      return {
        ...state,
        hasError: false,
        error: action?.payload,
      }
    default:
      return state
  }
}

export default createUserHandler
