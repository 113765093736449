import { AnnexeState, AnnexeActions } from '../models'
import ActionTypes from '../constants'

const setFiltersHandler = (
  state: AnnexeState,
  action: AnnexeActions,
): AnnexeState => {
  switch (action.type) {
    case ActionTypes.SET_ANNEXE_FILTERS:
      return {
        ...state,
        filters: action.payload.newFilters,
      }

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      }

    default:
      return state
  }
}

export default setFiltersHandler
