import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { v4 as uuidv4 } from 'uuid'

type Props = {
  exact: boolean
  filter: string
  handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeExact: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FiltersPlayerComponent = ({
  exact,
  filter,
  handleChangeFilter,
  handleChangeExact,
}: Props) => {
  const { t } = useTranslation()

  const filterArray = [
    ['username', 'fullname', 'name', 'lastname', 'playerid'],
    ['email', 'address', 'city', 'registrationip', 'affiliateCode'],
    ['phonenumber', 'ballyid', 'birthdate'],
  ]

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={exact}
            onChange={handleChangeExact}
            value="value2"
            disabled={
              filter === 'birthdate' ||
              filter === 'email' ||
              filter === 'phonenumber'
            }
          />
        }
        label={t('exactKeyword')}
      />
      <RadioGroup name="options" value={filter} onChange={handleChangeFilter}>
        <Grid container spacing={1}>
          {filterArray.map(filters => (
            <Grid item xs={4} key={uuidv4()}>
              {filters.map(item => (
                <FormControlLabel
                  key={item}
                  value={item}
                  control={<Radio />}
                  label={t(item)}
                  labelPlacement="end"
                />
              ))}
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </>
  )
}

export default FiltersPlayerComponent
