enum LimitPeriod {
  HOURLY = 1,
  DAILY = 2,
  WEEKLY = 3,
  MONTHLY = 4,
  QUARTERLY = 5,
  ANNUAL = 6,
  TOTAL = 7,
}

export default LimitPeriod
