const referFriend = {
  referAFriend: 'Refer A Friend',
  pendingInvitations: 'Pending Invitations',
  approvedInvitations: 'Approved Invitations',
  rejectedInvitations: 'Rejected Invitations',
  referralId: 'Referral ID',
  invitationDate: 'Invitation Date',
  referralName: 'Referral Name',
  referralEmail: 'Referral Email',
  referralMobile: 'Referral Mobile',
  resend: 'Resend',
  invitationStatus: {
    PENDING: 'Pending',
    ACCEPTED: 'Approved',
    REJECTED: 'Rejected',
    EXPIRED: 'Expired',
  },
  referredPlayerId: 'Referred Player ID',
  token: 'Token',
  registeredOn: 'Registered On',
  firstDeposit: 'First Deposit',
  reward: 'Reward',
  referralPlayerId: 'Referral Player ID',
  searchById: 'Search By ID',
  selectInvitationDate: 'Select Invitation Date',
  invitationStatusLabel: 'Invitation Status',
}

export default referFriend
