import io from 'socket.io-client'
import store from 'state'
import i18n from 'i18n'

import { SocketActions } from 'state/middleware/sockets'

export interface SocketGatewayOptions {
  isIO: boolean
  namespace?: string | undefined
  token?: string | undefined
  host?: string
  port?: string
  queryToken?: string | undefined
}

/**
 * Creates a new connection base in the namespace provided
 * @class SocketIoService
 * @service
 */

class SocketIoService {
  private socket: SocketIOClient.Socket

  private path: string = ''

  private port: string | undefined

  private namespace: string

  private host: string | undefined

  private token: string | undefined

  constructor({ host, port, namespace, token }: Partial<SocketGatewayOptions>) {
    if (namespace) {
      this.namespace = namespace
    }
    this.port = port
    this.host = host
    this.token = token
    this.connect()
  }

  connect() {
    this.setPath()
    this.setSocket()
    this.attachListeners()
    if (this.token) {
      this.setToken(this.token)
    }
  }

  /**
   * @description token Setter
   * @memberof SocketIoService
   */
  private setToken = (token: string | undefined) => {
    if (!token) {
      throw new Error(`ws:missing_token`)
    }
    this.token = token
  }

  /**
   * @description path Setter
   * @memberof SocketIoService
   */
  private setPath = () => {
    this.path = this.port
      ? `${this.host}:${this.port}/${this.namespace}`
      : `${this.host}${this.namespace}`
  }

  /**
   * @description set the Socket instance
   * @memberof SocketIoService
   */
  private setSocket = async () => {
    let webSocket
    if (this.token) {
      webSocket = io(this.path, {
        transports: ['websocket'],
        query: {
          authorization: this.token
        }
      })
    } else {
      webSocket = io(this.path, {
        transports: ['websocket'],
      })
    }

    this.socket = webSocket
  }

  /**
   * @description checks if the connection status was successful
   * @memberof Socket tIoService
   * @throws ws:connect_failed if the connection fails
   */
  checkConnectionStatus = async (): Promise<SocketIOClient.Emitter | Error> => {
    const connectSuccess =
      this.socket && this.socket.on('connect', () => this.socket)

    if (!connectSuccess) {
      throw new Error(`ws:connect_failed`)
    } else {
      return connectSuccess
    }
  }

  disconnect = async (): Promise<SocketIOClient.Emitter> => {
    if (!this.socket) {
      throw new Error(`ws:missing_socket`)
    }
    return this.socket.on('disconnect', () => this.socket)
  }

  reconnect = async (): Promise<SocketIOClient.Emitter> => {
    if (!this.socket) {
      throw new Error(`ws:missing_socket`)
    }
    return this.socket.on('reconnect', () => this.socket)
  }

  private attachListeners() {
    this.socket.on('disconnect', () => {
      store.dispatch(
        SocketActions.gatewayDisconnected({
          error: i18n.t('connection.refused'),
          namespace: this.namespace,
        }),
      )
    })
  }

  getSocket = () => this.socket
}

export default SocketIoService
