import ActionTypes from '../constants'
import { SegmentActions, SegmentState } from '../models'

const segmentPlayersCountHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.PLAYERS_COUNT_REQUEST:
      return { ...state, isLoadingPartial: true }

    case ActionTypes.PLAYERS_COUNT_SUCCESS:
      return {
        ...state,
        playersCount: action.payload?.count || 0,
        players: action.payload?.players || [],
        isLoadingPartial: false,
      }

    case ActionTypes.PLAYERS_COUNT_FAILURE:
      return {
        ...state,
        hasError: true,
        isLoadingPartial: false,
        playersCount: 0,
        players: [],
      }

    case ActionTypes.PLAYERS_COUNT_CANCEL:
      return {
        ...state,
        hasError: false,
        isLoadingPartial: false,
        playersCount: 0,
        players: [],
      }

    default:
      return state
  }
}

export default segmentPlayersCountHandler
