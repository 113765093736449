import { call, put } from 'redux-saga/effects'
import Container from 'typedi'
import JumioService from 'services/jumio/index.service'

import { getJumioDataAction } from '../actions/rest.actions'
import { GetJumioDataResponse } from '../models'

/**
 * @instance JumioService
 */
const jumioService: JumioService = Container.get(JumioService)

/**
 *
 * This saga get the jumio data
 *
 *
 * @requires JumioService
 * @param {ReturnType<typeof getJumioDataAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getJumioDataWorker(
  action: ReturnType<typeof getJumioDataAction.request>,
) {
  try {
    jumioService.requestParams = {
      method: 'POST',
      endpoint: 'get-jumio-data',
      body: action.payload,
    }

    const response: GetJumioDataResponse = yield call(jumioService.sendData)

    yield put(getJumioDataAction.success(response))
  } catch (error) {
    yield put(getJumioDataAction.failure(error))
  }
}

export default getJumioDataWorker
