import { initialState } from './initial-state'
import ActionTypes from '../constants'
import { TagActions, TagState } from '../models'

const getLinkedTagPlayersHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.GET_LINKED_TAG_PLAYERS:
      return { ...state, isLoading: true }

    case ActionTypes.GET_LINKED_TAG_PLAYERS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        tagPlayers: {
          ...state.tagPlayers,
          playersIds: action.payload.docs || [],
        },
        tagPlayersPagination: {
          ...state.tagPlayersPagination,
          total: action.payload.total,
          pages: Math.ceil(
            action.payload.total / state.tagPlayersPagination.limit,
          ),
        },
      }

    case ActionTypes.SET_TAG_PLAYERS_ID:
      return {
        ...state,
        tagPlayers: {
          ...state.tagPlayers,
          id: action.payload,
        },
      }

    case ActionTypes.GET_PLAYERS_BY_IDS:
      return {
        ...state,
        isLoading: true,
        tagPlayers: {
          ...state.tagPlayers,
          players: [],
        },
      }

    case ActionTypes.GET_PLAYERS_BY_IDS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        tagPlayers: {
          ...state.tagPlayers,
          players: action.payload.docs,
        },
        tagPlayersPagination: {
          ...state.tagPlayersPagination,
          total: action.payload.total,
          page: action.payload.page,
          pages: Math.ceil(
            action.payload.total / state.tagPlayersPagination.limit,
          ),
        },
      }

    case ActionTypes.SET_TAG_PLAYERS_FILTERS:
      return {
        ...state,
        tagPlayers: {
          ...state.tagPlayers,
          filters: action.payload,
        },
      }

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case ActionTypes.FORMAT_TAG_PLAYERS:
      return {
        ...state,
        tagPlayers: initialState.tagPlayers,
      }

    default:
      return state
  }
}

export default getLinkedTagPlayersHandler
