import * as CoolOffModels from '../models'
import ActionTypes from '../constants'

const getPeriodsAmountsHandler = (
  state: CoolOffModels.CoolOffState,
  action: CoolOffModels.CoolOffActions,
): CoolOffModels.CoolOffState => {
  switch (action.type) {
    case ActionTypes.GET_PERIODS_AND_AMOUNTS_SUBSCRIBER:
      return {
        ...state,
        periodsAndAmounts:
          action.payload.resolved && action.payload.message.length !== 0
            ? [...action.payload.message]
            : [],
      }

    default:
      return state
  }
}

export default getPeriodsAmountsHandler
