const ISSUE = {
  ADD_ISSUE: 'add-issue' as 'add-issue',
  GET_ISSUES: 'get-issues2' as 'get-issues2',
  GET_ISSUE: 'get-issue' as 'get-issue',
  GET_ISSUES_COUNT: 'get-issues2-count' as 'get-issues2-count',
  UPDATE_ISSUE: 'update-issue' as 'update-issue',
  INSERT_ISSUE_ADMIN: 'insert-issue-admin' as 'insert-issue-admin',
  GET_CLIENT_ACTIVE_ALERTS: 'risk/client-active-alerts-get' as 'risk/client-active-alerts-get',
}

export default ISSUE
