/**
 *
 * Reset page
 *
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
import Auth from '../../layouts/auth'

import ResetModule from '../../reset/index.module'

const Reset = () => {
  const { t } = useTranslation()

  return (
    <>
      <Auth
        title={t('createNewPassword')}
        subtitle={t('resetPasswordSubtitle')}
        auth={false}
        showBack
      >
        <ResetModule />
      </Auth>
    </>
  )
}

export default Reset
