import DocumentFileFormat from 'state/enums/document-file-format.enum'
import ActionTypes from '../constants'
import {
  KYCSocketActions,
  PlayerCardKYCActions,
  PlayerCardKYCDocumentState,
} from '../models'

const playerBankAccountHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions | KYCSocketActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER_IBANS:
      return { ...state, isLoading: true }

    case ActionTypes.GET_PLAYER_IBANS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        playerIbans: action.payload.data.details.ibans || [],
      }

    case ActionTypes.GET_PLAYER_IBAN_DOCUMENT:
      return { ...state, isLoading: true }

    case ActionTypes.GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER: {
      const metaData = action.payload.data.details?.ibanDocument?.metadata
      const validTypes = ['image/jpeg', 'image/png', '.jpeg', '.png']
      return {
        ...state,
        isLoading: false,
        currentDownloadedImage:
          action.payload.data.details?.ibanDocument?.downloadUrl || '',
        documentPreview: {
          format: validTypes.includes(metaData?.contentType || '')
            ? DocumentFileFormat.IMG
            : DocumentFileFormat.PDF,
          url: action.payload.data.details?.ibanDocument?.downloadUrl || '',
        },
        metaData: metaData || state.metaData,
      }
    }
    case ActionTypes.SET_IBAN_REJECTED:
      return { ...state, isLoading: true }

    case ActionTypes.SET_IBAN_REJECTED_SUBSCRIBER:
      return { ...state, isLoading: false }

    case ActionTypes.SET_IBAN_VERIFIED:
      return { ...state, isLoading: true }

    case ActionTypes.SET_IBAN_VERIFIED_SUBSCRIBER:
      return { ...state, isLoading: false }

    case ActionTypes.SET_PLAYER_BANK_ACCOUNT_INFO:
      return { ...state, playerBankAccountInfo: action.payload }

    default:
      return state
  }
}

export default playerBankAccountHandler
