import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'

const templateSendEmailForgotPasswordHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default templateSendEmailForgotPasswordHandler
