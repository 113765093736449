import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from '../../../services/api/index.service'

import { updateStatusSegmentAction } from '../actions/rest.actions'
import { tokenSelector } from '../../auth/selectors'
import { Segmentation } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga updates the status of a single segment
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateStatusSegmentAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateStatusSegmentWorker(
  action: ReturnType<typeof updateStatusSegmentAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'segmentations/update/one/status',
      body: action.payload,
    }

    const token = yield select(tokenSelector)
    const response: Segmentation = yield call(apiService.sendData, token)

    yield put(updateStatusSegmentAction.success(response))
  } catch (error) {
    yield put(updateStatusSegmentAction.failure(error))
  }
}

export default updateStatusSegmentWorker
