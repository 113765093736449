enum TimeZoneUTCType {
  'GMT+00 Coordinated Universal Time' = '+0000',
  'GMT+01 Central European Time / West Africa Time' = '+0100',
  'GMT+02 Central European Summer Time / Eastern European Time / Kaliningrad Time' = '+0200',
  'GMT+03 Eastern European Summer Time / Moscow Time / East Africa Time' = '+0300',
  'GMT+04 Samara Time / Armenia Time / Georgia Time / Mauritius Time' = '+0400',
  'GMT+05 Pakistan Standard Time, / Yekaterinburg Time, / Maldives Time' = '+0500',
  'GMT+06 Omsk Time, / Bangladesh Standard Time' = '+0600',
  'GMT+07 Christmas Island Time (Australia), / Krasnoyarsk Time' = '+0700',
  'GMT+08 China Standard Time / Irkutsk Time / Australian Western Standard Time' = '+0800',
  'GMT+09 Japan Standard Time , / Yakutsk Time' = '+0900',
  'GMT+10 East Australian Standard Time / Vladivostok Time' = '+1000',
  'GMT+11 Sakhalin Time' = '+1100',
  'GMT+12 International Date Line East / New Zealand Standard Time / Magadan Time' = '+1200',
  'GMT-01 Cape Verde Time' = '-0100',
  'GMT-02 South Georgia and the South Sandwich Islands Time' = '-0200',
  'GMT-03 Argentina Time / Uruguay Standard Time / Suriname Time' = '-0300',
  'GMT-04 Amazon Time / Atlantic Standard Time / Chile Standard Time' = '-0400',
  'GMT-05 USA on Eastern Standard Time / Daylight Saving Time' = '-0500',
  'GMT-06 USA on Central Standard Time / Daylight Saving Time' = '-0600',
  'GMT-07 USA and Canada on Mountain Standard Time' = '-0700',
  'GMT-08 Pitcairn Islands / USA and Canada on Pacific Standard Time' = '-0800',
  'GMT-09 Alaska Standard Time / Hawaii-Aleutian Daylight Time' = '-0900',
  'GMT-10 Cook Islands / Hawaii-Aleutian Standard Time' = '-1000',
  'GMT-11 American Samoa / Midway Islands' = '-1100',
  'GMT-12 Baker Island Time' = '-1200',
}

export default TimeZoneUTCType
