import { debounce } from 'redux-saga/effects'
import {
  downloadDocumentImageAction,
  downloadDocumentSecondImageAction,
  downloadImageAction,
  getDocumentImagesAction,
} from '../actions/rest.actions'
import downloadImageDocumentWorker from './download-image-document.saga'

import downloadImageWorker from './download-image.saga'
import downloadSecondImageDocumentWorker from './download-second-image-document.saga'
import getDocumentImagesWorker from './get-document-images.saga'

/**
 * Main Saga for watching saga workers for Issue
 *
 * @generator
 */
function* issueSagasWatcher() {
  yield [
    yield debounce(
      500,
      getDocumentImagesAction.request,
      getDocumentImagesWorker,
    ),
    yield debounce(
      500,
      downloadDocumentImageAction.request,
      downloadImageDocumentWorker,
    ),
    yield debounce(
      500,
      downloadDocumentSecondImageAction.request,
      downloadSecondImageDocumentWorker,
    ),
    yield debounce(500, downloadImageAction.request, downloadImageWorker),
  ]
}

export default issueSagasWatcher
