import ActionTypes from '../constants'
import { SegmentActions, SegmentState } from '../models'
import initialState from './initial-state'

const segmentGetOneHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_REQUEST:
      return { ...state }

    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        currentSegment: action.payload,
      }

    case ActionTypes.GET_ONE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_CURRENT_SEGMENT:
      return {
        ...state,
        currentSegment: initialState.currentSegment,
      }

    default:
      return state
  }
}

export default segmentGetOneHandler
