import FormStatusType from 'state/enums/form-status.enum'
import AmlStatusType from 'state/enums/aml-status.enum'

import { PlayerCardKYCStateUI } from '../models'

const initialState: PlayerCardKYCStateUI = {
  isDocumentDialogOpen: false,
  documentFormMode: FormStatusType.CREATE,
  amlUI: {
    status: AmlStatusType.UNDERREVIEW,
    statusNew: AmlStatusType.UNDERREVIEW,
    isEditing: false,
  },
  isTeledataDialogOpen: false,
  isAmlDialogOpen: false,
  isPEPDialogOpen: false,
}

export default initialState
