enum DateFilter {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  ALL_CAMPAIGNS = 'ALL_CAMPAIGNS',
  ALL_TRANSACTIONS = 'ALL_TRANSACTIONS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LIFETIME = 'LIFETIME',
  DATE_RANGE = 'DATE_RANGE',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_YEAR = 'LAST_YEAR',
}

export default DateFilter
