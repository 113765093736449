enum TimelineEntrySubType {
  TRANSACTIONS = 10,
  DEPOSIT,
  WITHDRAW,
  MANUAL_DEPOSIT,
  MANUAL_WITHDRAW,
  WITHDRAW_CANCEL,
  SESSIONS = 20,
  SESSION_CLOSED,
  SESSION_OPEN,
  ISSUES = 30,
  RISK_MANAGEMENT,
  RESPONSIBLE_GAMING,
  KNOW_YOUR_CUSTOMER,
  GAMIFICATION,
}

export default TimelineEntrySubType
