import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'
import ActionTypes from '../constants'

const updateDocumentsHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.UPDATE_DOCUMENT:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_DOCUMENT_SUBSCRIBER:
      return {
        ...state,
        documents: state.documents.map(doc => {
          if (doc._id === action.payload._id) {
            return {
              ...doc,
              ...action.payload,
            }
          }
          return doc
        }),
        documentImages: state.documentImages.map(doc => {
          if (doc.generation === action.payload.imageId) {
            return {
              ...doc,
              documentId: action.payload._id,
              isVisible: true,
              status: action.payload.status,
              docType: action.payload.documentType,
              issueDate: action.payload.documenIssueDate,
              documentExpiry: action.payload.documentExpiryDate,
              documentNumber: action.payload.documentNumber,
              issuuingauthority: action.payload.issuuingAuthority,
            } as any
          }
          return doc
        }),
      }

    case ActionTypes.UPDATE_DOCUMENT_EXCEPTION:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default updateDocumentsHandler
