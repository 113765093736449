const segmentations = {
  numberOfActivityDays: 'Number of activity days',
  totalWithdrawalCountLifetime: 'Total withdrawal count lifetime',
  secondDepositAmount: 'Second deposit amount',
  lastWithdrawalAmount: 'Last withdrawal amount',
  totalDepositCountLifetime: 'Total deposit count lifetime',
  age: 'Age',
  thirdDepositAmount: 'Third deposit amount',
  firstDepositAmount: 'First deposit amount',
  realMoneyBalance: 'Real money balance',
  bonusMoneyBalance: 'Bonus money balance',
  totalWithdrawalAmountLifetime: 'Total withdrawal amount lifetime',
  phoneNumber: 'Phone number',
  totalDepositAmountLifetime: 'Total deposit amount lifetime',
  lastDepositAmount: 'Last deposit amount',
  grossGamingRevenue: 'Gross gaming revenue',
  lastDepositDate: 'Last deposit date',
  secondDepositDate: 'Second deposit date',
  dateBirth: 'Date of birth',
  lastWithdrawalDate: 'Last withdrawal date',
  thirdDepositDate: 'Third deposit date',
  registrationDate: 'Registration date',
  firstDepositDate: 'First deposit date',
  lastActivityDate: 'Last activity date',
  lastLogin: 'Last login',
  paymentMethods: 'Payment methods',
  username: 'Username',
  currency: 'Currency',
  firstName: 'First name',
  lastName: 'Last name',
  accountStatus: 'Account status',
  email: 'Email',
  city: 'City',
  playerLanguage: 'Player language',
  country: 'Country',
  postalCode: 'Postal code',
  ballyID: 'Bally ID',
  affiliateCode: 'Affiliate code',
  gender: 'Gender',
  nationality: 'Nationality',
  allowsEmails: 'Allows emails',
  allowsSMS: 'Allows SMS',
  allowsPhone: 'Allows phone',
  equal: 'Is equal to',
  notEqual: 'Is not equal to',
  smallerThan: 'Is smaller than',
  greaterThan: 'Is greater than',
  greaterOrEqual: 'Is greater than or equal to',
  greaterOrEqualMultiply: 'Is greater than or equal to multiple of',
  smallerOrEqual: 'Is smaller than or equal to',
  between: 'Is between',
  wasMoreThan: 'Was more than',
  wasExactly: 'Was exactly',
  wasExactlyIgnoringTheYear: 'Was exactly (ignoring the year)',
  isNotEmpty: 'Is not empty',
  isEmpty: 'Is empty',
  isInThePast: 'Is in the past',
  isTodayOrEarlier: 'Is today or earlier',
  isTodayOrLater: 'Is today or later',
  isToday: 'Is today',
  isTodayIgnoringTheYear: 'Is today (ignoring the year)',
  wasInTheLast: 'Was in the last',
  willBeExactlyIn: 'Will be exactly in',
  willBeExactlyInIgnoringTheYear: 'Will be exactly in (ignoring the year)',
  isFalse: 'Is False',
  yes: 'Yes',
  no: 'No',
  activityDateRange: 'Activity Date Range',
  grossGamingRevenueDateRange: 'GGR Period',
  invitationCount: 'Invitation Count',
}
export default segmentations
