import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white, red } = colors

export const listItemIconTheme = {
  root: {
    minWidth: 36,
    color: alpha(white, 0.7),
    '.red &': {
      color: red,
    },
  },
}

export const listItemTextTheme = {
  inset: {
    paddingLeft: 40,
  },
  primary: {
    fontSize: 14,
  },
}
