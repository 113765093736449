import i18n from 'i18n'

/**
 * @function keys
 * This function allows you to type the Object.keys function
 * @param o
 */
export default function keys<O>(o: O) {
  return Object.keys(o) as (keyof O)[]
}

/**
 * @function getKeysArray
 * This function allows you to get an array of enum number values, sorted by translation
 * @param Enum
 * @param translation
 */
export function getKeysArray<O>(Enum: O, translation: string) {
  return keys(Enum)
    .map(item => parseInt(item as string, 10))
    .filter(item => item >= 0)
    .sort(
      (a, b) =>
        -i18n
          .t(`${translation}.${Enum[b as keyof O]}`)
          .localeCompare(i18n.t(`${translation}.${Enum[a as keyof O]}`)),
    )
}
