import ActionTypes from '../constants'
import { IssueState, IssuesActions } from '../model'
import initialState from './initial-state'

const AllHandlers = (state: IssueState, action: IssuesActions): IssueState => {
  switch (action.type) {
    case ActionTypes.SET_ISSUE_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }

    case ActionTypes.SET_ALL_ISSUE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.CREATE_ISSUE:
      return {
        ...state,
      }

    case ActionTypes.CREATE_ISSUE_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload.resolved,
      }

    case ActionTypes.UPDATE_ISSUE:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_ISSUE_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload.resolved,
      }

    case ActionTypes.SET_CURRENT_ISSUE:
      return {
        ...state,
        currentIssue: { ...state.currentIssue, ...action.payload },
      }

    case ActionTypes.SET_ISSUE_DIALOG:
      return {
        ...state,
        dialog: { ...state.dialog, ...action.payload },
      }

    case ActionTypes.SET_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload || state.pagination.page,
        },
      }

    case ActionTypes.SET_ROWS_PER_PAGE:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          limit: action.payload || state.pagination.limit,
          pages: Math.ceil(
            state.pagination.total / (action.payload || state.pagination.limit),
          ),
        },
      }

    case ActionTypes.TOGGLE_NOTIFICATIONS:
      return {
        ...state,
        areNotificationsOpen: action.payload,
      }

    case ActionTypes.CLEAR_ALERTS:
      return { ...initialState }

    case ActionTypes.SET_ISSUES_COUNT_BY_PLAYER:
      return {
        ...state,
        issuesCountByPlayer: action.payload.message,
      }

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case ActionTypes.SET_ISSUE_ID_FILTER:
      return {
        ...state,
        issueIdFilter: action.payload, // eslint-disable-line
      }

    default:
      return state
  }
}

export default AllHandlers
