import i18n from 'i18n'
import { REGEX_EMAIL } from 'constants/regex/index.constant'
import { ValidationSchema } from 'hooks/form/models'

import { setRegexLength } from 'utils/text/index.utils'

const loginValidator: ValidationSchema = {
  email: {
    required: true,
    validators: [
      {
        regExp: REGEX_EMAIL,
        error: 'Format example@email.com',
      },
      {
        regExp: setRegexLength(60),
        error: i18n.t('messages.inputMaxLength', {
          field: 'Email',
          max: '60',
        }),
      },
    ],
  },
}

export default loginValidator
