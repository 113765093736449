import React, { MutableRefObject } from 'react'
import {
  PopperProps,
  ClickAwayListener,
  Popper,
  Box,
  Paper,
} from '@material-ui/core'
import PopperJs from 'popper.js'

import useStyles from './style'

type OwnProps = {
  arrow: boolean
  arrowRef: any
  children?: React.ReactNode
  handleClickAway: (event: React.MouseEvent<Document, MouseEvent>) => void
  maxHeight?: number | string
  maxWidth?: number | string
  minWidth?: number | string
  popperRef?: MutableRefObject<PopperJs | null>
  padding?: number
}

type Props = OwnProps & Partial<PopperProps>

const PopperComponent = ({
  id,
  open,
  arrow,
  arrowRef,
  children,
  modifiers,
  placement,
  anchorEl,
  handleClickAway,
  maxHeight,
  maxWidth,
  popperRef,
  padding,
  minWidth,
}: Props) => {
  const classes = useStyles()

  return (
    <Popper
      popperRef={popperRef}
      id={id}
      className={classes.popper}
      open={open as boolean}
      anchorEl={anchorEl}
      placement={placement}
      modifiers={modifiers}
    >
      {arrow ? <div className={classes.arrow} ref={arrowRef} /> : null}
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          component={Paper}
          boxShadow={8}
          className="bg-blueNav"
          p={padding !== undefined ? padding : 2}
          maxHeight={maxHeight || 'auto'}
          maxWidth={maxWidth || 200}
          minWidth={minWidth || 0}
          overflow="auto"
        >
          {children}
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}

export default PopperComponent
