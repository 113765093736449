import ActionTypes from '../constants'
import { CampaignState, CampaignActions } from '../models'

const campaignGetAllTemplatesHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TEMPLATES_REQUEST:
      return { ...state }

    case ActionTypes.GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        templateNames: action.payload?.docs || [],
      }

    case ActionTypes.GET_ALL_TEMPLATES_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default campaignGetAllTemplatesHandler
