import React, { useEffect, useCallback } from 'react'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Snackbar from '@material-ui/core/Snackbar'

import { Info, Check, Warning, Error, Close } from '@material-ui/icons'
import useStyles from './style'

export const variantSnackbarIcon = {
  success: Check,
  warning: Warning,
  error: Error,
  info: Info,
}

export type SnackbarProps = {
  message: string
  open: boolean
  handleClose: () => void
  variant: keyof typeof variantSnackbarIcon
  automaticClose?: boolean
  secondNote?: boolean
}

const SnackbarWrapper = ({
  message,
  open,
  handleClose,
  variant,
  automaticClose,
  secondNote,
}: SnackbarProps) => {
  const classes = useStyles()
  const { snackbarMessage, icon, iconVariant, snackbarCloseButton } = classes
  const Icon = variantSnackbarIcon[variant]

  /**
   * * Cleans up the Snackbar State
   *
   * When transitioning pages, we need
   * to reset the snackbar state.
   */
  const handleCloseFunc = useCallback(handleClose, [])
  useEffect(() => {
    return () => {
      handleCloseFunc()
    }
  }, [handleCloseFunc])

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={automaticClose ? 3500 : null}
      onClose={automaticClose ? handleClose : () => {}}
      className={secondNote ? `${classes.secondNote}` : ''}
    >
      <SnackbarContent
        className={`${classes.root} ${classes[variant]}`}
        message={
          typeof message === 'string' ? (
            <span className={snackbarMessage}>
              <Icon className={`${icon} ${iconVariant}`} />
              {message}
            </span>
          ) : (
            ''
          )
        }
        action={[
          <div className={snackbarCloseButton} key="close">
            <Close className={icon} onClick={handleClose} />
          </div>,
        ]}
      />
    </Snackbar>
  )
}

export default SnackbarWrapper
