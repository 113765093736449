import { createReducer } from 'typesafe-actions'
import { BonusEngineState, BonusEngineActions } from '../models'
import ActionTypes from '../constants'

import bonusEngineInitialState from './initial-state'
import bonusHandlers from './bonus.handler'
import campaignHandler from './campaign.handler'

/**
 *
 * Bonus Engine main reducer
 *
 */
const BonusEngineReducer = createReducer<BonusEngineState, BonusEngineActions>(
  bonusEngineInitialState,
)
  .handleAction(
    [
      ActionTypes.GET_BONUSES,
      ActionTypes.GET_BONUSES_SUBSCRIBER,
      ActionTypes.SAVE_OFFER,
      ActionTypes.SAVE_OFFER_V2,
      ActionTypes.SAVE_OFFER_SUBSCRIBER,
      ActionTypes.SET_CURRENT_BONUS_OFFER,
      ActionTypes.UPDATE_CURRENT_BONUS_OFFER,
      ActionTypes.GET_BONUS_ENGINE_GAMES,
      ActionTypes.GET_BONUS_ENGINE_GAMES_SUBSCRIBER,
      ActionTypes.GET_GAME_CATEGORIES,
      ActionTypes.GET_GAME_CATEGORIES_SUBSCRIBER,
      ActionTypes.GET_GAME_PROVIDERS,
      ActionTypes.GET_GAME_PROVIDERS_SUBSCRIBER,
      ActionTypes.GET_WAGERING_CONTRIBUTIONS,
      ActionTypes.GET_WAGERING_CONTRIBUTIONS_SUBSCRIBER,
      ActionTypes.DUPLICATE_BONUS_SUBSCRIBER,
      ActionTypes.SET_OFFER_PAGINATION,
      ActionTypes.GET_FREE_GAMES_SETTINGS,
      ActionTypes.GET_FREE_GAMES_SETTINGS_SUBSCRIBER,
      ActionTypes.SET_LANGUAGES_SELECTED,
      ActionTypes.RESET_CURRENT_BONUS,
    ],
    bonusHandlers,
  )
  .handleAction(
    [
      ActionTypes.SET_CHOSEN_BONUSES,
      ActionTypes.GET_CAMPAIGNS,
      ActionTypes.GET_CAMPAIGNS_SUBSCRIBER,
      ActionTypes.CREATE_CAMPAIGN,
      ActionTypes.CREATE_CAMPAIGN_SUBSCRIBER,
      ActionTypes.GET_ONE_CAMPAIGN,
      ActionTypes.GET_ONE_CAMPAIGN_SUBSCRIBER,
      ActionTypes.UPDATE_CAMPAIGN,
      ActionTypes.UPDATE_CAMPAIGN_SUBSCRIBER,
      ActionTypes.FORMAT_CURRENT_CAMPAIGN,
      ActionTypes.SET_CAMPAIGNS_PAGINATION,
    ],
    campaignHandler,
  )

export default BonusEngineReducer
