enum SortTransactionType {
  id = 'id',
  balanceAfter = 'balance_after',
  balanceBefore = 'balance_before',
  date = 'created',
  dateCompleted = 'completed',
  method = 'method',
  cardCompany = 'cardCompany',
  number = 'number',
  transactionStatus = 'transaction_status_id',
  transactionType = 'transaction_type_id',
}

export default SortTransactionType
