import React, { useState, useEffect } from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import useStyles from './style'

type Props = {
  slides: { title: string; description: string }[]
}

const SliderComponent = ({ slides }: Props) => {
  const [current, setCurrent] = useState(0)

  const classes = useStyles()

  const setSlide = (id: number) => {
    setCurrent(id)
  }

  useEffect(() => {
    const interval = setTimeout(() => {
      setCurrent((current + 1) % slides.length)
    }, 7500)
    return () => clearTimeout(interval)
  })

  return (
    <Box>
      {slides.map((slide, index) => (
        // eslint-disable-next-line
        <div key={index}>
          {index === current && (
            <>
              <Typography variant="h4" className="fontWeight-500" paragraph>
                {slide.title}
              </Typography>
              <Typography>{slide.description}</Typography>
            </>
          )}
        </div>
      ))}
      <Box display="flex" mt={4}>
        {slides.map((slide, index) => (
          <Button
            key={index} // eslint-disable-line
            className={`${index === current ? 'active' : ''} ${classes.nav}`}
            onClick={() => setSlide(index)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default SliderComponent
