import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18next'

import ApiService from 'services/api/index.service'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { updateSegmentAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga updates a single segment
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateSegmentAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateSegmentWorker(
  action: ReturnType<typeof updateSegmentAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'segmentations/update/one',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('segment'),
      what: i18n.t('messages.updated'),
    })

    if (response._id) {
      yield put(updateSegmentAction.success(response))
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        updateSegmentAction.failure({
          name: response.status || '',
          message,
        }),
      )
    }
    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(updateSegmentAction.failure(error))
  }
}

export default updateSegmentWorker
