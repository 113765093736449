import { BonusReportActions, BonusReportState } from '../models'
import ActionTypes from '../constants'

const campaignReportHandler = (
  state: BonusReportState,
  action: BonusReportActions,
): BonusReportState => {
  switch (action.type) {
    case ActionTypes.GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        bonusOffers: {
          ...state.bonusOffers,
          report: [...action.payload.payload.data],
          pagination: {
            ...state.bonusOffers.pagination,
            page: 1,
            total: action.payload.payload.data.length,
            pages: Math.ceil(
              action.payload.payload.data.length /
                state.bonusOffers.pagination.limit,
            ),
          },
        },
      }

    default:
      return state
  }
}

export default campaignReportHandler
