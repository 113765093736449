import ActionTypes from '../constants'
import { RewardsState, RewardsActions, RewardProductType, RewardProductCreatePayload } from '../models'

const productHandler = (
  state: RewardsState,
  action: RewardsActions,
): RewardsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REWARD_PRODUCTS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_REWARD_PRODUCTS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardProducts: (action.payload.resolved &&
          action.payload.message) ? [...action.payload.message as RewardProductType[]] : [],
      }

    case ActionTypes.GET_ONE_REWARD_PRODUCT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_REWARD_PRODUCT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentProduct: action.payload.resolved ? action.payload.message as RewardProductType : undefined
      }

    case ActionTypes.CREATE_REWARD_PRODUCT:
      return {
        ...state,
        isLoading: true,
        currentProduct: action.payload
      }

    case ActionTypes.CREATE_REWARD_PRODUCT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardProducts: [ 
          ...state.rewardProducts, 
          {
            ...state.currentProduct as RewardProductCreatePayload,
            id: action.payload.message as number,
            created: 0,
            createdTs: ''
          }
        ]
      }

    case ActionTypes.UPDATE_REWARD_PRODUCT:
        return {
          ...state,
          isLoading: true,
          currentProduct: action.payload
        }
  
    case ActionTypes.UPDATE_REWARD_PRODUCT_SUBSCRIBER:
        return {
          ...state,
          isLoading: false,
          rewardProducts: state.rewardProducts.map(shop => {
            let thisProduct = shop
            if (shop.id === action.payload.message) {
              thisProduct = state.currentProduct as RewardProductType
            }
            return thisProduct
          })
        }

      case ActionTypes.DELETE_REWARD_PRODUCT:
        return {
          ...state,
          isLoading: true,
        }
  
      case ActionTypes.DELETE_REWARD_PRODUCT_SUBSCRIBER:
        return {
          ...state,
          isLoading: false,
          rewardProducts: state.rewardProducts.filter(shop => shop.id !== action.payload.message)
        }


    default:
      return state
  }
}

export default productHandler
