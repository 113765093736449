import * as CoolOffModels from '../models'
import ActionTypes from '../constants'

const deleteCoolOffHandler = (
  state: CoolOffModels.CoolOffState,
  action: CoolOffModels.CoolOffActions | CoolOffModels.SocketActions,
): CoolOffModels.CoolOffState => {
  switch (action.type) {
    case ActionTypes.DELETE_COOL_OFF:
      return {
        ...state,
        isLoadingFull: true,
      }

    case ActionTypes.DELETE_COOL_OFF_SUBSCRIBER:
      return {
        ...state,
        isLoadingFull: false,
        resolved: action?.payload?.resolved || false,
      }

    default:
      return state
  }
}

export default deleteCoolOffHandler
