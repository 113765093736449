import SlackListType from 'state/enums/slack-list.enum'
import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const getSlackListHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.GET_SLACK_LIST:
      return {
        ...state,
      }

    case ActionTypes.GET_SLACK_LIST_SUBSCRIBER:
      return {
        ...state,
        slackUsers:
          action?.payload?.list?.filter(
            item => item.type === SlackListType.USER,
          ) || [],
        slackChannels:
          action?.payload?.list?.filter(
            item => item.type === SlackListType.CHANNEL,
          ) || [],
      }

    default:
      return state
  }
}

export default getSlackListHandler
