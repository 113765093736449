import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import { getAllUserAction } from 'state/user/actions/rest.actions'
import jsonToQueryString from 'utils/query-string'
import { getAllSegmentsArchiveAction } from '../actions/rest.actions'

import { SegmentsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets all archived segments
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllSegmentsArchiveAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllSegmentsArchiveWorker(
  action: ReturnType<typeof getAllSegmentsArchiveAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'segmentations/get/archived',
      queries: jsonToQueryString(action.payload),
    }

    const { auth, user } = yield select()
    const response: SegmentsResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllSegmentsArchiveAction.success(response))

    if (user.users.length === 0) {
      yield put(getAllUserAction.request({ page: 1, limit: 999999 }))
    }
  } catch (error) {
    yield put(getAllSegmentsArchiveAction.failure(error))
  }
}

export default getAllSegmentsArchiveWorker
