import { createReducer } from 'typesafe-actions'
import { TeledataState, TeledataActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import teledataHandler from './teledata.handler'
import filtersHandler from './filters.handler'

/**
 * Creates a Teledata Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const teledataReducer = createReducer<TeledataState, TeledataActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_TELEDATA_REPORT,
      ActionTypes.GET_TELEDATA_REPORT_SUBSCRIBER,
    ],
    teledataHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], filtersHandler)

export default teledataReducer
