import {
  take,
  call,
  put,
  cancelled,
  fork,
  cancel,
  select,
  delay,
} from 'redux-saga/effects'
import { Container } from 'typedi'
import { push } from 'connected-react-router'

import i18n from 'i18n'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import { decodeJWT, encodeJWT } from 'utils/state/jwt.util'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import {
  getLoggedInAction,
  getOperatorById,
} from 'state/user/actions/rest.actions'

import UserActionType from 'state/user/constants'
import { EncodedJWTStatus } from 'utils/state/types'
import {
  loginAction,
  setUserSessionAction,
  setLegacyTokenAction,
} from '../actions'

import ActionType from '../constants'

const apiService: ApiService = Container.get(ApiService)

/**
 * ====================
 * L O G I N  S A G A S
 * ====================
 */
export function* authorize(searchParam: string, password: string) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'auth/login',
      body: {
        searchParam,
        password,
      },
    }

    const response = yield call(apiService.sendData)

    yield put(setLoadingFullAction(false))

    if (!response.accessToken) {
      yield put(
        loginAction.failure({
          name: response?.status,
          message: i18n.t('messages.invalidCredentials'),
        }),
      )
      yield put(
        setSnackbarAction({
          message: response.message || i18n.t('messages.invalidCredentials'),
          open: true,
          variant: 'error',
        }),
      )
    } else {
      yield put(loginAction.success(response))
      const { decoded, isValid } = decodeJWT(response.accessToken)

      if (isValid) {
        yield put(setUserSessionAction(decoded))

        yield put(push(routes.HOME))

        yield put(getLoggedInAction.request())

        yield delay(1500)

        yield put(getOperatorById.request({ userId: decoded._id }))

        yield take(UserActionType.GET_OPERATOR_SUCCESS)

        const { user } = yield select()

        const tokenPayload: EncodedJWTStatus = {
          id: user?.currentOperator?.operatorId,
          entity: 18,
          username: user?.currentOperator?.username,
          iat: Date.now(),
        }
        const adminLegacyToken = encodeJWT(tokenPayload)

        yield put(setLegacyTokenAction(adminLegacyToken))
      }
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(loginAction.failure(error))
    yield put(
      setSnackbarAction({
        message: i18n.t('messages.errorOccurred'),
        open: true,
        variant: 'error',
      }),
    )
  } finally {
    if (yield cancelled()) {
      yield put(loginAction.cancel(''))
    }
  }
}

export function* loginFlow() {
  while (true) {
    const { LOGOUT_REQUEST, REMOVE_TOKEN } = ActionType
    const { payload } = yield take(loginAction.request)

    const task = yield fork(authorize, payload.searchParam, payload.password)
    const action = yield take([LOGOUT_REQUEST, loginAction.failure])

    if (action.type === LOGOUT_REQUEST) {
      yield cancel(task)
      yield put({ type: REMOVE_TOKEN })
    }
  }
}
