import { USER } from './user/socket.routes'
import WALLET from './wallet/socket.routes'
import BONUS from './bonus/socket.routes'

import KYC from './player-card-kyc/socket.routes'
import PLAYER from './player-card/socket.routes'
import LIMIT from './limit/socket.routes'

import GAMING_ACTIVITY from './player-card-gaming-activity/socket.routes'
import COOL_OFFS from './cool-off/socket.routes'
import ISSUE from './issues/socket.routes'

import ASSESSMENT from './assessment/socket.routes'
import TELEDATA from './teledata/socket.routes'
import TAGS from './tags/socket.routes'

import LOG from './log/socket.routes'
import BLACKLIST from './blacklist/socket.routes'
import SUMMARY from './player-card-summary/socket.routes'

import NOTES from './player-card-note/socket.routes'
import REWARD from './reward/socket.routes'
import BONUS_ENGINE from './bonus-engine/socket.routes'

import RULES_ENGINE from './rules-engine/socket.routes'
import REFER_FRIEND from './refer-friend/socket.routes'
import GAME_STORE from './game-store/socket.routes'

import GGR_REPORT from './ggr-report/socket.routes'
import ANNEXE from './annexe/socket.routes'
import USER_LOGS from './user-logs/socket.routes'

import LEVELS from './levels/socket.routes'
import COMPANY from './company/socket.routes'
import BONUS_REPORT from './bonus-report/socket.routes'

import PLAYER_REPORT from './player-report/socket.routes'

export default {
  ANNEXE: { ...ANNEXE },
  ASSESSMENT: { ...ASSESSMENT },
  BLACKLIST: { ...BLACKLIST },
  BONUS: { ...BONUS },
  BONUS_ENGINE: { ...BONUS_ENGINE },
  BONUS_REPORT: { ...BONUS_REPORT },
  COMPANY: { ...COMPANY },
  COOL_OFFS: { ...COOL_OFFS },
  GAMING_ACTIVITY: { ...GAMING_ACTIVITY },
  GAME_STORE: { ...GAME_STORE },
  GGR_REPORT: { ...GGR_REPORT },
  ISSUE: { ...ISSUE },
  KYC: { ...KYC },
  LEVELS: { ...LEVELS },
  LIMIT: { ...LIMIT },
  LOG: { ...LOG },
  NOTES: { ...NOTES },
  PLAYER: { ...PLAYER },
  PLAYER_REPORT: { ...PLAYER_REPORT },
  REFER_FRIEND: { ...REFER_FRIEND },
  REWARD: { ...REWARD },
  RULES_ENGINE: { ...RULES_ENGINE },
  SUMMARY: { ...SUMMARY },
  TAGS: { ...TAGS },
  TELEDATA: { ...TELEDATA },
  USER: { ...USER },
  USER_LOGS: { ...USER_LOGS },
  WALLET: { ...WALLET },
  ERROR: 'exception',
  BROADCAST: 'broadcast',
}
