/**
 *
 * Missions constants
 *
 */

enum ActionTypes {
  SET_PAGINATION = '@missions/SET_PAGINATION',
  SET_FILTERS = '@missions/SET_FILTERS',
}

export default ActionTypes
