enum CampaignStatusType {
  ACTIVE = 1,
  SCHEDULED = 2,
  ENDED = 3,
  PAUSED = 4,
  PENDING = 5,
  SENT = 6,
  ABORTED = 7,
  ERROR = 8,
  ARCHIVED = 9,
  INDESIGN = 10,
}

export default CampaignStatusType
