const responsibleGaming = {
  responsibleGaming: 'Resp. Gaming',
  gamingLimits: 'Gaming Limits',
  coolOff: 'Cool Off',
  selfExclusion: 'Self Exclusion',
  selfAssessment: 'Self Assessment',
  limitCategories: 'Limit Categories',
  dateCreated: 'Date Created',
  validUntil: 'Valid Until',
  addCoolOff: 'Add Cool Off',
  addNewLimit: 'Add New Limit',
  editLimit: 'Edit Limit',
  addSelfExclusion: 'Add Self Exclusion',
  limitType: 'Limit Type',
  period: 'Period',
  activeAmount: 'Active Amount',
  usedAmount: 'Used Amount',
  futureAmount: 'Future Amount',
  save: 'Save',
  chooseDate: 'Choose Date',
  monthlyNetLoss: 'Monthly Net Loss',
  netLoss: 'Net Loss',
  dailyHoursPlayLoginSession: 'Daily Hours Play & Login Session',
  netUsed: 'Net Used',
  netLeft: 'Net Left',
  incoming: 'Incoming',
  setMaxLimit: 'Set Max Limit',
  maxLimit: 'Max Limit',
  playerLimit: 'Player Limit',
  activeLimit: 'Active Limit',
  limit: 'limit',
  daily: 'Daily',
  dateTaken: 'Date Assessment Taken',
  rgRiskLevel: 'Risk Level',
  score: 'Assessment Score',
  assessmentAnswers: 'Self Assessment Answers',
  selectCategories: 'Select Categories',
  validFrom: 'Valid From',
  limitStatus: {
    APPROVED: 'APPROVED',
    PENDING_APPROVAL: 'PDN APPRVL',
    DAY_APPROVAL: '24H APPRVL',
  },
  limitTypes: {
    NET_LOSS: 'Net Loss',
    WAGER: 'Wager',
    DEPOSIT: 'Deposit',
    WIN: 'Win',
    LOGIN_SESSION: 'Login Session',
    PLAY_TIME: 'Play Time',
  },
  limitUnits: {
    MINS: 'Minutes',
    HOURS: 'Hours',
    DAYS: 'Days',
    MONTHS: 'Months',
    YEARS: 'Years',
    SESSIONS: 'Sessions',
    SESSION: 'Session',
  },
  limitPeriods: {
    HOURLY: 'Hourly',
    HOURLIES: 'Hourlies',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    WEEKLIES: 'Weeklies',
    MONTHLY: 'Monthly',
    MONTHLIES: 'Monthlies',
    QUARTERLY: 'Quarterly',
    QUARTERLIES: 'Quarterlies',
    ANNUAL: 'Annual',
    ANNUALS: 'Annuals',
    TOTAL: 'Total',
    TOTALS: 'Totals',
  },
  limitUnit: {
    MIN: 'Minute',
    HOUR: 'Hour',
    DAY: 'Day',
    MONTH: 'Month',
    YEAR: 'Year',
  },
  assessmentRiskLevel: {
    UNDEFINED: 'UNDEFINED',
    LOW: 'LOW',
    NORMAL: 'NORMAL',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
    SEVERE: 'SEVERE',
  },
  gameSessions: 'Game Sessions',
  gameTransactions: 'Game Transactions',
  backToSessions: 'Back to sessions',
  maxLimitSuccessfullySet: 'New max limit has been successfully set',
  newPlayerLimitRejected: 'New player limit request has been rejected',
  netLossChangeRejected: 'NetLoss change has been rejected',
  limitRequest: 'new limit request',
  coolOffAddedSuccessfully: 'The cool off has been added successfully',
  coolOffDeletedSuccessfully: 'The cool off has been deleted successfully',
  limitPlayerAddedSuccessfully: 'The limit has been added successfully',
  selectDate: 'Select date',
  otherDate: 'Other date',
  theLimitWillBeAppliedUntil: 'The limit will be applied until',
  maxLimitChangeComment: 'The max limit was changed to {{newLimit}}',
  maxLimitChangeCommentOperator:
    '{{currentOperator}} changed max limit to {{newLimit}}',
  newMaxLimitSet: 'New max limit set',
  thisLimitIsMandatoryCantBeRemoved:
    'This limit is mandatory. Cannot be removed!',
  limitWillBeApplied: 'This future amount will be applied on',
  thisLimitWillBeDeletedOn: 'This limit will be deleted on',
  netlossLimitRequired:
    'A Net Loss limit is required, therefore it cannot be deleted.',
}
export default responsibleGaming
