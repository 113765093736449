/**
 *
 * PlayerCardKYC constants
 *
 */

enum ActionTypes {
  GET_DOCUMENTS = '@pcard-kyc/GET_DOCUMENTS',
  GET_DOCUMENTS_SUBSCRIBER = '@pcard-kyc/GET_DOCUMENTS_SUBSCRIBER',
  GET_DOCUMENTS_EXCEPTION = '@pcard-kyc/GET_DOCUMENTS_EXCEPTION',

  GET_DOCUMENT_IMAGES_REQUEST = '@pcard-kyc/GET_DOCUMENT_IMAGES_REQUEST',
  GET_DOCUMENT_IMAGES_SUCCESS = '@pcard-kyc/GET_DOCUMENT_IMAGES_SUCCESS',
  GET_DOCUMENT_IMAGES_FAILURE = '@pcard-kyc/GET_DOCUMENT_IMAGES_FAILURE',

  UPLOAD_DOCUMENT_REQUEST = '@pcard-kyc/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS = '@pcard-kyc/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAILURE = '@pcard-kyc/UPLOAD_DOCUMENT_FAILURE',

  DOWNLOAD_DOCUMENT_IMAGE_REQUEST = '@pcard-kyc/DOWNLOAD_DOCUMENT_IMAGE_REQUEST',
  DOWNLOAD_DOCUMENT_IMAGE_SUCCESS = '@pcard-kyc/DOWNLOAD_DOCUMENT_IMAGE_SUCCESS',
  DOWNLOAD_DOCUMENT_IMAGE_FAILURE = '@pcard-kyc/DOWNLOAD_DOCUMENT_IMAGE_FAILURE',

  ADD_DOCUMENT = '@pcard-kyc/ADD_DOCUMENT',
  ADD_DOCUMENT_SUBSCRIBER = '@pcard-kyc/ADD_DOCUMENT_SUBSCRIBER',

  UPDATE_DOCUMENT = '@pcard-kyc/UPDATE_DOCUMENT',
  UPDATE_DOCUMENT_SUBSCRIBER = '@pcard-kyc/UPDATE_DOCUMENT_SUBSCRIBER',
  UPDATE_DOCUMENT_EXCEPTION = '@pcard-kyc/UPDATE_DOCUMENT_EXCEPTION',

  DELETE_DOCUMENT = '@pcard-kyc/DELETE_DOCUMENT',
  DELETE_DOCUMENT_SUBSCRIBER = '@pcard-kyc/DELETE_DOCUMENT_SUBSCRIBER',
  DELETE_DOCUMENT_EXCEPTION = '@pcard-kyc/DELETE_DOCUMENT_EXCEPTION',

  CRIF_CHECK = '@pcard-kyc/CRIF_CHECK',
  CRIF_CHECK_SUBSCRIBER = '@pcard-kyc/CRIF_CHECK_SUBSCRIBER',

  MANUAL_CHECK_BLACKLIST = '@pcard-kyc/MANUAL_CHECK_BLACKLIST',
  MANUAL_CHECK_BLACKLIST_SUBSCRIBER = '@pcard-kyc/MANUAL_CHECK_BLACKLIST_SUBSCRIBER',

  GET_NON_PEP_HISTORY = '@pcard-kyc/GET_NON_PEP_HISTORY',
  GET_NON_PEP_HISTORY_SUBSCRIBER = '@pcard-kyc/GET_NON_PEP_HISTORY_SUBSCRIBER',

  GET_NON_PEP_HISTORY_COUNT_ISSUES = '@pcard-kyc/GET_NON_PEP_HISTORY_COUNT_ISSUES',
  GET_NON_PEP_HISTORY_COUNT_ISSUES_SUBSCRIBER = '@pcard-kyc/GET_NON_PEP_HISTORY_COUNT_ISSUES_SUBSCRIBER',

  GET_VETO_HISTORY = '@pcard-kyc/GET_VETO_HISTORY',
  GET_VETO_HISTORY_SUBSCRIBER = '@pcard-kyc/GET_VETO_HISTORY_SUBSCRIBER',

  GET_TELEDATA_HISTORY = '@pcard-kyc/GET_TELEDATA_HISTORY',
  GET_TELEDATA_HISTORY_SUBSCRIBER = '@pcard-kyc/GET_TELEDATA_HISTORY_SUBSCRIBER',

  CREATE_TELEDATA = '@pcard-kyc/UPDATE_TELEDATA',
  CREATE_TELEDATA_SUBSCRIBER = '@pcard-kyc/UPDATE_TELEDATA_SUBSCRIBER',
  CREATE_TELEDATA_ERROR = '@pcard-kyc/CREATE_TELEDATA_ERROR',

  UPDATE_KYC_STATUS = '@pcard-kyc/UPDATE_KYC_STATUS',
  UPDATE_KYC_STATUS_SUBSCRIBER = '@pcard-kyc/UPDATE_KYC_STATUS_SUBSCRIBER',
  UPDATE_KYC_STATUS_EXCEPTION = '@pcard-kyc/UPDATE_KYC_STATUS_EXCEPTION',

  GET_KYC_STATUS = '@pcard-kyc/GET_KYC_STATUS',
  GET_KYC_STATUS_SUBSCRIBER = '@pcard-kyc/GET_KYC_STATUS_SUBSCRIBER',
  GET_KYC_STATUS_EXCEPTION = '@pcard-kyc/GET_KYC_STATUS_EXCEPTION',

  UPDATE_AML_STATUS = '@pcard-kyc/UPDATE_AML_STATUS',
  UPDATE_AML_STATUS_SUBSCRIBER = '@pcard-kyc/UPDATE_AML_STATUS_SUBSCRIBER',

  AML_GET_ANSWERS = '@pcard-kyc/AML_GET_ANSWERS',
  AML_GET_ANSWERS_SUBSCRIBER = '@pcard-kyc/AML_GET_ANSWERS_SUBSCRIBER',

  AML_SET_ANSWERS = '@pcard-kyc/AML_SET_ANSWERS',
  AML_SET_ANSWERS_SUBSCRIBER = '@pcard-kyc/AML_SET_ANSWERS_SUBSCRIBER',

  AML_SET_STATUS = '@pcard-kyc/AML_SET_STATUS',
  AML_SET_STATUS_SUBSCRIBER = '@pcard-kyc/AML_SET_STATUS_SUBSCRIBER',

  AML_GET_STATUS = '@pcard-kyc/AML_GET_STATUS',
  AML_GET_STATUS_SUBSCRIBER = '@pcard-kyc/AML_GET_STATUS_SUBSCRIBER',

  GET_JUMIO_DATA_REQUEST = '@pcard-kyc/GET_JUMIO_DATA_REQUEST',
  GET_JUMIO_DATA_SUCCESS = '@pcard-kyc/GET_JUMIO_DATA_SUCCESS',
  GET_JUMIO_DATA_FAILURE = '@pcard-kyc/GET_JUMIO_DATA_FAILURE',

  CREATE_DOCUMENT = '@pcard-kyc/CREATE_DOCUMENT',
  CREATE_DOCUMENT_SUBSCRIBER = '@pcard-kyc/CREATE_DOCUMENT_SUBSCRIBER',
  CREATE_DOCUMENT_EXCEPTION = '@pcard-kyc/CREATE_DOCUMENT_EXCEPTION',

  SET_CURRENT_IMAGE_URL = '@pcard-kyc/SET_CURRENT_IMAGE_URL',
  SET_CURRENT_SECOND_IMAGE_URL = '@pcard-kyc/SET_CURRENT_SECOND_IMAGE_URL',
  SET_DOCUMENT_FORMAT = '@pcard-kyc/SET_DOCUMENT_FORMAT',
  RESET_DOC_STATE = '@pcard-kyc/RESET_DOC_STATE',

  UPLOAD_UTILITY_DOCUMENT = '@pcard-kyc/UPLOAD_UTILITY_DOCUMENT',
  UPLOAD_UTILITY_DOCUMENT_SUBSCRIBER = '@pcard-kyc/UPLOAD_UTILITY_DOCUMENT_SUBSCRIBER',

  CREATE_UTILITY_DOCUMENT = '@pcard-kyc/CREATE_UTILITY_DOCUMENT',
  CREATE_UTILITY_DOCUMENT_SUBSCRIBER = '@pcard-kyc/CREATE_UTILITY_DOCUMENT_SUBSCRIBER',

  SET_JUMIO_STATUS = '@pcard-kyc/SET_JUMIO_STATUS',
  SET_JUMIO_INFO = '@pcard-kyc/SET_JUMIO_INFO',
  TOGGLE_LOADING = '@pcard-kyc/TOGGLE_LOADING',

  UPDATE_PEP_STATUS = '@pcard-kyc/UPDATE_PEP_STATUS',
  UPDATE_PEP_STATUS_SUBSCRIBER = '@pcard-kyc/UPDATE_PEP_STATUS_SUBSCRIBER',
  RESET_TELEDATA_HISTORY = '@pcard-kyc/RESET_TELEDATA_HISTORY',

  GET_PLAYER_TELEDATA_HISTORY = '@pcard-kyc/GET_PLAYER_TELEDATA_HISTORY',
  GET_PLAYER_TELEDATA_HISTORY_SUBSCRIBER = '@pcard-kyc/GET_PLAYER_TELEDATA_HISTORY_SUBSCRIBER',

  SET_DOCUMENT_FILTERS = '@pcard-kyc/SET_DOCUMENT_FILTERS',

  GET_ALERT_KYC_DOCS = '@pcard-kyc/GET_ALERT_KYC_DOCS',
  GET_ALERT_KYC_DOCS_SUBSCRIBER = '@pcard-kyc/GET_ALERT_KYC_DOCS_SUBSCRIBER',
  SET_CURRENT_DOWNLOADED_IMAGE = '@pcard-kyc/SET_CURRENT_DOWNLOADED_IMAGE',

  GET_PLAYER_CASES = '@pcard-kyc/GET_PLAYER_CASES',
  GET_PLAYER_CASES_SUBSCRIBER = '@pcard-kyc/GET_PLAYER_CASES_SUBSCRIBER',

  SET_UBIID_STATUS = '@pcard-kyc/SET_UBIID_STATUS',
  SET_UBIID_INFO = '@pcard-kyc/SET_UBIID_INFO',

  GET_FAILED_INTEGRITY_CHECK = '@pcard-kyc/GET_FAILED_INTEGRITY_CHECK',
  GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER = '@pcard-kyc/GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER',

  GET_CASE_FILES = '@pcard-kyc/GET_CASE_FILES',
  GET_CASE_FILES_SUBSCRIBER = '@pcard-kyc/GET_CASE_FILES_SUBSCRIBER',

  FAILED_INTEGRITY_CHECK_UPDATE = '@pcard-kyc/FAILED_INTEGRITY_CHECK_UPDATE',
  FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER = '@pcard-kyc/FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER',

  FAILED_INTEGRITY_CHECK_REJECT = '@pcard-kyc/FAILED_INTEGRITY_CHECK_REJECT',
  FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER = '@pcard-kyc/FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER',
  SET_CURRENT_DOWNLOADED_SECOND_IMAGE = '@pcard-kyc/SET_CURRENT_DOWNLOADED_SECOND_IMAGE',

  GET_PLAYER_IBANS = '@pcard-kyc/GET_PLAYER_IBANS',
  GET_PLAYER_IBANS_SUBSCRIBER = '@pcard-kyc/GET_PLAYER_IBANS_SUBSCRIBER',
  GET_PLAYER_IBAN_DOCUMENT = '@pcard-kyc/GET_PLAYER_IBAN_DOCUMENT',
  GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER = '@pcard-kyc/GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER',
  SET_IBAN_REJECTED = '@pcard-kyc/SET_IBAN_REJECTED',
  SET_IBAN_REJECTED_SUBSCRIBER = '@pcard-kyc/SET_IBAN_REJECTED_SUBSCRIBER',
  SET_IBAN_VERIFIED = '@pcard-kyc/SET_IBAN_VERIFIED',
  SET_IBAN_VERIFIED_SUBSCRIBER = '@pcard-kyc/SET_IBAN_VERIFIED_SUBSCRIBER',

  SET_PLAYER_BANK_ACCOUNT_INFO = '@pcard-kyc/SET_PLAYER_BANK_ACCOUNT_INFO',

  DOCUMENT_VERIFY_UPDATE = '@pcard-kyc/DOCUMENT_VERIFY_UPDATE',
  DOCUMENT_VERIFY_UPDATE_SUBSCRIBER = '@pcard-kyc/DOCUMENT_VERIFY_UPDATE_SUBSCRIBER',

  UTILITY_VERIFY_UPDATE = '@pcard-kyc/UTILITY_VERIFY_UPDATE',
  UTILITY_VERIFY_UPDATE_SUBSCRIBER = '@pcard-kyc/UTILITY_VERIFY_UPDATE_SUBSCRIBER',

  ADDRESS_VERIFY_UPDATE = '@pcard-kyc/ADDRESS_VERIFY_UPDATE',
  ADDRESS_VERIFY_UPDATE_SUBSCRIBER = '@pcard-kyc/ADDRESS_VERIFY_UPDATE_SUBSCRIBER',

  DOCUMENT_DATA_INSERT = '@pcard-kyc/DOCUMENT_DATA_INSERT',
  DOCUMENT_DATA_INSERT_SUBSCRIBER = '@pcard-kyc/DOCUMENT_DATA_INSERT_SUBSCRIBER',
}

export default ActionTypes
