import { createAsyncAction, createAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import { TemplatePushModels } from '..'

export const createTemplatePushAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<
  {
    payload: TemplatePushModels.CreateTemplatePushPayload
    sideEffect: any
  },
  TemplatePushModels.TemplatePushType,
  Error
>()

export const updateTemplatePushAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<
  TemplatePushModels.UpdateTemplatePushPayload,
  TemplatePushModels.TemplatePushType,
  Error
>()

export const getAllTemplatePushAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<
  TemplatePushModels.GetAllTemplatePushPayload,
  TemplatePushModels.GetAllTemplatePushResponse,
  Error
>()

export const getOneTemplatePushAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<
  TemplatePushModels.GetOneTemplatePushPayload,
  TemplatePushModels.TemplatePushType,
  Error
>()

export const archiveTemplatePushAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<
  TemplatePushModels.ArchiveTemplatePushPayload,
  TemplatePushModels.TemplatePushType,
  Error
>()

export const duplicateTemplatePushAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<
  TemplatePushModels.DuplicateTemplatePushPayload,
  TemplatePushModels.TemplatePushType,
  Error
>()

export const setTemplatePushFiltersAction = createAction(
  ActionTypes.SET_FILTERS,
  action => {
    return (filters: Partial<TemplatePushModels.TemplatePushFilterProps>) =>
      action(filters)
  },
)
