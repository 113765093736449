import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const getAllUserHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: action.payload?.docs || [],
        allUsers: state.allUsers.length ? action.payload.docs : state.allUsers,
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    default:
      return state
  }
}

export default getAllUserHandler
