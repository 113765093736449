import { colors } from './colors.theme'

const { blueModal } = colors

export const dialogTheme = {
  paper: {
    backgroundColor: blueModal,
    '&.MuiDialog-paperWidthXxs': {
      maxWidth: 300,
    },
    '&.MuiDialog-paperWidthXsm': {
      maxWidth: 450,
    },
  },
}

export const dialogActionsTheme = {
  root: {
    padding: '16px 24px 24px',
  },
}
