import { select, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { Container } from 'typedi'

import i18n from 'i18n'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import {
  setFormModeAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import FormStatusType from 'state/enums/form-status.enum'
import { createTemplatePushAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga creates a push template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createTemplatePushAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createTemplatePushWorker(
  action: ReturnType<typeof createTemplatePushAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/push/create',
      body: action.payload.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    if (response._id) {
      yield put(createTemplatePushAction.success(response))

      yield put(setFormModeAction(FormStatusType.EDIT))

      yield put(push(`${routes.templates.EDIT_PUSH}/${response._id}`))

      yield put(action.payload.sideEffect())
    } else {
      yield put(
        createTemplatePushAction.failure({
          name: response.status,
          message: response.message,
        }),
      )

      yield put(
        setSnackbarAction({
          message:
            typeof response.message === 'string'
              ? response.message
              : i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createTemplatePushAction.failure(error))
  }
}

export default createTemplatePushWorker
