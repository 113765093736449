import { USER_NAMESPACE } from './user/socket.routes'
import { ADMIN_LEGACY_NAMESPACE } from './wallet/socket.routes'
import {
  PLAYER_SEARCH_NAMESPACE,
  ADMIN_LOGS_NAMESPACE,
} from './player-card/socket.routes'

import { NOTES_NAMESPACE } from './player-card-summary/socket.routes'
import { USER_LOGS_NAMESPACE } from './user-logs/socket.routes'

export default {
  ADMIN_LEGACY: ADMIN_LEGACY_NAMESPACE,
  ADMIN_LOGS: ADMIN_LOGS_NAMESPACE,
  ADMIN_MODULE: 'admin-module',
  CATEGORY: 'category',
  NOTES: NOTES_NAMESPACE,
  PLAYER_SEARCH: PLAYER_SEARCH_NAMESPACE,
  RULES_ENGINE: 'rulesengine',
  TAGS: 'tags',
  TAGS_HISTORIC: 'historic',
  USER: USER_NAMESPACE,
  USER_LOGS: USER_LOGS_NAMESPACE,
  VISITS: 'visits',
  WALLET: 'wallet',
}
