import { MediaDatabaseState } from '../models'

const initialState: MediaDatabaseState = {
  hasError: false,
  isLoading: false,
  error: '',
  wasDeleted: false,
  storage: {
    prefixes: [],
    items: [],
    nextPageToken: '',
  },
  files: [],
  activeFolder: '',
  upload: {
    fileName: '',
    uploadValue: 0,
  },
}

export default initialState
