/**
 *
 * Issue constants
 *
 */

enum ActionTypes {
  GET_ALL_ISSUES = '@issue/GET_ALL_ISSUES',
  GET_ALL_ISSUES_SUBSCRIBER = '@issue/GET_ALL_ISSUES_SUBSCRIBER',

  GET_ONE_ISSUE = '@issue/GET_ONE_ISSUE',
  GET_ONE_ISSUE_SUBSCRIBER = '@issue/GET_ONE_ISSUE_SUBSCRIBER',

  GET_ISSUES_COUNT = '@issue/GET_ISSUES_COUNT',
  GET_ISSUES_COUNT_SUBSCRIBER = '@issue/GET_ISSUES_COUNT_SUBSCRIBER',

  CREATE_ISSUE = '@issue/CREATE_ISSUE',
  CREATE_ISSUE_SUBSCRIBER = '@issue/CREATE_ISSUE_SUBSCRIBER',

  UPDATE_ISSUE = '@issue/UPDATE_ISSUE',
  UPDATE_ISSUE_SUBSCRIBER = '@issue/Update_ISSUE_SUBSCRIBER',

  UPDATE_ISSUE_SIDE_EFFECT = '@issue/UPDATE_ISSUE_SIDE_EFFECT',
  UPDATE_ISSUE_SIDE_EFFECT_SUBSCRIBER = '@issue/UPDATE_ISSUE_SIDE_EFFECT_SUBSCRIBER',

  SET_ISSUE_FILTERS = '@issue/SET_ISSUE_FILTERS',
  SET_ALL_ISSUE_FILTERS = '@issue/SET_ALL_ISSUE_FILTERS',
  SET_ISSUE_DIALOG = '@issue/SET_ISSUE_DIALOG',
  SET_CURRENT_ISSUE = '@issue/SET_CURRENT_ISSUE',

  ISSUE_RESET = '@issue/ISSUE_RESET',

  SET_PAGE = '@issue/SET_PAGE',
  SET_ROWS_PER_PAGE = '@issue/SET_ROWS_PER_PAGE',

  TOGGLE_NOTIFICATIONS = '@issue/TOGGLE_NOTIFICATIONS',
  CLEAR_ALERTS = '@issue/CLEAR_ALERTS',

  SET_ISSUES_COUNT_BY_PLAYER = '@issue/SET_ISSUES_COUNT_BY_PLAYER',

  DOWNLOAD_DOCUMENT_IMAGE_REQUEST = '@issue/DOWNLOAD_DOCUMENT_IMAGE_REQUEST',
  DOWNLOAD_DOCUMENT_IMAGE_SUCCESS = '@issue/DOWNLOAD_DOCUMENT_IMAGE_SUCCESS',
  DOWNLOAD_DOCUMENT_IMAGE_FAILURE = '@issue/DOWNLOAD_DOCUMENT_IMAGE_FAILURE',

  DOWNLOAD_DOCUMENT_SECOND_IMAGE_REQUEST = '@issue/DOWNLOAD_DOCUMENT_SECOND_IMAGE_REQUEST',
  DOWNLOAD_DOCUMENT_SECOND_IMAGE_SUCCESS = '@issue/DOWNLOAD_DOCUMENT_SECOND_IMAGE_SUCCESS',
  DOWNLOAD_DOCUMENT_SECOND_IMAGE_FAILURE = '@issue/DOWNLOAD_DOCUMENT_SECOND_IMAGE_FAILURE',

  DOWNLOAD_IMAGE_REQUEST = '@issue/DOWNLOAD_IMAGE_REQUEST',
  DOWNLOAD_IMAGE_SUCCESS = '@issue/DOWNLOAD_IMAGE_SUCCESS',
  DOWNLOAD_IMAGE_FAILURE = '@issue/DOWNLOAD_IMAGE_FAILURE',

  DOWNLOAD_DOCUMENT_IMAGE_CLEAR = '@issue/DOWNLOAD_DOCUMENT_IMAGE_CLEAR',

  CREATE_ADMIN_ISSUE = '@issue/CREATE_ADMIN_ISSUE',
  CREATE_ADMIN_ISSUE_SUBSCRIBER = '@issue/CREATE_ADMIN_ISSUE_SUBSCRIBER',

  GET_DOCUMENT_IMAGES_REQUEST = '@issue/GET_DOCUMENT_IMAGES_REQUEST',
  GET_DOCUMENT_IMAGES_SUCCESS = '@issue/GET_DOCUMENT_IMAGES_SUCCESS',
  GET_DOCUMENT_IMAGES_FAILURE = '@issue/GET_DOCUMENT_IMAGES_FAILURE',

  GET_CLIENT_ACTIVE_ALERTS = '@issue/GET_CLIENT_ACTIVE_ALERTS',
  GET_CLIENT_ACTIVE_ALERTS_SUBSCRIBER = '@issue/GET_CLIENT_ACTIVE_ALERTS_SUBSCRIBER',

  SET_LOADING = '@issue/SET_LOADING',

  UPDATE_ISSUES_COUNT_SUBSCRIBER = '@issue/UPDATE_ISSUES_COUNT_SUBSCRIBER',

  SET_ISSUE_ID_FILTER = '@issue/SET_ISSUE_ID_FILTER',
}

export default ActionTypes
