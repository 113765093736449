import { Tabs, Theme } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const tabsTheme = {
  root: {
    minHeight: 'auto',
    alignItems: 'center',
    '& .with-padding': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
  vertical: {
    '& .MuiTab-root': {
      opacity: '1!important',
      margin: '4px 0',
      padding: '4px 0',
      maxWidth: 'none',
    },
  },
}

export const TabsIcon = withStyles((theme: Theme) => ({
  indicator: {
    display: 'none',
  },
}))(Tabs)
