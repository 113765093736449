import { LevelsState } from '../models'

const initialState: LevelsState = {
  isLoading: false,
  isLoadingFull: false,
  hasError: false,
  wasDeleted: false,
  error: '',
  levels: [],
  currentLevel: {
    id: 0,
    enabled: false,
    levelType: 1,
    name: '',
    description: '',
    hierarchyNo: 0,
    gameCategoryModifiers: [],
  },
  pagination: {
    page: 1,
    pages: 1,
    limit: 25,
    total: 0,
  },
  filters: {
    filterParams: {},
    paginationParams: { page: 1 },
  },
}

export default initialState
