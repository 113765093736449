import { action, createAction, createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  GetOneAnalyticGeneralResponse,
  GetChannelActivityResponse,
  GetOneAnalyticGeneralPayload,
  GetChannelActivityPayload,
  GetAllAnalyticsPayload,
  GetAllAnalyticResponse,
  GetCampaignDatesResponse,
  GetCampaignDatesPayload,
  GetCampaignOneDateResponse,
  GetCampaignOneDatePayload,
  GetChannelEventsPayload,
  GetChannelEventsResponse,
  GetOneAnalyticGroupPayload,
  GetOneAnalyticGroupResponse,
  TargetsGroup,
  GetCampaignsByPlayerPayload,
  GetCampaignsByPlayerResponse,
  GetEventsDetailPayload,
  GetTemplatePreviewPayload,
  GetTemplatePreviewResponse,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const getAllAnalyticsAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<GetAllAnalyticsPayload, GetAllAnalyticResponse, Error>()

export const getOneAnalyticsAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<GetOneAnalyticGeneralPayload, GetOneAnalyticGeneralResponse, Error>()

export const getOneAnalyticsGroupAction = createAsyncAction(
  ActionTypes.GET_ONE_GROUP_REQUEST,
  ActionTypes.GET_ONE_GROUP_SUCCESS,
  ActionTypes.GET_ONE_GROUP_FAILURE,
)<GetOneAnalyticGroupPayload, GetOneAnalyticGroupResponse, Error>()

export const getChannelActivityAction = createAsyncAction(
  ActionTypes.GET_CHANNEL_ACTIVITY_REQUEST,
  ActionTypes.GET_CHANNEL_ACTIVITY_SUCCESS,
  ActionTypes.GET_CHANNEL_ACTIVITY_FAILURE,
)<GetChannelActivityPayload, GetChannelActivityResponse, Error>()

export const getAllChannelActivityAction = createAsyncAction(
  ActionTypes.GET_ALL_CHANNEL_ACTIVITY_REQUEST,
  ActionTypes.GET_ALL_CHANNEL_ACTIVITY_SUCCESS,
  ActionTypes.GET_ALL_CHANNEL_ACTIVITY_FAILURE,
)<GetChannelActivityPayload, GetChannelActivityResponse, Error>()

export const getChannelEventsAction = createAsyncAction(
  ActionTypes.GET_CHANNEL_EVENTS_REQUEST,
  ActionTypes.GET_CHANNEL_EVENTS_SUCCESS,
  ActionTypes.GET_CHANNEL_EVENTS_FAILURE,
)<GetChannelEventsPayload, GetChannelEventsResponse, Error>()

export const getEventsDetailAction = createAsyncAction(
  ActionTypes.GET_EVENTS_DETAIL_REQUEST,
  ActionTypes.GET_EVENTS_DETAIL_SUCCESS,
  ActionTypes.GET_EVENTS_DETAIL_FAILURE,
)<GetEventsDetailPayload, GetChannelEventsResponse, Error>()

export const getCampaignDateAction = createAsyncAction(
  ActionTypes.GET_CAMPAIGN_DATE_ANALYTIC_REQUEST,
  ActionTypes.GET_CAMPAIGN_DATE_ANALYTIC_SUCCESS,
  ActionTypes.GET_CAMPAIGN_DATE_ANALYTIC_FAILURE,
)<GetCampaignOneDatePayload, GetCampaignOneDateResponse, Error>()

export const getCampaignDatesAction = createAsyncAction(
  ActionTypes.GET_CAMPAIGN_DATES_REQUEST,
  ActionTypes.GET_CAMPAIGN_DATES_SUCCESS,
  ActionTypes.GET_CAMPAIGN_DATES_FAILURE,
)<GetCampaignDatesPayload, GetCampaignDatesResponse, Error | null>()

export const getCampaignsByPlayerAction = createAsyncAction(
  ActionTypes.GET_CAMPAIGNS_BY_PLAYER_REQUEST,
  ActionTypes.GET_CAMPAIGNS_BY_PLAYER_SUCCESS,
  ActionTypes.GET_CAMPAIGNS_BY_PLAYER_FAILURE,
)<GetCampaignsByPlayerPayload, GetCampaignsByPlayerResponse, Error | null>()

export const getTemplatePreviewAction = createAsyncAction(
  ActionTypes.GET_TEMPLATE_PREVIEW_REQUEST,
  ActionTypes.GET_TEMPLATE_PREVIEW_SUCCESS,
  ActionTypes.GET_TEMPLATE_PREVIEW_FAILURE,
)<GetTemplatePreviewPayload, GetTemplatePreviewResponse, Error>()

export const resetAnalyticsAction = () => action(ActionTypes.RESET_ANALYTICS)

export const resetAnalyticAction = () => action(ActionTypes.RESET_ANALYTIC)

export const setDownloadAnalyticEventsAction = createAction(
  ActionTypes.SET_DOWNLOAD_ANALYTIC_EVENTS,
  downloadAction => {
    return (isDownloading: boolean) => downloadAction(isDownloading)
  },
)

export const setFilterAnalyticAction = createAction(
  ActionTypes.SET_FILTERS,
  actions => {
    return (filters: GetAllAnalyticsPayload) => actions(filters)
  },
)

export const setFilterCampaignByPlayerAction = createAction(
  ActionTypes.SET_FILTERS_CAMPAIGN_BY_PLAYER,
  campaignByPlayerAction => {
    return (filters: GetCampaignsByPlayerPayload) =>
      campaignByPlayerAction(filters)
  },
)

export const setFilterTableAction = createAction(
  ActionTypes.SET_IS_TABLE,
  tableAction => {
    return (isTableLoading: boolean) => tableAction(isTableLoading)
  },
)

export const setFilterAnalyticEventsAction = createAction(
  ActionTypes.SET_FILTERS_EVENTS,
  eventAction => {
    return (filters: GetChannelActivityPayload) => eventAction(filters)
  },
)

export const setTargetsEventsAction = createAction(
  ActionTypes.SET_TARGETS_GROUP,
  targetAction => {
    return (targets: TargetsGroup[]) => targetAction(targets)
  },
)
