import { AnalyticsState, AnalyticActions } from '../models'
import ActionTypes from '../constants'

const getCampaignDatesHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_CAMPAIGN_DATES_REQUEST:
      return {
        ...state,
        isLoading: state.pagination.page === 0,
        isLoadingPartial: state.pagination.page > 0,
      }

    case ActionTypes.GET_CAMPAIGN_DATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
      }

    case ActionTypes.GET_CAMPAIGN_DATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
      }
    default:
      return state
  }
}

export default getCampaignDatesHandler
