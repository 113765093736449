import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import EN_US from './translations/en-us/index.translation'

/**
 * This is the list of the different languages that are imported
 */
const resources = {
  EN_US,
}

/**
 * Here is where the initialization of the language is set
 */
i18n.use(initReactI18next).init({
  resources,
  lng: 'EN_US',
})

export default i18n
