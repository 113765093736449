import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'

const channelActivityAllHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CHANNEL_ACTIVITY_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.GET_ALL_CHANNEL_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        totalChannelActivity: action?.payload?.docs || [],
      }

    case ActionTypes.GET_ALL_CHANNEL_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default channelActivityAllHandler
