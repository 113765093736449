import { GameStoreActions, GameStoreState } from '../models'
import ActionTypes from '../constants'

const getGameListHandler = (
  state: GameStoreState,
  action: GameStoreActions,
): GameStoreState => {
  switch (action.type) {
    case ActionTypes.GET_GAME_LIST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_GAME_LIST_SUBSCRIBER:
      return {
        ...state,
        gameList: action?.payload?.gameList || [],
        pagination: {
          total: action?.payload?.totalItems,
          limit: action?.payload?.currentLimit,
          page: action?.payload?.currentPage,
          pages: action?.payload?.totalPages,
        },
        isLoading: false,
      }

    default:
      return state
  }
}

export default getGameListHandler
