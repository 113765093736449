import ActionTypes from '../constants'
import {
  PlayerCardState,
  PlayerSocketActions,
  PlayerCardActions,
} from '../models'
import { playerTestData } from './initial-state'

const playerHandler = (
  state: PlayerCardState,
  action: PlayerSocketActions | PlayerCardActions,
): PlayerCardState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER:
      return {
        ...state,
      }

    case ActionTypes.GET_PLAYER_SUBSCRIBER:
      return {
        ...state,
        player: action.payload.message || { ...playerTestData },
        hasError: !action.payload.resolved,
      }

    case ActionTypes.GET_PLAYERS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_PLAYERS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        players: action.payload.docs || [],
        pagination: {
          total: action.payload.total || 0,
          limit: action.payload.limit || 10,
          page: action.payload.page || 1,
          pages: action.payload.pages || 0,
        },
      }

    case ActionTypes.GET_PLAYERS_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        players: [],
        pagination: {
          total: 0,
          limit: 10,
          page: 1,
          pages: 0,
        },
      }

    case ActionTypes.SET_PLAYER_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }

    case ActionTypes.RESET_PLAYER_INFO:
      return {
        ...state,
        player: playerTestData,
        hasError: false,
      }

    case ActionTypes.SET_PLAYER_PAGINATION: {
      return {
        ...state,
        pagination: {
          total: action?.payload?.total,
          limit: action?.payload?.limit,
          page: action?.payload?.page,
          pages: action?.payload?.pages,
        },
      }
    }

    case ActionTypes.CRIF_CHECK_ADDRESS_SUBSCRIBER: {
      return {
        ...state,
        crifCheckAddress: action.payload?.message || null,
      }
    }

    case ActionTypes.GET_PLAYER_ISSUE_COUNT_SUBSCRIBER: {
      return {
        ...state,
        issuesCount: action.payload?.message || [],
      }
    }

    default:
      return state
  }
}

export default playerHandler
