import ActionTypes from '../constants'
import { PlayerCardTagActions, PlayerCardTagState } from '../models'

const setTagsHandler = (
  state: PlayerCardTagState,
  action: PlayerCardTagActions,
): PlayerCardTagState => {
  switch (action.type) {
    case ActionTypes.SET_TAGS:
      return { ...state, tags: action.payload }

    case ActionTypes.SET_TAG_CATEGORIES:
      return { ...state, categories: action.payload }

    default:
      return state
  }
}

export default setTagsHandler
