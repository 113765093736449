export const REGEX_EMAIL = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailLength = /^\S{1,64}@\S{1,66}$/

export const REGEX_ONLY_TEXT = /^[a-zA-Z\u00C0-\u017F/\s/g]+$/

export const onlyTextAndNumbersRegex = /^[a-zA-Z0-9 ]*$/

export const nonEmpty = /(.|\s)*\S(.|\s)*/

export const usernameOrEmail = /((?=[A-Za-z0-9_@./#&+-\u00C0-\u017F]{5,20})[[A-Za-z0-9_@./#&+-\u00C0-\u017F]{5,20}|\S+@\S+\.\S+)$/

export const passwordValidators = [
  {
    regExp: /[a-z]/,
    error: 'The password should have at least 1 lowercase letter.',
  },
  {
    regExp: /\d/,
    error: 'The password should have at least 1 number.',
  },
  {
    regExp: /[A-Z]/,
    error: 'The password should have at least 1 uppercase letter.',
  },
  {
    regExp: /[!@#$%^&*?¿¡,:;{}|'"`~)(+=._-]/,
    error: 'The password should have at least 1 special character.',
  },
  {
    regExp: /^.{9,20}$/,
    error: 'The password should be between 9 and 20 characters long.',
  },
]

export const timeRegex = /(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/

export const REGEX_URL = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/m

export const REGEX_USERNAME = /^[^~"%@&*:<>/\\{|}\s]*$/m

export const REGEX_USERNAME_LIST = /^[^~"%@&*:<>/\\{|}\s]+(?:[,\n][^~"%@&*:<>/\\{|}\s]+)*$/m

export const REGEX_TEXT = /^[^~<>/\\{|}\s]+([\s][^~<>/\\{|}\s]+)*?$/

export const REGEX_TEXT_NAME = /^[^~<>/\\{|}0-9\s]+([ ][^~<>/\\{|}0-9\s]+)*?$/

export const REGEX_PHONE_NUMBER = /^\S+(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{1,4}[)]?))[)]?[-\s]?[(]?[0-9]{1,3}[)]?([-\s]?[0-9]{3})([-\s]?[0-9]{3,4})*$/m

export const REGEX_NUMBERS = /^\d+$/

export const REGEX_POSITIVE_AND_NEGATIVE_NUMBERS = /^[-]?\d+$/

export const REGEX_POSITIVE_NUMBERS = /^[1-9][0-9]*$/

export const REGEX_TEXTAREA = /^[^~<>/\\{|}]+$/

export const REGEX_TEXTAREA_PLACEHOLDER = /^[^~<>/\\|]+$/

export const REGEX_UUID_BY_COMMA = /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})+(?:[,\n]([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})+)*$/m

export const REGEX_UUID = /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/m

export const REGEX_NUMBER_FLOAT = /^([0-9]*[.])?[0-9]+$/

export const REGEX_NUMBER_FLOAT_AND_NEGATIVE = /^-?\d*\.{0,1}\d+$/

export const REGEX_ID_DOCUMENT = /^[^?.~!@#$^&*+="%&*:<>()/\\{|}\s]+([ ][^?.~!@#$^&*+="%&*:<>()/\\{|}\s]+)*?$/

export const REGEX_CONSECUTIVE_WHITESPACE = /\s\s/

export const REGEX_NUMBERS_1_TO_100 = /^[1-9][0-9]?$|^100$/

export const REGEX_NUMBERS_1_TO_1000 = /^([1-9][0-9]{0,2}|1000)$/

export const REGEX_TIME = /^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/

export const REGEX_NUMBER_SUFFIX = /^[1-9](\d+)?[h,d,w,m,y]/

export const GetRegexNumberFloat = (decimals: number) =>
  new RegExp(`^([0-9]*[.]?)?[0-9]{0,${decimals}}$`)

export const REGEX_NO_SPACES_IN_BETWEEN = /^\S*$/
