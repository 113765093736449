import ActionTypes from '../constants'
import { LogActions, LogState } from '../models'

const coolOffLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_COOLOFF_LOGS:
      return {
        ...state,
        isLoadingCoolOff: true,
      }

    case ActionTypes.GET_COOLOFF_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingCoolOff: false,
        coolOffs: action.payload?.message || [],
      }

    default:
      return state
  }
}

export default coolOffLogHandler
