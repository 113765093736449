import ActionTypes from '../constants'
import {
  KYCSocketActions,
  PlayerCardKYCActions,
  PlayerCardKYCDocumentState,
} from '../models'

const ubiIDDocumentHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions | KYCSocketActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER_CASES:
      return { ...state, isLoading: true }

    case ActionTypes.GET_PLAYER_CASES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        ubiIDDoc: action.payload.documents || [],
      }

    case ActionTypes.GET_FAILED_INTEGRITY_CHECK:
      return { ...state, isLoading: true }

    case ActionTypes.GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER:
      return { ...state, isLoading: false }

    case ActionTypes.GET_CASE_FILES:
      return { ...state, isLoading: true }

    case ActionTypes.GET_CASE_FILES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        caseFiles: action.payload.files || [],
        currentDownloadedImage:
          action.payload.files.find(
            file =>
              file.documentPart === 'front' || file.documentPart === 'scan',
          )?.fileUrl || '',
        currentDownloadedSecondImage:
          action.payload.files.find(file => file.documentPart === 'back')
            ?.fileUrl || '',
      }

    case ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE:
      return { ...state, isLoading: true }

    case ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER:
      return { ...state, isLoading: false }

    case ActionTypes.FAILED_INTEGRITY_CHECK_REJECT:
      return { ...state, isLoading: true }

    case ActionTypes.FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER:
      return { ...state, isLoading: false }

    case ActionTypes.SET_UBIID_INFO:
      return { ...state, ubiIDInfo: action.payload }

    default:
      return state
  }
}

export default ubiIDDocumentHandler
