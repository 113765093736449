enum ActionTypes {
  SET_SNACKBAR = '@ui-global/SET_SNACKBAR',
  SET_DIALOG = '@ui-global/SET_DIALOG',
  SET_POPOVER = '@ui-global/SET_POPOVER',
  SET_POPOVER_CONFIRM = '@ui-global/SET_POPOVER_CONFIRM',
  SET_FORM_MODE = '@ui-global/SET_FORM_MODE',
  SET_IS_LOADING_FULL = '@ui-global/SET_IS_LOADING_FULL',
  SET_DRAWER = '@ui-global/SET_DRAWER',
  SET_ACTIVE_STEP = '@ui-global/SET_ACTIVE_STEP',
  SET_LANGUAGES_SELECTED = '@ui-global/SET_LANGUAGES_SELECTED',
  SET_ACTIVE_LANGUAGE = '@ui-global/SET_ACTIVE_LANGUAGE',
  SET_INIT = '@ui-global/SET_INIT',
}

export default ActionTypes
