import { createReducer } from 'typesafe-actions'
import {
  PlayerCardState,
  PlayerCardActions,
  PlayerSocketActions,
} from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import playerHandler from './player.handler'
import getAccountStatusLogsHandler from './get-account-status-logs.handler'

import setAccountStatusHandler from './set-account-status.handler'
import updatePlayerCardHandler from './update-player-card.handler'
import setAccountTestHandler from './set-account-test.handler'

const playerCardReducer = createReducer<
  PlayerCardState,
  PlayerCardActions | PlayerSocketActions
>(initialState)
  .handleAction(
    [
      ActionTypes.GET_PLAYER,
      ActionTypes.GET_PLAYER_SUBSCRIBER,
      ActionTypes.GET_PLAYERS,
      ActionTypes.GET_PLAYERS_SUBSCRIBER,
      ActionTypes.GET_PLAYERS_EXCEPTION_SUBSCRIBER,
      ActionTypes.SET_PLAYER_FILTERS,
      ActionTypes.RESET_PLAYER_INFO,
      ActionTypes.RESEND_EMAIL,
      ActionTypes.RESEND_EMAIL_SUBSCRIBER,
      ActionTypes.SET_PLAYER_PAGINATION,
      ActionTypes.CRIF_CHECK_ADDRESS_SUBSCRIBER,
      ActionTypes.GET_PLAYER_ISSUE_COUNT_SUBSCRIBER,
    ],
    playerHandler,
  )
  .handleAction(
    [ActionTypes.GET_ACCOUNT_STATUS, ActionTypes.GET_ACCOUNT_STATUS_SUBSCRIBER],
    getAccountStatusLogsHandler,
  )
  .handleAction(
    [ActionTypes.CREATE_ACCOUNT_STATUS_SUBSCRIBER],
    setAccountStatusHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_PLAYER_CARD,
      ActionTypes.UPDATE_PLAYER_CARD_SUBSCRIBER,
      ActionTypes.DISABLE_TWO_FACTOR,
      ActionTypes.DISABLE_TWO_FACTOR_SUBSCRIBER,
      ActionTypes.UPDATE_PAYMENT_DATA,
      ActionTypes.UPDATE_PAYMENT_DATA_SUBSCRIBER,
    ],
    updatePlayerCardHandler,
  )
  .handleAction(
    [ActionTypes.SET_ACCOUNT_TEST, ActionTypes.SET_ACCOUNT_TEST_SUBSCRIBER],
    setAccountTestHandler,
  )

export default playerCardReducer
