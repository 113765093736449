/**
 *
 * Limit constants
 *
 */

enum ActionTypes {
  GET_ALL_LIMITS = '@limit/GET_ALL_LIMITS',
  GET_ALL_LIMITS_SUBSCRIBER = '@limit/GET_ALL_LIMITS_SUBSCRIBER',

  GET_LIMIT_TYPES = '@limit/GET_LIMIT_TYPES',
  GET_LIMIT_TYPES_SUBSCRIBER = '@limit/GET_LIMIT_TYPES_SUBSCRIBER',

  CANCEL_PENDING_LIMIT = '@limit/CANCEL_PENDING_LIMIT',
  CANCEL_PENDING_LIMIT_SUBSCRIBER = '@limit/CANCEL_PENDING_LIMIT_SUBSCRIBER',

  GET_PENDING_LIMITS = '@limit/GET_PENDING_LIMITS',
  GET_PENDING_LIMITS_SUBSCRIBER = '@limit/GET_PENDING_LIMITS_SUBSCRIBER',

  SET_NEW_LIMIT = '@limit/SET_NEW_LIMIT',
  SET_NEW_LIMIT_SUBSCRIBER = '@limit/SET_NEW_LIMIT_SUBSCRIBER',

  GET_MAX_LIMITS_DEPOSIT = '@limit/GET_MAX_LIMITS_DEPOSIT',
  GET_MAX_LIMITS_DEPOSIT_SUBSCRIBER = '@limit/GET_MAX_LIMITS_DEPOSIT_SUBSCRIBER',

  SET_NEW_LIMITS_PLAYER = '@limit/SET_NEW_LIMITS_PLAYER',
  SET_NEW_LIMITS_PLAYER_SUBSCRIBER = '@limit/SET_NEW_LIMITS_PLAYER',

  GET_LIMITS_SETTING_LIMITS = '@limit/GET_LIMITS_SETTING_LIMITS',
  GET_LIMITS_SETTING_LIMITS_SUBSCRIBER = '@limit/GET_LIMITS_SETTING_LIMITS_SUBSCRIBER',

  REMOVE_LIMIT = '@limit/REMOVE_LIMIT',
  REMOVE_LIMIT_SUBSCRIBER = '@limit/REMOVE_LIMIT_SUBSCRIBER',

  SET_GAMING_LIMITS = '@limit/SET_GAMING_LIMITS',
  SET_CURRENT_GAMING_LIMIT = '@limit/SET_CURRENT_GAMING_LIMIT',
}

export default ActionTypes
