import { BlacklistState } from '../models'

const initialState: BlacklistState = {
  isLoading: true,
  reports: [],
  filters: {},
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 0,
  },
  paginationParams: {
    page: 1,
    size: 25,
  },
}

export default initialState
