/**
 *
 * PlayerCardGamingActivity constants
 *
 */

enum ActionTypes {
  SET_FILTERS = '@pcard-gaming-activity/SET_FILTERS',

  GET_GAME_LIST_ADMIN = '@pcard-gaming/GET_GAME_LIST_ADMIN',
  GET_GAME_LIST_ADMIN_SUBSCRIBER = '@pcard-gaming/GET_GAME_LIST_ADMIN_SUBSCRIBER',

  GET_GAME_CATEGORY_LIST = '@pcard-gaming/GET_GAME_CATEGORY_LIST',
  GET_GAME_CATEGORY_LIST_SUBSCRIBER = '@pcard-gaming/GET_GAME_CATEGORY_LIST_SUBSCRIBER',

  GET_GAME_PROVIDER_LIST = '@pcard-gaming/GET_GAME_PROVIDER_LIST',
  GET_GAME_PROVIDER_LIST_SUBSCRIBER = '@pcard-gaming/GET_GAME_PROVIDER_LIST_SUBSCRIBER',

  GET_GAME_SESSIONS = '@pcard-gaming/GET_GAME_SESSIONS',
  GET_GAME_SESSIONS_SUBSCRIBER = '@pcard-gaming/GET_GAME_SESSIONS_SUBSCRIBER',

  GET_GAME_TRANSACTIONS = '@pcard-gaming/GET_GAME_TRANSACTIONS',
  GET_GAME_TRANSACTIONS_SUBSCRIBER = '@pcard-gaming/GET_GAME_TRANSACTIONS_SUBSCRIBER',

  DOWNLOAD_GAME_SESSIONS = '@pcard-gaming/DOWNLOAD_GAME_SESSIONS',
  DOWNLOAD_GAME_TRANSACTIONS = '@pcard-gaming/DOWNLOAD_GAME_TRANSACTIONS',
}

export default ActionTypes
