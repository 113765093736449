import DocumentFileFormat from 'state/enums/document-file-format.enum'
import { IssuesActions, IssueState } from '../model'
import ActionTypes from '../constants'

import { dataCurrentIssue } from './initial-state'

const downloadDocumentImageHandler = (
  state: IssueState,
  action: IssuesActions,
): IssueState => {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST:
      return {
        ...state,
        isLoadingImg: true,
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoadingImg: false,
        currentDownloadedImage:
          action.payload.type === DocumentFileFormat.IMG
            ? action.payload.url
            : '',
        documentPreview: {
          format: action.payload.type,
          url:
            action.payload.type === DocumentFileFormat.PDF
              ? action.payload.url
              : '',
          name:
            action.payload.type === DocumentFileFormat.PDF
              ? action.payload.name
              : '',
        },
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE:
      return {
        ...state,
        isLoadingImg: false,
      }
    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_CLEAR:
      return {
        ...state,
        isLoadingImg: false,
        currentDownloadedImage: '',
        currentDownloadedSecondImage: '',
        currentIssue: dataCurrentIssue,
        documentPreview: {
          format: DocumentFileFormat.IMG,
          url: '',
          name: '',
        },
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_REQUEST:
      return {
        ...state,
        isLoadingImg: true,
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_SUCCESS:
      return {
        ...state,
        isLoadingImg: false,
        currentDownloadedSecondImage:
          action.payload.type === DocumentFileFormat.IMG
            ? action.payload.url
            : '',
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_FAILURE:
      return {
        ...state,
        isLoadingImg: false,
      }

    default:
      return state
  }
}

export default downloadDocumentImageHandler
