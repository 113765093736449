import ActionTypes from '../constants'
import { MissionsActions, MissionsState } from '../models'

const missionsHandler = (
  state: MissionsState,
  action: MissionsActions,
): MissionsState => {
  switch (action.type) {
    case ActionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }

    default:
      return state
  }
}

export default missionsHandler
