import ActionTypes from '../constants'
import { BlacklistState, BlacklistActions } from '../models'

const blacklistHandler = (
  state: BlacklistState,
  action: BlacklistActions,
): BlacklistState => {
  switch (action.type) {
    case ActionTypes.GET_BLACKLIST_REPORT:
    case ActionTypes.GET_BLACKLIST_AUDIT_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_BLACKLIST_REPORT_SUBSCRIBER: {
      return {
        ...state,
        isLoading: false,
        reports: action.payload?.list || [],
        pagination: {
          total: action.payload?.pagination?.total || 0,
          limit: action.payload?.pagination?.size || 25,
          page: action.payload?.pagination?.page || 1,
          pages:
            Math.ceil(
              action.payload?.pagination?.total /
                action.payload?.pagination?.size,
            ) || 1,
        },
      }
    }

    case ActionTypes.GET_BLACKLIST_AUDIT_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        reports: action.payload?.data?.list || [],
        pagination: {
          total: action.payload?.data?.pagination?.total || 0,
          limit: action.payload?.data?.pagination?.size || 25,
          page: action.payload?.data?.pagination?.page || 1,
          pages:
            Math.ceil(
              action.payload?.data?.pagination?.total /
                action.payload?.data?.pagination?.size,
            ) || 1,
        },
      }

    default:
      return state
  }
}

export default blacklistHandler
