enum JumioVerificationStatus {
  APPROVED_VERIFIED = 'APPROVED_VERIFIED',
  DENIED_FRAUD = 'DENIED_FRAUD',
  DENIED_UNSUPPORTED_ID_TYPE = 'DENIED_UNSUPPORTED_ID_TYPE',
  DENIED_UNSUPPORTED_ID_COUNTRY = 'DENIED_UNSUPPORTED_ID_COUNTRY',
  ERROR_NOT_READABLE_ID = 'ERROR_NOT_READABLE_ID',
  NO_ID_UPLOADED = 'NO_ID_UPLOADED',
  UNDEFINED = 'UNDEFINED',
}

export default JumioVerificationStatus
