import ActionTypes from '../constants'
import { BlacklistActions, BlacklistState } from '../models'

const filtersHandler = (
  state: BlacklistState,
  action: BlacklistActions,
): BlacklistState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    case ActionTypes.SET_PAGINATION:
      return {
        ...state,
        paginationParams: {
          ...state.paginationParams,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default filtersHandler
