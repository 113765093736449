import { alpha } from '@material-ui/core/styles'
import { colors } from './colors.theme'

const { white, green, red } = colors

export const switchTheme = {
  root: {
    '&.green': {
      '& .MuiSwitch-thumb': {
        color: `${green}!important`,
      },
      '& .MuiSwitch-track': {
        backgroundColor: `${green}!important`,
      },
    },
    '&.red': {
      '& .MuiSwitch-thumb': {
        color: `${red}!important`,
      },
      '& .MuiSwitch-track': {
        backgroundColor: `${red}!important`,
      },
      '& .Mui-disabled': {
        '& .MuiSwitch-track': {
          backgroundColor: `${red}!important`,
        },
      },
    },
  },
  track: {
    backgroundColor: alpha(white, 0.4),
  },
  colorSecondary: {
    '&.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: white,
    },
  },
}
