import { ApplicationState } from 'state/root.models'
import { initialUser } from './reducers/initial-state'
import TimeZoneType from '../enums/timezone.enum'

export const root = ({ user }: ApplicationState) => user

export const userByIdSelector = () => {
  const userSession = sessionStorage.getItem('user')
  let userSaved = initialUser
  if (userSession) {
    userSaved = JSON.parse(userSession)
  }
  return userSaved
}

export const timeZoneSelector = () => {
  const userSession = sessionStorage.getItem('user')
  let timeZone = TimeZoneType.GMT_0
  if (userSession) {
    timeZone = JSON.parse(userSession).timezone
  }
  return timeZone
}
