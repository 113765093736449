import ActionTypes from '../constants'
import { LogState, LogActions } from '../models'

const teledataLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_TELEDATA_LOGS:
      return {
        ...state,
        isLoadingTeledata: true,
      }

    case ActionTypes.GET_TELEDATA_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingTeledata: false,
        teledata: action.payload || [],
      }

    default:
      return state
  }
}

export default teledataLogHandler
