import { createReducer } from 'typesafe-actions'
import {
  PlayerCardTagState,
  PlayerCardTagActions,
  SocketActions,
} from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import playerTagGetAllHandler from './get-all.handler'
import setTagsHandler from './set-tags.handler'

/**
 * Creates a PlayerCardTag Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardTagReducer = createReducer<
  PlayerCardTagState,
  PlayerCardTagActions | SocketActions
>(initialState)
  .handleAction(
    [ActionTypes.GET_ALL, ActionTypes.GET_ALL_SUBSCRIBER],
    playerTagGetAllHandler,
  )
  .handleAction(
    [ActionTypes.SET_TAGS, ActionTypes.SET_TAG_CATEGORIES],
    setTagsHandler,
  )

export default playerCardTagReducer
