const welcomeEmailTemplate = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <title>Welcome CRM GaminGenius</title>
  <!-- Designed by https://github.com/kaytcat -->
  <!-- Robot header image designed by Freepik.com -->

  <style type="text/css">
  @import url(http://fonts.googleapis.com/css?family=Montserrat);

  /* Take care of image borders and formatting */

  img {
    max-width: 600px;
    outline: none;
    text-decoration: none;
    -ms-interpolation-mode: bicubic;
  }

  a {
    text-decoration: none;
    border: 0;
    outline: none;
    color: #bbbbbb;
  }

  a img {
    border: none;
  }

  /* General styling */

  td, h1, h2, h3  {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
  }

  td {
    text-align: center;
  }

  body {
    -webkit-font-smoothing:antialiased;
    -webkit-text-size-adjust:none;
    width: 100%;
    height: 100%;
    color: #37302d;
    background: #ffffff;
    font-size: 16px;
  }

   table {
    border-collapse: collapse !important;
  }

  .headline {
    color: #000000;
    font-size: 36px;
      font-weight: bold;
  }

 .force-full-width {
  width: 100% !important;
 }

  </style>

  <style type="text/css" media="screen">
      @media screen {
         /*Thanks Outlook 2013! http://goo.gl/XLxpyl*/
        td, h1, h2, h3 {
          font-family: 'Montserrat', 'Helvetica Neue', 'Arial', 'sans-serif' !important;
        }
      }
  </style>

  <style type="text/css" media="only screen and (max-width: 480px)">
    /* Mobile styles */
    @media only screen and (max-width: 480px) {

      table[class="w320"] {
        width: 320px !important;
      }


    }
  </style>
</head>
<body class="body" style="padding:0; margin:0; display:block; background:#ededed; -webkit-text-size-adjust:none" bgcolor="#ffffff">
<table align="center" cellpadding="0" cellspacing="0" width="100%" height="100%" >
  <tr>
    <td align="center" valign="top" bgcolor="#ededed"  width="100%">
      <center>
        <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="600" class="w320">
          <tr>
            <td align="center" valign="top">

                <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="100%" style="margin:0 auto;">
                  <tr>
                    <td style="font-size: 20px; text-align:center;">
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="https://firebasestorage.googleapis.com/v0/b/crm-jackpots.appspot.com/o/email%2FGamanza-black_bold.png?alt=media&token=0adb4256-453d-4ab7-9692-48b9d39733f7" height="60" width="235" style="max-width:60%; height:auto">
                    </td>
                  </tr>
                  <tr>
                    <td style="font-size: 30px; text-align:center;">
                      &nbsp;
                    </td>
                  </tr>
                </table>

                <table style="margin: 0 auto; border-radius:10px 10px 10px 10px;" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                        <td width="20">&nbsp;</td>
                        <td>
                            <table style="margin: 0 auto; border-radius:10px 10px 10px 10px;" cellpadding="0" cellspacing="0" width="100%" bgcolor="#FFFFFF">
                            	<tr>
                                    <td style="font-size: 30px; text-align:center;">
                                      &nbsp;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="headline">
                                        Welcome {{UserName}}, to GaminGenius<sup style="font-size: 14px">&reg;</sup>
                                    </td>
                                  </tr>
                                    <td style="font-size: 10px; text-align:center; height: 10px;">
                                      &nbsp;
                                    </td>
                                    <tr>
                                        <td width="100%">
                                            <center>
                                              <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="100%">
                                                  <tr>
                                                      <td width="150" style="width:30%; max-width: 150px; display: inline-block; font-size: 3px; line-height: 4px; height:4px;">&nbsp;</td>
                                                      <td width="242" bgcolor="#e58f34" height="4" style="display: inline-block; line-height: 4px; height:4px; font-size: 3px;">&nbsp;</td>
                                                      <td width="150" style="width:30%; display: inline-block; font-size: 3px; line-height: 4px; height:4px;">&nbsp;</td>
                                                  </tr>
                                              </table>
                                            </center>
                                        </td>
                                    </tr>  
                                    <tr>
                                      <td style="font-size: 10px; text-align:center;  height: 30px;">
                                        &nbsp;
                                      </td>
                                    </tr>
                                    <tr>
                                        <td width="100%">
                                            <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="100%">
                                                <tr>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                    <td style="display: inline-block; font-size:16px; line-height: 20px; color:#000000;">
                                                      The iGaming Superstore is now a click away.
                                                    </td>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                    <td style="display: inline-block; line-height: 30px; height:30px; font-size: 16px;">&nbsp;</td>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                    <td style="display: inline-block; line-height: 20px; height:50px; font-size: 16px;">
                                                        <div><!--[if mso]>
                                                          <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{{URL}}" style="height:50px;v-text-anchor:middle;width:200px;" arcsize="8%" stroke="f" fillcolor="#e68f34">
                                                            <w:anchorlock/>
                                                            <center>
                                                          <![endif]-->
                                                              <a href="{{URL}}"
                                                        style="background-color:#e68f34;border-radius:4px;color:#000000;display:inline-block;font-family:Helvetica, Arial, sans-serif;font-size:16px;font-weight:regular;line-height:50px;text-align:center;text-decoration:none;width:200px;-webkit-text-size-adjust:none; text-transform: uppercase">Create password</a>
                                                          <!--[if mso]>
                                                            </center>
                                                          </v:roundrect>
                                                        <![endif]--></div>
                                                    </td>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                    <td style="display: inline-block; line-height: 30px; height:30px; font-size: 16px; max-width: 90%;">&nbsp;</td>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                    <td style="display: inline-block; font-size:16px; line-height: 20px; color:#000000; font-size: 16px;  max-width: 90%;">
                                                      If this link doesn’t work, copy and paste the following URL into your browser:
                                                      <p>{{URL}}</p>
                                                    </td>
                                                    <td width="15" style="width:5%; display: inline-block;">&nbsp;</td>
                                                </tr>
                                              </table>
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td height="40">&nbsp;</td>
                                    </tr>
                           </table>
                        </td>
                        <td width="20">&nbsp;</td>
                    </tr>
                    <tr>
                        <td width="20">&nbsp;</td>
                        <td height="40">&nbsp;</td>
                        <td width="20">&nbsp;</td>
                    </tr>
                </table>
            </td>
          </tr>
        </table>
        <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" class="force-full-width" bgcolor="#333333" style="margin: 0 auto">
          <tr>
            <td>
              <center>
                  <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="600" class="w320">
                      <tr>
                          <td style="background-color:#333333;" height="40">&nbsp;</td>
                        </tr>
                        <tr>
                          <td>
                            <table style="margin: 0 auto;" cellpadding="0" cellspacing="0" width="100%">>
                                <tr>
                                  </td>
                                  <td style="background-color:#333333; width: 4%">
                                  </td>
                                  <td style="background-color:#333333;" vertical-align="top">
                                    <table>
                                      <tr>
                                        <td align="left" style="font-size:14px; color:#e68f34; line-height: 22px; text-align: left;">
                                          <a style="color:#e68f34; text-decoration: none;" href="mailto:sales@gamanza.com">sales@gamanza.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="left" style="font-size:14px; color:#aaaaaa; line-height: 22px; text-align: left;">Gamanza Group AG</td>
                                      </tr>
                                      <tr>
                                          <td align="left" style="font-size:14px; color:#aaaaaa; line-height: 22px; text-align: left;">Haselstrasse 2</td>
                                      </tr>
                                      <tr>
                                          <td align="left" style="font-size:14px; color:#aaaaaa; line-height: 22px; text-align: left;">5400 Baden</td>
                                      </tr>
                                      <tr>
                                          <td align="left" style="font-size:14px; color:#aaaaaa; line-height: 22px; text-align: left;">Switzerland</td>
                                      </tr>
                                      <tr>
                                          <td height="40">&nbsp;</td>
                                        </tr>
                                    </table>
                                  </td>
                                  <td style="background-color:#333333; width: 2%">
                                  </td>
                                  <td style="background-color:#333333;">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/crm-jackpots.appspot.com/o/email%2FSSC_ISO27001_transparent-1-e1562488156708.png?alt=media&token=bdf89e66-2043-4f1b-9989-49b5648f3a69" height="75" width="75" style="height:auto"> 
                                  </td>
                                  <td style="background-color:#333333; width: 2%">
                                  </td>
                                  <td style="background-color:#333333;">
                                      <img src="https://firebasestorage.googleapis.com/v0/b/crm-jackpots.appspot.com/o/email%2Fgamanza-gray.png?alt=media&token=eb40d17b-7ee8-4e84-98f9-f049faa74d0d" height="33" width="131" style="height:auto">
                                  </td>
                                  <td style="background-color:#333333; width: 4%">
                                  </td>
                                </tr>
                            </table>
                        </tr>
                        <tr>
                            <td align="center" style="font-size:12px; color:#e68f34; line-height: 22px; text-align: center;">
                              &copy; GamanzaGroup | All Rights Reserved
                            </td>
                        </tr>
                        <tr>
                          <td style="background-color:#333333;">&nbsp;</td>
                        </tr>
                        <tr>
                          <td style="background-color:#333333;">&nbsp;</td>
                        </tr>
                  </table>
              </center>
            </td>
          </tr>
            
        </table>
    </center>
    </td>
  </tr>
</table>
</body>
</html>`

export default welcomeEmailTemplate
