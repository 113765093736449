import { PersonalizationEngineState } from 'state/wallet/models/personalization-engine.model'

const initialState: PersonalizationEngineState = {
  isLoading: false,
  isLoadingCategories: false,
  isLoadingProviders: false,
  isLoadingPartial: false,
  isLoadingFull: false,
  hasError: false,
  resolved: false,
  error: '',
  wasDeleted: false,
  gameCategories: [],
  gameProviders: [],
  gamesByPlayer: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    playerId: '',
    limit: 15,
    page: 1,
  },
}

export default initialState
