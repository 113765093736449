import { Avatar } from '@material-ui/core'
import { alpha, withStyles } from '@material-ui/core/styles'
import { colors } from './colors.theme'

const {
  orange,
  green,
  purple,
  blue,
  red,
  yellow,
  pink,
  teal,
  black,
  redLight,
  gray,
} = colors

export const avatarTheme = {
  root: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    '&.Mui-Avatar-orange': {
      backgroundColor: orange,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-green': {
      backgroundColor: green,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-purple': {
      backgroundColor: purple,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-blue': {
      backgroundColor: blue,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-red': {
      backgroundColor: red,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-yellow': {
      backgroundColor: yellow,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-teal': {
      backgroundColor: teal,
      color: alpha(black, 0.5),
    },
    '&.Mui-Avatar-pink': {
      backgroundColor: pink,
      color: alpha(black, 0.5),
    },
    '&.extra-large': {
      width: 200,
      height: 200,
      fontSize: 72,
    },
  },
}

export const AvatarBg = withStyles(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: 1,
    '&.Mui-Avatar-orange': {
      backgroundColor: alpha(orange, 0.3),
      color: orange,
    },
    '&.Mui-Avatar-green': {
      backgroundColor: alpha(green, 0.3),
      color: green,
    },
    '&.Mui-Avatar-purple': {
      backgroundColor: alpha(purple, 0.3),
      color: purple,
    },
    '&.Mui-Avatar-blue': {
      backgroundColor: alpha(blue, 0.3),
      color: blue,
    },
    '&.Mui-Avatar-red': {
      backgroundColor: alpha(red, 0.3),
      color: red,
    },
    '&.Mui-Avatar-yellow': {
      backgroundColor: alpha(yellow, 0.3),
      color: yellow,
    },
    '&.Mui-Avatar-teal': {
      backgroundColor: alpha(teal, 0.3),
      color: teal,
    },
    '&.Mui-Avatar-pink': {
      backgroundColor: alpha(pink, 0.3),
      color: pink,
    },
    '&.Mui-Avatar-redLight': {
      backgroundColor: alpha(redLight, 0.3),
      color: redLight,
    },
    '&.Mui-Avatar-gray': {
      backgroundColor: alpha(gray, 0.3),
      color: gray,
    },
  },
}))(Avatar)
