import { Theme, Tab } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/styles'

import { colors } from '../theme/colors.theme'

const { white, blueMedium, blueDark, blue } = colors

export const tabTheme = {
  root: {
    minWidth: 'auto !important',
    marginRight: 30,
    padding: 0,
    minHeight: 10,
    textTransform: 'none' as 'none',
    fontWeight: 'bold' as 'bold',
    opacity: '0.5!important',
    '&$selected': {
      opacity: '1!important',
    },
  },
  textColorInherit: {
    color: alpha(white, 0.7),
  },
}

export const TabIcon = withStyles((theme: Theme) => ({
  root: {
    padding: '6px 10px',
    margin: 0,
    marginRight: 1,
    borderRadius: '4px 4px 0px 0px',
    background: blueMedium,
    '&.bg-blueDark': {
      background: blueDark,
    },
    '&.Mui-disabled': {
      opacity: '0.5 !important',
    },
  },
  wrapper: {
    flexDirection: 'row',
  },
  labelIcon: {
    minHeight: 0,
    '& .MuiTab-wrapper > *:first-child': {
      margin: '0 6px 0 0',
    },
  },
}))(Tab)

export const TabButton = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 16,
    color: `${white}!important`,
    padding: '2px 16px',
    marginRight: 8,
  },
  selected: {
    background: blue,
  },
}))(Tab)
