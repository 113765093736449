import { put, select, call } from 'redux-saga/effects'
import { Container } from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import objectToQueryString from 'utils/query-string'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'

import removeEmptyProp from 'utils/state/remove-empty-prop.util'
import {
  archiveCampaignAction,
  getAllCampaignsAction,
  getAllCampaignsArchiveAction,
} from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the archival/recovery of a single campaign
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof archiveCampaignAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
export function* archiveCampaignWorker(
  action: ReturnType<typeof archiveCampaignAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'campaigns/archive/one',
      queries: objectToQueryString(action.payload),
    }

    const { auth, campaign } = yield select()
    const response = yield call(apiService.deleteData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('campaign'),
      what: i18n.t(
        action.payload.isDeleted ? 'messages.deleted' : 'messages.recovered',
      ),
    })

    if (response._id) {
      yield put(archiveCampaignAction.success(response))
      if (action.payload.isDeleted) {
        yield put(
          getAllCampaignsAction.request(removeEmptyProp(campaign.filters)),
        )
      } else {
        yield put(
          getAllCampaignsArchiveAction.request(
            removeEmptyProp(campaign.filters),
          ),
        )
      }
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        archiveCampaignAction.failure({
          name: response.status,
          message: response.message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(archiveCampaignAction.failure(error))
  }
}

export default archiveCampaignWorker
