import SearchType from 'state/enums/search.enum'
import TypeValue from 'state/enums/type-value.enum'
import { SearchState } from '../models'

import paycards from '../data-test'

const initialState: SearchState = {
  paycards,
  tab: SearchType.PLAYER,
  playerSearchProps: {
    exact: false,
    typeValue: TypeValue.STRING,
    filterValue: '',
    filter: 'username',
    limit: 25,
    page: 1,
  },
  transactionSearchProps: {
    filter: 'playerId',
  },
}

export default initialState
