import { ApplicationState } from 'state/root.models'
import { WebsocketsState } from './models'

export const root = (state: WebsocketsState) => state

export const currentGateway = (
  { websockets }: ApplicationState,
  namespace: string,
) => {
  return websockets?.gateways?.find(gateway => gateway.namespace === namespace)
}

export const getConnected = (state: WebsocketsState) => state
export const getConnecting = (state: WebsocketsState) => state.isConnecting
