import ActionTypes from '../constants'
import { PlayerReportActions, PlayerReportState } from '../models'
import initialState from './initial-state'

const generalHandler = (
  state: PlayerReportState,
  action: PlayerReportActions,
): PlayerReportState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_PLAYER_REPORT_SUBSCRIBER: {
      return {
        ...state,
        isLoading: false,
        reports: action.payload?.playerReports || [],
      }
    }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.RESET_ALL:
      return { ...initialState }

    default:
      return state
  }
}

export default generalHandler
