import { select, call, put } from 'redux-saga/effects'
import { Container } from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { getRoleUsersAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets users by role
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getRoleUsersAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getRoleUsersWorker(
  action: ReturnType<typeof getRoleUsersAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'users/getUsersByRole',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(getRoleUsersAction.success(response))
  } catch (error) {
    yield put(getRoleUsersAction.failure(error))
  }
}

export default getRoleUsersWorker
