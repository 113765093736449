import { createReducer } from 'typesafe-actions'
import { PlayerCardKYCStateUI, PlayerCardKYCUiActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import uiHandler from './ui.handler'

/**
 * Creates a PlayerCardKYC UI Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardKYCUiReducer = createReducer<
  PlayerCardKYCStateUI,
  PlayerCardKYCUiActions
>(initialState).handleAction(
  [
    ActionTypes.SET_DOCUMENT_FORM_MODE,
    ActionTypes.TOGGLE_DOCUMENT_DIALOG,
    ActionTypes.TOGGLE_TELEDATA_DIALOG,
    ActionTypes.TOGGLE_AML_DIALOG,
    ActionTypes.TOGGLE_AML_EDIT,
    ActionTypes.TOGGLE_PEP_DIALOG,
  ],
  uiHandler,
)

export default playerCardKYCUiReducer
