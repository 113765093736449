import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const updateShortcutsHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.UPDATE_SHORTCUTS_REQUEST:
      return { ...state }

    case ActionTypes.UPDATE_SHORTCUTS_SUCCESS: {
      const index = state.shortcuts.findIndex(
        item => item._id === action.payload._id,
      )
      const newShortcuts = [...state.shortcuts]
      newShortcuts[index] = action.payload
      return {
        ...state,
        shortcuts: [...newShortcuts],
      }
    }

    case ActionTypes.UPDATE_SHORTCUTS_FAILURE:
      return {
        ...state,
        hasError: true,
      }

    default:
      return state
  }
}

export default updateShortcutsHandler
