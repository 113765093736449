export const USER_LOGS_NAMESPACE = 'user-logs' as 'user-logs'

export const USERLOGS = {
  GET_ONE: 'get-one' as 'get-one',
  CREATE: 'create' as 'create',
  FILTER_LOGS: 'filter-logs' as 'filter-logs',
  GET_PRODUCTS: 'get-products' as 'get-products',
}

export default USERLOGS
