import { takeLatest, debounce } from 'redux-saga/effects'
import { TemplatePushActions } from 'state/template-push'
import getAllTemplatePushWorker from './get-all.saga'

import getOneTemplatePushWorker from './get-one.saga'
import createTemplatePushWorker from './create.saga'
import updateTemplatePushWorker from './update.saga'

import archiveTemplatePushWorker from './archive.saga'
import duplicateTemplatePushWorker from './duplicate.saga'

/**
 * Main Saga for watching saga workers in the Template Push state shape
 *
 * @generator
 */
function* templatePushSagasWatcher() {
  yield [
    yield debounce(
      500,
      TemplatePushActions.getAllTemplatePushAction.request,
      getAllTemplatePushWorker,
    ),
    yield takeLatest(
      TemplatePushActions.getOneTemplatePushAction.request,
      getOneTemplatePushWorker,
    ),
    yield takeLatest(
      TemplatePushActions.createTemplatePushAction.request,
      createTemplatePushWorker,
    ),
    yield takeLatest(
      TemplatePushActions.updateTemplatePushAction.request,
      updateTemplatePushWorker,
    ),
    yield takeLatest(
      TemplatePushActions.archiveTemplatePushAction.request,
      archiveTemplatePushWorker,
    ),
    yield takeLatest(
      TemplatePushActions.duplicateTemplatePushAction.request,
      duplicateTemplatePushWorker,
    ),
  ]
}

export default templatePushSagasWatcher
