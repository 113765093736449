import React from 'react'
import { NavLink } from 'react-router-dom'
import { Menu, MenuItem, ListItemText, Box } from '@material-ui/core'

import useSidebarActions from '../hooks/use-sidebar-actions.hook'
import useSidebarLinks from '../hooks/use-sidebar-links.hook'

type Props = {
  handleCreateClose: () => void
  handleDrawerClose: () => void
  anchorCreate: null | HTMLElement
}

const CreateListComponent = ({
  handleCreateClose,
  handleDrawerClose,
  anchorCreate,
}: Props) => {
  const { sidebarIds } = useSidebarActions()

  const { linksCreate } = useSidebarLinks()

  const handleClickLink = () => {
    handleCreateClose()
    handleDrawerClose()
  }

  return (
    <Menu
      anchorEl={anchorCreate}
      keepMounted
      open={Boolean(anchorCreate)}
      onClose={handleCreateClose}
      PaperProps={{
        style: {
          maxHeight: 'none',
          minWidth: 200,
        },
      }}
      MenuListProps={{
        style: {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      {linksCreate
        .filter((item: any) => sidebarIds.includes(item.id) && item.visible)
        .map((item: any) => (
          <Box clone order={item.order} key={item.id}>
            <MenuItem
              key={item.id}
              component={NavLink}
              to={item.route}
              onClick={handleClickLink}
            >
              <ListItemText primary={item.labelText} />
            </MenuItem>
          </Box>
        ))}
    </Menu>
  )
}

export default CreateListComponent
