import {
  makeStyles,
  createStyles,
  Theme,
  darken,
  alpha,
} from '@material-ui/core/styles'
import {
  drawerOpenWidth,
  drawerClosedWidth,
} from 'constants/sidebar/sidebar.constant'
import { colors } from 'theme/colors.theme'

const { blueDarkNav, white, blue, orange, teal } = colors

const { REACT_APP_ENV: env } = process.env

// !VALIDATION: env
const colorEnv = env === 'test' ? orange : env === 'stage' ? teal : blueDarkNav

const useStyles = makeStyles(
  (theme: Theme) => {
    const transitionTime = {
      easing: theme.transitions.easing.sharp,
      duration: 250,
      delay: 100,
    }

    return createStyles({
      paper: {
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overflowY: 'hidden',
        overflowX: 'hidden',
        backgroundColor: blueDarkNav,
        padding: '16px 0',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: `6px solid ${alpha(colorEnv, 0.3)}`,
      },
      drawerOpen: {
        width: drawerOpenWidth,
        transition: theme.transitions.create(['all'], transitionTime),
      },
      drawerClosed: {
        transition: theme.transitions.create(['all'], transitionTime),
        backgroundColor: blueDarkNav,
        width: drawerClosedWidth,
        '& .MuiTreeItem-iconContainer': {
          display: 'none',
        },
      },
      logo: {
        fontSize: '2rem',
      },
      textLogo: {
        fontSize: 20,
        fontWeight: 400,
      },
      buttonCreate: {
        border: `1px solid ${white}`,
        color: white,
        minWidth: 0,
        height: 36,
        '&:hover': {
          border: `1px solid ${darken(white, 0.2)}`,
        },
        '& .MuiButton-startIcon': {
          margin: '0 4px',
        },
        '[class*="drawerOpen"] &': {
          width: 116,
          padding: '3px 20px',
          transition: theme.transitions.create(['all'], transitionTime),
        },
        '[class*="drawerClosed"] &': {
          width: 40,
          borderRadius: 8,
          transition: theme.transitions.create(['all'], transitionTime),
        },
      },
      buttonCreateText: {
        overflow: 'hidden',
        '[class*="drawerClosed"] &': {
          opacity: 0,
          width: 0,
          transition: theme.transitions.create(
            ['opacity', 'width'],
            transitionTime,
          ),
        },
        '[class*="drawerOpen"] &': {
          opacity: 1,
          width: 52,
          transition: theme.transitions.create(
            ['opacity', 'width'],
            transitionTime,
          ),
        },
      },
      link: {
        height: 48,
        padding: '8px 4px',
        borderRadius: 4,
        marginBottom: 4,
        position: 'relative',
        '&.active, &:hover': {
          color: blue,
          backgroundColor: alpha(blue, 0.1),
          '& .MuiListItemIcon-root': {
            color: blue,
          },
        },
        '&:hover': {
          backgroundColor: alpha(blue, 0.05),
        },
        '& .MuiListItemIcon-root': {
          marginLeft: 14,
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
        },
        '& .MuiListItemText-root': {
          position: 'absolute',
          margin: 0,
          '& .MuiTypography-body1': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& + .MuiSvgIcon-root': {
            fontSize: 16,
            marginLeft: 'auto',
          },
        },
        '[class*="drawerOpen"] &': {
          width: '100%',
          transition: theme.transitions.create(['width'], transitionTime),
          '& > .MuiSvgIcon-root': {
            opacity: 1,
            transition: theme.transitions.create(['opacity'], transitionTime),
          },
        },
        '[class*="drawerClosed"] &': {
          width: 55,
          transition: theme.transitions.create(['width'], transitionTime),
          '& > .MuiSvgIcon-root': {
            opacity: 0,
            transition: theme.transitions.create(['opacity'], transitionTime),
          },
        },
      },
      linkOpen: {
        '&.MuiListItemText-root': {
          left: 56,
          transform: 'translateX(0%)',
          bottom: 12,
          '& .MuiTypography-body1': {
            fontSize: 14,
            maxWidth: 150,
          },
        },
        '[class*="drawerClosed"] &': {
          opacity: 0,
          width: 0,
          transition: theme.transitions.create(
            ['opacity', 'width'],
            transitionTime,
          ),
        },
        '[class*="drawerOpen"] &': {
          opacity: 1,
          width: 140,
          transition: theme.transitions.create(
            ['opacity', 'width'],
            transitionTime,
          ),
        },
      },
      linkClosed: {
        '&.MuiListItemText-root': {
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: 2,
          '& .MuiTypography-body1': {
            fontSize: 9,
            maxWidth: 75,
          },
        },
        '[class*="drawerOpen"] &': {
          opacity: 0,
          transition: theme.transitions.create(['opacity'], {
            ...transitionTime,
            duration: 100,
            delay: 0,
          }),
        },
        '[class*="drawerClosed"] &': {
          opacity: 1,
          transition: theme.transitions.create(['opacity'], {
            ...transitionTime,
            duration: 100,
            delay: 350,
          }),
        },
      },
      linkInner: {
        color: alpha(white, 0.7),
        borderRadius: 4,
        marginBottom: 4,
        '& .MuiListItemText-root': {
          '& .MuiTypography-body1': {
            fontSize: 14,
          },
        },
        '&.active, &:hover': {
          color: blue,
          backgroundColor: alpha(blue, 0.1),
        },
        '&:hover': {
          backgroundColor: alpha(blue, 0.05),
        },
      },
      flip: {
        transform: 'scaleX(-1)',
      },
      fadeIn: {
        '[class*="drawerClosed"] &': {
          opacity: 0,
          transition: theme.transitions.create(['opacity'], transitionTime),
        },
        '[class*="drawerOpen"] &': {
          opacity: 1,
          transition: theme.transitions.create(['opacity'], transitionTime),
        },
      },
      labelEnv: {
        color: colorEnv,
        background: alpha(colorEnv, 0.3),
        borderRadius: '0 4px 4px 0',
        fontWeight: 'bold',
        lineHeight: 1,
        textAlign: 'left',
        '[class*="drawerOpen"] &': {
          width: 254,
          padding: '18px 18px 18px 76px',
          transition: theme.transitions.create(
            ['padding', 'width'],
            transitionTime,
          ),
        },
        '[class*="drawerClosed"] &': {
          width: 76,
          padding: '18px 18px 18px 36px',
          transition: theme.transitions.create(
            ['padding', 'width'],
            transitionTime,
          ),
        },
      },
    })
  },
  { name: 'MuiDrawerComponent' },
)

export default useStyles
