import ActionTypes from '../constants'
import { TeledataActions, TeledataState } from '../models'

const filtersHandler = (
  state: TeledataState,
  action: TeledataActions,
): TeledataState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default filtersHandler
