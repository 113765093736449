import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import removeEmptyProp from 'utils/state/remove-empty-prop.util'

import {
  duplicateTemplateEmailAction,
  getAllEmailTemplatesAction,
} from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga duplicates a single email template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof duplicateTemplateEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* duplicateTemplateWorker(
  action: ReturnType<typeof duplicateTemplateEmailAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/email/duplicate/one',
      body: action.payload,
    }

    const { auth, templateEmail } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('template'),
      what: i18n.t('duplicated'),
    })

    if (response._id) {
      yield put(duplicateTemplateEmailAction.success(response))

      yield put(
        getAllEmailTemplatesAction.request(
          removeEmptyProp(templateEmail.filters),
        ),
      )
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        duplicateTemplateEmailAction.failure({
          name: response.status,
          message: response.message,
        }),
      )
    }
    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(duplicateTemplateEmailAction.failure(error))
  }
}

export default duplicateTemplateWorker
