import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const deleteShortcutsHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.DELETE_SHORTCUTS_REQUEST:
      return { ...state }

    case ActionTypes.DELETE_SHORTCUTS_SUCCESS:
      return {
        ...state,
        shortcuts: state.shortcuts.filter(
          shortcut => shortcut._id !== action.payload._id,
        ),
      }

    case ActionTypes.DELETE_SHORTCUTS_FAILURE:
      return {
        ...state,
        hasError: true,
      }

    default:
      return state
  }
}

export default deleteShortcutsHandler
