import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles' // eslint-disable-lineimport/no-extraneous-dependencies
import { ClearCacheProvider } from 'react-clear-cache'

import theme from './theme'

import './i18n.ts'
import App from './App'

ReactDOM.render(
  <ClearCacheProvider auto>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ClearCacheProvider>,
  document.getElementById('root'),
)
