const gameStore = {
  settingOptions: {
    betMin: 'Min Bet',
    betMax: 'Max Bet',
    active: 'Active',
    bonusExcluded: 'Excluded from Bonuses',
    wageringContribution: 'Bonus Contribution',
  },
  addNewGameVersion: 'Add New Game Version',
  advancedSettings: 'Advanced Settings',
  bonusContribution: 'Bonus Contribution',
  bonusSettings: 'Bonus Settings',
  gameVersion: 'Game Version',
  gamesAdvancedText: 'Configure advanced settings for the game',
  gamesBonusText: 'Set the bonus contribution percentage for the game',
  gamesDetailsText: 'Basic game details.',
  gamesVersionText: '',
  confirmEditSettings:
    'Are you sure you want to edit the selected setting for multiple games?',
  createAGame: 'Create a Game',
  createAGameText: 'Start by filling in the information in step 1.',
  editGameSettings: 'Edit Game Settings',
  editSelectedGames: 'Edit Selected Games',
  excludedFromBonuses: 'Excluded From Bonuses',
  gameSettings: 'Game Settings',
  gameVersionHistory: 'Game Version History',
  paylines: 'Paylines',
  rtpMin: 'Min RTP',
  rtpMax: 'Max RTP',
  selectSetting: 'Select Setting',
  selectedGames: 'Selected games',
  viewGameImage: 'View Game Image',
  lastVersionAdded: 'Last Version Added',
}

export default gameStore
