import { makeStyles } from '@material-ui/core'
import { colors } from 'theme/colors.theme'

const { blueNav } = colors

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 1400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&[x-placement*="bottom-end"]': {
      left: '45px!important',
    },
    '&[x-placement*="top"]': {
      top: '-12px!important',
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${blueNav} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      zIndex: 100,
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1.9em 2em 0 2em',
        borderColor: `${blueNav} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${blueNav} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${blueNav}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
}))

export default useStyles
