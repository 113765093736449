import { createReducer } from 'typesafe-actions'
import ActionTypes from '../constants'
import { MissionsState, MissionsActions } from '../models'

import missionsInitialState from './initial-state'
import missionsHandler from './missions.handler'

/**
 *
 * Missions main reducer
 *
 */
const missionsReducer = createReducer<MissionsState, MissionsActions>(
  missionsInitialState,
).handleAction(
  [ActionTypes.SET_PAGINATION, ActionTypes.SET_FILTERS],
  missionsHandler,
)

export default missionsReducer
