export const tablePaginationTheme = {
  select: {
    background: 'none',
    height: 'auto!important',
    '&:focus': {
      background: 'none',
    },
  },
  selectIcon: {
    right: 0,
    top: 4,
  },
}
