import NetlossCategory from 'state/enums/annexe-netloss-category.enum'
import FormStatusType from 'state/enums/form-status.enum'
import { Annexe, AnnexeGetAll, AnnexeState } from '../models'

const emptyAnnexe: Annexe = {
  personalData: {
    firstName: '',
    lastName: '',
    birthDate: '',
    username: '',
  },
  spcsVisum: {
    creationDate: '',
    openingDateFinalAccount: '',
    statisticsRegistrationDate: '',
    inscriptionDate: '',
  },
  rcsVisum: {
    creationDate: '',
    openingDateFinalAccount: '',
    statisticsRegistrationDate: '',
    inscriptionDate: '',
  },
  scsVisum: {
    creationDate: '',
    openingDateFinalAccount: '',
    statisticsRegistrationDate: '',
    inscriptionDate: '',
  },
  analysis: {
    resultDSMS: [],
    category: NetlossCategory.UNDER_500,
    activeNetLoss: 0,
    dailyPlayTime: 0,
    dailySession: 0,
    origin: '',
    salary: 0,
    budgetSetByCasino: 0,
    landbasedCasino: 0,
  },
  criteriaOverOneMonth: { criteriaA: 0, criteriaB: 0, criteriaC: 0 },
  interventionTypes: {
    criteriasBC: 0,
    greenTeledata: 0,
    orangeTeledata: 0,
    redTeledata: 0,
    coolOffs: 0,
    thirdPartyReporting: '',
    analysis: '',
  },
  decision: {
    allowPlay: '',
    doNotAllowPlay: '',
  },
  text: '',
  auditInterventions: [],
}

const emptyAnnexeGet: AnnexeGetAll = {
  id: 0,
  adminId: '',
  inserted: '',
  playerId: '',
  createdBy: '',
  data: {
    personalData: {
      firstName: '',
      lastName: '',
      birthDate: '',
      username: '',
    },
    spcsVisum: {
      creationDate: new Date().toISOString(),
      openingDateFinalAccount: '',
      statisticsRegistrationDate: '',
      inscriptionDate: '',
    },
    rcsVisum: {
      creationDate: '',
      openingDateFinalAccount: '',
      statisticsRegistrationDate: '',
      inscriptionDate: '',
    },
    scsVisum: {
      creationDate: '',
      openingDateFinalAccount: '',
      statisticsRegistrationDate: '',
      inscriptionDate: '',
    },
    analysis: {
      resultDSMS: [],
      category: NetlossCategory.UNDER_500,
      activeNetLoss: 0,
      dailyPlayTime: 0,
      dailySession: 0,
      origin: '',
      salary: 0,
      budgetSetByCasino: 0,
      landbasedCasino: 0,
    },
    criteriaOverOneMonth: { criteriaA: 0, criteriaB: 0, criteriaC: 0 },
    interventionTypes: {
      criteriasBC: 0,
      greenTeledata: 0,
      orangeTeledata: 0,
      redTeledata: 0,
      coolOffs: 0,
      thirdPartyReporting: '',
      analysis: '',
    },
    decision: {
      allowPlay: '',
      doNotAllowPlay: '',
    },
    text: '',
    auditInterventions: [],
  },
}

const AnnexeInitialState: AnnexeState = {
  filters: {},
  annexes: [],
  isLoading: false,
  currentAnnexe: emptyAnnexeGet,
  baseAnnexe: emptyAnnexe,
  formMode: FormStatusType.CREATE,
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default AnnexeInitialState
