const wallet = {
  manualDeposit: {
    categoryId: {
      undefined: 'Undefined',
      manualDeposit: 'Manual Deposit',
      refundRNG: 'Refund RNG',
      refundLive: 'Refund Live',
      refundCRM: 'Refund CRM',
      manualDupeACC: 'Manual Dupe ACC',
    },
  },
  manualWithdrawals: {
    categoryId: {
      undefined: 'Undefined',
      manualWithdrawal: 'Manual Withdrawal',
      manualDupeACC: 'Manual DUPE ACC',
      fraud: 'Fraud',
      AVS_BS: 'AVS VS',
      AVS_IA: 'AVS IA',
      AVS_JP: 'AVS JP',
      AVS_RES: 'AVS RES',
      AVS_WIN: 'AVS WIN',
    },
  },
  downloadWallet:
    'Reports are downloaded in ranges of 10000 rows, this might take some time.',
}

export default wallet
