const playerCard = {
  accountStatusOptions: {
    reason: {
      gamblingProblems: 'Gambling problems',
      playerRequest: 'Player Request',
      fraud: 'Fraud',
      other: 'Other',
      KYC_VERIFIED: 'KYC Verified',
    },
    subReason: {
      adminAction: 'Admin Action',
      badExperience: 'Bad Experience',
      promotions: 'Promotions',
      service: 'Service',
      bug: 'Bug',
      KYC_VERIFIED: 'KYC Verified',
      noReason: 'No Reason',
      other: 'Other',
    },
  },
  infoSummary: {
    notInCRIF: 'The player does not appear in the CRIF database',
    inCRIF: 'The player appeared in the CRIF database',
    check: 'Check',
    PEP: 'PEP',
    nonPEP: 'Non-PEP',
    showMore: 'Show more...',
    bonusBalance: 'Bonus Balance',
    realBalance: 'Real Balance',
    note: 'Note',
    notes: 'Notes',
    highlight: 'Highlight text',
    bold: 'Bold',
    resetPasswordConfirm: `You're trying to reset the password for the user`,
    otherActions: 'Other Actions',
    manualDeposit: ' Manual Deposit',
    manualCredit: ' Manual Credit',
    manualDebit: ' Manual Debit',
    manualWithdrawal: 'Manual Withdrawal',
    currentRealBalance: 'Current Real Balance',
    amountWithdrawn: 'Amount to be Withdrawn',
    amountAdded: 'Amount to be Added',
    newRealBalance: 'New Real Balance',
    remainingAmount: 'Remaining Amount',
    risk: 'Risk',
    accountStatus: 'Account Status',
    reason: 'Reason',
    subReason: 'Sub-Reason',
    riskLevel: 'Risk Level',
    gamblingProblems: 'Gambling problems',
    playerRequest: 'Player Request',
    fraud: 'Fraud',
    badExperience: 'Bad Experience',
    bug: 'Bug',
    promotions: 'Promotions',
    service: 'Service',
    adminAction: 'Admin Action',
    openPaymentMethods: 'Open Payment Methods',
    paymentMethods: 'Payment Methods',
    other: 'Other',
    paymentData: 'Payment Data',
  },
  playerAccountStatus: {
    INITIAL: 'Initial',
    PROVISIONAL: 'Provisional',
    ACTIVE: 'Active',
    FULLY_IDENTIFIED: 'Fully Identified',
    BLOCKED_FULL: 'Blocked Full',
    BLOCKED_PARTIAL: 'Blocked Partial',
    CLOSED_PROVISIONAL: 'Closed Provisional',
    INACTIVE: 'Inactive',
    CLOSED_ACTIVE: 'Closed Active',
    PENDING_ACTIVE: 'Pending Active',
  },
  tagsAssignRemoveStatus: {
    SUCCESS: 'Success',
    CURRENTLY_ASSIGNED: 'Currently assigned',
    FAIL: 'Failed',
  },
  tagsUnAssignRemoveStatus: {
    SUCCESS: 'Success',
    DOESNT_EXIST: "Doesn't Exist",
    FAIL: 'Failed',
  },
  playerPaymentMethodStatus: {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  noteTags: {
    BONUS: 'Bonus',
    CASINO: 'Casino',
    IMPORTANT: 'Important',
    KYC: 'KYC',
    OTHER: 'Other',
    RESP_GAMING: 'Resp. Gaming',
    TRANSACTIONAL: 'Transactional',
  },
  notePriority: {
    IMPORTANT: 'Important',
    NORMAL: 'Normal',
    LOW: 'Low',
  },
  bonusStatusTypeOptions: {
    ALL: 'All',
    ACCEPTED_AWAITING_EVENT: 'Accepted Awaiting Event',
    ELIGIBLE: 'Eligible',
    FREE_GAME_ACTIVE: 'Active Free Games',
    GRANTED: 'Granted',
    GRANTED_PENDING_ACCEPT: 'Granted Pending Accept',
    GRANTED_PENDING_ACTIVATION: 'Granted Pending Activation',
    GRANTED_FREE_GAMES: 'Granted Free Games',
    CANCELED: 'Cancelled',
    COMPLETED: 'Completed',
    CRITERIA_NOT_MET: 'Criteria Not Met',
    DECLINED: 'Declined',
    ENDED: 'Ended',
    EXCLUDED: 'Excluded',
    EXPIRED: 'Expired',
    GRANTED_EXPIRED: 'Granted Expired',
  },
  alertTypeOptions: {
    ALL: '',
    RISK: 'Risk',
    RISK_MANAGEMENT: 'Risk',
    RETAIL: 'Retail customer specific risks',
    FRAUD_ALERTS: 'Fraud Alerts',
    DEPOSITS_BELOW_100:
      'Three Or More Deposits Below Hundred Within Ten Minutes',
    TOTAL_DEPOSIT_W_10_DAYS:
      'Account Created Within Last Ten Days And Deposits Over Ten Thousand',
    WIN_RATIO_BETS: 'Win Ratio Over Eighty Pc And Bets Over Thousand',
    IP_NOT_REGISTRATION: 'Ip For Deposit Not Same As Registration',
    IP_NOT_EU: 'Ip Not From Eu Or Not Same As CreditCard',
    IP_EU_NOTS: 'Ip From Eu Not Switzerland',
    PROXY_FOR_DEPOSIT: 'Anonymous Proxy For Deposit Recognized',
    LOW_GAMBLING_ACTIVITY: 'Low Gaming Activity Deposits Over Thousand',
    TOTAL_WITHDRAWAL_10_DAYS:
      'Account Created Within Last Ten Days And Withdrawals Over Five Thousand',
    UNUSUAL_BONUS: 'Unusual Bonus Deposit Ratio 1.01',
    OTHER_1: 'Other - Risk Management',
    RESPONSIBLE_GAMING: 'Responsible Gaming',
    CALLBACK_REQUEST: 'Callback Request',
    PERMANENT_EXCLUSION: 'Permanent Exclusion',
    SELF_ASSESSMENT: 'Self Assessment',
    COOLOFFS_CHANGED: 'Cooloffs Changed',
    LIMITS_CHANGED: 'Limits Changed',
    BLACKLIST_VETO: 'Blacklist Veto',
    BLACKLIST_REGISTRATION: 'Blacklist Registration',
    BLACKLIST_EXCLUSION: 'Blacklist Exclusion',
    BLACKLIST_LOGIN: 'Blacklist Login',
    BLACKLIST_CRON_JOB: 'Blacklist Cron Job',
    BLACKLIST_MANUAL: 'Blacklist Manual',
    BLACKLIST_WHITELISTED: 'Blacklist Whitelisted',
    DASHBOARD_BACKEND: 'Dashboard Backend',
    DEPOSIT_METHODS: 'Deposit Methods',
    DEPOSIT_COOLOFF: 'Deposit Cooloff',
    CANCELLED_WITHDRAWALS: 'Cancelled Withdrawals',
    GAMING_TIME: 'Gaming Time',
    NET_LOSS_PER_HOUR: 'Net Loss Per Hour',
    NET_LOSS_THIRTY_DAYS: 'Net Loss Thirty Days',
    NET_LOSS_TWELVE_MONTHS: 'Net Loss Twelve Months',
    INITIATED_COOLOFFS: 'Initiated Cooloffs',
    INCREASED_NET_LOSS: 'Increased Net Loss',
    OTHER_2: 'Other - Responsible Gaming',
    KNOW_YOUR_CUSTOMER: 'KYC',
    UPLOAD_DOCUMENT: 'Upload Document',
    UPLOAD_UTILITY: 'Upload Utility',
    VERIFIED_DOCUMENT: 'Verified Document',
    VERIFIED_UTILITY: 'Verified Utility',
    VERIFIED_EMAIL: 'Verified Email',
    CHANGED_EMAIL: 'Changed Email',
    VERIFIED_ADDRESS: 'Verified Address',
    NOT_MATCH_ADDRESS:
      'The address could not be verified for the following reason:',
    CHANGED_PHONE_NUMBER: 'Changed Phone Number',
    CHANGED_BANKING_DETAILS: 'Changed Banking Details',
    SAME_PLAYER: 'Same Player',
    CHANGED_NATIONALITY: 'Changed Nationality',
    CHANGED_TEST_ACCOUNT: 'Changed Test Account',
    MANUAL_UPLOAD_DOCUMENT: 'Manual Upload Document',
    MANUAL_UPLOAD_UTILITY: 'Manual Upload Utility',
    VERIFIED_MOBILE: 'Verified Mobile',
    CHANGED_USERNAME: 'Changed Username',
    REGISTRATION_INFORMATION: 'Registration Information',
    TEMPORARY_ACCOUNT: 'Temporary Account',
    PLAYER_ACTIVITY: 'Player Activity',
    MONEY_STUFF: 'Money Stuff',
    LARGE_DEPOSIT: 'Large Deposit',
    LARGE_WITHDRAWAL: 'Large Withdrawal',
    MAXIMUM_BALANCE: 'Maximum Balance',
    MANUAL_WITHDRAWAL: 'Manual Withdrawal',
    MANUAL_DEPOSIT: 'Manual Deposit',
    ACCOUNT_STATUS_CHANGE: 'Account Status Change',
    ACCOUNT_CLOSED: 'Account Closed',
    ACCOUNT_CLOSED_PROVISIONAL: 'Account Closed Provisional',
    ACCOUNT_CLOSED_ACTIVE: 'Account Closed Active',
    CRIF: 'CRIF / PEP',
    CRIF_CHECK_MISMATCH: 'CRIF Check Mismatch',
    CRIF_CHECK_LOGIN: 'CRIF Check Login',
    CRIF_CHECK_REGISTRATION: 'CRIF Check Registration',
    CRIF_ADDRESS_CHANGED_PLAYER: 'CRIF Address Changed Player',
    CRIF_ADDRESS_CHANGED_OPERATOR: 'CRIF Address Changed Operator',
    PEP_CHECK_LOGIN: 'PEP Check Login',
    PEP_CHECK_REGISTRATION: 'PEP Check Registration',
    PEP_CHECK_NAME_CHANGED: 'PEP Check Name Changed',
    PEP_CHECK_BENEFICIARY_NAME: 'PEP Check Beneficiary Name',
    PEP_CHANGED_OPERATOR: 'PEP Changed By Operator',
    BALLY: 'Bally',
    BALLY_REGISTRATION_SUCCESS: 'Bally Registration Success',
    BALLY_REGISTRATION_FAILED: 'Bally Registration Failed',
    BANK_ACCOUNT: 'IBAN Verification Document',
    OTHER_3: 'Other - Know Your Customer',
    GAMIFICATION: 'Gamification',
    BONUSES: 'Bonuses',
    BONUS_ADD: 'Bonus Add',
    BONUS_ACTIVATE: 'Bonus Activate',
    BONUS_CANCEL: 'Bonus Cancel',
    BONUS_CANCEL_ALL: 'Bonus Cancel All',
    BONUS_ADD_MANUAL: 'Bonus Add Manual',
    BONUS_ADD_MANUAL_BONUS_CODE: 'Bonus Add Manual Bonus Code',
    BONUS_ACTIVATE_MANUAL: 'Bonus Activate Manual',
    BONUS_CANCEL_MANUAL: 'Bonus Cancel Manual',
    BONUS_CANCEL_ALL_MANUAL: 'Bonus Cancel All Manual',
    OTHER_4: 'Other',
    KYC: 'KYC Status Change',
  },
  riskLevelOptions: {
    UNDEFINED: 'Undefined',
    LOW: 'Low',
    NORMAL: 'Normal',
    MEDIUM: 'Medium',
    HIGH: 'High',
    SEVERE: 'Severe',
  },
  alertStatusOptions: {
    UNDEFINED: 'None',
    NEW: 'New',
    RESOLVED: 'Resolved',
  },
  alertImgStatusOptions: {
    REJECTED: 'Rejected',
    VERIFIED: 'Verified',
  },
  timelineTypeOptions: {
    TRANSACTION: 'Transaction',
    SESSION: 'Session',
    ISSUE: 'Issue',
  },
  timelineSubtypeOptions: {
    TRANSACTIONS: 'Transactions',
    DEPOSIT: 'Deposit',
    WITHDRAW: 'Withdraw',
    MANUAL_DEPOSIT: 'Manual Deposit',
    MANUAL_WITHDRAW: 'Manual Withdraw',
    WITHDRAW_CANCEL: 'Withdraw Cancel',
    SESSIONS: 'Sessions',
    GAME_SESSION: 'Game Session',
    SESSION_CLOSED: 'Game Session Closed',
    SESSION_OPEN: 'Game Session Open',
    ISSUES: 'Issues',
    RISK_MANAGEMENT: 'Risk',
    RESPONSIBLE_GAMING: 'RG',
    KNOW_YOUR_CUSTOMER: 'KYC',
    GAMIFICATION: 'GA',
  },
  playerCardAlerts: {
    newAlert: 'New alert',
    addNewAlert: 'Add new alert',
    addNote: 'Add Note',
    kycDocumentDetails: 'KYC Document Details',
    totalAlerts: 'Total Alerts',
    prop: 'Prop',
    currentlyStoredPersonalDetailsOfThisPlayerInThePlayerCard:
      'Currently stored personal details of this player in the player card',
    documentFirstName: 'Document First Name',
    documentLastName: 'Document Last Name',
    documentBirthday: 'Document Birthday',
    documentNationality: 'Document Nationality',
  },
  tags: {
    assignedTag: 'Successfully assigned tag',
    removedTag: 'Successfully unassigned tag',
    all: 'All Tags',
    categories: 'Categories',
    category: 'Category',
    clickCreate: 'Click the “Create” button to create a new tag',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    createdDescription: 'Description',
    deleteMessage: 'Are you sure you want to delete this role?',
    description: 'Description',
    noTagsYet: 'No tags added yet',
    searchTags: 'Search Tags',
    tag: 'Tag',
    tags: 'Tags',
    playerTags: 'Player tags',
    crmTags: 'CRM tags',
    tagName: 'Tag Name',
    actionCategory: '{{action}} Category',
    actionTag: '{{action}} Tag',
    searchByName: 'Search by name',
    selectFormat: 'Select Format',
    userList: 'User List',
    status: 'Status',
    player: 'Player',
    message: 'Message',
    assignmentsStatus: 'Assignments Status',
    unAssignmentsStatus: 'Unassignments Status',
    players: 'Players',
    playerCount: {
      playersPlural: 'players',
      playersSingular: 'player',
    },
    searchByUsername: 'Search by username',
  },
  gamificationLevelOptions: {
    MEMBER: 'Member',
    SILVER: 'Silver',
    GOLD: 'Gold',
    DIAMOND: 'Diamond',
    BLACKCIRCLE: 'Black Circle',
  },
  address: 'Address',
  alerts: 'Alerts',
  card: 'Card',
  cardIssuer: 'Card Issuer',
  cardNumber: 'Card Number',
  dateOfBirth: 'Date Of Birth',
  emailAddress: 'Email Address',
  exactKeyword: 'Exact Keyword',
  findPlayer: 'Find Player',
  findTransaction: 'Find Transaction',
  firstUsed: 'First Used',
  fullName: 'Full Name',
  includeActiveStatus: 'Include active status account only',
  orderID: 'Order ID',
  paycard: 'Paycard',
  paycardNumber: 'Paycard Number',
  player: 'Player',
  provider: 'Provider',
  subprovider: 'Subprovider',
  totalDeposits: 'Total Deposits',
  totalWithdrawals: 'Total Withdrawals',
  transactionID: 'Transaction ID',
  userID: 'User ID',
  username: 'Username',
  playerCardEmpty: `Welcome to GaminGenius™ Player Card. \n Use the Search bar above to find a user.`,
  fullname: 'Full Name',
  lastname: 'Last Name',
  playerid: 'Player ID',
  registrationip: 'Registration IP',
  phonenumber: 'Phone Number',
  ballyid: 'Bally ID',
  birthdate: 'Birth Date',
  cantChangeStatus: `Can't change from {{currentStatus}} to {{newStatus}} status`,
  cantChangeStatusActive: `Can't change a player's status to Active without max limit deposit`,
  noTagsAvailable: 'No tags available',
  loadingTags: 'Loading...',
  loadingPlayer: 'Loading player info...',
  invalidIban: 'Invalid IBAN',
  invalidBic: 'Invalid BIC',
  viewPlayerCard: 'View Player Card',
  viewPlayer: 'View Player',
  linkAddress: 'The Player Link Address',
  openPlayerNewTab: 'Open Player In New Tab',
  openPlayerNewWindow: 'Open Player in New Window',
  copyPlayerLinkAddress: 'Copy Player Link Address',
  testAccount: 'test account',
  adminOperator: 'Admin Operator',
  system: 'System',
  changedPlayer: 'Changed {{property}} to {{new}}',
  changedPlayerWithPrev: `{{formattedProperty}}: The administrator has changed the {{property}}.
  Previous: {{prevProp}}
  New: {{newProp}}`,
  post: 'Post',
  stopped: 'stopped',
  noteCategory: {
    PAYMENTS: 'Payments',
    BONUSES: 'Bonuses',
    RESPONSIBLE: 'Responsible Gaming',
    GAMING: 'Gaming',
    GAMES: 'Games',
    KYC: 'KYC',
    OTHER: 'Other',
    INFORMATIONABOUTSELFEXCLUSION: 'Information about self exclusion',
    INFORMATIONABOUTCREDIT: 'Information about credit',
    NEGATIVEFINANCIALSTATEMENT: 'Negative financial statement',
    LOSTCONTROL: 'Lost control',
    PERSONALPROBLEMS: 'Personal problems',
    SOCIALPROBLEMS: 'Social problems',
    WHISTLEBLOWER: 'Whistleblower',
    SOCIALSERVICESALERT: 'Social services alert',
    DEPOSITCANCELLATIONREQUEST: 'Deposit cancellation request',
    WITHDRAWALCANCELLATIONREQUEST: 'Withdrawal cancellation request',
  },
  enabled: 'Enabled',
  schedule: 'Schedule',
  timeSettings: 'Time Settings',
  stopNoteDisplayMessage: 'This note will be displayed until',
  stopNoteQuestion: 'Would you like to stop now?',
  stopNow: 'Stop Now',
  invalidNoteDate:
    'Please choose a date and time greater than the current day and time.',
  overWriteNoteQuestion:
    'Are you sure you want to overwrite the current notification?',
  overWriteNoteText:
    'There is a conflict with a notification currently displaying. This player should have just one active scheduled note.',
  invalidNoteAction: 'A note cannot be deleted, it can only be modified.',
  gameCategory: 'Game Category',
  gameProvider: 'Game Provider',
  favoriteGames: 'Favorite Games',
  totalTurnover: 'Total Turnover',
  perc: 'Perc.',
  crm: {
    crm: 'CRM',
    personalizationEngine: 'Personalization Engine',
    campaignHistory: 'Campaign History',
    noSegmentsAssigned: 'No segment assigned',
    noSegmentAssignedDescription: 'This user does not belong to any segment',
    noCampaignsRegistered: 'No registered campaigns',
    noCampaignsRegisteredDescription: 'This user has not received campaigns',
  },
  paymentMethodErrors: {
    duplicateIBAN: 'The IBAN is already in use by another account',
    ibanError: 'Something went wrong, IBAN ERROR',
  },
  verificationEmailWillBeSent:
    'A verification email will be sent to the player.',
  verificationCodeWillBeSent: `A verification code will be sent to the player's phone.`,
  sendVerificationCode: 'Send verification code',
  sendVerificationEmail: 'Send verification email',
  noCard: 'No Card',
  clickBelowToLinkCard: 'Click below to link a card',
}
export default playerCard
