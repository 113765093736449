import ActionTypes from '../constants'
import { RoleState, RoleActions } from '../models'

const createRoleHandler = (
  state: RoleState,
  action: RoleActions,
): RoleState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default createRoleHandler
