import { AnnexeState, AnnexeActions } from '../models'
import ActionTypes from '../constants'
import initialState from './initial-state'

const annexeHandler = (
  state: AnnexeState,
  action: AnnexeActions,
): AnnexeState => {
  switch (action.type) {
    case ActionTypes.CREATE_ANNEXE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.CREATE_ANNEXE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.GET_CREATE_ANNEXE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_CREATE_ANNEXE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentAnnexe: {
          ...state.currentAnnexe,
          data: {
            ...initialState.currentAnnexe.data,
            ...action.payload.message,
          },
        },
      }

    case ActionTypes.GET_ONE_ANNEXE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_ANNEXE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentAnnexe: action.payload.message || initialState.currentAnnexe,
      }

    case ActionTypes.UPDATE_ANNEXE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_ANNEXE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SET_FORM_MODE:
      return {
        ...state,
        formMode: action.mode,
      }

    case ActionTypes.SET_ANNEXE_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default annexeHandler
