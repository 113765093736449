import { call, put, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { Container } from 'typedi'

import i18n from 'i18n'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import FormStatusType from 'state/enums/form-status.enum'
import {
  setFormModeAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { createTemplateAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga creates an on-site notification template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createTemplateOnSiteAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createTemplateOnSiteWorker(
  action: ReturnType<typeof createTemplateAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/onsite/create',
      body: action.payload.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    if (!response._id) {
      yield put(
        createTemplateAction.failure({
          name: response.status,
          message: response.message,
        }),
      )

      yield put(
        setSnackbarAction({
          message:
            typeof response.message === 'string'
              ? response.message
              : i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
      return
    }

    yield put(createTemplateAction.success(response))

    yield put(setFormModeAction(FormStatusType.EDIT))

    yield put(push(`${routes.templates.EDIT_ONSITE}/${response._id}`))

    yield put(action.payload.sideEffect())
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createTemplateAction.failure(error))
  }
}

export default createTemplateOnSiteWorker
