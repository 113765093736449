/**
 *
 * PlayerCardLogin constants
 *
 */

enum ActionTypes {
  GET_ALL = '@pcard-login/GET_ALL',
  GET_ALL_SUBSCRIBER = '@pcard-login/GET_ALL_SUBSCRIBER',
}

export default ActionTypes
