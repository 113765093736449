/**
 *
 * PlayerCardKYC UI constants
 *
 */

enum ActionTypes {
  SET_DOCUMENT_FORM_MODE = '@pcard-kyc/SET_DOCUMENT_FORM_MODE',
  TOGGLE_DOCUMENT_DIALOG = '@pcard-kyc/TOGGLE_DOCUMENT_DIALOG',

  TOGGLE_AML_EDIT = '@pcard-kyc/TOGGLE_AML_EDIT',
  TOGGLE_TELEDATA_DIALOG = '@pcard-kyc/TOGGLE_TELEDATA_DIALOG',

  TOGGLE_AML_DIALOG = '@pcard-kyc/TOGGLE_AML_DIALOG',
  TOGGLE_PEP_DIALOG = '@pcard-kyc/TOGGLE_PEP_DIALOG',
}

export default ActionTypes
