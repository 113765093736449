import React from 'react'
import { useTranslation } from 'react-i18next'
import { InputLabel, TextField, Box, Button } from '@material-ui/core'

import { StateSchema } from 'hooks/form/models'
import ShortcutType from 'state/enums/shortcut.enum'
import { sentenceCase } from 'utils/text/index.utils'

type Props = {
  handleOnChange: (name: string, value: any) => void
  state: StateSchema
  disable: boolean
  handleAddShortcut: (name: string, url: string, type: ShortcutType) => void
  handleUpdateShortcut: () => void
  handleResetAddShortcut: () => void
  isEditing: boolean
  handleShortcutAddClose: () => void
}

const FormURLComponent = ({
  state,
  handleOnChange,
  disable,
  handleAddShortcut,
  handleUpdateShortcut,
  handleResetAddShortcut,
  handleShortcutAddClose,
  isEditing,
}: Props) => {
  const { t } = useTranslation()

  const handleSaveShortcut = () => {
    if (isEditing) {
      handleUpdateShortcut()
    } else {
      handleAddShortcut(state.name.value, state.url.value, ShortcutType.CUSTOM)
    }
  }

  return (
    <>
      <Box mb={1}>
        <InputLabel htmlFor="name">{t('sidebar.shortcutName')}</InputLabel>
        <TextField
          autoComplete="off"
          autoFocus
          name="name"
          id="name"
          fullWidth
          required
          margin="dense"
          variant="outlined"
          value={state.name.value}
          error={state.name.error.length > 0}
          helperText={sentenceCase(state.name.error, true)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(event.target.name, event.target.value)
          }
          inputProps={{
            maxLength: 100,
          }}
        />
      </Box>
      <Box mb={1}>
        <InputLabel htmlFor="url">{t('labels.url')}</InputLabel>
        <TextField
          autoComplete="off"
          name="url"
          id="url"
          fullWidth
          required
          margin="dense"
          variant="outlined"
          value={state.url.value}
          error={state.url.error.length > 0}
          helperText={state.url.error}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleOnChange(event.target.name, event.target.value)
          }
        />
      </Box>
      <Box textAlign="right">
        <Button
          className="danger"
          onClick={isEditing ? handleShortcutAddClose : handleResetAddShortcut}
        >
          {t(isEditing ? 'labels.cancel' : 'labels.clear')}
        </Button>
        <Button
          disabled={disable}
          color="primary"
          variant="contained"
          onClick={handleSaveShortcut}
        >
          {t(isEditing ? 'labels.update' : 'sidebar.addShortcut')}
        </Button>
      </Box>
    </>
  )
}

export default FormURLComponent
