import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

type Props = {
  filter: string
  handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const FiltersTransactionComponent = ({ filter, handleChangeFilter }: Props) => {
  const { t } = useTranslation()

  const filters = ['playerId', 'transactionType', 'transactionStatus']

  return (
    <RadioGroup name="options" value={filter} onChange={handleChangeFilter}>
      {filters.map(item => (
        <FormControlLabel
          key={item}
          value={item}
          control={<Radio />}
          label={t(item)}
          labelPlacement="end"
        />
      ))}
    </RadioGroup>
  )
}

export default FiltersTransactionComponent
