import * as CoolOffModels from '../models'
import ActionTypes from '../constants'

const documentGeneralHandler = (
  state: CoolOffModels.CoolOffState,
  action: CoolOffModels.CoolOffActions,
): CoolOffModels.CoolOffState => {
  switch (action.type) {
    case ActionTypes.SET_COOL_OFF_FILTERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
          pages: Math.ceil(state.pagination.total / (action.payload.limit || state.pagination.limit)),
        }
      }

    default:
      return state
  }
}

export default documentGeneralHandler
