import { alpha } from '@material-ui/core/styles/colorManipulator'
import { red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

import {
  accordionDetailsTheme,
  accordionSummaryTheme,
  accordionTheme,
  appBarTheme,
  avatarTheme,
  badgeTheme,
  breadcrumbsTheme,
  buttonGroupTheme,
  buttonTheme,
  cartActionAreaTheme,
  chipTheme,
  colors,
  cssbaseline,
  dialogActionsTheme,
  dialogTheme,
  dialogTitleTheme,
  dividerTheme,
  filledInputTheme,
  formControlLabelTheme,
  formControlTheme,
  formHelperTheme,
  formLabelTheme,
  iconButtonTheme,
  inputAdornmentTheme,
  inputBaseTheme,
  inputLabelTheme,
  linkTheme,
  listItemIconTheme,
  listItemTextTheme,
  menuItemTheme,
  outlinedInputTheme,
  paperTheme,
  popoverTheme,
  selectTheme,
  stepConnectorTheme,
  stepContentTheme,
  stepLabelTheme,
  stepperTheme,
  stepTheme,
  svgIconTheme,
  switchTheme,
  tableCellTheme,
  tableTheme,
  tablePaginationTheme,
  tableRowTheme,
  tableSortLabelTheme,
  tabsTheme,
  tabTheme,
  toolbarTheme,
  tooltipTheme,
  typographyTheme,
} from './theme/index.theme'

/**
 * A custom theme for this app
 */
const theme = createTheme({
  typography: {
    fontFamily: 'Avenir',
  },
  palette: {
    type: 'dark',
    primary: {
      light: colors.blue,
      dark: colors.blue,
      main: colors.blue,
      contrastText: colors.white,
    },
    secondary: {
      light: colors.blue,
      dark: colors.blue,
      main: colors.blue,
      contrastText: colors.white,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: colors.blueDark,
      paper: colors.blueMedium,
    },
    text: {
      primary: alpha(colors.white, 0.7),
    },
  },
  overrides: {
    MuiAccordion: accordionTheme,
    MuiAccordionDetails: accordionDetailsTheme,
    MuiAccordionSummary: accordionSummaryTheme,
    MuiAppBar: appBarTheme,
    MuiAvatar: avatarTheme,
    MuiBadge: badgeTheme,
    MuiBreadcrumbs: breadcrumbsTheme,
    MuiButton: buttonTheme,
    MuiButtonGroup: buttonGroupTheme,
    MuiCardActionArea: cartActionAreaTheme,
    MuiChip: chipTheme,
    MuiCssBaseline: {
      '@global': cssbaseline,
    },
    MuiDialog: dialogTheme,
    MuiDialogActions: dialogActionsTheme,
    MuiDialogTitle: dialogTitleTheme,
    MuiDivider: dividerTheme,
    MuiFilledInput: filledInputTheme,
    MuiFormControl: formControlTheme,
    MuiFormControlLabel: formControlLabelTheme,
    MuiFormHelperText: formHelperTheme,
    MuiFormLabel: formLabelTheme,
    MuiIconButton: iconButtonTheme,
    MuiInputAdornment: inputAdornmentTheme,
    MuiInputBase: inputBaseTheme,
    MuiInputLabel: inputLabelTheme,
    MuiLink: linkTheme,
    MuiListItemIcon: listItemIconTheme,
    MuiListItemText: listItemTextTheme,
    MuiMenuItem: menuItemTheme,
    MuiOutlinedInput: outlinedInputTheme,
    MuiPaper: paperTheme,
    MuiPopover: popoverTheme,
    MuiSelect: selectTheme,
    MuiStep: stepTheme,
    MuiStepConnector: stepConnectorTheme,
    MuiStepContent: stepContentTheme,
    MuiStepLabel: stepLabelTheme,
    MuiStepper: stepperTheme,
    MuiSvgIcon: svgIconTheme,
    MuiSwitch: switchTheme,
    MuiTab: tabTheme,
    MuiTableCell: tableCellTheme,
    MuiTable: tableTheme,
    MuiTablePagination: tablePaginationTheme,
    MuiTableRow: tableRowTheme,
    MuiTableSortLabel: tableSortLabelTheme,
    MuiTabs: tabsTheme,
    MuiToolbar: toolbarTheme,
    MuiTooltip: tooltipTheme,
    MuiTypography: typographyTheme,
  },
})

export default theme
