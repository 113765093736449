enum RiskLevelAlerts {
  UNDEFINED,
  LOW,
  NORMAL,
  MEDIUM,
  HIGH,
  SEVERE,
}

export default RiskLevelAlerts
