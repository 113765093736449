export enum SidebarId {
  HOME = 10,

  /**
   * ACCOUNT STORE
   */
  ACCOUNT_STORE = 20,

  WITHDRAWALS = 21,
  ISSUES = 22,

  /**
   * CRM
   */
  CRM = 30,

  CAMPAIGNS = 31,
  CAMPAIGNS_CREATE = 32,
  CAMPAIGNS_LIBRARY = 33,
  CAMPAIGNS_ANALYTICS = 34,
  CAMPAIGNS_ARCHIVE = 35,

  SEGMENTS = 36,
  SEGMENTS_CREATE = 37,
  SEGMENTS_LIBRARY = 38,
  SEGMENTS_ARCHIVE = 39,

  FREQUENCY_CAP = 320,

  TEMPLATES = 310,
  TEMPLATES_EMAIL = 311,
  TEMPLATES_SMS = 312,
  TEMPLATES_LIBRARY_EMAIL = 313,
  TEMPLATES_LIBRARY_SMS = 314,
  TEMPLATES_ARCHIVE_EMAIL = 315,
  TEMPLATES_ARCHIVE_SMS = 316,
  TEMPLATES_CREATE_EMAIL = 317,
  TEMPLATES_CREATE_SMS = 318,
  TEMPLATES_LIBRARY_ONSITE = 321,

  CUSTOM_TRIGGERS = 330,
  CUSTOM_TRIGGERS_CREATE = 331,
  CUSTOM_TRIGGERS_LIBRARY = 332,
  CUSTOM_TRIGGERS_ARCHIVE = 333,

  MEDIA_DATABASE = 319,

  /**
   * RULES ENGINE
   */
  RULES_ENGINE = 40,

  RULES_CREATE = 41,
  RULES_LIBRARY = 42,
  RULES_ARCHIVE = 43,

  /**
   * BONUS ENGINE
   */
  BONUS_ENGINE = 50,

  BONUS_OFFERS = 51,
  BONUS_OFFERS_CREATE = 52,
  BONUS_CAMPAIGNS = 53,
  BONUS_CAMPAIGNS_CREATE = 54,

  /**
   * TAGS
   */
  TAGS = 60,

  PLAYER_TAGS = 61,
  CRM_TAGS = 62,

  /**
   * REPORTS
   */
  REPORTS = 70,

  VETO_INTERNAL_BLACKLIST = 71,
  VETO_INTERNAL_BLACKLIST_AUDIT = 72,
  TELEDATA = 73,
  REFER_FRIEND = 74,
  TRANSACTIONS = 75,
  GGR_REPORT = 76,
  BONUS_REPORT = 77,
  PLAYER_REPORT = 78,

  /**
   * GAME STORE
   */
  GAME_STORE = 80,

  GAME_STORE_GAMES = 81,
  GAME_STORE_PROVIDERS = 82,

  /**
   * GAMIFICATION
   */
  GAMIFICATION = 90,

  REWARD_SHOP_PRODUCTS = 91,
  REWARD_SHOP_ITEMS = 92,
  REWARD_SHOP = 93,
  REWARD_SHOP_CREATE = 94,
  LEVELS = 95,

  /**
   * SETTINGS
   */
  SETTINGS = 100,

  CLIENT_SETTINGS = 101,
  USER_MANAGEMENT = 102,
  USER_LOGS = 103,
}

export default SidebarId
