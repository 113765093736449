import { takeLatest } from 'redux-saga/effects'
import { RoleActions } from '..'
import createRoleWorker from './create.saga'

import deleteRoleWorker from './delete.saga'
import getAllRolesWorker from './get-all.saga'
import getOneRoleWorker from './get-one.saga'

import getRoleUsersWorker from './get-role-users.saga'
import updateRoleWorker from './update.saga'

/**
 * Main Saga for watching saga workers in the role state shape
 *
 * @generator
 */
function* roleSagasWatcher() {
  yield [
    yield takeLatest(RoleActions.getOneRoleAction.request, getOneRoleWorker),
    yield takeLatest(RoleActions.getAllRolesAction.request, getAllRolesWorker),
    yield takeLatest(RoleActions.createRoleAction.request, createRoleWorker),
    yield takeLatest(RoleActions.deleteRoleAction.request, deleteRoleWorker),
    yield takeLatest(RoleActions.updateRoleAction.request, updateRoleWorker),
    yield takeLatest(
      RoleActions.getRoleUsersAction.request,
      getRoleUsersWorker,
    ),
  ]
}

export default roleSagasWatcher
