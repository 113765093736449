export const PLAYER_SEARCH_NAMESPACE = 'dataplayers' as 'dataplayers'
export const ADMIN_LOGS_NAMESPACE = 'adminLogs' as 'adminLogs'

const PLAYER = {
  GET_PLAYER: 'get-player-card' as 'get-player-card',
  UPDATE_PLAYER_CARD: 'auth/player-card-update' as 'auth/player-card-update',

  UPDATE_PAYMENT_DATA: 'auth/payment-data-update' as 'auth/payment-data-update',

  RESET_PASSWORD: 'resetPassword' as 'resetPassword',

  UPDATE_ACCOUNT_STATUS_ACTIVE: 'adbe/v1/auth/accountStatus/reactivate' as 'adbe/v1/auth/accountStatus/reactivate',
  UPDATE_ACCOUNT_STATUS_BLOCK: 'auth/api/v1/account/block-manual' as 'auth/api/v1/account/block-manual',
  UPDATE_ACCOUNT_STATUS_CLOSE: 'auth/api/v1/account/close' as 'auth/api/v1/account/close',
  UPDATE_ACCOUNT_STATUS_FULLY_IDENTIFIED: 'auth/api/v1/account/set-fully-identified' as 'auth/api/v1/account/set-fully-identified',

  RESEND_EMAIL: 'resend-email' as 'resend-email',
  GET_PLAYERS: 'findPlayers' as 'findPlayers',
  GET_PLAYERS_EXCEPTION: 'exception' as 'exception',

  GET_ACCOUNT_STATUS_LOGS: 'getAccountStatus' as 'getAccountStatus',
  CREATE_ACCOUNT_STATUS: 'createAccountStatus' as 'createAccountStatus',
  INSERT_ISSUE_ADMIN: 'insert-issue-admin' as 'insert-issue-admin',

  RESEND_SMS: 'auth/send-phone-number-verification-sms' as 'auth/send-phone-number-verification-sms',
  CHECK_IS_SAME_PLAYER: 'auth/api/v1/admin/check-is-same-player' as 'auth/api/v1/admin/check-is-same-player',
  GET_PLAYER_LOGINS: 'loginPlayerHistory',

  DISABLE_TWO_FACTOR: 'auth/api/v1/two-factor-auth/disable-admin' as 'auth/api/v1/two-factor-auth/disable-admin',

  UNLINK_BARRIERE_CLUB_CARD: 'auth/api/v1/barriere-club-card/unlink-card' as 'auth/api/v1/barriere-club-card/unlink-card',
  LINK_BARRIERE_CLUB_CARD: 'auth/api/v1/barriere-club-card/link-card' as 'auth/api/v1/barriere-club-card/link-card',

  EMAIL_UPDATE: 'auth/email-update' as 'auth/email-update',
}

export default PLAYER
