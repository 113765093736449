const placeholders = {
  triggerOptions: {
    TIME_TRIGGER: 'Time Trigger',
    RESET_PASS: 'Reset Password',
    REGISTER: 'Registration',
    BONUS_GRANTED: 'Bonus Granted',
    BONUS_RELEASED: 'Bonus Released',
    DEPOSIT: 'Deposit Successful',
    WAGER: 'Wager',
    WIN: 'Win',
    ACTIVATE_ACCOUNT_REQUEST: 'Verify Account Request',
    ACTIVATE_ACCOUNT: 'Verified Account',
    ACCOUNT_STATUS_CHANGED: 'Account Status Changed',
    KYC_VERIFICATION: 'KYC Verification',
    CALLBACK_REQUEST: 'Callback Request to Support',
    ACCOUNT_SUSPENDED: 'Account Suspended',
    PASSWORD_CHANGED: 'Password Changed',
    PERMANENT_EXCLUSION: 'Permanent Exclusion',
    LOST_SEVENTY_FIVE: 'Net Loss 75%',
    ACCOUNT_SUSPENDED_ALERT_B: 'Alert B',
    AML_BLOCKED: 'AML Blocked',
    REFER_FRIEND_INVITED: 'Refer A Friend - Godson',
    DOCUMENT_REJECTED: 'Document Rejected',
    REJECTED_PAYMENT: 'Deposit Rejected',
    BONUS_EXPIRATION: 'Bonus Expiration',
    REFER_FRIEND_PLAYER: 'Refer A Friend - Godfather',
    NOTE_ADDED: 'Note Added',
    PLAYER_TAG: 'Player Tag',
    VISITS: 'Visits',
    LOGIN: 'Login',
    EMERGENCY_CALLBACK_REQUEST: 'Emergency Callback',
    LAND_BASED_OBSERVATION: 'Land Based Observation',
    SELF_ASSESSMENT: 'Self Assessment Test',
    LIMITS: 'Limits',
    COOL_OFF: 'Cool Off',
    LOGIN_ATTEMPTS: 'Failed Login Attempts',
    WITHDRAW_REQUESTED: 'Withdrawal Pending',
    WITHDRAW_REJECTED: 'Withdrawal Rejected by Admin',
    WITHDRAW_COMPLETED: 'Withdrawal Completed',
    KYC_STATUS_CHANGED: 'KYC Status Changed',
    AFTER_ACTIVATE_ACCOUNT: 'One Hour After Account Creation',
    WITHDRAW_CANCELED_BY_PLAYER: 'Withdrawal Cancelled By Player',
    DOCUMENT_APPROVED: 'Document Approved',
    RESEND_SMS: 'Resend SMS',
    DELETE_ACCOUNT: 'Delete Account',
    WITHDRAWAL: 'Withdrawal',
  },
  placeholderCategories: {
    PLAYER: 'Player Data',
    TRANSACTIONAL: 'Transactional Data',
    RULES_ENGINE: 'Rules Engine',
    RULES_ENGINE_SEGMENTS: 'Rules Engine - Segments',
    INVITE_GODFATHER: 'Invite friend Godfather Data',
    INVITE_GODSON: 'Invite Friend Godson Data',
    INVITE_EVENT: 'Invite Friend Event Data',
  },
}

export default placeholders
