import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { setSnackbarAction } from 'state/ui/global/actions'
import { getOperatorById } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single operator
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOperatorById.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getOneOperatorWorker(
  action: ReturnType<typeof getOperatorById.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'users/getOperatorById',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.getData, auth.token)

    if (!response?._id) {
      yield put(getOperatorById.failure(response?.message))
      yield put(
        setSnackbarAction({
          message: response?.message,
          open: true,
          variant: 'error',
        }),
      )
    }

    yield put(getOperatorById.success(response))
  } catch (error) {
    yield put(getOperatorById.failure(error))
  }
}

export default getOneOperatorWorker
