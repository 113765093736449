/**
 *
 * Auth reducers
 *
 */
import RoleType from 'state/enums/role.enum'
import ActionTypes from './constants'
import { AuthState, AuthActions } from './models'

export const initialState: AuthState = {
  token: '',
  legacyToken: '',
  tokenTimeout: 5000,
  isLoading: false,
  hasError: false,
  isAuth: false,
  error: '',
  wasDeleted: false,
  userSession: {
    _id: '',
    username: '',
    role: [RoleType.CRM],
    companyId: '67',
    iat: 0,
    exp: 0,
  },
  sidebarIds: [],
}

const authReducer = (
  state: AuthState = initialState,
  action: AuthActions,
): AuthState => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        token: action.payload.accessToken,
      }

    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        hasError: true,
        error: action.payload.message,
      }

    case ActionTypes.LOGIN_CANCEL:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        hasError: false,
        error: action.payload,
      }

    case ActionTypes.REMOVE_TOKEN:
      return { ...state, token: undefined }

    case ActionTypes.SET_SESSION:
      return { ...state, userSession: action.payload }

    case ActionTypes.SET_LEGACY_TOKEN:
      return { ...state, legacyToken: action.payload }

    case ActionTypes.REMOVE_LEGACY_TOKEN:
      return { ...state, legacyToken: undefined }

    case ActionTypes.SET_SIDEBAR_IDS:
      return { ...state, sidebarIds: action.payload }

    default:
      return state
  }
}

export default authReducer
