const annexe = {
  annexe9: 'Annexe 9',
  annexeId: 'Annexe ID',
  createdDate: 'Created Date',
  interventionTotal: 'Intervention Total',
  decisionTotal: 'Decision Total',
  createdBy: 'Created By',
  filterById: 'Filter by ID',
  createAnnexe9: 'Create Annexe 9',
  exportAsXLS: 'Export as XLS',
  updateAnnexe9: 'Update Annexe 9',
  noInterventions: 'No Interventions',
}

export default annexe
