import { fork, takeLatest } from 'redux-saga/effects'
import {
  createSegmentAction,
  getAllSegmentAction,
  getOneSegmentAction,
  updateSegmentAction,
  updateStatusSegmentAction,
  archiveSegmentAction,
  getAllSegmentsArchiveAction,
  duplicateSegmentAction,
  createSegmentCsvAction,
} from '../actions/rest.actions'
import createSegmentWorker from './create.saga'

import getAllSegmentWorker from './get-all.saga'
import getOneSegmentWorker from './get-one.saga'
import updateSegmentWorker from './update.saga'

import updateStatusSegmentWorker from './update-status.saga'
import archiveSegmentWorker from './archive.saga'
import getAllSegmentsArchiveWorker from './get-all-archive.saga'

import duplicateSegmentWorker from './duplicate.saga'
import createSegmentCsvWorker from './create-csv.saga'
import playersCountFlow from './players-count.saga'

/**
 * Main Saga for watching saga workers  in the Segment state shape
 *
 * @generator
 */
function* segmentSagasWatcher() {
  yield [
    yield takeLatest(createSegmentAction.request, createSegmentWorker),
    yield takeLatest(createSegmentCsvAction.request, createSegmentCsvWorker),
    yield takeLatest(getAllSegmentAction.request, getAllSegmentWorker),
    yield takeLatest(archiveSegmentAction.request, archiveSegmentWorker),
    yield takeLatest(getOneSegmentAction.request, getOneSegmentWorker),
    yield takeLatest(updateSegmentAction.request, updateSegmentWorker),
    yield takeLatest(
      updateStatusSegmentAction.request,
      updateStatusSegmentWorker,
    ),
    yield takeLatest(
      getAllSegmentsArchiveAction.request,
      getAllSegmentsArchiveWorker,
    ),
    yield takeLatest(duplicateSegmentAction.request, duplicateSegmentWorker),
    yield fork(playersCountFlow),
  ]
}

export default segmentSagasWatcher
