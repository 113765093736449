enum TransactionStatus {
  APPROVED = 1,
  REJECTED,
  PENDING,
  CANCELED,
  EXECUTED,
  COMPLETED,
  DECLINED,
  LATE_BONUS_WIN,
}

export default TransactionStatus
