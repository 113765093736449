import * as LimitModels from '../models'
import ActionTypes from '../constants'

const removeLimitHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.REMOVE_LIMIT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.REMOVE_LIMIT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SET_GAMING_LIMITS:
      return {
        ...state,
        allLimits: state.allLimits.filter(
          // eslint-disable-next-line no-underscore-dangle
          limit => limit.id !== action.payload,
        ),
      }

    default:
      return state
  }
}

export default removeLimitHandler
