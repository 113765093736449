import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'

const templateUpdateHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return { ...state, isLoadingFull: true }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.map(template => {
          return template
        }),
        isLoadingFull: false,
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoadingFull: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default templateUpdateHandler
