import ActionTypes from '../constants'
import { PlayerCardTagActions, PlayerCardTagState } from '../models'

const playerTagGetAllHandler = (
  state: PlayerCardTagState,
  action: PlayerCardTagActions,
): PlayerCardTagState => {
  switch (action.type) {
    case ActionTypes.GET_ALL:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ALL_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        playerTags: action.payload.docs || [],
      }

    default:
      return state
  }
}

export default playerTagGetAllHandler
