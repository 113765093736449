import { colors } from './colors.theme'

const { blue } = colors

export const toolbarTheme = {
  root: {
    '&.toolbarTable': {
      paddingLeft: 16,
      paddingRight: 8,
      transition: `all .4s ease-in`,
      '&.highlight': {
        backgroundColor: blue,
        transition: `all 0.5s ease-out`,
      },
    },
  },
}
