import { createReducer } from 'typesafe-actions'
import { RoleState, RoleActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import filtersHandler from './filters.handler'
import getOneRoleHandler from './get-one.handler'

import getAllRolesHandler from './get-all.handler'
import createRoleHandler from './create.handler'
import deleteRoleHandler from './delete.handler'

import updateRoleHandler from './update.handler'
import getRoleUsersHandler from './get-role-users.handler'

/**
 * Creates a Roles Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const roleReducer = createReducer<RoleState, RoleActions>(initialState)
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    getOneRoleHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
    ],
    getAllRolesHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    createRoleHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_REQUEST,
      ActionTypes.DELETE_SUCCESS,
      ActionTypes.DELETE_FAILURE,
    ],
    deleteRoleHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    updateRoleHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ROLE_USERS_REQUEST,
      ActionTypes.GET_ROLE_USERS_SUCCESS,
      ActionTypes.GET_ROLE_USERS_FAILURE,
    ],
    getRoleUsersHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], filtersHandler)

export default roleReducer
