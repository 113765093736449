import { createReducer } from 'typesafe-actions'
import ActionTypes from '../constants'
import { EmailBuilderState, TemplateEmailActions } from '../models'

import emailBuilderHandler from './index.handler'
import initialState from '../initial-state'

const emailBuilderReducer = createReducer<
  EmailBuilderState,
  TemplateEmailActions
>(initialState).handleAction(
  [
    ActionTypes.UPDATE_EMAILS_INFO,
    ActionTypes.UPDATE_EMAIL_TEST,
    ActionTypes.UPDATE_ACTIVE_TAB,
    ActionTypes.UPDATE_DIALOG,
    ActionTypes.UPDATE_POPOVER,
    ActionTypes.UPDATE_LANGUAGES,
    ActionTypes.SET_FORM_MODE,
    ActionTypes.RESET_TEMPLATE,
    ActionTypes.UPDATE_ID,
  ],
  emailBuilderHandler,
)

export default emailBuilderReducer
