enum ActionTypes {
  CREATE_USER_SETTINGS_REQUEST = '@settings/CREATE_USER_SETTINGS_REQUEST',
  CREATE_USER_SETTINGS_SUCCESS = '@settings/CREATE_USER_SETTINGS_SUCCESS',
  CREATE_USER_SETTINGS_FAILURE = '@settings/CREATE_USER_SETTINGS_FAILURE',

  FILTER_USER_SETTINGS_REQUEST = '@settings/FILTER_USER_SETTINGS_REQUEST',
  FILTER_USER_SETTINGS_SUCCESS = '@settings/FILTER_USER_SETTINGS_SUCCESS',
  FILTER_USER_SETTINGS_FAILURE = '@settings/FILTER_USER_SETTINGS_FAILURE',
  FILTER_USER_SETTINGS_CANCEL = '@settings/FILTER_USER_SETTINGS_CANCEL',

  UPDATE_USER_SETTINGS_REQUEST = '@settings/UPDATE_USER_SETTINGS_REQUEST',
  UPDATE_USER_SETTINGS_SUCCESS = '@settings/UPDATE_USER_SETTINGS_SUCCESS',
  UPDATE_USER_SETTINGS_FAILURE = '@settings/UPDATE_USER_SETTINGS_FAILURE',

  APPLY_SETTINGS = '@settings/APPLY_SETTINGS',
  LOAD_SETTINGS = '@settings/LOAD_SETTINGS',
  RESET_SETTINGS = '@settings/RESET_SETTINGS',

  SELECT_COLUMNS = '@settings/SELECT_COLUMNS',
  UNSELECT_COLUMNS = '@settings/UNSELECT_COLUMNS',

  REMOVE_COLUMN_SETTINGS = '@settings/REMOVE_COLUMN_SETTINGS',

  CHANGE_WITHDRAWAL_TYPE = '@settings/CHANGE_WITHDRAWAL_TYPE',
}

export default ActionTypes
