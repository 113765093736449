const email = {
  addBlock: 'Add block ',
  bulletLabel: 'CTA Bullet',
  buttonLabel: 'CTA Button',
  buttonLink: 'CTA Link',
  createAnEmail: 'Create an Email',
  createAnEmailText: 'Start by filling in the information in step 1.',
  creationMode: 'Creation mode',
  desktop: 'Desktop',
  goToEmailTemplates: 'Go To Email Templates',
  emailBlocks: 'Email Blocks',
  emailCreated:
    'The template has been created successfully; if you want to create another one, click on the “Create New Template” button.',
  emailDetails: 'Email Details',
  emailDetailsText:
    'Add the contact information you want to include in your emails.',
  emailPreview: 'Email Preview:',
  emailSettings: 'Email Settings',
  emailSummary: 'Email Summary',
  emailTemplate: 'Email Template',
  exampleParagraph:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  exampleShortParagraph: 'Lorem ipsum dolor sit amet, consectetur',
  exampleShortParagraph2:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
  exampleSubtitle: 'Some Subtitle',
  exampleTitle: 'Some Title',
  helperTextImageLink: 'This is the link where the image will be redirected',
  htmlCode: 'HTML Code',
  imageAlt: 'Alternative text',
  imageLink: 'Image Link',
  inboxPreview: 'Inbox Preview:',
  messageEmailDisclaimer: 'Message size will vary if placeholders are added',
  messageLanguageTextEmail:
    'Select the language and add the information you want to include in your Email. You must enter the text in each chosen language.',
  mobile: 'Mobile',
  noLanguagesSelected: 'No Languages Selected',
  previewText: 'Preview Text',
  requiredFieldsMessage: `Please check the following fields: {{errors}}`,
  saveEmailError:
    'An error occurred while trying to {{mode}} the template. Please try again later.',
  sendEmailError:
    'An error occurred while trying to send the test email. Please try again later.',
  sendTestEmailText:
    'Select the language and enter a valid email to which you want to send a test email.',
  subjectLine: 'Subject Line',
  templateAlreadyExists: 'This name already exists. Please try another.',
  templateDetailsText:
    'Add the name, tags and description with which you want to save your email template. All fields are required.',
  visualBuilder: 'Visual Builder (Recommended)',
  gamrfirst: {
    offer: {
      EN: 'Up to 250 € free',
      IT: 'Fino a 250 € gratis',
      DE: 'Bis zu 250 € gratis',
      FR: 'Jusqu’à 250 € offerts',
    },
    onlineVersion: {
      EN: 'View online version',
      IT: 'Visualizza la versione online',
      DE: 'Online-Version anzeigen',
      FR: 'Voir la version en ligne',
    },
    card1Title: {
      EN: 'AS A REMINDER, YOUR LOGIN DETAILS:',
      IT: 'COME PROMEMORIA, I TUOI DATI DI ACCESSO:',
      DE: 'ZUR ERINNERUNG, IHRE ANMELDEDATEN:',
      FR: 'POUR RAPPEL, VOS IDENTIFIANTS DE CONNEXION:',
    },
    card2Title: {
      EN: {
        part1: `As a new BarriereBet.fr player, take advantage of an exceptional welcome offer`,
        part2: 'up to € 350 * in Free Paris!',
      },
      IT: {
        part1: `Come nuovo giocatore di BarriereBet.fr, approfitta di un'eccezionale offerta di benvenuto`,
        part2: 'fino a € 350 * a Parigi gratis!',
      },
      DE: {
        part1: `Profitieren Sie als neuer BarriereBet.fr-Spieler von einem außergewöhnlichen Willkommensangebot`,
        part2: 'bis zu 350 € * in Free Paris!',
      },
      FR: {
        part1: `En tant que nouveau joueur BarriereBet.fr, profitez d’une offre de bienvenue exceptionnelle`,
        part2: 'jusqu’à 350 €* en Paris Gratuits !',
      },
    },
    card2Description: {
      EN: `* depending on your Carré VIP status`,
      IT: `* a seconda del tuo stato VIP Carré`,
      DE: `* abhängig von Ihrem Carré VIP-Status`,
      FR: `*en fonction de votre statut Carré VIP`,
    },
    cardDescription1: 'Pseudo',
    cardDescription2: {
      EN: `Password`,
      IT: `Parola d'ordine`,
      DE: `Passwort`,
      FR: `Mot de Passe`,
    },
    cardIcon1Description: {
      EN: {
        part1: `To take advantage of this offer,`,
        part2: `you must confirm your BarriereBet.fr account within 30 days by sending us a copy of your identity document and a bank statement (RIB)`,
      },
      IT: {
        part1: `Per approfittare di questa offerta,`,
        part2: `devi confermare il tuo account BarriereBet.fr entro 30 giorni inviandoci una copia del tuo documento di identità e un estratto conto (RIB)`,
      },
      DE: {
        part1: `Um dieses Angebot zu nutzen,`,
        part2: `Sie müssen Ihr BarriereBet.fr-Konto innerhalb von 30 Tagen bestätigen, indem Sie uns eine Kopie Ihres Ausweises und einen Kontoauszug (RIB) senden.`,
      },
      FR: {
        part1: `Pour profiter de cette offre,`,
        part2: `vous devez confirmer votre compte BarriereBet.fr sous 30 jours  en nous envoyant une copie de votre pièce d’identité et un relevé d’identité bancaire (RIB)`,
      },
    },
    cardIcon2Description: {
      EN:
        'You have made a withdrawal request on BarriereBet.fr. We regret to inform you that this was not successful.',
      IT:
        'Hai presentato una richiesta di prelievo su BarriereBet.fr Ci dispiace informarti che non è andata a buon fine.',
      DE:
        'Sie haben auf BarriereBet.fr einen Widerrufsantrag gestellt. Wir bedauern, Ihnen mitteilen zu müssen, dass dies nicht erfolgreich war.',
      FR:
        'Vous avez effectué une demande de retrait sur BarriereBet.fr. Nous sommes au regret de vous annoncer que celle-ci n’a pu aboutir.',
    },
    footerDescription1: {
      EN: `You have received this message because you have agreed to receive news and updates from the GAMRFIRST program by e-mail. If you wish, you can unsubscribe or update your email preferences on the GAMRFIRST settings page.`,
      IT: `Hai ricevuto questo messaggio perché hai accettato di ricevere notizie e aggiornamenti dal programma GAMRFIRST via e-mail. Se lo desideri, puoi annullare l'iscrizione o aggiornare le tue preferenze email nella pagina delle impostazioni di GAMRFIRST.`,
      DE: `Sie haben diese Nachricht erhalten, weil Sie zugestimmt haben, Nachrichten und Updates vom GAMRFIRST-Programm per E-Mail zu erhalten. Wenn Sie möchten, können Sie Ihre E-Mail-Einstellungen auf der Seite mit den GAMRFIRST-Einstellungen abbestellen oder aktualisieren.`,
      FR: `Vous avez reçu ce message, car vous avez accepté de recevoir par e-mail les actualités et les nouveautés du programme GAMRFIRST. Si vous le souhaitez, vous pouvez vous désabonner ou mettre à jour vos préférences relatives aux e-mails sur la page des paramètres GAMRFIRST.`,
    },
    footerDescription2: {
      EN: `Our gaming platform is only accessible to Swiss residents, who are over 18 years of age and are not prohibited from gaming.`,
      IT: `La nostra piattaforma di gioco è accessibile solo ai residenti in Svizzera, che hanno più di 18 anni e non sono autorizzati a giocare.`,
      DE: `Unsere Spielplattform ist nur für Schweizer zugänglich, die über 18 Jahre alt sind und kein Spielverbot haben.`,
      FR: `Notre plateforme de jeux est uniquement accessible aux résidents suisses, qui ont plus de 18 ans et ne sont pas interdits de jeux.`,
    },
    socialMediaDescription1: {
      EN: `Contact our customer service`,
      IT: `Contatta il nostro servizio clienti`,
      DE: `Kontaktieren Sie unseren Kundenservice`,
      FR: `Contactez notre service client`,
    },
    socialMediaDescription2: {
      EN: `Follow us on the networks`,
      IT: `Seguici sulle reti`,
      DE: `Folgen Sie uns in den Netzwerken`,
      FR: `Suivez nous sur les réseaux`,
    },
    telephone: {
      EN: `Telephone`,
      IT: `Telefono`,
      DE: `Telefon`,
      FR: `Téléphone`,
    },
    chat: {
      EN: `Chat`,
      IT: `Chiacchierare`,
      DE: `Plaudern`,
      FR: `Bavarder`,
    },
    form: {
      EN: `Form`,
      IT: `Forma`,
      DE: `Bilden`,
      FR: `Formulaire`,
    },
  },
  goldenGrand: {
    footer1: {
      description1: {
        DE: `Sie haben Fragen?`,
        EN: `Any questions?`,
        FR: `Vous avez des questions ?`,
        IT: `Domande?`,
      },
      description2: {
        DE: `Kein Problem, unser Team Hilft Ihnen gern weiter.`,
        EN: `No problem. Our team is happy to help.`,
        FR: `Aucun problème, notre équipe vous renseignera volontiers.`,
        IT: `Nessun problema, il nostro team è qui per questo.`,
      },
      description3: {
        DE: `Oder per E-Mail an`,
        EN: `Or send an e-mail to`,
        FR: `Ou envoyez un e-mail à`,
        IT: `Oppure ci scriva a`,
      },
      liveChat: {
        DE: `Direkt zum Livechat`,
        EN: `Go to live chat`,
        FR: `Allez directement au live chat`,
        IT: `Vai alla live chat`,
      },
    },
    footer2: {
      description1: {
        DE: `Sichere Ein- und Auszahlungen`,
        EN: `Secure deposits and withdrawals`,
        FR: `Sécurité des dépôts et paiements`,
        IT: `Versamenti e pagamenti sicuri`,
      },
      description2: {
        DE: `Erstklassiger Kundendienst`,
        EN: `First-class customer service`,
        FR: `Service clientèle de premier ordre`,
        IT: `Servizio clienti eccezionale`,
      },
      description3: {
        DE: `Verantwortungsvolles Spielen`,
        EN: `Responsible gaming`,
        FR: `Jeu responsable`,
        IT: `Gioco responsabile`,
      },
      description4: {
        DE: `Zertifiziertes Sicherheitskonzept`,
        EN: `Certified security concept`,
        FR: `Concept de sécurité certifié`,
        IT: `Modello di sicurezza certificato`,
      },
      description5: {
        DE: `Careplay`,
        EN: `Careplay`,
        FR: `Care Play`,
        IT: `Care Play`,
      },
      description6: {
        DE: `Ab 18 Jahren`,
        EN: `From 18 years`,
        FR: `A partir de 18 ans`,
        IT: `A partire da 18 anni`,
      },
    },
    terms: {
      description1: {
        DE: `GOLDEN GRAND ist ein staatlich lizenziertes Casino und eine eingetragene Marke der Airport Casino Basel AG.`,
        EN: `GOLDEN GRAND is a government-licensed casino and a registered trademark of Airport Casino Basel AG.`,
        FR: `GOLDEN GRAND est un casino titulaire d’une licence d’État et une marque déposée d’Airport Casino Basel AG.`,
        IT: `GOLDEN GRAND è un casinò con licenza statale e un marchio registrato di Airport Casino Basel AG.`,
      },
      description2: {
        DE: `Copyright © 2021 GOLDEN GRAND, alle Rechte vorbehalten.`,
        EN: `Copyright © 2021 GOLDEN GRAND, all rights reserved.`,
        FR: `Copyright © 2021 GOLDEN GRAND, tous droits réservés.`,
        IT: `Copyright © 2021 GOLDEN GRAND, tutti i diritti riservati.`,
      },
    },
    greeting: {
      description1: {
        DE: `Wir freuen uns auf Ihren Besuch.`,
        EN: `We look forward to your visit.`,
        FR: `Nous nous réjouissons de votre visite.`,
        IT: `Grazie della visita.`,
      },
      description2: {
        DE: `Ihr GOLDEN GRAND Team`,
        EN: `The GOLDEN GRAND team`,
        FR: `L’équipe GOLDEN GRAND`,
        IT: `Il team GOLDEN GRAND`,
      },
    },
    header2: {
      description1: {
        DE: `GOLDEN GRAND | Möchten Sie die Mitteilung im Browser lesen,`,
        EN: `GOLDEN GRAND | If you would like to read the message in your browser,`,
        FR: `GOLDEN GRAND | Si vous souhaitez lire le message dans votre navigateur,`,
        IT: `GOLDEN GRAND | Se desideri leggere il messaggio nel tuo browser,`,
      },
      description2: {
        DE: `klicken Sie hier`,
        EN: `click here`,
        FR: `cliquez ici`,
        IT: `clicca qui`,
      },
    },
    unsubscribe: {
      description1: {
        DE: `Sie möchten keine E-Mails mehr erhalten,`,
        EN: `if you would like us to stop sending you e-mails.`,
        FR: `Si vous ne souhaitez plus recevoir nos e-mails,`,
        IT: `Se non si desidera più ricevere e-mail,`,
      },
      description2: {
        DE: `klicken Sie bitte hier.`,
        EN: `Click here`,
        FR: `cliquez ici.`,
        IT: `cliccare qui.`,
      },
    },
  },
  hurrah: {
    customTerms: {
      description1: {
        DE: `© 2020-22 Hurrah Casino Neuchâtel. Alle Rechte vorbehalten.`,
        EN: `© 2020-22 Hurrah Casino Neuchâtel. All rights reserved.`,
        FR: `© 2020-22 Hurrah Casino Neuchâtel. Tous les droits sont réservés.`,
        IT: `© 2020-22 Hurrah Casino Neuchâtel. Tutti i diritti riservati.`,
        PT: `© 2020-22 Hurrah Casino Neuchâtel. Todos os direitos reservados.`,
        TR: `© 2020-22 Hurrah Casino Neuchâtel. Tüm hakları saklıdır.`,
      },
      description2: {
        DE: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, Schweiz`,
        EN: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, Switzerland`,
        FR: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, Suisse`,
        IT: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, Svizzera`,
        PT: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, Suíça`,
        TR: `Casino Neuchâtel, Faubourg du Lac 14, 2000 Neuchâtel, İsviçre`,
      },
      option1: {
        DE: `Allgemeine Nutzungsbedingungen`,
        EN: `Terms & Conditions`,
        FR: `Conditions générales d’utilisation`,
        IT: `Termini e condizioni`,
        PT: `Termos e Condições`,
        TR: `Kullanım Koşulları`,
      },
      option2: {
        DE: `Abbestellen`,
        EN: `Unsubscribe`,
        FR: `Se désabonner`,
        IT: `Disiscriviti`,
        PT: `Anular a subscrição`,
        TR: `Abonelikten çık`,
      },
    },
  },
  jackpots: {
    sign: {
      title: {
        DE: 'Schöne Grüße',
        EN: 'Greetings',
        FR: 'Les salutations',
        IT: 'Saluti',
      },
      description: {
        DE: 'Arbeitsposition',
        EN: 'Work position',
        FR: 'Position de travail',
        IT: 'Posizione di lavoro',
      },
    },
    terms: {
      description: {
        DE: `Allgemeine Nutzungsbedingungen:`,
        EN: `Terms & Conditions:`,
        FR: `Conditions générales d’utilisation:`,
        IT: `Termini e condizioni:`,
      },
    },
    nav: {
      link1: {
        DE: 'ÜBER UNS',
        EN: 'ABOUT US',
        FR: 'QUI SOMMES-NOUS',
        IT: 'SU DI NOI',
      },
      link2: {
        DE: 'KONTAKT',
        EN: 'CONTACT',
        FR: 'CONTACT',
        IT: 'CONTATTO',
      },
      link3: {
        DE: 'AGB',
        EN: 'AGB',
        FR: 'AGB',
        IT: 'AGB',
      },
      link4: {
        DE: 'PROMOTIONS',
        EN: 'PROMOTIONS',
        FR: 'PROMOTIONS',
        IT: 'PROMOZIONI',
      },
    },
    header2: {
      description: {
        DE: `NUTZERNAME`,
        EN: `USERNAME`,
        FR: `NOM D'UTILISATEUR`,
        IT: `NOME UTENTE`,
      },
    },
    footer1: {
      description1: {
        DE: `Urheberrechte © 2020`,
        EN: `Copyright © 2020`,
        FR: `Droits d'auteur © 2020`,
        IT: `Diritto d'autore © 2020`,
      },
      description2: {
        DE: `Alle Rechte vorbehalten.`,
        EN: `All Rights Reserved.`,
        FR: `Tous les droits sont réservés.`,
        IT: `Tutti i diritti riservati.`,
      },
    },
  },
  pasino: {
    header1: {
      label: {
        DE: 'Benutzername',
        EN: 'Your username',
        FR: 'Votre identifiant',
        IT: 'Nome utente',
      },
      linkLabel: {
        DE: 'Password vergessen?',
        EN: 'Forgot your password?',
        FR: 'Mot de passe oublié?',
        IT: 'Password dimenticata?',
      },
    },
    footer1: {
      description1: {
        DE: `Copyright © 2022 Casino du Lac Meyrin SA, Route de Pré-Bois 20, CH-1215 Genf 15. Alle Rechte vorbehalten. Spielen kann süchtig machen! Behalten Sie die Kontrolle!`,
        EN: `Copyright © 2022 Casino du Lac Meyrin SA, Route de Pré-Bois 20, CH-1215 Genève 15. All rights reserved. Gambling can be addictive!`,
        FR: `Copyright © 2022 Casino du Lac Meyrin SA, Route de Pré-Bois 20, CH-1215 Genève 15. Tous droits réservés. Jouer peut être addictif! Gardez le contrôle!`,
        IT: `Copyright © 2022 Casino du Lac Meyrin SA, Route de Pré-Bois 20, CH-1215 Genève 15. Tutti i diritti riservati. Giocare può provocare dipendenza!`,
      },
      description2: {
        DE: `Unsere Spielplattform ist gesetzlich nur für Einwohner der Schweiz zugänglich. Online Casino Konzession Nr. 516-014-01.`,
        EN: `Keep control! By law, our gaming platform is not available to non-Swiss residents. Online casino license No. 516-014-01.`,
        FR: `De par la loi, notre plateforme de jeu n'est accessible qu'aux résidents de Suisse. Concession de casino en ligne No. 516-014-01.`,
        IT: `Mantenga il controllo! Per legge, la nostra piattaforma di gioco è disponibile solo per i residenti in Svizzera. Licenza di casinò online N. 516-014-01.`,
      },
      link1: {
        DE: 'Unsere Webseite',
        EN: 'Go to our website',
        FR: 'Accéder à notre site',
        IT: 'Acceda al nostro sito',
      },
      link2: {
        DE: 'Datenschutzbestimmungen',
        EN: 'Privacy policy',
        FR: 'Politique de confidentialité',
        IT: 'Informativa sulla privacy',
      },
      link3: {
        DE: 'Abmelden',
        EN: 'Unsubscribe',
        FR: 'Désinscription',
        IT: `Cancella l'iscrizione`,
      },
    },
  },
}

export default email
