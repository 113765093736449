import IssuesCodeFilters from 'constants/issues/issues-codes-filters.constants'
import ActionTypes from '../constants'
import { IssueState, IssuesActions, Alert } from '../model'

// !VALIDATION: env & client
const getActiveAlertsHandler = (
  state: IssueState,
  action: IssuesActions,
): IssueState => {
  const filterAlerts = (alerts: Alert[]) => {
    let newAlerts: Alert[] = []
    if (process.env.REACT_APP_CLIENT === 'JP') {
      newAlerts = alerts.filter(
        (alert: Alert) =>
          !Object.values(IssuesCodeFilters.JP).find(
            value => value === alert.code,
          ),
      )
    } else if (process.env.REACT_APP_CLIENT === 'PS') {
      newAlerts = alerts.filter(
        (alert: Alert) =>
          !Object.values(IssuesCodeFilters.PS).find(
            (value: any) => alert.code === value,
          ),
      )
    } else if (process.env.REACT_APP_CLIENT === 'HH') {
      newAlerts = alerts.filter(
        (alert: Alert) =>
          !Object.values(IssuesCodeFilters.HH).find(
            (value: any) => alert.code === value,
          ),
      )
    } else {
      newAlerts = alerts.filter(
        (alert: Alert) =>
          !Object.values(IssuesCodeFilters.JP).find(
            (value: any) => alert.code === value,
          ) &&
          !Object.values(IssuesCodeFilters.PS).find(
            (value: any) => alert.code === value,
          ) &&
          !Object.values(IssuesCodeFilters.HH).find(
            (value: any) => alert.code === value,
          ),
      )
    }
    return newAlerts
  }

  switch (action.type) {
    case ActionTypes.GET_CLIENT_ACTIVE_ALERTS:
      return {
        ...state,
      }

    case ActionTypes.GET_CLIENT_ACTIVE_ALERTS_SUBSCRIBER:
      let alertsFilter: Alert[] = [] // eslint-disable-line
      let fraudsFilter: Alert[] = [] // eslint-disable-line
      if (
        action.payload.message &&
        typeof action.payload.message !== 'string'
      ) {
        alertsFilter = filterAlerts(action.payload.message.alerts)
        fraudsFilter = filterAlerts(action.payload.message.fraudAlerts)
      }
      return {
        ...state,
        alertCodes: action.payload.message
          ? typeof action.payload.message === 'string'
            ? {
                alerts: [],
                fraudAlerts: [],
              }
            : {
                alerts: alertsFilter,
                fraudAlerts: fraudsFilter,
              }
          : {
              alerts: [],
              fraudAlerts: [],
            },
      }

    default:
      return state
  }
}

export default getActiveAlertsHandler
