import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white } = colors

export const breadcrumbsTheme = {
  li: {
    '& a,& p': {
      fontSize: 12,
      color: alpha(white, 0.7),
    },
    '& p': {
      fontWeight: 'bold',
    },
    '& a': {
      cursor: 'pointer',
    },
  },
}
