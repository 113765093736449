import { select, call, put } from 'redux-saga/effects'
import { Container } from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { setSnackbarAction } from 'state/ui/global/actions'
import { getOneTemplateEmailAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets one template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOneTemplateEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getOneTemplateWorker(
  action: ReturnType<typeof getOneTemplateEmailAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'templates/email/get/one',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (response._id) {
      yield put(getOneTemplateEmailAction.success(response))
    } else {
      yield put(
        setSnackbarAction({
          message: '',
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(getOneTemplateEmailAction.failure(error))
  }
}

export default getOneTemplateWorker
