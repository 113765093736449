const userLogs = {
  bonusCampaignCreatedDetails: `A new bonus campaign with the name: '{{name}}' and id: '{{id}}' was created`,
  bonusCampaignCreatedSubject: `A bonus campaign was created '{{name}}'`,
  bonusCampaignUpdated: `The bonus campaign '{{name}}' with id: '{{id}}' was updated.`,
  bonusOfferCreatedDetails: `A new bonus offer with the name: '{{name}}' and id: '{{id}}' was created`,
  bonusOfferCreatedSubject: `A bonus offer was created '{{name}}'`,
  bonusOfferUpdated: `The bonus offer '{{name}}' with id: '{{id}}' was updated.`,
  databaseCreatedDetails: `A {{what}} with the name: '{{name}}' was created`,
  databaseCreatedSubject: `A {{what}} was created in the media database: '{{name}}'`,
  databaseDeletedDetails: `A {{what}} with the name: '{{name}}' was deleted`,
  databaseDeletedSubject: `A {{what}} was deleted from the media database: '{{name}}'`,
  databaseUpdated: `The file '{{name}}'  was updated.`,
  gameUpdatedLog: `The game '{{name}}' was updated.`,
  gamesUpdatedLog: `The games '{{name}}' were updated.`,
  noChangesMade: 'No changes were made',
}

export default userLogs
