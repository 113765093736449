const BONUS_REPORT = {
  GET_BONUS_OFFERS_REPORT: 'bonus/bonus-offers-report' as 'bonus/bonus-offers-report',
  GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT: 'bonus/bonus-offers-on-campaign-report' as 'bonus/bonus-offers-on-campaign-report',

  GET_BONUS_PLAYER_COUNT: 'bonus/bonus-granted-players-report' as 'bonus/bonus-granted-players-report',
  GET_STATE_REPORT: 'bonus/bonus-offer-state-report' as 'bonus/bonus-offer-state-report',

  GET_DEPOSIT_TRIGGER_REPORT: 'bonus/bonus-deposit-trigger-report' as 'bonus/bonus-deposit-trigger-report',
}

export default BONUS_REPORT
