import { RoleState } from '../models'
import { roles, rights, usersByRole } from './data.test'

const initialState: RoleState = {
  hasError: false,
  isLoading: false,
  error: '',
  wasDeleted: false,
  roles,
  filters: {},
  rights,
  currentRoleUsers: usersByRole,
}

export default initialState
