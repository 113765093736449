import { select, call, put, delay } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18next'

import ApiService from 'services/api/index.service'
import QueryBuilder from 'services/query-builder/index.service'
import { setSnackbarAction } from 'state/ui/global/actions'

import { getOneCompanyAction } from 'state/company/actions/rest.actions'
import routes from 'routes/index.routes'
import { createResetPassTokenAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the user reset password token
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createResetPassTokenAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* resetPasswordTokenWorker(
  action: ReturnType<typeof createResetPassTokenAction.request>,
) {
  const { payload } = action

  const queries = new QueryBuilder().query('email', `${payload.email}`, true)
    .all

  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'auth/createResetPasswordToken',
      queries,
    }

    const { auth, router } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (
      !response.username ||
      response.status === 500 ||
      response.error ||
      response.status === 400
    ) {
      yield put(
        createResetPassTokenAction.failure({
          name: 'error',
          message: i18n.t('messages.errorOccurred'),
        }),
      )
      yield put(
        setSnackbarAction({
          message: response.message || i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
    } else {
      yield put(createResetPassTokenAction.success(response))
      if (router?.location?.pathname === routes.users.INDEX) {
        yield delay(500)
        yield put(getOneCompanyAction.request({ _id: response.companyId }))
      }
    }
  } catch (error) {
    yield put(createResetPassTokenAction.failure(error))
  }
}

export default resetPasswordTokenWorker
