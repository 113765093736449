import { CompanyState, CompanyActions } from '../models'
import ActionTypes from '../constants'

const updateCompanyHandler = (
  state: CompanyState,
  action: CompanyActions,
): CompanyState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return { ...state }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        company: action.payload.result,
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default updateCompanyHandler
