const gamification = {
  gamification: 'Gamification',
  rewardShopProducts: 'Reward Shop Products',
  rewardShopItems: 'Reward Shop Items',
  rewardShop: 'Reward Shop',
  createProduct: 'Create Product',
  productId: 'Product ID',
  supplier: 'Supplier',
  searchByNameOrId: 'Search by name or ID',
  selectSupplier: 'Select Supplier',
  selectStatus: 'Select Status',
  createShop: 'Create Shop',
  minLevel: 'Min Level',
  maxLevel: 'Max Level',
  inactive: 'Inactive',
  createItem: 'Create Item',
  selectTag: 'Select Tag',
  availableTo: 'Available To',
  availableFrom: 'Available From',
  limited: 'Limited',
  level: 'Level',
  top: 'Top',
  products: 'Products',
  goToProducts: 'Go To Products',
  createNewProduct: 'Create New Product',
  productName: 'Product Name',
  productDetails: 'Product Details',
  productCode: 'Product Code',
  selectOneOrMore: 'Select one or more',
  physicalItem: 'Physical Item',
  addSupplier: 'Add Supplier',
  desktopProductImage: 'Desktop Product Image',
  mobileProductImage: 'Mobile Product Image',
  enable: 'Enable',
  productSummary: 'Product Summary',
  createANewRewardShopProduct: 'Create a new reward shop product',
  productStatus: 'Product Status',
  inDesign: 'In Design',
  active: 'Active',
  invalidImageUrl: 'This is not a valid image URL',
  createRewardShop: 'Create Reward Shop',
  rewardShopName: 'Reward Shop Name',
  rewardShopDetails: 'Reward Shop Details',
  rewardShopSettings: 'Reward Shop Settings',
  includeItems: 'Include Items',
  rewardShopSummary: 'Reward Shop Summary',
  rewardShopStatus: 'Reward Shop Status',
  createANewRewardShop: 'Create a new reward shop',
  goToRewardShops: 'Go To Reward Shops',
  createNewRewardShop: 'Create New Reward Shop',
  onlyNumbersBetween1And1000: 'Only numbers between 1 and 1000',
  thisValueMustBeLowerThanTheMaxLevel:
    'This value must be lower than the max level',
  thisValueMustBeGreaterThanTheMinLevel:
    'This value must be greater than the min level',
  items: 'Items',
  productSelection: 'Product Selection',
  selectProduct: 'Select Product',
  goToItems: 'Go To Items',
  createNewItem: 'Create New Item',
  displayDetails: 'Display Details',
  sortOrderNo: 'Sort Order No',
  availabilityFrom: 'Availability From',
  dateMustNotBeInThePast: 'Date and time must not be in the past',
  dateMustBeAfter: 'Date must be after start date',
  endDate: 'End Date',
  itemDetails: 'Item Details',
  points: 'Points',
  itemSummary: 'Item Summary',
  rewardShopItemStatus: 'Reward Shop Item Status',
  createANewRewardShopItem: 'Create a new reward shop item',
  price: 'Price',
  quantity: 'Quantity',
  noTags: 'No tags',
  noEndDate: 'No end date',
  gamificationTags: 'Tags',
  processedViaCrm: 'Processed Via CRM',
  balancePoints: 'Balance Points',
  itemSettings: 'Item Settings',
  productSettings: 'Product Settings',
  shopSettings: 'Shop Settings',
  enableRewardShop: 'Enable Reward Shop',
  enableRewardShopItem: 'Enable Reward Shop Item',
  enableRewardShopProduct: 'Enable Reward Shop Product',
  enabled: 'Enabled',
  disabled: 'Disabled',
  rewardShopItem: 'Reward Shop Item',
  rewardShopProduct: 'Reward Shop Product',
  createRewardShopItem: 'Create Reward Shop Item',
  createRewardShopProduct: 'Create Reward Shop Product',
  rewardShops: 'Reward Shops',
  updateRewardShopItem: 'Update Reward Shop Item',
  updateRewardShopProduct: 'Update Reward Shop Product',
  updateRewardShop: 'Update Reward Shop',
  update: 'Update',
  editOrder: 'Edit Order',
  shippingAddress: 'Shipping Address',
  orderDetails: 'Order Details',
  orderHasBeenMade: 'Order has been made',
  cancelOrder: 'Cancel Order',
  goBack: 'Go Back',
  itemId: 'Item ID',
  shopDetailsInstructions:
    'Please provide a name and descriptions for this reward shop',
  enableRewardShopInstructions:
    'Please check the box if you want to enable this reward shop',
  shopSettingsInstructions:
    'Please provide the upcoming values in order to proceed with the next step',
}

export default gamification
