import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white } = colors
export const dividerTheme = {
  root: {
    '&.white': {
      backgroundColor: alpha(white, 0.2),
    },
  },
  light: {
    backgroundColor: alpha(white, 0.2),
  },
}
