import { createReducer } from 'typesafe-actions'
import { CompanyStateUI, CompanyUiActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import companyUiFormHandler from './form.handler'

/**
 * Creates a CompanyUi Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const companyUiReducer = createReducer<CompanyStateUI, CompanyUiActions>(initialState)
  .handleAction(
    [ActionTypes.TOGGLE_MODAL_FORM, ActionTypes.SET_MODAL_FORM_TITLE],
    companyUiFormHandler,
  )

export default companyUiReducer
