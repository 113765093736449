import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useForm from 'hooks/form/use-form.hook'

import { ResetPasswordTokenPayload } from 'state/user/models'
import { createResetPassTokenAction } from 'state/user/actions/rest.actions'
import { StateSchema } from 'hooks/form/models'

import { SendEmailType } from 'state/template-email/models'
import { sendEmailForgotPasswordAction } from 'state/template-email/actions/rest.actions'
import SendDirectEmailType from 'state/enums/send-email-type.enum'

import forgotPasswordEmailTemplate from 'email-htmls/templates/forgot-password.email'
import EmailProviderType from 'state/enums/email-provider'
import Languages from 'state/enums/languages.enum'

import { UserSelectors } from 'state/user'
import forgotSchema from '../components/schema'
import forgotValidator from '../components/validator'

const useForgotPassword = () => {
  const dispatch = useDispatch()

  const { userResetPasswordToken } = useSelector(UserSelectors.root)

  const createResetPasswordToken = (
    resetPasswordTokenPayload: ResetPasswordTokenPayload,
  ) => dispatch(createResetPassTokenAction.request(resetPasswordTokenPayload))

  const sendRecoverPasswordEmail = (userEmailPayload: SendEmailType) =>
    dispatch(sendEmailForgotPasswordAction.request(userEmailPayload))

  useEffect(() => {
    if (userResetPasswordToken?.firstName?.length > 0) {
      /**
       *
       */
      sendRecoverPasswordEmail({
        type: SendDirectEmailType.RESENT_PASSWORD,
        companyId: userResetPasswordToken.companyId,
        provider: {
          type: EmailProviderType.Develop,
          apiKey: `${process.env.REACT_APP_SENDGRID_API_KEY_DEV}`,
          defaultLanguage: `${process.env.REACT_APP_DEFAULT_LANGUAGE}`,
          ipPool: `${process.env.REACT_APP_SENDGRID_IP_POOL_DEV}`,
        },
        from: 'admin@gamingeniusdev.com',
        fromName: 'Gamanza Admin',
        to: userResetPasswordToken.email,
        replyTo: 'no-reply@gamingeniusdev.com',
        replyToName: 'Not Reply',
        templates: [
          {
            language: Languages.EN,
            subject: 'Recover password',
            HTML: forgotPasswordEmailTemplate,
          },
        ],
        placeholders: {
          URL: `${process.env.REACT_APP_SITE_DEV}reset-password?email={{EmailAddress}}&nn=${userResetPasswordToken.passwordResetToken}&username={{UserName}}`,
          EmailAddress: userResetPasswordToken.email,
          FirstName: userResetPasswordToken.firstName,
          LastName: userResetPasswordToken.lastName,
          UserName: userResetPasswordToken.username,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResetPasswordToken])

  /**
   * Create a request to reset the password token
   *
   * @param {StateSchema} state.email
   */
  const onSubmitForm = ({ email }: StateSchema) => {
    createResetPasswordToken({ email: email.value })
  }
  /**
   * This is the form state that uses the hook for forms and returns its state,
   * and a few functions to handle changes and resets
   */
  const { state, handleOnChange, handleOnSubmit } = useForm(
    forgotSchema,
    forgotValidator,
    () => {
      onSubmitForm(state)
    },
  )

  return {
    state,
    handleOnChange,
    handleOnSubmit,
  }
}

export default useForgotPassword
