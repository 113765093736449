import ActionTypes from '../constants'
import { SettingsState, SettingsActions } from '../models'

const filterUserSettingsHandler = (
  state: SettingsState,
  action: SettingsActions,
): SettingsState => {
  switch (action.type) {
    case ActionTypes.FILTER_USER_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.FILTER_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersSettings: action.payload?.docs || [],
      }

    case ActionTypes.FILTER_USER_SETTINGS_FAILURE:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default filterUserSettingsHandler
