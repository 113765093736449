import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import { setSnackbarAction } from 'state/ui/global/actions'
import jsonToQueryString from 'utils/query-string'

import { deleteFrequencyCapAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)
/**
 *
 * This saga delete a Frequency Cap
 *
 *
 *
 * @param {ReturnType<typeof deleteFrequencyCapAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* deleteFrequencyCapWorker(
  action: ReturnType<typeof deleteFrequencyCapAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'campaigns/frequencies/delete/one',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()

    const response = yield call(apiService.sendData, auth.token)

    let message = i18n.t('messages.deletedSuccessfully', {
      item: i18n.t('pages.frequencyCap'),
    })

    if (response._id) {
      yield put(deleteFrequencyCapAction.success(response))
    } else {
      message = response.message
      yield put(deleteFrequencyCapAction.failure(response))
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )

  } catch (error) {
    yield put(deleteFrequencyCapAction.failure(error))
  }
}

export default deleteFrequencyCapWorker
