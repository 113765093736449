export const NOTES_NAMESPACE = 'notes' as 'notes'

const SUMMARY = {
  CREATE_PLAYER_NOTE: 'createNote' as 'createNote',
  GET_PLAYER_NOTES: 'getNotes' as 'getNotes',
  UPDATE_PLAYER_NOTE: 'updateNote' as 'updateNote',
  DELETE_PLAYER_NOTE: 'deleteNote' as 'deleteNote',
}

export default SUMMARY
