import ActionTypes from '../constants'
import { PlayerCardKYCDocumentState, PlayerCardKYCActions } from '../models'

const getJumioDataHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.GET_JUMIO_DATA_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.GET_JUMIO_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jumioData: action.payload.data ? [...action.payload.data.data] : [],
        jumioImages: {
          front:
            action.payload && action.payload.imageFront
              ? URL.createObjectURL(
                  new Blob(
                    [new Uint8Array([...action.payload.imageFront.data])],
                    {
                      type: 'image/png',
                    },
                  ),
                )
              : '',
          back:
            action.payload && action.payload.imageBack
              ? URL.createObjectURL(
                  new Blob(
                    [new Uint8Array([...action.payload.imageBack.data])],
                    {
                      type: 'image/png',
                    },
                  ),
                )
              : '',
        },
      }

    case ActionTypes.GET_JUMIO_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default getJumioDataHandler
