/**
 *
 * EmailTracking reducers
 *
 */

import ActionTypes from './constants'
import { EmailTrackingState, EmailTrackingActions } from './models'

export const initialState: EmailTrackingState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  sendGrid: [],
  text: 'null',
}

const emailTrackingReducer = (
  state: EmailTrackingState = initialState,
  action: EmailTrackingActions,
): EmailTrackingState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_STATS_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ALL_STATS_SUCCESS:
      return {
        ...state,
        sendGrid: [...state.sendGrid, ...action.payload],
        isLoading: false,
      }

    case ActionTypes.GET_ALL_STATS_FAILURE:
      return { ...state, hasError: true, error: action.payload.name }

    default:
      return state
  }
}

export default emailTrackingReducer
