/**
 *
 * Player Card constants
 *
 */

enum ActionTypes {
  RESET_PLAYER_PASSWORD = '@player-card/RESET_PLAYER_PASSWORD',
  RESET_PLAYER_PASSWORD_SUBSCRIBER = '@player-card/RESET_PLAYER_PASSWORD_SUBSCRIBER',

  RESEND_EMAIL = '@player-card/RESEND_EMAIL',
  RESEND_EMAIL_SUBSCRIBER = '@player-card/RESEND_EMAIL_SUBSCRIBER',

  UPDATE_ACCOUNT_STATUS_ACTIVE = '@player-card/UPDATE_ACCOUNT_STATUS_ACTIVE',
  UPDATE_ACCOUNT_STATUS_BLOCK = '@player-card/UPDATE_ACCOUNT_STATUS_BLOCK',
  UPDATE_ACCOUNT_STATUS_CLOSE = '@player-card/UPDATE_ACCOUNT_STATUS_CLOSE',
  UPDATE_ACCOUNT_STATUS_FULLY_IDENTIFIED = '@pcard-kyc/UPDATE_ACCOUNT_STATUS_FULLY_IDENTIFIED',

  GET_PLAYER_VALIDATION = '@player-card/GET_PLAYER_VALIDATION',
  GET_PLAYER_VALIDATION_SUBSCRIBER = '@player-card/GET_PLAYER_VALIDATION_SUBSCRIBER',

  UPDATE_PAYMENT_DATA = '@player-card/UPDATE_PAYMENT_DATA',
  UPDATE_PAYMENT_DATA_SUBSCRIBER = '@player-card/UPDATE_PAYMENT_DATA_SUBSCRIBER',

  GET_PLAYER = '@player-card/GET_PLAYER',
  GET_PLAYER_SUBSCRIBER = '@player-card/GET_PLAYER_SUBSCRIBER',

  GET_PLAYERS = '@player-card/GET_PLAYERS',
  GET_PLAYERS_SUBSCRIBER = '@player-card/GET_PLAYERS_SUBSCRIBER',

  SET_PLAYER_FILTERS = '@player-card/SET_PLAYER_FILTERS',

  GET_PLAYERS_EXCEPTION = '@player-card/GET_PLAYERS_EXCEPTION',
  GET_PLAYERS_EXCEPTION_SUBSCRIBER = '@player-card/GET_PLAYERS_EXCEPTION_SUBSCRIBER',

  GET_ACCOUNT_STATUS = '@player-card/GET_ACCOUNT_STATUS',
  GET_ACCOUNT_STATUS_SUBSCRIBER = '@player-card/GET_ACCOUNT_STATUS_SUBSCRIBER',

  CREATE_ACCOUNT_STATUS = '@player-card/CREATE_ACCOUNT_STATUS',
  CREATE_ACCOUNT_STATUS_SUBSCRIBER = '@player-card/CREATE_ACCOUNT_STATUS_SUBSCRIBER',
  CREATE_ACCOUNT_STATUS_EXCEPTION = '@player-card/CREATE_ACCOUNT_STATUS_EXCEPTION',

  RESET_PLAYER_INFO = '@player-card/RESET_PLAYER_INFO',

  RESEND_SMS = '@player-card/RESEND_SMS',

  // admin legacy
  UPDATE_PLAYER_CARD = '@player-card/UPDATE_PLAYER_CARD',
  UPDATE_PLAYER_CARD_SUBSCRIBER = '@player-card/UPDATE_PLAYER_CARD_SUBSCRIBER',

  GET_PLAYER_CARD = '@player-card/GET_PLAYER_CARD',
  GET_PLAYER_CARD_SUBSCRIBER = '@player-card/GET_PLAYER_CARD_SUBSCRIBER',

  SET_ACCOUNT_TEST = '@player-card/SET_ACCOUNT_TEST',
  SET_ACCOUNT_TEST_SUBSCRIBER = '@player-card/SET_ACCOUNT_TEST_SUBSCRIBER',

  CHECK_IS_SAME_PLAYER = '@player-card/CHECK_IS_SAME_PLAYER',

  SET_PLAYER_PAGINATION = '@player-card/SET_PLAYER_PAGINATION',

  CRIF_CHECK_ADDRESS_SUBSCRIBER = '@player-card/CRIF_CHECK_ADDRESS_SUBSCRIBER',
  GET_PLAYER_ISSUE_COUNT_SUBSCRIBER = '@player-card/GET_PLAYER_ISSUE_COUNT_SUBSCRIBER',

  UNLINK_BARRIERE_CLUB_CARD = '@player-card/UNLINK_BARRIERE_CLUB_CARD',
  LINK_BARRIERE_CLUB_CARD = '@player-card/LINK_BARRIERE_CLUB_CARD',

  DISABLE_TWO_FACTOR = '@player-card/DISABLE_TWO_FACTOR',
  DISABLE_TWO_FACTOR_SUBSCRIBER = '@player-card/DISABLE_TWO_FACTOR_SUBSCRIBER',

  EMAIL_UPDATE = '@player-card/EMAIL_UPDATE',
}

export default ActionTypes
