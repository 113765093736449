const levels = {
  levelsStatus: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  },
  levelsType: {
    STANDARD: 'Standard',
    FINAL: 'Final',
    INVITATION: 'Invitation',
  },
  searchLevels: 'Search',
  allTypeLevels: 'All Types',
  createLevel: 'Create Level',
  levelName: 'Level Name',
  levelDescription: 'Level Description',
  levelType: 'Type',
  levelDetailsInstructions: `As a first step to create your new level, please let's give a Level Name and a Description which easily identifies your new level features`,
  levelSettingsInstructions: `Configure the main settings of your level. Choose from 3 level types: Standard, Final o Invitation. Insert a level hierarchy staring from 1 to X number`,
  enableLevelInstructions: `Please choose whether to enable or disable your level. You may save all level configurations without enabling the level yet`,
  levelSettings: 'Level Settings',
  levelSummary: 'Level Summary',
  enableLevel: 'Enable Level',
  goToLevels: 'Go To Levels',
  createNewLevel: 'Create New Level',
  createANewLevel: 'Create a new level',
  hierarchyNo: 'Hierarchy No',
  levelDetails: 'Level Details',
  selectType: 'Select Type',
  levelGameCategoryContributions: 'Game Category Contributions',
  levelGameCategoryContributionsInstructions: `Please configure the contribution per game category towards earning Experience Points and Virtual Currency`,
  levelStatus: 'Status',
  levelId: 'Level Id',
  searchLevelsPlaceholder: 'Name, ID or Description',
}

export default levels
