import { createReducer } from 'typesafe-actions'
import { PlayerCardLoginState, PlayerCardLoginActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import playerCardLoginGetAllHandler from './get-all.handler'

/**
 * Creates a PlayerCardLogin Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardLoginReducer = createReducer<
  PlayerCardLoginState,
  PlayerCardLoginActions
>(initialState).handleAction(
  [ActionTypes.GET_ALL, ActionTypes.GET_ALL_SUBSCRIBER],
  playerCardLoginGetAllHandler,
)

export default playerCardLoginReducer
