import { takeLatest, debounce } from 'redux-saga/effects'
import {
  createUserAction,
  updateUserAction,
  deleteUserAction,
  resetPasswordAction,
  createResetPassTokenAction,
  getAllUserAction,
  getLoggedInAction,
  getOperators,
  getOperatorById,
  getShortcutsAction,
  createShortcutsAction,
  updateShortcutsAction,
  deleteShortcutsAction,
} from '../actions/rest.actions'
import getAllUserWorker from './get-all.saga'

import updateUserWorker from './update.saga'
import createUserWorker from './create.saga'
import deleteUserWorker from './delete.saga'

import resetPasswordTokenWorker from './get-reset-password-token.saga'
import resetPasswordWorker from './reset-password.saga'
import getCompanyFromSessionWorker from './get-one-company.saga'

import { getOneCompanyAction } from '../../company/actions/rest.actions'
import getLoggedInWorker from './get-logged-in.saga'
import getAllOperatorsWorker from './get-all-operators.saga'

import getOneOperatorWorker from './get-one-operator.saga'
import getShortcutsWorker from './shortcuts-get.saga'
import createShortcutsWorker from './shortcuts-create.saga'

import updateShortcutsWorker from './shortcuts-update.saga'
import deleteShortcutsWorker from './shortcuts-delete.saga'

/**
 * Main Saga for watching saga workers  in the User state shape
 *
 * @generator
 */
function* userSagasWatcher() {
  yield [
    yield debounce(500, getAllUserAction.request, getAllUserWorker),
    yield takeLatest(updateUserAction.request, updateUserWorker),
    yield takeLatest(createUserAction.request, createUserWorker),
    yield takeLatest(deleteUserAction.request, deleteUserWorker),
    yield takeLatest(
      createResetPassTokenAction.request,
      resetPasswordTokenWorker,
    ),
    yield debounce(500, resetPasswordAction.request, resetPasswordWorker),
    yield debounce(
      500,
      getOneCompanyAction.request,
      getCompanyFromSessionWorker,
    ),
    yield takeLatest(getLoggedInAction.request, getLoggedInWorker),
    yield debounce(500, getOperators.request, getAllOperatorsWorker),
    yield debounce(500, getOperatorById.request, getOneOperatorWorker),
    yield debounce(500, getShortcutsAction.request, getShortcutsWorker),
    yield debounce(500, createShortcutsAction.request, createShortcutsWorker),
    yield debounce(500, updateShortcutsAction.request, updateShortcutsWorker),
    yield debounce(500, deleteShortcutsAction.request, deleteShortcutsWorker),
  ]
}

export default userSagasWatcher
