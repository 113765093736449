import { createAsyncAction, createAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import { RoleModels } from '..'

export const setRolesFilters = createAction(ActionTypes.SET_FILTERS, action => {
  return (filters: RoleModels.RolesFilterProps) => action(filters)
})

export const getOneRoleAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<RoleModels.GetOneRolePayload, RoleModels.GetOneRoleResponse, Error>()

export const getAllRolesAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<RoleModels.GetAllRolesPayload, RoleModels.GetAllRolesResponse, Error>()

export const createRoleAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<RoleModels.CreateRolePayload, RoleModels.CreateRoleResponse, Error>()

export const deleteRoleAction = createAsyncAction(
  ActionTypes.DELETE_REQUEST,
  ActionTypes.DELETE_SUCCESS,
  ActionTypes.DELETE_FAILURE,
)<RoleModels.DeleteRolePayload, RoleModels.DeleteRoleResponse, Error>()

export const updateRoleAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<RoleModels.UpdateRolePayload, RoleModels.UpdateRoleResponse, Error>()

export const getRoleUsersAction = createAsyncAction(
  ActionTypes.GET_ROLE_USERS_REQUEST,
  ActionTypes.GET_ROLE_USERS_SUCCESS,
  ActionTypes.GET_ROLE_USERS_FAILURE,
)<RoleModels.GetRoleUsersPayload, RoleModels.GetRoleUsersResponse, Error>()
