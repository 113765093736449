import React from 'react'
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import { CheckBox } from '@material-ui/icons'

import ShortcutType from 'state/enums/shortcut.enum'
import useSidebarActions from '../../hooks/use-sidebar-actions.hook'
import useSidebarLinks from '../../hooks/use-sidebar-links.hook'

import useSidebarShortcuts from '../../hooks/use-sidebar-shortcuts.hook'

type Props = {
  handleAddShortcut: (name: string, url: string, type: ShortcutType) => void
  handleDeleteShortcut: (_id: string) => void
}

const FormMenuComponent = ({
  handleAddShortcut,
  handleDeleteShortcut,
}: Props) => {
  const { links } = useSidebarLinks()

  const { sidebarIds } = useSidebarActions()

  const { shortcuts } = useSidebarShortcuts()

  return (
    <>
      {links
        .filter(link => sidebarIds.includes(link.id) && link.visible)
        .map(link => (
          <Box key={link.id} mb={2}>
            {link.links && link.links.length > 0 ? (
              <>
                <Typography variant="body2">{link.labelText}</Typography>
                <FormGroup>
                  {link.links.map(item => (
                    <Box key={item.id} my={1} ml={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checkedIcon={<CheckBox />}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              if (event.target.checked) {
                                handleAddShortcut(
                                  item.labelText,
                                  item.route,
                                  ShortcutType.MENU,
                                )
                              } else {
                                // eslint-disable-next-line
                                const _id = shortcuts.find(
                                  shortcut => shortcut.name === item.labelText,
                                )?._id
                                _id && handleDeleteShortcut(_id)
                              }
                            }}
                            checked={
                              shortcuts
                                ? shortcuts.some(
                                    shortcut =>
                                      shortcut.name === item.labelText,
                                  )
                                : false
                            }
                          />
                        }
                        label={<Box ml={1}>{item.labelText}</Box>}
                      />
                    </Box>
                  ))}
                </FormGroup>
              </>
            ) : (
              <Box my={1}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checkedIcon={<CheckBox />}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          if (event.target.checked) {
                            handleAddShortcut(
                              link.labelText,
                              link.route,
                              ShortcutType.MENU,
                            )
                          } else {
                            // eslint-disable-next-line
                            const _id = shortcuts.find(
                              shortcut => shortcut.name === link.labelText,
                            )?._id
                            _id && handleDeleteShortcut(_id)
                          }
                        }}
                        checked={
                          shortcuts
                            ? shortcuts.some(
                                shortcut => shortcut.name === link.labelText,
                              )
                            : false
                        }
                      />
                    }
                    label={<Box ml={1}>{link.labelText}</Box>}
                  />
                </FormGroup>
              </Box>
            )}
          </Box>
        ))}
    </>
  )
}

export default FormMenuComponent
