export const roles = [
  {
    accesses: [
      {
        _id: '1',
        uiCode: '1',
        name: 'Access to Player Card section',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
    ],
    _id: '1',
    name: 'Admin Role',
    createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
    updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
  },
  {
    accesses: [
      {
        _id: '5ec42222686dd48a2c732639',
        uiCode: 'ACTOCA_77',
        name: 'Access to Campaigns',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
    ],
    _id: '2',
    name: 'Campaign Role',
    createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
    updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
  },
  {
    accesses: [
      {
        _id: '5',
        uiCode: '5',
        name: 'Edit address',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
      {
        _id: '3',
        uiCode: '3',
        name: 'Edit gender',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
    ],
    _id: '3',
    name: 'Test Role',
    createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
    updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
  },
]

export const rights = [
  {
    access: [
      {
        _id: '1',
        uiCode: '1',
        name: 'Access to Player Card section',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
      {
        _id: '2',
        uiCode: '2',
        name: 'Edit name',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
      {
        _id: '3',
        uiCode: '3',
        name: 'Edit gender',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
      {
        _id: '4',
        uiCode: '4',
        name: 'View address',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
      {
        _id: '5',
        uiCode: '5',
        name: 'Edit address',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
    ],
    _id: '5ec3ffe6686dd431e25eae06',
    name: 'Player Card',
    createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
    updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
  },
  {
    access: [
      {
        _id: '5ec42222686dd48a2c732639',
        uiCode: 'ACTOCA_77',
        name: 'Access to Campaigns',
        createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
        updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
      },
    ],
    _id: '5ec3ffe6686dd431e25eae07',
    name: 'CRM',
    createdAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
    updatedAt: 'Tue May 19 2020 18:14:58 GMT+0000 (GMT)',
  },
]

export const usersByRole = [
  {
    _id: '5e4be8909661d12c97cb4ea6',
    firstName: 'test',
    lastName: 'Pasino',
  },
  {
    _id: '5ecdef70981818001957393e',
    firstName: 'testUser',
    lastName: 'Test',
  },
]
