import { action, createAction } from 'typesafe-actions'
import WithdrawalsTabList from 'state/enums/withdrawals-tabs-list.enum'
import ActionTypes from '../constants'

import { UserSettings } from '../models'

export const applySettingsAction = createAction(
  ActionTypes.APPLY_SETTINGS,
  actions => {
    return (payload: string[]) => actions(payload)
  },
)

export const selectColumnAction = createAction(
  ActionTypes.SELECT_COLUMNS,
  actions => {
    return (columns: string[]) => actions(columns)
  },
)

export const unselectColumnAction = createAction(
  ActionTypes.UNSELECT_COLUMNS,
  actions => {
    return (columns: string[]) => actions(columns)
  },
)

export const removeColumnAction = createAction(
  ActionTypes.REMOVE_COLUMN_SETTINGS,
  actions => {
    return (payload: string) => actions(payload)
  },
)

export const changeWithdrawalTypeAction = createAction(
  ActionTypes.CHANGE_WITHDRAWAL_TYPE,
  actions => {
    return (tab: WithdrawalsTabList) => actions(tab)
  },
)

export const loadSettingsAction = createAction(
  ActionTypes.LOAD_SETTINGS,
  actions => {
    return (payload: UserSettings) => actions(payload)
  },
)

export const resetSettingsAction = () => action(ActionTypes.RESET_SETTINGS)
