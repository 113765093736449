import ActionTypes from '../constants'
import { ReferFriendActions, ReferFriendState } from '../models'

const getReferredPlayerHandler = (
  state: ReferFriendState,
  action: ReferFriendActions,
): ReferFriendState => {
  switch (action.type) {
    case ActionTypes.GET_REFERRED_PLAYER:
      return { ...state, isLoading: true }

    case ActionTypes.GET_REFERRED_PLAYER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SET_REFERRED_APPROVED_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    case ActionTypes.SET_REFERRED_REJECTED_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default getReferredPlayerHandler
