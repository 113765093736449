import ActionTypes from '../constants'
import { AnalyticsState, AnalyticActions } from '../models'

const getCampaignsByPlayerHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_CAMPAIGNS_BY_PLAYER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_CAMPAIGNS_BY_PLAYER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaignsByPlayer: action.payload.docs || [],
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_CAMPAIGNS_BY_PLAYER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        hasError: true,
      }

    default:
      return state
  }
}

export default getCampaignsByPlayerHandler
