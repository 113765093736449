const LEVELS = {
  GET_ALL_LEVEL_DEFINITION: 'gamification/api/v1/admin/level-definition/list' as 'gamification/api/v1/admin/level-definition/list',
  GET_ONE_LEVEL_DEFINITION: 'gamification/api/v1/admin/level-definition/get' as 'gamification/api/v1/admin/level-definition/get',

  CREATE: 'gamification/api/v1/admin/level-definition/create' as 'gamification/api/v1/admin/level-definition/create',
  UPDATE: 'gamification/api/v1/admin/level-definition/update' as 'gamification/api/v1/admin/level-definition/update',
  DELETE: 'gamification/api/v1/admin/level-definition/delete' as 'gamification/api/v1/admin/level-definition/delete',

  GET_ALL_AUDIT: 'gamification/api/v1/admin/level-definition/audit/list' as 'gamification/api/v1/admin/level-definition/audit/list',
  GET_ONE_AUDIT: 'gamification/api/v1/admin/level-definition/audit/get' as 'gamification/api/v1/admin/level-definition/audit/get',
  GET_AUDIT_BY_LEVELID: 'gamification/api/v1/admin/level-definition/audit/get-by-levelId' as 'gamification/api/v1/admin/level-definition/audit/get-by-levelId',
}

export default LEVELS
