import { AnyAction } from 'redux'
import { SocketIoCallback } from 'state/middleware/sockets/middleware'
import WebSocketReadyState from 'state/middleware/sockets/websocket-ready-state.enum'

import SocketIoService, { SocketGatewayOptions } from './socket-io.service'
import WebSocketService from './websocket.service'

/**
 * @description Performs a Facade for these API's:
 *
 * * WebSocket API
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/WebSockets_API WebSocket API}
 *
 * * SocketIo API
 * @see {@link https://socket.io/docs/client-api/ SocketIo API}
 *
 * @class WebSocketFacade
 * @requires SocketIoService
 * @requires WebSocketService
 */
class WebSocketFacade {
  socketClient: SocketIOClient.Socket & SocketIoCallback

  webSocketService: WebSocketService

  socketIoService: SocketIoService

  socketGatewayOptions: Partial<SocketGatewayOptions>

  isConnected: boolean

  isSocketIo: boolean = false

  constructor(socketGatewayOptions: Partial<SocketGatewayOptions>) {
    this.socketGatewayOptions = socketGatewayOptions
    this.connect()
  }

  /**
   * @description Connects to a socket server
   *
   * * The connection depends on the service:
   *
   *  1) socketIoService.connect(socketGatewayOptions)
   *
   *  2) webSocketService.connect()
   *
   * @param {Partial<SocketGatewayOptions>} socketGatewayOptions
   * @memberof WebSocketFacade
   */
  connect() {
    const { namespace, token, host, port, isIO } = this.socketGatewayOptions
    if (isIO) {
      this.setIsSocketIo()
      if (this.isSocketIo) {
        this.socketIoService = new SocketIoService({
          host,
          port,
          namespace,
          token
        })
        this.socketClient = this.socketIoService.getSocket()
      }

    } else {
      this.webSocketService = new WebSocketService({
        host,
        port,
        namespace,
        token,
      })

      this.checkConnectionStatus()
    }
  }

  /**
   * @description isSocketIo value is used across the Facade
   * to validate which socket service should be active
   *
   * @memberof WebSocketFacade
   */
  setIsSocketIo() {
    this.isSocketIo = true
  }

  async getSocket() {
    if (this.isSocketIo) {
      return this.socketClient
    }
    return this.webSocketService?.getWebSocket()
  }

  getIsConnected() {
    return this.isConnected
  }

  checkConnectionStatus = () => {
    const socket = this.webSocketService.getWebSocket()
    if (socket?.readyState === WebSocketReadyState.OPEN) {
      this.isConnected = true
    } else {
      this.isConnected = false
    }
  }

  on(action: AnyAction) {
    if (this.isSocketIo) {
      this.socketClient?.on(action.event, action.handle)
    }
  }

  emit(action: AnyAction) {
    if (this.isSocketIo) {
      this.socketClient?.emit(action.event, action.payload)
    }

    this.webSocketService?.send(
      action?.event,
      action?.payload,
      action?.meta,
      action?.handler,
    )
  }

  close() {
    this.socketClient?.close()
  }
}

export default WebSocketFacade
