import { createAsyncAction, createAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import { TemplateSmsModels } from '..'

export const createTemplateSmsAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<
  TemplateSmsModels.CreateTemplateSmsPayload,
  TemplateSmsModels.TemplateSmsType,
  Error
>()

export const updateTemplateSmsAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<
  TemplateSmsModels.UpdateTemplateSmsPayload,
  TemplateSmsModels.TemplateSmsType,
  Error
>()

export const getAllTemplateSmsAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<
  TemplateSmsModels.GetAllTemplateSmsPayload,
  TemplateSmsModels.GetAllTemplateSmsResponse,
  Error
>()

export const getOneTemplateSmsAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<
  TemplateSmsModels.GetOneTemplateSmsPayload,
  TemplateSmsModels.TemplateSmsType,
  Error
>()

export const archiveTemplateSmsAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<
  TemplateSmsModels.ArchiveTemplateSmsPayload,
  TemplateSmsModels.TemplateSmsType,
  Error
>()

export const sendTestTemplateSmsAction = createAsyncAction(
  ActionTypes.SEND_TEST_REQUEST,
  ActionTypes.SEND_TEST_SUCCESS,
  ActionTypes.SEND_TEST_FAILURE,
)<any, any, any>()

export const duplicateTemplateSmsAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<
  TemplateSmsModels.DuplicateTemplateSmsPayload,
  TemplateSmsModels.TemplateSmsType,
  Error
>()

export const setLanguagesSelectedAction = createAction(
  ActionTypes.SET_LANGUAGES_SELECTED,
  action => {
    return (payload: string[]) => action(payload)
  },
)

export const setTemplateSmsFiltersAction = createAction(
  ActionTypes.SET_FILTERS,
  action => {
    return (filters: Partial<TemplateSmsModels.TemplateSmsFilterProps>) =>
      action(filters)
  },
)
