import { TagState } from '../models'

export const initialState: TagState = {
  hasError: false,
  error: '',
  wasDeleted: false,
  tags: [],
  currentTag: null,
  categories: [],
  currentCategory: null,
  pagination: {
    total: 0,
    page: 1,
    limit: 10,
    pages: 1,
  },
  isLoading: false,
  isLoadingCategories: false,
  isLoadingTags: false,
  dialog: {
    open: false,
    title: '',
    width: 'xs',
    content: null,
  },
  bulkTagAssignment: {
    tag: {
      _id: '',
      name: '',
      category: {
        _id: '',
        name: '',
      },
    },
    success: [],
    errors: [{ entity: { name: '', id: '' }, message: '', code: 0 }],
    assignDate: '',
    createdBy: {
      userId: '',
      username: '',
    },
  },
  tagNames: [],
  filters: {
    name: '',
    order: 'ASC',
    limit: 25,
    page: 1,
    category: '0',
  },
  tagType: 0,
  tagPlayers: {
    filters: {
      page: 1,
      limit: 10,
      usernames: [''],
    },
    players: [],
    playersIds: [],
    id: '',
  },
  tagPlayersPagination: {
    page: 1,
    limit: 10,
    total: 0,
    pages: 0,
  },
}

export default initialState
