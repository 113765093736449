import { createReducer } from 'typesafe-actions'
import { AnalyticActions, AnalyticsState } from '../models'
import ActionTypes from '../constants'

import analyticsGetOneSegmentHandler from './get-one.handler'
import channelActivityHandler from './get-channel-activity.handler'
import analyticsGetAllHandler from './get-all.handler'

import getCampaignDatesHandler from './get-campaign-dates.handler'
import initialState from './initial-state'
import analyticGeneralHandler from './general.handler'

import channelEventsHandler from './get-channel-events.handler'
import analyticsGetGroupHandler from './get-group.handler'
import getCampaignsByPlayerHandler from './get-campaigns-by-player'

import eventsDetailHandler from './get-events-detail.handler'
import channelActivityAllHandler from './get-all-channel-activity.handler'
import previewTemplateHandler from './get-preview-template.handler'

const analyticsReducer = createReducer<AnalyticsState, AnalyticActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    analyticsGetOneSegmentHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_GROUP_REQUEST,
      ActionTypes.GET_ONE_GROUP_SUCCESS,
      ActionTypes.GET_ONE_GROUP_FAILURE,
    ],
    analyticsGetGroupHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_CHANNEL_ACTIVITY_REQUEST,
      ActionTypes.GET_CHANNEL_ACTIVITY_SUCCESS,
      ActionTypes.GET_CHANNEL_ACTIVITY_FAILURE,
      ActionTypes.SET_DOWNLOAD_ANALYTIC_EVENTS,
    ],
    channelActivityHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_CHANNEL_ACTIVITY_REQUEST,
      ActionTypes.GET_ALL_CHANNEL_ACTIVITY_SUCCESS,
      ActionTypes.GET_ALL_CHANNEL_ACTIVITY_FAILURE,
    ],
    channelActivityAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_CHANNEL_EVENTS_REQUEST,
      ActionTypes.GET_CHANNEL_EVENTS_SUCCESS,
      ActionTypes.GET_CHANNEL_EVENTS_FAILURE,
    ],
    channelEventsHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_TEMPLATE_PREVIEW_REQUEST,
      ActionTypes.GET_TEMPLATE_PREVIEW_SUCCESS,
      ActionTypes.GET_TEMPLATE_PREVIEW_FAILURE,
    ],
    previewTemplateHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_EVENTS_DETAIL_REQUEST,
      ActionTypes.GET_EVENTS_DETAIL_SUCCESS,
      ActionTypes.GET_EVENTS_DETAIL_FAILURE,
    ],
    eventsDetailHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.RESET_ANALYTIC,
    ],
    analyticsGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_CAMPAIGN_DATES_REQUEST,
      ActionTypes.GET_CAMPAIGN_DATES_SUCCESS,
      ActionTypes.GET_CAMPAIGN_DATES_FAILURE,
    ],
    getCampaignDatesHandler,
  )
  .handleAction(
    [
      ActionTypes.SET_FILTERS,
      ActionTypes.SET_FILTERS_EVENTS,
      ActionTypes.SET_FILTERS_CAMPAIGN_BY_PLAYER,
      ActionTypes.SET_TARGETS_GROUP,
    ],
    analyticGeneralHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_CAMPAIGNS_BY_PLAYER_REQUEST,
      ActionTypes.GET_CAMPAIGNS_BY_PLAYER_SUCCESS,
      ActionTypes.GET_CAMPAIGNS_BY_PLAYER_FAILURE,
    ],
    getCampaignsByPlayerHandler,
  )

export default analyticsReducer
