/**
 *
 * Levels  constants
 *
 */

enum ActionTypes {
  GET_ALL_LEVEL_DEFINITION = '@level-definition/GET_ALL_LEVEL_DEFINITION',
  GET_ALL_LEVEL_DEFINITION_SUBSCRIBER = '@level-definition/GET_ALL_LEVEL_DEFINITION_SUBSCRIBER',
  GET_ALL_LEVEL_DEFINITION_EXCEPTION_SUBSCRIBER = '@level-definition/GET_ALL_LEVEL_DEFINITION_EXCEPTION_SUBSCRIBER',

  GET_ONE_LEVEL_DEFINITION = '@level-definition/GET_ONE_LEVEL_DEFINITION',
  GET_ONE_LEVEL_DEFINITION_SUBSCRIBER = '@level-definition/GET_ONE_LEVEL_DEFINITION_SUBSCRIBER',

  CREATE = '@level-definition/CREATE',
  CREATE_SUBSCRIBER = '@level-definition/CREATE_SUBSCRIBER',

  UPDATE = '@level-definition/UPDATE',
  UPDATE_SUBSCRIBER = '@level-definition/UPDATE_SUBSCRIBER',

  DELETE = '@level-definition/DELETE',
  DELETE_SUBSCRIBER = '@level-definition/DELETE_SUBSCRIBER',

  SET_FILTERS = '@level-definition/SET_FILTERS',
}

export default ActionTypes
