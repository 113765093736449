import removeDuplicates from 'utils/state/remove-duplicates.util'
import ActionTypes from '../constants'
import { PlayerCardKYCDocumentState, PlayerCardKYCActions } from '../models'

const createDocumentHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.CREATE_DOCUMENT:
      return {
        ...state,
      }

    case ActionTypes.CREATE_DOCUMENT_SUBSCRIBER:
      return {
        ...state,
        documents: removeDuplicates(
          [...state.documents, action.payload],
          'imageId',
        ),
        documentImages: state.documentImages?.map(doc => {
          if (doc?.generation === action.payload?.imageId) {
            return {
              ...doc,
              documentId: action.payload._id,
              isVisible: true,
              status: action.payload.status,
              documentType: action.payload.documentType,
              issueDate: action.payload.documenIssueDate,
              documentExpiry: action.payload.documentExpiryDate,
              documentNumber: action.payload.documentNumber,
              issuuingauthority: action.payload.issuuingAuthority,
            } as any
          }

          return doc
        }),
      }

    case ActionTypes.CREATE_UTILITY_DOCUMENT_SUBSCRIBER:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default createDocumentHandler
