/**
 *
 * PlayerCardSummary constants
 *
 */

enum ActionTypes {
  DEFAULT_ASYNC_REQUEST = '@player-card-summary/DEFAULT_ASYNC_REQUEST',
  DEFAULT_ASYNC_SUCCESS = '@player-card-summary/DEFAULT_ASYNC_SUCCESS',
  DEFAULT_ASYNC_FAILURE = '@player-card-summary/DEFAULT_ASYNC_FAILURE',

  CREATE_PLAYER_NOTE = '@player-card-summary/CREATE_PLAYER_NOTE',
  CREATE_PLAYER_NOTE_SUBSCRIBER = '@player-card-summary/CREATE_PLAYER_NOTE_SUBSCRIBER',

  UPDATE_PLAYER_NOTE = '@player-card-summary/UPDATE_PLAYER_NOTE',
  UPDATE_PLAYER_NOTE_SUBSCRIBER = '@player-card-summary/UPDATE_PLAYER_NOTE_SUBSCRIBER',

  GET_PLAYER_NOTES = '@player-card-summary/GET_PLAYER_NOTES',
  GET_PLAYER_NOTES_SUBSCRIBER = '@player-card-summary/GET_PLAYER_NOTES_SUBSCRIBER',

  DELETE_PLAYER_NOTE = '@player-card-summary/DELETE_PLAYER_NOTE',
  DELETE_PLAYER_NOTE_SUBSCRIBER = '@player-card-summary/DELETE_PLAYER_NOTE_SUBSCRIBER',
}

export default ActionTypes
