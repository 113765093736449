import ActionTypes from '../constants'
import { PlayerCardState, PlayerCardActions } from '../models'

const updatePlayerCardHandler = (
  state: PlayerCardState,
  action: PlayerCardActions,
): PlayerCardState => {
  switch (action.type) {
    case ActionTypes.UPDATE_PLAYER_CARD:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_PLAYER_CARD_SUBSCRIBER:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_PAYMENT_DATA:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_PAYMENT_DATA_SUBSCRIBER:
      return {
        ...state,
      }

    case ActionTypes.DISABLE_TWO_FACTOR:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.DISABLE_TWO_FACTOR_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default updatePlayerCardHandler
