import i18n from 'i18n'
import NoteTagType from 'state/enums/note-tag-type.enum'
import IssueStatus from 'state/enums/issue-status.enum'

import RiskLevel from 'state/enums/risk-level-alerts.enum'
import TimelineEntrySubType from 'state/enums/timeline-entry-subtype.enum'
import { RangeCategoryIssue } from 'views/player-card-alerts/hooks/type'

import IssueImgStatus from 'state/enums/issue-img-status.enum'

export const playerNoteTags = {
  [NoteTagType.BONUS]: {
    color: 'green',
  },
  [NoteTagType.CASINO]: {
    color: 'purple',
  },
  [NoteTagType.IMPORTANT]: {
    color: 'red',
  },
  [NoteTagType.KYC]: {
    color: 'orange',
  },
  [NoteTagType.OTHER]: {
    color: 'white',
  },
  [NoteTagType.RESP_GAMING]: {
    color: 'yellow',
  },
  [NoteTagType.TRANSACTIONAL]: {
    color: 'blue',
  },
}

const EmptySubType = {
  color: '',
  label: '',
  defaultType: 0,
  type: [],
}

export const playerAlertTypeList = {
  [TimelineEntrySubType.RISK_MANAGEMENT]: {
    color: 'orange',
    label: i18n.t('alertTypeOptions.RISK_MANAGEMENT'),
    defaultType: 199,
    type: [
      {
        label: i18n.t('alertTypeOptions.FRAUD_ALERTS'),
        start: 110,
        end: 120,
      },
      {
        label: i18n.t('alertTypeOptions.OTHER_1'),
        start: 199,
        end: 199,
      },
    ],
  },
  [TimelineEntrySubType.RESPONSIBLE_GAMING]: {
    color: 'turquoise',
    label: i18n.t('alertTypeOptions.RESPONSIBLE_GAMING'),
    defaultType: 299,
    type: [
      {
        label: i18n.t('alertTypeOptions.BLACKLIST_VETO'),
        start: 210,
        end: 216,
      },
      {
        label: i18n.t('alertTypeOptions.DASHBOARD_BACKEND'),
        start: 250,
        end: 259,
      },
      {
        label: i18n.t('alertTypeOptions.OTHER_2'),
        start: 299,
        end: 299,
      },
    ],
  },
  [TimelineEntrySubType.KNOW_YOUR_CUSTOMER]: {
    color: 'purple',
    label: i18n.t('alertTypeOptions.KNOW_YOUR_CUSTOMER'),
    defaultType: 399,
    type: [
      {
        label: i18n.t('alertTypeOptions.TEMPORARY_ACCOUNT'),
        start: 320,
        end: 329,
      },
      {
        label: i18n.t('alertTypeOptions.PLAYER_ACTIVITY'),
        start: 330,
        end: 339,
      },
      {
        label: i18n.t('alertTypeOptions.MONEY_STUFF'),
        start: 340,
        end: 349,
      },
      {
        label: i18n.t('alertTypeOptions.ACCOUNT_STATUS_CHANGE'),
        start: 350,
        end: 359,
      },
      {
        label: i18n.t('alertTypeOptions.ACCOUNT_CLOSED'),
        start: 360,
        end: 362,
      },
      {
        label: i18n.t('alertTypeOptions.CRIF'),
        start: 380,
        end: 382,
      },
      {
        label: i18n.t('alertTypeOptions.OTHER_3'),
        start: 399,
        end: 399,
      },
    ],
  },
  [TimelineEntrySubType.GAMIFICATION]: {
    color: 'orange',
    label: i18n.t('alertTypeOptions.GAMIFICATION'),
    defaultType: 499,
    type: [
      {
        label: i18n.t('alertTypeOptions.BONUSES'),
        start: 410,
        end: 419,
      },
      {
        label: i18n.t('alertTypeOptions.OTHER_4'),
        start: 499,
        end: 499,
      },
    ],
  },
  [TimelineEntrySubType.TRANSACTIONS]: EmptySubType,
  [TimelineEntrySubType.DEPOSIT]: EmptySubType,
  [TimelineEntrySubType.WITHDRAW]: EmptySubType,
  [TimelineEntrySubType.MANUAL_DEPOSIT]: EmptySubType,
  [TimelineEntrySubType.MANUAL_WITHDRAW]: EmptySubType,
  [TimelineEntrySubType.WITHDRAW_CANCEL]: EmptySubType,
  [TimelineEntrySubType.SESSIONS]: EmptySubType,
  [TimelineEntrySubType.SESSION_CLOSED]: EmptySubType,
  [TimelineEntrySubType.SESSION_OPEN]: EmptySubType,
  [TimelineEntrySubType.ISSUES]: EmptySubType,
}

export const playerAlertRiskLevelList = {
  [RiskLevel.UNDEFINED]: {
    color: 'gray',
    label: i18n.t('riskLevelOptions.UNDEFINED'),
  },
  [RiskLevel.LOW]: {
    color: 'green',
    label: i18n.t('riskLevelOptions.LOW'),
  },
  [RiskLevel.NORMAL]: {
    color: 'cyan',
    label: i18n.t('riskLevelOptions.NORMAL'),
  },
  [RiskLevel.MEDIUM]: {
    color: 'yellow',
    label: i18n.t('riskLevelOptions.MEDIUM'),
  },
  [RiskLevel.HIGH]: {
    color: 'orange',
    label: i18n.t('riskLevelOptions.HIGH'),
  },
  [RiskLevel.SEVERE]: {
    color: 'red',
    label: i18n.t('riskLevelOptions.SEVERE'),
  },
}

export const playerAlertStatusList = {
  [IssueStatus.UNDEFINED]: {
    value: undefined,
    color: 'yellow',
    label: i18n.t('alertStatusOptions.UNDEFINED'),
  },
  [IssueStatus.NEW]: {
    value: false,
    color: 'green',
    label: i18n.t('alertStatusOptions.NEW'),
  },
  [IssueStatus.RESOLVED]: {
    value: true,
    color: 'red',
    label: i18n.t('alertStatusOptions.RESOLVED'),
  },
}

export const playerAlertImgStatusList = {
  [IssueImgStatus.REJECTED]: {
    value: false,
    color: 'red',
    label: i18n.t('alertImgStatusOptions.REJECTED'),
  },
  [IssueImgStatus.VERIFIED]: {
    value: true,
    color: 'green',
    label: i18n.t('alertImgStatusOptions.VERIFIED'),
  },
}

export const rangeCategoryIssues: RangeCategoryIssue[] = [
  {
    timelineEntrySubType: 31,
    detail: playerAlertTypeList[TimelineEntrySubType.RISK_MANAGEMENT],
    start: 100,
    end: 199,
  },
  {
    timelineEntrySubType: 32,
    detail: playerAlertTypeList[TimelineEntrySubType.RESPONSIBLE_GAMING],
    start: 200,
    end: 299,
  },
  {
    timelineEntrySubType: 33,
    detail: playerAlertTypeList[TimelineEntrySubType.KNOW_YOUR_CUSTOMER],
    start: 300,
    end: 399,
  },
  {
    timelineEntrySubType: 34,
    detail: playerAlertTypeList[TimelineEntrySubType.GAMIFICATION],
    start: 400,
    end: 499,
  },
]
