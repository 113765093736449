import { max, isEqual } from 'date-fns'
import { ApplicationState } from 'state/root.models'
import { DocumentImageType } from './models'

export const documentsRoot = ({ playerCardKYCDocument }: ApplicationState) =>
  playerCardKYCDocument

export const statusRoot = ({ playerCardKYCStatus }: ApplicationState) =>
  playerCardKYCStatus

export const historyRoot = ({ playerCardKYCHistory }: ApplicationState) =>
  playerCardKYCHistory

export const previewImage = ({ playerCardKYCDocument }: ApplicationState) =>
  playerCardKYCDocument?.currentDownloadedImage

export const recentlyUploaded = (
  { playerCardKYCDocument }: ApplicationState,
  name: string,
  docs: DocumentImageType[],
) => {
  const dates = docs.map(doc => new Date(doc.timeCreated as string))

  // get recent date
  const mostRecentDate = max(dates)

  // find the log that matches with the recent date
  const lastImageEntry = docs?.find(image =>
    isEqual(mostRecentDate, new Date(image.timeCreated as string)),
  )

  // if state has no documents, return the last image ref
  if (playerCardKYCDocument?.documents.length === 0) {
    return lastImageEntry
  }

  return docs?.find(doc => {
    const [, fileName] = doc?.name?.split('/') as string[]

    const cleanFileName = fileName?.substr(0, fileName?.lastIndexOf('.'))

    return name === cleanFileName
  })
}

export const currentDocument = (
  { playerCardKYCDocument }: ApplicationState,
  docId: string,
) => {
  return playerCardKYCDocument?.documents?.find(doc => doc._id === docId)
}

export const currentImage = (
  { playerCardKYCDocument }: ApplicationState,
  docId: string,
) => {
  return playerCardKYCDocument?.documentImages?.find(
    doc => doc.documentId === docId,
  )
}
