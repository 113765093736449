const GAME_STORE = {
  GET_GAME_LIST_ADMIN: 'games/v1/get-game-list' as 'games/v1/get-game-list',
  GET_GAME_PROVIDER_LIST: 'games/get-game-provider-list' as 'games/get-game-provider-list',
  GET_GAME_CATEGORIES: 'games/get-game-categories' as 'games/get-game-categories',
  GET_GAME_DEVICES: 'games/get-devices' as 'games/get-devices',
  UPDATE_GAME: 'games/v1/update-game' as 'games/v1/update-game',
  GET_GAME: 'games/get-game' as 'games/get-game',
  GET_GAME_VERSION: 'games/get-game-version-data' as 'games/get-game-version-data',
  ADD_GAME_VERSION: 'games/add-game-version' as 'games/add-game-version',
  DELETE_GAME_VERSION: 'games/delete-future-game-version' as 'games/delete-future-game-version',
}

export default GAME_STORE
