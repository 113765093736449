import { takeLatest, put, delay } from 'redux-saga/effects'
import i18n from 'i18n'
import { push } from 'connected-react-router'

import { persistor } from 'state/index'
import routes from 'routes/index.routes'
import { setSnackbarAction } from 'state/ui/global/actions'

import { resetUserState } from 'state/user/actions/rest.actions'
import { resetSettingsAction } from 'state/settings/actions/standard.actions'
import { logoutAction } from '../actions'

import ActionTypes from '../constants'

/**
 *
 * This saga listens for the logout action to remove the token
 *
 * @param {ReturnType<typeof logoutAction>} action
 */
function* logoutAuthWorker(action: ReturnType<typeof logoutAction>) {
  yield put({ type: ActionTypes.REMOVE_TOKEN })
  yield put({ type: ActionTypes.REMOVE_LEGACY_TOKEN })
  yield put(resetUserState())
  yield put(resetSettingsAction())
  yield put({
    type: ActionTypes.SET_SESSION,
    userSession: {
      _id: '',
      username: '',
      role: '',
      companyId: '',
      iat: 0,
      exp: 0,
    },
  })
  yield put({
    type: ActionTypes.SET_SIDEBAR_IDS,
    sidebarIds: [],
  })
  localStorage.clear()
  sessionStorage.clear()
  persistor.purge()
  persistor.flush()
  yield put(push(routes.auth.LOGIN))
  yield delay(1000)
  yield put(
    setSnackbarAction({
      message: i18n.t('messages.sessionEnded'),
      open: true,
      variant: 'success',
    }),
  )
  yield delay(1000)
  window.location.reload()
}
function* logoutAuthSagasWatcher() {
  yield takeLatest(logoutAction, logoutAuthWorker)
}

export default logoutAuthSagasWatcher
