import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const createShortcutsHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.CREATE_SHORTCUTS_REQUEST:
      return { ...state }

    case ActionTypes.CREATE_SHORTCUTS_SUCCESS:
      return {
        ...state,
        shortcuts: action.payload.shortcuts,
      }

    case ActionTypes.CREATE_SHORTCUTS_FAILURE:
      return {
        ...state,
        hasError: true,
      }

    default:
      return state
  }
}

export default createShortcutsHandler
