import { takeLatest } from 'redux-saga/effects'
import {
  createCustomTriggerAction,
  updateCustomTriggerAction,
  getOneCustomTriggerAction,
  getAllCustomTriggerAction,
  duplicateCustomTriggerAction,
  archiveCustomTriggerAction,
} from '../actions/index.actions'
import createCustomTriggerWorker from './create.saga'

import updateCustomTriggerWorker from './update.saga'
import getOneCustomTriggerWorker from './get-one.saga'
import getAllCustomTriggerWorker from './get-all.saga'

import archiveCustomTriggerWorker from './archive.saga'
import duplicateCustomTriggerWorker from './duplicate.saga'

/**
 * Main Saga for watching saga workers  in the Custom Trigger state shape
 *
 * @generator
 */
function* customTriggerSagasWatcher() {
  yield [
    yield takeLatest(
      getAllCustomTriggerAction.request,
      getAllCustomTriggerWorker,
    ),
    yield takeLatest(
      getOneCustomTriggerAction.request,
      getOneCustomTriggerWorker,
    ),
    yield takeLatest(
      createCustomTriggerAction.request,
      createCustomTriggerWorker,
    ),
    yield takeLatest(
      updateCustomTriggerAction.request,
      updateCustomTriggerWorker,
    ),
    yield takeLatest(
      archiveCustomTriggerAction.request,
      archiveCustomTriggerWorker,
    ),
    yield takeLatest(
      duplicateCustomTriggerAction.request,
      duplicateCustomTriggerWorker,
    ),
  ]
}

export default customTriggerSagasWatcher
