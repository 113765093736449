import * as RoleReducer from './reducers/index.reducer'
import * as RoleTypes from './constants'
import * as RoleModels from './models'

import * as RoleActions from './actions/index.actions'
import * as RoleSelectors from './selectors'

export { RoleTypes, RoleModels, RoleActions, RoleSelectors }

export default RoleReducer
