import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'

const eventsDetailHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_EVENTS_DETAIL_REQUEST:
      return {
        ...state,
        isLoadingEvents: true,
      }

    case ActionTypes.GET_EVENTS_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingEvents: false,
        currentEventsDetail: [
          ...state.currentEventsDetail,
          action.payload,
        ],
      }

    case ActionTypes.GET_EVENTS_DETAIL_FAILURE:
      return {
        ...state,
        isLoadingEvents: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default eventsDetailHandler
