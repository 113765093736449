import React from 'react'
import { useTranslation } from 'react-i18next'
import Auth from '../../layouts/auth'

import ForgotModule from '../../forgot/index.module'

const Forgot = () => {
  const { t } = useTranslation()

  return (
    <>
      <Auth
        title={t('resetPassword')}
        subtitle={t('forgotPasswordSubtitle')}
        auth={false}
        showBack
      >
        <ForgotModule />
      </Auth>
    </>
  )
}

export default Forgot
