import { createReducer } from 'typesafe-actions'
import { CampaignState, CampaignActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import campaignGetAllHandler from './get-all.handler'
import campaignGetAllArchiveHandler from './get-all-archive.handler'

import campaignGetOneHandler from './get-one.handler'
import campaignUpdateHandler from './update.handler'
import campaignCreateHandler from './create.handler'

import campaignGetAllSegmentsHandler from './get-all-segments.handler'
import campaignGetAllTemplatesHandler from './get-all-templates.handler'
import campaignGetOneSegmentHandler from './get-one-segment.handler'

import getTemplateLanguagesHandler from './get-template-languages.handler'
import campaignUpdateStatusHandler from './update-status.handler'
import campaignGeneralHandler from './general.handler'

import campaignGetAllBonusesHandler from './get-all-bonuses.handler'

const campaignReducer = createReducer<CampaignState, CampaignActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
      ActionTypes.RESET_CAMPAIGN,
    ],
    campaignGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.RESET_CAMPAIGNS,
    ],
    campaignGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_ARCHIVE_REQUEST,
      ActionTypes.GET_ALL_ARCHIVE_SUCCESS,
      ActionTypes.GET_ALL_ARCHIVE_FAILURE,
    ],
    campaignGetAllArchiveHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    campaignUpdateHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    campaignCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_SEGMENTS_REQUEST,
      ActionTypes.GET_ALL_SEGMENTS_SUCCESS,
      ActionTypes.GET_ALL_SEGMENTS_FAILURE,
    ],
    campaignGetAllSegmentsHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_TEMPLATES_REQUEST,
      ActionTypes.GET_ALL_TEMPLATES_SUCCESS,
      ActionTypes.GET_ALL_TEMPLATES_FAILURE,
    ],
    campaignGetAllTemplatesHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_SEGMENT_REQUEST,
      ActionTypes.GET_ONE_SEGMENT_SUCCESS,
      ActionTypes.GET_ONE_SEGMENT_FAILURE,
      ActionTypes.RESET_SEGMENT,
    ],
    campaignGetOneSegmentHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_TEMPLATE_LANGUAGES_REQUEST,
      ActionTypes.GET_TEMPLATE_LANGUAGES_SUCCESS,
      ActionTypes.GET_TEMPLATE_LANGUAGES_FAILURE,
      ActionTypes.RESET_TEMPLATE_LANGUAGES,
    ],
    getTemplateLanguagesHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_STATUS_REQUEST,
      ActionTypes.UPDATE_STATUS_SUCCESS,
      ActionTypes.UPDATE_STATUS_FAILURE,
    ],
    campaignUpdateStatusHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], campaignGeneralHandler)
  .handleAction([ActionTypes.GET_ALL_BONUSES], campaignGetAllBonusesHandler)

export default campaignReducer
