import CurrencyCode from 'state/enums/currency-code.enum'
import CountryCode from 'state/enums/country-code.enum'
import SortType from 'state/enums/sort.enum'

import { WalletModels } from '../../models/index.model'

const WalletInitialState: WalletModels.State = {
  error: '',
  filters: {
    status: [],
    limit: 25,
    page: 1,
    sortType: SortType.Desc,
    sortField: 'transactionId',
  },
  filtersPayments: {
    limit: 25,
    page: 1,
    order: 'desc',
  },
  hasError: false,
  isLoading: false,
  isLoadingFull: false,
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  paymentFiles: [],
  pending: [],
  player: {},
  approved: [],
  resolved: false,
  searchParam: '',
  selected: [],
  settings: {
    paymentInformation: {
      abbreviation: 'GCB',
      address: 'Haselstrasse',
      beneficiary: 'Grand Casino Baden AG',
      bic: 'UBSWCHZH80A',
      city: 'Baden',
      country: 'Switzerland',
      countryAbbreviation: CountryCode.CH,
      iban: 'CH55 0023 2232 5025 0762 C',
      postalCode: 5400,
      streetNumber: '2',
    },
  },
  wasDeleted: false,
  withdrawals: [],
  wallet: {
    playerId: '',
    currency: CurrencyCode.CHF,
    realBalance: 0,
    bonusBalance: 0,
    balance: 0,
    balances: {
      currency: CurrencyCode.CHF,
      real: 0,
      bonus: 0,
      total: 0,
    },
  },
  isDownloading: false,
  totalWithdrawals: 0,
}

export default WalletInitialState
