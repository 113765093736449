import ActionTypes from '../constants'
import { TagState, TagActions } from '../models'

const bulkTagAssignHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.BULK_ASSIGN_TAG:
      return { ...state }

    case ActionTypes.BULK_REMOVE_TAG:
      return { ...state }

    case ActionTypes.BULK_TAG_ACTION_SUBSCRIBER:
      return {
        ...state,
        bulkTagAssignment: action.payload || {},
      }

    default:
      return state
  }
}

export default bulkTagAssignHandler
