import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import jsonToQueryString from 'utils/query-string'

import { deleteShortcutsAction } from '../actions/rest.actions'
import { DeleteShortcutsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga deletes a user shortcut
 *
 * @requires ApiService
 * @param {ReturnType<typeof deleteShortcutsAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* deleteShortcutsWorker(
  action: ReturnType<typeof deleteShortcutsAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'users/shortcuts/delete',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()

    const response: DeleteShortcutsResponse = yield call(
      apiService.deleteData,
      auth.token,
    )

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('sidebar.shortcut'),
      what: i18n.t('deleted'),
    })

    if (response._id) {
      yield put(deleteShortcutsAction.success(response))
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        deleteShortcutsAction.failure({
          name: response.status.toString(),
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response?._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(deleteShortcutsAction.failure(error))
  }
}

export default deleteShortcutsWorker
