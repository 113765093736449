import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import DateFnsUtils from '@date-io/date-fns'
import { InputBase } from '@material-ui/core'

import { dateFormat } from 'constants/date/index.constant'
import { PlayerSearchProps } from 'state/search/models'

type Props = {
  playerSearchProps: PlayerSearchProps
  stateUI: any
  maxDate: Date
  minDate: Date
  handleChangePlayerSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeDate: (event: any) => void
  handleToggleFilters: (event: any) => void
  handleTogglePicker: (event: any) => void
  onKeyDown: (event: React.KeyboardEvent<any>) => void
  inputRef: React.RefObject<any>
}

const InputsPlayerComponent = ({
  playerSearchProps,
  stateUI,
  maxDate,
  minDate,
  handleChangePlayerSearch,
  handleChangeDate,
  handleToggleFilters,
  handleTogglePicker,
  onKeyDown,
  inputRef,
}: Props) => {
  const { t } = useTranslation()

  return (
    <>
      {playerSearchProps.filter === 'birthdate' ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputRef={inputRef}
            open={stateUI.openPicker}
            onOpen={() => handleTogglePicker(true)}
            onClose={() => handleTogglePicker(false)}
            fullWidth
            autoOk
            disableFuture
            maxDate={maxDate}
            minDate={minDate}
            maxDateMessage=""
            minDateMessage=""
            openTo="year"
            disableToolbar
            variant="inline"
            value={
              (playerSearchProps.filterValue as Date) ||
              new Date(new Date().setHours(0, 0, 0, 0))
            }
            onChange={(date: MaterialUiPickersDate) => handleChangeDate(date)}
            onKeyDown={onKeyDown}
            format={dateFormat}
            onFocus={() => handleToggleFilters(true)}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <InputBase
          inputRef={inputRef}
          autoComplete="off"
          fullWidth
          name="search"
          margin="dense"
          value={playerSearchProps.filterValue}
          onChange={handleChangePlayerSearch}
          onKeyDown={onKeyDown}
          onFocus={() => handleToggleFilters(true)}
          placeholder={t('findPlayer')}
        />
      )}
    </>
  )
}

export default InputsPlayerComponent
