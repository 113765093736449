/**
 *
 * Auth Action constants
 *
 * @action LOGIN
 * @action LOGOUT
 * @action SAVE_TOKEN
 * @action REMOVE_TOKEN
 * @action SET_SESSION
 * @action SET_LEGACY_TOKEN
 */

enum ActionTypes {
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@auth/LOGIN_FAILURE',

  LOGIN_CANCEL = '@auth/LOGIN_CANCEL',
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST',
  SAVE_TOKEN = '@auth/SAVE_TOKEN',

  REMOVE_TOKEN = '@auth/REMOVE_TOKEN',
  SET_SESSION = '@auth/SET_SESSION',
  SET_LEGACY_TOKEN = '@auth/SET_LEGACY_TOKEN',
  REMOVE_LEGACY_TOKEN = '@auth/REMOVE_LEGACY_TOKEN',

  SET_SIDEBAR_IDS = '@auth/SET_SIDEBAR_IDS',
}

export default ActionTypes
