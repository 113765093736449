import { Service } from 'typedi'
import { RequestParams } from '../models'
/**
 * @description Handle API REST calls
 * @class ApiService
 */
@Service()
class ApiService {
  private requestParamsProp: RequestParams

  private url: string

  constructor() {
    this.url = ''
    this.requestParamsProp = {
      method: 'GET',
      endpoint: '',
      queries: '',
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    }
  }

  set requestParams(requestParams: RequestParams) {
    this.requestParamsProp = requestParams
  }

  get requestParams(): RequestParams {
    return this.requestParamsProp
  }

  /**
   *
   * @description Build the url to fetch
   * @memberof ApiService
   */
  private setUrl = async () => {
    const { endpoint, queries } = this.requestParamsProp
    const serverUrl = process.env && process.env.REACT_APP_SERVER_URL
    const url = queries
      ? `${serverUrl}${endpoint}?${queries}`
      : `${serverUrl}${endpoint}`
    this.url = url
  }

  /**
   *
   * @description Method to perform request over the GET HTTP verb
   * @memberof ApiService
   */
  getData = async <D>(token: string): Promise<D> => {
    await this.setUrl()
    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the POST HTTP verb
   * @memberof ApiService
   */
  sendData = async <D>(token?: string): Promise<D> => {
    const { body, method, signal } = this.requestParamsProp
    await this.setUrl()
    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method,
        body: JSON.stringify(body),
        signal,
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the POST HTTP verb
   * @memberof ApiService
   */
  sendDataMultipart = async <D>(token?: string): Promise<D> => {
    const { body, method } = this.requestParamsProp
    await this.setUrl()
    try {
      const response = await fetch(this.url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method,
        body,
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the PUT HTTP verb
   * @memberof ApiService
   */
  updateData = async <D>(token?: string): Promise<D> => {
    const { body } = this.requestParamsProp
    await this.setUrl()
    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'PUT',
        body: JSON.stringify(body),
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the DELETE HTTP verb
   * @memberof ApiService
   */
  deleteData = async <D>(token?: string): Promise<D> => {
    const { method } = this.requestParamsProp
    await this.setUrl()
    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method,
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }
}
export default ApiService
