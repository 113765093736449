/**
 *
 * Remove duplicate objects from the array
 * @param {Array} data - The state shape from the store.
 */
const removeDuplicates = <T extends any[]>(data: T, prop?: string) => {
  const identifier = prop || '_id'
  const seen = new Set()
  return data.filter(el => {
    const duplicate = seen.has(el[identifier])
    seen.add(el[identifier])
    return !duplicate
  })
}
export default removeDuplicates
