import { select, call, put, all } from 'redux-saga/effects'
import ChannelType from 'state/enums/channel.enum'
import { getTemplateLanguagesAction } from '../actions/index.actions'

/**
 *
 * This saga gets languages from a template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getTemplateLanguagesAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */

function* callAPI(id: string, channel: string) {
  const { auth } = yield select()
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    },
    method: 'GET',
  }
  const serverUrl = process.env && process.env.REACT_APP_SERVER_URL

  const json = yield call(
    fetch,
    `${serverUrl}templates/${channel}/get/one?id=${id}`,
    options,
  )

  const data = yield json.json()
  return { ...data, channel }
}

function* getTemplateLanguagesWorker(
  action: ReturnType<typeof getTemplateLanguagesAction.request>,
) {
  try {
    const result = yield all(
      action.payload.targetGroup.map(item => {
        return call(
          callAPI,
          item.templateId,
          item.channel ? ChannelType[item.channel] : action.payload.channel,
        )
      }),
    )

    yield put(getTemplateLanguagesAction.success(result))
  } catch (error) {
    yield put(getTemplateLanguagesAction.failure(error))
  }
}

export default getTemplateLanguagesWorker
