import { mergeDocumentImages } from 'state/player-card-kyc/utils'
import { IssuesActions, IssueState } from '../model'
import ActionTypes from '../constants'

const getDocumentImagesHandler = (
  state: IssueState,
  action: IssuesActions,
): IssueState => {
  switch (action.type) {
    case ActionTypes.GET_DOCUMENT_IMAGES_REQUEST:
      return {
        ...state,
        isLoadingImg: true,
      }

    case ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS:
      return {
        ...state,
        isLoadingImg: false,
        documentImages: mergeDocumentImages(
          state.documentImages,
          state.documents,
          action.payload.images,
          action.payload.requestType,
        ),
      }

    case ActionTypes.GET_DOCUMENT_IMAGES_FAILURE:
      return {
        ...state,
        isLoadingImg: false,
      }
    default:
      return state
  }
}

export default getDocumentImagesHandler
