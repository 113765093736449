const account = {
  accountSettings: 'Account settings',
  nightMode: 'Night Mode',
  selectTimeZone: 'Select Time Zone',
  timeZone: 'Time Zone',
  applicableOnlyForCRM: 'Applicable only for CRM & Message Gateway Campaigns',
  accountInformation: 'Account information',
  addressVerified: 'Address verified',
  addressNotVerified: 'Address not verified',
  alertReason: 'Reason',
  alertSubReason: 'Subreason',
  alertComment: 'Comment',
  passwordsMustMatch: 'Passwords must match.',
  personalInformation: 'Personal Information',
  emailInUse: 'Email is already in use',
}

export default account
