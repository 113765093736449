const withdrawals = {
  acc: 'Acc',
  allStatuses: 'All Statuses',
  amount: 'Amount',
  beneficiary: 'beneficiary',
  beneficiaryName: 'Beneficiary Name',
  BIC: 'BIC',
  columnTypes: {
    TRANSACTION_ID: 'Txn ID',
    CREATED: 'Requested Date',
    PLAYER_ID: 'Player ID',
    PLAYER_NAME: 'Name',
    USERNAME: 'Username',
    BENEFICIARY_NAME: 'Beneficiary Name',
    IDENTITY_NAME: 'Name Identity',
    PAYMENT_METHOD: 'Payment Method',
    ACCOUNT_STATUS: 'Account Status',
    RISK: 'Risk Level',
    KYC: 'KYC Status',
    RG: 'RG Level',
    GAMIFICATION: 'Gamification Level',
    TRANSACTION_AMOUNT: 'Txn Amount',
    TRANSACTION_STATUS: 'Txn Status',
    FRAUD: 'Fraud Alert',
    IBAN: 'IBAN',
    IBAN_VALIDITY: 'IBAN Validity',
    BIC: 'BIC',
    MARKED_FOR_EXECUTION: 'Marked for execution',
  },
  completedDate: 'Completed at',
  dayInWeek: 'day',
  deposit: 'deposit',
  disableApproveText: `Some of the selected transactions don't have a valid IBAN or BIC.`,
  generateFile: 'Generate File',
  file: 'File',
  fraudAlerts: 'Fraud Alerts',
  fullName: 'firstName / lastName',
  gamification: 'Gamification',
  generatedBy: 'Generated By',
  generatedDate: 'Generated at',
  IBAN: 'IBAN',
  ibanValidity: 'Valid IBAN',
  Identity: 'identified',
  markedForExec: 'Marked for Exec',
  markedForExecution: 'Marked for Execution',
  nameIdentity: 'Name Identity',
  noTagsAssigned: 'No tags assigned',
  paymentDate: 'Payment Date',
  paymentFile: 'Payment File',
  paymentFileId: '',
  paymentFiles: 'Payment Files',
  paymentFileView: 'Payment File View',
  paymentsInDate: 'Payments',
  paymentStatus: 'Payment Status',
  paymentStatuses: {
    GENERATED: 'Generated',
    PENDING: 'Pending',
    COMPLETED: 'Completed',
    INQUIRY: 'Inquiry',
  },
  pendingDate: 'Pending at',
  playerId: 'Player ID',
  processed: 'Processed',
  requestedUTC: 'Requested UTC',
  revertToPending: 'Revert to Pending',
  riskLevel: 'Risk Level',
  saveColumns: 'Save current columns',
  searchAvailableColumns: 'Search Available Columns',
  searchSelectedColumns: 'Search Selected Columns',
  searchByPlayerId: 'Search by Player ID',
  showTags: 'Show Tags',
  totalAmount: 'Total Amount',
  totalCount: 'TXNS',
  transactionId: 'Transaction ID',
  TXNID: ' Txn ID',
  TXNStatus: ' Txn Status',
  withdrawal: 'withdrawal',
  withdrawals: 'Withdrawals',
  xml: 'XML',
  withdrawalInfo: {
    mustSelect: 'You must select a withdrawal first.',
    invalidItems:
      'You have selected withdrawals that lack either IBAN, BIC or Beneficiary Name. Please check again.',
    missingIban: 'Missing IBAN',
    cantCreatePayment:
      'You have payments in GENERATED STATUS, please process them first.',
    cantCreateFromRejected: `You can't generate a payment file from rejected withdrawals.`,
    wasProcessed: 'This item was already marked for execution.',
    cantWithdraw: `You can't withdraw a quantity greater than the real balance.`,
  },
  withdrawalSettings: {
    columns: 'Columns',
    configurationName: 'Configuration Name',
    existing: 'Existing Configurations',
    existingName: 'Existing Configuration Name',
    loadColumns: 'Load Saved Columns',
    overwrite: 'Overwrite',
    saveConfig:
      'Save as brand new configuration or select another configuration to overwrite it.',
    savedConfigurations: 'Saved Configurations',
    loadConfig:
      'Choose a saved configuration in order to load it on your screen.',
  },
}

export default withdrawals
