import { call, put, select } from 'redux-saga/effects'
import Container from 'typedi'
import FileStorageService from 'services/file-storage/index.service'

import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import i18n from 'i18n'
import defaultSnackbarError from 'constants/default-error-snackbar.constant'

import { currentAccountStatus } from 'state/player-card/selectors'
import { AccountStatusLog } from 'state/player-card/models'
import { UploadDocumentResponse, RequestType } from '../models'

import {
  uploadDocumentsAction,
  getDocumentImagesAction,
} from '../actions/rest.actions'

/**
 * @instance FileStorageService
 */
const fileStorageService: FileStorageService = Container.get(FileStorageService)

/**
 *
 * This saga uploads an image / pdf
 *
 * @requires FileStorageService
 * @param {ReturnType<typeof uploadDocumentsAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* uploadDocumentWorker(
  action: ReturnType<typeof uploadDocumentsAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    fileStorageService.requestParams = {
      method: 'POST',
      endpoint: '/upload',
      body: action.payload.form,
    }

    const response: UploadDocumentResponse = yield call(
      fileStorageService.uploadFile,
      'burek',
    )
    yield put(setLoadingFullAction(false))
    if (response.msg === 'Uploaded') {
      const { playerId } = action.payload
      yield put(uploadDocumentsAction.success(response))

      yield put(
        setSnackbarAction({
          message: i18n.t('messages.successfully', {
            item: i18n.t('file'),
            what: i18n.t(`messages.uploaded`),
          }),
          open: true,
          variant: 'success',
        }),
      )
      const playerAccount: AccountStatusLog = yield select(currentAccountStatus)
      const [file] = response?.storageResponse

      const { user } = yield select()

      yield put(
        getDocumentImagesAction.request({
          folderName: playerId,
          requestType: RequestType.CREATE,
          image: response.storageResponse,
          createDocPayload: action.payload,
          file,
          documentType: action.payload.documentType,
          accountStatus: playerAccount.accountStatus,
          playerId: action.payload.playerId,
          user,
        }),
      )
    }
    if (response.msg !== 'Uploaded') {
      if (typeof response.msg === 'string') {
        yield put(
          setSnackbarAction({
            message: response.msg,
            open: true,
            variant: 'error',
          }),
        )
      } else {
        defaultSnackbarError()
      }
    }
  } catch (error) {
    defaultSnackbarError()
    yield put(setLoadingFullAction(false))
    yield put(uploadDocumentsAction.failure(error))
  }
}

export default uploadDocumentWorker
