const login = {
  confirmNewPassword: 'Confirm new password',
  createNewPassword: 'Create new password',
  currentEnvironment: 'Current Environment',
  currentPassword: 'Current password',
  dashboardHome: 'Dashboard Home',
  dashboardIntro: 'Have a nice day of work.',
  forgotPasswordSubtitle:
    'Enter the email address you used when you joined and we’ll send you instructions to reset your password.',
  enterYourEmail: 'Enter your Email',
  environment: 'environment',
  forgotPassword: 'Forgot Password',
  gamanzaGamingGenius: 'Gamanza GaminGenius™',
  login: 'Login',
  newPassword: 'New password',
  resetPassword: 'Reset Password',
  password: 'Password',
  resetPasswordSubtitle: 'Please enter your new password and then confirm it.',
  quickAccess: 'Quick Access',
  sendInstructions: 'Send Instructions',
  updatePassword: 'Update Password',
  usernameOrEmail: 'Username or Email',
  usernameOrEmailIncorrect: 'Username or email incorrect, please try again',
  welcomeBack: 'Welcome back',
  welcomeTo: 'Welcome to',
  youAreWorking: 'you are working in the',
  slides: {
    titleAut: 'Automation',
    descriptionAut:
      'The GaminGenius™ Real-time CRM allows automated marketing campaigns in real time via email, SMS, Pop-ups and push notifications.',
    titleSeg: 'Segmentation',
    descriptionSeg:
      'Dynamic and static segments can be created using a wide range of parameters, through a deep integration with other GaminGenius™ services.',
    titleCam: 'Campaigns',
    descriptionCam:
      'Easily manage a library of permanent, periodic or special campaigns that can be “set and forget”, or otherwise triggered by time slots or events.',
    titleAna: 'Analytics',
    descriptionAna:
      'Dive into rich campaign analytics that provide a comprehensive overview of logins, deposits, claimed bonuses, GGR and more.',
    titlePer: 'Personalization',
    descriptionPer:
      'Track an individual player’s interactions with a campaign and generate visual insights to their gaming preferences – use this to personalise offers.',
  },
}

export default login
