import ActionTypes from '../constants'
import { BlacklistState, BlacklistActions } from '../models'

const deleteBlacklistRecordHandler = (
  state: BlacklistState,
  action: BlacklistActions,
): BlacklistState => {
  switch (action.type) {
    case ActionTypes.DELETE_BLACKLIST_RECORD:
      return {
        ...state,
      }

    case ActionTypes.DELETE_BLACKLIST_RECORD_SUBSCRIBER:
      return {
        ...state,
        reports: state.reports.filter(
          record => record.id !== action.payload?.data,
        ),
      }

    default:
      return state
  }
}

export default deleteBlacklistRecordHandler
