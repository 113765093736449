import ActionTypes from '../constants'
import { MediaDatabaseState, MediaDatabaseActions } from '../models'

const { REACT_APP_DATABASE_ROOT } = process.env

const getDataHandler = (
  state: MediaDatabaseState,
  action: MediaDatabaseActions,
): MediaDatabaseState => {
  switch (action.type) {
    case ActionTypes.GET_FOLDERS:
      return {
        ...state,
        storage: action.payload,
      }

    case ActionTypes.GET_FILES: {
      const filesArray = Object.keys(action.payload)
        .filter(
          (key: string) =>
            action.payload[key].root &&
            action.payload[key].root.includes(REACT_APP_DATABASE_ROOT || 'xxx'),
        )
        .map((key: string) => {
          return {
            ...action.payload[key],
            id: key,
          }
        })

      return {
        ...state,
        files: filesArray,
      }
    }

    case ActionTypes.SET_ACTIVE_FOLDER:
      return {
        ...state,
        activeFolder: action.payload,
      }

    case ActionTypes.SET_UPLOAD:
      return {
        ...state,
        upload: action.payload,
      }

    case ActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    default:
      return state
  }
}

export default getDataHandler
