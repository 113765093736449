/**
 *
 * KYC document actions
 *
 */

import { createCustomAction, createAction } from 'typesafe-actions'
import WS_ROUTES from 'state/websocket.routes'
import WS_NAMESPACES from 'state/websocket.namespaces'

import { GetIssuesPayload, GetIssuesResponse } from 'state/issues/model'
import ActionTypes from '../constants'
import { DispatchSocket } from '../../middleware/sockets/utils'

import {
  AddDocumentPayload,
  UpdateDocumentPayload,
  DeleteDocumentPayload,
  DeleteDocumentResponse,
  CreateDocumentPayload,
  CreateDocumentResponse,
  GetDocumentsPayload,
  GetDocumentsResponse,
  CreateUtilityIssuePayload,
  CreateUtilityIssueResponse,
  UpdateDocumentResponse,
  DocumentFormatType,
  DocumentVerifyUpdatePayload,
  DocumentVerifyUpdateResponse,
  DocumentDataInsertPayload,
  DocumentDataInsertResponse,
  UtilityVerifyUpdatePayload,
  AddressVerifyUpdatePayload,
} from '../models'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET DOCUMENTS
 *
 */
export const getDocuments = createCustomAction(
  ActionTypes.GET_DOCUMENTS,
  type => (
    payload: GetDocumentsPayload,
    event: typeof WS_ROUTES.KYC.GET_DOCUMENTS,
    handler?: (response: GetDocumentsResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    overwrite: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
  }),
)

export const getDocumentsSubscribe = createAction(
  ActionTypes.GET_DOCUMENTS_SUBSCRIBER,
  action => {
    return (response: GetDocumentsResponse) => {
      return action(response)
    }
  },
)

export const getDocumentsException = createAction(
  ActionTypes.GET_DOCUMENTS_EXCEPTION,
  action => {
    return (response: any) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] ADD DOCUMENT
 *
 */
export const addDocument = createCustomAction(
  ActionTypes.ADD_DOCUMENT,
  type => (
    payload: AddDocumentPayload,
    event: typeof WS_ROUTES.KYC.ADD_DOCUMENT,
  ) => ({
    type,
    event,
    emit: true,
    payload,
  }),
)

export function addDocumentSubscribe() {
  return (dispatch: DispatchSocket) =>
    dispatch({
      event: WS_ROUTES.KYC.ADD_DOCUMENT,
      handle: (response: any) =>
        dispatch({
          type: ActionTypes.ADD_DOCUMENT_SUBSCRIBER,
          payload: response,
        }),
    })
}

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE DOCUMENT
 *
 */
export const updateDocument = createCustomAction(
  ActionTypes.UPDATE_DOCUMENT,
  type => (
    payload: UpdateDocumentPayload,
    event: typeof WS_ROUTES.KYC.UPDATE_DOCUMENT,
    handler?: (response: UpdateDocumentResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    payload,
    handler,
  }),
)

export const updateDocumentSubscribe = createAction(
  ActionTypes.UPDATE_DOCUMENT_SUBSCRIBER,
  action => {
    return (response: UpdateDocumentResponse) => {
      return action(response)
    }
  },
)

export const updateDocumentException = createAction(
  ActionTypes.UPDATE_DOCUMENT_EXCEPTION,
  action => {
    return (response: UpdateDocumentResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DELETE DOCUMENT
 *
 */
export const deleteDocument = createCustomAction(
  ActionTypes.DELETE_DOCUMENT,
  type => (
    payload: DeleteDocumentPayload,
    event: typeof WS_ROUTES.KYC.DELETE_DOCUMENT,
    handler?: (response: DeleteDocumentResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    payload,
    handler,
  }),
)

export const deleteDocumentSubscribe = createAction(
  ActionTypes.DELETE_DOCUMENT_SUBSCRIBER,
  action => {
    return (response: DeleteDocumentResponse) => {
      return action(response)
    }
  },
)

export const deleteDocumentException = createAction(
  ActionTypes.DELETE_DOCUMENT_EXCEPTION,
  action => {
    return (response: DeleteDocumentResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE DOCUMENT
 *
 */
export const createDocument = createCustomAction(
  ActionTypes.CREATE_DOCUMENT,
  type => (
    payload: CreateDocumentPayload,
    event: typeof WS_ROUTES.KYC.CREATE_DOCUMENT,
    handler?: (response: CreateDocumentResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
  }),
)

export const createDocumentSubscribe = createAction(
  ActionTypes.CREATE_DOCUMENT_SUBSCRIBER,
  action => {
    return (response: CreateDocumentResponse) => {
      return action(response)
    }
  },
)

export const createDocumentException = createAction(
  ActionTypes.CREATE_DOCUMENT_EXCEPTION,
  action => {
    return (response: CreateDocumentResponse) => {
      return action(response)
    }
  },
)

export const resetDocumentsState = createAction(ActionTypes.RESET_DOC_STATE)

export const setDocumentFormat = createAction(
  ActionTypes.SET_DOCUMENT_FORMAT,
  action => {
    return (payload: DocumentFormatType) => {
      return action(payload)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE UTILITY DOCUMENT
 *
 */
export const createUtilityDocument = createCustomAction(
  ActionTypes.CREATE_UTILITY_DOCUMENT,
  type => (
    payload: CreateUtilityIssuePayload,
    event: typeof WS_ROUTES.KYC.CREATE_UTILITY_ISSUE_DOCUMENT,
    handler?: (response: CreateUtilityIssueResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const createUtilityDocumentSubscribe = createAction(
  ActionTypes.CREATE_UTILITY_DOCUMENT_SUBSCRIBER,
  action => {
    return (response: CreateUtilityIssueResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ALERT KYC DOCS
 *
 */
export const getAlertKYCDocs = createCustomAction(
  ActionTypes.GET_ALERT_KYC_DOCS,
  type => (
    payload: GetIssuesPayload,
    event: typeof WS_ROUTES.ISSUE.GET_ISSUES,
    handler?: (data: GetIssuesResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DOCUMENT VERIFY UPDATE
 *
 */

export const documentVerifyUpdate = createCustomAction(
  ActionTypes.DOCUMENT_VERIFY_UPDATE,
  type => (
    payload: DocumentVerifyUpdatePayload,
    event: typeof WS_ROUTES.KYC.DOCUMENT_VERIFY_UPDATE,
    handler?: (response: DocumentVerifyUpdateResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const documentVerifyUpdateSubscribe = createAction(
  ActionTypes.DOCUMENT_VERIFY_UPDATE_SUBSCRIBER,
  action => {
    return (response: DocumentVerifyUpdateResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UTILITY VERIFY UPDATE
 *
 */

export const utilityVerifyUpdate = createCustomAction(
  ActionTypes.UTILITY_VERIFY_UPDATE,
  type => (
    payload: UtilityVerifyUpdatePayload,
    event: typeof WS_ROUTES.KYC.UTILITY_VERIFY_UPDATE,
    handler?: (response: DocumentVerifyUpdateResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const utilityVerifyUpdateSubscribe = createAction(
  ActionTypes.UTILITY_VERIFY_UPDATE_SUBSCRIBER,
  action => {
    return (response: DocumentVerifyUpdateResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UTILITY VERIFY UPDATE
 *
 */

export const addressVerifyUpdate = createCustomAction(
  ActionTypes.ADDRESS_VERIFY_UPDATE,
  type => (
    payload: AddressVerifyUpdatePayload,
    event: typeof WS_ROUTES.KYC.ADDRESS_VERIFY_UPDATE,
    handler?: (response: DocumentVerifyUpdateResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const addressVerifyUpdateSubscribe = createAction(
  ActionTypes.ADDRESS_VERIFY_UPDATE_SUBSCRIBER,
  action => {
    return (response: DocumentVerifyUpdateResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DOCUMENT DATA INSERT
 *
 */

export const documentDataInsert = createCustomAction(
  ActionTypes.DOCUMENT_DATA_INSERT,
  type => (
    payload: DocumentDataInsertPayload,
    event: typeof WS_ROUTES.KYC.DOCUMENT_DATA_INSERT,
    handler?: (response: DocumentDataInsertResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const documentDataInsertSubscribe = createAction(
  ActionTypes.DOCUMENT_DATA_INSERT_SUBSCRIBER,
  action => {
    return (response: DocumentDataInsertResponse) => {
      return action(response)
    }
  },
)

export const getAlertKYCDocsSubscribe = createAction(
  ActionTypes.GET_ALERT_KYC_DOCS_SUBSCRIBER,
  action => {
    return (response: GetIssuesResponse) => {
      return action(response)
    }
  },
)

export const setCurrentDownloadedImage = createAction(
  ActionTypes.SET_CURRENT_DOWNLOADED_IMAGE,
  action => {
    return (url: string) => {
      return action(url)
    }
  },
)

export const setCurrentDownloadedSecondImage = createAction(
  ActionTypes.SET_CURRENT_DOWNLOADED_SECOND_IMAGE,
  action => {
    return (url: string) => {
      return action(url)
    }
  },
)
