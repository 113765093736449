import { createReducer } from 'typesafe-actions'
import * as BonusModels from '../models'
import ActionTypes from '../constants'

import BonusInitialState from './initial-state'
import getBonusHistoryHandler from './get-bonus-history.handler'
import cancelBonusHandler from './cancel-bonus.handler'

import getBonusesHandler from './get-bonuses.handler'
import templateGeneralHandler from './general.handler'
import getBonusLogsHandler from './get-bonus-logs.handler'

/**
 *
 * Bonus main reducer
 *
 */
const bonusReducer = createReducer<
  BonusModels.BonusState,
  BonusModels.BonusActions
>(BonusInitialState)
  .handleAction(
    [
      ActionTypes.GET_BONUS_HISTORY,
      ActionTypes.GET_BONUS_HISTORY_SUBSCRIBER,
      ActionTypes.GET_FILTER_BONUS_HISTORY_SUBSCRIBER,
    ],
    getBonusHistoryHandler,
  )
  .handleAction(
    [ActionTypes.GET_BONUS_LOGS, ActionTypes.GET_BONUS_LOGS_SUBSCRIBER],
    getBonusLogsHandler,
  )
  .handleAction(
    [ActionTypes.CANCEL_BONUS, ActionTypes.CANCEL_BONUS_SUBSCRIBER],
    cancelBonusHandler,
  )
  .handleAction(
    [ActionTypes.GET_BONUSES, ActionTypes.GET_BONUSES_SUBSCRIBER],
    getBonusesHandler,
  )
  .handleAction(
    [
      ActionTypes.SET_PAGINATION_BONUS_ACTIVE,
      ActionTypes.SET_PAGINATION_BONUS_COMPLETED,
      ActionTypes.SET_FILTERS,
    ],
    templateGeneralHandler,
  )

export default bonusReducer
