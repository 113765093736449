import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { interval } from 'rxjs'

import { AppBar, Box, Toolbar, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import TimeZoneUTCType from 'state/enums/timezone-utc.enum'
import TimeZoneType from 'state/enums/timezone.enum'
import RoleType from 'state/enums/role.enum'

import { ApplicationState } from 'state/root.models'
import { timeZoneSelector } from 'state/user/selectors'
import { useSelector, shallowEqual } from 'react-redux'

import { UserSelectors } from 'state/user'
import SearchBar from 'views/components/search-bar/index.component'
import TopBarDropdownComponent from './top-bar-dropdown.component'

type Props = { hasSearchBar: boolean }

const TopBarComponent = ({ hasSearchBar }: Props) => {
  const { t } = useTranslation()

  const [today, setToday] = useState(new Date())

  const userSessionStorage = sessionStorage.getItem('user')

  const { userSession } = useSelector(({ auth }: ApplicationState) => auth)

  const { loggedInUser } = useSelector(UserSelectors.root, shallowEqual)

  const timeZoneText = timeZoneSelector()

  const todayUTC = utcToZonedTime(today, TimeZoneUTCType[TimeZoneType.GMT_0])

  const todayZoned = utcToZonedTime(today, TimeZoneUTCType[timeZoneText])

  const utcTime = (): string => {
    return `${format(todayUTC, 'd MMM - H:mm')} UTC`
  }

  const gtmTime = (): string => {
    return `${format(todayZoned, 'd MMM - H:mm')} ${timeZoneText.slice(0, 6)}`
  }

  useEffect(() => {
    const $subscription = interval(1000).subscribe(() => setToday(new Date()))

    return () => {
      $subscription.unsubscribe()
    }
  }, [userSessionStorage]) // eslint-disable-line

  return (
    <AppBar position="fixed" elevation={0} square>
      <Box clone>
        <Toolbar>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            {(userSessionStorage || loggedInUser) && (
              <>
                <Box display="flex" flexShrink={0} mr={2}>
                  <Typography variant="caption" component="p">
                    {utcTime()}
                  </Typography>

                  <Typography variant="caption" component="p">
                    <Box clone display="inline-flex" ml={1.5} mr={0.5}>
                      <strong>{t('sidebar.yourTime')}:</strong>
                    </Box>
                    {gtmTime()}
                  </Typography>
                </Box>
                {userSession.role.includes(RoleType.PLAYER_CARD) ||
                userSession.role.includes(RoleType.SUPER_ADMIN)
                  ? hasSearchBar && (
                      <Box mr={2}>
                        <SearchBar />
                      </Box>
                    )
                  : ''}
                <TopBarDropdownComponent />
              </>
            )}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  )
}

export default TopBarComponent
