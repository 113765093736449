import * as LimitModels from '../models'
import ActionTypes from '../constants'

const maxLimitsDepositHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.GET_MAX_LIMITS_DEPOSIT:
      return {
        ...state,
      }

    case ActionTypes.GET_MAX_LIMITS_DEPOSIT_SUBSCRIBER:
      return {
        ...state,
        maxLimitDeposit: action.payload.message,
      }

    default:
      return state
  }
}

export default maxLimitsDepositHandler
