/**
 *
 * User Action constants
 *
 * @action CREATE
 * @action UPDATE
 * @action DELETE
 * @action GET_ALL
 * @action GET_ALL_SUBSCRIBER
 * @action GET_ONE
 * @action RESET_PASSWORD
 * @action CREATE_RESET_PASSWORD_TOKEN
 * @action CHANGE_USER_STATUS
 * @action CREATE_SUBSCRIBER
 * @action UPDATE_SUBSCRIBER
 * @action UPDATE_STATUS_SUBSCRIBER
 * @action DELETE_SUBSCRIBER
 */
enum ActionTypes {
  CREATE_REQUEST = '@user/CREATE_REQUEST',
  CREATE_SUCCESS = '@user/CREATE_SUCCESS',
  CREATE_FAILURE = '@user/CREATE_FAILURE',
  CREATE_CANCEL = '@user/CREATE_CANCEL',

  UPDATE_REQUEST = '@user/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@user/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@user/UPDATE_FAILURE',

  DELETE_REQUEST = '@user/DELETE_REQUEST',
  DELETE_SUCCESS = '@user/DELETE_SUCCESS',
  DELETE_FAILURE = '@user/DELETE_FAILURE',

  GET_ALL_REQUEST = '@user/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@user/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@user/GET_ALL_FAILURE',

  RESET_PASSWORD_REQUEST = '@user/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE',

  CREATE_RESET_PASSWORD_TOKEN_REQUEST = '@user/CREATE_RESET_PASSWORD_TOKEN_REQUEST',
  CREATE_RESET_PASSWORD_TOKEN_SUCCESS = '@user/CREATE_RESET_PASSWORD_TOKEN_SUCCESS',
  CREATE_RESET_PASSWORD_TOKEN_FAILURE = '@user/CREATE_RESET_PASSWORD_TOKEN_FAILURE',
  CREATE_RESET_PASSWORD_TOKEN_CANCEL = '@user/CREATE_RESET_PASSWORD_TOKEN_CANCEL',

  SEARCH_REQUEST = '@user/SEARCH_REQUEST',
  SEARCH_SUCCESS = '@user/SEARCH_SUCCESS',
  SEARCH_FAILURE = '@user/SEARCH_FAILURE',

  CHANGE_USER_STATUS = '@user/CHANGE_USER_STATUS',

  GET_LOGGED_IN_REQUEST = '@user/GET_LOGGED_IN_REQUEST',
  GET_LOGGED_IN_SUCCESS = '@user/GET_LOGGED_IN_SUCCESS',
  GET_LOGGED_IN_FAILURE = '@user/GET_LOGGED_IN_FAILURE',

  CREATE_SUBSCRIBER = '@user/CREATE_SUBSCRIBER',
  DELETE_SUBSCRIBER = '@user/DELETE_SUBSCRIBER',
  UPDATE_SUBSCRIBER = '@user/UPDATE_SUBSCRIBER',
  UPDATE_STATUS_SUBSCRIBER = '@user/UPDATE_STATUS_SUBSCRIBER',

  UPDATE_EMITTER = '@user/UPDATE_EMITTER',

  GET_OPERATORS_REQUEST = '@user/GET_OPERATORS_REQUEST',
  GET_OPERATORS_SUCCESS = '@user/GET_OPERATORS_SUCCESS',
  GET_OPERATORS_FAILURE = '@user/GET_OPERATORS_FAILURE',

  GET_OPERATOR_REQUEST = '@user/GET_OPERATOR_REQUEST',
  GET_OPERATOR_SUCCESS = '@user/GET_OPERATOR_SUCCESS',
  GET_OPERATOR_FAILURE = '@user/GET_OPERATOR_FAILURE',

  RESET_USER_STATE = '@user/RESET_USER_STATE',

  SET_FILTERS = '@user/SET_FILTERS',

  GET_SHORTCUTS_REQUEST = '@user/GET_SHORTCUTS_REQUEST',
  GET_SHORTCUTS_SUCCESS = '@user/GET_SHORTCUTS_SUCCESS',
  GET_SHORTCUTS_FAILURE = '@user/GET_SHORTCUTS_FAILURE',

  CREATE_SHORTCUTS_REQUEST = '@user/CREATE_SHORTCUTS_REQUEST',
  CREATE_SHORTCUTS_SUCCESS = '@user/CREATE_SHORTCUTS_SUCCESS',
  CREATE_SHORTCUTS_FAILURE = '@user/CREATE_SHORTCUTS_FAILURE',

  UPDATE_SHORTCUTS_REQUEST = '@user/UPDATE_SHORTCUTS_REQUEST',
  UPDATE_SHORTCUTS_SUCCESS = '@user/UPDATE_SHORTCUTS_SUCCESS',
  UPDATE_SHORTCUTS_FAILURE = '@user/UPDATE_SHORTCUTS_FAILURE',

  DELETE_SHORTCUTS_REQUEST = '@user/DELETE_SHORTCUTS_REQUEST',
  DELETE_SHORTCUTS_SUCCESS = '@user/DELETE_SHORTCUTS_SUCCESS',
  DELETE_SHORTCUTS_FAILURE = '@user/DELETE_SHORTCUTS_FAILURE',
}
export default ActionTypes
