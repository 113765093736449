import ActionTypes from '../constants'
import { CampaignState, CampaignActions } from '../models'

const campaignGetAllHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        campaigns: action.payload.docs,
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_CAMPAIGNS:
      return {
        ...state,
        campaigns: [],
      }

    default:
      return state
  }
}

export default campaignGetAllHandler
