const KYC = {
  ADD_DOCUMENT: 'addPlayerDocuments' as 'addPlayerDocuments',
  UPDATE_DOCUMENT: 'updateDocument' as 'updateDocument',
  DELETE_DOCUMENT: 'deleteDocument' as 'deleteDocument',
  CREATE_DOCUMENT: 'createDocument' as 'createDocument',
  GET_DOCUMENTS: 'getDocuments' as 'getDocuments',

  UPDATE_AML_STATUS: 'updatePlayerAmlStatus' as 'updatePlayerAmlStatus',
  GET_VETO_HISTORY: 'getVetoHistory' as 'getVetoHistory',
  GET_NON_PEP_HISTORY: 'non-pep-history-get' as 'non-pep-history-get',

  GET_TELEDATA_HISTORY: 'risk/limits-new-teledata-get' as 'risk/limits-new-teledata-get',
  CREATE_TELEDATA: 'risk/limits-new-teledata-create' as 'risk/limits-new-teledata-create',

  AML_SET_STATUS: 'risk/aml-set-status' as 'risk/aml-set-status',
  AML_GET_STATUS: 'risk/aml-get-status' as 'risk/aml-get-status',
  AML_GET_ANSWERS: 'risk/aml-get-answers' as 'risk/aml-get-answers',
  AML_SET_ANSWERS: 'risk/aml-set-answers' as 'risk/aml-set-answers',

  UPDATE_CRIF_CHECK: 'crif-check' as 'crif-check',
  UPDATE_CHECK_BLACKLIST: 'manual-check-blacklist' as 'manual-check-blacklist ',

  UPDATE_KYC_STATUS: 'createKycStatus' as 'createKycStatus',
  GET_KYC_STATUS: 'getKycStatus' as 'getKycStatus',

  CREATE_UTILITY_ISSUE_DOCUMENT: 'add-issue' as 'add-issue',

  GET_PLAYER_TELEDATA_HISTORY: 'risk/limits-new-teledata-history' as 'risk/limits-new-teledata-history',

  GET_PLAYER_CASES: 'auth/api/v1/ubiid/documents/get-player-cases' as 'auth/api/v1/ubiid/documents/get-player-cases',
  GET_CASE_FILES: 'auth/api/v1/ubiid/documents/get-case-files' as 'auth/api/v1/ubiid/documents/get-case-files',
  GET_FAILED_INTEGRITY_CHECK: 'auth/api/v1/ubiid/documents/get-failed-integrity-check' as 'auth/api/v1/ubiid/documents/get-failed-integrity-check',
  FAILED_INTEGRITY_CHECK_UPDATE: 'auth/api/v1/ubiid/documents/failed-integrity-check-update' as 'auth/api/v1/ubiid/documents/failed-integrity-check-update',
  FAILED_INTEGRITY_CHECK_REJECT: 'auth/api/v1/ubiid/documents/failed-integrity-check-reject' as 'auth/api/v1/ubiid/documents/failed-integrity-check-reject',

  GET_PLAYER_IBANS: 'auth/api/v1/iban/get-player-ibans' as 'auth/api/v1/iban/get-player-ibans',
  GET_PLAYER_IBAN_DOCUMENT: 'auth/api/v1/iban/get-document' as 'auth/api/v1/iban/get-document',
  SET_IBAN_REJECTED: 'auth/api/v1/iban/set-rejected' as 'auth/api/v1/iban/set-rejected',
  SET_IBAN_VERIFIED: 'auth/api/v1/iban/set-verified' as 'auth/api/v1/iban/set-verified',

  DOCUMENT_VERIFY_UPDATE: 'adbe/v1/auth/verifyPlayer/document-verify-update' as 'adbe/v1/auth/verifyPlayer/document-verify-update',
  UTILITY_VERIFY_UPDATE: 'adbe/v1/auth/verifyPlayer/utility-verify-update' as 'adbe/v1/auth/verifyPlayer/utility-verify-update',
  ADDRESS_VERIFY_UPDATE: 'adbe/v1/auth/verifyPlayer/address-verify-update' as 'adbe/v1/auth/verifyPlayer/address-verify-update',

  DOCUMENT_DATA_INSERT: 'adbe/v1/auth/verifyPlayer/document-data-insert' as 'adbe/v1/auth/verifyPlayer/document-data-insert',
}

export default KYC
