import { createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  UpdateCompanyPayload,
  GetOneCompanyPayload,
  UpdateCompanyResponse,
  GetOneCompanyResponse,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const updateCompanyAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateCompanyPayload, UpdateCompanyResponse, Error>()

export const getOneCompanyAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<GetOneCompanyPayload, GetOneCompanyResponse, Error>()
