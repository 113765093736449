/**
 *
 * EmailTracking constants
 *
 */

enum ActionTypes {
  GET_ALL_STATS_REQUEST = '@email-tracking/GET_ALL_REQUEST',
  GET_ALL_STATS_SUCCESS = '@email-tracking/GET_ALL_SUCCESS',
  GET_ALL_STATS_FAILURE = '@email-tracking/GET_ALL_FAILURE',
}

export default ActionTypes

/**
 *  @see {@link https://stackoverflow.com/questions/46569967/typings-for-normalized-redux-state-shape|Normalizr + TypeScript}
 */
