enum ActionTypes {
  WS_CONNECT = '@@websockets/CONNECT',
  WS_CONNECTING = '@@websockets/CONNECTING',
  WS_CONNECTED = '@@websockets/CONNECTED',

  WS_DISCONNECT = '@@websockets/DISCONNECT',
  WS_DISCONNECTED = '@@websockets/DISCONNECTED',
  WS_MSG_RECEIVED = '@@websockets/MSG_RECEIVED',

  WS_SET_SOCKET = '@@websockets/SET_SOCKET',
  WS_ADD_EVENT = '@@websockets/ADD_EVENT',

  WS_REGISTER_GATEWAY = '@@websockets/WS_REGISTER_GATEWAY',
  WS_GATEWAY_CONNECTED = '@@websockets/WS_GATEWAY_CONNECTED',
  WS_GATEWAY_DISCONNECTED = '@@websockets/WS_GATEWAY_DISCONNECTED',
}

export default ActionTypes
