import { withStyles, TextField, alpha, InputBase } from '@material-ui/core'
import { colors } from './colors.theme'

const { white, lightBlue, redDark, green, orangeLight, grayDark } = colors

export const inputBaseTheme = {
  root: {
    padding: '0!important',
    height: 36,
    lineHeight: '36px',
    '&:before, &:after': {
      content: 'none!important',
    },
    '&$disabled': {
      opacity: 0.5,
    },
  },
  input: {
    fontSize: 14,
    lineHeight: 1,
    padding: '8px 0!important',
    minHeight: 36,
    boxSizing: 'border-box' as 'border-box',
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
      '-webkit-box-shadow': 'none',
    },
    '&::-webkit-clear-button': {
      display: 'none',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      opacity: 0.5,
    },
  },
  multiline: {
    height: 'auto!important',
  },
  inputMultiline: {
    lineHeight: 1.5,
  },
}

export const filledInputTheme = {
  root: {
    backgroundColor: grayDark,
    borderRadius: '4px!important',
    fontSize: 14,
    color: alpha(white, 0.5),
    '&:hover, &.Mui-focused': {
      backgroundColor: grayDark,
    },
    '&$disabled': {
      backgroundColor: grayDark,
    },
  },
  input: {
    margin: '0 8px',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
}

export const outlinedInputTheme = {
  input: {
    margin: '0 8px',
    '&::placeholder': {
      opacity: 0.5,
    },
    '&:-webkit-autofill': {
      transitionDelay: '9999s',
      transitionProperty: 'background-color, color',
      '-webkit-box-shadow': 'none',
    },
  },
  notchedOutline: {
    borderWidth: 2,
    borderRadius: 4,
    borderColor: alpha(white, 0.7),
    fontSize: 14,
    color: alpha(white, 0.7),
    '& legend span': {
      lineHeight: 1,
    },
    '$disabled &': {
      borderColor: 'initial!important',
    },
    '.MuiTextField-root:not(.label-float) &': {
      top: 0,
      '& legend': {
        display: 'none',
      },
    },
  },
}

export const inputLabelTheme = {
  root: {
    color: alpha(white, 0.7),
    fontWeight: 'bold' as 'bold',
    fontSize: 14,
    textTransform: 'capitalize' as 'capitalize',
    '&.Mui-focused': {
      color: `${alpha(white, 0.7)}!important`,
    },
  },
  shrink: {
    color: alpha(white, 0.7),
    fontWeight: 'bold' as 'bold',
    fontSize: 14,
    '&.Mui-focused': {
      color: `${alpha(white, 0.7)}!important`,
    },
  },
}

export const inputAdornmentTheme = {
  root: {
    color: alpha(white, 0.6),
    maxHeight: 'none',
    height: 32,
    padding: '0 6px 0 4px',
    '& > *': {
      color: alpha(white, 0.6),
    },
    '& .MuiIconButton-root': {
      padding: 0,
      margin: 0,
      background: 'none!important',
    },
  },
  positionStart: {
    margin: '0!important',
  },
  positionEnd: {
    margin: '0!important',
  },
}

export const InputBaseHighlighted = withStyles({
  root: {
    padding: '0 4px',
    margin: 8,
    width: 'auto',
    display: 'inline-block',

    '&.blue': {
      backgroundColor: alpha(lightBlue, 0.2),
      color: lightBlue,
    },
    '&.red': {
      backgroundColor: alpha(redDark, 0.2),
      color: redDark,
    },
    '&.green': {
      backgroundColor: alpha(green, 0.2),
      color: green,
    },
    '&.yellow': {
      backgroundColor: alpha(orangeLight, 0.2),
      color: orangeLight,
    },
  },
  input: {
    fontSize: 14,
    textAlign: 'center',
    '&::placeholder': {
      opacity: 0.5,
    },
  },
})(InputBase)

export const ValidationTextField = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: green,
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: redDark,
      borderWidth: 2,
    },
  },
})(TextField)
