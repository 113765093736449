const removeEmptyProp = (data: any) => {
  const formatData = { ...data }
  Object.keys(formatData).forEach(key => {
    return (
      (formatData[key] === null ||
        formatData[key] === '' ||
        formatData[key].length === 0) &&
      delete formatData[key]
    )
  })
  return formatData
}

export default removeEmptyProp
