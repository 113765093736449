const users = {
  userManagement: 'User Management',
  users: 'Users',
  roles: 'Roles',
  searchUser: 'Search User',
  editUser: 'Edit User',
  createNewUser: 'Create New User',
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  username: 'Username',
  environments: 'Environments',
  firstName: 'First Name',
  lastName: 'Last Name',
  securityQuestion: 'Security Question',
  answer: 'Answer',
  selectEnvironments: 'Select Environments',
  saveChanges: 'Save Changes',
  confirmUser: 'Confirm User',
  createUser: 'Create User',
  theUserHasBeenCreatedSuccessfully:
    'The user {{user}} has been created successfully',
  theUserHasBeenEditedSuccessfully:
    'The user {{user}} has been edited successfully',
  deleteConfirmationUserPermanently:
    'Are you sure you want to delete {{user}} permanently',
  userHasBeenDeletedPermanently:
    'The user {{user}} has been deleted permanently',
  thePasswordHasBeenCreatedSuccessfully:
    'Your password has been reset successfully',
  userEmailHasBeenSent: 'An email has been sent to {{email}}',
  update: 'update',
  youAreAboutToCreateThisUser: 'You are about to create this user',
  confirmChanges: 'Confirm Changes',
  youAreAboutToEditThisUser: 'You are about to edit this user',
  gaminGenius: 'GaminGenius™',
  admin: 'Admin',
  createUserText: 'Create a new user under your organization',
  editUserText: 'Edit an existing user under your organization',
  userStatus: 'User status',
  loginPage: 'Login',
  invalidUsername: 'Invalid username format',
  dontHaveProviderInfo: 'We could not get your email provider info.',
  roleType: {
    SUPER_ADMIN: 'Super Admin',
    CRM: 'CRM',
    PLAYER_CARD: 'Player Card',
    WITHDRAWALS: 'Withdrawals',
    ALERTS: 'Alerts',
    REPORTS: 'Reports',
    RULES: 'Rules Engine',
    SETTINGS: 'Settings',
    TAGS: 'Tags',
    GAMIFICATION: 'Gamification',
    BONUS: 'Bonus',
    USER_MANAGEMENT: 'User Management',
    GAME_STORE: 'Game Store',
  },
  active: 'Active',
  blocked: 'Blocked',
  failedLoginAttempts: 'Blocked ~ 3 Failed Login Attempts',
}

export default users
