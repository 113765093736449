import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const {
  green,
  white,
  redDark,
  turquoise,
  orange,
  purple,
  yellow,
  gray,
  cyan,
} = colors

export const svgIconTheme = {
  root: {
    '&.green-bg': {
      color: green,
      background: alpha(green, 0.3),
      borderRadius: '50%',
      padding: 2,
    },
    '&.red-bg': {
      color: redDark,
      background: alpha(redDark, 0.3),
      borderRadius: '50%',
      padding: 2,
    },
    '&.white-fade': {
      color: alpha(white, 0.2),
    },
    '&.white': {
      color: white,
    },
    '&.small': {
      fontSize: 20,
    },
    '&.cursor-pointer': {
      cursor: 'pointer',
    },
    '&.turquoise': {
      color: turquoise,
    },
    '&.orange': {
      color: orange,
    },
    '&.purple': {
      color: purple,
    },
    '&.green': {
      color: green,
    },
    '&.yellow': {
      color: yellow,
    },
    '&.red': {
      color: redDark,
    },
    '&.gray': {
      color: gray,
    },
    '&.cyan': {
      color: cyan,
    },
  },
}
