import BonusStatus from 'state/enums/bonus-status'
import BonusGeneralStatus from 'state/enums/bonus-general-status'
import * as BonusModels from '../models'

import ActionTypes from '../constants'

const getActiveBonuses = (status: BonusStatus) =>
  status === BonusStatus.ACCEPTED_AWAITING_EVENT ||
  status === BonusStatus.ELIGIBLE ||
  status === BonusStatus.GRANTED ||
  status === BonusStatus.GRANTED_PENDING_ACCEPT ||
  status === BonusStatus.GRANTED_PENDING_ACTIVATION ||
  status === BonusStatus.GRANTED_FREE_GAMES ||
  status === BonusStatus.FREE_GAME_ACTIVE

const getCompletedBonuses = (status: BonusStatus) =>
  status === BonusStatus.CANCELED ||
  status === BonusStatus.COMPLETED ||
  status === BonusStatus.CRITERIA_NOT_MET ||
  status === BonusStatus.DECLINED ||
  status === BonusStatus.ENDED ||
  status === BonusStatus.EXCLUDED ||
  status === BonusStatus.EXPIRED ||
  status === BonusStatus.GRANTED_EXPIRED

const getBonusHistoryHandler = (
  state: BonusModels.BonusState,
  action: BonusModels.BonusActions,
): BonusModels.BonusState => {
  switch (action.type) {
    case ActionTypes.GET_BONUS_HISTORY:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_BONUS_HISTORY_SUBSCRIBER: {
      const activeMoneyBonuses =
        action.payload?.payload?.data?.bonusInstances.filter(bonus =>
          getActiveBonuses(bonus.state),
        ) || []

      const completedMoneyBonuses =
        action.payload?.payload?.data?.bonusInstances.filter(bonus =>
          getCompletedBonuses(bonus.state),
        ) || []

      const activeFreeGamesBonuses =
        action.payload?.payload?.data?.freeGamesInstances.filter(bonus =>
          getActiveBonuses(bonus.state),
        ) || []

      const completedFreeGameBonuses =
        action.payload?.payload?.data?.freeGamesInstances.filter(bonus =>
          getCompletedBonuses(bonus.state),
        ) || []

      const activeBonuses = [...activeMoneyBonuses, ...activeFreeGamesBonuses]

      const completedBonuses = [
        ...completedMoneyBonuses,
        ...completedFreeGameBonuses,
      ]

      return {
        ...state,
        isLoading: false,
        resolved: action.payload.resolved,
        allBonuses:
          [
            ...action.payload?.payload?.data?.bonusInstances,
            ...action.payload?.payload?.data?.freeGamesInstances,
          ] || [],
        activeBonuses,
        completedBonuses,
        paginationCompletedBonus: {
          ...state.paginationCompletedBonus,
          total: completedBonuses.length,
          pages: Math.ceil(
            completedBonuses.length / state.paginationCompletedBonus.limit,
          ),
        },
        paginationActiveBonus: {
          ...state.paginationActiveBonus,
          total: activeBonuses.length,
          pages: Math.ceil(
            activeBonuses.length / state.paginationActiveBonus.limit,
          ),
        },
      }
    }

    case ActionTypes.GET_FILTER_BONUS_HISTORY_SUBSCRIBER: {
      let listFilterBonus: BonusModels.BonusType[] = []
      state.filters.status?.forEach(filter => {
        listFilterBonus = [
          ...listFilterBonus,
          ...state.allBonuses.filter(
            bonus => filter.toString() === bonus.state.toString(),
          ),
        ]
      })

      let newState = {}

      if (
        (state.filters.status === undefined ||
          state.filters.status.length === 0) &&
        state.filters.typeBonus === BonusGeneralStatus.ACTIVE
      ) {
        const activeBonuses =
          state.allBonuses.filter(bonus => getActiveBonuses(bonus.state)) || []

        newState = {
          ...state,
          activeBonuses: [...activeBonuses],
          paginationActiveBonus: {
            ...state.paginationActiveBonus,
            total: activeBonuses.length,
            pages: Math.ceil(
              activeBonuses.length / state.paginationActiveBonus.limit,
            ),
            page: 1,
          },
        }
      } else if (
        (state.filters.status === undefined ||
          state.filters.status.length === 0) &&
        state.filters.typeBonus === BonusGeneralStatus.COMPLETED
      ) {
        const completedBonuses =
          state.allBonuses.filter(bonus => getCompletedBonuses(bonus.state)) ||
          []

        newState = {
          ...state,
          completedBonuses: [...completedBonuses],
          paginationCompletedBonus: {
            ...state.paginationCompletedBonus,
            total: completedBonuses.length,
            pages: Math.ceil(
              completedBonuses.length / state.paginationCompletedBonus.limit,
            ),
            page: 1,
          },
        }
      } else if (
        state.filters.status !== undefined &&
        state.filters.status.length > 0 &&
        getActiveBonuses(BonusStatus[state.filters.status[0]])
      ) {
        newState = {
          ...state,
          activeBonuses: listFilterBonus,
          paginationActiveBonus: {
            ...state.paginationActiveBonus,
            total: listFilterBonus.length,
            pages: Math.ceil(
              listFilterBonus.length / state.paginationActiveBonus.limit,
            ),
            page: 1,
          },
        }
      } else if (
        state.filters.status !== undefined &&
        state.filters.status.length > 0 &&
        getCompletedBonuses(BonusStatus[state.filters.status[0]])
      ) {
        newState = {
          ...state,
          completedBonuses: listFilterBonus,
          paginationCompletedBonus: {
            ...state.paginationCompletedBonus,
            total: listFilterBonus.length,
            pages: Math.ceil(
              listFilterBonus.length / state.paginationCompletedBonus.limit,
            ),
            page: 1,
          },
        }
      }

      return {
        ...state,
        ...newState,
      }
    }

    default:
      return state
  }
}

export default getBonusHistoryHandler
