/**
 *
 * Campaign constants
 *
 */

enum ActionTypes {
  GET_ALL_REQUEST = '@campaign/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@campaign/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@campaign/GET_ALL_FAILURE',

  GET_ALL_ARCHIVE_REQUEST = '@campaign/GET_ALL_ARCHIVE_REQUEST',
  GET_ALL_ARCHIVE_SUCCESS = '@campaign/GET_ALL_ARCHIVE_SUCCESS',
  GET_ALL_ARCHIVE_FAILURE = '@campaign/GET_ALL_ARCHIVE_FAILURE',

  GET_ONE_REQUEST = '@campaign/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@campaign/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@campaign/GET_ONE_FAILURE',

  CREATE_REQUEST = '@campaign/CREATE_REQUEST',
  CREATE_SUCCESS = '@campaign/CREATE_SUCCESS',
  CREATE_FAILURE = '@campaign/CREATE_FAILURE',

  UPDATE_REQUEST = '@campaign/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@campaign/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@campaign/UPDATE_FAILURE',

  SET_FILTERS = '@campaign/SET_FILTERS',
  SET_FILTERS_CAMPAIGN_BY_PLAYER = '@campaign/SET_FILTERS_CAMPAIGN_BY_PLAYER',

  UPDATE_STATUS_REQUEST = '@campaign/UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS = '@campaign/UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE = '@campaign/UPDATE_STATUS_FAILURE',

  ARCHIVE_REQUEST = '@campaign/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@campaign/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@campaign/ARCHIVE_FAILURE',

  DUPLICATE_REQUEST = '@campaign/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@campaign/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@campaign/DUPLICATE_FAILURE',

  GET_ALL_SEGMENTS_REQUEST = '@campaign/GET_ALL_SEGMENTS_REQUEST',
  GET_ALL_SEGMENTS_SUCCESS = '@campaign/GET_ALL_SEGMENTS_SUCCESS',
  GET_ALL_SEGMENTS_FAILURE = '@campaign/GET_ALL_SEGMENTS_FAILURE',

  GET_ALL_TEMPLATES_REQUEST = '@campaign/GET_ALL_TEMPLATES_REQUEST',
  GET_ALL_TEMPLATES_SUCCESS = '@campaign/GET_ALL_TEMPLATES_SUCCESS',
  GET_ALL_TEMPLATES_FAILURE = '@campaign/GET_ALL_TEMPLATES_FAILURE',

  GET_ONE_SEGMENT_REQUEST = '@campaign/GET_ONE_SEGMENT_REQUEST',
  GET_ONE_SEGMENT_SUCCESS = '@campaign/GET_ONE_SEGMENT_SUCCESS',
  GET_ONE_SEGMENT_FAILURE = '@campaign/GET_ONE_SEGMENT_FAILURE',

  GET_TEMPLATE_LANGUAGES_REQUEST = '@campaign/GET_TEMPLATE_LANGUAGES_REQUEST',
  GET_TEMPLATE_LANGUAGES_SUCCESS = '@campaign/GET_TEMPLATE_LANGUAGES_SUCCESS',
  GET_TEMPLATE_LANGUAGES_FAILURE = '@campaign/GET_TEMPLATE_LANGUAGES_FAILURE',

  RESET_CAMPAIGNS = '@campaign/RESET_CAMPAIGNS',
  RESET_CAMPAIGN = '@campaign/RESET_CAMPAIGN',
  RESET_TEMPLATE_LANGUAGES = '@campaign/RESET_TEMPLATE_LANGUAGES',

  RESET_SEGMENT = '@campaign/RESET_SEGMENT',

  GET_ALL_BONUSES = '@campaign/GET_ALL_BONUSES',
}

export default ActionTypes
