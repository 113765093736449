/**
 *
 * CompanyCreate constants
 *
 * @action TOGGLE_MODAL_FORM
 * @action SET_MODAL_TITLE
 *
 */

enum ActionTypes {
  TOGGLE_MODAL_FORM = '@ui-company/TOGGLE_MODAL_FORM',
  SET_MODAL_FORM_TITLE = '@ui-company/SET_MODAL_FORM_TITLE',

  SET_FORM_MODE = '@ui-company/SET_FORM_MODE',
}

export default ActionTypes
