import { createReducer } from 'typesafe-actions'
import { RulesEngineState, RulesEngineActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import generalHandler from './general.handler'
import createRuleHandler from './create.handler'

import findRuleHandler from './find.handler'
import filterRulesHandler from './filter-rules.handler'
import updateRuleHandler from './update.handler'

import getZendeskAgentsHandler from './get-zendesk-agents.handler'
import getBonusesHandler from './get-bonuses.handler'
import getSlackListHandler from './get-slack-list.handler'

/**
 * Creates a RulesEngine Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const rulesEngineReducer = createReducer<RulesEngineState, RulesEngineActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.SET_FILTERS,
      ActionTypes.RESET_RULES,
      ActionTypes.RESET_CURRENT_RULE,
    ],
    generalHandler,
  )
  .handleAction(
    [ActionTypes.CREATE_RULE, ActionTypes.CREATE_RULE_SUBSCRIBER],
    createRuleHandler,
  )
  .handleAction(
    [ActionTypes.UPDATE_RULE, ActionTypes.UPDATE_RULE_SUBSCRIBER],
    updateRuleHandler,
  )
  .handleAction(
    [ActionTypes.FIND_RULE, ActionTypes.FIND_RULE_SUBSCRIBER],
    findRuleHandler,
  )
  .handleAction(
    [ActionTypes.FILTER_RULES, ActionTypes.FILTER_RULES_SUBSCRIBER],
    filterRulesHandler,
  )
  .handleAction(
    [ActionTypes.GET_ZENDESK_AGENTS, ActionTypes.GET_ZENDESK_AGENTS_SUBSCRIBER],
    getZendeskAgentsHandler,
  )
  .handleAction(
    [ActionTypes.GET_BONUSES, ActionTypes.GET_BONUSES_SUBSCRIBER],
    getBonusesHandler,
  )
  .handleAction(
    [ActionTypes.GET_SLACK_LIST, ActionTypes.GET_SLACK_LIST_SUBSCRIBER],
    getSlackListHandler,
  )

export default rulesEngineReducer
