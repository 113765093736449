import ActionTypes from '../constants'
import { FrequencyCapState, FrequencyCapActions } from '../models'

const frequencyCapCreateHandler = (
  state: FrequencyCapState,
  action: FrequencyCapActions,
): FrequencyCapState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frequenciesCap: [...state.frequenciesCap, action.payload]
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default frequencyCapCreateHandler
