import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'state/root.models'

import { setSnackbarAction } from 'state/ui/global/actions'
import { SnackbarModel } from 'state/ui/global/models'

const useSnackbar = (snackbarModel: Partial<SnackbarModel>) => {
  const dispatch = useDispatch()
  const { snackbar } = useSelector(({ globalUi }: ApplicationState) => globalUi)

  const { open, message, variant } = snackbarModel

  const closeHandler = useCallback(() => {
    dispatch(
      setSnackbarAction({
        ...snackbar,
        open: false,
      }),
    )
  }, [dispatch, snackbar])

  const actionHandler = useCallback(() => {
    if (open && message && variant) {
      dispatch(
        setSnackbarAction({
          open,
          message,
          variant,
        }),
      )
    }
  }, [dispatch, open, message, variant])

  if (open && message && variant) {
    return {
      snackbar,
      actionHandler,
      closeHandler,
    }
  }

  return {
    snackbar,
    closeHandler,
  }
}

export default useSnackbar
