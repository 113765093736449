import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { TemplatesResponse } from '../models'
import { getAllTemplatesAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a list of onsite templates paginated
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllTemplatesAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllTemplatesWorker(
  action: ReturnType<typeof getAllTemplatesAction.request>,
) {
  try {
    const { auth, router } = yield select()
    const isArchive = router.location.pathname.includes('archive')

    apiService.requestParams = {
      method: 'GET',
      endpoint: 'templates/onsite/get',
      queries: jsonToQueryString({ ...action.payload, isArchive }),
    }

    const response: TemplatesResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllTemplatesAction.success(response))
  } catch (error) {
    yield put(getAllTemplatesAction.failure(error))
  }
}

export default getAllTemplatesWorker
