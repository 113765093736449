import ActionTypes from '../constants'
import {
  PlayerCardKYCHistoryState,
  KYCSocketActions,
  PlayerCardKYCActions,
} from '../models'
import { nonPepCheck } from '../data-test'

const historyHandler = (
  state: PlayerCardKYCHistoryState,
  action: KYCSocketActions | PlayerCardKYCActions,
): PlayerCardKYCHistoryState => {
  switch (action.type) {
    case ActionTypes.CRIF_CHECK_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        nonPepCheck: action.payload.resolved
          ? action.payload.message
          : nonPepCheck,
      }

    case ActionTypes.MANUAL_CHECK_BLACKLIST_SUBSCRIBER:
      return {
        ...state,
        vetoCheck: { resolved: action.payload },
      }

    case ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        nonPepCountIssues: action.payload?.message,
      }

    case ActionTypes.UPDATE_PEP_STATUS:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_PEP_STATUS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        nonPepCheck: {
          ...state.nonPepCheck,
          isPEP: action.payload.message?.issue?.data.isPEP as boolean,
        },
      }

    default:
      return state
  }
}

export default historyHandler
