import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'

// TODO: double-check store updates
const analyticsGetOneHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        AnalyticsGeneralStats: action.payload || {},
      }

    case ActionTypes.GET_ONE_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default analyticsGetOneHandler
