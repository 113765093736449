import { select, call, put } from 'redux-saga/effects'
import { Container } from 'typedi/Container'
import ApiService from 'services/api/index.service'

import { tokenSelector } from 'state/auth/selectors'
import { GetOneLanguagesResponse } from '../models'
import { getLanguagesTemplateAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga get all languages of a single email template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getLanguagesTemplateAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getLanguagesOneTemplateWorker(
  action: ReturnType<typeof getLanguagesTemplateAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'templates/email/one',
    }

    const token = yield select(tokenSelector)
    const response: GetOneLanguagesResponse = yield call(
      apiService.sendData,
      token,
    )

    yield put(getLanguagesTemplateAction.success(response))
  } catch (error) {
    yield put(getLanguagesTemplateAction.failure(error))
  }
}

export default getLanguagesOneTemplateWorker
