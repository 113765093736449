import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const getWalletHandler = (
  state: WalletModels.State,
  action: WalletModels.SocketActions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.GET_WALLET_SUBSCRIBER:
      return {
        ...state,
        wallet: action.payload,
      }

    default:
      return state
  }
}

export default getWalletHandler
