import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const getTemplateLanguagesHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_TEMPLATE_LANGUAGES_REQUEST:
      return {
        ...state,
        isLoadingPartial: true,
        currentTemplateLanguages: [],
      }

    case ActionTypes.GET_TEMPLATE_LANGUAGES_SUCCESS:
      return {
        ...state,
        isLoadingPartial: false,
        currentTemplateLanguages: action.payload || [],
      }

    case ActionTypes.GET_TEMPLATE_LANGUAGES_FAILURE:
      return {
        ...state,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_TEMPLATE_LANGUAGES:
      return {
        ...state,
        currentTemplateLanguages: [],
      }

    default:
      return state
  }
}

export default getTemplateLanguagesHandler
