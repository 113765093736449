import ActionTypes from '../constants'
import { BonusActions, BonusState } from '../models'

const templateGeneralHandler = (
  state: BonusState,
  action: BonusActions,
): BonusState => {
  switch (action.type) {
    case ActionTypes.SET_PAGINATION_BONUS_ACTIVE:
      return {
        ...state,
        paginationActiveBonus: {
          ...state.paginationActiveBonus,
          ...action.payload,
          pages: Math.ceil(
            state.activeBonuses.length /
              (action.payload.limit || state.paginationActiveBonus.limit),
          ),
        },
      }
    case ActionTypes.SET_PAGINATION_BONUS_COMPLETED:
      return {
        ...state,
        paginationCompletedBonus: {
          ...state.paginationCompletedBonus,
          ...action.payload,
          pages: Math.ceil(
            state.completedBonuses.length /
              (action.payload.limit || state.paginationCompletedBonus.limit),
          ),
        },
      }
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }

    default:
      return state
  }
}

export default templateGeneralHandler
