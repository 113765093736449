/**
 * User Actions
 */
import { createAsyncAction, createAction } from 'typesafe-actions'
import { ApiResponseType } from 'services/models'
import ActionTypes from '../constants'

import {
  CreateUserPayload,
  UpdateUserPayload,
  DeleteUserPayload,
  ResetPasswordPayload,
  ResetPasswordTokenPayload,
  UsersResponse,
  UserResponse,
  GetAllUserPayload,
  DeleteSubscriberResponse,
  GetAllOperatorsPayload,
  GetAllOperatorsResponse,
  Operator,
  GetOneOperatorPayload,
  FilterUsersPayload,
  GetShortcutsPayload,
  GetShortcutsResponse,
  CreateShortcutsPayload,
  CreateShortcutsResponse,
  UpdateShortcutsPayload,
  UpdateShortcutsResponse,
  DeleteShortcutsPayload,
  DeleteShortcutsResponse,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const getAllUserAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<GetAllUserPayload, UsersResponse, Error>()

export const updateUserAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateUserPayload, UserResponse, Error>()

export const createUserAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
  ActionTypes.CREATE_CANCEL,
)<CreateUserPayload, UserResponse, Error, string>()

export const deleteUserAction = createAsyncAction(
  ActionTypes.DELETE_REQUEST,
  ActionTypes.DELETE_SUCCESS,
  ActionTypes.DELETE_FAILURE,
)<DeleteUserPayload, DeleteSubscriberResponse, Error>()

export const createResetPassTokenAction = createAsyncAction(
  ActionTypes.CREATE_RESET_PASSWORD_TOKEN_REQUEST,
  ActionTypes.CREATE_RESET_PASSWORD_TOKEN_SUCCESS,
  ActionTypes.CREATE_RESET_PASSWORD_TOKEN_FAILURE,
  ActionTypes.CREATE_RESET_PASSWORD_TOKEN_CANCEL,
)<ResetPasswordTokenPayload, UserResponse, Error, undefined>()

export const resetPasswordAction = createAsyncAction(
  ActionTypes.RESET_PASSWORD_REQUEST,
  ActionTypes.RESET_PASSWORD_SUCCESS,
  ActionTypes.RESET_PASSWORD_FAILURE,
)<ResetPasswordPayload, ApiResponseType, Error>()

export const getLoggedInAction = createAsyncAction(
  ActionTypes.GET_LOGGED_IN_REQUEST,
  ActionTypes.GET_LOGGED_IN_SUCCESS,
  ActionTypes.GET_LOGGED_IN_FAILURE,
)<undefined, UserResponse, Error>()

export const getOperators = createAsyncAction(
  ActionTypes.GET_OPERATORS_REQUEST,
  ActionTypes.GET_OPERATORS_SUCCESS,
  ActionTypes.GET_OPERATORS_FAILURE,
)<GetAllOperatorsPayload, GetAllOperatorsResponse, Error>()

export const getOperatorById = createAsyncAction(
  ActionTypes.GET_OPERATOR_REQUEST,
  ActionTypes.GET_OPERATOR_SUCCESS,
  ActionTypes.GET_OPERATOR_FAILURE,
)<GetOneOperatorPayload, Operator, Error>()

export const getShortcutsAction = createAsyncAction(
  ActionTypes.GET_SHORTCUTS_REQUEST,
  ActionTypes.GET_SHORTCUTS_SUCCESS,
  ActionTypes.GET_SHORTCUTS_FAILURE,
)<GetShortcutsPayload, GetShortcutsResponse, Error>()

export const createShortcutsAction = createAsyncAction(
  ActionTypes.CREATE_SHORTCUTS_REQUEST,
  ActionTypes.CREATE_SHORTCUTS_SUCCESS,
  ActionTypes.CREATE_SHORTCUTS_FAILURE,
)<CreateShortcutsPayload, CreateShortcutsResponse, Error>()

export const updateShortcutsAction = createAsyncAction(
  ActionTypes.UPDATE_SHORTCUTS_REQUEST,
  ActionTypes.UPDATE_SHORTCUTS_SUCCESS,
  ActionTypes.UPDATE_SHORTCUTS_FAILURE,
)<UpdateShortcutsPayload, UpdateShortcutsResponse, Error>()

export const deleteShortcutsAction = createAsyncAction(
  ActionTypes.DELETE_SHORTCUTS_REQUEST,
  ActionTypes.DELETE_SHORTCUTS_SUCCESS,
  ActionTypes.DELETE_SHORTCUTS_FAILURE,
)<DeleteShortcutsPayload, DeleteShortcutsResponse, Error>()

export const resetUserState = createAction(ActionTypes.RESET_USER_STATE)

export const setUserFiltersAction = createAction(
  ActionTypes.SET_FILTERS,
  action => (filters: FilterUsersPayload) => action(filters),
)
