enum GGRDate {
  YESTERDAY = 'YESTERDAY',
  CURRENT_MONTH = 'CURRENT_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  DATE_RANGE = 'DATE_RANGE',
}

export default GGRDate
