import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
} from '@material-ui/core'
import { KeyboardArrowDown, ExitToApp } from '@material-ui/icons'

import routes from 'routes/index.routes'
import { UserSelectors } from 'state/user'
import { logoutAction } from 'state/auth/actions'

import AvatarLettersComponent from 'views/components/avatar-letters'
import useStyles from './style'

const TopBarDropdownComponent = () => {
  const { t } = useTranslation()

  const classes = useStyles()

  const dispatch = useDispatch()

  const logout = () => dispatch(logoutAction())

  const {
    loggedInUser: { firstName, lastName, email },
  } = useSelector(UserSelectors.root)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <AvatarLettersComponent firstName={firstName} lastName={lastName} />
        <Box clone mx="8px!important">
          <Button endIcon={<KeyboardArrowDown />} onClick={handleClick}>
            {`${firstName} ${lastName}`.toLowerCase()}
          </Button>
        </Box>
      </Box>
      <Box clone mt="17px">
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            style: {
              minWidth: 225,
            },
          }}
        >
          <Box px={2} py={1} className={classes.box}>
            <Typography variant="body2" className="capitalize fontWeight-700">
              {`${firstName} ${lastName}`.toLowerCase()}
            </Typography>
            <Typography variant="caption" component="p" gutterBottom>
              {email}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              component={Link}
              to={routes.account.EDIT}
              onClick={handleClose}
            >
              {t('sidebar.editProfile')}
            </Button>
          </Box>
          <Box clone m="8px!important">
            <Divider light />
          </Box>

          {/* 
          // TODO: uncomment when help page is implemented
          <MenuItem component={Link} to="/help" onClick={handleClose}>
            <ListItemIcon>
              <Help fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.helpSupport')} />
          </MenuItem> 
          */}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>

            <ListItemText primary={t('sidebar.logout')} />
          </MenuItem>
        </Menu>
      </Box>
    </>
  )
}

export default TopBarDropdownComponent
