import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'
import initialState from './initial-state'

const templateUpdateStatusReducer = (
  state: TemplateEmailState = initialState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.UPDATE_STATUS_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.map(template => {
          // eslint-disable-next-line no-underscore-dangle
          if (action.payload._id === template._id) {
            return Object.assign(template, action.payload)
          }
          return template
        }),
        isLoading: false,
      }

    case ActionTypes.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default templateUpdateStatusReducer
