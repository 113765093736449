import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const createRuleHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.CREATE_RULE:
      return {
        ...state,
      }

    case ActionTypes.CREATE_RULE_SUBSCRIBER:
      return {
        ...state,
        currentRule: action.payload,
      }

    default:
      return state
  }
}

export default createRuleHandler
