const kyc = {
  documentStatusOptions: {
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    IN_REVIEW: 'In Review',
  },
  bankAccountStatusOptions: {
    DOCUMENT_TOO_OLD: 'Document too old',
    IMAGE_QUALITY_TOO_LOW: 'Image quality too low',
    NOT_SAME_PERSON: 'Not same person',
    WRONG_DOCUMENT: 'Wrong document',
  },
  documentTypeOptions: {
    PASSPORT: 'Passport',
    ID: 'ID',
    DRIVERS_LICENSE: `Driver's License`,
    PROOF_OF_ADDRESS: 'Proof of Address',
    CC_COPY: 'CC Copy',
    BANK_STATEMENT: 'Bank Statement',
    PAYCARD_COPY: 'Paycard Copy',
    UTILITY_BILL: 'Utility Bill',
    LOCAL_AUTHORITY_TAX_BILL: 'Local Authority Tax Bill',
    OTHER: 'Other',
  },
  kycStatusOptions: {
    UNVERIFIED: 'Unverified',
    REQUESTED: 'Requested',
    UNDER_REVIEW: 'Under Review',
    VERIFIED: 'Verified',
    FAILED: 'Failed',
  },
  amlStatusOptions: {
    UNVERIFIED: 'Unverified',
    REQUESTED: 'Requested',
    UNDERREVIEW: 'Under Review',
    VERIFIED: 'Verified',
    FAILED: 'Failed',
  },
  amlSubstatusOptions: {
    UNTRIGGERED: 'Untriggered',
    PENDING_CUSTOMER: 'Pending Customer',
    ANSWERED: 'Answered',
    REVIEWED: 'Reviewed',
  },
  historyOptions: {
    VETO: 'Veto',
    NON_PEP: 'Non-PEP',
    TELEDATA: 'Teledata',
  },
  teledataOptions: {
    GREEN: 'Green',
    YELLOW: 'Yellow',
    RED: 'Red',
  },
  kyc: 'KYC',
  status: 'Status',
  uploaded: 'Uploaded',
  documentType: 'Document Type',
  issuingAuthority: 'Issuing Authority',
  documentNumber: 'Document Number',
  issueDate: 'Issue Date',
  expiryDate: 'Expiry Date',
  verificationStatus: 'Verification Status',
  playerDocuments: 'Documents uploaded from the player',
  uploadFile: 'Upload File',
  uploadNewDocument: 'Upload new document',
  chooseFile: 'Choose File',
  allowedFiles: 'Allowed Files',
  pdfImages: 'PDF, Images',
  noFileChosen: 'No File Chosen',
  checkingFile: 'Checking File',
  documentPreview: 'Document Preview',
  documentPreviewFront: 'Document Preview Front',
  documentPreviewBack: 'Document Preview Back',
  fullView: 'Full View',
  page: 'Page',
  of: 'of',
  editDocument: 'Edit Document',
  viewDocument: 'View Document',
  checkVeto: 'Check VETO',
  checkNonPep: 'Check Non PEP',
  checkPep: 'Check PEP',
  kycStatus: 'KYC Status',
  amlStatus: 'AML Status',
  amlPopUpStatus: 'AML Pop Up Status',
  vetoChecked: 'Veto Checked',
  vetoCheckUnderway: 'Manual blacklist VETO check is underway!',
  vetoCheckPerformed: 'VETO check performed',
  vetoCheckFailed: 'VETO check failed',
  crifCheckFailed:
    'There was an error while connecting to the CRIF. Please retry.',
  nonPepChecked: 'Non PEP Checked',
  playerInDatabase: 'The player appeared in the {{type}} database',
  playerNotInDatabase: 'The player did not appear in the {{type}} database',
  addTeledataStatus: 'Add Teledata Status',
  addTeledata: 'Add Teledata',
  selectTeledata: 'Select Teledata',
  teledata: 'Teledata',
  lastRealBet: 'Last Real Bet',
  limitReached: 'Limit Reached',
  isPEPIdentityOwner: `I have important political or administrative functions at national
  level, in an intergovernmental organization or in an international
  sports federation?`,
  isPEPIdentityOwnerFull:
    'I have important political or administrative functions at national level, in an intergovernmental organization or in an international sports federation?',
  certifyAccuracy: 'I certify the accuracy of the information provided.',
  specifyOwnerIdentity: 'Specify the identity of the owner',
  playerInfo: 'Player information',
  beneficialOwner:
    'The funds involved belong to me and I am the beneficial owner?',
  lastStatusUpdate: 'Last Status Update',
  answeredBy: 'Answered by',
  on: 'On',
  amlPopup: 'AML Pop Up',
  KYCFailed: 'KYC status update failed. Please try again later.',
  KYCUpdated: 'KYC status was updated successfully.',
  KYCUpdateAccount:
    'KYC status was verified and the account status was changed to active successfully.',
  number: 'Number',
  amlStatusVerified: 'The AML status has been VERIFIED',
  amlVerified: 'AML VERIFIED',
  kycVerified: 'KYC VERIFIED',
  kycErrorDocument: `Can't change a player's KYC status to Verified, please verify the ID or passport.`,
  kycErrorUtility: `Can't change a player's KYC status to Verified, please verify the Utility bill or Address.`,
  kycErrorEmailSMSUnverified:
    'Email or SMS must be verified in order to change the KYC status',
  jumioVerificationStatus: {
    APPROVED_VERIFIED: 'Approved Verified',
    DENIED_FRAUD: 'Denied Fraud',
    DENIED_UNSUPPORTED_ID_TYPE: 'Denied Unsupported ID Type',
    DENIED_UNSUPPORTED_ID_COUNTRY: 'Denied Unsupported ID Country',
    ERROR_NOT_READABLE_ID: 'Error Not Readable ID',
    NO_ID_UPLOADED: 'No ID Uploaded',
    UNDEFINED: 'Undefined',
  },
  uploadComments: {
    standard: 'The admin operator uploaded a document',
    utility: 'The admin operator uploaded an utility bill.',
    issue: 'Issue',
  },
  downloadPdf: 'Download PDF',
  manualCheckCRIF: 'Manual CRIF check',
  isPEP: 'Is this a politically exposed person ?',
  PEP: {
    pep: 'PEP',
    yes: 'Politically exposed person',
    no: 'Not a politically exposed person',
    success: 'The PEP status has been updated',
  },
  adminUtilitySuccessfullyVerified: 'Admin - Utility successfully verified',
  adminDocumentSuccessfullyVerified: 'Admin - Document successfully verified',
  teledataChange: 'Teledata change',
  teledataChangedTo: 'Teledata changed to',
  changedKYCStatus: 'KYC status was changed to {{status}}',
  pepChangedByOperator: 'PEP changed by operator',
  adminManuallyCheckPEP: 'Admin manually check PEP',
  changedAMLStatus:
    'The administrator changed the status from {{last}} to {{new}}',
  adminAddComment: 'The administrator added a comment "{{new}}"',
  adminUpdateComment:
    'The administrator update the comment from "{{last}}" to "{{new}}"',
  uploadDocument: 'Upload Document',
  uploadUtility: 'Upload Utility',
  contrast: 'Contrast',
  brightness: 'Brightness',
  rejectedReason: 'Rejected Reason',
  personalDetailsInTheDocument: 'Personal details in the Document',
  personalDetailsInThePlayerCard: 'Personal details in the Player Card',
  nameDoesNoMatch: '{{name}} does not match',
  nameMatches: '{{name}} matches',
  confirmUbitecMessage: `By approving this document, the personal details in the Player Card that do not match will be replaced by the ones in the Document. Do you still want to proceed?`,
  statusByAdmin: '{{status}} by: {{admin}}',
  noDocument: 'No document',
  bankAccount: 'Bank Account',
  bankInformation: 'Bank Information',
  personalInformation: 'Personal Information',
  updateBankInformation: 'Update Bank Information',
  viewBankInformation: 'View Bank Information',
  ubitecDocumentInfo:
    'Document details may be edited. To apply changes, please approve the document.',
  maxLimitRequired: 'Max-limit required',
}
export default kyc
