import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const getZendeskAgentsHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.GET_ZENDESK_AGENTS:
      return {
        ...state,
      }

    case ActionTypes.GET_ZENDESK_AGENTS_SUBSCRIBER:
      return {
        ...state,
        zendeskAgents: action.payload?.agents || [],
      }

    default:
      return state
  }
}

export default getZendeskAgentsHandler
