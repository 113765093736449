import { StateSchema } from 'hooks/form/models'

const resetPasswordSchema: StateSchema = {
  newPassword: {
    value: '',
    error: '',
  },
  confirmNewPassword: {
    value: '',
    error: '',
  },
}

export default resetPasswordSchema
