import { createReducer } from 'typesafe-actions'
import { CompanyState, CompanyActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getOneCompanyHandler from './get-one.handler'
import updateCompanyHandler from './update.handler'

import getSettingsHandler from './get-settings.handler'

/**
 * Creates a Company Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const companyReducer = createReducer<CompanyState, CompanyActions>(initialState)
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    getOneCompanyHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    updateCompanyHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_SETTINGS_SUBSCRIBER,
      ActionTypes.GET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER,
      ActionTypes.SET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER,
      ActionTypes.GET_VISITS_CONFIG_SUBSCRIBER,
      ActionTypes.GET_EVALUATION_SUBSCRIBER,
    ],
    getSettingsHandler,
  )

export default companyReducer
