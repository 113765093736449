import ActionTypes from '../constants'
import { TemplateActions, TemplateState } from '../models'

const templateGetOneHandler = (
  state: TemplateState,
  action: TemplateActions,
): TemplateState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    default:
      return state
  }
}

export default templateGetOneHandler
