enum WithdrawalTableColumns {
  TRANSACTION_ID = 'transactionId',
  USERNAME = 'username',
  PLAYER_NAME = 'name',
  PLAYER_ID = 'playerId',
  BENEFICIARY_NAME = 'beneficiaryName',
  IDENTITY_NAME = 'nameIdentity',
  ACCOUNT_STATUS = 'accountStatus',
  CREATED = 'created',
  TRANSACTION_AMOUNT = 'amount',
  TRANSACTION_STATUS = 'status',
  IBAN_VALIDITY = 'ibanValidity',
  IBAN = 'iban',
  BIC = 'bic',
  MARKED_FOR_EXECUTION = 'markedForExecution',
  PAYMENT_METHOD = 'method',
  KYC = 'kyc',
}

export default WithdrawalTableColumns
