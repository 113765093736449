import { createAction, createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  CreateCustomTriggerPayload,
  CreateCustomTriggerResponse,
  UpdateCustomTriggerPayload,
  UpdateCustomTriggerResponse,
  GetOneCustomTriggerPayload,
  GetOneCustomTriggerResponse,
  ArchiveCustomTriggerPayload,
  ArchiveCustomTriggerResponse,
  DuplicateCustomTriggerPayload,
  DuplicateCustomTriggerResponse,
  GetAllCustomTriggerFilteredPayload,
  CustomTriggersResponse,
} from '../models'

export const setFilters = createAction(
  ActionTypes.SET_CUSTOM_TRIGGER_FILTERS,
  action => {
    return (filters: GetAllCustomTriggerFilteredPayload) => {
      return action(filters)
    }
  },
)

export const resetCustomTriggers = createAction(
  ActionTypes.RESET_CUSTOM_TRIGGERS,
)

export const resetCurrentCustomTrigger = createAction(
  ActionTypes.RESET_CURRENT_CUSTOM_TRIGGER,
)

/**
 * @description
 * Listen for the Action:
 * * [EVENT] CREATE CUSTOM TRIGGER
 */
export const createCustomTriggerAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<CreateCustomTriggerPayload, CreateCustomTriggerResponse, Error>()

/**
 * @description
 * Listen for the Action:
 * * [EVENT] UPDATE CUSTOM TRIGGER
 */
export const updateCustomTriggerAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateCustomTriggerPayload, UpdateCustomTriggerResponse, Error>()

/**
 * @description
 * Listen for the Action:
 * * [EVENT] GET_ONE_ID CUSTOM TRIGGER
 */
export const getOneCustomTriggerAction = createAsyncAction(
  ActionTypes.GET_ONE_CUSTOM_TRIGGER_REQUEST,
  ActionTypes.GET_ONE_CUSTOM_TRIGGER_SUCCESS,
  ActionTypes.GET_ONE_CUSTOM_TRIGGER_FAILURE,
)<GetOneCustomTriggerPayload, GetOneCustomTriggerResponse, Error>()

/**
 * @description
 * Listen for the Action:
 * * [EVENT] GET_ALL CUSTOM TRIGGER
 */
export const getAllCustomTriggerAction = createAsyncAction(
  ActionTypes.GET_ALL_CUSTOM_TRIGGER_REQUEST,
  ActionTypes.GET_ALL_CUSTOM_TRIGGER_SUCCESS,
  ActionTypes.GET_ALL_CUSTOM_TRIGGER_FAILURE,
)<GetAllCustomTriggerFilteredPayload, CustomTriggersResponse, Error>()

/**
 * @description
 * Listen for the Action:
 * * [EVENT] ARCHIVE CUSTOM TRIGGER
 */
export const archiveCustomTriggerAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<ArchiveCustomTriggerPayload, ArchiveCustomTriggerResponse, Error>()

/**
 * @description
 * Listen for the Action:
 * * [EVENT] DUPLICATE CUSTOM TRIGGER
 */
export const duplicateCustomTriggerAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<DuplicateCustomTriggerPayload, DuplicateCustomTriggerResponse, Error>()
