import { ContentState, convertToRaw, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'

/**
 * Converts the given html string value into EditorState object
 *
 * @param {string} htmlValue
 * @return EditorState
 */
export const editorStateFromHtmlString = (htmlValue: string): EditorState => {
  const blocksFromHtml = htmlToDraft(htmlValue)

  const { contentBlocks, entityMap } = blocksFromHtml

  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap,
  )

  return EditorState.createWithContent(contentState)
}

/**
 * Converts the given Editor State object into the raw html string value.
 *
 * @param {EditorState | undefined} value
 * @return string
 */
export const htmlStrFromEditorState = (value: EditorState | undefined) => {
  return value ? draftToHtml(convertToRaw(value.getCurrentContent())) : ''
}

export const getPlainTextFromHtmlString = (value: string) =>
  editorStateFromHtmlString(value)
    .getCurrentContent()
    .getPlainText('\u0001')
