import ActionTypes from '../constants'
import { LevelDefinitionActions, LevelsState } from '../models'

const getLevelsDefinitionHandler = (
  state: LevelsState,
  action: LevelDefinitionActions,
): LevelsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_LEVEL_DEFINITION:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_LEVEL_DEFINITION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        levels: action.payload.message?.list || [],
        pagination: {
          total: action.payload.message?.pagination.total || 0,
          limit: action.payload.message?.pagination.size || 25,
          page: action.payload.message?.pagination.page || 1,
          pages:
            Math.round(
              action.payload.message?.pagination.total /
                action.payload.message?.pagination.size,
            ) || 1,
        },
      }

    case ActionTypes.GET_ALL_LEVEL_DEFINITION_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }

    default:
      return state
  }
}

export default getLevelsDefinitionHandler
