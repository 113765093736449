import { BonusReportActions, BonusReportState } from '../models'
import ActionTypes from '../constants'

const offerReportHandler = (
  state: BonusReportState,
  action: BonusReportActions,
): BonusReportState => {
  switch (action.type) {
    case ActionTypes.GET_BONUS_OFFERS_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_BONUS_OFFERS_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        bonusOffers: {
          ...state.bonusOffers,
          report: [...action.payload.payload.data],
          pagination: {
            ...state.bonusOffers.pagination,
            page: 1,
            total: action.payload.payload.data.length,
            pages: Math.ceil(
              action.payload.payload.data.length /
                state.bonusOffers.pagination.limit,
            ),
          },
        },
      }

    case ActionTypes.SET_OFFERS_REPORT_PAGINATION: {
      return {
        ...state,
        bonusOffers: {
          ...state.bonusOffers,
          pagination: {
            ...state.bonusOffers.pagination,
            ...action.payload,
          },
        },
      }
    }

    case ActionTypes.GET_BONUS_PLAYER_COUNT:
      return {
        ...state,
        isLoadingDrawer: true,
      }

    case ActionTypes.GET_BONUS_PLAYER_COUNT_SUBSCRIBER:
      return {
        ...state,
        isLoadingDrawer: false,
        playerCount: action.payload.payload.data,
        offerDrawerPagination: {
          total: action.payload.payload.data.length,
          limit: 10,
          page: 1,
          pages: Math.ceil(
            action.payload.payload.data.length /
              state.offerDrawerPagination.limit,
          ),
        },
      }

    case ActionTypes.SET_OFFER_DRAWER_PAGINATION: {
      return {
        ...state,
        offerDrawerPagination: {
          ...state.offerDrawerPagination,
          ...action.payload,
        },
      }
    }

    case ActionTypes.GET_STATE_REPORT:
      return {
        ...state,
        isLoadingDrawer: true,
      }

    case ActionTypes.GET_STATE_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoadingDrawer: false,
        stateReport: action.payload.payload.data,
        offerDrawerPagination: {
          total: action.payload.payload.data.length,
          limit: 10,
          page: 1,
          pages: Math.ceil(
            action.payload.payload.data.length /
              state.offerDrawerPagination.limit,
          ),
        },
      }

    case ActionTypes.GET_DEPOSIT_TRIGGER_REPORT:
      return {
        ...state,
        isLoadingDrawer: true,
      }

    case ActionTypes.GET_DEPOSIT_TRIGGER_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoadingDrawer: false,
        depositReport: action.payload.payload.data,
        offerDrawerPagination: {
          total: action.payload.payload.data.length,
          limit: 10,
          page: 1,
          pages: Math.ceil(
            action.payload.payload.data.length /
              state.offerDrawerPagination.limit,
          ),
        },
      }

    case ActionTypes.SET_LOADING_DRAWER:
      return {
        ...state,
        isLoadingDrawer: action.payload,
      }

    case ActionTypes.RESET_DRAWER:
      return {
        ...state,
        playerCount: [],
        offerDrawerPagination: {
          total: 0,
          limit: 10,
          page: 1,
          pages: 0,
        },
        stateReport: [],
        depositReport: [],
      }

    default:
      return state
  }
}

export default offerReportHandler
