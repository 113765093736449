import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const deleteUserHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.DELETE_REQUEST:
      return { ...state }

    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        wasDeleted: true,
      }

    case ActionTypes.DELETE_FAILURE:
      return { ...state, error: action.payload }

    default:
      return state
  }
}

export default deleteUserHandler
