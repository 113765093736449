import ActionTypes from '../constants'
import { LogActions, LogState } from '../models'

const noteLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_NOTE_LOGS:
      return {
        ...state,
        isLoadingTags: true,
      }

    case ActionTypes.GET_NOTE_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingTags: false,
        notes: action.payload?.docs || [],
      }

    default:
      return state
  }
}

export default noteLogHandler
