import {
  PlayerCardState,
  PlayerCardActions,
  PlayerSocketActions,
} from '../models'
import ActionTypes from '../constants'

const setAccountStatusHandler = (
  state: PlayerCardState,
  action: PlayerCardActions | PlayerSocketActions,
): PlayerCardState => {
  switch (action.type) {
    case ActionTypes.CREATE_ACCOUNT_STATUS_SUBSCRIBER:
      return {
        ...state,
        accountStatusLogs: [...state.accountStatusLogs, action.payload],
      }

    default:
      return state
  }
}

export default setAccountStatusHandler
