import ActionTypes from '../constants'
import { SegmentActions, SegmentState } from '../models'

const segmentUpdateHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return { ...state, isLoadingPartial: true }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingPartial: false,
        segments: state.segments.map(segment => {
          // eslint-disable-next-line no-underscore-dangle
          if (action.payload._id === segment._id) {
            return Object.assign(segment, action.payload)
          }
          return segment
        }),
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default segmentUpdateHandler
