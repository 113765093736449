/**
 *
 * KYC status actions
 *
 */

import { createCustomAction, createAction } from 'typesafe-actions'
import WS_NAMESPACES from 'state/websocket.namespaces'
import ActionTypes from '../constants'

import WS_ROUTES from '../../websocket.routes'
import {
  UpdatePlayerKycStatusPayload,
  UpdatePlayerAmlStatusPayload,
  GetAmlDataPayload,
  GetAmlDataResponse,
  SetAmlDataPayload,
  SetAmlDataResponse,
  SetAmlStatusPayload,
  SetAmlStatusResponse,
  UpdatePlayerKycStatusResponse,
  GetPlayerKycStatusPayload,
  GetPlayerKycStatusResponse,
  GetAmlStatusResponse,
} from '../models'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE KYC STATUS
 *
 */
export const updateKycStatus = createCustomAction(
  ActionTypes.UPDATE_KYC_STATUS,
  type => (
    payload: UpdatePlayerKycStatusPayload,
    event: typeof WS_ROUTES.KYC.UPDATE_KYC_STATUS,
    handler?: (data: UpdatePlayerKycStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
  }),
)

export const updateKycStatusSubscribe = createAction(
  ActionTypes.UPDATE_KYC_STATUS_SUBSCRIBER,
  action => {
    return (response: UpdatePlayerKycStatusResponse) => {
      return action(response)
    }
  },
)

export const updateKycStatusException = createAction(
  ActionTypes.UPDATE_KYC_STATUS_EXCEPTION,
  action => {
    return (response: UpdatePlayerKycStatusResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET KYC STATUS
 *
 */
export const getKycStatus = createCustomAction(
  ActionTypes.GET_KYC_STATUS,
  type => (
    payload: GetPlayerKycStatusPayload,
    event: typeof WS_ROUTES.KYC.GET_KYC_STATUS,
    handler?: (data: GetPlayerKycStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
  }),
)

export const getKycStatusSubscribe = createAction(
  ActionTypes.GET_KYC_STATUS_SUBSCRIBER,
  action => {
    return (response: GetPlayerKycStatusResponse) => {
      return action(response)
    }
  },
)

export const getKycStatusException = createAction(
  ActionTypes.GET_KYC_STATUS_EXCEPTION,
  action => {
    return (response: GetPlayerKycStatusResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE AML STATUS
 *
 */
export const updateAmlStatus = createCustomAction(
  ActionTypes.UPDATE_AML_STATUS,
  type => (
    payload: UpdatePlayerAmlStatusPayload,
    event: typeof WS_ROUTES.KYC.UPDATE_AML_STATUS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    handler,
  }),
)

export const updateAmlStatusSubscribe = createAction(
  ActionTypes.UPDATE_AML_STATUS_SUBSCRIBER,
  action => {
    return (response: any) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET AML DATA
 *
 */
export const getAmlData = createCustomAction(
  ActionTypes.AML_GET_ANSWERS,
  type => (
    payload: GetAmlDataPayload,
    event: typeof WS_ROUTES.KYC.AML_GET_ANSWERS,
    handler?: (data: GetAmlDataResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getAmlDataSubscribe = createAction(
  ActionTypes.AML_GET_ANSWERS_SUBSCRIBER,
  action => {
    return (response: GetAmlDataResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] SET AML DATA
 *
 */
export const setAmlData = createCustomAction(
  ActionTypes.AML_SET_ANSWERS,
  type => (
    payload: SetAmlDataPayload,
    event: typeof WS_ROUTES.KYC.AML_SET_ANSWERS,
    handler?: (data: SetAmlDataResponse) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      payload,
      meta: WS_NAMESPACES.ADMIN_LEGACY,
      handler,
    }
  },
)

export const setAmlDataSubscribe = createAction(
  ActionTypes.AML_SET_ANSWERS_SUBSCRIBER,
  action => {
    return (response: SetAmlDataResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] SET AML STATUS
 *
 */
export const setAmlStatus = createCustomAction(
  ActionTypes.AML_SET_STATUS,
  type => (
    payload: SetAmlStatusPayload,
    event: typeof WS_ROUTES.KYC.AML_SET_STATUS,
    handler?: (data: SetAmlStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const setAmlStatusSubscribe = createAction(
  ActionTypes.AML_SET_STATUS_SUBSCRIBER,
  action => {
    return (response: SetAmlStatusResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET AML DATA STATUS
 *
 */
export const getAmlStatusData = createCustomAction(
  ActionTypes.AML_GET_STATUS,
  type => (
    payload: GetAmlDataPayload,
    event: typeof WS_ROUTES.KYC.AML_GET_STATUS,
    handler?: (data: GetAmlStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getAmlStatusDataSubscribe = createAction(
  ActionTypes.AML_GET_STATUS_SUBSCRIBER,
  action => {
    return (response: GetAmlStatusResponse) => {
      return action(response)
    }
  },
)
