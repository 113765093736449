const LIMIT = {
  GET_ALL_LIMITS: 'risk/limits-player-admin-get' as 'risk/limits-player-admin-get',
  CANCEL_PENDING_LIMIT: 'risk/limits-player-cancel-pending' as 'risk/limits-player-cancel-pending',
  CANCEL_PENDING_ADMIN_LIMIT: 'risk/limits-player-admin-cancel-pending' as 'risk/limits-player-admin-cancel-pending',
  GET_PENDING_LIMITS: 'risk/limits-player-pending-admin-get' as 'risk/limits-player-pending-admin-get',
  SET_NEW_LIMIT: 'risk/limits-player-admin-set' as 'risk/limits-player-admin-set',
  GET_MAX_LIMITS_DEPOSIT: 'risk/limits-max-deposit' as 'risk/limits-max-deposit',
  SET_NEW_LIMIT_PLAYER: 'risk/limits-player-set' as 'risk/limits-player-set',
  SET_NEW_LIMIT_PLAYER_ADMINISTRATOR: 'risk/limits-player-set-administrator' as 'risk/limits-player-set-administrator',
  GET_LIMITS_SETTINGS: 'risk/limits-settings-all' as 'risk/limits-settings-all',
  REMOVE_LIMIT: 'risk/limits-player-remove' as 'risk/limits-player-remove',
}

export default LIMIT
