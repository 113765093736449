import InvitationStatus from 'state/enums/invitation-status.enum'
import ActionTypes from '../constants'
import { ReferFriendActions, ReferFriendState } from '../models'

const getAllInvitationsHandler = (
  state: ReferFriendState,
  action: ReferFriendActions,
): ReferFriendState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_INVITATIONS:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ALL_INVITATIONS_SUBSCRIBER: {
      const pagination =
        action.payload?.message?.details?.pagination || state.pagination
      return {
        ...state,
        isLoading: false,
        allInvitations: action.payload.resolved
          ? action.payload.message.details.list
          : [],
        pendingInvitations:
          action.payload.resolved &&
          action.payload.message.details.list &&
          action.payload.message.details.list.length > 0
            ? action.payload.message.details.list.filter(
                invitation =>
                  invitation.status === InvitationStatus.PENDING ||
                  invitation.status === InvitationStatus.EXPIRED,
              )
            : [],
        approvedInvitations:
          action.payload.resolved &&
          action.payload.message.details.list &&
          action.payload.message.details.list.length > 0
            ? action.payload.message.details.list.filter(
                invitation => invitation.status === InvitationStatus.ACCEPTED,
              )
            : [],
        rejectedInvitations:
          action.payload.resolved &&
          action.payload.message.details.list &&
          action.payload.message.details.list.length > 0
            ? action.payload.message.details.list.filter(
                invitation => invitation.status === InvitationStatus.REJECTED,
              )
            : [],
        pagination: {
          total: pagination.total,
          limit: state.pagination.limit,
          page: pagination.page,
          pages: Math.ceil(pagination.total / state.pagination.limit),
        },
      }
    }

    case ActionTypes.SET_REFERRED_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default getAllInvitationsHandler
