/**
 * Bonus Engine Trigger Types
 */
enum BonusEngineTriggerType {
  ACCOUNT_VERIFICATION = 'ACCOUNT_VERIFICATION',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  BONUS_CODE = 'BONUS_CODE',
  DEPOSIT = 'DEPOSIT',
  MANUAL_BONUS = 'MANUAL_BONUS',
  SEGMENTATION_BONUS = 'SEGMENTATION_BONUS',
  DEPENDENT = 'DEPENDENT',
  GAMIFICATION = 'GAMIFICATION',
  BY_LOGIN = 'LOGIN',
}

export default BonusEngineTriggerType
