import { createReducer } from 'typesafe-actions'
import { PlayerReportState, PlayerReportActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import generalHandler from './general.handler'

/**
 * Creates a PlayerReport Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const playerReportReducer = createReducer<
  PlayerReportState,
  PlayerReportActions
>(initialState).handleAction(
  [
    ActionTypes.SET_FILTERS,
    ActionTypes.GET_PLAYER_REPORT,
    ActionTypes.GET_PLAYER_REPORT_SUBSCRIBER,
    ActionTypes.RESET_ALL,
  ],
  generalHandler,
)

export default playerReportReducer
