import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const createPaymentHandler = (
  state: WalletModels.State,
  action: WalletModels.SocketActions | WalletModels.Actions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.CREATE_PAYMENT_FILE:
      return {
        ...state,
      }

    case ActionTypes.CREATE_PAYMENT_FILE_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload.resolved,
      }

    default:
      return state
  }
}

export default createPaymentHandler
