import React, { useEffect, Suspense } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import isPast from 'date-fns/isPast'

import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import routes from 'routes/index.routes'
import { logoutAction } from 'state/auth/actions'

import { ApplicationState } from 'state/root.models'
import SidebarComponent from 'views/layouts/sidebar/index.component'
import useWebSocketSubscription from 'hooks/sockets/use-websocket-subscription.hook'

import WS_NAMESPACES from 'state/websocket.namespaces'
import {
  updateUserSubscriber,
  createUserSubscriber,
  deleteUserSubscriber,
} from 'state/user/actions/sockets.actions'
import RoleType from 'state/enums/role.enum'

import TopBarComponent from 'views/layouts/top-bar/top-bar.component'
import FullPageLoader from '../full-page-loader/index.component'
import * as Pages from './pages.component'

const AuthorizedApp = () => {
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const { userSession, token, legacyToken } = useSelector(
    ({ auth }: ApplicationState) => auth,
    shallowEqual,
  )

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.WALLET,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.TAGS,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.CATEGORY,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.TAGS_HISTORIC,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.PLAYER_SEARCH,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.NOTES,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.USER_LOGS,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: false,
    namespace: WS_NAMESPACES.ADMIN_LEGACY,
    host: process.env.REACT_APP_ADMIN_LEGACY_API,
    token: legacyToken,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.USER,
    token,
    subscribers: [
      updateUserSubscriber,
      createUserSubscriber,
      deleteUserSubscriber,
    ],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.ADMIN_LOGS,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.RULES_ENGINE,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  useWebSocketSubscription({
    isIO: true,
    namespace: WS_NAMESPACES.VISITS,
    host: process.env.REACT_APP_PLAYER_SERVICE,
    port: process.env.REACT_APP_PLAYER_SERVICE_PORT,
    token,
    subscribers: [],
  })

  /**
   * Checks if the token expiration time is not longer valid
   *
   *  * JWT token expires in an 1hr
   */
  const hasTokenExpired = () => isPast(new Date(userSession?.exp * 1000))

  useEffect(() => {
    if (userSession?.exp) {
      if (hasTokenExpired() || !token) {
        dispatch(logoutAction())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pathname, dispatch, legacyToken])

  const hasSearchBar =
    userSession?.role.includes(RoleType.PLAYER_CARD) ||
    userSession?.role.includes(RoleType.SUPER_ADMIN)

  const roleWIDW =
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.WITHDRAWALS)  

  const roleRP = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.REPORTS)

  const roleAL = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.ALERTS)

  const roleRE = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.RULES)

  const roleTAG = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.TAGS)

  const roleGAM = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.GAMIFICATION)

  const roleBON = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.BONUS)

  const roleCRM = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.CRM)

  const roleUser = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.USER_MANAGEMENT)

  const roleGS = 
    userSession?.role.includes(RoleType.SUPER_ADMIN) ||
    userSession?.role.includes(RoleType.GAME_STORE)

  return (
    <Route
      render={({ location }) => (
        <>
          <TopBarComponent hasSearchBar={hasSearchBar} />
          <SidebarComponent />
          <Suspense fallback={<FullPageLoader open />}>
            <Switch location={location}>
              <Route path={routes.HOME} component={Pages.Home} exact />
              <Redirect from={routes.auth.LOGIN} to={routes.HOME} />
              <Route
                path={routes.database.INDEX}
                component={roleCRM ? Pages.MediaDatabasePage : Pages.Home}
              />
              <Route path={routes.account.INDEX} component={Pages.Account} />
              <Route
                path={routes.campaigns.INDEX}
                component={ roleCRM ? Pages.Campaigns : Pages.Home}
                exact
              />
              <Route
                path={routes.campaigns.CREATE}
                component={ roleCRM ? Pages.CampaignsCreate : Pages.Home}
                exact
              />
              <Route
                path={routes.campaigns.ARCHIVE}
                component={ roleCRM ? Pages.Campaigns : Pages.Home}
                exact
              />

              <Route
                path={routes.campaigns.EDITCAMPAIGN}
                component={ roleCRM ? Pages.CampaignsCreate : Pages.Home}
              />
              <Route
                path={routes.templates.INDEX}
                component={ roleCRM ? Pages.TemplateEmail : Pages.Home}
                exact
              />
              <Route
                path={routes.templates.ARCHIVE}
                component={ roleCRM ? Pages.TemplateEmail : Pages.Home}
                exact
              />
              <Route
                path={routes.templates.CREATE_EMAIL}
                component={ roleCRM ? Pages.TemplateCreateEmail : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.EDIT}/:templateId`}
                component={ roleCRM ? Pages.TemplateCreateEmail : Pages.Home}
              />
              <Route
                path={routes.templates.INDEX_SMS}
                component={ roleCRM ? Pages.TemplateSms : Pages.Home}
                exact
              />
              <Route
                path={routes.templates.ARCHIVE_SMS}
                component={ roleCRM ? Pages.TemplateSms : Pages.Home}
                exact
              />
              <Route
                path={routes.templates.CREATE_SMS}
                component={ roleCRM ? Pages.TemplateCreateSms : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.EDIT_SMS}/:templateId`}
                component={ roleCRM ? Pages.TemplateCreateSms : Pages.Home}
              />
              <Route
                path={routes.templates.INDEX_PUSH}
                component={ roleCRM ? Pages.TemplatePush : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.ARCHIVE_PUSH}`}
                component={ roleCRM ? Pages.TemplatePush : Pages.Home}
                exact
              />
              <Route
                path={routes.templates.CREATE_PUSH}
                component={ roleCRM ? Pages.TemplateCreatePush : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.EDIT_PUSH}/:templateId`}
                component={ roleCRM ? Pages.TemplateCreatePush : Pages.Home}
              />
              <Route
                path={`${routes.templates.INDEX_ONSITE}`}
                component={ roleCRM ? Pages.TemplateOnSite : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.ARCHIVE_ONSITE}`}
                component={ roleCRM ? Pages.TemplateOnSite : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.CREATE_ONSITE}`}
                component={ roleCRM ? Pages.TemplateCreateOnSite : Pages.Home}
                exact
              />
              <Route
                path={`${routes.templates.EDIT_ONSITE}/:templateId`}
                component={ roleCRM ? Pages.TemplateCreateOnSite : Pages.Home}
              />
              <Route
                path={routes.segments.INDEX}
                component={ roleCRM ? Pages.Segments : Pages.Home}
                exact
              />

              <Route
                path={routes.segments.CREATE}
                component={ roleCRM ? Pages.SegmentsCreate : Pages.Home}
                exact
              />
              <Route
                path={routes.segments.ARCHIVE}
                component={ roleCRM ? Pages.Segments : Pages.Home}
                exact
              />
              <Route
                path={`${routes.segments.EDIT}/:segmentId`}
                component={ roleCRM ? Pages.SegmentsCreate : Pages.Home}
              />

              <Route path={routes.users.INDEX} 
                component={ roleUser ? Pages.Users : Pages.Home} 
              />

              <Route path={routes.userLogs.INDEX} 
                component={hasSearchBar ? Pages.UserLogs : Pages.Home}
              />

              <Route
                path={routes.campaigns.ANALYTICS}
                component={ roleCRM ? Pages.Analytics : Pages.Home}
                exact
              />
              <Route
                path={routes.campaigns.ANALYTICS_HISTORY}
                component={ roleCRM ? Pages.Analytics : Pages.Home}
                exact
              />
              <Route
                path={`${routes.campaigns.ANALYTICS_DETAIL}/:id`}
                component={ roleCRM ? Pages.AnalyticsDetail : Pages.Home}
                exact
              />
              <Route
                path={`${routes.campaigns.ANALYTICS_ACTIVITY}/:category`}
                component={ roleCRM ? Pages.AnalyticsActivity : Pages.Home}
                exact
              />

              <Route
                path={routes.clients.INDEX}
                component={ roleUser ? Pages.ClientSettings : Pages.Home}
              />
              <Route
                path={`${routes.playerCard.INDEX}/:playerId`}
                component={ hasSearchBar ? Pages.PlayerCard : Pages.Home}
                exact
              />
              <Route
                path={`${routes.playerCard.INDEX}`}
                component={ hasSearchBar ? Pages.PlayerCard : Pages.Home}
                exact
              />
              <Route
                path={routes.playerCard.PLAYER_TAGS}
                component={ roleTAG ? Pages.Tags : Pages.Home}
                exact
              />
              <Route
                path={routes.playerCard.CRM_TAGS}
                component={ roleTAG ? Pages.Tags : Pages.Home}
                exact
              />
              <Route path={routes.issues.INDEX} 
                component={ roleAL ? Pages.IssuesPage : Pages.Home}
              />
              <Route
                path={routes.search.PLAYERS}
                component={ hasSearchBar ? Pages.SearchPlayer : Pages.Home}
                exact
              />
              <Route
                path={routes.search.TRANSACTIONS}
                component={ roleRP ? Pages.SearchTransaction : Pages.Home}
                exact
              />
              <Route
                path={routes.reports.TRANSACTIONS}
                component={ roleRP ? Pages.TransactionReport : Pages.Home}
                exact
              />
              <Route
                path={routes.withdrawals.INDEX}
                component={ roleWIDW ? Pages.WithdrawalsPage : Pages.Home}
              />
              <Route
                path={routes.playerCard.TELEDATA}
                component={ roleRP ? Pages.TeledataPage : Pages.Home}
              />
              <Route
                path={routes.playerCard.BLACKLIST}
                component={ roleRP ? Pages.BlacklistPage : Pages.Home}
                exact
              />
              <Route
                path={routes.playerCard.BLACKLIST_AUDIT}
                component={ roleRP ? Pages.BlacklistPage : Pages.Home}
                exact
              />
              <Route
                path={routes.roles.INDEX}
                component={ hasSearchBar ? Pages.RolesPage : Pages.Home} 
                exact
              />
              <Route
                path={routes.rules.CREATE}
                component={ roleRE ? Pages.RulesCreatePage : Pages.Home}
                exact
              />
              <Route
                path={routes.rules.INDEX}
                component={roleRE ? Pages.RulesPage : Pages.Home}
                exact
              />
              <Route
                path={routes.rules.ARCHIVE}
                component={roleRE ? Pages.RulesPage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.rules.EDIT}/:ruleId`}
                component={roleRE ? Pages.RulesCreatePage : Pages.Home}
              />
              <Route
                path={routes.rules.ANALYTICS_DETAIL}
                component={roleRE ? Pages.RulesAnalyticsDetailsPage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.rules.ANALYTICS_ACTIVITY}/:category`}
                component={roleRE ? Pages.RulesAnalyticsActivityPage : Pages.Home}
                exact
              />
              <Route
                path={routes.customTrigger.INDEX}
                component={ roleCRM ? Pages.CustomTriggerPage : Pages.Home}
                exact
              />
              <Route
                path={routes.customTrigger.ARCHIVE}
                component={ roleCRM ? Pages.CustomTriggerPage : Pages.Home}
                exact
              />
              <Route
                path={routes.customTrigger.CREATE}
                component={ roleCRM ? Pages.CustomTriggerCreatePage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.customTrigger.EDIT}/:customTriggerId`}
                component={ roleCRM ? Pages.CustomTriggerCreatePage : Pages.Home}
              />
              <Route
                path={routes.search.PAYCARDS}
                component={hasSearchBar ? Pages.SearchPaycard : Pages.Home}
                exact
              />
              <Route
                path={`${routes.bonusEngine.INDEX}/:tab`}
                component={roleBON ? Pages.BonusEnginePage : Pages.Home}
                exact
              />
              <Route
                path={routes.bonusEngine.CREATE_BONUS_OFFER}
                component={roleBON ? Pages.BonusEngineOffersPage : Pages.Home}
                exact
              />
              <Route
                path={routes.bonusEngine.CREATE_BONUS_CAMPAIGN}
                component={roleBON ? Pages.BonusEngineCampaignsPage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.bonusEngine.UPDATE_BONUS_OFFER}/:offerId`}
                component={roleBON ? Pages.BonusEngineOffersPage : Pages.Home}
              />
              <Route
                path={`${routes.bonusEngine.UPDATE_BONUS_CAMPAIGN}/:campaignId`}
                component={roleBON ? Pages.BonusEngineCampaignsPage : Pages.Home}
              />
              <Route
                path={routes.gamification.REWARD_SHOP_PRODUCTS}
                component={roleGAM ? Pages.RewardShopProductsPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.REWARD_SHOP_ITEMS}
                component={roleGAM ? Pages.RewardShopItemsPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.REWARD_SHOP}
                component={roleGAM ? Pages.RewardShopPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.CREATE_REWARD_SHOP_PRODUCTS}
                component={roleGAM ? Pages.CreateRewardShopProductPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.CREATE_REWARD_SHOP}
                component={roleGAM ? Pages.CreateRewardShopPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.CREATE_REWARD_SHOP_ITEMS}
                component={roleGAM ? Pages.CreateRewardShopItemPage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.gamification.UPDATE_REWARD_SHOP_PRODUCTS}/:productId`}
                component={roleGAM ? Pages.CreateRewardShopProductPage : Pages.Home}
              />
              <Route
                path={`${routes.gamification.UPDATE_REWARD_SHOP}/:shopId`}
                component={roleGAM ? Pages.CreateRewardShopPage : Pages.Home}
              />
              <Route
                path={`${routes.gamification.UPDATE_REWARD_SHOP_ITEMS}/:itemId`}
                component={roleGAM ? Pages.CreateRewardShopItemPage : Pages.Home}
              />
              <Route
                path={routes.gamification.LEVELS}
                component={roleGAM ? Pages.LevelsPage : Pages.Home}
                exact
              />
              <Route
                path={routes.gamification.CREATE_LEVELS}
                component={roleGAM ? Pages.LevelsCreatePage : Pages.Home}
                exact
              />
              <Route
                path={`${routes.gamification.UPDATE_LEVELS}/:levelId`}
                component={roleGAM ? Pages.LevelsCreatePage : Pages.Home}
              />
              <Route
                path={routes.referFriend.INDEX}
                component={roleRP ? Pages.ReferFriendPage : Pages.Home}
              />
              <Route
                path={routes.gameStore.GAMES}
                component={ roleGS ? Pages.GameStoreGames : Pages.Home}
                exact
              />
              <Route
                path={routes.gameStore.PROVIDERS}
                component={ roleGS ? Pages.GameStoreProviders : Pages.Home}
                exact
              />
              <Route
                path={`${routes.gameStore.EDIT}/:gameId`}
                component={ roleGS ? Pages.GameStoreEdit : Pages.Home}
                exact
              />
              <Route
                path={`${routes.ggrReport.INDEX}/:tab`}
                component={roleRP ? Pages.GgrReport : Pages.Home}
              />
              <Route
                path={`${routes.frequencyCap.INDEX}`}
                component={ roleCRM ? Pages.FrequencyCap : Pages.Home}
              />
              <Route
                path={routes.bonusReport.INDEX}
                component={roleRP ? Pages.BonusReportPage : Pages.Home}
                exact
              />
              <Route
                path={routes.playerReport.INDEX}
                component={roleRP ? Pages.PlayerReportPage : Pages.Home}
                exact
              />
              <Route
                path={routes.missions.INDEX}
                component={roleGAM ? Pages.MissionsPage : Pages.Home}
                exact
              />
              <Route
                path={routes.missions.ARCHIVE}
                component={roleGAM ? Pages.MissionsPage : Pages.Home}
                exact
              />
              {/* <Route
                path={routes.missions.CREATE}
                component={Pages.MissionsCreate}
                exact
              /> */}
              {/* <Route
                path={`${routes.missions.EDIT}/:missionId`}
                component={Pages.MissionsCreate}
              /> */}
              <Route component={Pages.ErrorPage} />
            </Switch>
          </Suspense>
        </>
      )}
    />
  )
}

export default AuthorizedApp
