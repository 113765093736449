import ActionTypes from '../constants'
import { IssueState, IssuesActions } from '../model'
import initialState from './initial-state'

const getOneIssueHandler = (
  state: IssueState,
  action: IssuesActions,
): IssueState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_ISSUE:
      return {
        ...state,
        isLoading: !!state.issueIdFilter,
      }

    case ActionTypes.GET_ONE_ISSUE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentIssue: action.payload?.message || initialState.currentIssue,
        issues:
          action.payload?.resolved && state.issueIdFilter
            ? [action.payload.message]
            : !action.payload?.resolved && state.issueIdFilter
            ? []
            : state.issues.length > 0
            ? state.issues
            : [],
        pagination: {
          ...state.pagination,
          total: 1,
          pages: 1,
        },
      }

    default:
      return state
  }
}

export default getOneIssueHandler
