import { getHours, getMinutes, parseISO, format } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'
import TimeZoneUTCType from 'state/enums/timezone-utc.enum'

import TimeZoneType from 'state/enums/timezone.enum'
import {
  dateFormat,
  dateTimeFormat,
  europeanDateFormat,
  europeanDateTimeFormat,
} from 'constants/date/index.constant'

export const getIsoUTCFromDateTime = (
  date: Date,
  time: string,
  timeZone: TimeZoneUTCType,
) => {
  const hours = Number(time.split(':')[0])
  const minutes = Number(time.split(':')[1])
  const dateTime = new Date(date.setHours(hours, minutes, 0, 0))
  const dateUTC = zonedTimeToUtc(dateTime, timeZone)
  return dateUTC.toISOString()
}

export const getDateTimeFromIsoUTC = (
  isoDate: string,
  timeZone: TimeZoneUTCType,
) => {
  const date = utcToZonedTime(new Date(isoDate), timeZone)
  return date
}

export const getDateTimeFormattedFromIsoUTC = (
  date: string,
  timeZoneText: TimeZoneType,
  dateStringFormat?: string,
) => {
  return format(
    getDateTimeFromIsoUTC(date, TimeZoneUTCType[timeZoneText]),
    dateStringFormat ?? dateTimeFormat,
  )
}

export const getTimeFromDate = (date: Date): string => {
  const hours = getHours(date)
  const minutes = getMinutes(date)

  const hoursFormat = hours < 10 ? `0${hours}` : hours
  const minutesFormat = minutes < 10 ? `0${minutes}` : minutes

  const time = `${hoursFormat}:${minutesFormat}:00`
  return time
}

export const getRoundedTime = (time: string): string => {
  const hours = Number(time.split(':')[0])
  let minutes = Number(time.split(':')[1])
  minutes = Math.ceil(minutes / 5) * 5
  const seconds = Number(time.split(':')[2]) || '00'

  const hoursFormat = hours < 10 ? `0${hours}` : hours
  const minutesFormat = minutes < 10 ? `0${minutes}` : minutes

  const timeRounded = `${hoursFormat}:${minutesFormat}:${seconds}`
  return timeRounded
}

export const setTimeForToday = (time: string): Date => {
  const hours = Number(time.split(':')[0])
  const minutes = Number(time.split(':')[1])
  const seconds = Number(time.split(':')[2])
  return new Date(new Date().setHours(hours, minutes, seconds))
}

export const formatDateFromISOString = (ISOString: string) => {
  const date = parseISO(ISOString)
  return format(date, dateFormat)
}

/**
 * @description Generates an eventId
 *
 * The eventId is needed in the server
 * for internal API's communication.
 *
 * * The eventId in generated in milliseconds
 *
 * @returns CRM_1585077559897
 */
export const createEventId = () => `CRM_${new Date().valueOf()}`

export const setFirstHour = (date: Date) => {
  return date.setHours(0, 0, 0, 0)
}

export const setLastHour = (date: Date) => {
  return date.setHours(23, 59, 59, 0)
}

/**
 * @description formats a date given a string
 * @param {string} date
 *
 * @example
 *  ```ts
 *  const date = 'Thu, 21 May 2020 21:57:53 GMT'
 *  const formatted = getDateFromUTCString(date)
 *
 *  * 21/05/2020 - 21:57
 * ```
 */
export const getDateFromUTCString = (date: string) => {
  const day = new Date(date).getUTCDate()
  const month = new Date(date).getUTCMonth()
  const year = new Date(date).getUTCFullYear()
  const hours = new Date(date).getUTCHours()
  const minutes = new Date(date).getUTCMinutes()
  const seconds = new Date(date).getUTCSeconds()

  return format(
    new Date(year, month, day, hours, minutes, seconds),
    europeanDateTimeFormat,
  )
}

export const getEuropeanDateFormatFromUTCString = (date: string) => {
  const day = new Date(date).getUTCDate()
  const month = new Date(date).getUTCMonth()
  const year = new Date(date).getUTCFullYear()

  return format(new Date(year, month, day), europeanDateFormat)
}

export const getConvertedTime = (
  time: string,
  timezone: string,
  saved?: boolean,
) => {
  const number = parseInt(timezone.slice(0, -2).substring(1), 10)
  const hours = Number(time.split(':')[0])
  const minutes = time.split(':')[1]
  const seconds = time.split(':')[2] || '00'

  let hoursZoned = saved ? hours - number : hours + number
  if (timezone.includes('+')) {
    hoursZoned = saved ? hours + number : hours - number
  }
  if (hoursZoned >= 24) {
    hoursZoned -= 24
  }
  if (hoursZoned < 0) {
    hoursZoned += 24
  }
  const hoursFormat = hoursZoned < 10 ? `0${hoursZoned}` : hoursZoned
  return `${hoursFormat}:${minutes}:${seconds}`
}

export const getDateFromISOString = (date: string) => {
  const dateValue = date.split('T')[0]
  const [year, month, day] = dateValue.split('-')
  return new Date(Number(year), Number(month) - 1, Number(day))
}

export const getISOStringFromStartDate = (date: Date) => {
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${year}-${month}-${day}T00:00:00.000Z`
}

export const getISOStringFromEndDate = (date: Date) => {
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${year}-${month}-${day}T23:59:59.000Z`
}
