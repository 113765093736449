import ActionTypes from '../constants'
import {
  PlayerCardKYCHistoryState,
  PlayerCardKYCActions,
  TeleDataHistory,
} from '../models'

const teledataHistoryHandler = (
  state: PlayerCardKYCHistoryState,
  action: PlayerCardKYCActions,
): PlayerCardKYCHistoryState => {
  switch (action.type) {
    case ActionTypes.GET_TELEDATA_HISTORY:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_TELEDATA_HISTORY_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        teledataHistory: action.payload
          ? Array.isArray(action.payload)
            ? action.payload
            : [action.payload]
          : state.teledataHistory,
      }

    case ActionTypes.CREATE_TELEDATA:
      return {
        ...state,
      }

    case ActionTypes.CREATE_TELEDATA_SUBSCRIBER:
      return {
        ...state,
        teledataHistory: [
          ...state.teledataHistory,
          // eslint-disable-next-line
          {
            playerId: action.payload?.playerId,
            colorId: action.payload?.colorId,
            comment: action.payload?.comment,
            inserted: action.payload?.inserted,
            color: action.payload?.color,
          } as TeleDataHistory,
        ],
      }

    case ActionTypes.CREATE_TELEDATA_ERROR:
      return {
        ...state,
      }

    case ActionTypes.RESET_TELEDATA_HISTORY:
      return {
        ...state,
        teledataHistory: [],
      }

    case ActionTypes.GET_PLAYER_TELEDATA_HISTORY:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_PLAYER_TELEDATA_HISTORY_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        playerTeledataHistory: action.payload.message || [],
      }

    default:
      return state
  }
}

export default teledataHistoryHandler
