const clients = {
  accountStatusCharacteristicsOptions: {
    play: 'Play',
    login: 'Login',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    temporary: 'Temporary',
    isEmailVerified: 'Email',
    isDocumentVerified: 'Document',
    isUtilityBillVerified: 'Utility Bill & Address',
    isSuperVerified: 'Fully Identified',
  },
  accountStatusCharacteristics: 'Account Status Characteristics',
  allowedAction: 'Allowed Action',
  apiKey: 'Provider API Key',
  clientSettings: 'client settings',
  conceptOfVisit: 'The concept of visit is defined as:',
  domain: 'Domain',
  fromEmail: 'From Email',
  loginSession: 'Login Session',
  notificationToCRM: 'Notification to CRM ',
  beforeBonusOfferExpires: 'before bonus offer expires',
  provider: 'Provider',
  priorToExpiration: '{{when}} prior to expiration.',
  replyTo: 'Reply To',
  riskEvaluation: 'Risk Evaluation',
  type: 'Type',
  verifiedItems: 'Verified Items',
  visitsConfiguration: 'Visits Configuration',
  visitsConfigurationOptions: {
    totalBetsAmount: 'Total Bets Amount',
    gamesession: 'Game Session Duration',
  },
  noSpacesInBetween: `Blank spaces aren't allowed.`,
}

export default clients
