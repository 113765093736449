enum NoteTagType {
  IMPORTANT = 1,
  BONUS = 2,
  TRANSACTIONAL = 3,
  RESP_GAMING = 4,
  CASINO = 5,
  KYC = 6,
  OTHER = 7,
}

export default NoteTagType
