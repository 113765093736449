import { createReducer } from 'typesafe-actions'
import teledataHistoryHandler from 'state/player-card-kyc/reducers/teledata-history.handler'
import ActionTypes from '../constants'

import {
  KYCSocketActions,
  PlayerCardKYCDocumentState,
  PlayerCardKYCHistoryState,
  PlayerCardKYCStatusState,
  PlayerCardKYCActions,
} from '../models'
import historyHandler from './history.handler'
import statusHandler from './status.handler'

import {
  initialStateDocument,
  initialStateStatus,
  initialStateHistory,
} from './initial-state'
import getDocumentImagesHandler from './get-document-images.handler'
import getJumioDataHandler from './get-jumio-data.handler'

import downloadDocumentImageHandler from './download-document-image.handler'
import createDocumentHandler from './create-document.handler'
import getDocumentsHandler from './get-documents.handler'

import deleteDocumentHandler from './delete-document.handler'
import updateDocumentsHandler from './update-document.handler'
import resetStateHandler from './reset-state.handler'

import documentGeneralHandler from './general.handler'
import ubiIDDocumentHandler from './ubiid-document.handler'
import playerBankAccountHandler from './player-bank-account.handler'

/**
 * ==========================
 * K Y C  D O C U M E N T S
 * ==========================
 */
export const playerCardKYCDocumentReducer = createReducer<
  PlayerCardKYCDocumentState,
  KYCSocketActions | PlayerCardKYCActions
>(initialStateDocument)
  .handleAction(
    [
      ActionTypes.GET_DOCUMENTS,
      ActionTypes.GET_DOCUMENTS_SUBSCRIBER,
      ActionTypes.GET_DOCUMENTS_EXCEPTION,
      ActionTypes.GET_ALERT_KYC_DOCS,
      ActionTypes.GET_ALERT_KYC_DOCS_SUBSCRIBER,
    ],
    getDocumentsHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_DOCUMENT_IMAGES_REQUEST,
      ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS,
      ActionTypes.GET_DOCUMENT_IMAGES_FAILURE,
      ActionTypes.SET_CURRENT_IMAGE_URL,
      ActionTypes.SET_CURRENT_SECOND_IMAGE_URL,
      ActionTypes.SET_DOCUMENT_FORMAT,
      ActionTypes.SET_JUMIO_STATUS,
      ActionTypes.SET_JUMIO_INFO,
      ActionTypes.TOGGLE_LOADING,
    ],
    getDocumentImagesHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_JUMIO_DATA_REQUEST,
      ActionTypes.GET_JUMIO_DATA_SUCCESS,
      ActionTypes.GET_JUMIO_DATA_FAILURE,
    ],
    getJumioDataHandler,
  )
  .handleAction(
    [
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST,
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS,
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE,
      ActionTypes.SET_CURRENT_DOWNLOADED_IMAGE,
      ActionTypes.SET_CURRENT_DOWNLOADED_SECOND_IMAGE,
    ],
    downloadDocumentImageHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_DOCUMENT,
      ActionTypes.CREATE_DOCUMENT_SUBSCRIBER,
      ActionTypes.CREATE_DOCUMENT_EXCEPTION,
    ],
    createDocumentHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_DOCUMENT,
      ActionTypes.DELETE_DOCUMENT_SUBSCRIBER,
      ActionTypes.DELETE_DOCUMENT_EXCEPTION,
    ],
    deleteDocumentHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_DOCUMENT,
      ActionTypes.UPDATE_DOCUMENT_SUBSCRIBER,
      ActionTypes.UPDATE_DOCUMENT_EXCEPTION,
    ],
    updateDocumentsHandler,
  )
  .handleAction(ActionTypes.RESET_DOC_STATE, resetStateHandler)
  .handleAction(ActionTypes.SET_DOCUMENT_FILTERS, documentGeneralHandler)
  .handleAction(
    [
      ActionTypes.GET_PLAYER_CASES,
      ActionTypes.GET_PLAYER_CASES_SUBSCRIBER,
      ActionTypes.GET_FAILED_INTEGRITY_CHECK,
      ActionTypes.GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER,
      ActionTypes.GET_CASE_FILES,
      ActionTypes.GET_CASE_FILES_SUBSCRIBER,
      ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE,
      ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER,
      ActionTypes.FAILED_INTEGRITY_CHECK_REJECT,
      ActionTypes.FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER,
      ActionTypes.SET_UBIID_INFO,
    ],
    ubiIDDocumentHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_PLAYER_IBANS,
      ActionTypes.GET_PLAYER_IBANS_SUBSCRIBER,
      ActionTypes.GET_PLAYER_IBAN_DOCUMENT,
      ActionTypes.GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER,
      ActionTypes.SET_IBAN_REJECTED,
      ActionTypes.SET_IBAN_REJECTED_SUBSCRIBER,
      ActionTypes.SET_IBAN_VERIFIED,
      ActionTypes.SET_IBAN_VERIFIED_SUBSCRIBER,
      ActionTypes.SET_PLAYER_BANK_ACCOUNT_INFO,
    ],
    playerBankAccountHandler,
  )

/**
 * ==========================
 * K Y C  H I S T O R Y
 * ==========================
 */
export const playerCardKYCHistoryReducer = createReducer<
  PlayerCardKYCHistoryState,
  PlayerCardKYCActions
>(initialStateHistory)
  .handleAction(
    [
      ActionTypes.CRIF_CHECK,
      ActionTypes.MANUAL_CHECK_BLACKLIST,
      ActionTypes.CRIF_CHECK_SUBSCRIBER,
      ActionTypes.MANUAL_CHECK_BLACKLIST_SUBSCRIBER,
      ActionTypes.UPDATE_PEP_STATUS,
      ActionTypes.UPDATE_PEP_STATUS_SUBSCRIBER,
      ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES,
      ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES_SUBSCRIBER,
    ],
    historyHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_TELEDATA_HISTORY,
      ActionTypes.GET_TELEDATA_HISTORY_SUBSCRIBER,
      ActionTypes.CREATE_TELEDATA,
      ActionTypes.CREATE_TELEDATA_SUBSCRIBER,
      ActionTypes.CREATE_TELEDATA_ERROR,
      ActionTypes.RESET_TELEDATA_HISTORY,
      ActionTypes.GET_PLAYER_TELEDATA_HISTORY,
      ActionTypes.GET_PLAYER_TELEDATA_HISTORY_SUBSCRIBER,
    ],
    teledataHistoryHandler,
  )

/**
 * ==========================
 * K Y C  S T A T U S
 * ==========================
 */
export const playerCardKYCStatusReducer = createReducer<
  PlayerCardKYCStatusState,
  KYCSocketActions | PlayerCardKYCActions
>(initialStateStatus).handleAction(
  [
    ActionTypes.UPDATE_KYC_STATUS_SUBSCRIBER,
    ActionTypes.UPDATE_KYC_STATUS,
    ActionTypes.UPDATE_KYC_STATUS_EXCEPTION,
    ActionTypes.UPDATE_AML_STATUS_SUBSCRIBER,
    ActionTypes.AML_GET_ANSWERS,
    ActionTypes.AML_GET_ANSWERS_SUBSCRIBER,
    ActionTypes.AML_SET_ANSWERS,
    ActionTypes.AML_SET_ANSWERS_SUBSCRIBER,
    ActionTypes.AML_SET_STATUS,
    ActionTypes.AML_SET_STATUS_SUBSCRIBER,
    ActionTypes.GET_KYC_STATUS,
    ActionTypes.GET_KYC_STATUS_SUBSCRIBER,
    ActionTypes.GET_KYC_STATUS_EXCEPTION,
    ActionTypes.AML_GET_STATUS,
    ActionTypes.AML_GET_STATUS_SUBSCRIBER,
  ],
  statusHandler,
)
