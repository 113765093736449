import {
  select,
  call,
  put,
  cancelled,
  take,
  cancel,
  takeLatest,
} from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { playersCountSegmentAction } from '../actions/rest.actions'
import { PlayersCountSegmentResponse } from '../models'

import ActionType from '../constants'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single segment
 *
 *
 *
 * @requires QueryBuilder
 * @requires ApiService
 *
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* playersCountSegmentWorker(
  action: ReturnType<typeof playersCountSegmentAction.request>,
) {
  const abortController = new AbortController()
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'segmentations/get/playersBySegment',
      queries: jsonToQueryString(action.payload),
      signal: abortController.signal,
    }

    const { auth } = yield select()
    const response: PlayersCountSegmentResponse = yield call(
      apiService.sendData,
      auth.token,
    )
    yield put(playersCountSegmentAction.success(response))
  } catch (error) {
    yield put(playersCountSegmentAction.failure(error))
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
}

function* playersCountFlow() {
  while (true) {
    const { PLAYERS_COUNT_CANCEL } = ActionType

    const task = yield takeLatest(
      playersCountSegmentAction.request,
      playersCountSegmentWorker,
    )

    const action = yield take([
      PLAYERS_COUNT_CANCEL,
      playersCountSegmentAction.cancel,
    ])

    if (action.type === PLAYERS_COUNT_CANCEL) {
      yield cancel(task)
    }
  }
}

export default playersCountFlow
