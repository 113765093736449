import { CompanyState } from '../models'
import EmailProviderType from '../../enums/email-provider'
import LanguageCode from '../../enums/language-code.enum'

const initialState: CompanyState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  company: {
    isActive: false,
    _id: '',
    name: '',
    serviceProvider: EmailProviderType.Client,
    apiKeyProvider: '',
    usernameProvider: '',
    defaultLanguage: LanguageCode.EN,
    domain: '',
    fromEmail: [''],
    replyToEmail: [''],
    image: '',
    createdAt: '',
    updatedAt: '',
  },
  settings: [],
  accountStatusInfo: [],
  paymentsInfo: {
    abbreviation: '',
    address: '',
    beneficiary: '',
    bic: '',
    city: '',
    country: '',
    countryAbbreviation: '',
    iban: '',
    postalCode: '',
    streetNumber: '',
  },
  bonusExpiry: {
    notificationTimeValue: 0,
    notificationTimeUnit: 'days',
  },
  visitsConfig: {
    config: {
      filterCriteria: '',
      filterTypeData: '',
      typeValue: '',
      filterValue: '',
    },
    frequencyCap: 0,
  },
  riskEvaluation: {
    filterType: 'and',
    rules: [],
  },
}

// if is multiple sender change to serviceProvider: EmailProviderType.Client

export default initialState
