import { select, call, put } from 'redux-saga/effects'
import { getLoggedInAction } from '../actions/rest.actions'

function* callAPI() {
  const { auth } = yield select()
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.token}`,
    },
    method: 'GET',
  }
  const serverUrl = process.env && process.env.REACT_APP_SERVER_URL

  const json = yield call(
    fetch,
    `${serverUrl}users/getUsersByFilter?searchParam=${auth.userSession._id}`,
    options,
  )

  const data = yield json.json()
  return data
}

/**
 *
 * This saga gets the logged in user
 *s
 * @requires ApiService
 * @param {ReturnType<typeof getLoggedInAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getLoggedInWorker(
  action: ReturnType<typeof getLoggedInAction.request>,
) {
  try {
    const response = yield call(callAPI)
    if (response.docs?.length > 0) {
      yield put(getLoggedInAction.success(response.docs[0]))
    }
  } catch (error) {
    yield put(getLoggedInAction.failure(error))
  }
}

export default getLoggedInWorker
