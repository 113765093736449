const templatesPush = {
  createAPush: 'Create a Push Notification',
  createAPushText: 'Start by filling in the information in step 1.',
  createPushTemplate: 'Create Push Notification Template',
  getShortURLButton: 'Get Short URL',
  goToPushTemplates: 'Go To Push Notification Templates',
  grantPermissionInstruction:
    'You must first grant permission to receive push notifications.',
  messageLanguageTextPush:
    'Select the language and add the information you want to include in your Push Notification. You must enter the text in each chosen language.',
  noLanguagesSelected: 'No Languages Selected',
  sendTest: 'Send Test',
  sendTestPushText:
    'Please select one language and click on the “Send Test” button to receive a test push notification.',
  pushSettings: 'Push Notification Settings',
  pushSummary: 'Push Notification Summary',
  pushTemplate: 'Push Notification Template',
  templatePushDetailsText:
    'Add the name, tags and description with which you want to save your Push Notification template. All fields are required.',
  userConsentIs: 'User consent is:',
}

export default templatesPush
