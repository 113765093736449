import { GameStoreActions, GameStoreState } from '../models'
import ActionTypes from '../constants'

const getProvidersHandler = (
  state: GameStoreState,
  action: GameStoreActions,
): GameStoreState => {
  switch (action.type) {
    case ActionTypes.GET_GAME_PROVIDERS:
      return {
        ...state,
      }

    case ActionTypes.GET_GAME_PROVIDERS_SUBSCRIBER:
      return {
        ...state,
        providers: action.payload.response?.providerList || [],
        pagination: action?.payload?.pathname.includes('providers')
          ? {
              total: action?.payload?.response?.providerList.length,
              limit: 25,
              page: 1,
              pages: Math.ceil(
                action?.payload?.response?.providerList.length / 25,
              ),
            }
          : state.pagination,
      }

    default:
      return state
  }
}

export default getProvidersHandler
