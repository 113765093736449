import ActionTypes from '../constants'
import { FrequencyCapState, FrequencyCapActions } from '../models'

const frequencyCapUpdateHandler = (
  state: FrequencyCapState,
  action: FrequencyCapActions,
): FrequencyCapState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frequenciesCap: state.frequenciesCap.map(frequencyCap => {
          if (action.payload._id === frequencyCap._id) {
            return Object.assign(frequencyCap, action.payload)
          }
          return frequencyCap
        }),
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      }

    default:
      return state
  }
}

export default frequencyCapUpdateHandler
