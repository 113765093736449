import TriggerType from 'state/enums/trigger.enum'
import { CustomTriggersState } from '../models'

const initialState: CustomTriggersState = {
  isLoading: false,
  hasError: false,
  wasDeleted: false,
  error: '',
  customTriggers: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
  },
  currentCustomTrigger: {
    createdAt: '',
    createdBy: {
      userId: '',
      username: '',
    },
    description: '',
    status: 0,
    name: '',
    trigger: TriggerType.RESET_PASS,
    filterType: '',
    rules: [],
    updatedAt: '',
    id: '',
  },
}

export default initialState
