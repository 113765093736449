import { colors } from './colors.theme'

const { white } = colors

export const tableSortLabelTheme = {
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover,&:focus': {
      color: white,
    },
  },
  icon: {
    fontSize: 24,
  },
}
