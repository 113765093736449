import { GameStoreActions, GameStoreState } from '../models'
import ActionTypes from '../constants'

const filtersHandler = (
  state: GameStoreState,
  action: GameStoreActions,
): GameStoreState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        gameFilters: action.payload,
      }

    case ActionTypes.SET_GAME_SELECTION:
      return {
        ...state,
        gameSelection: action.payload,
      }

    case ActionTypes.SET_CURRENT_GAME:
      return {
        ...state,
        currentGame: action.payload,
      }

    case ActionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          total: action?.payload?.total,
          limit: action?.payload?.limit,
          page: action?.payload?.page,
          pages: action?.payload?.pages,
        },
      }

    case ActionTypes.GET_GAME_CATEGORIES:
      return {
        ...state,
      }

    case ActionTypes.GET_GAME_CATEGORIES_SUBSCRIBER:
      return {
        ...state,
        categories: action?.payload?.gameCategories || [],
      }

    case ActionTypes.GET_GAME_DEVICES:
      return {
        ...state,
      }

    case ActionTypes.GET_GAME_DEVICES_SUBSCRIBER:
      return {
        ...state,
        devices: action?.payload?.devices || [],
      }

    default:
      return state
  }
}

export default filtersHandler
