import Languages from 'state/enums/languages.enum'
import MissionBonusOfferType from 'state/enums/mission-bonus-offer-type.enum'
import MissionRewardType from 'state/enums/mission-reward-type.enum'

import MissionTimeType from 'state/enums/mission-time-type.enum'
import { MissionsState } from '../models'

const missionsInitialState: MissionsState = {
  isLoadingPartial: false,
  pagination: { total: 0, limit: 10, page: 1, pages: 0 },
  filters: {},
  currentMission: {
    id: '',
    name: '',
    description: '',
    enabled: true,
    version: 0,
    tags: [],
    objectives: [],
    validityTime: { timeType: MissionTimeType.ANY_TIME },
    rewards: [],
  },
  missionsData: [
    {
      id: '1',
      name: 'Mission 1',
      description: 'Mission 1',
      enabled: true,
      version: 1,
      tags: ['tag 1.1'],
      objectives: [],
      validityTime: { timeType: MissionTimeType.ANY_TIME },
      rewards: [
        {
          rewardType: MissionRewardType.BONUS_OFFER,
          categoryLow: {
            bonusOfferType: MissionBonusOfferType.DEPOSIT,
            bonusId: 1,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categoryMedium: {
            bonusOfferType: MissionBonusOfferType.DEPOSIT,
            bonusId: 1,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categoryHigh: {
            bonusOfferType: MissionBonusOfferType.DEPOSIT,
            bonusId: 1,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categorySuperHigh: {
            bonusOfferType: MissionBonusOfferType.DEPOSIT,
            bonusId: 1,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
        },
        {
          rewardType: MissionRewardType.VIRTUAL_CURRENCY,
          categoryLow: {
            points: 10,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categoryMedium: {
            points: 111,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categoryHigh: {
            points: 12,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
          categorySuperHigh: {
            points: 200,
            i18nDescriptions: [{ language: Languages.DE, content: 'DE' }],
          },
        },
        {
          rewardType: MissionRewardType.LEVEL_BOOSTER,
          categoryLow: {
            automaticActivation: true,
            boosterRate: 10,
            timeFrame: 'P7D',
          },
          categoryMedium: {
            automaticActivation: true,
            boosterRate: 11,
            timeFrame: 'P7D',
          },
          categoryHigh: {
            automaticActivation: true,
            boosterRate: 130,
            timeFrame: 'P7D',
          },
          categorySuperHigh: {
            automaticActivation: true,
            boosterRate: 1000,
            timeFrame: 'P7D',
          },
        },
        {
          rewardType: MissionRewardType.MISSION_BOOSTER,
          categoryLow: {
            automaticActivation: true,
            boosterRate: 110,
            timeFrame: 'P7D',
          },
          categoryMedium: {
            automaticActivation: true,
            boosterRate: 121,
            timeFrame: 'P7D',
          },
          categoryHigh: {
            automaticActivation: true,
            boosterRate: 1320,
            timeFrame: 'P7D',
          },
          categorySuperHigh: {
            automaticActivation: true,
            boosterRate: 100,
            timeFrame: 'P7D',
          },
        },
      ],
    },
    {
      id: '2',
      name: 'Mission 2',
      description: 'Mission 2',
      enabled: true,
      version: 2,
      tags: ['tag 2.1', 'tag 2.2', 'tag 2.3'],
      objectives: [],
      validityTime: { timeType: MissionTimeType.ANY_TIME },
      rewards: [],
    },
    {
      id: '3',
      name: 'Mission 3',
      description: 'Mission 3',
      enabled: false,
      version: 3,
      tags: ['tag 3.1', 'tag 3.2'],
      objectives: [],
      validityTime: { timeType: MissionTimeType.ANY_TIME },
      rewards: [
        {
          rewardType: MissionRewardType.LEVEL_BOOSTER,
          categoryLow: {
            automaticActivation: true,
            boosterRate: 10,
            timeFrame: 'P7D',
          },
          categoryMedium: {
            automaticActivation: true,
            boosterRate: 11,
            timeFrame: 'P7D',
          },
          categoryHigh: {
            automaticActivation: true,
            boosterRate: 130,
            timeFrame: 'P7D',
          },
          categorySuperHigh: {
            automaticActivation: true,
            boosterRate: 1000,
            timeFrame: 'P7D',
          },
        },
      ],
    },
  ],
}

export default missionsInitialState
