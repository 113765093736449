export const dateFormat = 'dd/MM/yyyy'

export const dateTimeFormat = 'dd/MM/yyyy - H:mm:ss'

export const dateTimeHourFormat = 'dd/MM/yyyy - HH:mm:ss'

export const dateTimeTableFormat = 'd MMM, yyy / H:mm:ss'

export const fullDateTimeFormat = 'yyyy-MM-dd HH:mm:ss'

export const dateTimeWrappedFormat = 'ddMMyyyy'

export const datePaymentFormat = 'dd.MM.yyyy'

export const invertedDatePaymentFormat = 'yyyy-MM-dd'

export const dateSpacelessFormat = 'ddMMyyyyHHmmss'

export const europeanDateTimeFormat = 'dd-MM-yyyy HH:mm:ss'

export const europeanDateFormat = 'dd-MM-yyyy'

export const pdfFileDateTimeFormat = 'dd-MM-yyyy - HHmmss'

export const hourFormat = 'HH:mm:ss'
