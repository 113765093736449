import { select, call, put } from 'redux-saga/effects'
import i18n from 'i18n'
import { Container } from 'typedi'

import ApiService from 'services/api/index.service'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import removeEmptyProp from 'utils/state/remove-empty-prop.util'

import {
  duplicateTemplatePushAction,
  getAllTemplatePushAction,
} from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga duplicates a push template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof duplicateTemplatePushAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* duplicateTemplatePushWorker(
  action: ReturnType<typeof duplicateTemplatePushAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/push/duplicate/one',
      body: action.payload,
    }

    const { auth, templatePush } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('pushTemplate'),
      what: i18n.t('messages.duplicated'),
    })

    if (response._id) {
      yield put(duplicateTemplatePushAction.success(response))

      yield put(
        getAllTemplatePushAction.request(removeEmptyProp(templatePush.filters)),
      )
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        duplicateTemplatePushAction.failure({
          name: response.status || '',
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(duplicateTemplatePushAction.failure(error))
  }
}

export default duplicateTemplatePushWorker
