enum TransactionMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  TRUSTLY = 'TRUSTLY',
  PAYSAFECARD = 'PAYSAFECARD',
  APLAUZ = 'APLAUZ',
  CC = 'CC',
  TWINT = 'TWINT',
  POSTFINANCE = 'POSTFINANCE',
  POSTCARD = 'POSTCARD',
  APMGW_MONEYBOOKERS = 'apmgw_MoneyBookers',
  APMGW_NETELLER = 'apmgw_Neteller',
  APMGW_PAYSAFECARD = 'apmgw_PaySafeCard',
  APMGW_POSTFINANCE_CARD = 'apmgw_PostFinance_Card',
  APMGW_POSTFINANCE_EFINANCE = 'apmgw_PostFinance_eFinance',
  APMGW_TWINT = 'apmgw_TWINT',
  CC_CARD = 'cc_card',
}

export default TransactionMethod
