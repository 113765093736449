/**
 *
 * * Template SMS selectors
 *
 */

import { ApplicationState } from '../root.models'
import { TemplateSmsState } from './models'

export const root = ({ templateSms }: ApplicationState) => templateSms

export const templateSmsNamesSelector = ({ templates }: TemplateSmsState) => {
  return templates
}

export default root
