import { createReducer } from 'typesafe-actions'
import { IssueState, IssuesActions } from '../model'
import ActionTypes from '../constants'

import IssuesInitialState from './initial-state'
import AllHandlers from './all.handler'
import downloadDocumentImageHandler from './download-document-image.handler'

import downloadImageHandler from './download-image.handler'
import getDocumentImagesHandler from './get-document-images.handler'
import getActiveAlertsHandler from './get-active-alerts.handler'

import getOneIssueHandler from './get-one-issue.handler'
import getAllIssuesHandler from './get-all-issues.handler'

/**
 *
 * Issues main reducer
 *
 */
const IssueReducer = createReducer<IssueState, IssuesActions>(
  IssuesInitialState,
)
  .handleAction(
    [
      ActionTypes.CREATE_ISSUE,
      ActionTypes.CREATE_ISSUE_SUBSCRIBER,
      ActionTypes.UPDATE_ISSUE,
      ActionTypes.UPDATE_ISSUE_SUBSCRIBER,
      ActionTypes.SET_ISSUE_FILTERS,
      ActionTypes.SET_ALL_ISSUE_FILTERS,
      ActionTypes.SET_ISSUE_DIALOG,
      ActionTypes.SET_CURRENT_ISSUE,
      ActionTypes.SET_PAGE,
      ActionTypes.SET_ROWS_PER_PAGE,
      ActionTypes.SET_ISSUES_COUNT_BY_PLAYER,
      ActionTypes.TOGGLE_NOTIFICATIONS,
      ActionTypes.CLEAR_ALERTS,
      ActionTypes.CREATE_ADMIN_ISSUE,
      ActionTypes.CREATE_ADMIN_ISSUE_SUBSCRIBER,
      ActionTypes.SET_LOADING,
      ActionTypes.SET_ISSUE_ID_FILTER,
    ],
    AllHandlers,
  )
  .handleAction(
    [
      ActionTypes.GET_CLIENT_ACTIVE_ALERTS,
      ActionTypes.GET_CLIENT_ACTIVE_ALERTS_SUBSCRIBER,
    ],
    getActiveAlertsHandler,
  )
  .handleAction(
    [ActionTypes.GET_ONE_ISSUE, ActionTypes.GET_ONE_ISSUE_SUBSCRIBER],
    getOneIssueHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_ISSUES,
      ActionTypes.GET_ALL_ISSUES_SUBSCRIBER,
      ActionTypes.GET_ISSUES_COUNT,
      ActionTypes.GET_ISSUES_COUNT_SUBSCRIBER,
      ActionTypes.UPDATE_ISSUES_COUNT_SUBSCRIBER,
    ],
    getAllIssuesHandler,
  )
  .handleAction(
    [
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST,
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS,
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE,
      ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_REQUEST,
      ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_SUCCESS,
      ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_FAILURE,
      ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_CLEAR,
    ],
    downloadDocumentImageHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_DOCUMENT_IMAGES_REQUEST,
      ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS,
      ActionTypes.GET_DOCUMENT_IMAGES_FAILURE,
    ],
    getDocumentImagesHandler,
  )
  .handleAction(
    [
      ActionTypes.DOWNLOAD_IMAGE_REQUEST,
      ActionTypes.DOWNLOAD_IMAGE_SUCCESS,
      ActionTypes.DOWNLOAD_IMAGE_FAILURE,
    ],
    downloadImageHandler,
  )

export default IssueReducer
