const templates = {
  addFirstLanguage: 'Add the first language',
  addLanguage: 'Add Language',
  addTag: 'Add Tag',
  blockNotFound: 'Block not found. Please remove it.',
  bullet: 'Bullet',
  button: 'Button',
  buttonText: 'Button Text',
  clickAddLanguageButton: 'Click on the “Add Language” button.',
  configuredLanguage: 'configured language',
  contentInformation: 'Content Information',
  createdDate: 'Created Date',
  createNewTemplate: 'Create New Template',
  filterByLanguage: 'Filter By Language',
  goToTemplates: 'Go To Templates',
  imageFixed: 'This image is fixed.',
  messageLanguage: 'Message Language',
  placeholders: 'Placeholders',
  sendTest: 'Send Test',
  sendTestOptional: 'Send Test (Optional)',
  stepSummary: 'Step {{number}}: {{name}}',
  template: 'template',
  templateDetails: 'Template Details',
  templateDetailsInstruction:
    'Add the name, tags and description with which you want to save your {{what}}. All fields are required.',
  templates: 'Templates',
  messageLanguageText:
    'Select the language and add the information you want to include in your {{what}}. You must enter the text in each chosen language.',
  createInstruction: 'Start by filling in the information in step 1.',
}

export default templates
