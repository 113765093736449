const mediaDatabase = {
  addImage: 'Add Image',
  addNewFolder: 'Add New Folder',
  addNewMedia: 'Add New Media',
  changeImage: 'Change Image',
  createdDate: 'Created Date',
  deleteFolder: 'Delete Folder',
  deleteFolderFilesWarning:
    'This action will also permanently delete all file contents.',
  deleteFoloderTooltip:
    'This folder cannot be deleted. Please delete its content.',
  dragAndDropInstruction: 'Drag and drop a file here to be uploaded or',
  dragAndDropDisabledInstruction:
    'Please select a folder name to enable file upload.',
  folderName: 'Folder Name',
  images: 'Images',
  maximumFileSize: 'Maximum file size',
  mediaDatabase: 'Media Database',
  recommendedSize: `The recommended size is {{size}}`,
  renameFile: 'Rename File',
  selectFile: 'Select File',
}
export default mediaDatabase
