/**
 *
 * Transactions actions
 *
 */

import { createCustomAction, createAction } from 'typesafe-actions'
import WS_NAMESPACES from 'state/websocket.namespaces'
import WS_ROUTES from 'state/websocket.routes'

import { WSErrorException } from 'state/root.models'
import { TransactionModels } from '../models/index.model'
import ActionTypes from '../constants'

import { TransactionsFilters } from '../models/transactions.model'

export const setTransactionFilter = createAction(
  ActionTypes.SET_TRANSACTION_FILTERS,
  action => {
    return (searchTransaction: TransactionsFilters) => action(searchTransaction)
  },
)

export const setTransactionPlayerFilters = createAction(
  ActionTypes.SET_TRANSACTION_PLAYER_FILTERS,
  action => {
    return (newFilters: TransactionsFilters) => action(newFilters)
  },
)

export const setDownloadingXLSAction = createAction(
  ActionTypes.SET_DOWNLOAD_TRANSACTION_PLAYER,
  action => {
    return (isDownloading: boolean) => action(isDownloading)
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ALL TRANSACTIONS
 *
 */
export const getAllTransactions = createCustomAction(
  ActionTypes.GET_ALL_TRANSACTIONS,
  type => (
    payload: TransactionModels.GetTransactionsPayload,
    event: typeof WS_ROUTES.WALLET.GET_ALL_TRANSACTIONS,
    handler?: (data: any) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      overwrite: true,
      payload,
      meta: WS_NAMESPACES.WALLET,
      handler,
    }
  },
)

export const getAllTransactionsSubscribe = createAction(
  ActionTypes.GET_ALL_TRANSACTIONS_SUBSCRIBER,
  action => {
    return (response: TransactionModels.GetTransactionsResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ALL DEPOSIT TRANSACTIONS
 *
 */
export const getAllDepositTransactions = createCustomAction(
  ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS,
  type => (
    payload: TransactionModels.GetTransactionsPayload,
    event: typeof WS_ROUTES.WALLET.GET_ALL_TRANSACTIONS,
    handler?: (data: any) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      overwrite: true,
      payload,
      meta: WS_NAMESPACES.WALLET,
      handler,
    }
  },
)

export const getAllDepositTransactionsSubscribe = createAction(
  ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS_SUBSCRIBER,
  action => {
    return (response: TransactionModels.GetTransactionsResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ONE TRANSACTION
 *
 */
export const getOneTransaction = createCustomAction(
  ActionTypes.GET_ONE_TRANSACTION,
  type => (
    payload: TransactionModels.GetOneTransactionPayload,
    event: typeof WS_ROUTES.WALLET.GET_ONE_TRANSACTION,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    overwrite: true,
    payload,
    meta: WS_NAMESPACES.WALLET,
    handler,
  }),
)

export const getOneTransactionSubscribe = createAction(
  ActionTypes.GET_ONE_TRANSACTION_SUBSCRIBER,
  action => {
    return (response: TransactionModels.GetOneTransactionResponse) => {
      return action(response)
    }
  },
)

export const getTransactionExceptionSubscribe = createAction(
  ActionTypes.GET_TRANSACTION_EXCEPTION_SUBSCRIBER,
  action => {
    return (response: WSErrorException) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] COMPLETE LATE BONUS WIN
 *
 */
export const completeLateBonusWin = createCustomAction(
  ActionTypes.COMPLETE_LATE_BONUS_WIN,
  type => (
    payload: TransactionModels.CompleteLateBonusWinPayload,
    event: typeof WS_ROUTES.WALLET.COMPLETE_LATE_BONUS_WIN,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    overwrite: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)
