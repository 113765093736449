/**
 *
 * * Auth selectors
 *
 * @selector token getter
 * @selector isAuth getter
 *
 */

import { ApplicationState } from 'state/root.models'
import { AuthState } from './models'

export const tokenSelector = ({ token }: AuthState) => token
export const isAuthSelector = ({ isAuth }: AuthState) => isAuth
export const loginErrorSelector = ({ error }: AuthState) => error

export const hasLoginErrorSelector = ({ error }: AuthState) => {
  // eslint-disable-next-line no-nested-ternary
  return typeof error === 'string'
    ? error.length > 0
    : error.name
    ? error.name.length > 0
    : false
}

export const sessionSelector = ({ userSession }: AuthState) => userSession
export const roleSelector = ({ userSession }: AuthState) => userSession?.role
export const companyIdFromSessionSelector = ({ userSession }: AuthState) =>
  userSession.companyId
export const userNameSelector = ({ userSession }: AuthState) =>
  userSession.username

export const root = ({ auth }: ApplicationState) => auth
