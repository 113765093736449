import React from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIconProps } from '@material-ui/core'

import {
  AccountBalanceWallet,
  Assignment,
  CardGiftcardOutlined,
  Casino,
  DonutSmallOutlined,
  Email,
  FormatListBulleted,
  HomeOutlined,
  LocalOffer,
  RecentActors,
  Send,
  SettingsApplicationsOutlined,
  ShoppingBasket,
  Sms,
} from '@material-ui/icons'
import routes from 'routes/index.routes'
import SidebarId from 'state/enums/sidebar-id.enum'

export type SidebarLinkType = {
  id: SidebarId
  route: string
  labelText: string
  visible: boolean
  labelIcon?: React.ReactElement<SvgIconProps>
  order?: number
  links?: SidebarLinkType[]
}

const useSidebarLinks = () => {
  const { t } = useTranslation()

  const { REACT_APP_CLIENT: client, REACT_APP_ENV: env } = process.env

  const links: SidebarLinkType[] = [
    {
      id: SidebarId.HOME,
      route: '/',
      labelText: t('pages.home'),
      labelIcon: <HomeOutlined />,
      order: 1,
      visible: true,
    },
    {
      id: SidebarId.ACCOUNT_STORE,
      route: '',
      labelText: t('pages.accountStore'),
      labelIcon: <RecentActors />,
      order: 2,
      visible: true,
      links: [
        {
          id: SidebarId.ISSUES,
          route: routes.issues.INDEX,
          labelText: t('pages.alerts'),
          visible: true,
        },
      ],
    },
    {
      id: SidebarId.CRM,
      route: '',
      labelText: t('pages.crm'),
      labelIcon: <Send />,
      order: 3,
      visible: true,
      links: [
        {
          id: SidebarId.CAMPAIGNS_LIBRARY,
          route: routes.campaigns.INDEX,
          labelText: t('pages.campaigns'),
          visible: true,
        },
        {
          id: SidebarId.CAMPAIGNS_ANALYTICS,
          route: routes.campaigns.ANALYTICS,
          labelText: t('pages.analytics'),
          visible: true,
        },
        {
          id: SidebarId.FREQUENCY_CAP,
          route: routes.frequencyCap.INDEX,
          labelText: t('pages.frequencyCap'),
          visible: true,
        },
        {
          id: SidebarId.SEGMENTS_LIBRARY,
          route: routes.segments.INDEX,
          labelText: t('pages.segments'),
          visible: true,
        },
        {
          id: SidebarId.TEMPLATES_LIBRARY_EMAIL,
          route: routes.templates.INDEX,
          labelText: t('pages.emailTemplates'),
          visible: true,
        },
        {
          id: SidebarId.TEMPLATES_LIBRARY_SMS,
          route: routes.templates.INDEX_SMS,
          labelText: t('pages.smsTemplates'),
          visible: true,
        },
        {
          id: SidebarId.TEMPLATES_LIBRARY_ONSITE,
          route: routes.templates.INDEX_ONSITE,
          labelText: t('pages.onsiteTemplates'),
          visible: false,
        },
        {
          id: SidebarId.CUSTOM_TRIGGERS_LIBRARY,
          route: routes.customTrigger.INDEX,
          labelText: t('pages.customTriggers'),
          visible: true,
        },
        {
          id: SidebarId.MEDIA_DATABASE,
          route: routes.database.INDEX,
          labelText: t('pages.mediaDatabase'),
          visible: true,
        },
      ],
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.RULES_ENGINE,
      route: routes.rules.INDEX,
      labelText: t('pages.rulesEngine'),
      labelIcon: <FormatListBulleted />,
      order: 4,
      visible:
        env !== 'prod' ||
        (env === 'prod' &&
          (client === 'HH' || client === 'GF' || client === 'GG')),
    },
    {
      id: SidebarId.BONUS_ENGINE,
      route: '',
      labelText: t('pages.bonus'),
      labelIcon: <CardGiftcardOutlined />,
      order: 5,
      visible: true,
      links: [
        {
          id: SidebarId.BONUS_OFFERS,
          route: `${routes.bonusEngine.INDEX}/offers`,
          labelText: t('pages.bonusOffers'),
          visible: true,
        },
        {
          id: SidebarId.BONUS_CAMPAIGNS,
          route: `${routes.bonusEngine.INDEX}/campaigns`,
          labelText: t('pages.bonusCampaigns'),
          visible: true,
        },
      ],
    },
    {
      id: SidebarId.TAGS,
      route: '',
      labelText: t('pages.tags'),
      labelIcon: <LocalOffer />,
      order: 6,
      visible: true,
      links: [
        {
          id: SidebarId.PLAYER_TAGS,
          route: routes.playerCard.PLAYER_TAGS,
          labelText: `${t('pages.player')} ${t('pages.tags')}`,
          visible: true,
        },
        {
          id: SidebarId.CRM_TAGS,
          route: routes.playerCard.CRM_TAGS,
          labelText: `${t('pages.admin')} ${t('pages.tags')}`,
          visible: true,
        },
      ],
    },
    {
      id: SidebarId.REPORTS,
      route: '',
      labelText: t('pages.reports'),
      labelIcon: <Assignment />,
      order: 7,
      visible: true,
      links: [
        {
          id: SidebarId.GGR_REPORT,
          route: `${routes.ggrReport.INDEX}/ggr`,
          labelText: t('pages.ggrReport'),
          visible: true,
        },
        {
          id: SidebarId.TRANSACTIONS,
          route: routes.reports.TRANSACTIONS,
          labelText: t('pages.transactions'),
          visible: true,
        },
        {
          id: SidebarId.VETO_INTERNAL_BLACKLIST,
          route: routes.playerCard.BLACKLIST,
          labelText: t('pages.vetoInternalBlacklist'),
          visible: client === 'PS',
        },
        {
          id: SidebarId.VETO_INTERNAL_BLACKLIST_AUDIT,
          route: routes.playerCard.BLACKLIST_AUDIT,
          labelText: t('pages.vetoInternalBlacklistAudit'),
          visible: client === 'PS',
        },
        // !VALIDATION: client
        {
          id: SidebarId.TELEDATA,
          route: routes.playerCard.TELEDATA,
          labelText: t('pages.teledata'),
          visible: client === 'PS',
        },
        // !VALIDATION: client
        {
          id: SidebarId.REFER_FRIEND,
          route: routes.referFriend.INDEX,
          labelText: t('pages.referAFriend'),
          visible:
            client === 'JP' ||
            client === 'GF' ||
            client === 'HH' ||
            client === 'PS',
        },
        {
          id: SidebarId.PLAYER_REPORT,
          route: routes.playerReport.INDEX,
          labelText: t('pages.playerReport'),
          visible: true,
        },
      ],
    },
    {
      id: SidebarId.GAME_STORE,
      route: '',
      labelText: t('pages.gameStore'),
      labelIcon: <ShoppingBasket />,
      order: 8,
      visible: true,
      links: [
        {
          id: SidebarId.GAME_STORE_GAMES,
          route: routes.gameStore.GAMES,
          labelText: t('pages.games'),
          visible: true,
        },
        {
          id: SidebarId.GAME_STORE_PROVIDERS,
          route: routes.gameStore.PROVIDERS,
          labelText: t('pages.gameProviders'),
          visible: true,
        },
      ],
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.GAMIFICATION,
      route: '',
      labelText: t('pages.gamification'),
      labelIcon: <Casino />,
      order: 9,
      visible: client === 'JP' && env !== 'prod',
      links: [
        {
          id: SidebarId.REWARD_SHOP,
          route: routes.gamification.REWARD_SHOP,
          labelText: t('pages.rewardShop'),
          visible: client === 'JP' && env !== 'prod',
        },
        {
          id: SidebarId.LEVELS,
          route: routes.gamification.LEVELS,
          labelText: t('pages.levels'),
          visible: client === 'JP' && env !== 'prod',
        },
      ],
    },
    // !VALIDATION: env
    {
      id: SidebarId.WITHDRAWALS,
      route: routes.withdrawals.INDEX,
      labelText: t('pages.withdrawals'),
      labelIcon: <AccountBalanceWallet />,
      order: 10,
      visible: !(env === 'prod' && client === 'GF'),
    },
    {
      id: SidebarId.SETTINGS,
      route: '',
      labelText: t('pages.settings'),
      labelIcon: <SettingsApplicationsOutlined />,
      order: 11,
      visible: true,
      links: [
        // !VALIDATION: env & client
        {
          id: SidebarId.CLIENT_SETTINGS,
          route: routes.clients.INDEX,
          labelText: t('pages.clientSettings'),
          visible: env !== 'prod' || (env === 'prod' && client === 'GF'),
        },
        {
          id: SidebarId.USER_MANAGEMENT,
          route: routes.users.INDEX,
          labelText: t('pages.userManagement'),
          visible: true,
        },
        // !VALIDATION: env & client
        {
          id: SidebarId.USER_LOGS,
          route: routes.userLogs.INDEX,
          labelText: t('pages.userLogs'),
          visible: client === 'GG' && env === 'test',
        },
      ],
    },
  ]

  const linksCreate: SidebarLinkType[] = [
    {
      id: SidebarId.CAMPAIGNS_CREATE,
      route: routes.campaigns.CREATE,
      labelText: t('pages.campaign'),
      order: 1,
      visible: true,
    },
    {
      id: SidebarId.SEGMENTS_CREATE,
      route: routes.segments.CREATE,
      labelText: t('pages.segment'),
      order: 2,
      visible: true,
    },
    {
      id: SidebarId.TEMPLATES_CREATE_EMAIL,
      route: routes.templates.CREATE_EMAIL,
      labelText: t('pages.emailTemplate'),
      order: 3,
      visible: true,
    },
    {
      id: SidebarId.TEMPLATES_CREATE_SMS,
      route: routes.templates.CREATE_SMS,
      labelText: t('pages.smsTemplate'),
      order: 4,
      visible: true,
    },
    {
      id: SidebarId.CUSTOM_TRIGGERS_CREATE,
      route: routes.customTrigger.CREATE,
      labelText: t('pages.customTrigger'),
      order: 5,
      visible: true,
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.RULES_CREATE,
      route: routes.rules.CREATE,
      labelText: t('pages.rule'),
      order: 6,
      visible:
        env !== 'prod' ||
        (env === 'prod' &&
          (client === 'HH' || client === 'GF' || client === 'GG')),
    },
    {
      id: SidebarId.PLAYER_TAGS,
      route: routes.playerCard.PLAYER_TAGS,
      labelText: `${t('pages.player')} ${t('pages.tags')}`,
      order: 7,
      visible: true,
    },
    {
      id: SidebarId.CRM_TAGS,
      route: routes.playerCard.CRM_TAGS,
      labelText: `${t('pages.admin')} ${t('pages.tags')}`,
      order: 8,
      visible: true,
    },
    {
      id: SidebarId.BONUS_OFFERS_CREATE,
      route: routes.bonusEngine.CREATE_BONUS_OFFER,
      labelText: t('pages.bonusOffer'),
      order: 9,
      visible: true,
    },
    {
      id: SidebarId.BONUS_CAMPAIGNS_CREATE,
      route: routes.bonusEngine.CREATE_BONUS_CAMPAIGN,
      labelText: t('pages.bonusCampaign'),
      order: 10,
      visible: true,
    },
    {
      id: SidebarId.USER_MANAGEMENT,
      route: routes.users.INDEX,
      labelText: t('pages.user'),
      order: 11,
      visible: true,
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.GAMIFICATION,
      route: routes.gamification.CREATE_REWARD_SHOP,
      labelText: t('pages.rewardShop'),
      order: 12,
      visible: client === 'JP' && env !== 'prod',
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.LEVELS,
      route: routes.gamification.CREATE_LEVELS,
      labelText: t('pages.levels'),
      order: 13,
      visible: client === 'JP' && env !== 'prod',
    },
  ]

  const linksHome: SidebarLinkType[] = [
    {
      id: SidebarId.BONUS_OFFERS,
      route: `${routes.bonusEngine.INDEX}/offers`,
      labelText: t('pages.bonusOffers'),
      labelIcon: <CardGiftcardOutlined />,
      visible: true,
    },
    // !VALIDATION: env & client
    {
      id: SidebarId.RULES_ENGINE,
      route: routes.rules.INDEX,
      labelText: t('pages.rulesEngine'),
      labelIcon: <FormatListBulleted />,
      visible:
        env !== 'prod' ||
        (env === 'prod' &&
          (client === 'HH' || client === 'GF' || client === 'GG')),
    },
    {
      id: SidebarId.CAMPAIGNS_LIBRARY,
      route: routes.campaigns.INDEX,
      labelText: t('pages.campaigns'),
      labelIcon: <Send />,
      visible: true,
    },
    {
      id: SidebarId.SEGMENTS_LIBRARY,
      route: routes.segments.INDEX,
      labelText: t('pages.segments'),
      labelIcon: <DonutSmallOutlined />,
      visible: true,
    },
    {
      id: SidebarId.TEMPLATES_LIBRARY_EMAIL,
      route: routes.templates.INDEX,
      labelText: t('pages.emailTemplates'),
      labelIcon: <Email />,
      visible: true,
    },
    {
      id: SidebarId.TEMPLATES_LIBRARY_SMS,
      route: routes.templates.INDEX_SMS,
      labelText: t('pages.smsTemplates'),
      labelIcon: <Sms />,
      visible: true,
    },
  ]

  return {
    links,
    linksCreate,
    linksHome,
  }
}

export default useSidebarLinks
