/**
 *
 * Annexe constants
 *
 */
enum ActionTypes {
  SET_ANNEXE_FILTERS = '@annexe/SET_ANNEXE_FILTERS',
  SET_CURRENT_ANNEXE = '@annexe/SET_CURRENT_ANNEXE',

  GET_ANNEXE = '@annexe/GET_ANNEXE',
  GET_ANNEXE_SUBSCRIBER = '@annexe/GET_ANNEXE_SUBSCRIBER',

  GET_ALL_ANNEXES = '@annexe/GET_ALL_ANNEXES',
  GET_ALL_ANNEXES_SUBSCRIBER = '@annexe/GET_ALL_ANNEXES_SUBSCRIBER',

  GET_ONE_BY_ID_ANNEXE = '@annexe/GET_ONE_BY_ID_ANNEXE',
  GET_ONE_BY_ID_ANNEXE_SUBSCRIBER = '@annexe/GET_ONE_BY_ID_ANNEXE_SUBSCRIBER',

  CREATE_ANNEXE = '@annexe/CREATE_ANNEXE',
  CREATE_ANNEXE_SUBSCRIBER = '@annexe/CREATE_ANNEXE_SUBSCRIBER',
  GET_CREATE_ANNEXE = '@annexe/GET_CREATE_ANNEXE',
  GET_CREATE_ANNEXE_SUBSCRIBER = '@annexe/GET_CREATE_ANNEXE_SUBSCRIBER',

  GET_ONE_ANNEXE = '@annexe/GET_ONE_ANNEXE',
  GET_ONE_ANNEXE_SUBSCRIBER = '@annexe/GET_ONE_ANNEXE_SUBSCRIBER',

  UPDATE_ANNEXE = '@annexe/UPDATE_ANNEXE',
  UPDATE_ANNEXE_SUBSCRIBER = '@annexe/UPDATE_ANNEXE_SUBSCRIBER',

  SET_LOADING = '@annexe/SET_LOADING',
  SET_FORM_MODE = '@annexe/SET_FORM_MODE',

  SET_ANNEXE_PAGINATION = '@annexe/SET_ANNEXE_PAGINATION',
}

export default ActionTypes
