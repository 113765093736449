enum TriggerType {
  // FROM CAMPAIGN
  RESET_PASS = 1,
  REGISTER = 3,
  BONUS_GRANTED = 4,
  BONUS_RELEASED = 5,
  DEPOSIT = 7,
  WAGER = 9,
  WIN = 10,
  ACTIVATE_ACCOUNT_REQUEST = 12,
  ACTIVATE_ACCOUNT = 13,
  ACCOUNT_STATUS_CHANGED = 14,
  KYC_VERIFICATION = 15,
  CALLBACK_REQUEST = 16,
  ACCOUNT_SUSPENDED = 17,
  PASSWORD_CHANGED = 18,
  PERMANENT_EXCLUSION = 19,
  LOST_SEVENTY_FIVE = 20,
  ACCOUNT_SUSPENDED_ALERT_B = 21,
  AML_BLOCKED = 22,
  REFER_FRIEND_INVITED = 23,
  DOCUMENT_REJECTED = 24,
  REJECTED_PAYMENT = 25,
  BONUS_EXPIRATION = 26,
  REFER_FRIEND_PLAYER = 27,
  // FROM RULES ENGINE
  NOTE_ADDED = 30,
  PLAYER_TAG = 31,
  VISITS = 32,
  LOGIN = 33,
  EMERGENCY_CALLBACK_REQUEST = 34,
  LAND_BASED_OBSERVATION = 35,
  SELF_ASSESSMENT = 36,
  LIMITS = 37,
  COOL_OFF = 38,
  LOGIN_ATTEMPTS = 39,
  // WITHDRAW TYPES
  WITHDRAW_REQUESTED = 40,
  WITHDRAW_REJECTED = 41,
  WITHDRAW_COMPLETED = 42,
  KYC_STATUS_CHANGED = 43,
  AFTER_ACTIVATE_ACCOUNT = 44,
  WITHDRAW_CANCELED_BY_PLAYER = 45,
  DOCUMENT_APPROVED = 46,
  RESEND_SMS = 47,
  DELETE_ACCOUNT = 48,
  // FROM SPECIAL RULES ENGINE ACTION
  RULES_ENGINE = 90,
}

export default TriggerType
