/**
 *
 * Refer a friend constants
 *
 */

enum ActionTypes {
  GET_ALL_INVITATIONS = '@refer-friend/GET_ALL_INVITATIONS',
  GET_ALL_INVITATIONS_SUBSCRIBER = '@refer-friend/GET_ALL_INVITATIONS_SUBSCRIBER',

  RESEND_INVITATION = '@refer-friend/RESEND_INVITATION',
  RESEND_INVITATION_SUBSCRIBER = '@refer-friend/RESEND_INVITATION_SUBSCRIBER',

  GET_REFERRED_PLAYER = '@refer-friend/GET_REFERRED_PLAYER',
  GET_REFERRED_PLAYER_SUBSCRIBER = '@refer-friend/GET_REFERRED_PLAYER_SUBSCRIBER',

  GET_ADMIN_INVITATIONS = '@refer-friend/GET_ADMIN_INVITATIONS',
  GET_ADMIN_INVITATIONS_SUBSCRIBER = '@refer-friend/GET_ADMIN_INVITATIONS_SUBSCRIBER',

  SET_REFERRED_APPROVED_PAGINATION = '@refer-friend/SET_REFERRED_APPROVED_PAGINATION',

  SET_REFERRED_REJECTED_PAGINATION = '@refer-friend/SET_REFERRED_REJECTED_PAGINATION',

  SET_REFERRED_PAGINATION = '@refer-friend/SET_REFERRED_PAGINATION',
}

export default ActionTypes
