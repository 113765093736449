import ActionTypes from '../constants'
import { SegmentState, SegmentActions } from '../models'

const segmentGetAllArchiveHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_ARCHIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_ARCHIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        segments: action.payload.docs,
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ALL_ARCHIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default segmentGetAllArchiveHandler
