/**
 *
 * Personalization Engine actions
 *
 */

import { createCustomAction, createAction } from 'typesafe-actions'
import WS_NAMESPACES from 'state/websocket.namespaces'
import WS_ROUTES from 'state/websocket.routes'

import { WSErrorException } from 'state/root.models'
import {
  PersonalizationEngineResponse,
  GetPersonalizationEnginePayload,
  GetPersonalizationEngineByPlayerPayload,
  GetGameCategoriesResponse,
  GetGameProvidersResponse,
} from '../models/personalization-engine.model'
import ActionTypes from '../constants'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET GAMES BY PLAYER
 *
 */
export const getAllGamesByPlayer = createCustomAction(
  ActionTypes.GET_ALL_GAMES_PLAYER,
  type => (
    payload: GetPersonalizationEnginePayload,
    event: typeof WS_ROUTES.WALLET.GET_GAMES_PLAYER,
    handler?: (data: any) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      payload,
      meta: WS_NAMESPACES.WALLET,
      handler,
    }
  },
)

export const getAllGamesByPlayerSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_PLAYER_SUBSCRIBER,
  action => {
    return (response: PersonalizationEngineResponse) => {
      return action(response)
    }
  },
)

export const getAllGamesByPlayerExceptionSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_PLAYER_EXCEPTION_SUBSCRIBER,
  action => {
    return (response: WSErrorException) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET GAME CATEGORIES BY PLAYER
 *
 */
export const getAllGamesCategoriesByPlayer = createCustomAction(
  ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER,
  type => (
    payload: GetPersonalizationEngineByPlayerPayload,
    event: typeof WS_ROUTES.WALLET.GET_GAME_CATEGORIES_PLAYER,
    handler?: (data: any) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      payload,
      meta: WS_NAMESPACES.WALLET,
      handler,
    }
  },
)

export const getAllGamesCategoriesByPlayerSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_SUBSCRIBER,
  action => {
    return (response: GetGameCategoriesResponse) => {
      return action(response)
    }
  },
)

export const getAllGamesCategoriesByPlayerExceptionSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_EXCEPTION_SUBSCRIBER,
  action => {
    return (response: WSErrorException) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET GAME PROVIDERS BY PLAYER
 *
 */
export const getAllGamesProvidersByPlayer = createCustomAction(
  ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER,
  type => (
    payload: GetPersonalizationEngineByPlayerPayload,
    event: typeof WS_ROUTES.WALLET.GET_GAME_PROVIDERS_PLAYER,
    handler?: (data: any) => void,
  ) => {
    return {
      type,
      event,
      emit: true,
      payload,
      meta: WS_NAMESPACES.WALLET,
      handler,
    }
  },
)

export const getAllGamesProvidersByPlayerSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_SUBSCRIBER,
  action => {
    return (response: GetGameProvidersResponse) => {
      return action(response)
    }
  },
)

export const getAllGamesProvidersByPlayerExceptionSubscribe = createAction(
  ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_EXCEPTION_SUBSCRIBER,
  action => {
    return (response: WSErrorException) => {
      return action(response)
    }
  },
)

export const setFilters = createAction(
  ActionTypes.SET_PERSONALIZATION_ENGINE_FILTERS,
  action => {
    return (filters: GetPersonalizationEnginePayload) => action(filters)
  },
)