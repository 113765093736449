import { all, fork } from 'redux-saga/effects'
import userSagasWatcher from './user/sagas/index.saga'
import templateEmailSagasWatcher from './template-email/sagas/index.saga'

import segmentSagasWatcher from './segment/sagas/index.saga'
import companySagasWatcher from './company/sagas/index.saga'
import authSagasWatcher from './auth/sagas/index.saga'

import campaignSagasWatcher from './campaign/sagas/index.saga'
import analyticsSagasWatcher from './analytics/sagas/index.saga'
import kycSagasWatcher from './player-card-kyc/sagas/index.saga'

import issueSagasWatcher from './issues/sagas/index.saga'
import templateSmsSagasWatcher from './template-sms/sagas/index.saga'
import roleSagasWatcher from './role/sagas/index.saga'

import frequencyCapSagasWatcher from './frequency-cap/sagas/index.saga'
import settingsSagasWatcher from './settings/sagas/index.saga'
import customTriggerSagasWatcher from './custom-trigger/sagas/index.saga'

import templatePushSagasWatcher from './template-push/sagas/index.saga'
import templateOnSiteSagasWatcher from './template-onsite/sagas/index.saga'

export default function* rootSaga() {
  yield all([
    fork(analyticsSagasWatcher),
    fork(authSagasWatcher),
    fork(campaignSagasWatcher),
    fork(companySagasWatcher),
    fork(customTriggerSagasWatcher),
    fork(frequencyCapSagasWatcher),
    fork(issueSagasWatcher),
    fork(kycSagasWatcher),
    fork(roleSagasWatcher),
    fork(segmentSagasWatcher),
    fork(settingsSagasWatcher),
    fork(templateEmailSagasWatcher),
    fork(templateOnSiteSagasWatcher),
    fork(templatePushSagasWatcher),
    fork(templateSmsSagasWatcher),
    fork(userSagasWatcher),
  ])
}
