const bonusEngine = {
  chosenSegment: 'Chosen Segment',
  bonusEngine: 'Bonus Engine',
  bonusOffers: 'Bonus Offers',
  create: 'Create',
  noBonusesAddedYet: 'No bonus offers added yet',
  clickTheCreateBonusOfferButton:
    'Click the “Create Bonus Offer” button to create a new offer',
  createBonus: 'Create Bonus Offer',
  createBonusCampaign: 'Create Bonus Campaign',
  createCampaign: 'Create Campaign',
  noBonusCampaignsAddedYet: 'No bonus campaigns added yet',
  clickTheCreateBonusCampaignButton:
    'Click the “Create Bonus Campaign" button to create a new bonus campaign',
  termsAndConditions: 'Terms & Conditions',
  termsAndConditionsURL: 'Terms & Conditions URL',
  termsAndConditionsValidation: 'Terms and Conditions',
  baseCurrency: 'Base Currency',
  goToBonus: 'Go to Bonus',
  internalBonusName: 'Internal Bonus Name',
  bonusDetails: 'Bonus Details',
  externalBonusDetails: 'External Bonus Details',
  externalBonusName: 'External Bonus Name',
  createNewBonus: 'Create New Bonus',
  bonusOfferStepOneInstructions:
    'Please add the base currency, internal bonus name and terms in order to save your bonus.',
  bonusOfferPopoverAlert:
    'You will lose the data. Are you sure you want to abort this bonus offer?',
  bonusCampaignPopoverAlert:
    'You will lose the data. Are you sure you want to abort this bonus campaign?',
  selectLanguage: 'Select Language',
  selectCurrency: 'Select Currency',
  addDescription: 'Add Description',
  addName: 'Add Name',
  thisFieldIsRequired: 'This field is required.',
  invalidFormat: 'Invalid format.',
  selectBonusType: 'Select Bonus Type',
  cashBonus: 'Cash Bonus',
  freeGames: 'Free Games',
  manualBonusCodes: 'Manual Bonus Codes',
  affiliateCodes: 'Affiliate Codes',
  bonusCodesFile: 'Bonus Codes File',
  barriereCardLevel: 'Barriere Card Level',
  uploadedFile: 'Uploaded File',
  dragAndDropATxtFileHereToBeUploadedOr:
    'Drag and drop a .txt file here to be uploaded or click',
  createANewBonusOffer: 'Create a new bonus offer',
  summary: 'Summary',
  inDesign: 'In Design',
  settings: 'Settings',
  bonusCodes: 'Bonus Codes',
  reusableBonusCodes: 'Reusable Bonus Codes',
  bonusCodesRepeatedError: 'Bonus Codes may not be repeated',
  bonusCodesRepeatedMessage: 'The Bonus Code "{{field}}" is repeated',
  affiliateCodesRepeatedError: 'Affiliate codes may not be repeated',
  affiliateCodesRepeatedMessage: 'The affiliate code "{{field}}" is repeated',
  reusable: 'Reusable',
  notReusable: 'Not Reusable',
  createBonusOffer: 'Create Bonus Offer',
  uploadFile: 'Upload File',
  bonusOffer: 'Bonus Offer',
  otherSettings: 'Other Settings',
  selectUnit: 'Select Unit',
  validityTime: 'Validity Time',
  validityTimeUnit: 'Validity Time (Time Unit)',
  validityTimeSpecificDate: 'Validity Time (Specific Date)',
  acceptanceTime: 'Acceptance Time',
  selectRecurrence: 'Select Recurrence',
  recurrence: 'Recurrence',
  maxGrantedCount: 'Max Granted Count',
  maxGrantedAmount: 'Max Granted Amount',
  bonusGrantedToSameCustomer:
    'This bonus program may be granted to the same customer up to',
  validityTimeTooltip:
    'From the moment the bonus is granted, it determines within how much time the bonus conditions must be met, before the bonus expires. For example: 2 days or specific date',
  acceptanceTimeTooltip:
    'From the moment the bonus is offered to the player, for how long will it be available and may be accepted by the player. For example: 2 days.',
  priorityTooltip:
    'If the player is eligible for multiple bonuses, the bonus with the highest priority will be offered. Priority 1 being the highest priority.',
  maxGrantedCountTooltip:
    'This contemplates how many bonuses have been granted and once the max is reached, no more bonuses will get granted.',
  maxGrantedAmountTooltip:
    'This contemplates how much bonus money has been granted (in the defined currency) and once the max is reached, no more bonuses will get granted.',
  unitOptions: {
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days',
    WEEKS: 'weeks',
    MONTHS: 'months',
    YEARS: 'years',
  },
  bonusRecurrenceOptions: {
    SINGLE: 'Single',
    MULTIPLE: 'Multiple',
  },
  priorityHelperText: 'The priority range is from 1 to 100.',
  stepBonusEngineSummary: 'Step {{number}}:',
  bonusTrigger: 'Trigger',
  triggerType: 'Trigger Type',
  depositCount: 'Deposit Count',
  deposits: 'Deposits',
  minimumDepositAmount: 'Minimum Deposit Amount',
  amount: 'Amount',
  selectTriggerType: 'Select Trigger Type',
  selectDepositCount: 'Select Deposit Count',
  selectMinimumDepositAmount: 'Select Minimum Deposit Amount',
  triggerTypes: {
    DEPOSIT: 'By Deposit',
    ACCOUNT_VERIFICATION: 'By Account Verification',
    ACCOUNT_ACTIVATION_KYC: 'By Account Activation - KYC',
    BONUS_CODE: 'By Bonus Code',
    MANUAL_BONUS: 'By Manual Bonus ',
    SEGMENTATION_BONUS: 'By Segmentation Bonus',
    DEPENDENT: 'By Dependent',
  },
  verificationType: {
    EMAIL: 'Email',
    SMS: 'SMS',
    SMS_EMAIL: 'Email & SMS',
  },
  verifiedBy: 'Verified By',
  depositConditions: 'Deposit Conditions',
  depositCountOptions: {
    EQUAL: 'Equal',
    GREATER_THAN: 'Greater Than >',
    MORE_THAN: 'Greater Than >',
    MORE_THAN_OR_EQUAL: 'Greater Than > Equal To =',
  },
  minimumDepositAmountOptions: {
    MORE_THAN: 'Greater Than >',
    MORE_THAN_OR_EQUAL: 'Greater Than > Equal To =',
  },
  bonusAmountInstructions:
    'Please configure your bonus with the following settings',
  cashBonusTypes: {
    PERCENTAGE: 'Percentage',
    FIXED: 'Fixed',
  },
  bonusCap: 'Bonus Cap',
  selectCashBonus: 'Select Cash Bonus',
  selectBonusCap: 'Select Bonus Cap',
  percentage: 'Percentage',
  bonusCapOptions: {
    LESS_THAN_OR_EQUAL: 'Less Than or Equal to <=',
    LESS_THAN: 'Less Than <',
  },
  wageringRequirementInstructions:
    'Please configure your bonus with the following settings',
  baseAmountForWR: 'Base Amount For Wagering Requirement',
  wageringRequirementCoefficient: 'Wagering Requirement Coefficient',
  bonusMode: 'Bonus Mode',
  selectBonusMode: 'Select Bonus Mode',
  byRounds: 'By Rounds',
  byTurnover: 'By Turnover',
  selectBaseAmount: 'Select Base Amount',
  bonusModes: {
    DEPOSIT_PLUS_BONUS: 'Deposit + Bonus',
  },
  rounds: 'Rounds',
  bonusRestrictionsInstructions:
    'Please configure the following optional settings for Bonus Money Bets and the Max Bonus Win if the bonus is released to Real Money (all of them are optional).',
  bonusRestrictions: 'Bonus Restrictions',
  maxBonusWin: 'Max Bonus Win',
  minBet: 'Min Bet',
  whenBelowtheLimit: 'When Below the Limit',
  discardContribution: 'Discard Contribution',
  denyBet: 'Deny Bet',
  maxBet: 'Max Bet',
  whenAboveTheLimit: 'When Above The Limit',
  freeGamesSettings: 'Free Rounds Settings',
  freeGamesSettingsInstructions:
    'Please configure your Bonus with the following settings.',
  contributionsRestrictionsInstructions:
    'Please configure the Wagering Contributions and Restrictions for Game Categories and Individual Games. Bonus Win if the bonus is released to Real Money.',
  contributionsRestrictions: 'Games Contributions & Restrictions',
  searchCategory: 'Search Category',
  searchGame: 'Search Game',
  contribution: 'Contribution',
  noInformationAvailable: 'No Information Available',
  categories: 'Categories',
  games: 'Games',
  selectGames: 'Select Games',
  optInRequired: 'Opt In Required',
  optIn: 'Opt In',
  chooseSegment: 'Choose Segment',
  selectSegment: 'Select Segment',
  default: 'Default',
  allPlayers: 'All players',
  custom: 'Custom',
  eligibilityInstructions:
    'Please configure which players will be eligible for your bonus',
  eligibility: 'Eligibility',
  selectAffiliateCode: 'Select Affiliate Code',
  codes: 'Codes',
  affiliateCode: 'Affiliate Code',
  contains: 'Contains',
  include: 'Include',
  exclude: 'Exclude',
  addTag: 'Add Tag',
  addRule: 'Add Rule',
  removeRule: 'Remove Rule',
  required: 'Required',
  notRequired: 'Not Required',
  bonusEngineEnableInstructions:
    'Please choose whether to enable or disable your bonus. You may save all bonus configurations without enabling the bonus yet.',
  enableBonusOffer: 'Enable Bonus Offer',
  enable: 'Enable',
  disabled: 'Disabled',
  createNewCampaign: 'Create New Campaign',
  bonusCampaign: 'Bonus Campaign',
  bonusCampaignDetails: 'Bonus Campaign Details',
  createANewBonusCampaign: 'Create A New Bonus Campaign',
  notCombinable: 'Not Combinable',
  combinable: 'Combinable',
  goToCampaigns: 'Go To Campaigns',
  invalidDate: 'Invalid Date',
  startDate: 'Start Date',
  time: 'Time',
  endDate: 'End Date',
  dateMustBeInTheFuture: 'Date and time must be in the future.',
  greaterDate:
    'Please choose a date and time greater than the current day and time.',
  addBonusOffers: 'Add Bonus Offers',
  newEndDateMustNotBeMinorThatOriginalEndDate:
    'Please, the new end date should not be less than the original end date.',
  endDateMustBeAfterStartDate: 'End date must be after the start date.',
  zipcode: 'Zip Code',
  streetNumber: 'Street Number',
  streetName: 'Street Name',
  cancelledAndDeleted: 'Cancelled and deleted',
  searchByNameIdBonusCode: 'Search By Name, ID or Bonus Code',
  selectStatus: 'Select Status',
  inactive: 'Inactive',
  filterByStatus: 'Filter By Status',
  filterByTrigger: 'Filter By Trigger',
  filterByType: 'Filter By Type',
  searchByCampaignNameOrId: 'Search By Campaign Name Or Id',
  campaignNameOrId: 'Campaign Name or ID',
  nameIdBonusCode: 'Name, ID or Bonus Code',
  beTriggerType: {
    ACCOUNT_VERIFICATION: 'Account Verification',
    ACTIVATE_ACCOUNT: 'Activate Account',
    BONUS_CODE: 'Bonus Code',
    DEPOSIT: 'Deposit',
    MANUAL_BONUS: 'Manual Bonus',
    SEGMENTATION_BONUS: 'Segmentation Bonus',
    DEPENDENT: 'Dependent',
    GAMIFICATION: 'Gamification',
    LOGIN: 'By Login',
  },
  beBonusType: {
    MONEY: 'Cash Bonus',
    FREE_GAMES: 'Free Rounds',
  },
  affiliateType: {
    EQUAL: 'Equal',
    ENDS_WITH: 'Ends With',
    STARTS_WITH: 'Starts With',
    CONTAINS: 'Contains',
  },
  updateBonusCampaign: 'Update Bonus Campaign',
  update: 'Update',
  onlyCampaignsNotStartedYet: 'Only campaigns not started yet can be deleted.',
  thisBonusHasALinkedCampaign: `This bonus has an offer, it can't be removed.`,
  noBonusOffersAdded: 'No bonus offers added',
  updateBonusOffer: 'Update Bonus Offer',
  bonusOffersInstructions:
    'Please add the desired bonus offers for this campaign',
  campaignDetailsInstructions:
    'Please add the following campaign details to complete this step',
  periodInstructions:
    'Please select dates and times for the campaign to be active',
  searchStatus: {
    ALL: 'All',
    INACTIVE: 'Inactive',
    ACTIVE: 'Active',
  },
  addCodes: 'Add Codes',
  wageringType: {
    ROUNDS: 'By Rounds',
    TURNOVER: 'By Turnover',
  },
  otherSettingsInstructions:
    'Please add the following fields in order to complete this step (all of them are optional).',
  bonusOfferTriggerInstructions:
    'Please select a trigger type for this bonus offer',
  typeAndCodesInstructions:
    'Please provide the following information in order to continue',
  gameCategories: {
    ALL: 'All',
    SLOTS: 'Slots',
    DICE_GAMES: 'Dice Games',
    BLACKJACK: 'Blackjack',
    ROULETTE: 'Roulette',
    BACCARAT: 'Baccarat',
    POKER: 'Poker',
    OTHER: 'Other',
  },
  grantedBonus: 'Granted Bonus',
  contributionMustBeBetween0and100: 'Must be between 0 and 100.',
  byGameCategory: 'By Game Category',
  byGameProvider: 'By Game Provider',
  bonusGamesFilter: 'Filter',
  byId: 'By ID',
  restrictions: 'Restrictions',
  byGameName: 'By Game Name',
  bonusCodesMustBeUniquePerBonus: 'Bonus codes must be unique per bonus.',
  delayTime: 'Delay Time',
  delayTimeTooltip:
    'From the moment the player gets this bonus, it determines if there should be a delay for the bonus to get granted.',
  dependentBonuses: 'Dependent Bonuses',
  skipBonusOfferStep7:
    'This step does not apply for trigger type "Manual Bonus", please continue to Step 7.',
  skipBonusOfferStep7Summary:
    'This step does not apply for trigger type "Manual Bonus".',
  skipBonusCodesStep4:
    'This step does not apply for trigger type "By Login", please continue to Step 5.',
  skipBonusCodesStep4Summary:
    'This step does not apply for trigger type "By Login".',
  optInTooltip:
    'If the Opt In is Enabled, after the pertinent event occurs, the player will be prompted to accept the bonus first, before it can be granted.',
  goToBonusOffers: 'Go To Bonus Offers',
  goToBonusCampaigns: 'Go To Bonus Campaigns',
  freeGamesSettingsValues: {
    amountValue: 'Total Free Rounds Value',
    amountValueDescription:
      'This value refers to the total value in the selected currency of all free rounds granted.  In other words, the amount of free rounds multiplied by the cost per bet.  Important: this is the value reported to ESBK, therefore it is advised to include games with the same bet per spin value, to avoid discrepancies in the reporting.',
    freeRounds: 'Amount of Free Rounds',
    freeRoundsDescription:
      'This value refers to the total amount of free rounds to be granted to the player when this bonus offer is received.',
    betLevel: 'Bet Level',
    coinValue: 'Coin Level',
    nearestCostPerBet: 'Nearest Cost Per Bet',
    nearestCostPerBetDescription:
      'This value refers to the approximate cost per bet or spin.  If the value is lower than the minimum bet value in the game, then it will use the correct minimum value.  Please refer to the actual game for each exact bet value per spin.',
  },
  maximumOfUnitExceeded:
    'The maximum amount of {{unit}} is {{maximum}}. Please insert a lower number.',
}

export default bonusEngine
