import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'

export const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        fill="#fff"
        d="M17.21,16.12h-3.83v1.68h3.91c-0.26,2.57-2.45,4.37-5.23,4.37c-3.34,0-5.74-2.72-5.74-6.17c0,0,0,0,0-0.01
	c0-0.84,0.16-1.64,0.46-2.38l-0.01,0.04C8.22,15.11,10.21,16,12.41,16h0c4.39,0,6.98-3.61,6.98-8h-6.81c-0.06,0-0.11,0-0.17,0
	c-2.22,0-4.22,0.91-5.66,2.37l0,0c-0.26-0.7-0.41-1.51-0.41-2.35c0-0.01,0-0.02,0-0.03v0c0-3.45,2.71-6.24,6.06-6.24
	c1.97,0.01,3.71,0.96,4.8,2.44l0.01,0.02l1.49-1.17C17.21,1.18,14.95,0,12.41,0c-4.42,0-8,3.58-8,8l0,0l0,0
	c0,1.47,0.4,2.85,1.11,4.03L5.5,12c-0.68,1.14-1.09,2.52-1.09,4l0,0c0.02,4.41,3.59,7.98,8,8h0c4.39,0,6.98-3.49,6.98-7.88
	L17.21,16.12L17.21,16.12z M12.39,9.76c1.62,0,3.09,0.66,4.16,1.71l0,0c-0.86,1.64-2.39,2.71-4.48,2.71c-0.02,0-0.03,0-0.05,0
	c-1.75,0-3.32-0.82-4.32-2.1l-0.01-0.01C8.79,10.66,10.49,9.76,12.39,9.76L12.39,9.76L12.39,9.76z"
      />
      <path
        fill="#f28b00"
        d="M13.61,11.96c0,0.78-0.63,1.41-1.41,1.41s-1.41-0.63-1.41-1.41s0.63-1.41,1.41-1.41S13.61,11.18,13.61,11.96z"
      />
    </SvgIcon>
  )
}

export const IconSms = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,2C5.7,2,0.6,6.2,0.6,11.3c0,2.2,1,4.2,2.5,5.8c-0.6,2.2-2.4,4.2-2.4,4.3c-0.1,0.1-0.1,0.3-0.1,0.4C0.7,21.9,0.8,22,1,22
	c2.9,0,5.2-1.4,6.2-2.3c1.5,0.5,3.1,0.9,4.8,0.9c6.3,0,11.4-4.1,11.4-9.2S18.3,2,12,2z M6.3,14.1H5.8c-0.2,0-0.4-0.2-0.4-0.4v-0.7
	c0-0.2,0.2-0.4,0.4-0.4h0.5c0.3,0,0.5-0.2,0.5-0.3c0-0.1,0-0.1-0.1-0.2l-1-0.8c-0.4-0.3-0.6-0.8-0.6-1.2C5.1,9.2,6,8.4,7,8.4h0.5
	c0.2,0,0.4,0.2,0.4,0.4v0.7c0,0.2-0.2,0.4-0.4,0.4H7c-0.3,0-0.5,0.2-0.5,0.3c0,0.1,0,0.1,0.1,0.2l1,0.8c0.4,0.3,0.6,0.8,0.6,1.2
	C8.2,13.4,7.4,14.1,6.3,14.1L6.3,14.1z M14.8,13.8c0,0.2-0.2,0.4-0.4,0.4h-0.7c-0.2,0-0.4-0.2-0.4-0.4v-3l-1.1,2.5
	c-0.1,0.3-0.5,0.3-0.6,0l-1.1-2.5v3c0,0.2-0.2,0.4-0.4,0.4H9.5c-0.2,0-0.4-0.2-0.4-0.4V9.2c0-0.4,0.3-0.7,0.7-0.7h0.7
	c0.3,0,0.5,0.2,0.6,0.4l0.8,1.6l0.8-1.6c0.1-0.2,0.4-0.4,0.6-0.4h0.7c0.4,0,0.7,0.3,0.7,0.7V13.8z M17,14.1h-0.5
	c-0.2,0-0.4-0.2-0.4-0.4v-0.7c0-0.2,0.2-0.4,0.4-0.4H17c0.3,0,0.5-0.2,0.5-0.3c0-0.1,0-0.1-0.1-0.2l-1-0.8c-0.4-0.3-0.6-0.8-0.6-1.2
	c0-0.9,0.8-1.7,1.9-1.7h0.5c0.2,0,0.4,0.2,0.4,0.4v0.7c0,0.2-0.2,0.4-0.4,0.4h-0.5c-0.3,0-0.5,0.2-0.5,0.3c0,0.1,0,0.1,0.1,0.2
	l1,0.8c0.4,0.3,0.6,0.8,0.6,1.2C18.9,13.4,18,14.1,17,14.1L17,14.1z"
      />
    </SvgIcon>
  )
}
