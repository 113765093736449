const error = {
  alreadyExist: 'This {{item}} already exists.',
  bicIbanMatchError: `BIC and IBAN don't belong to the same bank.`,
  chooseOneOption: 'Please choose at least one option',
  consecutiveWhitespace: 'Consecutive whitespace is not allowed.',
  emailFormat: 'Format: example@email.com',
  emailRepeat: 'Please do not repeat emails.',
  fileNotFound: 'File not found.',
  fileSize: 'File size',
  firstValueGreaterThanLastValue:
    'First value must not be greater than or equal to last value.',
  invalidFormat: 'Invalid format',
  invalidFormatBarriere:
    'Must be a 4 or 6 digit long number, optionally beginning with MON, CRD or FRB, with optional dash in between.',
  issueBeforeExpiry: 'Issue date should be before expiry date.',
  maxError: 'Value must be less than {{max}}.',
  maxDecimalsError: 'Value must have less than {{max}} decimals.',
  maxErrorValue: '{{value}} must be less than {{max}}.',
  maxFileSize: 'Maximum file size',
  minError: 'Value must be more than {{min}}.',
  minErrorValue: '{{value}} must be more than {{min}}.',
  minFileSize: 'Minimum file size',
  noteDateError: 'Note must be scheduled after current date/time.',
  passwordDontMatch: `Password and confirm password don't match`,
  passwordValidation:
    'Between 9 and 20 characters long, at least one uppercase letter, one lowercase letter, one number and one special character.',
  requiredField: 'This field is required.',
  regexTextarea: 'The following characters are not allowed: ~<>/\\{|}',
  regexTextareaPlaceholder: 'The following characters are not allowed: ~<>/\\|',
  regexURL: 'Format: https://google.com',
  returnToHomepage: 'Return to the homepage',
  selectOneFile: 'Please select only one file.',
  settingsError:
    'Your current selected settings configuration has an error. Please load a different one.',
  theRequestPageWasNotFoundOnThisServer:
    'The requested page was not found on this server',
  valuesNotChanged: `You haven't changed any values.`,
}

export default error
