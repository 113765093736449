import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, TextField } from '@material-ui/core'

import useForgotPassword from '../hooks/use-forgot-password.hook'

const ForgotPasswordComponent = () => {
  const { t } = useTranslation()

  const { handleOnChange, handleOnSubmit, state } = useForgotPassword()

  const handleOnChangeInput = ({
    currentTarget: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    handleOnChange(name, value)
  }

  return (
    <>
      <TextField
        className="label-float"
        error={state.email.error.length > 0}
        helperText={state.email.error || ' '}
        fullWidth
        name="email"
        defaultValue=""
        label={t('labels.email')}
        margin="dense"
        variant="outlined"
        onChange={handleOnChangeInput}
        autoFocus
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleOnSubmit}
        fullWidth
      >
        {t('sendInstructions')}
      </Button>
    </>
  )
}

export default ForgotPasswordComponent
