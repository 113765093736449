import FormStatusType from 'state/enums/form-status.enum'
import { GlobalUiState } from '../models'

const initialState: GlobalUiState = {
  isLoadingFull: false,
  snackbar: {
    message: '',
    open: false,
    variant: 'success',
  },

  dialog: {
    open: false,
  },
  dialogs: [],
  popover: {
    id: '',
    transformOrigin: undefined,
    anchorOrigin: undefined,
    open: false,
  },
  popoverConfirm: {
    open: false,
    action: 'delete',
    type: '',
    id: '',
    anchorPosition: {
      top: 0,
      left: 0,
    },
  },
  formMode: FormStatusType.CREATE,
  drawer: { open: false },
  activeStep: 0,
  languagesSelected: [],
  activeLanguage: '',
  isInit: false,
}

export default initialState
