/**
 *
 * Cool Off constants
 *
 */

enum ActionTypes {
  GET_COOL_OFFS = '@cool-off/GET_COOL_OFFS',
  GET_COOL_OFFS_SUBSCRIBER = '@cool-off/GET_COOL_OFFS_SUBSCRIBER',

  ADD_COOL_OFF = '@cool-off/ADD_COOL_OFF',
  ADD_COOL_OFF_SUBSCRIBER = '@cool-off/ADD_COOL_OFF_SUBSCRIBER',

  GET_CATEGORIES = '@cool-off/GET_CATEGORIES',
  GET_CATEGORIES_SUBSCRIBER = '@cool-off/GET_CATEGORIES_SUBSCRIBER',

  GET_PERIODS_AND_AMOUNTS = '@cool-off/GET_PERIODS_AND_AMOUNTS',
  GET_PERIODS_AND_AMOUNTS_SUBSCRIBER = '@cool-off/GET_PERIODS_AND_AMOUNTS_SUBSCRIBER',

  DELETE_COOL_OFF = '@cool-off/DELETE_COOL_OFF',
  DELETE_COOL_OFF_SUBSCRIBER = '@cool-off/DELETE_COOL_OFF_SUBSCRIBER',

  SET_COOL_OFF_FILTERS = '@cool-off/SET_COOL_OFF_FILTERS'
}

export default ActionTypes
