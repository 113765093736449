import { createReducer } from 'typesafe-actions'
import { TemplateEmailState, TemplateActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import templateCreateHandler from './create.handler'
import templateGetOneHandler from './get-one.handler'

import templateGetAllHandler from './get-all.handler'
import templateGetOneLanguageHandler from './get-one-language.handler'
import templateUpdateHandler from './update.handler'

import templateUpdateStatusHandler from './update.status.handler'
import templateSendTestEmailHandler from './send-email-test.handler'
import templateSendEmailHandler from './send-email.handler'

import templateSendEmailForgotPasswordHandler from './send-email-forgot-password.handle'
import generalHandler from './ui-action.handler'

const templateReducer = createReducer<TemplateEmailState, TemplateActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.CREATE_EMAIL_REQUEST,
      ActionTypes.CREATE_EMAIL_SUCCESS,
      ActionTypes.CREATE_EMAIL_FAILURE,
    ],
    templateCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_EMAIL_REQUEST,
      ActionTypes.GET_ONE_EMAIL_SUCCESS,
      ActionTypes.GET_ONE_EMAIL_FAILURE,
    ],
    templateGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_EMAIL_REQUEST,
      ActionTypes.GET_ALL_EMAIL_SUCCESS,
      ActionTypes.GET_ALL_EMAIL_FAILURE,
    ],
    templateGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_LANGUAGES_REQUEST,
      ActionTypes.GET_ONE_LANGUAGES_SUCCESS,
      ActionTypes.GET_ONE_LANGUAGES_FAILURE,
    ],
    templateGetOneLanguageHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    templateUpdateHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_STATUS_REQUEST,
      ActionTypes.UPDATE_STATUS_SUCCESS,
      ActionTypes.UPDATE_STATUS_FAILURE,
    ],
    templateUpdateStatusHandler,
  )
  .handleAction(
    [
      ActionTypes.SEND_EMAIL_TEST_REQUEST,
      ActionTypes.SEND_EMAIL_TEST_SUCCESS,
      ActionTypes.SEND_EMAIL_TEST_FAILURE,
    ],
    templateSendTestEmailHandler,
  )
  .handleAction(
    [
      ActionTypes.SEND_EMAIL_REQUEST,
      ActionTypes.SEND_EMAIL_SUCCESS,
      ActionTypes.SEND_EMAIL_FAILURE,
    ],
    templateSendEmailHandler,
  )
  .handleAction(
    [
      ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_REQUEST,
      ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
      ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_FAILURE,
    ],
    templateSendEmailForgotPasswordHandler,
  )
  .handleAction(
    [
      ActionTypes.RESET_EMAIL_TEMPLATES,
      ActionTypes.RESET_TEMPLATES_ARCHIVED,
      ActionTypes.SET_CREATION_MODE,
      ActionTypes.SET_FORM_MODE,
      ActionTypes.SET_FILTERS,
      ActionTypes.SET_CURRENT_TEMPLATE,
      ActionTypes.SET_POPOVER,
      ActionTypes.SET_ACTIVE_LANGUAGE,
      ActionTypes.SET_TEMPLATE_EMAIL_DIALOG,
    ],
    generalHandler,
  )

export default templateReducer
