import ActionTypes from '../constants'
import {
  PersonalizationEngineState,
  PersonalizationEngineActions,
} from '../models/personalization-engine.model'

const getGamesByPlayerHandler = (
  state: PersonalizationEngineState,
  action: PersonalizationEngineActions,
): PersonalizationEngineState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_GAMES_PLAYER:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_GAMES_PLAYER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        gamesByPlayer: action.payload.docs,
        pagination: {
          total: action.payload.total || 0,
          limit: action.payload.limit || 10,
          page: action.payload.page || 1,
          pages: action.payload.pages || 0,
        },
      }

    case ActionTypes.GET_ALL_GAMES_PLAYER_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
      }

      case ActionTypes.SET_PERSONALIZATION_ENGINE_FILTERS:
        return {
          ...state,
          filters: { ...state.filters, ...action.payload },
        }

    default:
      return state
  }
}

export default getGamesByPlayerHandler
