const initialState = {
  player: {
    username: '',
    isOnline: true,
    email: '',
    country: '',
    fullName: '',
    id: '',
    realBalance: '',
    bonusBalance: '',
  },
  accountStatus: 0,
  text: '',
  alerts: [{ id: 'RG', message: 'RG', type: '0' }],
  labels: [{ name: 'SEVERE', id: '0' }],
  tags: [{ id: '0', name: 'f player', type: '0' }],
  notes: [],
  isLoading: false,
}

export default initialState
