import { createReducer } from 'typesafe-actions'
import * as CoolOffModels from '../models'
import CoolOffInitialState from './initial-state'

import ActionTypes from '../constants'
import getCoolOffsHandler from './get-cool-offs.handler'
import addCoolOffHandler from './add-cool-off.handler'

import getCategoriesHandler from './get-categories.handler'
import getPeriodsAmountsHandler from './get-periods-amounts.handler'
import deleteCoolOffHandler from './delete-cool-off.handler'

import documentGeneralHandler from './general.handler'

/**
 *
 * Cool Offs main reducer
 *
 */

const coolOffReducer = createReducer<
  CoolOffModels.CoolOffState,
  CoolOffModels.CoolOffActions | CoolOffModels.SocketActions
>(CoolOffInitialState)
  .handleAction(
    [ActionTypes.GET_COOL_OFFS, ActionTypes.GET_COOL_OFFS_SUBSCRIBER],
    getCoolOffsHandler,
  )
  .handleAction(
    [ActionTypes.ADD_COOL_OFF, ActionTypes.ADD_COOL_OFF_SUBSCRIBER],
    addCoolOffHandler,
  )
  .handleAction([ActionTypes.GET_CATEGORIES_SUBSCRIBER], getCategoriesHandler)
  .handleAction(
    [ActionTypes.GET_PERIODS_AND_AMOUNTS_SUBSCRIBER],
    getPeriodsAmountsHandler,
  )
  .handleAction(
    [ActionTypes.DELETE_COOL_OFF, ActionTypes.DELETE_COOL_OFF_SUBSCRIBER],
    deleteCoolOffHandler,
  )
  .handleAction(ActionTypes.SET_COOL_OFF_FILTERS, documentGeneralHandler)

export default coolOffReducer
