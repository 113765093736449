import * as CoolOffModels from '../models'
import ActionTypes from '../constants'

const getCoolOffsHandler = (
  state: CoolOffModels.CoolOffState,
  action: CoolOffModels.CoolOffActions,
): CoolOffModels.CoolOffState => {
  switch (action.type) {
    case ActionTypes.GET_COOL_OFFS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_COOL_OFFS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        coolOffs:
          action.payload?.resolved && action.payload?.message.length !== 0
            ? [...action.payload?.message]
            : [],
        pagination : {
          ...state.pagination,
          total: action.payload?.message.length,
          pages: Math.ceil(action.payload?.message.length / state.pagination.limit)
        },
      }

    default:
      return state
  }
}

export default getCoolOffsHandler
