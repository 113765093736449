import React from 'react'
import { useTranslation } from 'react-i18next'
import Auth from '../../layouts/auth'

import LoginModule from '../../login/index.module'

const Login = () => {
  const { t } = useTranslation()

  return (
    <>
      <Auth title={t('login')} auth={false}>
        <LoginModule />
      </Auth>
    </>
  )
}

export default Login
