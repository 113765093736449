import * as BonusModels from '../models'
import ActionTypes from '../constants'

const getBonusLogsHandler = (
  state: BonusModels.BonusState,
  action: BonusModels.BonusActions,
): BonusModels.BonusState => {
  switch (action.type) {
    case ActionTypes.GET_BONUS_LOGS:
      return {
        ...state,
        isLoadingLogs: true,
      }

    case ActionTypes.GET_BONUS_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingLogs: false,
        bonusLogs: action?.payload?.payload?.data || [],
      }

    default:
      return state
  }
}

export default getBonusLogsHandler
