import * as PlayerCardKYCReducer from './reducers/index.reducer'
import * as PlayerCardKYCTypes from './constants'
import * as PlayerCardKYCActions from './actions/index.actions'

import * as PlayerCardKYCSelectors from './selectors'

export {
  PlayerCardKYCReducer,
  PlayerCardKYCTypes,
  PlayerCardKYCActions,
  PlayerCardKYCSelectors,
}

export default PlayerCardKYCReducer
