import * as BonusModels from '../models'
import ActionTypes from '../constants'

const cancelBonusHandler = (
  state: BonusModels.BonusState,
  action: BonusModels.BonusActions,
): BonusModels.BonusState => {
  switch (action.type) {
    case ActionTypes.CANCEL_BONUS:
      return {
        ...state,
      }

    case ActionTypes.CANCEL_BONUS_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload.resolved,
      }

    default:
      return state
  }
}

export default cancelBonusHandler
