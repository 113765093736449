import { Accordion, AccordionSummary, withStyles } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { blueMedium, black, white, blueModal, blueNav } = colors

export const accordionTheme = {
  root: {
    backgroundColor: blueModal,
    '&.analytics-detail': {
      backgroundColor: blueMedium,
      borderTop: 'none',
      padding: 5,
    },
  },
}

export const accordionSummaryTheme = {
  root: {
    padding: '0 16px 0 16px',
    '&.Mui-expanded': {
      minHeight: 48,
    },
    '&.analytics-detail': {
      borderRadius: 5,
      backgroundColor: blueMedium,
      padding: '0 16px 0 16px',
    },
  },
  content: {
    fontWeight: 500,
    '&.Mui-expanded': {
      margin: '8px 0',
    },
  },
}

export const accordionDetailsTheme = {
  root: {
    display: 'block',
  },
}

export const AccordionSummaryCustom = withStyles({
  root: {
    borderRadius: 5,
    backgroundColor: blueNav,
    padding: '0 16px 0 16px',
    '&.Mui-expanded': {
      minHeight: 48,
    },
    '&.Mui-focused': {
      backgroundColor: black,
    },
  },
  expandIcon: {
    color: white,
  },
  content: {
    fontSize: '0.8rem',
    fontWeight: 700,
    color: alpha(white, 0.8),
    margin: '7px 0',
    '&.Mui-expanded': {
      margin: '12px 0',
    },
    '& svg': {
      width: '1.2em',
      height: '1.2em',
      marginRight: 0,
    },
    display: 'block',
  },
})(AccordionSummary)

export const AccordionCustom = withStyles({
  root: {
    backgroundColor: blueNav,
    borderRadius: 5,
    boxShadow: '10px 0px 20px -10px rgba(0,0,0,0.64)',
    borderTop: 'none',
  },
})(Accordion)
