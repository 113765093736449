/**
 *
 * User Logs Action constants
 *
 * @action CREATE
 * @action CREATE_SUBSCRIBER
 * @action UPDATE
 * @action UPDATE_SUBSCRIBER
 * @action DELETE
 * @action DELETE_SUBSCRIBER
 * @action GET_ALL
 * @action GET_ALL_SUBSCRIBER
 * @action GET_ONE
 * @action GET_ONE_SUBSCRIBER
 */
enum ActionTypes {
  CREATE_USER_LOG = '@user-log/CREATE_USER_LOG',
  CREATE_USER_LOG_SUBSCRIBER = '@user-log/CREATE_USER_LOG_SUBSCRIBER',

  GET_ALL_USER_LOG = '@user-log/GET_ALL_USER_LOG',
  GET_ALL_USER_LOG_SUBSCRIBER = '@user-log/GET_ALL_USER_LOG_SUBSCRIBER',

  GET_ALL_USER_LOG_CONSOLIDATED = '@user-log/GET_ALL_USER_LOG_CONSOLIDATED',
  GET_ALL_USER_LOG_CONSOLIDATED_SUBSCRIBER = '@user-log/GET_ALL_USER_LOG_CONSOLIDATED_SUBSCRIBER',

  GET_ONE_USER_LOG = '@user-log/GET_ONE_USER_LOG',
  GET_ONE_USER_LOG_SUBSCRIBER = '@user-log/GET_ONE_USER_LOG_SUBSCRIBER',

  GET_ONE_USER_LOG_CONSOLIDATED = '@user-log/GET_ONE_USER_LOG_CONSOLIDATED',
  GET_ONE_USER_LOG__CONSOLIDATED_SUBSCRIBER = '@user-log/GET_ONE_USER_LOG__CONSOLIDATED_SUBSCRIBER',

  SET_FILTERS = '@user-log/SET_FILTERS',

  GET_PRODUCTS = '@user-log/GET_PRODUCTS',
  GET_PRODUCTS_SUBSCRIBER = '@user-log/GET_PRODUCTS_SUBSCRIBER',
}
export default ActionTypes
