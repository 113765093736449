/**
 *
 * Search constants
 *
 */

enum ActionTypes {
  GET_ALL_PAYCARD_REQUEST = '@search/GET_ALL_PAYCARD_REQUEST',
  GET_ALL_PAYCARD_SUCCESS = '@search/GET_ALL_PAYCARD_SUCCESS',
  GET_ALL_PAYCARD_FAILURE = '@search/GET_ALL_PAYCARD_FAILURE',

  SET_PLAYER_SEARCH_PROPS = '@search/SET_PLAYER_SEARCH_PROPS',
  SET_TRANSACTION_SEARCH_PROPS = '@search/SET_TRANSACTION_SEARCH_PROPS',
  SET_SEARCH_TAB = '@search/SET_SEARCH_TAB',
}

export default ActionTypes
