import WithdrawalsTabList from 'state/enums/withdrawals-tabs-list.enum'
import WithdrawalTableColumns from 'state/enums/withdrawal-table-columns.enum'
import { SettingsState } from '../models'

import { emptyUserSettings } from '../utils/get-latest-updated-config.util'

const columnNames = Object.values(WithdrawalTableColumns)

const initialState: SettingsState = {
  hasError: false,
  isLoading: false,
  error: '',
  wasDeleted: false,
  usersSettings: [],
  tabs: {
    [WithdrawalsTabList.PENDING]: {
      currentUserSettings: emptyUserSettings,
      columnNames: columnNames.filter(
        column => column !== WithdrawalTableColumns.PAYMENT_METHOD,
      ),
      firstLoad: true,
    },
    [WithdrawalsTabList.PROCESSED]: {
      currentUserSettings: emptyUserSettings,
      columnNames,
      firstLoad: true,
    },
    [WithdrawalsTabList.PAYMENT]: {
      currentUserSettings: emptyUserSettings,
      columnNames,
      firstLoad: true,
    },
    [WithdrawalsTabList.ALL]: {
      currentUserSettings: emptyUserSettings,
      columnNames,
      firstLoad: true,
    },
  },
  transferColumns: {
    right: columnNames,
    left: [],
  },
  currentTab: WithdrawalsTabList.PENDING,
}

export default initialState
