import * as LimitModels from '../models'
import ActionTypes from '../constants'

const getPendingLimitsHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.GET_PENDING_LIMITS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_PENDING_LIMITS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        pendingLimits:
          action.payload.message.length !== 0
            ? [...action.payload.message]
            : [],
      }

    default:
      return state
  }
}

export default getPendingLimitsHandler
