const templatesOnSite = {
  onSiteNotification: 'On-site Notification',
  createAnOnsite: 'Create an on-site notification',
  variationLabel: 'Message Version:',
  variationTextOnly: 'Variation text only',
  variationWithImage: 'Variation with image',
  onSiteTestHelp:
    'In this section you can send an on-site test notification to one or more player IDs added in the relevant field.',
  onSiteSettingsTitle: 'On-site Notification Settings',
  goToOnSiteTemplates: 'Go To On-site Templates',
  onSiteSummaryAllLanguages: 'All languages',
}

export default templatesOnSite
