import * as AssessmentModels from '../models'
import ActionTypes from '../constants'

const getAssessmentsHandler = (
  state: AssessmentModels.AssessmentState,
  action: AssessmentModels.SocketActions | AssessmentModels.AssessmentActions,
): AssessmentModels.AssessmentState => {
  switch (action.type) {
    case ActionTypes.GET_ASSESSMENTS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ASSESSMENTS_SUBSCRIBER:
      return {
        ...state,
        assessments: action.payload.message,
        isLoading: false,
      }

    case ActionTypes.GET_ASSESSMENTS_EXCEPTION_SUBSCRIBER: {
      return {
        ...state,
        isLoading: false,
        hasError: true,
        assessments: [],
      }
    }

    default:
      return state
  }
}

export default getAssessmentsHandler
