import ActionTypes from '../constants'
import { RoleState, RoleActions } from '../models'

const updateRoleHandler = (
  state: RoleState,
  action: RoleActions,
): RoleState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default updateRoleHandler
