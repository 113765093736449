import ActionTypes from '../constants'
import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'

const resetStateHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.RESET_DOC_STATE:
      return {
        ...state,
        currentDownloadedImage: '',
        documents: [],
        documentImages: [],
        jumioData: [],
        currentDocumentImage: {},
        jumioImages: {
          front: '',
          back: '',
        },
      }
    default:
      return state
  }
}

export default resetStateHandler
