import React from 'react'
import { useSelector } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import history from 'routes/browser-history'
import { AuthSelectors } from 'state/auth'
import { globalUiSelectors } from 'state/ui/global'

import AuthorizedApp from '../authorized-app/index.component'
import UnAuthorizedApp from '../un-authorized-app/index.component'
import FullPageLoader from '../full-page-loader/index.component'

const AuthGuard = () => {
  const { token } = useSelector(AuthSelectors.root)

  const { isLoadingFull } = useSelector(globalUiSelectors.root)

  return (
    <ConnectedRouter history={history}>
      {token ? <AuthorizedApp /> : <UnAuthorizedApp />}
      <FullPageLoader open={isLoadingFull} />
    </ConnectedRouter>
  )
}

export default AuthGuard
