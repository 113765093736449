import * as CoolOffModels from '../models'
import ActionTypes from '../constants'

const getCategoriesHandler = (
  state: CoolOffModels.CoolOffState,
  action: CoolOffModels.SocketActions,
): CoolOffModels.CoolOffState => {
  switch (action.type) {
    case ActionTypes.GET_CATEGORIES_SUBSCRIBER:
      return {
        ...state,
        categories:
          action.payload.resolved && action.payload.gameCategories.length !== 0
            ? [...action.payload.gameCategories]
            : [],
      }

    default:
      return state
  }
}

export default getCategoriesHandler
