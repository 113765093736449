import { alpha } from '@material-ui/core/styles/colorManipulator'
import {
  AvenirLTStdBlack,
  AvenirLTStdBlackOblique,
  AvenirLTStdBook,
  AvenirLTStBookOblique,
  AvenirLTStdHeavy,
  AvenirLTStdHeavyOblique,
  AvenirLTStdLight,
  AvenirLTStdLightOblique,
  AvenirLTStdMedium,
  AvenirLTStdMediumOblique,
} from '../assets/fonts'
import { colors } from './colors.theme'

const { blue, grayDark, green, blueMedium, blueNav, red, blue3C } = colors

export const cssbaseline = {
  '@font-face': [
    AvenirLTStdBlack,
    AvenirLTStdBlackOblique,
    AvenirLTStdBook,
    AvenirLTStBookOblique,
    AvenirLTStdHeavy,
    AvenirLTStdHeavyOblique,
    AvenirLTStdLight,
    AvenirLTStdLightOblique,
    AvenirLTStdMedium,
    AvenirLTStdMediumOblique,
  ],
  '*::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
  },
  '*::-webkit-scrollbar:vertical': {
    width: 4,
  },
  '*::-webkit-scrollbar:horizontal': {
    height: 4,
  },
  '*::-webkit-scrollbar-thumb': {
    background: blue,
    borderRadius: 16,
    opacity: '1',
  },
  '*::-webkit-scrollbar-track': {
    background: grayDark,
    borderRadius: 16,
    opacity: '0.3',
  },
  'textarea:focus': {
    outline: 'none',
  },
  '.disabledDiv': {
    opacity: 0.3,
    'pointer-events': 'none',
  },
  '.MuiAutocomplete-input': {
    '&::placeholder': {
      fontStyle: 'italic' as 'italic',
    },
  },
  '.MuiAutocomplete-paper': {
    backgroundColor: `${blueNav}!important`,
    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)!important`,
  },
  '.MuiAutocomplete-listbox': {
    padding: '0!important',
  },
  '.MuiAutocomplete-option': {
    fontSize: 14,
  },
  '.MuiAutocomplete-inputRoot': {
    overflow: 'hidden',
    borderRadius: 4,
    '&.Mui-focused': {
      height: 'auto',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 1,
      background: `${blueMedium}!important`,
    },
  },
  '.MuiAutocomplete-root': {
    '&.MuiAutocomplete-hasClearIcon, &.MuiAutocomplete-hasPopupIcon': {
      '& .MuiAutocomplete-inputRoot ': {
        padding: '0 32px 0 0!important',
      },
    },
    '&.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon': {
      '& .MuiAutocomplete-inputRoot ': {
        padding: '0 64px 0 0!important',
      },
    },
    '&.green': {
      '& .MuiChip-root': {
        backgroundColor: green,
      },
    },
    '&.red': {
      '& .MuiChip-root': {
        backgroundColor: red,
      },
    },
    '&.MuiAutocomplete-hasPopupIcon': {
      '& .MuiAutocomplete-input ': {
        paddingRight: '15px!important',
      },
    },
    '& .MuiFormControl-root': {
      minHeight: 37,
    },

    '& .MuiAutocomplete-tag': {
      margin: '6px 4px',
      maxHeight: 24,
      lineHeight: 1,
      '&.MuiChip-root': {
        height: 24,
      },
    },
    '& .MuiAutocomplete-endAdornment': {
      top: ' 50%',
      transform: 'translateY(-50%)',
    },
    '& .MuiAutocomplete-popupIndicator': {
      '&.MuiIconButton-root svg': {
        fontSize: '1.5rem',
      },
      '&.Mui-disabled': {
        display: 'none',
      },
    },
  },
  '.MuiSkeleton-root': {
    backgroundColor: `${alpha(blue3C, 0.9)}!important`,
  },
  '#label-alerts-popup': {
    '& .MuiAutocomplete-option': {
      paddingTop: 0,
      paddingBottom: 0,
      '& > .close': {
        display: 'none !important',
      },
    },
  },
  '.MuiBox-root': {
    '&.refresh :hover': {
      color: alpha(green, 0.6),
    },
  },
  '.MuiAvatarGroup-small': {
    alignItems: 'center',
    '& .MuiAvatar-root': {
      width: 30,
      height: 30,
    },
  },
  '.table-date-filter': {
    '& .MuiFormControl-root': {
      '& .MuiFormControl-datePicker': {
        '& .MuiInput-root': {
          maxWidth: 152,
        },
      },
    },
  },
}
