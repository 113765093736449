import { createReducer } from 'typesafe-actions'
import { UserState, UserActions, UserSocketActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getAllUserHandler from './get-all.handler'
import updateUserHandler from './update.handler'

import createUserHandler from './create.handler'
import deleteUserHandler from './delete.handler'
import createResetPasswordTokenHandler from './create-reset-password-token.handler'

import userResetPasswordHandler from './reset-password.handler'
import getLoggedInHandler from './get-logged-in.handler'
import userSubscribersHandler from './subscribers.handler'

import getAllOperatorHandler from './get-all-operators.handler'
import getOneOperatorHandler from './get-one-operator.handler'
import getShortcutsHandler from './shortcuts-get.handler'

import createShortcutsHandler from './shortcuts-create.handler'
import updateShortcutsHandler from './shortcuts-update.handler'
import deleteShortcutsHandler from './shortcuts-delete.handler'

/**
 * Creates a User Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const userReducer = createReducer<UserState, UserActions | UserSocketActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.SET_FILTERS,
    ],
    getAllUserHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    updateUserHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
      ActionTypes.CREATE_CANCEL,
    ],
    createUserHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_REQUEST,
      ActionTypes.DELETE_SUCCESS,
      ActionTypes.DELETE_FAILURE,
    ],
    deleteUserHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_RESET_PASSWORD_TOKEN_REQUEST,
      ActionTypes.CREATE_RESET_PASSWORD_TOKEN_SUCCESS,
      ActionTypes.CREATE_RESET_PASSWORD_TOKEN_FAILURE,
      ActionTypes.CREATE_RESET_PASSWORD_TOKEN_CANCEL,
    ],
    createResetPasswordTokenHandler,
  )
  .handleAction(
    [
      ActionTypes.RESET_PASSWORD_REQUEST,
      ActionTypes.RESET_PASSWORD_SUCCESS,
      ActionTypes.RESET_PASSWORD_FAILURE,
    ],
    userResetPasswordHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_LOGGED_IN_REQUEST,
      ActionTypes.GET_LOGGED_IN_SUCCESS,
      ActionTypes.GET_LOGGED_IN_FAILURE,
      ActionTypes.RESET_USER_STATE,
    ],
    getLoggedInHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_SUBSCRIBER,
      ActionTypes.UPDATE_SUBSCRIBER,
      ActionTypes.UPDATE_STATUS_SUBSCRIBER,
      ActionTypes.DELETE_SUBSCRIBER,
    ],
    userSubscribersHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_OPERATORS_REQUEST,
      ActionTypes.GET_OPERATORS_SUCCESS,
      ActionTypes.GET_OPERATORS_FAILURE,
    ],
    getAllOperatorHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_OPERATOR_REQUEST,
      ActionTypes.GET_OPERATOR_SUCCESS,
      ActionTypes.GET_OPERATOR_FAILURE,
    ],
    getOneOperatorHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_SHORTCUTS_REQUEST,
      ActionTypes.GET_SHORTCUTS_SUCCESS,
      ActionTypes.GET_SHORTCUTS_FAILURE,
    ],
    getShortcutsHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_SHORTCUTS_REQUEST,
      ActionTypes.CREATE_SHORTCUTS_SUCCESS,
      ActionTypes.CREATE_SHORTCUTS_FAILURE,
    ],
    createShortcutsHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_SHORTCUTS_REQUEST,
      ActionTypes.UPDATE_SHORTCUTS_SUCCESS,
      ActionTypes.UPDATE_SHORTCUTS_FAILURE,
    ],
    updateShortcutsHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_SHORTCUTS_REQUEST,
      ActionTypes.DELETE_SHORTCUTS_SUCCESS,
      ActionTypes.DELETE_SHORTCUTS_FAILURE,
    ],
    deleteShortcutsHandler,
  )

export default userReducer
