const frequencyCap = {
  frequencyCapList: 'Frequency Cap List',
  addFrequencyCap: 'Add Frequency Cap',
  actionFrecuencyCap: {
    edit: 'Edit Frecuency Cap',
    create: 'Create Frecuency Cap',
  },
  frequencyCapDescription:
    'With the following setting, you can limit the amount of messages that a player can receive per communication channel, in a specific timeframe',
  frequencyCapChannelDescription:
    'Customers can receive up to {{limit}} {{channel}} {{period}}',
  frequencyCapPeriod: {
    DAILY: 'Per Day',
    WEEKLY: 'Per Week',
    MONTHLY: 'Per Month',
  },
}

export default frequencyCap
