/**
 *
 * Bonus Engine constants
 *
 */

enum ActionTypes {
  GET_BONUSES = '@bonus-engine/GET_BONUSES',
  GET_BONUSES_SUBSCRIBER = '@bonus-engine/GET_BONUSES_SUBSCRIBER',

  GET_CAMPAIGNS = '@bonus-engine/GET_CAMPAIGNS',
  GET_CAMPAIGNS_SUBSCRIBER = '@bonus-engine/GET_CAMPAIGNS_SUBSCRIBER',

  CREATE_CAMPAIGN = '@bonus-engine/CREATE_CAMPAIGN',
  CREATE_CAMPAIGN_SUBSCRIBER = '@bonus-engine/CREATE_CAMPAIGN_SUBSCRIBER',

  UPDATE_CAMPAIGN = '@bonus-engine/UPDATE_CAMPAIGN',
  UPDATE_CAMPAIGN_SUBSCRIBER = '@bonus-engine/UPDATE_CAMPAIGN_SUBSCRIBER',

  GET_ONE_CAMPAIGN = '@bonus-engine/GET_ONE_CAMPAIGN',
  GET_ONE_CAMPAIGN_SUBSCRIBER = '@bonus-engine/GET_ONE_CAMPAIGN_SUBSCRIBER',

  FORMAT_CURRENT_CAMPAIGN = '@bonus-engine/FORMAT_CURRENT_CAMPAIGN',
  FORMAT_CURRENT_BONUS = '@bonus-engine/FORMAT_CURRENT_BONUS',
  SET_CHOSEN_BONUSES = '@bonus-engine/SET_CHOSEN_BONUSES',
  SET_CURRENT_BONUS_OFFER = '@bonus-engine/SET_CURRENT_BONUS_OFFER',
  UPDATE_CURRENT_BONUS_OFFER = '@bonus-engine/UPDATE_CURRENT_BONUS_OFFER',

  DELETE_CAMPAIGN = '@bonus-engine/DELETE_CAMPAIGN',
  DELETE_CAMPAIGN_SUBSCRIBER = '@bonus-engine/DELETE_CAMPAIGN_SUBSCRIBER',

  DELETE_OFFER = '@bonus-engine/DELETE_OFFER',
  DELETE_OFFER_SUBSCRIBER = '@bonus-engine/DELETE_OFFER_SUBSCRIBER',

  SAVE_OFFER = '@bonus-engine/SAVE_OFFER',
  SAVE_OFFER_V2 = '@bonus-engine/SAVE_OFFER_V2',
  SAVE_OFFER_SUBSCRIBER = '@bonus-engine/SAVE_OFFER_SUBSCRIBER',

  GET_BONUS_ENGINE_GAMES = '@bonus-engine/GET_BONUS_ENGINE_GAMES',
  GET_BONUS_ENGINE_GAMES_SUBSCRIBER = '@bonus-engine/GET_BONUS_ENGINE_GAMES_SUBSCRIBER',

  GET_GAME_CATEGORIES = '@bonus-engine/GET_GAME_CATEGORIES',
  GET_GAME_CATEGORIES_SUBSCRIBER = '@bonus-engine/GET_GAME_CATEGORIES_SUBSCRIBER',

  GET_GAME_PROVIDERS = '@bonus-engine/GET_GAME_PROVIDERS',
  GET_GAME_PROVIDERS_SUBSCRIBER = '@bonus-engine/GET_GAME_PROVIDERS_SUBSCRIBER',

  GET_ALL_BONUS_ENGINE_GAMES = '@bonus-engine/GET_ALL_BONUS_ENGINE_GAMES',
  GET_ALL_BONUS_ENGINE_GAMES_SUBSCRIBER = '@bonus-engine/GET_ALL_BONUS_ENGINE_GAMES_SUBSCRIBER',

  GET_WAGERING_CONTRIBUTIONS = '@bonus-engine/GET_WAGERING_CONTRIBUTIONS',
  GET_WAGERING_CONTRIBUTIONS_SUBSCRIBER = '@bonus-engine/GET_WAGERING_CONTRIBUTIONS_SUBSCRIBER',

  DUPLICATE_BONUS = '@bonus-engine/DUPLICATE_BONUS',
  DUPLICATE_BONUS_SUBSCRIBER = '@bonus-engine/DUPLICATE_BONUS_SUBSCRIBER',

  SET_OFFER_PAGINATION = '@bonus-engine/SET_OFFER_PAGINATION',
  SET_CAMPAIGNS_PAGINATION = '@bonus-engine/SET_CAMPAIGNS_PAGINATION',

  GET_FREE_GAMES_SETTINGS = '@bonus-engine/GET_FREE_GAMES_SETTINGS',
  GET_FREE_GAMES_SETTINGS_SUBSCRIBER = '@bonus-engine/GET_FREE_GAMES_SETTINGS_SUBSCRIBER',

  SET_LANGUAGES_SELECTED = '@bonus-engine/SET_LANGUAGES_SELECTED',

  RESET_CURRENT_BONUS = '@bonus-engine/RESET_CURRENT_BONUS',
}

export default ActionTypes
