import { Dispatch, AnyAction, MiddlewareAPI } from 'redux'
import { isPast } from 'date-fns'
import { logoutAction } from 'state/auth/actions'

import WebSocketFacade from 'services/websockets/websocket.facade'
import WS_ROUTES from 'state/websocket.routes'
import { wsDisconnected, gatewayConnected } from './actions'

import ActionTypes from './constants'
import { isGatewayRegistered, isHandlerNotInstalled } from './utils'

type FacadeGatewayType = {
  [namespace: string]: WebSocketFacade
}

const Gateways: FacadeGatewayType = {}

export type SocketIoCallback = {
  _callbacks?: any
}

/**
 *
 * Middleware to handle
 * socket events
 * @returns a new {Dispatch} function
 */
const socketMiddleware = () => {
  let socket:
    | (SocketIOClient.Socket & SocketIoCallback)
    | WebSocket
    | null
    | undefined

  let webSocketFacade: WebSocketFacade

  const initialize = async (action: AnyAction, store: MiddlewareAPI) => {
    let socketInstance: WebSocketFacade
    const options = {
      namespace: action.payload?.namespace,
      host: action.payload?.host,
      port: action.payload?.port,
      token: action.payload?.token,
      isIO: action.payload?.isIO,
    }

    if (action.payload.isIO) {
      // Connection via SocketIo
      webSocketFacade = new WebSocketFacade(options)

      webSocketFacade.setIsSocketIo()
    } else {
      // Connection via WebSockets
      webSocketFacade = new WebSocketFacade(options)

      // const { namespace } = webSocketFacade?.socketGatewayOptions
    }

    try {
      socketInstance = webSocketFacade
    } catch (error) {
      throw new Error(error)
    }

    return socketInstance
  }

  /**
   * @des disconnect event handler
   * Dispatch wsDisconnected {function}
   */
  const onClose = (store: MiddlewareAPI) => {
    webSocketFacade?.close()
    store.dispatch(wsDisconnected(false))
  }

  /**
   * @des connection open event handler
   * Dispatch wsConnected {function}
   */
  const onOpen = async (
    store: MiddlewareAPI,
    action: AnyAction,
    wsFacade: WebSocketFacade,
  ) => {
    Gateways[action.payload.namespace as string] = wsFacade

    if (wsFacade) {
      store.dispatch(
        gatewayConnected({
          isIO: action.payload.isIO,
          namespace: action.payload?.namespace,
          isConnected: true,
          error: '',
        }),
      )
    }
  }

  /**
   * @des Handles the connection states for the socket
   * @param action to dispatch
   * @param store MiddlewareAPI to interact with
   */
  const connectionStateHandler = async (
    action: AnyAction,
    store: MiddlewareAPI,
    // eslint-disable-next-line consistent-return
  ) => {
    let webSocketFacadeInstance
    switch (action.type) {
      case ActionTypes.WS_REGISTER_GATEWAY:
        webSocketFacadeInstance = await initialize(action, store)

        if (webSocketFacadeInstance) {
          onOpen(store, action, webSocketFacadeInstance)
        }
        break

      case ActionTypes.WS_DISCONNECT: // TODO: CHECK FOR DISCONNECTED
        if (socket !== null) {
          onClose(store)
        }
        break

      default:
        return null
    }
  }

  return (store: MiddlewareAPI) => (next: Dispatch<AnyAction>) => (
    action: AnyAction,
    // eslint-disable-next-line consistent-return
  ) => {
    const { event, handle } = action

    const { gateways } = store.getState().websockets

    const wasFound =
      action?.payload?.namespace &&
      isGatewayRegistered(gateways, action?.payload?.namespace)

    // Connects only if the namespace doesn't exist
    if (!wasFound && !event) {
      connectionStateHandler(action, store)
    }

    if (typeof action === 'function') {
      return next(action)
    }

    if (!event) {
      return next(action)
    }

    let eventHandler = handle
    if (typeof eventHandler === 'string') {
      eventHandler = (response: any) =>
        store.dispatch({ type: action?.handle, response })
    }
    // get aut state
    const { auth } = store.getState()

    // prevent request if token has expired
    if (isPast(new Date(auth?.userSession?.exp * 1000))) {
      store.dispatch(logoutAction())
    }

    const currentGateway = Gateways[action.meta as string]

    if (currentGateway?.isSocketIo && action?.handler) {
      if (
        !isHandlerNotInstalled(currentGateway.socketClient, action.event) &&
        action.overwrite
      ) {
        currentGateway.socketClient._callbacks[`$${action.event}`][0] =
          action.handler
      }
      if (isHandlerNotInstalled(currentGateway.socketClient, action.event)) {
        currentGateway.socketClient.on(action.event, action.handler)
      }
      currentGateway.socketClient.on(WS_ROUTES.ERROR, action.handler)
    }

    if (handle) currentGateway?.on(action)

    if (action.emit) {
      currentGateway?.emit(action)

      store.dispatch({ type: action.type })
    }
  }
}

export default socketMiddleware
