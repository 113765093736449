enum PlayerDocumentType {
  PASSPORT = 1,
  ID = 2,
  DRIVERS_LICENSE = 3,
  PROOF_OF_ADDRESS = 4,
  CC_COPY = 5,
  BANK_STATEMENT = 6,
  PAYCARD_COPY = 7,
  UTILITY_BILL = 8,
  LOCAL_AUTHORITY_TAX_BILL = 9,
  OTHER = 10,
  JUMIO = 11
}

export default PlayerDocumentType
