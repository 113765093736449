import { Badge, withStyles } from '@material-ui/core'
import { alpha, darken } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { orange, blue, black, white, red, green } = colors

export const badgeTheme = {
  badge: {
    color: white,
    '.blue &': {
      backgroundColor: blue,
    },
    '.orange &': {
      backgroundColor: orange,
    },
    '.red &': {
      backgroundColor: red,
    },
    '.green &': {
      backgroundColor: green,
    },
    '.border-white &': {
      border: `1px solid ${white}`,
    },
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
}

export const BadgeAlert = withStyles(() => ({
  root: {
    backgroundColor: black,
    border: `solid 1px ${alpha(white, 0.3)}`,
    borderRadius: 4,
    padding: '4px 8px',
    margin: 10,
    cursor: 'pointer',
  },
  badge: {
    backgroundColor: darken(orange, 0.7),
    border: `solid 1px ${orange}`,
    color: orange,
    fontWeight: 700,
    minWidth: 35,
    padding: '0 8px',
  },
  anchorOriginTopRightRectangular: {
    transform: 'scale(1) translate(50%, -80%)',
  },
}))(Badge)

export const BadgeChip = withStyles(() => ({
  root: {
    alignItems: 'center',
  },
  badge: {
    transform: 'none',
    position: 'static',
    padding: '0 6px',
    marginLeft: 8,
    height: 17,
  },
}))(Badge)
