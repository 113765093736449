import ActionTypes from '../constants'
import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'

const documentGeneralHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.SET_DOCUMENT_FILTERS:
      return {
        ...state,
        paginationDocuments: {
          ...state.paginationDocuments,
          ...action.payload,
          pages: action.payload.pages || Math.ceil(state.paginationDocuments.total / (action.payload.limit || state.paginationDocuments.limit)),
        }
      }

    default:
      return state
  }
}

export default documentGeneralHandler
