import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  Button,
  Box,
  ClickAwayListener,
  Typography,
  Divider,
  Drawer,
} from '@material-ui/core/'

import { Add } from '@material-ui/icons'
import SidebarIds from 'constants/sidebar/sidebar.constant'
import routes from 'routes/index.routes'

import RoleType from 'state/enums/role.enum'
import SidebarId from 'state/enums/sidebar-id.enum'
import { ApplicationState } from 'state/root.models'

import { Logo } from 'utils/icons'
import PopperComponent from 'views/components/popper/index.component'
import MenuComponent from './components/menu/index.component'

import useSidebarActions from './hooks/use-sidebar-actions.hook'
import useSidebarUI from './hooks/use-sidebar-ui.hook'
import ShortcutAddComponent from './components/add-shortcut/index.component'

import CreateListComponent from './components/create-list.component'
import UiFeedbackComponent from './components/ui-feedback/index.component'
import useSidebarShortcuts from './hooks/use-sidebar-shortcuts.hook'

import ShortcutsComponent from './components/shortcuts/index.component'
import useStyles from './style'
import useSidebarLinks from './hooks/use-sidebar-links.hook'

const SidebarComponent = () => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { REACT_APP_ENV: env } = process.env

  const { userSession } = useSelector(({ auth }: ApplicationState) => auth)

  const { setSidebarIds, getShortcuts, sidebarIds } = useSidebarActions()

  const {
    state,
    handleOnChange,
    setFormValues,
    disable,
    handleAddShortcut,
    handleUpdateShortcut,
    handleDeleteShortcut,
    handleResetAddShortcut,
    handleShortcutAddOpen,
    handleShortcutEditOpen,
    handleShortcutAddClose,
    shortcutPopper,
    shortcutPopperAnchor,
    handleShortcutMenuOpen,
    handleShortcutMenuClose,
    shortcutMenuState,
  } = useSidebarShortcuts()

  const {
    openDrawer,
    handleDrawerOpen,
    handleDrawerClose,
    handleDrawerEnter,
    handleDrawerLeave,
    setArrowRef,
    modifiers,
    anchorCreate,
    handleCreateOpen,
    handleCreateClose,
  } = useSidebarUI()

  useEffect(() => {
    let ids: SidebarId[] = []
    userSession?.role.forEach((role: RoleType) => {
      if (SidebarIds[role]) {
        ids = [...ids, ...SidebarIds[role].ids]
      }
    })
    setSidebarIds(ids)
    if (userSession?._id !== '') {
      getShortcuts()
    }
  }, [userSession]) // eslint-disable-line

  const handleCloseShortcut = () => {
    handleResetAddShortcut()
    handleShortcutAddClose()
  }

  const handleCloseDrawer = () => {
    if (openDrawer) {
      handleCloseShortcut()
      handleDrawerClose()
    }
  }

  const linksCreateFiltered =
    useSidebarLinks().linksCreate.filter(
      (item: any) => sidebarIds.includes(item.id) && item.visible,
    ) || []

  const visibleCreateButton =
    linksCreateFiltered.length > 0 &&
    !(
      linksCreateFiltered.length === 1 &&
      linksCreateFiltered[0].id === SidebarId.USER_MANAGEMENT
    )

  // !VALIDATION: env
  const label = env === 'test' ? 'DEV' : env === 'stage' ? 'STG' : ''

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseDrawer}>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClosed]: !openDrawer,
            }),
          }}
          open={openDrawer}
          onMouseEnter={handleDrawerEnter}
          onMouseLeave={handleDrawerLeave}
          onFocus={() => {}}
        >
          <Box px="20px" pb={1}>
            <Box
              display="flex"
              flexWrap="nowrap"
              alignItems="center"
              pr="7px"
              pl="15px"
            >
              {openDrawer ? (
                <Box height={32}>
                  <Link to={routes.HOME}>
                    <Logo className={classes.logo} />
                  </Link>
                </Box>
              ) : (
                <Logo onClick={handleDrawerOpen} className={classes.logo} />
              )}
              <Typography
                variant="h6"
                className={clsx([classes.textLogo, classes.fadeIn])}
              >
                GaminGenius&trade;
              </Typography>
            </Box>
            <Box clone my="16px!important">
              <Divider light />
            </Box>
            {visibleCreateButton && (
              <Box mb={1} ml="10px">
                <Button
                  id="create"
                  variant="outlined"
                  className={classes.buttonCreate}
                  size="small"
                  startIcon={<Add />}
                  onClick={handleCreateOpen}
                >
                  <div className={classes.buttonCreateText}>{t('create')}</div>
                </Button>
              </Box>
            )}

            <CreateListComponent
              anchorCreate={anchorCreate}
              handleCreateClose={handleCreateClose}
              handleDrawerClose={handleDrawerClose}
            />
          </Box>
          {env !== 'prod' && (
            <Typography className={classes.labelEnv} variant="caption">
              {label}
            </Typography>
          )}

          <Box height="100%" overflow="hidden auto" py={2} px="20px" mb={3}>
            <PopperComponent
              arrow
              id="sidebar"
              arrowRef={setArrowRef}
              open={
                (shortcutPopper.id === 'shortcutEdit' ||
                  shortcutPopper.id === 'shortcutAdd') &&
                shortcutPopper.open
              }
              anchorEl={shortcutPopperAnchor}
              placement="right"
              modifiers={modifiers}
              handleClickAway={handleCloseShortcut}
              padding={2}
              minWidth={300}
              maxHeight={350}
            >
              <ShortcutAddComponent
                state={state}
                handleOnChange={handleOnChange}
                disable={disable}
                handleAddShortcut={handleAddShortcut}
                handleUpdateShortcut={handleUpdateShortcut}
                handleDeleteShortcut={handleDeleteShortcut}
                handleResetAddShortcut={handleResetAddShortcut}
                shortcutPopper={shortcutPopper}
                handleShortcutAddClose={handleShortcutAddClose}
              />
            </PopperComponent>

            <MenuComponent
              openDrawer={openDrawer}
              handleDrawerClose={handleDrawerClose}
            />

            <ShortcutsComponent
              handleDrawerClose={handleDrawerClose}
              handleShortcutAddOpen={handleShortcutAddOpen}
              handleShortcutEditOpen={handleShortcutEditOpen}
              handleDeleteShortcut={handleDeleteShortcut}
              handleShortcutMenuOpen={handleShortcutMenuOpen}
              handleShortcutMenuClose={handleShortcutMenuClose}
              shortcutMenuState={shortcutMenuState}
              setFormValues={setFormValues}
              openDrawer={openDrawer}
            />
          </Box>

          {/* 
          // TODO: uncomment when help page is implemented
          <Box px="20px" mt="auto">
            <Box clone mt="4px!important" mb="8px!important">
              <Divider light />
            </Box>
            <ListItem
              button
              disableGutters
              component={NavLink}
              to="/help"
              className={classes.link}
              onClick={handleDrawerClose}
            >
              <ListItemIcon>
                <ContactSupport />
              </ListItemIcon>

              <ListItemText
                primary={t('sidebar.help')}
                className={classes.linkClosed}
              />
              <ListItemText
                primary={t('sidebar.helpSupport')}
                className={classes.linkOpen}
              />
            </ListItem> 
          </Box>
          */}
        </Drawer>
      </ClickAwayListener>
      <UiFeedbackComponent />
    </>
  )
}

export default SidebarComponent
