import ActionTypes from '../constants'
import { PlayerCardKYCUiActions, PlayerCardKYCStateUI } from '../models'

const uiHandler = (
  state: PlayerCardKYCStateUI,
  action: PlayerCardKYCUiActions,
): PlayerCardKYCStateUI => {
  switch (action.type) {
    case ActionTypes.SET_DOCUMENT_FORM_MODE:
      return {
        ...state,
        documentFormMode: action.payload,
      }

    case ActionTypes.TOGGLE_DOCUMENT_DIALOG:
      return {
        ...state,
        isDocumentDialogOpen: action.payload,
      }

    case ActionTypes.TOGGLE_AML_EDIT:
      return {
        ...state,
        amlUI: {
          ...state.amlUI,
          ...action.payload,
        },
      }

    case ActionTypes.TOGGLE_TELEDATA_DIALOG:
      return {
        ...state,
        isTeledataDialogOpen: action.payload,
      }

    case ActionTypes.TOGGLE_AML_DIALOG:
      return {
        ...state,
        isAmlDialogOpen: action.payload,
      }

    case ActionTypes.TOGGLE_PEP_DIALOG:
      return {
        ...state,
        isPEPDialogOpen: action.payload,
      }

    default:
      return state
  }
}

export default uiHandler
