enum Languages {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
  IT = 'IT',
  PT = 'PT',
  TR = 'TR',
}

export default Languages
