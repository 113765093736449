/**
 *
 * Tags constants
 *
 */

enum ActionTypes {
  SET_CURRENT_TAG = '@tag/SET_CURRENT_TAG',
  SET_CURRENT_CATEGORY = '@tag/SET_CURRENT_CATEGORY',

  SET_FILTERS = '@tag/SET_FILTERS',

  SET_TAGS_CRM = '@tag/SET_TAGS_CRM',
  SET_TAGS_CRM_SUBSCRIBER = '@tag/SET_TAGS_CRM_SUBSCRIBER',

  REMOVE_TAGS_CRM = '@tag/REMOVE_TAGS_CRM',
  REMOVE_TAGS_CRM_SUBSCRIBER = '@tag/REMOVE_TAGS_CRM_SUBSCRIBER',

  GET_TAGS_CRM = '@tag/GET_TAGS_CRM',
  GET_TAGS_CRM_SUBSCRIBER = '@tag/GET_TAGS_CRM_SUBSCRIBER',

  CREATE_TAG = '@tag/CREATE_TAG',
  CREATE_TAG_SUBSCRIBER = '@tag/CREATE_TAG_SUBSCRIBER',

  UPDATE_TAG = '@tag/UPDATE_TAG',
  UPDATE_TAG_SUBSCRIBER = '@tag/UPDATE_TAG_SUBSCRIBER',

  DELETE_TAG = '@tag/DELETE_TAG',
  DELETE_TAG_SUBSCRIBER = '@tag/DELETE_TAG_SUBSCRIBER',

  CREATE_CATEGORY = '@tag/CREATE_CATEGORY',
  CREATE_CATEGORY_SUBSCRIBER = '@tag/CREATE_CATEGORY_SUBSCRIBER',

  UPDATE_CATEGORY = '@tag/UPDATE_CATEGORY',
  UPDATE_CATEGORY_SUBSCRIBER = '@tag/UPDATE_CATEGORY_SUBSCRIBER',

  DELETE_CATEGORY = '@tag/DELETE_CATEGORY',
  DELETE_CATEGORY_SUBSCRIBER = '@tag/DELETE_CATEGORY_SUBSCRIBER',

  GET_ALL_TAGS_BY_CATEGORY = '@campaign/GET_ALL_TAGS_BY_CATEGORY',
  GET_ALL_TAGS_BY_CATEGORY_SUBSCRIBER = '@campaign/GET_ALL_TAGS_BY_CATEGORY_SUBSCRIBER',

  GET_ALL_TAGS = '@tag/GET_ALL_TAGS',
  GET_ALL_TAGS_SUBSCRIBER = '@tag/GET_ALL_TAGS_SUBSCRIBER',

  GET_ALL_CATEGORIES = '@tag/GET_ALL_CATEGORIES',
  GET_ALL_CATEGORIES_SUBSCRIBER = '@tag/GET_ALL_CATEGORIES_SUBSCRIBER',

  BULK_ASSIGN_TAG = '@tag/BULK_ASSIGN_TAG',
  BULK_REMOVE_TAG = '@tag/BULK_REMOVE_TAG',
  BULK_TAG_ACTION_SUBSCRIBER = '@tag/BULK_TAG_ACTION_SUBSCRIBER',

  GET_TAG_EXCEPTION_SUBSCRIBER = '@tag/GET_TAG_EXCEPTION_SUBSCRIBER',
  GET_CRM_TAG_EXCEPTION_SUBSCRIBER = '@tag/GET_CRM_TAG_EXCEPTION_SUBSCRIBER',
  GET_CATEGORY_EXCEPTION_SUBSCRIBER = '@tag/GET_CATEGORY_EXCEPTION_SUBSCRIBER',

  SET_TAG_DIALOG = '@tag/SET_TAG_DIALOG',

  SET_TAG_TYPE = '@tag/SET_TAG_TYPE',

  SET_PLAYER_TAGS_PAGINATION = '@tag/SET_PLAYER_TAGS_PAGINATION',

  GET_LINKED_TAG_PLAYERS = '@tag/GET_LINKED_TAG_PLAYERS',
  GET_LINKED_TAG_PLAYERS_SUBSCRIBER = '@tag/GET_LINKED_TAG_PLAYERS_SUBSCRIBER',

  GET_PLAYERS_BY_IDS = '@tag/GET_PLAYERS_BY_IDS',
  GET_PLAYERS_BY_IDS_SUBSCRIBER = '@tag/GET_PLAYERS_BY_IDS_SUBSCRIBER',
  SET_TAG_PLAYERS_FILTERS = '@tag/SET_TAG_PLAYERS_FILTERS',

  SET_LOADING = '@tag/SET_LOADING',
  FORMAT_TAG_PLAYERS = '@tag/FORMAT_TAG_PLAYERS',
  SET_TAG_PLAYERS_ID = '@tag/SET_TAG_PLAYERS_ID',
}
export default ActionTypes
