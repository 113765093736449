import TriggerBonusType from 'state/enums/trigger-bonus.enum'
import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const getBonusesHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.GET_BONUSES:
      return {
        ...state,
      }

    case ActionTypes.GET_BONUSES_SUBSCRIBER:
      return {
        ...state,
        bonuses:
          action.payload?.payload?.data?.filter(
            item =>
              item.active &&
              Object.values(TriggerBonusType).includes(
                (item.triggerType as unknown) as TriggerBonusType,
              ),
          ) || [],
      }

    default:
      return state
  }
}

export default getBonusesHandler
