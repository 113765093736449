import React from 'react'
import useSnackbar from 'hooks/ui/use-snackbar.hook'
import SnackbarWrapper from 'views/components/snackbar/index.component'

const UiFeedbackComponent = () => {
  const { snackbar, closeHandler } = useSnackbar({})

  return (
    <>
      <SnackbarWrapper
        message={snackbar.message}
        open={snackbar.open}
        handleClose={closeHandler}
        variant={snackbar.variant}
        automaticClose
      />
    </>
  )
}

export default UiFeedbackComponent
