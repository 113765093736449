/**
 *
 * Assessments constants
 *
 */

enum ActionTypes {
  GET_ASSESSMENTS = '@assessment/GET_ASSESSMENTS',
  GET_ASSESSMENTS_SUBSCRIBER = '@assessment/GET_ASSESSMENTS_SUBSCRIBER',
  GET_ASSESSMENTS_EXCEPTION_SUBSCRIBER = '@assessment/GET_ASSESSMENTS_EXCEPTION_SUBSCRIBER',

  GET_ASSESSMENTS_QUESTIONS = '@assessment/GET_ASSESSMENTS_QUESTIONS',
  GET_ASSESSMENTS_QUESTIONS_SUBSCRIBER = '@assessment/GET_ASSESSMENTS_QUESTIONS_SUBSCRIBER',
  GET_ASSESSMENTS_QUESTIONS_EXCEPTION_SUBSCRIBER = '@assessment/GET_ASSESSMENTS_QUESTIONS_EXCEPTION_SUBSCRIBER',

}

export default ActionTypes
