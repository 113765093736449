import CampaignStatusType from 'state/enums/campaign-status.enum'
import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const campaignGetOneHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_REQUEST:
      return {
        ...state,
        currentCampaign: {
          isDeleted: false,
          _id: '',
          name: '',
          description: '',
          statusCampaign: CampaignStatusType.INDESIGN,
          targetGroup: [],
          trigger: 0,
          channel: 0,
          createdBy: '',
          companyId: '',
          createdAt: '',
          updatedAt: '',
        },
      }

    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        currentCampaign: action.payload,
      }

    case ActionTypes.GET_ONE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_CAMPAIGN:
      return {
        ...state,
        currentCampaign: {
          isDeleted: false,
          _id: '',
          name: '',
          description: '',
          statusCampaign: CampaignStatusType.INDESIGN,
          targetGroup: [],
          trigger: 0,
          channel: 0,
          createdBy: '',
          companyId: '',
          createdAt: '',
          updatedAt: '',
        },
      }

    default:
      return state
  }
}

export default campaignGetOneHandler
