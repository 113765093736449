import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const getOneOperatorHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.GET_OPERATOR_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.GET_OPERATOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentOperator: action.payload,
      }

    case ActionTypes.GET_OPERATOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default getOneOperatorHandler
