import { call, put } from 'redux-saga/effects'
import Container from 'typedi'
import FileStorageService from 'services/image-issue/index.service'

import { setLoadingFullAction } from 'state/ui/global/actions'
import DocumentFileFormat from 'state/enums/document-file-format.enum'
import IssueTypes from 'state/enums/issue-type.enum'

import { downloadDocumentImageAction } from '../actions/rest.actions'

/**
 * @instance FileStorageService
 */
const fileStorageService: FileStorageService = Container.get(FileStorageService)

/**
 *
 * This saga get an image from a Issue Document
 *
 * @requires FileStorageService
 * @param {ReturnType<typeof downloadDocumentImageAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* downloadImageDocumentWorker(
  action: ReturnType<typeof downloadDocumentImageAction.request>,
) {
  try {
    let url = '/download-utility'
    let name = action.payload.file || ''
    const newPayload = action.payload

    if (action.payload.type === IssueTypes.UPLOAD_DOCUMENT) {
      url = '/download-document'
      if (name === '') {
        name = action.payload.fileName
      }
    } else {
      delete newPayload.file
    }

    fileStorageService.requestParams = {
      method: 'POST',
      endpoint: url,
      body: newPayload,
      responseType: 'blob',
    }

    const type = name?.includes(DocumentFileFormat.PDF)
      ? DocumentFileFormat.PDF
      : DocumentFileFormat.IMG

    const response: string = yield call(fileStorageService.sendData, 'burek')
    yield put(setLoadingFullAction(false))

    if (typeof response === 'string' && response.length > 0) {
      yield put(
        downloadDocumentImageAction.success({
          url: response,
          type,
          name,
        }),
      )
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(downloadDocumentImageAction.failure(error))
  }
}

export default downloadImageDocumentWorker
