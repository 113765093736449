import CampaignStatusType from 'state/enums/campaign-status.enum'
import { CampaignState } from '../models'

const initialState: CampaignState = {
  isLoadingPartial: false,
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  campaigns: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
  },
  segmentNames: [],
  templateNames: [],
  bonusNames: [],
  currentCampaign: {
    isDeleted: false,
    _id: '',
    name: '',
    description: '',
    statusCampaign: CampaignStatusType.INDESIGN,
    targetGroup: [],
    trigger: 0,
    channel: 0,
    createdBy: '',
    companyId: '',
    createdAt: '',
    updatedAt: '',
  },
  currentSegment: null,
  currentTemplateLanguages: [],
}

export default initialState
