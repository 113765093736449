/**
 *
 * Bonus Report constants
 *
 */

enum ActionTypes {
  GET_BONUS_OFFERS_REPORT = '@bonus-report/GET_BONUS_OFFERS_REPORT',
  GET_BONUS_OFFERS_REPORT_SUBSCRIBER = '@bonus-report/GET_BONUS_OFFERS_REPORT_SUBSCRIBER',
  SET_OFFERS_REPORT_PAGINATION = '@bonus-report/SET_OFFERS_REPORT_PAGINATION',

  GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT = '@bonus-report/GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT',
  GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT_SUBSCRIBER = '@bonus-report/GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT_SUBSCRIBER',

  GET_BONUS_PLAYER_COUNT = '@bonus-report/GET_BONUS_PLAYER_COUNT',
  GET_BONUS_PLAYER_COUNT_SUBSCRIBER = '@bonus-report/GET_BONUS_PLAYER_COUNT_SUBSCRIBER',

  SET_OFFER_DRAWER_PAGINATION = '@bonus-report/SET_OFFER_DRAWER_PAGINATION',

  GET_STATE_REPORT = '@bonus-report/GET_STATE_REPORT',
  GET_STATE_REPORT_SUBSCRIBER = '@bonus-report/GET_STATE_REPORT_SUBSCRIBER',

  GET_DEPOSIT_TRIGGER_REPORT = '@bonus-report/GET_DEPOSIT_TRIGGER_REPORT',
  GET_DEPOSIT_TRIGGER_REPORT_SUBSCRIBER = '@bonus-report/GET_DEPOSIT_TRIGGER_REPORT_SUBSCRIBER',

  SET_LOADING_DRAWER = '@bonus-report/SET_LOADING_DRAWER',
  RESET_DRAWER = '@bonus-report/RESET_DRAWER',
}

export default ActionTypes
