import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AuthSelectors } from 'state/auth'

import { logoutAction, setSidebarIdsAction } from 'state/auth/actions'
import SidebarId from 'state/enums/sidebar-id.enum'
import { setPopoverAction } from 'state/ui/global/actions'

import { PopoverModel } from 'state/ui/global/models'
import {
  createShortcutsAction,
  deleteShortcutsAction,
  getShortcutsAction,
  updateShortcutsAction,
} from 'state/user/actions/rest.actions'
import { ShortcutLinkType, UpdateShortcutsPayload } from 'state/user/models'

const useSidebarActions = () => {
  const dispatch = useDispatch()

  const { sidebarIds, userSession } = useSelector(AuthSelectors.root)

  const setSidebarIds = useCallback(
    (payload: SidebarId[]) => {
      dispatch(setSidebarIdsAction(payload))
    },
    [dispatch],
  )

  const getShortcuts = useCallback(() => {
    dispatch(
      getShortcutsAction.request({
        userId: userSession._id,
      }),
    )
  }, [dispatch, userSession]) // eslint-disable-line

  const deleteShortcut = useCallback(
    (id: string) => {
      dispatch(
        deleteShortcutsAction.request({
          id,
        }),
      )
    },
    [dispatch], // eslint-disable-line
  )

  const createShortcut = useCallback(
    (payload: Omit<ShortcutLinkType, '_id' | 'userId'>) => {
      dispatch(
        createShortcutsAction.request({
          userId: userSession._id,
          shortcuts: [payload],
        }),
      )
    },
    [dispatch, userSession], // eslint-disable-line
  )

  const updateShortcut = useCallback(
    (payload: UpdateShortcutsPayload) => {
      dispatch(updateShortcutsAction.request(payload))
    },
    [dispatch, userSession], // eslint-disable-line
  )

  const setShortcutPopper = useCallback(
    (payload: PopoverModel) => {
      dispatch(setPopoverAction(payload))
    },
    [dispatch], // eslint-disable-line
  )

  const logout = () => dispatch(logoutAction())

  return {
    sidebarIds,
    setSidebarIds,
    logout,
    deleteShortcut,
    getShortcuts,
    createShortcut,
    updateShortcut,
    setShortcutPopper,
  }
}

export default useSidebarActions
