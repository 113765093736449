import { CompanyStateUI } from '../models'
import FormStatusType from '../../../enums/form-status.enum'

const initialState: CompanyStateUI = {
  text: 'null',
  isFormOpen: false,
  formTitle: '',
  formMode: FormStatusType.CREATE,
}

export default initialState
