import { createReducer } from 'typesafe-actions'
import { GgrReportState, GgrReportActions } from '../models'
import ActionTypes from '../constants'

import ggrReportInitialState from './initial-state'
import ggrHandler from './ggr.handler'

/**
 *
 * Bonus Engine main reducer
 *
 */
const GgrReportsReducer = createReducer<GgrReportState, GgrReportActions>(
  ggrReportInitialState,
).handleAction(
  [
    ActionTypes.GET_GGR_REPORTS,
    ActionTypes.GET_GGR_REPORTS_SUBSCRIBER,
    ActionTypes.SET_GGR_FILTERS,
    ActionTypes.SET_REPORTS_BY_PERIOD,
    ActionTypes.SET_NEW_GGR_PAGE,
    ActionTypes.SET_NEW_CONSOLIDATION_PAGE,
    ActionTypes.SET_NEW_GGR_TOTALS_PAGE,
    ActionTypes.SET_LOADING,
    ActionTypes.SET_ROWS_AMOUNT_GGR,
    ActionTypes.SET_ROWS_AMOUNT_NEW_CONSOLIDATION,
    ActionTypes.SET_ROWS_AMOUNT_NEW_GGR_TOTALS,
  ],
  ggrHandler,
)

export default GgrReportsReducer
