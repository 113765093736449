const BONUS_ENGINE = {
  GET_BONUSES: 'bonus/get-bonuses' as 'bonus/get-bonuses',
  GET_CAMPAIGNS: 'bonus/get-campaigns' as 'bonus/get-campaigns',
  CREATE_CAMPAIGN: 'bonus/save-campaign' as 'bonus/save-campaign',
  UPDATE_CAMPAIGN: 'bonus/update-campaign' as 'bonus/update-campaign',
  GET_ONE_CAMPAIGN: 'bonus/get-campaign' as 'bonus/get-campaign',
  DELETE_CAMPAIGN: 'bonus/delete-campaign' as 'bonus/delete-campaign',
  SAVE_OFFER: 'bonus/save-bonus-v3' as 'bonus/save-bonus-v3',
  SAVE_OFFER_V2: 'bonus/save-bonus-v2' as 'bonus/save-bonus-v2',
  DELETE_OFFER: 'bonus/delete-bonus' as 'bonus/delete-bonus',
  GET_BONUS_ENGINE_GAMES: 'games/v1/get-game-list' as 'games/v1/get-game-list',
  GET_GAME_CATEGORIES: 'games/get-game-categories' as 'games/get-game-categories',
  GET_GAME_PROVIDERS: 'games/get-game-provider-list' as 'games/get-game-provider-list',
  GET_WAGERING_CONTRIBUTIONS: 'bonus/get-wagering-contributions' as 'bonus/get-wagering-contributions',
  DUPLICATE_BONUS: 'bonus/duplicate-bonus' as 'bonus/duplicate-bonus',
  GET_FREE_GAMES_SETTINGS: 'bonus/get-default-free-game-settings' as 'bonus/get-default-free-game-settings',
}

export default BONUS_ENGINE
