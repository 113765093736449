import ActionTypes from '../constants'
import { TagState, TagActions } from '../models'

const updateTagDialogHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.SET_TAG_DIALOG:
      return {
        ...state,
        dialog: action?.payload,
      }

    default:
      return state
  }
}

export default updateTagDialogHandler
