import ActionTypes from '../constants'
import { CustomTriggersState, CustomTriggersActions } from '../models'

const getOneCustomTriggerHandler = (
  state: CustomTriggersState,
  action: CustomTriggersActions,
): CustomTriggersState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_CUSTOM_TRIGGER_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_CUSTOM_TRIGGER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCustomTrigger: action.payload,
      }

    case ActionTypes.GET_ONE_CUSTOM_TRIGGER_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default getOneCustomTriggerHandler
