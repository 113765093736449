import { CoolOffState } from '../models'

const CoolOffInitialState: CoolOffState = {
  isLoadingFull: false,
  coolOffs: [],
  categories: [],
  resolved: false,
  periodsAndAmounts: [],
  isLoading: false,
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default CoolOffInitialState
