import { alpha, TableCell, Theme, withStyles } from '@material-ui/core'
import { colors } from './colors.theme'

const { gray, blueDarkNav, blue, orange } = colors

export const tableCellTheme = {
  root: {
    padding: 10,
    borderBottom: `1px solid ${gray}`,
    borderRight: `1px solid ${gray}`,
    '&:last-child': {
      borderRight: 'none',
    },
    '&.blue-cell': {
      backgroundColor: blueDarkNav,
    },
    '&.no-br': {
      borderRight: 'none',
    },
    '&.no-border': {
      border: 'none',
    },
  },
  paddingCheckbox: {
    padding: '10px 16px',
  },
  head: {
    borderBottom: `1px solid ${blue}`,
    backgroundColor: blueDarkNav,
    fontWeight: 700,
    textTransform: 'capitalize' as 'capitalize',
    '.MuiTableHead-orange &': {
      borderBottom: `2px solid ${orange} !important`,
      backgroundColor: `${alpha(orange, 0.5)} !important`,
    },
    '.MuiTableHead-border-normal &': {
      borderBottom: `1px solid ${gray}`,
    },
  },
}

export const TableCellPEngine = withStyles((theme: Theme) => ({
  root: {
    padding: 5,
    borderBottom: `1px solid transparent`,
    borderRight: `1px solid transparent`,
    backgroundColor: blueDarkNav,
    border: 'none',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}))(TableCell)

export const TableCellNotBorder = withStyles((theme: Theme) => ({
  root: {
    padding: 10,
    borderBottom: `1px solid ${gray}`,
    borderRight: `none`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  paddingCheckbox: {
    padding: '10px 16px',
  },
}))(TableCell)
