import { colors } from './colors.theme'
import { Theme, DialogTitle, alpha } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const { orangeLight, white } = colors

export const dialogTitleTheme = {
  root: {
    '&.border-bottom-yellow': {
      borderBottom: `2px solid ${orangeLight}`,
    },
    '& .MuiTypography-root': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiButtonBase-root': {
      '& svg': {
        color: alpha(white, 0.7),
      },
    },
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
    },
    '&.no-title': {
      '& .MuiTypography-root': {
        justifyContent: 'flex-end',
      },
    },
  },
}

export const DialogTitleSimple = withStyles((theme: Theme) => ({
  root: {
    border: 'none',
    '& .MuiTypography-root': {
      'text-transform': 'none',
      fontSize: '1rem',
    },
  },
}))(DialogTitle)
