import ActionTypes from '../constants'
import { SegmentActions, SegmentState } from '../models'

const segmentCreateHandler = (
  state: SegmentState,
  action: SegmentActions,
): SegmentState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return { ...state }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        segments: [...state.segments, action.payload],
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default segmentCreateHandler
