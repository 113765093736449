const bonusTranslation = {
  noContentTitle: 'Search for a {{bonusType}}',
  noContentInstruction:
    'Use filters to search for a {{bonusType}} that meets your criteria',
  campaignsFoundBasedOnSearchCriteria:
    '{{amount}} Campaigns found based on search criteria',
  bonusId: 'ID',
  bonusName: 'Name',
  bonusType: 'Type',
  triggerType: 'Trigger',
  maxGrantedCount: 'Max Granted Count',
  maxGrantedAmount: 'Max Granted Amount',
  validityTime: 'Validity Time',
  acceptanceTime: 'Acceptance Time',
  delayTime: 'Delay Time',
  priority: 'Priority',
  playerCount: 'Player Count',
  playerMoreThanOneBonus: 'Player More Than One Bonus',
  totalAmountDeposited: 'Total Amount Deposited',
  grantedAmount: 'Granted Amount',
  grantedPendingAcceptAmount: 'Granted Pending Accept Amount',
  grantedPendingActivationAmount: 'Granted Pending Activation Amount',
  acceptedAwaitingEventAmount: 'Accepted Awaiting Event Amount',
  eligibleAmount: 'Eligible Amount',
  completedAmount: 'Completed Amount',
  cancelledAmount: 'Cancelled Amount',
  endedAmount: 'Ended Amount',
  grantedExpiredAmount: 'Granted Expired Amount',
  expiredAmount: 'Expired Amount',
  declinedAmount: 'Declined Amount',
  criteriaNotMetAmount: 'Criteria Not Met Amount',
  maxBonusWinDeductionAmount: 'Max Bonus Win Deduction Amount',
  grantedCount: 'Granted Count',
  grantedPendingAcceptCount: 'Granted Pending Accept Count',
  grantedPendingActivationCount: 'Granted Pending Activation Count',
  acceptedAwaitingEventCount: 'Accepted Awaiting Event Count',
  eligibleCount: 'Eligible Count',
  completedCount: 'Completed Count',
  canceledCount: 'Canceled Count',
  endedCount: 'Ended Count',
  grantedExpiredCount: 'Granted Expired Count',
  expiredCount: 'Expired Count',
  declinedCount: 'Declined Count',
  criteriaNotMetCount: 'Criteria Not Met Count',
  maxBonusWinDeductionCount: 'Max Bonus Win Deduction Count',
  totalBonusCostPreliminary: 'Total Bonus Cost Preliminary',
  totalBonusCostActual: 'Total Bonus Cost Actual',
  newCustomerCampaign: 'New Customer Campaign',
  nameOrId: 'Name Or ID',
  totalBonusCostPreliminaryInfo:
    'Preliminary formula based on the total amount of Granted Bonuses minus the Expired and Cancelled Bonuses. This formula is preliminary because it still considers bonuses that are being played.',
  totalBonusCostActualInfo:
    'The actual formula of bonus costs is: Granted Bonuses - Cancelled Bonuses - Expired Bonuses. This formula only considers the bonuses that have a finished status (either Completed, Ended, Granted Expired, Cancelled), but not ongoing active bonuses.',
  bonusReportDate: {
    CURRENT_MONTH: 'Current Month',
    LAST_MONTH: 'Last Month',
    DATE_RANGE: 'Date Range',
    LIFETIME: 'Lifetime',
  },
  id: 'ID',
  type: 'Type',
  trigger: 'Trigger',
  bonusOfferReport: 'Bonus Offer Report',
  searchPlayerId: 'Search Player ID',
  offerState: {
    ACCEPTED_AWAITING_EVENT: 'Accepted Awaiting Event',
    CANCELED: 'Cancelled',
    COMPLETED: 'Completed',
    CRITERIA_NOT_MET: 'Criteria Not Met',
    DECLINED: 'Declined',
    ELIGIBLE: 'Eligible',
    ENDED: 'Ended',
    EXCLUDED: 'Excluded',
    EXPIRED: 'Expired',
    GRANTED: 'Granted',
    GRANTED_EXPIRED: 'Granted Expired',
    GRANTED_PENDING_ACCEPT: 'Granted Pending Accept',
    GRANTED_PENDING_ACTIVATION: 'Granted Pending Activation',
    GRANTED_FREE_GAMES: 'Granted Free Games',
  },
}

export default bonusTranslation
