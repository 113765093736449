import { getDaysInMonth, getMonth, getYear } from 'date-fns'
import DateFilter from 'state/enums/date-filter.enum'
import { PlayerReportState } from '../models'

const today = new Date()

const initialState: PlayerReportState = {
  isLoading: false,
  reports: [],
  filters: {
    date: DateFilter.CURRENT_MONTH,
    playerId: '',
    dayFrom: 1,
    dayTo: getDaysInMonth(new Date(today.getFullYear(), today.getMonth())),
    monthFrom: getMonth(today) + 1,
    monthTo: getMonth(today) + 1,
    yearFrom: getYear(today),
    yearTo: getYear(today),
  },
}

export default initialState
