import {
  Table,
  withStyles,
  Theme,
  TableContainer,
  alpha,
} from '@material-ui/core'
import { colors } from './colors.theme'

const { blueDarkNav, blue } = colors

export const tableTheme = {
  root: {
    borderCollapse: 'unset' as 'unset',
  },
}

export const TableRules = withStyles((theme: Theme) => ({
  root: {
    '& .MuiTableCell-root': {
      borderRight: 'none',
    },
    '& .MuiTableHead-root': {
      borderBottom: 'none',
      '& th': {
        padding: 10,
        borderBottom: 'none',
        fontWeight: 700,
      },
    },
  },
}))(Table)

export const TableRoles = withStyles((theme: Theme) => ({
  root: {
    marginTop: 8,
    '& .MuiTableHead-root': {
      border: 'none',
      '& .MuiTableCell-root': {
        border: 'none',
      },
    },
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:first-child': {
          background: 'transparent',
          height: 8,
          '& .MuiTableCell-root': {
            padding: 0,
            lineHeight: 0,
            border: 'none',
          },
        },
      },
    },
  },
}))(Table)

export const TableAccountStatus = withStyles((theme: Theme) => ({
  root: {
    '& .MuiTableHead-root': {
      '& th': {
        borderBottom: `1px solid ${colors.gray}`,
        borderRight: `1px solid ${colors.gray}`,
      },
    },

    '& .MuiTableCell-root.allowedAction, & .MuiTableCell-root.verifiedItems': {
      backgroundColor: alpha(colors.white, 0.03),
    },
  },
}))(Table)

export const TableGGR = withStyles((theme: Theme) => ({
  root: {
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&:last-child': {
          background: blueDarkNav,
          '& .MuiTableCell-root': {
            border: 'none',
            borderTop: `2px solid ${blue}`,
          },
        },
      },
    },
  },
}))(Table)

export const TableContainerSticky = withStyles((theme: Theme) => ({
  root: {
    maxHeight: 700,
  },
}))(TableContainer)
