const rule = {
  actionTypeOptions: {
    SendMessage: 'Send Message',
    ChangeAccountStatus: 'Change Account Status',
    AddNote: 'Add Note',
    WithdrawStatus: 'Withdrawal Status',
    CreateAlert: 'Create Alert',
    TagAction: 'Player Tag',
    CreateZendeskTicket: 'Create Zendesk Ticket',
    SlackMessage: 'Send Slack Message',
    BonusAction: 'Add Bonus Action',
    RabbitMessage: 'Send Event',
  },
  priorityTypeOptions: {
    URGENT: 'Urgent',
    HIGH: 'High',
    NORMAL: 'Normal',
    LOW: 'Low',
  },
  accountVerificationTypeOptions: {
    SMS: 'SMS',
    EMAIL: 'Email',
    EMAIL_SMS: 'Email / SMS',
  },
  inviteFriendTypeOptions: {
    TOKEN: 'Token',
    CODE: 'Code',
    USERNAME: 'Username',
  },
  contextOptions: {
    subscription: 'Subscription',
    login: 'Login',
    batch: 'Batch',
  },
  rabbitEventOptions: {
    CS_A_3_session_1D: '3 Sessions', // eslint-disable-line
  },
  actionRulesText: 'If the above conditions are met, then',
  addAction: 'Add Action',
  addCondition: 'Add Condition',
  addRule: 'Add Rule',
  anyRepetitions: 'Any Repetitions',
  changeEventTriggerCaption:
    'Doing so will clear the Trigger Rules already added.',
  changeEventTriggerDisclaimer:
    'Are you sure you want to change the Event Trigger?',
  createRule: 'Create Rule',
  createARule: 'Create a Rule',
  createARuleText: 'Start by filling in the information in step 1.',
  editRule: 'Edit Rule',
  isToPlayer: 'The Player',
  maximumRepetitions: 'Maximum Repetitions',
  noActionsAdded: 'No Actions Added',
  noTriggerAdded: 'No Trigger Added',
  removeAction: 'Remove Action',
  removeCondition: 'Remove Condition',
  removeRule: 'Remove Rule',
  repetitionLimit: 'Repetition Limit',
  rulesArchive: 'Rules Archive',
  rulesDetailsText:
    'Add the name, tags and description with which you want to save your rule.',
  rulesEngine: 'Rules Engine',
  rolesGroup: 'Admin Roles',
  rulesSettings: 'Rules Settings',
  segmentationRulesText: 'If it matches these additional criteria',
  specificEmailAddresses: 'Specific Email Addresses',
  specificPhoneNumbers: 'Specific Phone Numbers',
  specificRepetitions: 'Specific Repetitions',
  triggerRules: 'Trigger Rules',
  triggerRulesText: 'When the event contains the following criteria',
}

export default rule
