import ActionTypes from '../constants'
import { RewardsState, RewardsActions, RewardOrderType } from '../models'

const orderHandler = (
  state: RewardsState,
  action: RewardsActions,
): RewardsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REWARD_ORDERS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_REWARD_ORDERS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardOrders:
          action.payload.resolved && action.payload.message
            ? [...(action.payload.message.list as RewardOrderType[])]
            : [],
      }

    case ActionTypes.GET_ONE_REWARD_ORDER:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_REWARD_ORDER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentOrder: action.payload.resolved
          ? (action.payload.message as RewardOrderType)
          : undefined,
      }

    case ActionTypes.UPDATE_REWARD_ORDER:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_REWARD_ORDER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardOrders: state.rewardOrders.map(shop => {
          let thisOrder = shop
          if (shop.id === action.payload.id) {
            thisOrder = {
              id: action.payload.id,
              productId: action.payload.productId,
              price: action.payload.price,
              quantity: action.payload.quantity,
              status: action.payload.status,
              supplier: action.payload.supplier,
              comment: action.payload.comment,
              created: state.currentOrder?.created || 0,
              createdTs: state.currentOrder?.createdTs || '',
              lastUpdated: state.currentOrder?.lastUpdated || 0,
              lastUpdatedTs: state.currentOrder?.lastUpdatedTs || '',
              shippingInfo: {
                firstName: state.currentOrder?.shippingInfo.firstName || '',
                lastName: state.currentOrder?.shippingInfo.lastName || '',
                streetName: state.currentOrder?.shippingInfo.streetName || '',
                streetNumber:
                  state.currentOrder?.shippingInfo.streetNumber || '',
                city: state.currentOrder?.shippingInfo.city || '',
                zipcode: state.currentOrder?.shippingInfo.zipcode || '',
                country: state.currentOrder?.shippingInfo.country || '',
              },
            }
          }
          return thisOrder
        }),
      }

    case ActionTypes.DELETE_REWARD_ORDER:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.DELETE_REWARD_ORDER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardOrders: state.rewardOrders.filter(
          shop => shop.id !== action.payload.message,
        ),
      }

    case ActionTypes.GET_BALANCE_POINTS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        balancePoints:
          action.payload.resolved && action.payload.message
            ? action.payload.message
            : 0,
      }

    case ActionTypes.SET_ORDER_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default orderHandler
