import { ValidationSchema } from 'hooks/form/models'
import { passwordValidators } from 'constants/regex/index.constant'

const resetPasswordValidator: ValidationSchema = {
  newPassword: {
    required: true,
    validators: passwordValidators,
  },
  confirmNewPassword: {
    required: true,
    validators: passwordValidators,
  },
}

export default resetPasswordValidator
