import ActionTypes from '../constants'
import { PlayerCardNoteState, PlayerCardNoteActions } from '../models'

const notesHandler = (
  state: PlayerCardNoteState,
  action: PlayerCardNoteActions,
): PlayerCardNoteState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_NOTES:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ALL_NOTES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        playerNotes: action.payload?.docs || [],
        currentScheduledNote:
          action.payload.docs &&
          action.payload.docs.find(note => note.isScheduled === true),
      }

    case ActionTypes.GET_NOTE_BROADCAST:
      return {
        ...state,
        isLoading: false,
        playerNotes: action.payload._id
          ? !action.payload.isScheduled
            ? state.playerNotes.map(note =>
                note._id === action.payload._id ? action.payload : note,
              )
            : [...state.playerNotes, action.payload]
          : [...state.playerNotes],
        currentScheduledNote:
          state.currentScheduledNote?._id === action.payload._id
            ? undefined
            : action.payload,
      }

    case ActionTypes.STOP_SCHEDULED_NOTE_SUBSCRIBER:
      return {
        ...state,
        currentScheduledNote: undefined,
      }

    default:
      return state
  }
}

export default notesHandler
