import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { globalUiSelectors } from 'state/ui/global'

const useSidebarUI = () => {
  const location = useLocation()

  const isCurrentPage = (route: string) => {
    return location.pathname === route
  }

  const { isLoadingFull } = useSelector(globalUiSelectors.root)

  const [openDrawer, setOpenDrawer] = useState(false)

  const [delayHandler, setDelayHandler] = useState(0)

  const [arrowRef, setArrowRef] = useState(null)

  const [anchorCreate, setAnchorCreate] = useState<null | HTMLElement>(null)

  const handleCreateOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorCreate(event.currentTarget)
  }

  const handleCreateClose = () => {
    setAnchorCreate(null)
  }

  const modifiers = {
    arrow: {
      enabled: true,
      element: arrowRef,
    },
    offset: { offset: '0, 8' },
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  const handleDrawerClose = () => {
    setOpenDrawer(false)
  }

  const handleDrawerEnter = () => {
    setDelayHandler(
      window.setTimeout(() => {
        handleDrawerOpen()
      }, 250),
    )
  }

  const handleDrawerLeave = () => {
    clearTimeout(delayHandler)
  }

  return {
    openDrawer,
    handleDrawerOpen,
    handleDrawerClose,
    handleDrawerEnter,
    handleDrawerLeave,
    setArrowRef,
    modifiers,
    anchorCreate,
    handleCreateOpen,
    handleCreateClose,
    isCurrentPage,
    isLoadingFull,
  }
}

export default useSidebarUI
