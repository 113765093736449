/**
 *
 * Template Action constants
 *
 */

enum ActionTypes {
  CREATE_EMAIL_REQUEST = '@template/CREATE_EMAIL_REQUEST',
  CREATE_EMAIL_SUCCESS = '@template/CREATE_EMAIL_SUCCESS',
  CREATE_EMAIL_FAILURE = '@template/CREATE_EMAIL_FAILURE',

  GET_ALL_EMAIL_REQUEST = '@template/GET_ALL_EMAIL_REQUEST',
  GET_ALL_EMAIL_SUCCESS = '@template/GET_ALL_EMAIL_SUCCESS',
  GET_ALL_EMAIL_FAILURE = '@template/GET_ALL_EMAIL_FAILURE',

  GET_ALL_ARCHIVED_REQUEST = '@template/GET_ALL_ARCHIVED_REQUEST',
  GET_ALL_ARCHIVED_SUCCESS = '@template/GET_ALL_ARCHIVED_SUCCESS',
  GET_ALL_ARCHIVED_FAILURE = '@template/GET_ALL_ARCHIVED_FAILURE',

  GET_ONE_EMAIL_REQUEST = '@template/GET_ONE_EMAIL_REQUEST',
  GET_ONE_EMAIL_SUCCESS = '@template/GET_ONE_EMAIL_SUCCESS',
  GET_ONE_EMAIL_FAILURE = '@template/GET_ONE_EMAIL_FAILURE',

  GET_ONE_LANGUAGES_REQUEST = '@template/GET_ONE_LANGUAGES_REQUEST',
  GET_ONE_LANGUAGES_SUCCESS = '@template/GET_ONE_LANGUAGES_SUCCESS',
  GET_ONE_LANGUAGES_FAILURE = '@template/GET_ONE_LANGUAGES_FAILURE',

  UPDATE_REQUEST = '@template/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@template/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@template/UPDATE_FAILURE',

  UPDATE_STATUS_REQUEST = '@template/UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS = '@template/UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE = '@template/UPDATE_STATUS_FAILURE',

  SET_FILTERS = '@template/SET_FILTERS',

  DELETE_REQUEST = '@template/DELETE_REQUEST',
  DELETE_SUCCESS = '@template/DELETE_SUCCESS',
  DELETE_FAILURE = '@template/DELETE_FAILURE',

  DUPLICATE_REQUEST = '@template/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@template/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@template/DUPLICATE_FAILURE',

  ARCHIVE_REQUEST = '@template/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@template/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@template/ARCHIVE_FAILURE',

  SEND_CAMPAIGN_REQUEST = '@template/SEND_CAMPAIGN_REQUEST',
  SEND_CAMPAIGN_SUCCESS = '@template/SEND_CAMPAIGN_SUCCESS',
  SEND_CAMPAIGN_FAILURE = '@template/SEND_CAMPAIGN_FAILURE',

  SEND_EMAIL_TEST_REQUEST = '@template/SEND_EMAIL_TEST_REQUEST',
  SEND_EMAIL_TEST_SUCCESS = '@template/SEND_EMAIL_TEST_SUCCESS',
  SEND_EMAIL_TEST_FAILURE = '@template/SEND_EMAIL_TEST_FAILURE',

  SEND_EMAIL_REQUEST = '@template/SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS = '@template/SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILURE = '@template/SEND_EMAIL_FAILURE',

  SEND_EMAIL_FORGOT_PASSWORD_REQUEST = '@template/SEND_EMAIL_FORGOT_PASSWORD_REQUEST',
  SEND_EMAIL_FORGOT_PASSWORD_SUCCESS = '@template/SEND_EMAIL_FORGOT_PASSWORD_SUCCESS',
  SEND_EMAIL_FORGOT_PASSWORD_FAILURE = '@template/SEND_EMAIL_FORGOT_PASSWORD_FAILURE',

  CREATE_SUBSCRIBER = '@template/CREATE_SUBSCRIBER',
  DELETE_SUBSCRIBER = '@template/DELETE_SUBSCRIBER',
  UPDATE_SUBSCRIBER = '@template/UPDATE_SUBSCRIBER',

  RESET_EMAIL_TEMPLATES = '@template/RESET_EMAIL_TEMPLATES',
  RESET_TEMPLATES_ARCHIVED = '@template/RESET_TEMPLATES_ARCHIVED',
  SET_CREATION_MODE = '@template/SET_CREATION_MODE',

  SET_FORM_MODE = '@template/SET_FORM_MODE',
  SET_CURRENT_TEMPLATE = '@template/SET_CURRENT_TEMPLATE',

  SET_POPOVER = '@template/SET_POPOVER',
  SET_ACTIVE_LANGUAGE = '@template/SET_ACTIVE_LANGUAGE',
  SET_TEMPLATE_EMAIL_DIALOG = '@template/SET_TEMPLATE_EMAIL_DIALOG',
}

export default ActionTypes
