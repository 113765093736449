import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18next'

import { push } from 'connected-react-router'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import FormStatusType from 'state/enums/form-status.enum'
import {
  setDialogAction,
  setFormModeAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { createSegmentAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the creation of a single segment
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createSegmentAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createSegmentWorker(
  action: ReturnType<typeof createSegmentAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'segmentations/create',
      body: action.payload,
    }

    const { auth, router } = yield select()

    const isAnalyticsSegment = router.location.pathname.includes(
      'email-activity',
    )

    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    if (response._id) {
      if (isAnalyticsSegment) {
        yield put(setDialogAction({ open: false }))
        yield put(
          setSnackbarAction({
            message: i18n.t('messages.successfully', {
              item: i18n.t('segment'),
              what: i18n.t('messages.created'),
            }),
            open: true,
            variant: 'success',
          }),
        )
      } else {
        yield put(createSegmentAction.success(response))
        yield put(setFormModeAction(FormStatusType.EDIT))
        yield put(push(`${routes.segments.EDIT}/${response._id}`))
        yield put(setDialogAction({ open: true, id: 'createSegmentSuccess' }))
      }
    } else {
      const message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')
      yield put(
        createSegmentAction.failure({
          name: response.status || '',
          message,
        }),
      )
      yield put(
        setSnackbarAction({
          message,
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(createSegmentAction.failure(error))
  }
}

export default createSegmentWorker
