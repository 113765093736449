import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import PasswordFieldComponent from 'views/components/password/index.component'
import useResetPassword from '../hooks/use-reset-password.hook'

const FormReset = () => {
  const { t } = useTranslation()

  const {
    state,
    validations,
    handleOnChangeInput,
    handleSave,
  } = useResetPassword()

  const disableButton = validations.some(item => item.isInvalid)

  const errorPassword = validations.find(
    item => item.isInvalid && item.name !== '',
  )

  return (
    <>
      <PasswordFieldComponent
        state={state}
        name="newPassword"
        handleOnChange={handleOnChangeInput}
        label={t('newPassword')}
      />
      <PasswordFieldComponent
        state={state}
        name="confirmNewPassword"
        handleOnChange={handleOnChangeInput}
        label={t('confirmNewPassword')}
      />
      {errorPassword && (
        <Typography variant="caption" className="text-red" paragraph>
          {t(errorPassword.name)}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSave}
        disabled={disableButton}
      >
        {t('updatePassword')}
      </Button>
    </>
  )
}

export default FormReset
