import React from 'react'
import { Avatar } from '@material-ui/core'

type Props = {
  firstName: string
  lastName: string
  size?: string
}

const getAvatarColors = (text: string) => {
  const letter = text.charAt(0).toUpperCase()
  switch (true) {
    case /[A-D]/.test(letter):
      return 'Mui-Avatar-orange'
    case /[E-G]/.test(letter):
      return 'Mui-Avatar-green'
    case /[H-J]/.test(letter):
      return 'Mui-Avatar-purple'
    case /[K-N]/.test(letter):
      return 'Mui-Avatar-blue'
    case /[O-Q]/.test(letter):
      return 'Mui-Avatar-red'
    case /[R-T]/.test(letter):
      return 'Mui-Avatar-yellow'
    case /[U-W]/.test(letter):
      return 'Mui-Avatar-teal'
    case /[X-Z]/.test(letter):
      return 'Mui-Avatar-pink'
    default:
      return 'Mui-Avatar-gray'
  }
}

const AvatarLettersComponent = ({ firstName, lastName, size }: Props) => {
  const first = firstName === '-' ? 'S' : firstName.charAt(0).toUpperCase()
  const last = lastName === '-' ? '' : lastName.charAt(0).toUpperCase()
  return (
    <Avatar className={`${size || ''} ${getAvatarColors(lastName)}`}>
      {first}
      {last}
    </Avatar>
  )
}

export default AvatarLettersComponent
