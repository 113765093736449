import { withStyles, Theme, Step, StepLabel } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white, blue } = colors

export const stepperTheme = {
  root: {
    background: 'none',
    padding: 0,
  },
}

export const stepTheme = {
  root: {
    '&.MuiStep-completed': {
      '& + .MuiStepConnector-vertical.Mui-disabled': {
        opacity: 1,
      },
    },
  },
}

export const stepConnectorTheme = {
  root: {
    opacity: 1,
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  line: {
    borderColor: blue,
  },
  vertical: {
    marginLeft: 21,
    padding: 0,
  },
  lineVertical: {
    minHeight: 40,
  },
}

export const stepContentTheme = {
  root: {
    borderLeft: `1px solid ${alpha(blue, 0.5)}`,
    marginTop: 0,
    marginLeft: 21,
    paddingLeft: 28,
    paddingRight: 28,
  },
}

export const stepLabelTheme = {
  root: {
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
  label: {
    fontSize: '1.125rem',
    fontWeight: 700,
    color: alpha(white, 0.7),
    '&$active, &$completed': {
      fontWeight: 700,
    },
    '& .MuiIconButton-root': {
      display: 'none',
    },
  },
  completed: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      display: 'block',
      marginLeft: 8,
      fontSize: '1.25rem',
    },
  },
}

export const StepNonLinear = withStyles((theme: Theme) => ({
  root: {
    '&.disabled': {
      '&:not(.active)': {
        opacity: 0.5,
        '& + .MuiStepConnector-vertical': {
          opacity: 0.5,
        },
      },
    },
    '&.active': {
      '& .MuiStepContent-root': {
        borderLeft: `1px solid ${blue}`,
        '&.MuiStepContent-last': {
          borderLeft: 'none',
        },
      },
    },
    '.disabled &': {
      opacity: 0.5,
    },
  },
}))(Step)

export const StepLabelNonLinear = withStyles((theme: Theme) => ({
  root: {},
  label: {
    '& .MuiIconButton-root': {
      display: 'inline-flex',
    },
  },
}))(StepLabel)
