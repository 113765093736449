import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'

const templateGetOneLanguageHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_LANGUAGES_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ONE_LANGUAGES_SUCCESS:
      return {
        ...state,
        currentTemplateLanguages: Object.assign(
          state.currentTemplateLanguages,
          action.payload,
        ),
        isLoading: false,
      }

    case ActionTypes.GET_ONE_LANGUAGES_FAILURE:
      return { ...state, isLoading: false }

    default:
      return state
  }
}

export default templateGetOneLanguageHandler
