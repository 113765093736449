import { ApplicationState } from '../root.models'
import { TemplatePushState } from './models'

export const root = ({ templatePush }: ApplicationState) => templatePush

export const templatePushNamesSelector = ({ templates }: TemplatePushState) => {
  return templates
}

export default root
