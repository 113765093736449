enum TransactionType {
  DEPOSIT = 1,
  WITHDRAW = 2,
  WAGER = 3,
  WIN = 4,
  DEPOSIT_CANCEL = 5,
  WITHDRAW_CANCEL = 6,
  WAGER_CANCEL = 7,
  WIN_CANCEL = 8,
  DEPOSIT_MANUAL = 9,
  WITHDRAW_MANUAL = 10,
  BONUS_ADD = 11,
  BONUS_REDEEM = 12,
  BONUS_CANCEL = 13,
  REAL_TO_BONUS = 14,
  BONUS_TO_REAL = 15,
  FREE_GAME_BONUS_ADD = 16,
  FREE_GAME_WAGER = 17,
  FREE_GAME_WIN = 18,
  FREE_GAME_BONUS = 19,
  FREE_GAME_REAL = 20,
}

export default TransactionType
