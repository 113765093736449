import { AnalyticsState } from '../models'
/* eslint-disable @typescript-eslint/camelcase */

const initialState: AnalyticsState = {
  isLoadingPartial: false,
  isLoadingFull: false,
  isLoading: false,
  isLoadingEvents: false,
  isLoadingTemplatePreview: false,
  isDownloading: false,
  isTableLoading: false,
  hasError: false,
  wasDeleted: false,
  error: '',
  analytics: [],
  targets: [],
  campaignsByPlayer: [],
  AnalyticsGeneralStats: {
    emailStats: [],
    smsStats: [],
    onSiteStats: [],
    kpiGroups: [],
    campaignId: '',
    campaignName: '',
    ruleId: '',
    ruleName: '',
    targets: [],
    stats: {
      login: 0,
      loginPercentage: 0,
      deposit: 0,
      depositPercentage: 0,
      repeats: 0,
      repeatsPercentage: 0,
      depositTotal: '',
      ggr: '',
      bonusCount: 0,
      bonusTotal: '',
      dcpu: 0,
      adpu: '',
      players: 0,
      targetGroup: '',
    },
  },
  AnalyticsStatsByGroup: {
    statsByWeek: [],
    campaignId: '',
    campaignName: '',
    ruleId: '',
    ruleName: '',
    targetGroup: '',
    stats: {
      login: 0,
      loginPercentage: 0,
      deposit: 0,
      depositPercentage: 0,
      repeats: 0,
      repeatsPercentage: 0,
      depositTotal: '',
      ggr: '',
      bonusCount: 0,
      bonusTotal: '',
      dcpu: 0,
      adpu: '',
      players: 0,
      targetGroup: '',
    },
    emailStats: {
      targetGroup: '',
      players: 0,
      sent: 0,
      sentPercentage: 0,
      delivered: 0,
      deliveredPercentage: 0,
      opened: 0,
      openedPercentage: 0,
      clicks: 0,
      clicksPercentage: 0,
    },
    smsStats: {
      targetGroup: '',
      players: 0,
      sent: 0,
      sentPercentage: 0,
      delivered: 0,
      deliveredPercentage: 0,
      opened: 0,
      openedPercentage: 0,
      clicks: 0,
      clicksPercentage: 0,
    },
    onSiteStats: {
      targetGroup: '',
      players: 0,
      sent: 0,
      sentPercentage: 0,
      delivered: 0,
      deliveredPercentage: 0,
      opened: 0,
      openedPercentage: 0,
      clicks: 0,
      clicksPercentage: 0,
    },
  },
  totalChannelActivity: [],
  currentChannelsActivity: [],
  currentChannelEvents: {
    campaignName: '',
    playerId: '',
    userName: '',
    email: '',
    mobileNumber: '',
    events: [],
  },
  currentPreviewTemplates: [],
  currentEventsDetail: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 0,
    pages: 1,
  },
  filters: {},
  filtersEvents: { limit: 25, page: 1 },
  filtersCampaignByPlayer: {
    playerId: '',
    limit: 25,
    page: 1,
  },
}

export default initialState
