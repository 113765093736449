import ActionTypes from '../constants'
import { TemplatePushActions, TemplatePushState } from '../models'

const templatePushUpdateHandler = (
  state: TemplatePushState,
  action: TemplatePushActions,
): TemplatePushState => {
  switch (action.type) {
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    default:
      return state
  }
}

export default templatePushUpdateHandler
