import { createReducer } from 'typesafe-actions'
import { MediaDatabaseState, MediaDatabaseActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getData from './get-data.handler'

const mediaDatabaseReducer = createReducer<
  MediaDatabaseState,
  MediaDatabaseActions
>(initialState).handleAction(
  [
    ActionTypes.GET_FOLDERS,
    ActionTypes.GET_FILES,
    ActionTypes.SET_ACTIVE_FOLDER,
    ActionTypes.SET_UPLOAD,
    ActionTypes.SET_IS_LOADING,
  ],
  getData,
)

export default mediaDatabaseReducer
