import * as jwt from 'jsonwebtoken'
import { DecodedJWTStatus, EncodedJWTStatus } from './types'

/**
 *
 * * JWT Decoder
 *
 * Also returns if the token received is valid
 *
 * @param {string} token - The JWT token
 * @returns the JWT payload
 * @returns if the token is valid
 */
export const decodeJWT = (token: string | undefined): DecodedJWTStatus => {
  let decoded: DecodedJWTStatus['decoded'] = ''
  try {
    decoded =
      token && jwt.verify(token, `${process.env.REACT_APP_JWT_SECRET}`)
  } catch (err) {
    return {
      isValid: false,
      decoded: undefined,
    }
  }
  return {
    isValid: true,
    decoded,
  }
}

export const encodeJWT = (payload: EncodedJWTStatus) => {
  let encode = ''
  try {
    encode = jwt.sign(payload, `${process.env.REACT_APP_JWT_SECRET_ADMIN}`)
  } catch (err) {
    return undefined
  }
  return encode
}
