import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import { tokenSelector } from 'state/auth/selectors'
import { EmailTemplate } from '../models'
import { updateTemplateEmailStatusAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the update of a single email template status
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateTemplateEmailStatusAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateStatusTemplateWorker(
  action: ReturnType<typeof updateTemplateEmailStatusAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'templates/email/update/one/status ',
      body: action.payload,
    }

    const token = yield select(tokenSelector)
    const response: EmailTemplate = yield call(apiService.sendData, token)
    yield put(updateTemplateEmailStatusAction.success(response))
  } catch (error) {
    yield put(updateTemplateEmailStatusAction.failure(error))
  }
}

export default updateStatusTemplateWorker
