import TriggerType from 'state/enums/trigger.enum'
import ActionTypes from '../constants'
import { RulesEngineActions, RulesEngineState } from '../models'

const generalHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.RESET_RULES:
      return {
        ...state,
        rules: [],
      }

    case ActionTypes.RESET_CURRENT_RULE:
      return {
        ...state,
        currentRule: {
          decisionTree: [],
          createdAt: '',
          createdBy: '',
          description: '',
          isActive: false,
          isDeleted: false,
          name: '',
          trigger: { event: TriggerType.RESET_PASS },
          updatedAt: '',
          _id: '',
        },
      }

    default:
      return state
  }
}

export default generalHandler
