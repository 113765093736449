import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const updateStatusHandler = (
  state: WalletModels.State,
  action: WalletModels.Actions | WalletModels.SocketActions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.UPDATE_WITHDRAWALS_STATUS:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_WITHDRAWALS_STATUS_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload.resolved,
        error: action.payload.resolved ? '' : 'error',
      }

    default:
      return state
  }
}

export default updateStatusHandler
