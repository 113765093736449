enum TimeZoneType {
  GMT_0 = 'GMT+00 Coordinated Universal Time',
  GMT_1 = 'GMT+01 Central European Time / West Africa Time',
  GMT_2 = 'GMT+02 Central European Summer Time / Eastern European Time / Kaliningrad Time',
  GMT_3 = 'GMT+03 Eastern European Summer Time / Moscow Time / East Africa Time',
  GMT_4 = 'GMT+04 Samara Time / Armenia Time / Georgia Time / Mauritius Time',
  GMT_5 = 'GMT+05 Pakistan Standard Time, / Yekaterinburg Time, / Maldives Time',
  GMT_6 = 'GMT+06 Omsk Time, / Bangladesh Standard Time',
  GMT_7 = 'GMT+07 Christmas Island Time (Australia), / Krasnoyarsk Time',
  GMT_8 = 'GMT+08 China Standard Time / Irkutsk Time / Australian Western Standard Time',
  GMT_9 = 'GMT+09 Japan Standard Time , / Yakutsk Time',
  GMT_10 = 'GMT+10 East Australian Standard Time / Vladivostok Time',
  GMT_11 = 'GMT+11 Sakhalin Time',
  GMT_12 = 'GMT+12 International Date Line East / New Zealand Standard Time / Magadan Time',
  GMT_13 = 'GMT-01 Cape Verde Time',
  GMT_14 = 'GMT-02 South Georgia and the South Sandwich Islands Time',
  GMT_15 = 'GMT-03 Argentina Time / Uruguay Standard Time / Suriname Time',
  GMT_16 = 'GMT-04 Amazon Time / Atlantic Standard Time / Chile Standard Time',
  GMT_17 = 'GMT-05 USA on Eastern Standard Time / Daylight Saving Time',
  GMT_18 = 'GMT-06 USA on Central Standard Time / Daylight Saving Time',
  GMT_19 = 'GMT-07 USA and Canada on Mountain Standard Time',
  GMT_20 = 'GMT-08 Pitcairn Islands / USA and Canada on Pacific Standard Time',
  GMT_21 = 'GMT-09 Alaska Standard Time / Hawaii-Aleutian Daylight Time',
  GMT_22 = 'GMT-10 Cook Islands / Hawaii-Aleutian Standard Time',
  GMT_23 = 'GMT-11 American Samoa / Midway Islands',
  GMT_24 = 'GMT-12 Baker Island Time',
}

export default TimeZoneType
