import { createReducer } from 'typesafe-actions'
import { ReferFriendState, ReferFriendActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getAllInvitationsHandler from './get-all-invitations.handler'
import getReferredPlayerHandler from './get-referred-player.handler'

const referFriendReducer = createReducer<ReferFriendState, ReferFriendActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ALL_INVITATIONS,
      ActionTypes.GET_ALL_INVITATIONS_SUBSCRIBER,
      ActionTypes.SET_REFERRED_PAGINATION,
    ],
    getAllInvitationsHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_REFERRED_PLAYER,
      ActionTypes.GET_REFERRED_PLAYER_SUBSCRIBER,
      ActionTypes.SET_REFERRED_APPROVED_PAGINATION,
      ActionTypes.SET_REFERRED_REJECTED_PAGINATION,
    ],
    getReferredPlayerHandler,
  )

export default referFriendReducer
