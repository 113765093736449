/**
 *
 * Email Template reducers
 *
 */
import ActionTypes from '../constants'
import { EmailBuilderState, TemplateEmailActions } from '../models'
import EmailBuilderInitialState from '../initial-state'

const emailBuilderReducer = (
  state: EmailBuilderState = EmailBuilderInitialState,

  action: TemplateEmailActions,
): EmailBuilderState => {
  switch (action.type) {
    case ActionTypes.UPDATE_EMAILS_INFO:
      return {
        ...state,
        emailsInfo: action.payload,
      }

    case ActionTypes.UPDATE_EMAIL_TEST:
      return {
        ...state,
        emailTest: action.payload,
      }

    case ActionTypes.UPDATE_ACTIVE_TAB:
      return { ...state, activeTab: action.payload }

    case ActionTypes.UPDATE_POPOVER:
      return { ...state, popover: action.payload }

    case ActionTypes.UPDATE_DIALOG:
      return { ...state, dialog: action.payload }

    case ActionTypes.UPDATE_LANGUAGES:
      return { ...state, languages: action.payload }

    case ActionTypes.SET_FORM_MODE:
      return {
        ...state,
        formMode: action.payload,
      }

    case ActionTypes.RESET_TEMPLATE:
      return {
        ...state,
        ...action.payload,
      }

    case ActionTypes.UPDATE_ID:
      return {
        ...state,
        id: action.payload,
      }

    default:
      return state
  }
}

export default emailBuilderReducer
