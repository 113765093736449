import { Service } from 'typedi'
import removeDuplicates from 'utils/state/remove-duplicates.util'

export type MessagePayload = {
  path: string
  input: object
  meta?: string
  handler?: (data: any) => void
}

@Service()
class QueueMessages {
  private _queue: MessagePayload[] = []

  clean() {
    this._queue = []
  }

  add(messagePayload: MessagePayload) {
    this._queue = removeDuplicates([...this.queue, messagePayload], 'path')
  }

  get queue() {
    return this._queue
  }

  get queueLength() {
    return this._queue.length
  }
}

export default QueueMessages
