import ActionTypes from '../constants'
import {
  RewardsState,
  RewardsActions,
  RewardShopType,
  RewardShopCreatePayload,
} from '../models'

const shopHandler = (
  state: RewardsState,
  action: RewardsActions,
): RewardsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REWARD_SHOPS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_REWARD_SHOPS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardShops:
          action.payload.resolved && action.payload.message
            ? [...(action.payload.message as RewardShopType[])]
            : [],
      }

    case ActionTypes.GET_ONE_REWARD_SHOP:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_REWARD_SHOP_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentShop: action.payload.resolved
          ? (action.payload.message as RewardShopType)
          : undefined,
      }

    case ActionTypes.CREATE_REWARD_SHOP:
      return {
        ...state,
        isLoading: true,
        currentShop: action.payload,
      }

    case ActionTypes.CREATE_REWARD_SHOP_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardShops: [
          ...state.rewardShops,
          {
            ...(state.currentShop as RewardShopCreatePayload),
            id: action.payload.message as number,
          },
        ],
      }

    case ActionTypes.UPDATE_REWARD_SHOP:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.UPDATE_REWARD_SHOP_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.DELETE_REWARD_SHOP:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.DELETE_REWARD_SHOP_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardShops: state.rewardShops.filter(
          shop => shop.id !== action.payload.message,
        ),
      }

    case ActionTypes.SET_REWARD_SHOP_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default shopHandler
