import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { black, red } = colors

export const menuItemTheme = {
  root: {
    fontSize: 14,
    '&$selected': {
      backgroundColor: alpha(black, 0.2),
      '&:hover': {
        backgroundColor: alpha(black, 0.2),
      },
    },
    '&:hover': {
      backgroundColor: alpha(black, 0.2),
    },
    '&.red': {
      color: red,
    },
  },
}
