import { MiddlewareAPI, Dispatch, Action } from 'redux'

/**
 * Lets you dispatch a function instead of an action.
 * This function will receive `dispatch` and `getState` as arguments.
 *
 * Useful for early exits (conditions over `getState()`), as well
 * as for async control flow (it can `dispatch()` something else).
 *
 * `dispatch` will return the return value of the dispatched function.
 */
const cthunkMiddleware = (store: MiddlewareAPI) => (next: Dispatch<Action>) => (
  action: any,
) =>
  typeof action === 'function'
    ? action(store.dispatch, store.getState)
    : next(action)

export default cthunkMiddleware
