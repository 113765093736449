import React from 'react'
import { useDispatch } from 'react-redux'
import useForm from 'hooks/form/use-form.hook'

import { StateSchema } from 'hooks/form/models'
import { loginAction } from 'state/auth/actions'
import { LoginPayload } from 'state/user/models'

import loginSchema from '../components/schema'
import loginValidator from '../components/validator'

const useLogin = () => {
  const dispatch = useDispatch()

  const login = (credentials: LoginPayload) =>
    dispatch(loginAction.request(credentials))

  /**
   * This is the function that's executed when all the form inputs are validated
   */
  const onSubmitForm = ({ email, password }: StateSchema) => {
    const loginPayload: LoginPayload = {
      searchParam: email.value,
      password: password.value,
    }

    login(loginPayload)
  }

  /**
   * This is the form state that uses the hook for forms and returns its state,
   * and a few functions to handle changes and resets
   */
  const { state, handleOnChange, handleOnSubmit, disable } = useForm(
    loginSchema,
    loginValidator,
    () => {
      onSubmitForm(state)
    },
  )

  /**
   * Add the option to submit when enter is pressed.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event
   */
  const onSubmitEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === 13 && !disable) {
      onSubmitForm(state)
    }
  }

  return {
    onSubmitEnterKey,
    state,
    handleOnChange,
    handleOnSubmit,
    disable,
  }
}

export default useLogin
