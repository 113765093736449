import { createReducer } from 'typesafe-actions'
import { PlayerCardNoteState, PlayerCardNoteActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import notesHandler from './notes.handler'

/**
 * Creates a PlayerCardNote Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardNoteReducer = createReducer<
  PlayerCardNoteState,
  PlayerCardNoteActions
>(initialState).handleAction(
  [
    ActionTypes.GET_ALL_NOTES,
    ActionTypes.GET_ALL_NOTES_SUBSCRIBER,
    ActionTypes.GET_NOTE_BROADCAST,
    ActionTypes.CREATE_SCHEDULED_NOTE,
    ActionTypes.CREATE_SCHEDULED_NOTE_SUBSCRIBER,
    ActionTypes.CREATE_SCHEDULED_NOTE_EXCEPTION,
    ActionTypes.STOP_SCHEDULED_NOTE,
    ActionTypes.STOP_SCHEDULED_NOTE_SUBSCRIBER,
    ActionTypes.DELETE_SCHEDULED_NOTE,
    ActionTypes.DELETE_SCHEDULED_NOTE_SUBSCRIBER
  ],
  notesHandler,
)

export default playerCardNoteReducer
