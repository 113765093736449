import { BonusReportState } from '../models'

const bonusReportInitialState: BonusReportState = {
  isLoading: false,
  isLoadingDrawer: false,
  bonusOffers: {
    report: [],
    pagination: {
      total: 0,
      limit: 10,
      page: 1,
      pages: 0,
    },
  },
  playerCount: [],
  offerDrawerPagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  stateReport: [],
  depositReport: [],
}

export default bonusReportInitialState
