import { action, createAction, createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  CreateCampaignPayload,
  UpdateCampaignPayload,
  ArchiveCampaignPayload,
  GetOneCampaignPayload,
  CampaignsResponse,
  GetAllCampaignsPayload,
  CreateCampaignResponse,
  UpdateCampaignResponse,
  GetOneCampaignResponse,
  DuplicateCampaignPayload,
  NamesResponseType,
  GetOneSegmentResponse,
  UpdateStatusCampaignPayload,
  CampaignTargetGroup,
  OptionBonusType,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */
export const createCampaignAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<CreateCampaignPayload, CreateCampaignResponse, Error>()

export const updateCampaignAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateCampaignPayload, UpdateCampaignResponse, Error>()

export const updateStatusCampaignAction = createAsyncAction(
  ActionTypes.UPDATE_STATUS_REQUEST,
  ActionTypes.UPDATE_STATUS_SUCCESS,
  ActionTypes.UPDATE_STATUS_FAILURE,
)<UpdateStatusCampaignPayload, UpdateCampaignResponse, Error>()

export const archiveCampaignAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<ArchiveCampaignPayload, UpdateCampaignResponse, Error>()

export const getAllCampaignsAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<GetAllCampaignsPayload, CampaignsResponse, Error>()

export const getAllCampaignsArchiveAction = createAsyncAction(
  ActionTypes.GET_ALL_ARCHIVE_REQUEST,
  ActionTypes.GET_ALL_ARCHIVE_SUCCESS,
  ActionTypes.GET_ALL_ARCHIVE_FAILURE,
)<GetAllCampaignsPayload, CampaignsResponse, Error>()

export const getOneCampaignAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<GetOneCampaignPayload, GetOneCampaignResponse, Error>()

export const duplicateCampaignAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<DuplicateCampaignPayload, CreateCampaignResponse, Error>()

export const getAllSegmentNamesAction = createAsyncAction(
  ActionTypes.GET_ALL_SEGMENTS_REQUEST,
  ActionTypes.GET_ALL_SEGMENTS_SUCCESS,
  ActionTypes.GET_ALL_SEGMENTS_FAILURE,
)<undefined, NamesResponseType, Error>()

export const getOneCampaignSegmentAction = createAsyncAction(
  ActionTypes.GET_ONE_SEGMENT_REQUEST,
  ActionTypes.GET_ONE_SEGMENT_SUCCESS,
  ActionTypes.GET_ONE_SEGMENT_FAILURE,
)<string, GetOneSegmentResponse, Error>()

export const getAllTemplateNamesAction = createAsyncAction(
  ActionTypes.GET_ALL_TEMPLATES_REQUEST,
  ActionTypes.GET_ALL_TEMPLATES_SUCCESS,
  ActionTypes.GET_ALL_TEMPLATES_FAILURE,
)<undefined, NamesResponseType, Error>()

export const getTemplateLanguagesAction = createAsyncAction(
  ActionTypes.GET_TEMPLATE_LANGUAGES_REQUEST,
  ActionTypes.GET_TEMPLATE_LANGUAGES_SUCCESS,
  ActionTypes.GET_TEMPLATE_LANGUAGES_FAILURE,
)<{ targetGroup: CampaignTargetGroup[]; channel: string }, any, Error>()

export const resetCampaignsAction = () => action(ActionTypes.RESET_CAMPAIGNS)

export const resetCampaignAction = () => action(ActionTypes.RESET_CAMPAIGN)

export const setFilterCampaignAction = createAction(
  ActionTypes.SET_FILTERS,
  actions => {
    return (filters: GetAllCampaignsPayload) => actions(filters)
  },
)

export const resetTemplateLanguagesAction = () =>
  action(ActionTypes.RESET_TEMPLATE_LANGUAGES)

export const resetSegmentAction = () => action(ActionTypes.RESET_SEGMENT)

export const setBonusNamesAction = createAction(
  ActionTypes.GET_ALL_BONUSES,
  actions => {
    return (bonusNames: OptionBonusType[]) => actions(bonusNames)
  },
)
