import ActionTypes from '../constants'
import { CustomTriggersState, CustomTriggersActions } from '../models'

const archiveCustomTriggerHandler = (
  state: CustomTriggersState,
  action: CustomTriggersActions,
): CustomTriggersState => {
  switch (action.type) {
    case ActionTypes.ARCHIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.ARCHIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCustomTrigger: action.payload,
      }

    case ActionTypes.ARCHIVE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default archiveCustomTriggerHandler
