import { TeledataState } from '../models'

const initialState: TeledataState = {
  isLoading: false,
  reports: [],
  filters: {
    page: 1,
    pageLimit: 25,
  },
  pagination: {
    page: 1,
    limit: 25,
    total: 0,
    pages: 0,
  },
}

export default initialState
