import { debounce } from 'redux-saga/effects'
import {
  getDocumentImagesAction,
  getJumioDataAction,
  downloadDocumentImageAction,
  uploadDocumentsAction,
} from '../actions/rest.actions'
import getJumioDataWorker from './get-jumio-data.saga'

import getDocumentImagesWorker from './get-document-images.saga'
import downloadImageDocumentWorker from './download-image-document.saga'
import uploadDocumentWorker from './upload-file.saga'

/**
 * Main Saga for watching saga workers for KYC
 *
 * @generator
 */
function* kycSagasWatcher() {
  // !VALIDATION: client
  if (process.env.REACT_APP_CLIENT === 'PS') {
    yield [yield debounce(500, getJumioDataAction.request, getJumioDataWorker)]
  }

  yield [
    yield debounce(
      500,
      getDocumentImagesAction.request,
      getDocumentImagesWorker,
    ),
    yield debounce(
      500,
      downloadDocumentImageAction.request,
      downloadImageDocumentWorker,
    ),
    yield debounce(500, uploadDocumentsAction.request, uploadDocumentWorker),
  ]
}

export default kycSagasWatcher
