import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import queryString from 'query-string'

import useForm from 'hooks/form/use-form.hook'
import { ResetPasswordPayload } from 'state/user/models'
import { resetPasswordAction } from 'state/user/actions/rest.actions'

import resetSchema from '../components/schema'
import resetPasswordValidator from '../components/validator'

export type ValidationType = {
  id: string
  name: string
  isInvalid: boolean
}

const useResetPassword = () => {
  const { search } = useLocation()

  const dispatch = useDispatch()

  const params = queryString.parse(search)

  const resetPassword = (resetPasswordPayload: ResetPasswordPayload) =>
    dispatch(resetPasswordAction.request(resetPasswordPayload))

  const { state, handleOnChange } = useForm(
    resetSchema,
    resetPasswordValidator,
    () => {},
  )

  const validations: ValidationType[] = [
    {
      id: 'password',
      name: '',
      isInvalid:
        state.newPassword.value === '' ||
        state.newPassword.error.length > 0 ||
        state.confirmNewPassword.value === '' ||
        state.confirmNewPassword.error.length > 0,
    },
    {
      id: 'password',
      name: 'passwordsMustMatch',
      isInvalid:
        state.newPassword.value !== '' &&
        state.confirmNewPassword.value !== '' &&
        state.confirmNewPassword.value !== state.newPassword.value,
    },
  ]

  const handleSave = () => {
    if (params?.username && params?.nn) {
      resetPassword({
        username: params.username as string,
        passwordResetToken: params.nn as string,
        newPassword: state.newPassword.value,
      })
    }
  }

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    handleOnChange(name, value)
  }

  return {
    state,
    validations,
    handleOnChangeInput,
    handleSave,
  }
}

export default useResetPassword
