import { select, call, put, delay } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import {
  getAllSegmentNamesAction,
  getAllTemplateNamesAction,
} from '../actions/index.actions'
import { NamesResponseType } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets all segment names by companyId
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllSegmentNamesAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllSegmentNamesWorker(
  action: ReturnType<typeof getAllSegmentNamesAction.request>,
) {
  try {
    const { auth } = yield select()

    apiService.requestParams = {
      method: 'GET',
      endpoint: 'segmentations/get/names',
      queries: jsonToQueryString({
        companyId: auth.userSession.companyId,
      }),
    }

    const response: NamesResponseType = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllSegmentNamesAction.success(response))
    yield delay(500)
    yield put(getAllTemplateNamesAction.request())
  } catch (error) {
    yield put(getAllSegmentNamesAction.failure(error))
  }
}

export default getAllSegmentNamesWorker
