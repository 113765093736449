import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const getAllOperatorHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.GET_OPERATORS_REQUEST:
      return {
        ...state,
        isLoading: action.payload?.page === 1,
      }

    case ActionTypes.GET_OPERATORS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        operators: action.payload?.docs,
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_OPERATORS_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default getAllOperatorHandler
