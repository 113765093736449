import SegmentationType from 'state/enums/segmentation-type.enum'
import ActionTypes from '../constants'
import { CampaignState, CampaignActions } from '../models'

const campaignGetAllSegmentsHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_SEGMENTS_REQUEST:
      return { ...state }

    case ActionTypes.GET_ALL_SEGMENTS_SUCCESS: {
      const segmentHasType = action.payload?.docs.some(item => item.type)
      return {
        ...state,
        segmentNames: segmentHasType
          ? action.payload?.docs.filter(
              item =>
                item.type === SegmentationType.DYNAMIC ||
                item.type === SegmentationType.STATIC ||
                item.type === SegmentationType.PLAYERS,
            ) || []
          : action.payload?.docs || [],
      }
    }

    case ActionTypes.GET_ALL_SEGMENTS_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default campaignGetAllSegmentsHandler
