import ActionTypes from '../constants'
import { TemplatePushState, TemplatePushActions } from '../models'

const templatePushGetAllHandler = (
  state: TemplatePushState,
  action: TemplatePushActions,
): TemplatePushState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templates: action.payload?.docs || [],
        pagination: {
          total: action.payload.total || 0,
          limit: action.payload.limit || 25,
          page: action.payload.page || 1,
          pages: action.payload.pages || 1,
        },
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default templatePushGetAllHandler
