import ActionTypes from '../constants'
import { CampaignState, CampaignActions } from '../models'

const campaignGetAllBonusesHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_BONUSES:
      return { ...state, bonusNames: action.payload }

    default:
      return state
  }
}

export default campaignGetAllBonusesHandler
