import { max, isEqual } from 'date-fns'
import { defaultColumnsList } from 'constants/wallet/withdrawal-columns.constant'
import TimeZoneUTCType from 'state/enums/timezone-utc.enum'

import UserSettingsTypeList from 'state/enums/type-user-settings-list.enum'
import WithdrawalsTabList from 'state/enums/withdrawals-tabs-list.enum'
import { getDateTimeFromIsoUTC } from 'utils/date'

import { UserSettingsResponse } from '../models'

export const getDate = (dateTimeUTC: string) =>
  getDateTimeFromIsoUTC(
    dateTimeUTC,
    TimeZoneUTCType['GMT+00 Coordinated Universal Time'],
  )

export const getLastUpdatedConfig = (settings: UserSettingsResponse[]) => {
  const dates = settings.map(s => getDate(s.updatedAt))

  const latestModification = max(dates)

  const [latestConfig] = settings.filter(s =>
    isEqual(latestModification, getDate(s.updatedAt)),
  )

  return latestConfig
}

export const emptyUserSettings = {
  _id: '',
  user: '',
  type: UserSettingsTypeList.FAVORITES,
  name: '',
  url: [],
  list: defaultColumnsList,
  tab: WithdrawalsTabList.PENDING,
  createdBy: '',
  updatedBy: '',
  createdAt: '',
  updatedAt: '',
}

export default getLastUpdatedConfig
