import { Service } from 'typedi'
import { RequestParams } from '../models'

/**
 * @description Handle API REST calls
 * @class FileStorageService
 */
@Service()
class FileStorageService {
  private requestParamsProp: RequestParams

  private url: string

  constructor() {
    this.url = ''
    this.requestParamsProp = {
      method: 'GET',
      endpoint: '',
      queries: '',
      headers: {
        'Content-Type': 'application/json',
        Authorization: '',
      },
    }
  }

  set requestParams(requestParams: RequestParams) {
    this.requestParamsProp = requestParams
  }

  get requestParams(): RequestParams {
    return this.requestParamsProp
  }

  /**
   *
   * @description Build the url to fetch
   * @memberof FileStorageService
   */
  private setUrl = async () => {
    const { endpoint, queries } = this.requestParamsProp
    const serverUrl = process.env && process.env.REACT_APP_URL_DOWNLOADER_SERVER
    const url = queries
      ? `${serverUrl}${endpoint}?${queries}`
      : `${serverUrl}${endpoint}`
    this.url = url
  }

  /**
   *
   * @description Method to perform request over the GET HTTP verb
   * @memberof FileStorageService
   */
  getData = async <D>(token: string): Promise<D> => {
    await this.setUrl()

    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: 'GET',
      })
      const data = await response.json()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the POST HTTP verb
   * @memberof FileStorageService
   */
  sendData = async <D>(apiKey?: string): Promise<D | string> => {
    const { body, method } = this.requestParamsProp
    await this.setUrl()

    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': `${apiKey}`,
        },
        method,
        body: JSON.stringify(body),
      })
      if (this.requestParamsProp.responseType === 'blob') {
        const imageBlob = await response.blob()
        return URL.createObjectURL(imageBlob)
      }
      const data = await response.json()

      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the PUT HTTP verb
   * @memberof FileStorageService
   */
  updateData = async <D>(apiKey?: string): Promise<D> => {
    const { body } = this.requestParamsProp

    await this.setUrl()

    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': `${apiKey}`,
        },
        method: 'PUT',
        body: JSON.stringify(body),
      })

      const data = await response.json()

      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @description Method to perform request over the DELETE HTTP verb
   * @memberof FileStorageService
   */
  deleteData = async <D>(apiKey?: string): Promise<D> => {
    const { method } = this.requestParamsProp

    await this.setUrl()

    try {
      const response = await fetch(this.url, {
        headers: {
          'Content-Type': 'application/json',
          'api-key': `${apiKey}`,
        },
        method,
      })

      const data = await response.json()

      return data
    } catch (error) {
      return error
    }
  }
}

export default FileStorageService
