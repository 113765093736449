import { takeLatest, takeEvery } from 'redux-saga/effects'
import {
  filterUserSettings,
  createUserSettings,
  updateUserSettings,
} from '../actions/rest.actions'
import filterUserSettingsWorker from './filter-user-settings.saga'

import createUserSettingsWorker from './create-user-settings.saga'
import updateUserSettingsWorker from './update-user-settings.saga'

/**
 * Main Saga for watching saga workers in the Settings state shape
 *
 * @generator
 */
function* settingsSagasWatcher() {
  yield [
    yield takeEvery(filterUserSettings.request, filterUserSettingsWorker),
    yield takeLatest(createUserSettings.request, createUserSettingsWorker),
    yield takeLatest(updateUserSettings.request, updateUserSettingsWorker),
  ]
}

export default settingsSagasWatcher
