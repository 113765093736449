import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  InputBase,
  TextField,
  FormControl,
  Chip,
  Typography,
  Tooltip,
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardArrowDown, Clear } from '@material-ui/icons'
import {
  transactionStatusList,
  transactionTypeList,
} from 'constants/transaction/index.constant'

import { TransactionSearchProps } from 'state/search/models'
import TransactionStatus from 'state/enums/transaction-status.enum'
import TransactionType from 'state/enums/transaction-type.enum'

import mapEnum from 'utils/enum-map.util'
import { TransactionsFilters } from 'state/wallet/models/transactions.model'

type Props = {
  transactionSearchProps: TransactionSearchProps
  setFiltersTransactions: (filters: TransactionsFilters) => void
  handleToggleFilters: (event: any) => void
  filtersTransactions: TransactionsFilters
  onKeyDown: (event: React.KeyboardEvent<any>) => void
  inputRef: React.RefObject<any>
}

const InputsTransactionComponent = ({
  transactionSearchProps,
  setFiltersTransactions,
  handleToggleFilters,
  filtersTransactions,
  onKeyDown,
  inputRef,
}: Props) => {
  const { t } = useTranslation()

  const transactionStatuses = mapEnum(
    TransactionStatus,
    (index: TransactionStatus) => index,
  )

  const transactionTypes = mapEnum(
    TransactionType,
    (index: TransactionType) => index,
  )

  const transactionStatusTooltip =
    filtersTransactions.transactionStatus
      ?.map((item: TransactionStatus) => {
        return transactionStatusList[item].name
      })
      .join(', ') || ''

  const transactionTypeTooltip =
    filtersTransactions.transactionType
      ?.map((item: TransactionType) => {
        return transactionTypeList[item].name
      })
      .join(', ') || ''

  return (
    <>
      {transactionSearchProps.filter === 'playerId' && (
        <InputBase
          inputRef={inputRef}
          placeholder={t('findTransaction')}
          autoComplete="off"
          fullWidth
          name="playerId"
          margin="dense"
          value={filtersTransactions.playerId || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setFiltersTransactions({
              playerId: event.currentTarget.value,
            })
          }
          onFocus={() => handleToggleFilters(true)}
          onKeyDown={onKeyDown}
        />
      )}
      {transactionSearchProps.filter === 'transactionStatus' && (
        <FormControl fullWidth>
          <Autocomplete
            filterSelectedOptions
            limitTags={2}
            getLimitTagsText={more => {
              return (
                <Tooltip title={transactionStatusTooltip}>
                  <Typography variant="caption">{`+${more}`}</Typography>
                </Tooltip>
              )
            }}
            forcePopupIcon={false}
            multiple
            popupIcon={<KeyboardArrowDown />}
            id="transactionStatus"
            options={transactionStatuses}
            getOptionLabel={(option: TransactionStatus) => {
              return transactionStatusList[option].name
            }}
            value={filtersTransactions.transactionStatus || []}
            onChange={(event: any, newValue: any | null) => {
              setFiltersTransactions({
                transactionStatus: newValue,
              })
            }}
            onFocus={() => handleToggleFilters(true)}
            renderInput={params => (
              <TextField
                onKeyDown={onKeyDown}
                inputRef={inputRef}
                {...params}
                fullWidth
                margin="none"
              />
            )}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                let returnValue = null
                if (option !== undefined) {
                  returnValue = (
                    <Chip
                      deleteIcon={<Clear />}
                      label={transactionStatusList[option].name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  )
                }
                return returnValue
              })
            }}
          />
        </FormControl>
      )}
      {transactionSearchProps.filter === 'transactionType' && (
        <FormControl fullWidth>
          <Autocomplete
            filterSelectedOptions
            limitTags={2}
            getLimitTagsText={more => {
              return (
                <Tooltip title={transactionTypeTooltip}>
                  <Typography variant="caption">{`+${more}`}</Typography>
                </Tooltip>
              )
            }}
            forcePopupIcon={false}
            multiple
            popupIcon={<KeyboardArrowDown />}
            id="transactionType"
            options={transactionTypes}
            getOptionLabel={(option: TransactionType) => {
              return transactionTypeList[option].name
            }}
            value={filtersTransactions.transactionType || []}
            onChange={(event: any, newValue: any | null) => {
              setFiltersTransactions({
                transactionType: newValue,
              })
            }}
            onFocus={() => handleToggleFilters(true)}
            renderInput={params => (
              <TextField
                onKeyDown={onKeyDown}
                inputRef={inputRef}
                {...params}
                fullWidth
                margin="none"
              />
            )}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                let returnValue = null
                if (option !== undefined) {
                  returnValue = (
                    <Chip
                      deleteIcon={<Clear />}
                      label={transactionTypeList[option].name}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  )
                }
                return returnValue
              })
            }}
          />
        </FormControl>
      )}
    </>
  )
}

export default InputsTransactionComponent
