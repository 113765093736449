import ActionTypes from '../constants'
import { SearchActions, SearchState } from '../models'

const setSearchPropsHandler = (
  state: SearchState,
  action: SearchActions,
): SearchState => {
  switch (action.type) {
    case ActionTypes.SET_PLAYER_SEARCH_PROPS:
      return {
        ...state,
        playerSearchProps: {
          ...state.playerSearchProps,
          ...action.payload,
        },
      }

    case ActionTypes.SET_TRANSACTION_SEARCH_PROPS:
      return {
        ...state,
        transactionSearchProps: {
          ...state.transactionSearchProps,
          ...action.payload,
        },
      }

    case ActionTypes.SET_SEARCH_TAB:
      return {
        ...state,
        tab: action.payload,
      }

    default:
      return state
  }
}

export default setSearchPropsHandler
