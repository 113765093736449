import { ValidationSchema } from 'hooks/form/models'
import {
  passwordValidators,
  usernameOrEmail,
} from 'constants/regex/index.constant'
import { setRegexLength } from 'utils/text/index.utils'

import i18n from 'i18n'

const loginValidator: ValidationSchema = {
  email: {
    required: true,
    validators: [
      {
        regExp: usernameOrEmail,
        error: 'Format example@email.com or username',
      },
      {
        regExp: setRegexLength(60),
        error: i18n.t('messages.inputMaxLength', {
          field: 'Email',
          max: '60',
        }),
      },
    ],
  },
  password: {
    required: true,
    validators: passwordValidators,
  },
}

export default loginValidator
