import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  List,
  Box,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'

import { Add, Delete, Edit } from '@material-ui/icons'
import { StateSchema } from 'hooks/form/models'
import ShortcutType from 'state/enums/shortcut.enum'

import { IconButtonBg } from 'theme/icon-button.theme'
import ShortcutLinkComponent from './link.component'
import useSidebarShortcuts, {
  PopperStateType,
} from '../../hooks/use-sidebar-shortcuts.hook'

import useStyles from '../../style'

type Props = {
  handleDrawerClose: () => void
  handleShortcutAddOpen: (event: React.MouseEvent<HTMLElement>) => void
  handleShortcutEditOpen: (event: React.MouseEvent<HTMLElement>) => void
  handleShortcutMenuOpen: (
    event: React.MouseEvent<HTMLElement>,
    type: ShortcutType,
    _id: string,
  ) => void
  handleShortcutMenuClose: () => void
  handleDeleteShortcut: (_id: string) => void
  shortcutMenuState: PopperStateType
  setFormValues: (newState: StateSchema) => void
  openDrawer: boolean
}

const ShortcutsComponent = ({
  handleDrawerClose,
  handleShortcutAddOpen,
  handleShortcutEditOpen,
  handleShortcutMenuOpen,
  handleShortcutMenuClose,
  handleDeleteShortcut,
  shortcutMenuState,
  setFormValues,
  openDrawer,
}: Props) => {
  const { t } = useTranslation()

  const classes = useStyles()

  const { shortcuts } = useSidebarShortcuts()

  const handleEditShortcut = (event: React.MouseEvent<HTMLElement>) => {
    const shortcut = shortcuts.find(item => item._id === shortcutMenuState.id)
    setFormValues({
      id: {
        value: shortcut?._id || '',
        error: '',
      },
      name: {
        value: shortcut?.name || '',
        error: '',
      },
      url: {
        value: shortcut?.url || '',
        error: '',
      },
    })
    handleShortcutEditOpen(event)
  }

  return (
    <>
      <Box
        mt={1}
        px={0.5}
        py={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
        className={classes.fadeIn}
      >
        <Typography variant="body2" className="fontWeight-700">
          {t('sidebar.shortcuts')}
        </Typography>
        <IconButtonBg
          id="shortcutAdd"
          color="primary"
          onClick={handleShortcutAddOpen}
        >
          <Add />
        </IconButtonBg>
      </Box>
      <Box
        clone
        mt="4px!important"
        mb="8px!important"
        className={shortcuts?.length === 0 ? classes.fadeIn : ''}
      >
        <Divider light />
      </Box>

      {shortcuts?.length === 0 && openDrawer ? (
        <Typography variant="caption" style={{ whiteSpace: 'normal' }}>
          {t('sidebar.emptyShortcutsMessage')}
        </Typography>
      ) : (
        <Box display="flex" flexDirection="column" clone>
          <List disablePadding component="nav">
            {shortcuts
              ?.sort((a, b) => -b.name.localeCompare(a.name))
              .map(link => (
                <Box key={link._id}>
                  <ShortcutLinkComponent
                    link={link}
                    handleDrawerClose={handleDrawerClose}
                    handleShortcutMenuOpen={handleShortcutMenuOpen}
                    openDrawer={openDrawer}
                  />
                </Box>
              ))}
          </List>
        </Box>
      )}
      <Menu
        id="simple-menu"
        anchorEl={shortcutMenuState.anchorEl}
        keepMounted
        open={shortcutMenuState.isOpen}
        onClose={handleShortcutMenuClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
        PaperProps={{
          style: {
            minWidth: 165,
          },
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        {shortcutMenuState.type === ShortcutType.CUSTOM && (
          <MenuItem id="shortcutEdit" onClick={handleEditShortcut}>
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('labels.edit')} />
          </MenuItem>
        )}
        <MenuItem onClick={() => handleDeleteShortcut(shortcutMenuState.id)}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('labels.delete')} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default ShortcutsComponent
