import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import storage from 'redux-persist/lib/storage'

import { persistReducer } from 'redux-persist'
import websocketsReducer from './middleware/sockets/reducers'
import authReducer from './auth/reducers'

import emailTrackingReducer from './email-tracking/reducers'
import companyReducer from './company/reducers/index.reducer'
import templateEmailReducer from './template-email/reducers/index.reducer'

import campaignReducer from './campaign/reducers/index.reducer'
import segmentReducer from './segment/reducers/index.reducer'
import emailBuilderReducer from './ui/email-builder/reducers/index.reducer'

import userReducer from './user/reducers/index.reducer'
import globalUiReducer from './ui/global/reducers/index.reducer'
import companyUiReducer from './ui/company/reducers/index.reducer'

import analyticsReducer from './analytics/reducers/index.reducer'
import playerCardReducer from './player-card/reducers/index.reducer'
import playerCardTagReducer from './player-card-tag/reducers/index.reducer'

import playerCardLoginReducer from './player-card-login/reducers/index.reducer'
import playerCardGamingActivityReducer from './player-card-gaming-activity/reducers/index.reducer'
import playerCardNoteReducer from './player-card-note/reducers/index.reducer'

import {
  transactionsReducer,
  walletReducer,
  personalizationEngineReducer,
} from './wallet/reducers/index.reducer'
import searchReducer from './search/reducers/index.reducer'
import {
  playerCardKYCDocumentReducer,
  playerCardKYCHistoryReducer,
  playerCardKYCStatusReducer,
} from './player-card-kyc/reducers/index.reducer'

import tagsReducer from './tags/reducers/index.reducer'
import bonusReducer from './bonus/reducers/index.reducer'
import playerCardKYCUiReducer from './ui/player-card-kyc/reducers/index.reducer'

import limitsReducer from './limit/reducers/index.reducer'
import coolOffReducer from './cool-off/reducers/index.reducer'
import assessmentReducer from './assessment/reducers/index.reducer'

import IssueReducer from './issues/reducers/index.reducer'
import teledataReducer from './teledata/reducers/index.reducer'
import annexeReducer from './annexe/reducers/index.reducer'

import logReducer from './log/reducers/index.reducer'
import blacklistReducer from './blacklist/reducers/index.reducer'
import playerCardSummaryReducer from './player-card-summary/reducers/index.reducer'

import templateSmsReducer from './template-sms/reducers/index.reducer'
import roleReducer from './role/reducers/index.reducer'
import rewardsReducer from './reward/reducers/index.reducer'

import bonusEngineReducer from './bonus-engine/reducers/index.reducer'
import rulesEngineReducer from './rules-engine/reducers/index.reducer'
import referFriendReducer from './refer-friend/reducers/index.reducer'

import gameStoreReducer from './game-store/reducers/index.reducer'
import ggrReportReducer from './ggr-report/reducers/index.reducer'
import frequencyCapsReducer from './frequency-cap/reducers/index.reducer'

import levelDefinitionReducer from './levels/reducers/index.reducer'
import settingsReducer from './settings/reducers/index.reducer'
import userLogsReducer from './user-logs/reducers/index.reducer'

import customTriggersReducer from './custom-trigger/reducers/index.reducer'
import templatePushReducer from './template-push/reducers/index.reducer'
import templateOnSiteReducer from './template-onsite/reducers/index.reducer'

import mediaDatabaseReducer from './media-database/reducers/index.reducer'
import bonusReportReducer from './bonus-report/reducers/index.reducer'
import playerReportReducer from './player-report/reducers/index.reducer'

import missionsReducer from './missions/reducers/index.reducer'

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isLoading'],
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    analytics: analyticsReducer,
    annexe: annexeReducer,
    assessment: assessmentReducer,
    auth: persistReducer(authPersistConfig, authReducer),
    blacklist: blacklistReducer,
    bonus: bonusReducer,
    bonusEngine: bonusEngineReducer,
    bonusReport: bonusReportReducer,
    campaign: campaignReducer,
    company: companyReducer,
    companyUi: companyUiReducer,
    coolOff: coolOffReducer,
    customTriggers: customTriggersReducer,
    email: emailTrackingReducer,
    emailBuilder: emailBuilderReducer,
    frequencyCap: frequencyCapsReducer,
    globalUi: globalUiReducer,
    gameStore: gameStoreReducer,
    ggrReport: ggrReportReducer,
    issue: IssueReducer,
    levels: levelDefinitionReducer,
    limit: limitsReducer,
    log: logReducer,
    mediaDatabase: mediaDatabaseReducer,
    missions: missionsReducer,
    personalizationEngine: personalizationEngineReducer,
    playerCard: playerCardReducer,
    playerCardTag: playerCardTagReducer,
    playerCardLogin: playerCardLoginReducer,
    playerCardNote: playerCardNoteReducer,
    playerCardGamingActivity: playerCardGamingActivityReducer,
    playerCardKYCDocument: playerCardKYCDocumentReducer,
    playerCardKYCHistory: playerCardKYCHistoryReducer,
    playerCardKYCStatus: playerCardKYCStatusReducer,
    playerCardKYCUi: playerCardKYCUiReducer,
    playerCardSummary: playerCardSummaryReducer,
    playerReport: playerReportReducer,
    referFriend: referFriendReducer,
    rewards: rewardsReducer,
    role: roleReducer,
    rulesEngine: rulesEngineReducer,
    search: searchReducer,
    segment: segmentReducer,
    settings: settingsReducer,
    tag: tagsReducer,
    teledata: teledataReducer,
    templateEmail: templateEmailReducer,
    templateOnSite: templateOnSiteReducer,
    templatePush: templatePushReducer,
    templateSms: templateSmsReducer,
    user: userReducer,
    userLogs: userLogsReducer,
    wallet: walletReducer,
    walletTransactions: transactionsReducer,
    websockets: websocketsReducer,
  })

export default createRootReducer
