import ActionTypes from '../constants'
import { TemplateSmsActions, TemplateSmsState } from '../models'

const templateSmsCreateHandler = (
  state: TemplateSmsState,
  action: TemplateSmsActions,
): TemplateSmsState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
      }

    case ActionTypes.SET_LANGUAGES_SELECTED:
      return {
        ...state,
        languagesSelected: action.payload,
      }

    default:
      return state
  }
}

export default templateSmsCreateHandler
