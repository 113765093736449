const transactionsBonuses = {
  transactionTypes: {
    DEPOSIT: 'Deposit',
    WITHDRAW: 'Withdraw',
    WAGER: 'Bet',
    WIN: 'Win',
    DEPOSIT_CANCEL: 'Deposit Cancel',
    WITHDRAW_CANCEL: 'Withdraw Cancel',
    WAGER_CANCEL: 'Wager Cancel',
    WIN_CANCEL: 'Win Cancel',
    DEPOSIT_MANUAL: 'Deposit Manual',
    WITHDRAW_MANUAL: 'Withdraw Manual',
    BONUS_ADD: 'Bonus Add',
    BONUS_REDEEM: 'Bonus Redeem',
    BONUS_CANCEL: 'Bonus Cancel',
    REAL_TO_BONUS: 'Real to Bonus',
    BONUS_TO_REAL: 'Bonus to Real',
    FREE_GAME_BONUS_ADD: 'Free Game Bonus Add',
    FREE_GAME_WAGER: 'Free Game Wager',
    FREE_GAME_WIN: 'Free Game Win',
    FREE_GAME_BONUS: 'Free Game Bonus',
    FREE_GAME_REAL: 'Free Game Real',
  },
  typeAbbreviation: {
    DEPOSIT: 'D',
    WITHDRAW: 'W',
    WAGER: 'Bet',
    WIN: 'Win',
    DEPOSIT_CANCEL: 'D-C',
    WITHDRAW_CANCEL: 'W-C',
    WAGER_CANCEL: 'Bet-C',
    WIN_CANCEL: 'Win-C',
    DEPOSIT_MANUAL: 'D-M',
    WITHDRAW_MANUAL: 'W-M',
    BONUS_ADD: 'Bon-A',
    BONUS_REDEEM: 'Bon-R',
    BONUS_CANCEL: 'Bon-C',
    REAL_TO_BONUS: 'Real-Bon',
    BONUS_TO_REAL: 'Bon-Real',
    FREE_GAME_BONUS_ADD: 'FR-BONUS',
    FREE_GAME_WAGER: 'FR-BET',
    FREE_GAME_WIN: 'FR-WIN',
    FREE_GAME_BONUS: 'FG-B',
    FREE_GAME_REAL: 'FG-R',
  },
  transactionStatuses: {
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    PENDING: 'Pending',
    CANCELED: 'Cancelled',
    EXECUTED: 'Executed',
    COMPLETED: 'Completed',
    DECLINED: 'Declined',
    LATE_BONUS_WIN: 'Late Bonus Win',
  },
  paymentMethodOptions: {
    BANK_TRANSFER: 'Bank Transfer',
    TRUSTLY: 'Trustly',
    PAYSAFECARD: 'Paysafecard',
    CC_CARD: 'Card',
    APLAUZ: 'APLAUZ',
    TWINT: 'TWINT',
    POSTFINANCE: 'PostFinance',
    POSTCARD: 'PostCard',
    APMGW_MONEYBOOKERS: 'Skrill (Moneybookers)',
    APMGW_NETELLER: 'Neteller',
    APMGW_PAYSAFECARD: 'Paysafecard',
    APMGW_POSTFINANCE_EFINANCE: 'PostFinance Efinance',
  },
  cardCompanyOptions: {
    VISA: 'Visa',
    MASTERCARD: 'MasterCard',
    AMEX: 'Amex',
    UATP: 'UATP',
    DINERS: 'Diners',
    MAESTRO: 'Maestro',
    SWITCH: 'SWITCH',
    DISCOVER: 'Discover',
    ISRAELICARD: 'IsraeliCard',
    MIR: 'MIR',
  },
  bonusState: {
    ACCEPTED_AWAITING_EVENT: 'Accepted Awaiting Event',
    ELIGIBLE: 'Eligible',
    FREE_GAME_ACTIVE: 'Active Free Games',
    GRANTED: 'Granted',
    GRANTED_PENDING_ACCEPT: 'Granted Pending Accept',
    GRANTED_PENDING_ACTIVATION: 'Granted Pending Activation',
    GRANTED_FREE_GAMES: 'Granted Free Games',
    CANCELED: 'Cancelled',
    COMPLETED: 'Completed',
    CRITERIA_NOT_MET: 'Criteria Not Met',
    DECLINED: 'Declined',
    ENDED: 'Ended',
    EXCLUDED: 'Excluded',
    EXPIRED: 'Expired',
    GRANTED_EXPIRED: 'Granted Expired',
  },
  transactionCategoryOptions: {
    UNDEFINED: 'Undefined',
    MANUAL_DEPOSIT: 'Manual Deposit',
    REFUND_RNG: 'Refund RNG',
    REFUND_LIVE: 'Refund Live',
    REFUND_CRM: 'Refund CRM',
    MANUAL_DUPE_ACC: 'Manual Dupe Acc',
  },
  downloadTransactions:
    'Transaction reports are downloaded from the newest to the oldest',
  activeBonuses: 'Pending & Active Bonuses',
  addBonusAmount: 'Add Bonus Amount',
  addBonusProgram: 'Add Bonus Program',
  addBonusStandard: 'Add Standard Bonus',
  addDepositBonus: 'Add Deposit Bonus',
  addFreeRounds: 'Add Free Rounds',
  addManualBonus: 'Add Manual Bonus',
  addNewWr: 'Add New WR',
  allBonuses: 'All Bonuses',
  amount: 'Amount',
  amountConverted: 'Converted',
  amountToCombine: 'Amount to Combine',
  applyPlayerSegmentation: 'Apply Player Segmentation',
  approved: 'Approved',
  balanceAfter: 'Balance After',
  balanceBefore: 'Balance Before',
  bankTransfer: 'Bank Transfer',
  betLevel: 'Bet Level',
  BONUS_ALREADY_GRANTED: 'This bonus was already granted.',
  bonusAmount: 'Bonus Amount',
  bonusAndSegmentation: 'Bonus & Segmentation',
  bonusDepositRatio: 'Bonus/Deposit Ratio',
  bonusDepositRatioText:
    'This ratio shows how many deposit bonuses are taken by the player for each deposit done. The closer it is to 1, the probability of being a bonus hunter increases and further evaluation is suggested.',
  bonuses: 'Bonuses',
  bonusId: 'Bonus ID',
  bonusName: 'Bonus Name',
  bonusProgram: 'Bonus Program',
  bonusType: 'Bonus Type',
  cancelled: 'Cancelled',
  cardCompany: 'Card Company',
  coinValue: 'Coin Value',
  combine: 'Combine',
  combinePlayerFunds: 'Combine Player Funds',
  completed: 'Completed',
  completedBonuses: 'Completed & Other Finished Bonuses',
  completedDate: 'Completed Date',
  credit: 'Credit',
  creditAmount: 'Credit Amount',
  currentAmount: 'Current Amount',
  debit: 'Debit',
  debitAmount: 'Debit Amount',
  declinedByBank: 'Declined by bank',
  declined: 'Declined',
  depositAmount: 'Deposit Amount',
  depositAndManual: 'Deposits and Manual Deposits',
  depositBonus: 'Deposit Bonus',
  depositTxnId: 'Deposit Txn ID',
  depositTxnIDError: 'This deposit Txn ID is already used by another bonus.',
  description: 'Description',
  error: 'Error',
  errorDepositBonus:
    'Invalid Deposit Transaction ID or Bonus, please enter correct information.',
  errorBonusNotCampaign: 'Bonus selected is not part of an existing campaign',
  executed: 'Executed',
  expiration: 'Expiration',
  extend: 'Extend',
  filterByDate: 'Filter by Date',
  freeRounds: 'Free Rounds',
  freeSpinsAmount: 'Free Spins Amount',
  from: 'From',
  gameProviderAndGame: 'Game Provider & Game',
  gamificationBonuses: 'Gamification Bonuses',
  grantedAmount: 'Granted Amount',
  grantedDate: 'Granted Date',
  ignore: 'Ignore',
  inDays: 'In Days',
  insufficientFunds: 'Insufficient funds',
  loadingBonuses: 'Loading bonuses...',
  lateBonusWin: 'Late Bonus Win',
  lockedAmount: 'Locked Amount',
  maxBonusAmount: 'Max Amount {{currency}} {{amount}}',
  method: 'Method',
  orderId: 'Order ID',
  overrideDefaultWr: 'Override Default WR',
  paycardNumber: 'Paycard Number',
  paymentInfo: 'Payment Info',
  paymentInfoType: 'Type',
  paymentMethod: 'Payment Method',
  pending: 'Pending',
  realBalance: 'Real Balance {{currency}} {{amount}}',
  reason: 'Reason',
  rejected: 'Rejected',
  searchByTransactionId: 'Search by Transaction ID',
  searchFilter: 'Search Filter',
  seeMore: 'See more',
  selectExistentBonusId: 'Select Existent Bonus ID',
  selectGame: 'Select Game',
  selectGameProvider: 'Select Game Provider',
  selectOneOrMore: 'Select one or more',
  spinsValue: 'Spins Value',
  standardBonus: 'Standard Bonus',
  standardBonuses: 'Standard Bonuses',
  status: 'Status',
  statusLabel: 'Status',
  submittedDate: 'Submitted Date',
  to: 'To',
  transactionAmount: 'Transaction Amount',
  transactionDateAndTime: 'Transaction Date And Time',
  transactionId: 'Transaction ID',
  transactionMethod: 'Method',
  transactionNumber: 'Number',
  transactions: 'Transactions',
  transactionsReport: 'Transactions Report',
  transactionsBonuses: 'Trans. & Bonuses',
  transactionStatus: 'Transaction Status',
  transactionType: 'Transaction Type',
  depositCategory: 'Deposit Category',
  visa: 'Visa',
  wageringRequirement: 'Wagering Requirement',
  wallet: 'Wallet',
  wr: 'Wagering Requirement',
  wrMessage: 'Optional. If left blank will default to 30x.',
  wrMessagePS: 'Optional. If left blank will default to 35x.',
  wrMessageGF: 'Optional. If left blank will default to 25x.',
  monthly: 'Monthly',
  bonusMaximumAmountOrCountReached: 'Bonus maximum amount or count reached.',
  allTransactions: 'All Transactions',
}
export default transactionsBonuses
