import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'
import initialState from './initial-state'

const getLoggedInHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.GET_LOGGED_IN_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_LOGGED_IN_SUCCESS:
      sessionStorage.setItem('user', JSON.stringify(action.payload))
      return {
        ...state,
        isLoading: false,
        loggedInUser: action.payload,
      }

    case ActionTypes.GET_LOGGED_IN_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_USER_STATE:
      return initialState
    default:
      return state
  }
}

export default getLoggedInHandler
