import ActionTypes from '../constants'
import { PlayerCardKYCDocumentState, PlayerCardKYCActions } from '../models'

const deleteDocumentHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.DELETE_DOCUMENT:
      return {
        ...state,
      }

    case ActionTypes.DELETE_DOCUMENT_SUBSCRIBER:
      return {
        ...state,
        documents: state.documents?.filter(doc => {
          return doc.imageId !== action.payload.imageId
        }),
        documentImages: state.documentImages?.filter(doc => {
          return doc.generation !== action.payload.imageId
        }),
      }

    case ActionTypes.DELETE_DOCUMENT_EXCEPTION:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default deleteDocumentHandler
