import { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { ApplicationState, WebSocketsNamespaceModel } from 'state/root.models'

import { SocketActions } from 'state/middleware/sockets/index'
import { isGatewayConnected } from 'state/middleware/sockets/utils'
import WS_NAMESPACES from 'state/websocket.namespaces'

type SubscriptionModel = {
  isIO: boolean
  host?: string
  port?: string
  namespace: WebSocketsNamespaceModel
  token?: string | undefined
  queryToken?: string | undefined
  subscribers?: (() => (dispatch: any) => any)[] // eslint-disabled-line @typescript-eslint/no-explicit-any
}

/**
 * @hook useWebSocketSubscription
 *
 * @description
 *
 * This custom hook provides:
 *
 * 1) Authenticated websocket connection via namespace
 *
 * 2) Subscriptions to a websocket event
 *
 * 3) The subscriptions will attach a redux action. This is useful to dispatch
 *    an action when the websocket event returns a payload from the server.
 *
 * 4) Core hooks to trigger view rendering
 *
 * @param {SubscriptionModel} - An object containing the namespace and an array of subscribers
 *
 * @example
 * ```ts
 *   useWebSocketSubscription({
 *     namespace: WS_NAMESPACE.USER,
 *     subscribers: [
          updateUserSubscriber,
          createUserSubscriber,
          deleteUserSubscriber,
        ],
 *   })
 * ```
 */
const useWebSocketSubscription = ({
  isIO,
  host,
  port,
  namespace,
  token,
  subscribers,
  queryToken
}: SubscriptionModel) => {
  const dispatch = useDispatch()

  const { gateways } = useSelector(
    ({ websockets }: ApplicationState) => websockets,
  )

  const { legacyToken } = useSelector(
    ({ auth }: ApplicationState) => auth,
    shallowEqual,
  )

  useEffect(() => {
    if (isIO) {

      dispatch(
        SocketActions.registerGateway({
          isIO,
          namespace,
          host: host || process.env.REACT_APP_SERVER_URL,
          port,
          token
        }),
      )

    } else {
      if (
        namespace === WS_NAMESPACES.ADMIN_LEGACY
      ) {
        if (!token || token?.length === 0) return
        dispatch(
          SocketActions.registerGateway({
            isIO,
            namespace,
            host,
            token,
            port: port || process.env.REACT_APP_ADMIN_SERVICE_PORT,
          }),
        )
      } else {
        dispatch(
          SocketActions.registerGateway({
            isIO,
            namespace,
            host,
            token,
            port: port || process.env.REACT_APP_ADMIN_SERVICE_PORT,
          }),
        )
      }
    }
  }, [token, namespace, dispatch, host, isIO, port, legacyToken]) // eslint-disable-line

  /**
   * @description Subscribe events if the current Gateway is connected
   */
  useEffect(() => {
    const isConnected = isGatewayConnected(gateways)

    if (isConnected && subscribers) {
      // eslint-disable-next-line
      subscribers.forEach(subscribe => {
        return dispatch(subscribe())
      })
    }
  }, [gateways, dispatch, subscribers, token])
}
export default useWebSocketSubscription
