import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'

const templateCreateHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.CREATE_EMAIL_REQUEST:
      return { ...state, isLoadingFull: true }

    case ActionTypes.CREATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailTemplates: state.emailTemplates.concat(action.payload),
        isLoadingFull: false,
      }

    case ActionTypes.CREATE_EMAIL_FAILURE:
      return {
        ...state,
        isLoadingFull: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default templateCreateHandler
