import PlayerDocumentStatusType from 'state/enums/player-document-status.enum'
import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'
import ActionTypes from '../constants'

import { mergeDocumentImages } from '../utils'

const getDocumentImagesHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.GET_DOCUMENT_IMAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingTable: true,
      }

    case ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS:
      // eslint-disable-next-line
      const documentImages = mergeDocumentImages(
        state.documentImages,
        state.documents,
        action.payload.images,
        action.payload.requestType,
      )
      return {
        ...state,
        isLoading: false,
        isLoadingTable: false,
        documentImages,
        paginationDocuments: {
          ...state.paginationDocuments,
          total: documentImages.length || state.paginationDocuments.total,
          pages: Math.ceil(
            documentImages.length / state.paginationDocuments.limit,
          ),
        },
      }

    case ActionTypes.GET_DOCUMENT_IMAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingTable: false,
      }

    case ActionTypes.SET_CURRENT_IMAGE_URL:
      return {
        ...state,
        currentDownloadedImage: action.payload,
      }

    case ActionTypes.SET_CURRENT_SECOND_IMAGE_URL:
      return {
        ...state,
        currentDownloadedSecondImage: action.payload,
      }

    case ActionTypes.SET_DOCUMENT_FORMAT:
      return {
        ...state,
        documentPreview: action.payload,
      }

    case ActionTypes.SET_JUMIO_STATUS:
      return {
        ...state,
        jumioDoc: {
          ...state.jumioDoc,
          status: action.payload,
        },
      }

    case ActionTypes.SET_JUMIO_INFO:
      return {
        ...state,
        jumioInfo: { ...action.payload },
        jumioDoc: {
          ...state.jumioDoc,
          createdAtText: action.payload.created,
          documentId: action.payload.documentId || '',
          status:
            PlayerDocumentStatusType[
              action.payload
                .gamanzaStatus as keyof typeof PlayerDocumentStatusType
            ],
          id: action.payload.gamanzaId,
        },
        isLoading: false,
      }

    case ActionTypes.TOGGLE_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }

    default:
      return state
  }
}

export default getDocumentImagesHandler
