const pages = {
  pages: {
    home: 'Home',

    crm: 'CRM',
    create: 'Create',
    edit: 'Edit',
    update: 'Update',

    pushTemplate: 'Push Notification Template',
    pushTemplates: 'Push Notification Templates',

    smsTemplate: 'SMS Template',
    smsTemplates: 'SMS Templates',

    emailTemplate: 'Email Template',
    emailTemplates: 'Email Templates',

    onsiteTemplate: 'On-site Notification',
    onsiteTemplates: 'On-site Notification Templates',

    segment: 'Segment',
    segments: 'Segments',

    customTrigger: 'Custom Trigger',
    customTriggers: 'Custom Triggers',

    campaign: 'Campaign',
    campaigns: 'Campaigns',

    analytics: 'Analytics',

    mediaDatabase: 'Media Database',

    tag: 'Tag',
    tags: 'Tags',
    admin: 'Admin',
    player: 'Player',

    accountStore: 'Account Store',
    alerts: 'Alerts',

    settings: 'Settings',
    clients: 'Clients',
    clientSettings: 'Client Settings',
    user: 'User',
    users: 'Users',
    userManagement: 'User Management',
    userLogs: 'User Logs',

    reports: 'Reports',
    referAFriend: 'Refer A Friend',
    vetoInternalBlacklist: 'VETO Internal Blacklist',
    vetoInternalBlacklistAudit: 'VETO Internal Blacklist Audit',
    teledata: 'Teledata',
    transactions: 'Transactions',
    ggrReport: 'GGR Report',
    playerReport: 'Player Report',

    rule: 'Rule',
    rules: 'Rules',
    rulesEngine: 'Rules Engine',

    bonus: 'Bonus',
    bonusEngine: 'BonusEngine',
    offer: 'Offer',
    offers: 'Offers',
    bonusOffer: 'Bonus Offer',
    bonusOffers: 'Bonus Offers',
    bonusCampaign: 'Bonus Campaign',
    bonusCampaigns: 'Bonus Campaigns',

    bonusReport: 'Bonus Report',

    gamification: 'Gamification',
    rewardShops: 'Reward Shops',
    rewardShop: 'Reward Shop',
    levels: 'Levels',

    withdrawals: 'Withdrawals',

    gameStore: 'Game Store',
    casinoGames: 'Casino Games',
    game: 'Game',
    games: 'Games',
    gameProviders: 'Game Providers',

    frequencyCap: 'Frequency Cap',

    missions: 'Missions',
  },
}

export default pages
