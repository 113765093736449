import ActionTypes from '../constants'
import { TemplatePushActions, TemplatePushState } from '../models'

const templatePushCreateHandler = (
  state: TemplatePushState,
  action: TemplatePushActions,
): TemplatePushState => {
  switch (action.type) {
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    default:
      return state
  }
}

export default templatePushCreateHandler
