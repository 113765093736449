import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const {
  blueMedium,
  blueDark,
  blueDarkNav,
  black,
  blue,
  grayDark,
  white,
  blueNav,
} = colors

export const paperTheme = {
  rounded: {
    borderRadius: 4,
  },
  root: {
    backgroundColor: blueDark,
    '&.bg-blueDarkNav': {
      backgroundColor: blueDarkNav,
    },
    '&.bg-blueMedium': {
      backgroundColor: blueMedium,
    },
    '&.bg-blackFade': {
      backgroundColor: alpha(black, 0.5),
    },
    '&.bg-blueDark': {
      backgroundColor: blueDark,
    },
    '&.bg-blue': {
      backgroundColor: blue,
    },
    '&.bg-grayDark': {
      backgroundColor: grayDark,
    },
    '&.bg-transparent': {
      backgroundColor: 'transparent !important',
    },
    '&.bg-blueNav': {
      backgroundColor: blueNav,
    },
  },
  outlined: {
    border: `1px solid ${alpha(white, 0.5)}`,
  },
}
