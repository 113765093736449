import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import { push } from 'connected-react-router'
import routes from 'routes/index.routes'

import {
  setDialogAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { createCustomTriggerAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)
/**
 *
 * This saga create a Custom Trigger
 *
 *
 *
 * @param {ReturnType<typeof createCustomTriggerAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createCustomTriggerWorker(
  action: ReturnType<typeof createCustomTriggerAction.request>,
) {
  try {
    yield put(setLoadingFullAction(true))

    apiService.requestParams = {
      method: 'POST',
      endpoint: 'campaigns/triggers/create',
      body: action.payload,
    }

    const { auth } = yield select()

    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.createdSuccessfully', {
      item: i18n.t('pages.customTrigger'),
    })

    if (response.id) {
      yield put(createCustomTriggerAction.success(response))
      yield put(push(`${routes.customTrigger.EDIT}/${response.id}`))
      yield put(
        setDialogAction({ open: true, id: 'createCustomTriggerSuccess' }),
      )
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')
      yield put(createCustomTriggerAction.failure(response))
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response.id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(createCustomTriggerAction.failure(error))
  }
}

export default createCustomTriggerWorker
