import { UserState, UserSocketActions, UserResponse } from '../models'
import ActionTypes from '../constants'
import removeDuplicates from '../../../utils/state/remove-duplicates.util'

const userSubscribersHandler = (
  state: UserState,
  action: UserSocketActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.CREATE_SUBSCRIBER:
      return {
        ...state,
        users: removeDuplicates<UserResponse[]>([
          ...state.users,
          action.payload,
        ]),
      }

    case ActionTypes.UPDATE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        users: state.users.map(user => {
          // eslint-disable-next-line no-underscore-dangle
          if (action.payload._id === user._id) {
            return Object.assign(user, action.payload)
          }
          return user
        }),
      }

    case ActionTypes.UPDATE_STATUS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        users: state.users.map(user => {
          if (action.payload.result.companyId === user.companyId) {
            return Object.assign(user, action.payload)
          }
          return user
        }),
      }

    case ActionTypes.DELETE_SUBSCRIBER:
      return {
        ...state,
        users: state.users.filter(
          user => user.username !== action.payload.result.username,
        ),
      }
    default:
      return state
  }
}

export default userSubscribersHandler
