import ActionTypes from '../constants'
import { FrequencyCapState, FrequencyCapActions } from '../models'

const frequencyCapDeleteHandler = (
  state: FrequencyCapState,
  action: FrequencyCapActions,
): FrequencyCapState => {
  switch (action.type) {
    case ActionTypes.DELETE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frequenciesCap: state.frequenciesCap.filter(
          frequencyCap => frequencyCap._id !== action.payload._id,
        ),
      }

    case ActionTypes.DELETE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default frequencyCapDeleteHandler
