import ActionTypes from '../constants'
import { TemplateSmsActions, TemplateSmsState } from '../models'

const templateSmsGetOneHandler = (
  state: TemplateSmsState,
  action: TemplateSmsActions,
): TemplateSmsState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    case ActionTypes.GET_ONE_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default templateSmsGetOneHandler
