import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { filterUserSettings } from '../actions/rest.actions'
import { getLastUpdatedConfig } from '../utils/get-latest-updated-config.util'

import { loadSettingsAction } from '../actions/standard.actions'
import { FilterUserSettingsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets the user settings
 *
 * The following filter values are available:
 *
 * @type {string} name - The configuration name
 * @type {string} user - The userId
 * @type {UserSettingsTypeList} type - The configuration type
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof filterUserSettings.request} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* filterUserSettingsWorker(
  action: ReturnType<typeof filterUserSettings.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'users/settings/getUserSettingsByFilter',
      queries: jsonToQueryString(action.payload),
    }

    const { auth, settings } = yield select()
    const response: FilterUserSettingsResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(filterUserSettings.success(response))

    if (
      response.docs &&
      response.docs?.length > 0 &&
      action.payload.tab &&
      settings.tabs[action.payload.tab].firstLoad
    ) {
      yield put(loadSettingsAction(getLastUpdatedConfig(response.docs || [])))
    }
  } catch (error) {
    yield put(filterUserSettings.failure(error))
  }
}

export default filterUserSettingsWorker
