import { makeStyles, createStyles } from '@material-ui/core/styles'
import bg from 'assets/img/login_img2x.jpg'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundImage: `url(${bg})`,
      backgroundSize: 'cover',
    },
    logo: {
      width: 200,
    },
  }),
)

export default useStyles
