import React from 'react'
import { List, Box } from '@material-ui/core'
import MenuLinkComponent from './link.component'

import useSidebarActions from '../../hooks/use-sidebar-actions.hook'
import useSidebarLinks from '../../hooks/use-sidebar-links.hook'

type Props = {
  openDrawer: boolean
  handleDrawerClose: () => void
}

const MenuComponent = ({ openDrawer, handleDrawerClose }: Props) => {
  const { sidebarIds } = useSidebarActions()

  const { links } = useSidebarLinks()

  return (
    <Box display="flex" flexDirection="column" clone>
      <List disablePadding component="nav">
        {links
          .filter(link => sidebarIds.includes(link.id) && link.visible)
          .map(link => (
            <Box order={link.order} key={link.id}>
              <MenuLinkComponent
                link={link}
                handleDrawerClose={handleDrawerClose}
                openDrawer={openDrawer}
                sidebarIds={sidebarIds}
              />
            </Box>
          ))}
      </List>
    </Box>
  )
}

export default MenuComponent
