import { setSnackbarAction } from 'state/ui/global/actions'
import store from 'state'
import i18n from 'i18n'

const defaultSnackbarError = () =>
  store.dispatch(
    setSnackbarAction({
      message: i18n.t('messages.errorOccurred'),
      open: true,
      variant: 'error',
    }),
  )

export default defaultSnackbarError
