import ActionTypes from '../constants'
import { CustomTriggersState, CustomTriggersActions } from '../models'

const createCustomTriggerHandler = (
  state: CustomTriggersState,
  action: CustomTriggersActions,
): CustomTriggersState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentCustomTrigger: action.payload,
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default createCustomTriggerHandler
