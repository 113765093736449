import { call, put, select } from 'redux-saga/effects'
import { Container } from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { setLoadingFullAction } from 'state/ui/global/actions'
import { getOneTemplateAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single onsite template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOneTemplateAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getOneTemplateWorker(
  action: ReturnType<typeof getOneTemplateAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'templates/onsite/get/one',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    if (response._id) {
      yield put(getOneTemplateAction.success(response))
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(getOneTemplateAction.failure(error))
  }
}

export default getOneTemplateWorker
