/**
 *
 * Frequency Cap actions
 *
 */

import { createAsyncAction, createCustomAction } from 'typesafe-actions'
import {
  DialogProps,
  CreateFrequencyCapPayload,
  GetAllFrequencyCapPayload,
  FrequencyCapResponse,
  FrequencyCapExtendedResponse,
  UpdateFrequencyCapPayload,
  DeleteFrequencyCapPayload,
  FrequencyCap,
} from 'state/frequency-cap/models'
import ActionTypes from '../constants'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const getAllFrequencyCapAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<GetAllFrequencyCapPayload, FrequencyCapResponse, Error>()

export const createFrequencyCapAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<CreateFrequencyCapPayload, FrequencyCapExtendedResponse, Error>()

export const updateFrequencyCapAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateFrequencyCapPayload, FrequencyCapExtendedResponse, Error>()

export const deleteFrequencyCapAction = createAsyncAction(
  ActionTypes.DELETE_REQUEST,
  ActionTypes.DELETE_SUCCESS,
  ActionTypes.DELETE_FAILURE,
)<DeleteFrequencyCapPayload, FrequencyCapExtendedResponse, Error>()

export const setCurrentFrequencyCap = createCustomAction(
  ActionTypes.SET_CURRENT_FREQUENCY_CAP,
  type => (payload: FrequencyCap | null) => ({
    type,
    payload,
  }),
)

export const setFrequencyCapDialogAction = createCustomAction(
  ActionTypes.SET_FREQUENCY_CAP_DIALOG,
  type => (payload: DialogProps) => ({
    type,
    payload,
  }),
)
