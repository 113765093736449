import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { Box, Button, Grid, Typography, Paper } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

import Logo from 'assets/svg/logoText.svg'
import useSnackbar from 'hooks/ui/use-snackbar.hook'
import routes from 'routes/index.routes'

import { globalUiSelectors } from 'state/ui/global'
import FullPageLoader from 'views/components/full-page-loader/index.component'
import SnackbarWrapper from 'views/components/snackbar/index.component'

import SliderComponent from 'views/components/slider/slider'
import useStyles from './style'

type Props = {
  children: React.ReactNode
  title: string
  subtitle?: string
  auth: boolean
  sidebar?: boolean
  showBack?: boolean
}

const Auth = ({ children, title, subtitle, showBack, auth }: Props) => {
  const { t } = useTranslation()

  const { root, logo } = useStyles()

  const { isLoadingFull } = useSelector(globalUiSelectors.root)

  const { snackbar, closeHandler } = useSnackbar({})

  const slides = [
    {
      title: t('slides.titleAut'),
      description: t('slides.descriptionAut'),
    },
    {
      title: t('slides.titleSeg'),
      description: t('slides.descriptionSeg'),
    },
    {
      title: t('slides.titleCam'),
      description: t('slides.descriptionCam'),
    },
    {
      title: t('slides.titleAna'),
      description: t('slides.descriptionAna'),
    },
    {
      title: t('slides.titlePer'),
      description: t('slides.descriptionPer'),
    },
  ]

  return (
    <>
      <Grid
        container
        component={Box}
        minHeight="100vh"
        pl={auth ? '100px' : 0}
        pt={auth ? '68px' : 0}
      >
        <Grid item xs={12} sm={6} className={root}>
          <Box
            p={7}
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <img src={Logo} alt="logo" className={logo} />
            {!auth && <SliderComponent slides={slides} />}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            p={7}
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
            component={Paper}
            className="bg-blueDarkNav"
            borderRadius={0}
          >
            <Box width="100%" maxWidth={400}>
              <Box mb={3}>
                <Typography
                  component="h1"
                  variant="h3"
                  className="fontWeight-500"
                >
                  {title}
                </Typography>
              </Box>
              {showBack && (
                <Box clone minWidth="0!important" mb={2}>
                  <Button
                    to={routes.auth.LOGIN}
                    component={Link}
                    startIcon={<ChevronLeft />}
                  >
                    {t('labels.back')}
                  </Button>
                </Box>
              )}
              <Typography variant="body2" paragraph>
                {subtitle}
              </Typography>
              {children}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <FullPageLoader open={isLoadingFull} />
      <SnackbarWrapper
        message={snackbar.message}
        open={snackbar.open}
        handleClose={closeHandler}
        variant={snackbar.variant}
        automaticClose
      />
    </>
  )
}

export default Auth
