import { takeLatest, takeEvery, debounce } from 'redux-saga/effects'
import {
  createCampaignAction,
  updateCampaignAction,
  archiveCampaignAction,
  getAllCampaignsAction,
  getOneCampaignAction,
  getAllCampaignsArchiveAction,
  duplicateCampaignAction,
  getAllSegmentNamesAction,
  getAllTemplateNamesAction,
  getOneCampaignSegmentAction,
  getTemplateLanguagesAction,
  updateStatusCampaignAction,
} from '../actions/index.actions'
import createCampaignWorker from './create.saga'

import archiveCampaignWorker from './archive.saga'
import updateCampaignWorker from './update.saga'
import getAllCampaignsWorker from './get-all.saga'

import getOneCampaignWorker from './get-one.saga'
import getAllCampaignsArchiveWorker from './get-all-archive.saga'
import duplicateCampaignWorker from './duplicate.saga'

import getAllSegmentNamesWorker from './get-all-segments.saga'
import getAllTemplateNamesWorker from './get-all-templates.saga'
import getOneSegmentWorker from './get-one-segment.saga'

import getTemplateLanguagesWorker from './get-template-languages.saga'
import updateStatusCampaignWorker from './update-status.saga'

/**
 * Main Saga for watching saga workers  in the Campaign state shape
 *
 * @generator
 */
function* campaignSagasWatcher() {
  yield [
    yield takeLatest(createCampaignAction.request, createCampaignWorker),
    yield takeLatest(updateCampaignAction.request, updateCampaignWorker),
    yield takeLatest(
      updateStatusCampaignAction.request,
      updateStatusCampaignWorker,
    ),
    yield takeLatest(archiveCampaignAction.request, archiveCampaignWorker),
    yield takeLatest(getAllCampaignsAction.request, getAllCampaignsWorker),
    yield takeLatest(getOneCampaignAction.request, getOneCampaignWorker),
    yield debounce(
      500,
      getAllCampaignsArchiveAction.request,
      getAllCampaignsArchiveWorker,
    ),
    yield takeLatest(duplicateCampaignAction.request, duplicateCampaignWorker),
    yield takeLatest(
      getAllSegmentNamesAction.request,
      getAllSegmentNamesWorker,
    ),
    yield debounce(
      500,
      getAllTemplateNamesAction.request,
      getAllTemplateNamesWorker,
    ),
    yield takeLatest(getOneCampaignSegmentAction.request, getOneSegmentWorker),
    yield takeEvery(
      getTemplateLanguagesAction.request,
      getTemplateLanguagesWorker,
    ),
  ]
}

export default campaignSagasWatcher
