import { TransactionModels } from '../models/index.model'
import ActionTypes from '../constants'
import { TransactionActions } from '../models/transactions.model'

const getAllTransactionsHandler = (
  state: TransactionModels.State,
  action: TransactionActions,
): TransactionModels.State => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TRANSACTIONS:
      return {
        ...state,
        isLoading: !state.isDownloading,
      }

    case ActionTypes.GET_ALL_TRANSACTIONS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        transactions: state.isDownloading
          ? state.transactions
          : action.payload.docs || [],
        pagination: {
          total: state.isDownloading
            ? state.pagination.total
            : action.payload.total || 0,
          limit: state.isDownloading
            ? state.pagination.limit
            : action.payload.limit || 10,
          page: state.isDownloading
            ? state.pagination.page
            : action.payload.page || 1,
          pages: state.isDownloading
            ? state.pagination.pages
            : action.payload.pages || 0,
        },
      }

    case ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS:
      return {
        ...state,
        isLoadingDrawer: true,
      }

    case ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS_SUBSCRIBER:
      return {
        ...state,
        isLoadingDrawer: false,
        depositTransactions: state.isDownloading
          ? state.transactions
          : action.payload.docs || [],
        pagination: {
          total: state.isDownloading
            ? state.pagination.total
            : action.payload.total || 0,
          limit: state.isDownloading
            ? state.pagination.limit
            : action.payload.limit || 10,
          page: state.isDownloading
            ? state.pagination.page
            : action.payload.page || 1,
          pages: state.isDownloading
            ? state.pagination.pages
            : action.payload.pages || 0,
        },
      }

    case ActionTypes.GET_ONE_TRANSACTION:
      return {
        ...state,
        isLoadingPartial: true,
      }

    case ActionTypes.GET_ONE_TRANSACTION_SUBSCRIBER:
      return {
        ...state,
        isLoadingPartial: false,
        currentTransaction: action.payload,
      }

    case ActionTypes.SET_TRANSACTION_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      }

    case ActionTypes.GET_TRANSACTION_EXCEPTION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
      }

    case ActionTypes.SET_TRANSACTION_PLAYER_FILTERS: {
      return {
        ...state,
        playerFilters: {
          ...state.playerFilters,
          ...action.payload,
        },
      }
    }

    case ActionTypes.SET_DOWNLOAD_TRANSACTION_PLAYER: {
      return {
        ...state,
        isDownloading: action.payload,
      }
    }

    default:
      return state
  }
}

export default getAllTransactionsHandler
