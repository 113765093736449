import { createCustomAction, createAction } from 'typesafe-actions'
import { DispatchSocket } from 'state/middleware/sockets/utils'
import WS_ROUTES from 'state/websocket.routes'

import WS_NAMESPACES from 'state/websocket.namespaces'
import ActionTypes from '../constants'
import { PaymentModels } from '../models/index.model'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET_WITHDRAWAL_PAYMENTS

 *
 */
export const getPayments = createCustomAction(
  ActionTypes.GET_WITHDRAWAL_PAYMENTS,
  type => (
    payload: PaymentModels.GetPaymentsPayload,
    event: typeof WS_ROUTES.WALLET.GET_WITHDRAWAL_PAYMENTS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getPaymentsSubscribe = createAction(
  ActionTypes.GET_WITHDRAWAL_PAYMENTS_SUBSCRIBER,
  action => {
    return (response: PaymentModels.GetPaymentsResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE_PAYMENT_FILE
 *
 */
export const createPayment = createCustomAction(
  ActionTypes.CREATE_PAYMENT_FILE,
  type => (
    payload: PaymentModels.CreatePaymentPayload,
    event: typeof WS_ROUTES.WALLET.CREATE_PAYMENT_FILE,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const createPaymentSubscribe = createAction(
  ActionTypes.CREATE_PAYMENT_FILE_SUBSCRIBER,
  action => {
    return (response: PaymentModels.CreatePaymentResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] BASIC UPDATE
 * * (table) processed-payments
 *
 */
export const updatePayment = createCustomAction(
  ActionTypes.UPDATE_PAYMENT_FILE,
  type => (
    payload: PaymentModels.UpdatePaymentPayload,
    event: typeof WS_ROUTES.WALLET.UPDATE_BASIC,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updatePaymentSubscriber = createAction(
  ActionTypes.UPDATE_PAYMENT_FILE_SUBSCRIBER,
  action => {
    return (response: PaymentModels.UpdatePaymentResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] COMPLETE PAYMENT
 *
 */
export const completePaymentAction = createCustomAction(
  ActionTypes.COMPLETE_WITHDRAWALS_IN_PAYMENT,
  type => (
    payload: PaymentModels.ChangeWithdrawalsStatusPayload,
    event: typeof WS_ROUTES.WALLET.COMPLETE_PAYMENT,
  ) => ({
    type,
    event,
    emit: true,
    payload,
  }),
)

export function completePaymentSubscriber() {
  return (dispatch: DispatchSocket) =>
    dispatch({
      event: WS_ROUTES.WALLET.COMPLETE_PAYMENT,
      handle: (response: PaymentModels.ChangeWithdrawalsStatusResponse) =>
        dispatch({
          type: ActionTypes.COMPLETE_WITHDRAWALS_IN_PAYMENT_SUBSCRIBER,
          payload: response,
        }),
    })
}

export const walletReset = createAction(ActionTypes.WALLET_RESET)

export const filterPayments = createAction(
  ActionTypes.FILTER_PAYMENTS,
  action => {
    return (filterCriteria: PaymentModels.GetPaymentsPayload) => {
      return action(filterCriteria)
    }
  },
)
