import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'
import initialState from './initial-state'

const channelActivityHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_CHANNEL_ACTIVITY_REQUEST:
      return {
        ...state,
        isLoading: !state.isDownloading,
      }

    case ActionTypes.GET_CHANNEL_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentChannelsActivity: state.isDownloading
          ? state.currentChannelsActivity
          : action?.payload?.docs || [],
        pagination: {
          total: state.isDownloading
            ? state.pagination.total
            : action.payload.total || 0,
          limit: state.isDownloading
            ? state.pagination.limit
            : action.payload.limit || 25,
          page: state.isDownloading
            ? state.pagination.page
            : action.payload.page || 1,
          pages: state.isDownloading
            ? state.pagination.pages
            : action.payload.pages || 0,
        },
      }

    case ActionTypes.GET_CHANNEL_ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_ANALYTIC:
      return {
        ...state,
        currentChannelsActivity: initialState.currentChannelsActivity,
      }

    case ActionTypes.SET_DOWNLOAD_ANALYTIC_EVENTS:
      return {
        ...state,
        isDownloading: action.payload,
      }

    default:
      return state
  }
}

export default channelActivityHandler
