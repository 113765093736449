import { lazy } from 'react'

/**
 * ==========================
 * L A Z Y  I M P O R T S
 * ==========================
 */

export const Home = lazy(() => import('views/pages/home/index.page'))

export const Campaigns = lazy(() => import('views/pages/campaigns/index.page'))

export const CampaignsCreate = lazy(() =>
  import('views/pages/campaigns-create/index.page'),
)

export const TemplateEmail = lazy(() =>
  import('views/pages/templates-email/index.page'),
)

export const TemplateOnSite = lazy(() =>
  import('views/pages/templates-onsite/index.page'),
)

export const TemplatePush = lazy(() =>
  import('views/pages/templates-push/index.page'),
)

export const TemplateSms = lazy(() =>
  import('views/pages/templates-sms/index.page'),
)

export const TemplateCreateEmail = lazy(() =>
  import('views/pages/template-create-email/index.page'),
)

export const TemplateCreateOnSite = lazy(() =>
  import('views/pages/template-create-onsite/index.page'),
)

export const TemplateCreatePush = lazy(() =>
  import('views/pages/template-create-push/index.page'),
)

export const TemplateCreateSms = lazy(() =>
  import('views/pages/template-create-sms/index.page'),
)

export const Segments = lazy(() => import('views/pages/segments/index.page'))

export const SegmentsCreate = lazy(() =>
  import('views/pages/segments-create/index.page'),
)

export const Account = lazy(() => import('views/pages/account/index.page'))

export const ClientSettings = lazy(() =>
  import('views/pages/client-settings/index.page'),
)

export const Users = lazy(() => import('views/pages/users/index.page'))

export const UserLogs = lazy(() => import('views/pages/user-logs/index.page'))

export const AnalyticsActivity = lazy(() =>
  import('views/pages/analytics-activity/index.page'),
)

export const Analytics = lazy(() => import('views/pages/analytics/index.page'))

export const AnalyticsDetail = lazy(() =>
  import('views/pages/analytics-detail/index.page'),
)

export const Tags = lazy(() => import('views/pages/tags/index.page'))

export const SearchPlayer = lazy(() =>
  import('views/pages/search-player/index.page'),
)

export const SearchTransaction = lazy(() =>
  import('views/pages/search-transaction/index.page'),
)

export const PlayerCard = lazy(() =>
  import('views/pages/player-card/index.page'),
)

export const SearchPaycard = lazy(() =>
  import('views/pages/search-paycard/index.page'),
)

export const WithdrawalsPage = lazy(() =>
  import('views/pages/withdrawals/index.page'),
)

export const MediaDatabasePage = lazy(() =>
  import('views/pages/media-database/index.page'),
)

export const ErrorPage = lazy(() => import('views/pages/error/index.page'))

export const TeledataPage = lazy(() =>
  import('views/pages/teledata/index.page'),
)

export const IssuesPage = lazy(() => import('views/pages/issues/index.page'))

export const BlacklistPage = lazy(() =>
  import('views/pages/blacklist/index.page'),
)

export const BonusEnginePage = lazy(() =>
  import('views/pages/bonus-engine/index.page'),
)

export const BonusEngineOffersPage = lazy(() =>
  import('views/pages/bonus-engine-offers/index.page'),
)

export const RolesPage = lazy(() => import('views/pages/roles/index.page'))

export const RulesCreatePage = lazy(() =>
  import('views/pages/rules-create/index.page'),
)

export const RulesPage = lazy(() => import('views/pages/rules/index.page'))

export const RulesAnalyticsDetailsPage = lazy(() =>
  import('views/pages/rules-analytics-detail/index.page'),
)

export const RulesAnalyticsActivityPage = lazy(() =>
  import('views/pages/rules-analytics-activity/index.page'),
)

export const CustomTriggerPage = lazy(() =>
  import('views/pages/custom-trigger/index.page'),
)

export const CustomTriggerCreatePage = lazy(() =>
  import('views/pages/custom-trigger-create/index.page'),
)

export const BonusEngineCampaignsPage = lazy(() =>
  import('views/pages/bonus-engine-campaigns/index.page'),
)

export const RewardShopProductsPage = lazy(() =>
  import('views/pages/reward-products/index.page'),
)

export const RewardShopPage = lazy(() =>
  import('views/pages/reward-shop/index.page'),
)

export const RewardShopItemsPage = lazy(() =>
  import('views/pages/reward-items/index.page'),
)

export const CreateRewardShopProductPage = lazy(() =>
  import('views/pages/reward-products-create/index.page'),
)

export const CreateRewardShopPage = lazy(() =>
  import('views/pages/reward-shop-create/index.page'),
)

export const CreateRewardShopItemPage = lazy(() =>
  import('views/pages/reward-items-create/index.page'),
)

export const ReferFriendPage = lazy(() =>
  import('views/pages/refer-friend/index.page'),
)

export const TransactionReport = lazy(() =>
  import('views/pages/transaction-report/index.page'),
)

export const GameStoreGames = lazy(() =>
  import('views/pages/game-store-games/index.page'),
)

export const GameStoreProviders = lazy(() =>
  import('views/pages/game-store-providers/index.page'),
)

export const GameStoreEdit = lazy(() =>
  import('views/pages/game-store-games-edit/index.page'),
)

export const GgrReport = lazy(() => import('views/pages/ggr-report/index.page'))

export const FrequencyCap = lazy(() =>
  import('views/pages/frequency-cap/index.page'),
)

export const LevelsPage = lazy(() => import('views/pages/levels/index.page'))

export const LevelsCreatePage = lazy(() =>
  import('views/pages/levels-create/index.page'),
)

export const BonusReportPage = lazy(() =>
  import('views/pages/bonus-report/index.page'),
)

export const PlayerReportPage = lazy(() =>
  import('views/pages/player-report/index.page'),
)

export const MissionsPage = lazy(() =>
  import('views/pages/missions/index.page'),
)
