import ActionTypes from '../constants'
import { TemplateActions, TemplateEmailState } from '../models'

const templateUiHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.RESET_EMAIL_TEMPLATES:
      return {
        ...state,
        emailTemplates: [],
      }

    case ActionTypes.RESET_TEMPLATES_ARCHIVED:
      return {
        ...state,
        emailTemplatesArchived: [],
      }

    case ActionTypes.SET_CREATION_MODE:
      return {
        ...state,
        currentTemplate: {
          ...state.currentTemplate,
          creationMode: action.payload,
        },
      }

    case ActionTypes.SET_FORM_MODE:
      return {
        ...state,
        formMode: action.payload,
      }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: action.payload,
      }

    case ActionTypes.SET_POPOVER:
      return {
        ...state,
        popover: action.payload,
      }

    case ActionTypes.SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: action.payload,
      }
    case ActionTypes.SET_TEMPLATE_EMAIL_DIALOG:
      return {
        ...state,
        dialog: { ...state.dialog, ...action.payload },
      }

    default:
      return state
  }
}

export default templateUiHandler
