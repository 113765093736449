/**
 *
 * Game Store constants
 *
 */

enum ActionTypes {
  GET_GAME_LIST = '@game-store/GET_GAME_LIST',
  GET_GAME_LIST_SUBSCRIBER = '@game-store/GET_GAME_LIST_SUBSCRIBER',

  GET_GAME_PROVIDERS = '@game-store/GET_GAME_PROVIDERS',
  GET_GAME_PROVIDERS_SUBSCRIBER = '@game-store/GET_GAME_PROVIDERS_SUBSCRIBER',

  GET_GAME_CATEGORIES = '@game-store/GET_GAME_CATEGORIES',
  GET_GAME_CATEGORIES_SUBSCRIBER = '@game-store/GET_GAME_CATEGORIES_SUBSCRIBER',

  GET_GAME_DEVICES = '@game-store/GET_GAME_DEVICES',
  GET_GAME_DEVICES_SUBSCRIBER = '@game-store/GET_GAME_DEVICES_SUBSCRIBER',

  UPDATE_GAME = '@game-store/UPDATE_GAME',
  UPDATE_GAME_SUBSCRIBER = '@game-store/UPDATE_GAME_SUBSCRIBER',

  GET_GAME = '@game-store/GET_GAME',
  GET_GAME_SUBSCRIBER = '@game-store/GET_GAME_SUBSCRIBER',

  GET_GAME_VERSION = '@game-store/GET_GAME_VERSION',
  GET_GAME_VERSION_SUBSCRIBER = '@game-store/GET_GAME_VERSION_SUBSCRIBER',

  ADD_GAME_VERSION = '@game-store/ADD_GAME_VERSION',
  ADD_GAME_VERSION_SUBSCRIBER = '@game-store/ADD_GAME_VERSION_SUBSCRIBER',

  DELETE_GAME_VERSION = '@game-store/DELETE_GAME_VERSION',
  DELETE_GAME_VERSION_SUBSCRIBER = '@game-store/DELETE_GAME_VERSION_SUBSCRIBER',

  SET_FILTERS = '@game-store/SET_FILTERS',

  SET_GAME_SELECTION = '@game-store/SET_GAME_SELECTION',

  SET_CURRENT_GAME = '@game-store/SET_CURRENT_GAME',

  SET_PAGINATION = '@game-store/SET_PAGINATION',
}

export default ActionTypes
