import { AnnexeState, AnnexeActions } from '../models'
import ActionTypes from '../constants'

const getAnnexeInfoHandler = (
  state: AnnexeState,
  action: AnnexeActions,
): AnnexeState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_BY_ID_ANNEXE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_BY_ID_ANNEXE_SUBSCRIBER:
      return {
        ...state,
        annexes: action.payload.message ? [action.payload.message] : [],
        pagination: {
          ...state.pagination,
          total: action.payload.message ? 1 : 0,
          pages: action.payload.message
            ? Math.ceil(1 / state.pagination.limit)
            : 0,
        },
        isLoading: false,
      }

    case ActionTypes.GET_ALL_ANNEXES:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_ANNEXES_SUBSCRIBER:
      return {
        ...state,
        annexes: action.payload.message || [],
        pagination: {
          ...state.pagination,
          total: action.payload.message ? action.payload.message.length : 0,
          pages: action.payload.message
            ? Math.ceil(action.payload.message.length / state.pagination.limit)
            : 0,
        },
        isLoading: false,
      }

    case ActionTypes.SET_CURRENT_ANNEXE:
      return {
        ...state,
        currentAnnexe: {
          ...state.currentAnnexe,
          ...action.payload,
        },
      }

    default:
      return state
  }
}

export default getAnnexeInfoHandler
