import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterDomLink } from 'react-router-dom'

import { TextField, Button, Link, Box } from '@material-ui/core'
import PasswordFieldComponent from 'views/components/password/index.component'
import useLogin from '../hooks/use-login.hook'

const LoginComponent = () => {
  const { t } = useTranslation()

  const {
    onSubmitEnterKey,
    state,
    handleOnChange,
    handleOnSubmit,
    disable,
  } = useLogin()

  const handleOnChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    handleOnChange(name, value)
  }

  return (
    <>
      <TextField
        className="label-float"
        error={state.email.error.length > 0}
        helperText={state.email.error || ' '}
        fullWidth
        name="email"
        defaultValue=""
        label={t('usernameOrEmail')}
        margin="dense"
        variant="outlined"
        onChange={handleOnChangeInput}
        onKeyUp={onSubmitEnterKey}
        autoFocus
      />
      <PasswordFieldComponent
        state={state}
        name="password"
        handleOnChange={handleOnChangeInput}
        label={t('password')}
        onKeyUp={onSubmitEnterKey}
      />

      <Box mb={3} textAlign="right">
        <Link component={RouterDomLink} to="/forgot" className="bold underline">
          {t('forgotPassword')}
        </Link>
      </Box>
      <Button
        disabled={disable}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleOnSubmit}
      >
        {t('login')}
      </Button>
    </>
  )
}

export default LoginComponent
