import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import { setLoadingFullAction } from 'state/ui/global/actions'
import jsonToQueryString from 'utils/query-string'
import { getOneSegmentAction } from '../actions/rest.actions'

import { Segmentation } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single segment
 *
 *
 *
 * @requires QueryBuilder
 * @requires ApiService
 *
 * @param {ReturnType<typeof getOneSegmentAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getOneSegmentWorker(
  action: ReturnType<typeof getOneSegmentAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'segmentations/get/one',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response: Segmentation = yield call(apiService.sendData, auth.token)
    yield put(setLoadingFullAction(false))
    yield put(getOneSegmentAction.success(response))
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(getOneSegmentAction.failure(error))
  }
}

export default getOneSegmentWorker
