/**
 *
 * Template SMS Action constants
 *
 * @action GET_ONE
 * @action GET
 * @action CREATE
 * @action UPDATE_ONE
 */

enum ActionTypes {
  GET_ONE_REQUEST = '@template-sms/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@template-sms/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@template-sms/GET_ONE_FAILURE',

  GET_ALL_REQUEST = '@template-sms/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@template-sms/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@template-sms/GET_ALL_FAILURE',

  CREATE_REQUEST = '@template-sms/CREATE_REQUEST',
  CREATE_SUCCESS = '@template-sms/CREATE_SUCCESS',
  CREATE_FAILURE = '@template-sms/CREATE_FAILURE',

  UPDATE_REQUEST = '@template-sms/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@template-sms/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@template-sms/UPDATE_FAILURE',

  ARCHIVE_REQUEST = '@template-sms/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@template-sms/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@template-sms/ARCHIVE_FAILURE',

  SEND_TEST_REQUEST = '@template-sms/SEND_TEST_REQUEST',
  SEND_TEST_SUCCESS = '@template-sms/SEND_TEST_SUCCESS',
  SEND_TEST_FAILURE = '@template-sms/SEND_TEST_FAILURE',

  DUPLICATE_REQUEST = '@template-sms/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@template-sms/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@template-sms/DUPLICATE_FAILURE',

  SET_LANGUAGES_SELECTED = '@template-sms/SET_LANGUAGES_SELECTED',

  SET_FILTERS = '@template-sms/SET_FILTERS',
}

export default ActionTypes
