import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const campaignCreateHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.CREATE_REQUEST:
      return { ...state }

    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        currentCampaign: Object.assign(state.currentCampaign, action.payload),
      }

    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default campaignCreateHandler
