// FIXME: get real urls
const GAMING_ACTIVITY = {
  GET_GAME_LIST_ADMIN: 'games/get-game-list' as 'games/get-game-list',
  GET_GAME_CATEGORY_LIST: 'get-game-category-list' as 'get-game-category-list',
  GET_GAME_PROVIDER_LIST: 'get-game-provider-list' as 'get-game-provider-list',

  GET_GAME_SESSIONS: 'wbo/admin/game-sessions-get' as 'wbo/admin/game-sessions-get',
  GET_GAME_TRANSACTIONS: 'wbo/admin/game-transactions-get' as 'wbo/admin/game-transactions-get',
}

export default GAMING_ACTIVITY
