enum ActionTypes {
  GET_PLAYER_TIMELINE = '@log/GET_PLAYER_TIMELINE',
  GET_PLAYER_TIMELINE_SUBSCRIBER = '@log/GET_PLAYER_TIMELINE_SUBSCRIBER',

  GET_TELEDATA_LOGS = '@log/GET_TELEDATA_LOGS',
  GET_TELEDATA_LOGS_SUBSCRIBER = '@log/GET_TELEDATA_LOGS_SUBSCRIBER',

  GET_TAG_LOGS = '@log/GET_TAG_LOGS',
  GET_TAG_LOGS_SUBSCRIBER = '@log/GET_TAG_LOGS_SUBSCRIBER',

  GET_AML_STATUS_LOGS = '@log/GET_AML_STATUS_LOGS',
  GET_AML_STATUS_LOGS_SUBSCRIBER = '@log/GET_AML_STATUS_LOGS_SUBSCRIBER',

  GET_AML_ANSWER_LOGS = '@log/GET_AML_ANSWER_LOGS',
  GET_AML_ANSWER_LOGS_SUBSCRIBER = '@log/GET_AML_ANSWER_LOGS_SUBSCRIBER',

  GET_COOLOFF_LOGS = '@log/GET_COOLOFF_LOGS',
  GET_COOLOFF_LOGS_SUBSCRIBER = '@log/GET_COOLOFF_LOGS_SUBSCRIBER',

  GET_LIMIT_LOGS = '@log/GET_LIMIT_LOGS',
  GET_LIMIT_LOGS_SUBSCRIBER = '@log/GET_LIMIT_LOGS_SUBSCRIBER',

  GET_NOTE_LOGS = '@log/GET_NOTE_LOGS',
  GET_NOTE_LOGS_SUBSCRIBER = '@log/GET_NOTE_LOGS_SUBSCRIBER',
}

export default ActionTypes
