const templates = {
  INDEX: '/templates',
  INDEX_SMS: '/templates/sms',
  CREATE_SMS: '/templates/sms/create',
  CREATE_EMAIL: '/templates/email/create',
  ARCHIVE: '/templates/archive',
  ARCHIVE_SMS: '/templates/sms/archive',
  EDIT: '/templates/email/edit',
  EDIT_SMS: '/templates/sms/edit',
  INDEX_PUSH: '/templates/push',
  CREATE_PUSH: '/templates/push/create',
  EDIT_PUSH: '/templates/push/edit',
  ARCHIVE_PUSH: '/templates/push/archive',
  INDEX_ONSITE: '/templates/on-site',
  CREATE_ONSITE: '/templates/on-site/create',
  EDIT_ONSITE: '/templates/on-site/edit',
  ARCHIVE_ONSITE: '/templates/on-site/archive',
}

export default templates
