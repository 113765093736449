enum ActionTypes {
  GET_GGR_REPORTS = '@ggr/GET_GGR_REPORTS',
  GET_GGR_REPORTS_SUBSCRIBER = '@ggr/GET_GGR_REPORTS_SUBSCRIBER',

  SET_GGR_FILTERS = '@ggr/SET_GGR_FILTERS',
  SET_REPORTS_BY_PERIOD = '@ggr/SET_REPORTS_BY_PERIOD',
  SET_LOADING = '@ggr/SET_LOADING',

  SET_NEW_GGR_PAGE = '@ggr/SET_NEW_GGR_PAGE',
  SET_NEW_CONSOLIDATION_PAGE = '@ggr/SET_NEW_CONSOLIDATION_PAGE',
  SET_NEW_GGR_TOTALS_PAGE = '@ggr/SET_NEW_GGR_TOTALS_PAGE',

  SET_ROWS_AMOUNT_GGR = '@ggr/SET_ROWS_AMOUNT_GGR',
  SET_ROWS_AMOUNT_NEW_CONSOLIDATION = '@ggr/SET_ROWS_AMOUNT_NEW_CONSOLIDATION',
  SET_ROWS_AMOUNT_NEW_GGR_TOTALS = '@ggr/SET_ROWS_AMOUNT_NEW_GGR_TOTALS',
}

export default ActionTypes
