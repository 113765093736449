const REWARD = {
  GET_REWARD_SHOPS: 'gamification/api/v1/admin/reward-shop/list' as 'gamification/api/v1/admin/reward-shop/list',
  GET_ONE_REWARD_SHOP: 'gamification/api/v1/admin/reward-shop/get' as 'gamification/api/v1/admin/reward-shop/get',
  CREATE_REWARD_SHOP: 'gamification/api/v1/admin/reward-shop/create' as 'gamification/api/v1/admin/reward-shop/create',
  UPDATE_REWARD_SHOP: 'gamification/api/v1/admin/reward-shop/update' as 'gamification/api/v1/admin/reward-shop/update',
  DELETE_REWARD_SHOP: 'gamification/api/v1/admin/reward-shop/delete' as 'gamification/api/v1/admin/reward-shop/delete',

  GET_REWARD_PRODUCTS: 'gamification/api/v1/admin/product/list' as 'gamification/api/v1/admin/product/list',
  GET_ONE_REWARD_PRODUCT: 'gamification/api/v1/admin/product/get' as 'gamification/api/v1/admin/product/get',
  CREATE_REWARD_PRODUCT: 'gamification/api/v1/admin/product/create' as 'gamification/api/v1/admin/product/create',
  UPDATE_REWARD_PRODUCT: 'gamification/api/v1/admin/product/update' as 'gamification/api/v1/admin/product/update',
  DELETE_REWARD_PRODUCT: 'gamification/api/v1/admin/product/delete' as 'gamification/api/v1/admin/product/delete',

  GET_REWARD_ITEMS: 'gamification/api/v1/admin/reward-shop/item/list' as 'gamification/api/v1/admin/reward-shop/item/list',
  GET_ONE_REWARD_ITEM: 'gamification/api/v1/admin/reward-shop/item/get' as 'gamification/api/v1/admin/reward-shop/item/get',
  CREATE_REWARD_ITEM: 'gamification/api/v1/admin/reward-shop/item/create' as 'gamification/api/v1/admin/reward-shop/item/create',
  UPDATE_REWARD_ITEM: 'gamification/api/v1/admin/reward-shop/item/update' as 'gamification/api/v1/admin/reward-shop/item/update',
  DELETE_REWARD_ITEM: 'gamification/api/v1/admin/reward-shop/item/delete' as 'gamification/api/v1/admin/reward-shop/item/delete',

  GET_ORDERS: 'gamification/api/v1/admin/order/list' as 'gamification/api/v1/admin/order/list',
  GET_ONE_ORDER: 'gamification/api/v1/admin/order/get' as 'gamification/api/v1/admin/order/get',
  UPDATE_ORDER: 'gamification/api/v1/admin/order/update' as 'gamification/api/v1/admin/order/update',
  DELETE_ORDER: 'gamification/api/v1/admin/order/delete' as 'gamification/api/v1/admin/order/delete',
  GET_BALANCE_POINTS: 'gamification/api/v1/player/virtual-currency-balance/get' as 'gamification/api/v1/player/virtual-currency-balance/get',
}

export default REWARD
