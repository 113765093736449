import DocumentFileFormat from 'state/enums/document-file-format.enum'
import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'
import ActionTypes from '../constants'

const downloadDocumentImageHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentDownloadedImage:
          action.payload.type === DocumentFileFormat.IMG
            ? action.payload.url
            : '',
        documentPreview: {
          format: action.payload.type,
          url:
            action.payload.type === DocumentFileFormat.PDF
              ? action.payload.url
              : '',
        },
      }

    case ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SET_CURRENT_DOWNLOADED_IMAGE: {
      return {
        ...state,
        isLoading: false,
        currentDownloadedImage: action.payload,
      }
    }

    case ActionTypes.SET_CURRENT_DOWNLOADED_SECOND_IMAGE: {
      return {
        ...state,
        isLoading: false,
        currentDownloadedSecondImage: action.payload,
      }
    }

    default:
      return state
  }
}

export default downloadDocumentImageHandler
