/**
 *
 * PlayerCardNote constants
 *
 */

enum ActionTypes {
  GET_ALL_NOTES = '@pcard-note/GET_ALL_NOTES',
  GET_ALL_NOTES_SUBSCRIBER = '@pcard-note/GET_ALL_NOTES_SUBSCRIBER',

  LISTEN_NOTE_BROADCAST = '@pcard-note/BROADCAST_NOTES',
  GET_NOTE_BROADCAST = '@pcard-note/GET_NOTE_BROADCAST',

  CREATE_SCHEDULED_NOTE = '@pcard-note/CREATE_SCHEDULED_NOTE',
  CREATE_SCHEDULED_NOTE_SUBSCRIBER = '@pcard-note/CREATE_SCHEDULED_NOTE_SUBSCRIBER',
  CREATE_SCHEDULED_NOTE_EXCEPTION = '@pcard-note/CREATE_SCHEDULED_NOTE_EXCEPTION',

  STOP_SCHEDULED_NOTE = '@pcard-note/STOP_SCHEDULED_NOTE',
  STOP_SCHEDULED_NOTE_SUBSCRIBER = '@pcard-note/STOP_SCHEDULED_NOTE_SUBSCRIBER',

  DELETE_SCHEDULED_NOTE = '@pcard-note/DELETE_SCHEDULED_NOTE',
  DELETE_SCHEDULED_NOTE_SUBSCRIBER = '@pcard-note/DELETE_SCHEDULED_NOTE_SUBSCRIBER',
}

export default ActionTypes
