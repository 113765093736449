import i18n from 'i18n'
import WithdrawalTableColumns from 'state/enums/withdrawal-table-columns.enum'
import { ColumnName } from 'state/settings/models'

import keys from 'utils/keys'

type ColumnProps = {
  name: string
}

type ColumnTypes = {
  [column: string]: ColumnProps
}

const defaultNames = keys(WithdrawalTableColumns)

let columnsList: ColumnTypes = {}

defaultNames.forEach(key => {
  columnsList = {
    ...columnsList,
    [WithdrawalTableColumns[key]]: {
      name: i18n.t(`columnTypes.${key}`),
    },
  }
})

export const defaultColumnsList: ColumnName[] = defaultNames.map(key => ({
  name: WithdrawalTableColumns[key],
  filter: WithdrawalTableColumns[key],
}))

export const withdrawalColumnsTypeList = columnsList
