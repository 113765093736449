import { createReducer } from 'typesafe-actions'
import { RewardsState, RewardsActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import rewardsFormHandler from './form.handler'
import shopHandler from './shops.handler'

import productHandler from './products.handler'
import itemHandler from './items.handler'
import orderHandler from './orders.handler'

/**
 * Creates a Rewards Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const rewardsReducer = createReducer<RewardsState, RewardsActions>(initialState)
  .handleAction(
    [
      ActionTypes.SET_ITEM_FORM_MODE,
      ActionTypes.SET_PRODUCT_FORM_MODE,
      ActionTypes.SET_SHOP_FORM_MODE,
    ],
    rewardsFormHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REWARD_SHOPS,
      ActionTypes.GET_ALL_REWARD_SHOPS_SUBSCRIBER,
      ActionTypes.GET_ONE_REWARD_SHOP,
      ActionTypes.GET_ONE_REWARD_SHOP_SUBSCRIBER,
      ActionTypes.CREATE_REWARD_SHOP,
      ActionTypes.CREATE_REWARD_SHOP_SUBSCRIBER,
      ActionTypes.UPDATE_REWARD_SHOP,
      ActionTypes.UPDATE_REWARD_SHOP_SUBSCRIBER,
      ActionTypes.DELETE_REWARD_SHOP,
      ActionTypes.DELETE_REWARD_SHOP_SUBSCRIBER,
      ActionTypes.SET_REWARD_SHOP_PAGINATION,
    ],
    shopHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REWARD_PRODUCTS,
      ActionTypes.GET_ALL_REWARD_PRODUCTS_SUBSCRIBER,
      ActionTypes.GET_ONE_REWARD_PRODUCT,
      ActionTypes.GET_ONE_REWARD_PRODUCT_SUBSCRIBER,
      ActionTypes.CREATE_REWARD_PRODUCT,
      ActionTypes.CREATE_REWARD_PRODUCT_SUBSCRIBER,
      ActionTypes.UPDATE_REWARD_PRODUCT,
      ActionTypes.UPDATE_REWARD_PRODUCT_SUBSCRIBER,
      ActionTypes.DELETE_REWARD_PRODUCT,
      ActionTypes.DELETE_REWARD_PRODUCT_SUBSCRIBER,
    ],
    productHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REWARD_ITEMS,
      ActionTypes.GET_ALL_REWARD_ITEMS_SUBSCRIBER,
      ActionTypes.GET_ONE_REWARD_ITEM,
      ActionTypes.GET_ONE_REWARD_ITEM_SUBSCRIBER,
      ActionTypes.CREATE_REWARD_ITEM,
      ActionTypes.CREATE_REWARD_ITEM_SUBSCRIBER,
      ActionTypes.UPDATE_REWARD_ITEM,
      ActionTypes.UPDATE_REWARD_ITEM_SUBSCRIBER,
      ActionTypes.DELETE_REWARD_ITEM,
      ActionTypes.DELETE_REWARD_ITEM_SUBSCRIBER,
    ],
    itemHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REWARD_ORDERS,
      ActionTypes.GET_ALL_REWARD_ORDERS_SUBSCRIBER,
      ActionTypes.GET_ONE_REWARD_ORDER,
      ActionTypes.GET_ONE_REWARD_ORDER_SUBSCRIBER,
      ActionTypes.UPDATE_REWARD_ORDER,
      ActionTypes.UPDATE_REWARD_ORDER_SUBSCRIBER,
      ActionTypes.DELETE_REWARD_ORDER,
      ActionTypes.DELETE_REWARD_ORDER_SUBSCRIBER,
      ActionTypes.GET_BALANCE_POINTS,
      ActionTypes.GET_BALANCE_POINTS_SUBSCRIBER,
      ActionTypes.SET_ORDER_PAGINATION,
    ],
    orderHandler,
  )

export default rewardsReducer
