import { createReducer } from 'typesafe-actions'
import { GlobalUiState, GlobalUiActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import globalUiHandler from './global-ui.handler'

/**
 * Creates a GlobalUi Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const globalUiReducer = createReducer<GlobalUiState, GlobalUiActions>(
  initialState,
).handleAction(
  [
    ActionTypes.SET_SNACKBAR,
    ActionTypes.SET_DIALOG,
    ActionTypes.SET_POPOVER,
    ActionTypes.SET_POPOVER_CONFIRM,
    ActionTypes.SET_FORM_MODE,
    ActionTypes.SET_IS_LOADING_FULL,
    ActionTypes.SET_DRAWER,
    ActionTypes.SET_ACTIVE_STEP,
    ActionTypes.SET_LANGUAGES_SELECTED,
    ActionTypes.SET_ACTIVE_LANGUAGE,
    ActionTypes.SET_INIT,
  ],
  globalUiHandler,
)

export default globalUiReducer
