import { createAction } from 'typesafe-actions'
import ActionTypes from './constants'
import {
  GatewayConnectedType,
  RegisterGatewayPayload,
  GatewayDisconnectedType,
} from './models'

export const wsConnect = createAction(ActionTypes.WS_CONNECT, action => {
  return (host?: string, namespace?: string, token?: string | undefined) =>
    action({ host, namespace, token })
})

export const wsConnecting = createAction(ActionTypes.WS_CONNECTING)

export const wsConnected = createAction(ActionTypes.WS_CONNECTED, action => {
  return (connected: boolean) => action(connected)
})

export const wsDisonnect = createAction(ActionTypes.WS_DISCONNECT, action => {
  return (host: string) => action(host)
})

export const wsDisconnected = createAction(
  ActionTypes.WS_DISCONNECTED,
  action => {
    return (connected: boolean) => action(connected)
  },
)

export const setSocket = createAction(ActionTypes.WS_SET_SOCKET, action => {
  return (wsocket: SocketIOClient.Socket | WebSocket | null) => action(wsocket)
})

export const addEvent = createAction(ActionTypes.WS_ADD_EVENT, action => {
  return (socketEvent: string) => action(socketEvent)
})

/**
 * =======================
 * G A T E W A Y S
 * =======================
 */
export const registerGateway = createAction(
  ActionTypes.WS_REGISTER_GATEWAY,
  action => {
    return (registerGatewayPayload: RegisterGatewayPayload) =>
      action(registerGatewayPayload)
  },
)
export const gatewayConnected = createAction(
  ActionTypes.WS_GATEWAY_CONNECTED,
  action => {
    return (connected: GatewayConnectedType) => action(connected)
  },
)

export const gatewayDisconnected = createAction(
  ActionTypes.WS_GATEWAY_DISCONNECTED,
  action => {
    return (disconnected: GatewayDisconnectedType) => {
      return action(disconnected)
    }
  },
)
