import { createReducer } from 'typesafe-actions'
import { SearchState, SearchActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import setSearchPropsHandler from './search.handler'

/**
 * Creates a Search Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const searchReducer = createReducer<SearchState, SearchActions>(
  initialState,
).handleAction(
  [
    ActionTypes.SET_PLAYER_SEARCH_PROPS,
    ActionTypes.SET_TRANSACTION_SEARCH_PROPS,
    ActionTypes.SET_SEARCH_TAB,
  ],
  setSearchPropsHandler,
)

export default searchReducer
