import React, { useState } from 'react'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { StateSchema } from 'hooks/form/models'

type Props = {
  state: StateSchema
  handleOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  label: string
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const PasswordFieldComponent = ({
  state,
  handleOnChange,
  name,
  label,
  onKeyUp,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      autoComplete="off"
      className="label-float"
      type={showPassword ? 'text' : 'password'}
      error={state[name].error.length > 0}
      helperText={state[name].error || ' '}
      fullWidth
      name={name}
      value={state[name].value}
      label={label}
      margin="dense"
      variant="outlined"
      onChange={handleOnChange}
      onKeyUp={onKeyUp}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PasswordFieldComponent
