/**
 *
 * KYC history actions
 *
 */

import { createCustomAction, createAction } from 'typesafe-actions'
import WS_NAMESPACES from 'state/websocket.namespaces'
import WS_ROUTES from 'state/websocket.routes'

import {
  InsertIssueAdminPayload,
  InsertIssueAdminResponse,
} from 'state/player-card/models'
import { IssueModels } from 'state/issues'
import ActionTypes from '../constants'

import { DispatchSocket } from '../../middleware/sockets/utils'
import {
  HistoryFilterType,
  CrifCheckPayload,
  CrifCheckResponse,
  ManualCheckBlacklistPayload,
  ManualCheckBlacklistResponse,
  GetTeleDataHistoryPayload,
  GetTeleDataHistoryResponse,
  CreateTeleDataPayload,
  CreateTeleDataResponse,
  GetNonPepHistoryPayload,
} from '../models'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] MANUAL VETO VERIFICATION
 *
 */

export const CrifCheckVerifyAction = createCustomAction(
  ActionTypes.CRIF_CHECK,
  type => (
    payload: CrifCheckPayload,
    event: typeof WS_ROUTES.KYC.UPDATE_CRIF_CHECK,
    handler?: (response: CrifCheckResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const CrifCheckVerificationSubscribe = createAction(
  ActionTypes.CRIF_CHECK_SUBSCRIBER,
  action => {
    return (response: CrifCheckResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] MANUAL CRIF VERIFICATION
 *
 */

export const ManualCheckVerificationAction = createCustomAction(
  ActionTypes.MANUAL_CHECK_BLACKLIST,
  type => (
    payload: ManualCheckBlacklistPayload,
    event: typeof WS_ROUTES.KYC.UPDATE_CHECK_BLACKLIST,
    handler?: (response: ManualCheckBlacklistResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const ManualCheckVerificationSubscribe = createAction(
  ActionTypes.MANUAL_CHECK_BLACKLIST_SUBSCRIBER,
  action => {
    return (response: ManualCheckBlacklistResponse) => {
      return action(response.resolved)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET VETO HISTORY
 *
 */
export const getVetoHistory = createCustomAction(
  ActionTypes.GET_VETO_HISTORY,
  type => (
    payload: HistoryFilterType,
    event: typeof WS_ROUTES.KYC.GET_VETO_HISTORY,
  ) => ({
    type,
    event,
    emit: true,
    payload,
  }),
)

export function getVetoHistorySubscribe() {
  return (dispatch: DispatchSocket) =>
    dispatch({
      event: WS_ROUTES.KYC.GET_VETO_HISTORY,
      handle: (response: any) =>
        dispatch({
          type: ActionTypes.GET_VETO_HISTORY_SUBSCRIBER,
          payload: response,
        }),
    })
}

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET NON PEP HISTORY
 *
 */
export const getNonPepHistory = createCustomAction(
  ActionTypes.GET_NON_PEP_HISTORY,
  type => (
    payload: GetNonPepHistoryPayload,
    event: typeof WS_ROUTES.KYC.GET_NON_PEP_HISTORY,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export function getNonPepHistorySubscribe() {
  return (dispatch: DispatchSocket) =>
    dispatch({
      event: WS_ROUTES.KYC.GET_NON_PEP_HISTORY,
      handle: (response: any) =>
        dispatch({
          type: ActionTypes.GET_NON_PEP_HISTORY_SUBSCRIBER,
          payload: response,
        }),
    })
}

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ISSUES PEP COUNT
 *
 */
export const getNonPepIssuesCount = createCustomAction(
  ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES,
  type => (
    payload: IssueModels.GetIssuesCountPayload,
    event: typeof WS_ROUTES.ISSUE.GET_ISSUES_COUNT,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getNonPepIssuesCountSubscribe = createAction(
  ActionTypes.GET_NON_PEP_HISTORY_COUNT_ISSUES_SUBSCRIBER,
  action => (response: IssueModels.GetIssuesCountResponse) => action(response),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE TELEDATA
 *
 */
export const createTeledata = createCustomAction(
  ActionTypes.CREATE_TELEDATA,
  type => (
    payload: CreateTeleDataPayload,
    event: typeof WS_ROUTES.KYC.CREATE_TELEDATA,
    handler?: (response: CreateTeleDataResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const createTeledataSubscribe = createAction(
  ActionTypes.CREATE_TELEDATA_SUBSCRIBER,
  action => {
    return (response: CreateTeleDataResponse) => {
      return action(response.message)
    }
  },
)

export const createTeledataError = createAction(
  ActionTypes.CREATE_TELEDATA_ERROR,
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET TELEDATA HISTORY
 *
 */
export const getTeleDataHistory = createCustomAction(
  ActionTypes.GET_TELEDATA_HISTORY,
  type => (
    payload: GetTeleDataHistoryPayload,
    event: typeof WS_ROUTES.KYC.GET_TELEDATA_HISTORY,
    handler?: (response: GetTeleDataHistoryResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getTeleDataHistorySubscribe = createAction(
  ActionTypes.GET_TELEDATA_HISTORY_SUBSCRIBER,
  action => {
    return (response: GetTeleDataHistoryResponse) => {
      return action(response.message)
    }
  },
)

/**

 * @description
 * Listen for the socket:
 *
 * * [EVENT] INSERT ISSUE ADMIN
 * * [ISSUE TYPE 379] PEP_CHANGED_OPERATOR 
 *
 */
export const updatePEPStatus = createCustomAction(
  ActionTypes.UPDATE_PEP_STATUS,
  type => (
    payload: InsertIssueAdminPayload,
    event: typeof WS_ROUTES.ISSUE.INSERT_ISSUE_ADMIN,
    handler?: (response: InsertIssueAdminResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updatePEPStatusSubscriber = createAction(
  ActionTypes.UPDATE_PEP_STATUS_SUBSCRIBER,
  action => {
    return (response: InsertIssueAdminResponse) => {
      return action(response)
    }
  },
)

export const resetTeleDataHistory = createAction(
  ActionTypes.RESET_TELEDATA_HISTORY,
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYER TELEDATA HISTORY
 *
 */
export const getPlayerTeledataHistory = createCustomAction(
  ActionTypes.GET_PLAYER_TELEDATA_HISTORY,
  type => (
    payload: GetTeleDataHistoryPayload,
    event: typeof WS_ROUTES.KYC.GET_PLAYER_TELEDATA_HISTORY,
    handler?: (response: GetTeleDataHistoryResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getPlayerTeledataHistorySubscribe = createAction(
  ActionTypes.GET_PLAYER_TELEDATA_HISTORY_SUBSCRIBER,
  action => {
    return (response: GetTeleDataHistoryResponse) => {
      return action(response)
    }
  },
)
