/**
 *
 * Frequency Cap constants
 *
 */

enum ActionTypes {
  GET_ALL_REQUEST = '@frequency-cap/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@frequency-cap/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@frequency-cap/GET_ALL_FAILURE',

  CREATE_REQUEST = '@frequency-cap/CREATE_REQUEST',
  CREATE_SUCCESS = '@frequency-cap/CREATE_SUCCESS',
  CREATE_FAILURE = '@frequency-cap/CREATE_FAILURE',

  UPDATE_REQUEST = '@frequency-cap/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@frequency-cap/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@frequency-cap/UPDATE_FAILURE',

  DELETE_REQUEST = '@frequency-cap/DELETE_REQUEST',
  DELETE_SUCCESS = '@frequency-cap/DELETE_SUCCESS',
  DELETE_FAILURE = '@frequency-cap/DELETE_FAILURE',

  SET_CURRENT_FREQUENCY_CAP = '@frequency-cap/SET_CURRENT_FREQUENCY_CAP',
  SET_FREQUENCY_CAP_DIALOG = '@frequency-cap/SET_FREQUENCY_CAP_DIALOG',
}
export default ActionTypes
