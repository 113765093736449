const playerCard = {
  INDEX: '/player-card',
  PLAYER_TAGS: '/player-tags',
  CRM_TAGS: '/crm-tags',
  TELEDATA: '/teledata',
  BLACKLIST: '/blacklist',
  BLACKLIST_AUDIT: '/blacklist/audit',
}

export default playerCard
