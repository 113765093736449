import { call, put } from 'redux-saga/effects'
import Container from 'typedi'
import FileStorageService from 'services/file-storage/index.service'

import { setLoadingFullAction } from 'state/ui/global/actions'
import DocumentFileFormat from 'state/enums/document-file-format.enum'
import { downloadDocumentImageAction } from '../actions/rest.actions'

/**
 * @instance FileStorageService
 */
const fileStorageService: FileStorageService = Container.get(FileStorageService)

/**
 *
 * This saga get an image from a KYC Document
 *
 * @requires FileStorageService
 * @param {ReturnType<typeof downloadDocumentImageAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* downloadImageDocumentWorker(
  action: ReturnType<typeof downloadDocumentImageAction.request>,
) {
  try {
    fileStorageService.requestParams = {
      method: 'POST',
      endpoint: '/download',
      body: action.payload,
      responseType: 'blob',
    }

    const type = action.payload.file?.includes(DocumentFileFormat.PDF)
      ? DocumentFileFormat.PDF
      : DocumentFileFormat.IMG

    const response = yield call(fileStorageService.sendData, 'burek')
    yield put(setLoadingFullAction(false))
    if (typeof response === 'string' && response.length > 0) {
      yield put(downloadDocumentImageAction.success({ url: response, type }))
    }
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(downloadDocumentImageAction.failure(error))
  }
}

export default downloadImageDocumentWorker
