import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { TemplatesResponse } from '../models'
import { getAllEmailTemplatesAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a list of email templates
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllEmailTemplatesAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllTemplateWorker(
  action: ReturnType<typeof getAllEmailTemplatesAction.request>,
) {
  try {
    const { auth, router } = yield select()
    const isArchive = router.location.pathname.includes('archive')

    apiService.requestParams = {
      method: 'GET',
      endpoint: isArchive
        ? 'templates/email/get/archived'
        : 'templates/email/get',
      queries: jsonToQueryString(action.payload),
    }

    const response: TemplatesResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllEmailTemplatesAction.success(response))
  } catch (error) {
    yield put(getAllEmailTemplatesAction.failure(error))
  }
}

export default getAllTemplateWorker
