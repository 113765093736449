import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'
import initialState from './initial-state'

const createResetPasswordTokenHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.CREATE_RESET_PASSWORD_TOKEN_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.CREATE_RESET_PASSWORD_TOKEN_SUCCESS: {
      if (action.payload._id === state.loggedInUser._id) {
        sessionStorage.setItem('user', JSON.stringify(action.payload))
      }
      return {
        ...state,
        isLoading: false,
        userResetPasswordToken: action.payload,
        loggedInUser:
          action.payload._id === state.loggedInUser._id
            ? action.payload
            : state.loggedInUser,
      }
    }

    case ActionTypes.CREATE_RESET_PASSWORD_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.CREATE_RESET_PASSWORD_TOKEN_CANCEL:
      return {
        ...state,
        isLoading: false,
        userResetPasswordToken: { ...initialState.userResetPasswordToken },
      }

    default:
      return state
  }
}

export default createResetPasswordTokenHandler
