/**
 *
 * Rewards Constants
 *
 */

enum ActionTypes {
  SET_ITEM_FORM_MODE = '@reward-shop/SET_ITEM_FORM_MODE',
  SET_PRODUCT_FORM_MODE = '@reward-shop/SET_PRODUCT_FORM_MODE',
  SET_SHOP_FORM_MODE = '@reward-shop/SET_SHOP_FORM_MODE',
  SET_ORDER_FORM_MODE = '@reward-shop/SET_ORDER_FORM_MODE',

  GET_ALL_REWARD_SHOPS = '@reward-shop/GET_ALL_REWARD_SHOPS',
  GET_ALL_REWARD_SHOPS_SUBSCRIBER = '@reward-shop/GET_ALL_REWARD_SHOPS_SUBSCRIBER',
  GET_ONE_REWARD_SHOP = '@reward-shop/GET_ONE_REWARD_SHOP',
  GET_ONE_REWARD_SHOP_SUBSCRIBER = '@reward-shop/GET_ONE_REWARD_SHOP_SUBSCRIBER',
  CREATE_REWARD_SHOP = '@reward-shop/CREATE_REWARD_SHOP',
  CREATE_REWARD_SHOP_SUBSCRIBER = '@reward-shop/CREATE_REWARD_SHOP_SUBSCRIBER',
  UPDATE_REWARD_SHOP = '@reward-shop/UPDATE_REWARD_SHOP',
  UPDATE_REWARD_SHOP_SUBSCRIBER = '@reward-shop/UPDATE_REWARD_SHOP_SUBSCRIBER',
  DELETE_REWARD_SHOP = '@reward-shop/DELETE_REWARD_SHOP',
  DELETE_REWARD_SHOP_SUBSCRIBER = '@reward-shop/DELETE_REWARD_SHOP_SUBSCRIBER',

  GET_ALL_REWARD_PRODUCTS = '@reward-shop/GET_ALL_REWARD_PRODUCTS',
  GET_ALL_REWARD_PRODUCTS_SUBSCRIBER = '@reward-shop/GET_ALL_REWARD_PRODUCTS_SUBSCRIBER',
  GET_ONE_REWARD_PRODUCT = '@reward-shop/GET_ONE_REWARD_PRODUCT',
  GET_ONE_REWARD_PRODUCT_SUBSCRIBER = '@reward-shop/GET_ONE_REWARD_PRODUCT_SUBSCRIBER',
  CREATE_REWARD_PRODUCT = '@reward-shop/CREATE_REWARD_PRODUCT',
  CREATE_REWARD_PRODUCT_SUBSCRIBER = '@reward-shop/CREATE_REWARD_PRODUCT_SUBSCRIBER',
  UPDATE_REWARD_PRODUCT = '@reward-shop/UPDATE_REWARD_PRODUCT',
  UPDATE_REWARD_PRODUCT_SUBSCRIBER = '@reward-shop/UPDATE_REWARD_PRODUCT_SUBSCRIBER',
  DELETE_REWARD_PRODUCT = '@reward-shop/DELETE_REWARD_PRODUCT',
  DELETE_REWARD_PRODUCT_SUBSCRIBER = '@reward-shop/DELETE_REWARD_PRODUCT_SUBSCRIBER',

  GET_ALL_REWARD_ITEMS = '@reward-shop/GET_ALL_REWARD_ITEMS',
  GET_ALL_REWARD_ITEMS_SUBSCRIBER = '@reward-shop/GET_ALL_REWARD_ITEMS_SUBSCRIBER',
  GET_ONE_REWARD_ITEM = '@reward-shop/GET_ONE_REWARD_ITEM',
  GET_ONE_REWARD_ITEM_SUBSCRIBER = '@reward-shop/GET_ONE_REWARD_ITEM_SUBSCRIBER',
  CREATE_REWARD_ITEM = '@reward-shop/CREATE_REWARD_ITEM',
  CREATE_REWARD_ITEM_SUBSCRIBER = '@reward-shop/CREATE_REWARD_ITEM_SUBSCRIBER',
  UPDATE_REWARD_ITEM = '@reward-shop/UPDATE_REWARD_ITEM',
  UPDATE_REWARD_ITEM_SUBSCRIBER = '@reward-shop/UPDATE_REWARD_ITEM_SUBSCRIBER',
  DELETE_REWARD_ITEM = '@reward-shop/DELETE_REWARD_ITEM',
  DELETE_REWARD_ITEM_SUBSCRIBER = '@reward-shop/DELETE_REWARD_ITEM_SUBSCRIBER',

  GET_ALL_REWARD_ORDERS = '@reward-shop/GET_ALL_REWARD_ORDERS',
  GET_ALL_REWARD_ORDERS_SUBSCRIBER = '@reward-shop/GET_ALL_REWARD_ORDERS_SUBSCRIBER',
  GET_ONE_REWARD_ORDER = '@reward-shop/GET_ONE_REWARD_ORDER',
  GET_ONE_REWARD_ORDER_SUBSCRIBER = '@reward-shop/GET_ONE_REWARD_ORDER_SUBSCRIBER',
  UPDATE_REWARD_ORDER = '@reward-shop/UPDATE_REWARD_ORDER',
  UPDATE_REWARD_ORDER_SUBSCRIBER = '@reward-shop/UPDATE_REWARD_ORDER_SUBSCRIBER',
  DELETE_REWARD_ORDER = '@reward-shop/DELETE_REWARD_ORDER',
  DELETE_REWARD_ORDER_SUBSCRIBER = '@reward-shop/DELETE_REWARD_ORDER_SUBSCRIBER',
  GET_BALANCE_POINTS = '@reward-shop/GET_BALANCE_POINTS',
  GET_BALANCE_POINTS_SUBSCRIBER = '@reward-shop/GET_BALANCE_POINTS_SUBSCRIBER',

  SET_REWARD_SHOP_PAGINATION = '@reward-shop/SET_REWARD_SHOP_PAGINATION',

  SET_ORDER_PAGINATION = '@reward-shop/SET_ORDER_PAGINATION',
}

export default ActionTypes
