/*
 *   Snackbar styles
 */
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { colors } from 'theme/colors.theme'

const { green, white, redLight, blue, orangeLight } = colors

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: 'nowrap',
    },
    success: {
      backgroundColor: green,
    },
    error: {
      backgroundColor: redLight,
    },
    info: {
      backgroundColor: blue,
    },
    warning: {
      backgroundColor: orangeLight,
    },
    icon: {
      fontSize: 20,
      color: white,
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    snackbarMessage: {
      display: 'flex',
      alignItems: 'center',
      color: white,
      fontWeight: 700,
    },
    snackbarCloseButton: {
      marginRight: theme.spacing(1),
      marginTop: '5px',
      cursor: 'pointer',
      opacity: 0.9,
    },
    secondNote: {
      marginTop: '55px',
    },
  }),
)

export default useStyles
