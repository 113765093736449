enum LanguageCode {
  DE = 'de_CH',
  EN = 'en_US',
  FR = 'fr_CH',
  IT = 'it_CH',
  PT = 'pt_CH',
  TR = 'tr_CH',
}

export default LanguageCode
