import { Theme, FormControlLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const formControlLabelTheme = {
  root: {
    marginLeft: 0,
    '&$disabled': {
      opacity: 0.5,
    },
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
  },
}

export const FormControlLabelAvatar = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    margin: 0,
    padding: '4px 8px 4px 0',
    '& .MuiAvatar-root': {
      marginRight: 8,
      '& + .MuiTypography-root': {
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    '&.checked': {
      order: -1,
    },
  },
  label: {
    flexGrow: 1,
    marginRight: 8,
  },
}))(FormControlLabel)
