import { createAction } from 'typesafe-actions'
import FormStatusType from 'state/enums/form-status.enum'
import ActionTypes from './constants'

import {
  SnackbarModel,
  DialogModel,
  PopoverModel,
  PopoverConfirmType,
} from './models'

export const setSnackbarAction = createAction(
  ActionTypes.SET_SNACKBAR,
  action => (snackbarModel: SnackbarModel) => action(snackbarModel),
)

export const setDialogAction = createAction(
  ActionTypes.SET_DIALOG,
  action => (dialogModel: DialogModel) => action(dialogModel),
)

export const setPopoverAction = createAction(
  ActionTypes.SET_POPOVER,
  action => (popoverModel: PopoverModel) => action(popoverModel),
)

export const setPopoverConfirmAction = createAction(
  ActionTypes.SET_POPOVER_CONFIRM,
  action => (popoverConfirmType: PopoverConfirmType) =>
    action(popoverConfirmType),
)

export const setFormModeAction = createAction(
  ActionTypes.SET_FORM_MODE,
  action => (formMode: FormStatusType) => action(formMode),
)

export const setLoadingFullAction = createAction(
  ActionTypes.SET_IS_LOADING_FULL,
  action => (isLoadingFull: boolean) => action(isLoadingFull),
)

export const setDrawerAction = createAction(
  ActionTypes.SET_DRAWER,
  action => (payload: DialogModel) => action(payload),
)

export const setActiveStepAction = createAction(
  ActionTypes.SET_ACTIVE_STEP,
  action => (payload: number) => action(payload),
)

export const setLanguagesSelectedAction = createAction(
  ActionTypes.SET_LANGUAGES_SELECTED,
  action => (payload: string[]) => action(payload),
)

export const setActiveLanguageAction = createAction(
  ActionTypes.SET_ACTIVE_LANGUAGE,
  action => (payload: string) => action(payload),
)

export const setInitAction = createAction(
  ActionTypes.SET_INIT,
  action => (payload: boolean) => action(payload),
)
