const log = {
  logLimit: {
    activeLimit: 'Active Limit',
    limitInserted: 'Limit Inserted',
    limitPeriodId: 'Limit Period',
    limitTypeId: 'Limit Type',
    maxLimit: 'Max Limit',
    maxLimitAuthor: 'Max Limit Author',
    maxLimitCreated: 'Max Limit Created',
    operation: 'Operation',
    playerLimit: 'Player Limit',
    validFrom: 'Valid From',
    validUntil: 'Valid Until',
    limits: 'Limits',
    limitsChanged: 'Limits Changed',
  },
  logLimitType: {
    NET_LOSS: 'Net Loss',
    WAGER: 'Wager Limit',
    DEPOSIT: 'Deposit Limit',
    WIN: 'Win Limit',
    LOGIN_SESSION: 'Login Session Limit',
    PLAY_TIME: 'Play Time Limit',
  },
  logLimitPeriod: {
    HOURLY: 'Hourly',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    ANNUAL: 'Annual',
    TOTAL: 'Total',
  },
  logLimitOperation: {
    NEW: 'was created',
    REMOVE: 'was deleted',
    INCREASE_LIMIT: 'was updated to',
    DECREASE_LIMIT: 'was updated to',
    CANCEL_PENDING_INCREASE: 'was cancel',
    CANCEL_PENDING_REMOVAL: 'was cancel',
    CHANGE_MAX_LIMIT: 'was updated to',
    UPDATE_PENDING_INCREASE: 'was updated to',
    REJECT_PENDING: 'was rejected',
  },
  logCoolOffAdded: {
    createdBy: 'A Cool Off was added by {{createdBy}} on:',
    categories: 'in the Categories: {{categories}}.',
    validUntil: 'It will be valid until',
  },
  logCountType: {
    LOGIN_SESSION: 'Sessions',
    PLAY_TIME: 'Hours',
    CHF: 'CHF',
  },
  logData: {
    currentStatus: 'Current status',
    previousStatus: 'Previous status',
    wasActive: 'Was active',
    wasProvisional: 'Was provisional',
    insertedByAdmin: 'Inserted by Admin',
    attachedUploads: 'Attached uploads',
    isAddressVerified: 'Is address verified',
    verified: 'Verified',
    addressCheckResult: 'Address check result',
    nameHint: 'Name hint',
    character: 'Character',
    addressMatchResultType: 'Address match result type',
    locationIdentification: 'Location identification',
    requestLocationNormalized: 'Request location normalized',
    zip: 'Zip',
    city: 'City',
    street: 'Street',
    country: 'Country',
    regionCode: 'Region code',
    houseNumber: 'House number',
    locationIdentificationType: 'Location identification type',
    newPlayerInfo: 'New player info',
    email: 'Email',
    gender: 'Gender',
    address: 'Address',
    streetName: 'Street name',
    streetNumber: 'Street number',
    currency: 'Currency',
    lastName: 'Last name',
    postcode: 'Postcode',
    username: 'Username',
    birthDate: 'Birth date',
    firstName: 'First name',
    nationality: 'Nationality',
    phoneNumber: 'Phone number',
    otherPlayersInfo: `Other player's info`,
    date: 'Date',
    riskLevel: 'Risk level',
    totalPoints: 'Total points',
    type: 'Type',
    resolve: 'Resolve',
    issueDate: 'Issue date',
    documentId: 'Document id',
    documentType: 'Document type',
    documentExpiry: 'Document expiry',
    documentNumber: 'Document number',
    issuingAuthority: 'Issuing authority',
    playerId: 'Player id',
    fileNames: 'File names',
    utilityId: 'Utility id',
    bic: 'BIC',
    iban: 'IBAN',
    beneficiaryName: 'Beneficiary name',
    isPEP: 'Is PEP',
    barrierIds: 'Barrier ids',
    blacklistedPlayers: 'Blacklisted Players',
    vetoPlayerId: 'Veto player id',
    createdOnVeto: 'Created on veto',
    vetoBarrierId: 'Veto barrier id',
    responsibleProvider: 'Responsible provider',
  },
  addedCoolOff: 'Added cool off',
}

export default log
