import LimitPeriod from 'state/enums/limit-period.enum'
import LimitType from 'state/enums/limit-type.enum'
import * as LimitModels from '../models'

import ActionTypes from '../constants'

const getAllLimitsHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_LIMITS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ALL_LIMITS_SUBSCRIBER: {
      const daily =
        action.payload.message?.filter(
          limit =>
            limit.limitPeriodId === LimitPeriod.DAILY &&
            (limit.limitTypeId === LimitType.LOGIN_SESSION ||
              limit.limitTypeId === LimitType.PLAY_TIME),
        ) || []
      const approved =
        action.payload.message?.filter(
          limit =>
            limit.limitPeriodId === LimitPeriod.MONTHLY &&
            limit.limitTypeId === LimitType.NET_LOSS,
        ) || []
      return {
        ...state,
        isLoading: false,
        allLimits:
          action.payload.message && action.payload.message.length !== 0
            ? [...action.payload.message]
            : [],

        approvedLimits: [...approved],
        dailyLimits: [...daily],
        activeLimit: approved.length > 0 ? approved[0].activeLimit : 0,
        netlossMaxLimit: approved.length > 0 ? approved[0].maxLimit : 0,
        netlossPlayerLimit: approved.length > 0 ? approved[0].playerLimit : 0,
      }
    }
    case ActionTypes.GET_LIMITS_SETTING_LIMITS:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_LIMITS_SETTING_LIMITS_SUBSCRIBER:
      return {
        ...state,
        isLoading: true,
        limitPeriods: action.payload.message.limitPeriods,
        limitTypes: action.payload.message.limitTypes,
        units: action.payload.message.units,
      }
    default:
      return state
  }
}

export default getAllLimitsHandler
