import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white } = colors

export const formLabelTheme = {
  root: {
    color: alpha(white, 0.7),
    fontSize: 14,
    textTransform: 'capitalize' as 'capitalize',
    '&.Mui-focused': {
      color: `${alpha(white, 0.7)}!important`,
    },
    '.MuiTextField-root.label-float &': {
      fontWeight: 'normal' as 'normal',
      '&.MuiInputLabel-outlined': {
        transform: 'translate(14px, 12px) scale(1)',
        opacity: 0.5,
        zIndex: 'auto' as 'auto',
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
          opacity: 1,
          fontStyle: 'normal' as 'normal',
          '.Mui-focused &': {
            zIndex: 2,
          },
        },
      },
    },
  },
}
