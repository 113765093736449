import ActionTypes from '../constants'
import { LevelDefinitionActions, LevelsState } from '../models'

const getOneLevelDefinitionHandler = (
  state: LevelsState,
  action: LevelDefinitionActions,
): LevelsState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_LEVEL_DEFINITION:
      return {
        ...state,
        isLoading: true,
        isLoadingFull: true,
      }

    case ActionTypes.GET_ONE_LEVEL_DEFINITION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        isLoadingFull: false,
        currentLevel: {
          ...action.payload.message,
          enabled: action.payload.message?.gameCategoryModifiers[0]?.active || false,
        },
      }

    default:
      return state
  }
}

export default getOneLevelDefinitionHandler
