import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const filterRulesHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.FILTER_RULES:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.FILTER_RULES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rules: action.payload?.docs || [],
        pagination: {
          total: action.payload?.total || 0,
          limit: action.payload?.limit || 25,
          page: action.payload?.page || 1,
          pages: action.payload?.pages || 0,
        },
      }

    default:
      return state
  }
}

export default filterRulesHandler
