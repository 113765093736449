import * as UserReducer from './reducers/index.reducer'
import * as UserSagas from './sagas/index.saga'
import * as UserTypes from './constants'

import * as UserSelectors from './selectors'
import * as UserActions from './actions/index.actions'
import * as UserModels from './models'

export { UserSagas, UserTypes, UserSelectors, UserActions, UserModels }

export default UserReducer
