import EmailTemplateType from 'state/enums/email-template-type.enum'
import FormStatusType from 'state/enums/form-status.enum'
import { TemplateEmailState } from '../models'

import EmailProviderType from '../../enums/email-provider'

const initialState: TemplateEmailState = {
  isLoading: false,
  isLoadingFull: false,
  isLoadingPartial: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  emailTemplates: [],
  emailTemplatesArchived: [],
  formMode: FormStatusType.CREATE,
  currentTemplate: {
    active: false,
    isDeleted: false,
    _id: '',
    name: '',
    tags: [],
    description: '',
    fromEmail: '',
    fromName: '',
    replyTo: '',
    createdBy: '',
    createdAt: '',
    companyId: '',
    creationMode: EmailTemplateType.VISUAL,
    templates: [],
  },
  emailCampaign: {
    users: [],
    provider: {
      type: EmailProviderType.Client,
      apiKey: '',
      defaultLanguage: 'EN',
      ipPool: `${process.env.REACT_APP_SENDGRID_IP_POOL_DEV}`,
    },
    templateId: '',
  },
  currentTemplateLanguages: {},
  sendEmailTest: {
    type: 2,
    companyId: '',
    provider: {
      type: EmailProviderType.Client,
      apiKey: '',
      defaultLanguage: `${process.env.REACT_APP_DEFAULT_LANGUAGE}`,
      ipPool: `${process.env.REACT_APP_SENDGRID_IP_POOL_DEV}`,
    },
    from: '',
    fromName: '',
    to: '',
    replyTo: '',
    replyToName: '',
    templates: [],
    placeholders: {
      AccountStatus: 'active',
      Age: 30,
      AllowsEmails: true,
      AllowsSMS: false,
      Amount: 20,
      BonusId: 12,
      BonusName: 'Name of bonus',
      City: 'Geneve',
      Country: 'CH',
      Currency: 'CHF',
      Date: '2019-11-06T22:12:08.794Z',
      DateBirth: '1989-04-18T22:12:08.794Z',
      EmailAddress: 'test@gamingenius.net',
      Message: 'Message test',
      BonusExpirationDate: '10-10-2020',
      URL: 'https://gamanza.com/reset-password?email={{EmailAdress}}',
      FirstDeposit: '2019-08-20T22:12:08.794Z',
      FirstName: 'TestName',
      Gender: 'f',
      GrantedBonusAmount: 122,
      Issue: 123,
      LastHourSession: 'Last Hour Session xx',
      LastLogin: '2020-02-20T22:12:08.794Z',
      LastName: 'TestLastName',
      MobileNumber: '41794144881',
      Nationality: 'CH',
      NumberSessions: 'NumberSessions xx',
      PaymentMethod: 'Payment Method xx',
      PlayerId: '',
      PlayerLanguage: `${process.env.REACT_APP_DEFAULT_LANGUAGE}`,
      PostalCode: 675,
      RealMoneyBalance: 'Real Money Balance xx',
      BonusMoneyBalance: 'Bonus Money Balance xx',
      RealTimeNetLoss: 'RealTimeNetLoss xx',
      Reason: 'Test reason',
      RegistrationDate: '2019-08-18T22:12:08.794Z',
      RemainingNetLoss: 'RemainingNetLoss xx',
      SmsVerificationCode: 'asudya7s6da8sdagsday',
      TotalPlayingTime: 'TotalPlayingTime xx',
      UserName: 'TestUsername',
      WageringRequirement: 10,

      // TODO : Placeholder pending to be added.
      // ActionTag: 'ActionTag',
      // Method: 'Method',
      // MethodValue: 'MethodValue',
      // Note: 'Note',
      // NoteCategory: 'NoteCategory',
      // NoteCategoryCount: 'NoteCategoryCount',
      // NoteCount: 'NoteCount',
      // NotePriority: 'NotePriority',
      // ReferredFirstName: 'ReferredFirstName',
      // ReferredLastName: 'ReferredLastName',
      // RiskLevel: 'RiskLevel',
      // TagAssigned: 'TagAssigned',
      // TagCategory: 'TagCategory',
      // TagUnassigned: 'TagUnassigned',
      // TitleFirstName: 'TitleFirstName',
      // TitleLastName: 'TitleLastName',
      // Type: 'Type',
      // TypeValue: 'TypeValue',
    },
  },
  sendEmailType: {
    type: 0,
    companyId: '',
    from: '',
    fromName: '',
    to: '',
    replyTo: '',
    replyToName: '',
    templates: [
      {
        language: '',
        subject: '',
        HTML: '',
      },
    ],
    placeholders: {
      URL: 'https://gamanza.com/reset-password?email={{EmailAdress}}',
      EmailAddress: 'angel.rodriguez@gamanza.com',
      FirstName: 'Angel',
      LastName: 'Rodriguez',
      UserName: 'angel',
    },
  },
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
  },
  popover: {
    open: false,
    element: null,
    content: null,
  },
  dialog: {
    open: false,
    title: '',
    content: null,
    maxWidth: 'xs',
  },
  activeLanguage: '',
}

export default initialState
