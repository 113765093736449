const sms = {
  addLongURL: 'Add the long url that you want to shorten',
  createAnSms: 'Create an SMS',
  createAnSmsText: 'Start by filling in the information in step 1.',
  createSmsTemplate: 'Create SMS Template',
  getShortURLButton: 'Get Short URL',
  goToSmsTemplates: 'Go To SMS Templates',
  messageLanguageTextSMS:
    'Select the language and add the information you want to include in your SMS. You must enter the text in each chosen language.',
  messageSmsDisclaimer: 'message{{s}} (will vary if placeholders are added)',
  noLanguagesSelected: 'No Languages Selected',
  sendTest: 'Send Test',
  sendTestSMSText:
    'Please enter a valid phone number and click on the “Send Test” button to receive a test message.',
  shortURLPlaceholder: 'https://example.com',
  smsSettings: 'SMS Settings',
  smsSummary: 'SMS Summary',
  smsTemplate: 'SMS Template',
  templateSMSDetailsText:
    'Add the name, tags and description with which you want to save your SMS template. All fields are required.',
}

export default sms
