import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import { setSnackbarAction } from 'state/ui/global/actions'
import { createResetPassTokenAction } from 'state/user/actions/rest.actions'

import { sendEmailAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga sends an email of type:
 *
 *  * SendEmailType
 *
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof sendEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* sendEmailToUserWorker(
  action: ReturnType<typeof sendEmailAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/email/send-email',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (response.status === 200) {
      yield put(sendEmailAction.success(response))
      yield put(
        setSnackbarAction({
          message: i18n.t('userEmailHasBeenSent', {
            email: action.payload.placeholders.EmailAddress,
          }),
          open: true,
          variant: 'success',
        }),
      )
      yield put(createResetPassTokenAction.cancel())
    } else {
      yield put(
        sendEmailAction.failure(response.error || response.error.message),
      )
      yield put(createResetPassTokenAction.cancel())
      yield put(
        setSnackbarAction({
          message: i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(sendEmailAction.failure(error))
  }
}

export default sendEmailToUserWorker
