import ActionTypes from '../constants'
import { RewardsState, RewardsActions, RewardItemType, RewardItemCreatePayload } from '../models'

const itemHandler = (
  state: RewardsState,
  action: RewardsActions,
): RewardsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REWARD_ITEMS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_REWARD_ITEMS_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardItems: (action.payload.resolved &&
          action.payload.message) ? [...action.payload.message as RewardItemType[]] : [],
      }

    case ActionTypes.GET_ONE_REWARD_ITEM:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ONE_REWARD_ITEM_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentItem: action.payload.resolved ? action.payload.message as RewardItemType : undefined
      }

    case ActionTypes.CREATE_REWARD_ITEM:
      return {
        ...state,
        isLoading: true,
        currentItem: action.payload
      }

    case ActionTypes.CREATE_REWARD_ITEM_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        rewardItems: [ 
          ...state.rewardItems, 
          {
            ...state.currentItem as RewardItemCreatePayload,
            id: action.payload.message as number,
            created: 0,
            createdTs: '',
            minLevelHierarchyNo: 1
          }
        ]
      }

    case ActionTypes.UPDATE_REWARD_ITEM:
        return {
          ...state,
          isLoading: true,
          currentItem: action.payload
        }
  
    case ActionTypes.UPDATE_REWARD_ITEM_SUBSCRIBER:
        return {
          ...state,
          isLoading: false,
          rewardItems: state.rewardItems.map(shop => {
            let thisItem = shop
            if (shop.id === action.payload.message) {
              thisItem = state.currentItem as RewardItemType
            }
            return thisItem
          })
        }

      case ActionTypes.DELETE_REWARD_ITEM:
        return {
          ...state,
          isLoading: true,
        }
  
      case ActionTypes.DELETE_REWARD_ITEM_SUBSCRIBER:
        return {
          ...state,
          isLoading: false,
          rewardItems: state.rewardItems.filter(shop => shop.id !== action.payload.message)
        }


    default:
      return state
  }
}

export default itemHandler
