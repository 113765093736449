import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Box, Paper, alpha } from '@material-ui/core'

import { StateSchema } from 'hooks/form/models'
import { colors } from 'theme/colors.theme'
import ShortcutType from 'state/enums/shortcut.enum'

import { PopoverModel } from 'state/ui/global/models'
import FormURLComponent from './form-url.component'
import FormMenuComponent from './form-menu.component'

type Props = {
  handleOnChange: (name: string, value: any) => void
  state: StateSchema
  disable: boolean
  handleAddShortcut: (name: string, url: string, type: ShortcutType) => void
  handleUpdateShortcut: () => void
  handleDeleteShortcut: (_id: string) => void
  handleResetAddShortcut: () => void
  shortcutPopper: PopoverModel
  handleShortcutAddClose: () => void
}

const ShortcutAddComponent = ({
  state,
  handleOnChange,
  disable,
  handleAddShortcut,
  handleUpdateShortcut,
  handleDeleteShortcut,
  handleResetAddShortcut,
  shortcutPopper,
  handleShortcutAddClose,
}: Props) => {
  const { t } = useTranslation()

  const isEditing = shortcutPopper.id === 'shortcutEdit'

  return (
    <>
      <Typography variant="body2">
        {t(isEditing ? 'sidebar.editShortcut' : 'sidebar.addURLShortcut')}
      </Typography>
      <Box my={1} py={1.5} borderTop={1} borderColor={alpha(colors.white, 0.1)}>
        <Paper>
          <Box p={2}>
            <FormURLComponent
              state={state}
              handleOnChange={handleOnChange}
              disable={disable}
              handleAddShortcut={handleAddShortcut}
              handleUpdateShortcut={handleUpdateShortcut}
              handleResetAddShortcut={handleResetAddShortcut}
              isEditing={isEditing}
              handleShortcutAddClose={handleShortcutAddClose}
            />
          </Box>
        </Paper>
      </Box>
      {!isEditing && (
        <>
          <Typography variant="body2">
            {t('sidebar.addMenuShortcut')}
          </Typography>
          <Box
            mt={1}
            py={1.5}
            borderTop={1}
            borderColor={alpha(colors.white, 0.1)}
          >
            <Paper>
              <Box p={2}>
                <FormMenuComponent
                  handleAddShortcut={handleAddShortcut}
                  handleDeleteShortcut={handleDeleteShortcut}
                />
              </Box>
            </Paper>
          </Box>
        </>
      )}
    </>
  )
}

export default ShortcutAddComponent
