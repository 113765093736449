/**
 *
 * PlayerCardTag constants
 *
 */

enum ActionTypes {
  GET_ALL = '@pcard-tag/GET_ALL',
  GET_ALL_SUBSCRIBER = '@pcard-tag/GET_ALL_SUBSCRIBER',

  ASSIGN_TAG = '@pcard-tag/ASSIGN_TAG',

  REMOVE_TAG = '@pcard-tag/REMOVE_TAG',

  GET_TAG_EXCEPTION_SUBSCRIBER = '@tag/GET_TAG_EXCEPTION_SUBSCRIBER',
  SET_TAGS = '@pcard-tag/SET_TAGS',
  SET_TAG_CATEGORIES = '@pcard-tag/SET_TAG_CATEGORIES',
}

export default ActionTypes
