import TypeValue from 'state/enums/type-value.enum'

import { PlayerCardState, PlayerInfoType } from '../models'

export const playerTestData: PlayerInfoType = {
  playerId: '',
  testAccount: false,
  firstName: '',
  lastName: '',
  birthday: '',
  nationality: '',
  gender: '',
  phoneNumber: '',
  iban: '',
  beneficiaryName: '',
  bic: '',
  address: '',
  streetNumber: '',
  city: '',
  postalCode: '',
  country: '',
  username: '',
  email: '',
  isEmailVerified: false,
  isDocumentUploaded: false,
  isDocumentVerified: false,
  isUtilityUploaded: false,
  isUtilityVerified: false,
  isAddressVerified: false,
  isSuperVerified: false,
  isMobileVerified: false,
  isPEP: false,
  currency: '',
  allowLogin: false,
  allowPlay: false,
  allowDeposit: false,
  allowWithdraw: false,
  accountStatus: 1,
  registrationIp: '',
  registered: '',
  ballyId: 123,
  realBalance: 10,
  bonusBalance: 10,
  lastLogin: '',
  loggedIn: false,
  limits: [],
  allowEmail: true,
  allowSms: true,
  allowTelephone: true,
  allowPost: true,
  language: '',
  riskLevel: 0,
  tags: [],
  level: 1,
  twoFactorEnabled: false,
  barriereClubCard: {
    cardNumber: '',
    cardLevel: '',
    fetishDates: [],
  },
  myAffiliateToken: null,
}

const initialState: PlayerCardState = {
  accountStatusLogs: [],
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  player: { ...playerTestData },
  players: [],
  filters: {
    exact: false,
    typeValue: TypeValue.STRING,
    filterValue: '',
    filter: 'username',
    limit: 25,
    page: 0,
  },
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  crifCheckAddress: null,
  issuesCount: [],
}

export default initialState
