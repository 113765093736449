import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import welcomeEmailTemplate from 'email-htmls/templates/welcome.email'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import { getOneCompanyAction } from 'state/company/actions/rest.actions'
import { setSnackbarAction } from 'state/ui/global/actions'
import { sendEmailAction } from 'state/template-email/actions/rest.actions'

import SendDirectEmailType from 'state/enums/send-email-type.enum'
import jsonToQueryString from 'utils/query-string'
import EmailProviderType from 'state/enums/email-provider'

import Languages from 'state/enums/languages.enum'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single company
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOneCompanyAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getCompanyFromSessionWorker(
  action: ReturnType<typeof getOneCompanyAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'companies/get/one',
      queries: jsonToQueryString({ companyId: action.payload._id }),
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (
      response?.status === 500 ||
      response?.status === 404 ||
      response?.error
    ) {
      yield put(
        getOneCompanyAction.failure({
          name: response?.status,
          message: i18n.t('dontHaveProviderInfo'),
        }),
      )

      yield put(
        setSnackbarAction({
          message: i18n.t('dontHaveProviderInfo'),
          open: true,
          variant: 'error',
        }),
      )
    }
    yield put(getOneCompanyAction.success(response))
    const { user, router } = yield select()

    if (router?.location?.pathname === routes.users.INDEX) {
      yield put(
        sendEmailAction.request({
          type: SendDirectEmailType.WELCOME_USER,
          companyId: user?.userResetPasswordToken?.companyId,
          provider: {
            type: EmailProviderType.Develop,
            apiKey: `${process.env.REACT_APP_SENDGRID_API_KEY_DEV}`,
            defaultLanguage: `${process.env.REACT_APP_DEFAULT_LANGUAGE}`,
            ipPool: `${process.env.REACT_APP_SENDGRID_IP_POOL_DEV}`,
          },
          from: 'admin@gamingeniusdev.com',
          fromName: 'Gamanza Admin',
          to: user?.userResetPasswordToken?.email,
          replyTo: 'no-reply@gamingeniusdev.com',
          replyToName: 'Not Reply',
          templates: [
            {
              language: Languages.EN,
              subject: 'Welcome',
              HTML: welcomeEmailTemplate,
            },
          ],
          placeholders: {
            URL: `${process.env.REACT_APP_SITE_DEV}reset-password?email={{EmailAddress}}&nn=${user.userResetPasswordToken?.passwordResetToken}&username={{UserName}}`,
            EmailAddress: user?.userResetPasswordToken?.email,
            FirstName: user?.userResetPasswordToken?.firstName,
            LastName: user?.userResetPasswordToken?.lastName,
            UserName: user?.userResetPasswordToken?.username,
          },
        }),
      )
    }
  } catch (error) {
    yield put(getOneCompanyAction.failure(error))
  }
}

export default getCompanyFromSessionWorker
