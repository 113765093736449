import ActionTypes from '../constants'
import { FrequencyCapState, FrequencyCapActions } from '../models'

const frequencyCapGetAllHandler = (
  state: FrequencyCapState,
  action: FrequencyCapActions,
): FrequencyCapState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        frequenciesCap: action?.payload?.docs || [],
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default frequencyCapGetAllHandler
