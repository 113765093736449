import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const { white, grayDark, blue, redDark, green, blueMedium } = colors

export const formControlTheme = {
  root: {
    '&.full': {
      minWidth: '100%',
    },
    '&.input-verified': {
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline ': {
        borderColor: colors.green,
      },
    },
    '&.not-verified': {
      '& .Mui-disabled .MuiOutlinedInput-notchedOutline ': {
        borderColor: colors.orangeMedium,
      },
    },
    '&.MuiFormControl-inputLeft': {
      '& .MuiInputBase-input': {
        order: -1,
      },
    },
    '&.MuiFormControl-inputRight': {
      '& .MuiInputBase-input': {
        order: 1,
      },
    },
    '&.MuiFormControl-inputTop': {
      '& .MuiInputBase-root.MuiInputBase-multiline': {
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    },
    '&.MuiFormControl-divided': {
      '& .MuiInputAdornment-root': {
        borderLeft: `2px solid ${alpha(white, 0.5)}`,
      },
      '& .Mui-disabled .MuiInputAdornment-root': {
        borderLeft: `2px solid ${alpha(white, 0.2)}`,
      },
      '& .Mui-focused .MuiInputAdornment-root': {
        borderLeft: `2px solid ${blue}`,
      },
      '& .Mui-error .MuiInputAdornment-root': {
        borderLeft: `2px solid ${redDark}`,
      },
    },
    '&.MuiFormControl-datePicker': {
      '& .MuiFormLabel-root': {
        color: alpha(white, 0.7),
      },
      '& .MuiInputBase-input': {
        padding: '6px 0!important',
        margin: '0 8px',
      },
      '& .MuiInput-root': {
        padding: '2px 0',
        borderRadius: 4,
        border: `2px solid ${alpha(white, 0.7)}`,
        maxWidth: 132,
        height: 36,
        '&.Mui-disabled': {
          opacity: 0.6,
        },
        '& .MuiIconButton-root.Mui-disabled': {
          color: alpha(white, 0.6),
        },
      },
      '& .MuiInputAdornment-root .MuiSvgIcon-root': {
        fontSize: '1.25rem',
      },
      '&.full': {
        '& .MuiInput-root': {
          maxWidth: 'none',
        },
      },
      '&.green': {
        '& .MuiInput-root': {
          border: `2px solid ${green} !important`,
        },
      },
      '&.red': {
        '& .MuiInput-root': {
          border: `2px solid ${redDark} !important`,
        },
      },
    },
    '&.MuiFormControl-datePickerIconWrap': {
      position: 'relative' as 'relative',
      '& .MuiSvgIcon-root': {
        position: 'absolute' as 'absolute',
        top: 8,
        right: 8,
        zIndex: 1,
        fontSize: '1.25rem',
        color: alpha(white, 0.6),
        pointerEvents: 'none' as 'none',
      },
      '& .MuiInput-root': {
        padding: '0 28px 0 8px!important',
      },
      '&.full': {
        width: '100%',
      },
    },
    '&.MuiFormControl-datePicker-no-adornment': {
      '& .MuiInput-root': {
        padding: '2px 10px',
        borderRadius: 4,
        backgroundColor: grayDark,
        border: 'none',
        height: 36,
      },
    },
    '&.MuiFormControl-chips': {
      '& .MuiInputBase-root': {
        paddingRight: '2px!important',
      },
      '& .MuiInputBase-input': {
        display: 'none',
      },
      '& .MuiInputAdornment-root': {
        overflow: 'hidden',
        flexShrink: 0,
        width: '100%',
      },
      '&.types': {
        '&:hover': {
          background: blueMedium,
          zIndex: 1301,
          '& .MuiInputBase-root': {
            height: 'auto',
          },
          '& .MuiInputAdornment-root': {
            height: 'auto',
            flexWrap: 'wrap',
            paddingTop: 2,
            paddingBottom: 1,
          },
        },
      },
    },
  },
}
