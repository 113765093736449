import account from './account.translation'
import analytics from './analytics.translation'
import annexe from './annexe.translation'

import blacklist from './blacklist.translation'
import bonusCampaigns from './bonus-campaigns.translation'
import bonusEngine from './bonus-engine.translation'

import bonusReport from './bonus-report.translation'
import campaigns from './campaigns.translation'
import clients from './clients.translation'

import common from './common.translation'
import commonLabels from './common-labels.translation'
import customTrigger from './custom-trigger.translation'

import email from './email.translation'
import error from './error.translation'
import frequencyCap from './frequency-cap.translation'

import gameStore from './game-store.translation'
import gamification from './gamification.translation'
import gamingActivity from './gaming-activity.translation'

import ggrReport from './ggr-report.translation'
import kyc from './kyc.translation'
import levels from './levels.translation'

import log from './log.translation'
import login from './login.translation'
import mediaDatabase from './media-database.translation'

import pages from './pages.translation'
import personalInfo from './personal-info.translation'
import placeholders from './placeholders.translation'

import playerCard from './player-card.translation'
import referFriend from './refer-friend.translation'
import responsibleGaming from './responsible-gaming.translation'

import riskManagement from './risk-management.translation'
import role from './role.translation'
import rule from './rule.translation'

import segmentations from './segmentations.translation'
import segments from './segments.translation'
import sidebar from './sidebar.translation'

import sms from './sms.translation'
import tags from './tags.translation'
import templates from './templates.translation'

import transactionsBonuses from './transactions-bonuses.translation'
import users from './users.translation'
import wallet from './wallet.translation'

import websockets from './websockets.translation'
import withdrawals from './withdrawals.translation'
import userLogs from './user-logs.translation'

import templatesOnSite from './templates-onsite.translation'
import templatesPush from './templates-push.translation'
import missions from './missions.translation'

const EN_US = {
  translation: {
    ...account,
    ...analytics,
    ...annexe,
    ...blacklist,
    ...bonusCampaigns,
    ...bonusEngine,
    ...bonusReport,
    ...campaigns,
    ...clients,
    ...common,
    ...commonLabels,
    ...customTrigger,
    ...email,
    ...error,
    ...frequencyCap,
    ...gameStore,
    ...gamification,
    ...gamingActivity,
    ...ggrReport,
    ...kyc,
    ...levels,
    ...log,
    ...login,
    ...mediaDatabase,
    ...missions,
    ...pages,
    ...personalInfo,
    ...placeholders,
    ...playerCard,
    ...referFriend,
    ...responsibleGaming,
    ...riskManagement,
    ...role,
    ...rule,
    ...segmentations,
    ...segments,
    ...sidebar,
    ...sms,
    ...tags,
    ...templates,
    ...templatesOnSite,
    ...templatesPush,
    ...transactionsBonuses,
    ...userLogs,
    ...users,
    ...wallet,
    ...websockets,
    ...withdrawals,
  },
}

export default EN_US
