import ActionTypes from '../constants'

import { UserState, UserActions } from '../models'

const userResetPasswordHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.RESET_PASSWORD_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, wasPasswordReset: true }

    case ActionTypes.RESET_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return state
  }
}

export default userResetPasswordHandler
