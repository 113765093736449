import React, { useState, useEffect } from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core'
import SidebarId from 'state/enums/sidebar-id.enum'
import { SidebarLinkType } from '../../hooks/use-sidebar-links.hook'

import useStyles from '../../style'
import useSidebarUI from '../../hooks/use-sidebar-ui.hook'

type Props = {
  link: SidebarLinkType
  handleDrawerClose: () => void
  openDrawer: boolean
  sidebarIds: SidebarId[]
}

const MenuLinkComponent = ({
  link,
  handleDrawerClose,
  openDrawer,
  sidebarIds,
}: Props) => {
  const history = useHistory()

  const classes = useStyles()

  const { isCurrentPage } = useSidebarUI()

  const isCurrentParent =
    link.links && link.links.some(item => isCurrentPage(item.route))

  const [openDropdown, setOpenDropdown] = useState(false)

  const handleClickDropdown = () => {
    if (link.links && link.links.length > 0) {
      setOpenDropdown(!openDropdown)
    } else {
      handleDrawerClose()
      history.push(link.route)
    }
  }

  const handleClickLink = () => {
    handleDrawerClose()
    setOpenDropdown(!openDropdown)
  }

  useEffect(() => {
    if (!openDrawer) {
      setOpenDropdown(false)
    }
  }, [openDrawer])

  return (
    <>
      <ListItem
        button
        disableGutters
        onClick={handleClickDropdown}
        className={`${classes.link} ${
          isCurrentPage(link.route) ? 'active' : isCurrentParent ? 'active' : ''
        }`}
      >
        <ListItemIcon>{link.labelIcon}</ListItemIcon>
        <ListItemText primary={link.labelText} className={classes.linkClosed} />
        <ListItemText primary={link.labelText} className={classes.linkOpen} />
        {link.links && <>{openDropdown ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItem>
      {link.links && link.links.length > 0 && (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List disablePadding>
            {link.links &&
              link.links
                .filter(item => sidebarIds.includes(item.id) && item.visible)
                .map(item => (
                  <ListItem
                    disableGutters
                    key={item.id}
                    component={NavLink}
                    to={item.route}
                    onClick={handleClickLink}
                    className={classes.linkInner}
                    exact
                  >
                    <ListItemText inset primary={item.labelText} />
                  </ListItem>
                ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

export default MenuLinkComponent
