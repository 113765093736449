import { utcToZonedTime } from 'date-fns-tz'
import TimeZoneUTCType from 'state/enums/timezone-utc.enum'
import TimeZoneType from 'state/enums/timezone.enum'

import { UserLogsState, UserLogType } from '../models'

const todayUTC = utcToZonedTime(new Date(), TimeZoneUTCType[TimeZoneType.GMT_0])

export const initialUser: UserLogType = {
  _id: '',
  actionType: 0,
  product: '',
  subProduct: '',
  details: '',
  createdAt: '',
  updatedAt: '',
  subject: '',
  user: '',
  userInfo: {
    id: '',
    firstName: '',
    lastName: '',
    username: '',
    companyId: '',
    role: '',
  },
  objectChanges: {
    old: '',
    new: '',
  },
  date: {
    date: '',
    time: '',
  },
}

const initialState: UserLogsState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  userLogs: [],
  products: [],
  subProducts: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
    order: 'DESC',
    dateFrom: '2021-01-01T00:00:00.000',
    dateTo: `${new Date(todayUTC).getFullYear()}-${`0${new Date(
      todayUTC,
    ).getMonth() + 1}`.slice(-2)}-${`0${new Date(todayUTC).getDate()}`.slice(
      -2,
    )}T23:59:59.000`,
  },
}

export default initialState
