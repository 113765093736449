import { AssessmentState } from '../models'

const AssessmentInitialState: AssessmentState = {
  assessments: [],
  assessmentsQuestions: [],
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
}

export default AssessmentInitialState
