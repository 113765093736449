import { darken } from '@material-ui/core'
import { colors } from './colors.theme'

const { blue3C, blueDarkNav, gray, blue } = colors

export const tableRowTheme = {
  root: {
    backgroundColor: blueDarkNav,
    '&:nth-of-type(odd)': {
      backgroundColor: blue3C,
    },
    '&.dark-row': {
      backgroundColor: blueDarkNav,
      '& .MuiTableCell-root': {
        border: 'none',
      },
      '&:last-child': {
        '& .MuiTableCell-root': {
          borderTop: `1px solid ${gray}`,
        },
      },
    },
    '&.light-row': {
      backgroundColor: blue3C,
      '& .MuiTableCell-root': {
        borderTop: `1px solid ${gray}`,
      },
      '&:last-child': {
        '& .MuiTableCell-root': {
          borderTop: `1px solid ${gray}`,
        },
      },
    },
    '&.MuiTableRow-selected': {
      borderLeft: `4px solid ${blue}`,
    },
    '&.MuiTableRow-dropdown': {
      '&:nth-child(4n) , &:nth-child(4n-1)': {
        backgroundColor: blueDarkNav,
      },
      '&:nth-child(4n-2) , &:nth-child(4n-3)': {
        backgroundColor: blue3C,
      },
    },
  },
  hover: {
    '&.MuiTableRow-hoverClick:hover': {
      cursor: 'pointer',
    },
  },
}
