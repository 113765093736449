const personalInfo = {
  accountInfo: 'Account Information',
  addManualCredit: 'Add Manual Credit',
  addManualDebit: 'Add Manual Debit',
  addNewNote: 'Add New Note',
  addNote: 'Add Note',
  addressNotVerified: 'Address not verified',
  addressVerified: 'Address verified',
  affiliateCode: 'Affiliate Code',
  assignedBy: 'Assigned By',
  assignedDate: 'Assigned Date',
  assignTag: 'Assign Tag',
  ballyCard: 'Bally Card',
  ballyInstruction:
    'This information refers to the landbased loyalty system, such as Level, ID, Status Points and Prime Points',
  barriereClubCard: 'Barriere Club Card',
  bonusBalance: 'Bonus Balance',
  cardLevel: 'Card Level',
  category: 'Category',
  communicationChannels: 'Communication Channels',
  editAccount: 'Edit Account',
  editNote: 'Edit Note',
  emailNotVerified: 'Email not verified',
  emailVerified: 'Email verified',
  fetishDates: 'Fetish Dates',
  gamificationLevel: 'Gamification Level',
  information: 'information',
  landbasedID: 'Landbased ID',
  landbaseId: 'Landbase ID',
  lastLogin: 'Last Login',
  logins: 'Logins',
  logs: 'Logs',
  manuallyApprovedByOperator: 'Manually approved by operator',
  manuallyNotApprovedByOperator: 'Manually not approved by operator',
  noDataAvailable: 'No Data Available',
  noNotes: 'No Notes',
  noNotesInstruction: 'To add a note click on the plus button',
  notes: 'Notes',
  personalInfo: 'Personal Info.',
  phoneNumberNotVerified: 'Phone number not verified',
  phoneNumberVerified: 'Phone number verified',
  playerLanguage: 'Player Language',
  playerTags: 'Player Tags',
  primePoints: 'Prime Points',
  realBalance: 'Real Balance',
  registrationDate: 'Registration Date',
  resendVerificationEmail: 'Resend verification email',
  resendVerificationEmailConfirm: 'Verification email was sent',
  resendVerificationSms: 'Resend verification SMS',
  resendVerificationSmsConfirm: 'Verification SMS was sent',
  saveNote: 'Save Note',
  statusPoints: 'Status Points',
  tagName: 'Tag Name',
  twoFAEnable: '2FA Enabled',
  twoFA: 'Two-Factor Authentication (2FA):',
  unlinkInstruction: 'If you want to unlink the card, click on this button:',
  verifyAddressManually: 'Verify address manually',
  wasCopied: '{{word}} was copied to the clipboard.',
  playerCardAlertOptions: {
    RISK: 'Risk',
    RESPONSIBLE_GAMING: 'Resp. Gaming',
    KYC: 'KYC',
    GAMIFICATION: 'Gamification',
  },
}
export default personalInfo
