import { select, put, call } from 'redux-saga/effects'
import { Container } from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { updateCampaignAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the update of a single campaign
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateCampaignAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateCampaignWorker(
  action: ReturnType<typeof updateCampaignAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'campaigns/update/one',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.updateData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('campaign'),
      what: i18n.t('messages.updated'),
    })

    if (response._id) {
      yield put(updateCampaignAction.success(response))
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        updateCampaignAction.failure({
          name: response.status || '',
          message,
        }),
      )
    }
    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(updateCampaignAction.failure(error))
  }
}

export default updateCampaignWorker
