import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Theme, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { colors } from './colors.theme'

const {
  orangeDark,
  blue,
  redDark,
  green,
  white,
  yellow,
  purple,
  teal,
  lightBlue,
  orange,
} = colors

export const iconButtonTheme = {
  root: {
    color: alpha(white, 0.7),
    padding: 5,
    '&.margin, &.margin-x': {
      marginLeft: '4px!important',
      marginRight: '4px!important',
    },
    '& svg': {
      fontSize: '1.25rem',
    },
    '&:hover': {
      backgroundColor: alpha(white, 0.2),
    },
    '&.danger, &.red': {
      color: `${redDark}!important`,
      '&:hover': {
        backgroundColor: alpha(redDark, 0.2),
      },
    },
    '&.success, &.green': {
      color: `${green}!important`,
      '&:hover': {
        backgroundColor: alpha(green, 0.2),
      },
    },
    '&.warning, &.yellow': {
      color: `${yellow}!important`,
      '&:hover': {
        backgroundColor: alpha(yellow, 0.2),
      },
    },
    '&.purple': {
      color: `${purple}!important`,
      '&:hover': {
        backgroundColor: alpha(purple, 0.2),
      },
    },
    '&.teal': {
      color: `${teal}!important`,
      '&:hover': {
        backgroundColor: alpha(teal, 0.2),
      },
    },
    '&.orangeDark': {
      color: `${orangeDark}!important`,
      '&:hover': {
        backgroundColor: alpha(orangeDark, 0.2),
      },
    },
    '&.gray': {
      color: `${alpha(white, 0.6)}!important`,
      '&:hover': {
        backgroundColor: alpha(white, 0.2),
      },
    },
    '&.lightBlue': {
      color: `${alpha(lightBlue, 0.6)}!important`,
      '&:hover': {
        backgroundColor: alpha(lightBlue, 0.2),
      },
    },
    '&.blue': {
      color: `${blue}!important`,
      '&:hover': {
        backgroundColor: alpha(blue, 0.2),
      },
    },
    '&.orange': {
      color: `${orange}!important`,
      '&:hover': {
        backgroundColor: alpha(orange, 0.2),
      },
    },
    '&.MuiPickersDay-dayDisabled': {
      opacity: 0.5,
    },
    '&$disabled': {
      opacity: 0.4,
    },
    '&.notHover': {
      '&:hover': {
        backgroundColor: 'transparent!important',
      },
    },
  },
  colorPrimary: {
    color: `${blue}!important`,
    '&:hover': {
      backgroundColor: alpha(blue, 0.2),
    },
  },
  sizeSmall: {
    padding: 5,
    '& svg': {
      fontSize: '1rem',
    },
  },
}

export const IconButtonBg = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: alpha(white, 0.1),
    '&.danger, &.red': {
      backgroundColor: `${alpha(redDark, 0.3)}!important`,
    },
    '&.success, &.green': {
      backgroundColor: `${alpha(green, 0.3)}!important`,
    },
    '&.warning, &.yellow': {
      backgroundColor: `${alpha(yellow, 0.3)}!important`,
    },
    '&.purple': {
      backgroundColor: `${alpha(purple, 0.3)}!important`,
    },
    '&.teal': {
      backgroundColor: `${alpha(teal, 0.3)}!important`,
    },
    '&.orangeDark': {
      backgroundColor: `${alpha(orangeDark, 0.3)}!important`,
    },
    '&.lightBlue': {
      backgroundColor: `${alpha(lightBlue, 0.3)}!important`,
    },
    '&.blue': {
      backgroundColor: `${alpha(blue, 0.3)}!important`,
    },
    '&.blue-solid': {
      color: white,
      backgroundColor: `${blue}!important`,
    },
    '&.orange': {
      backgroundColor: `${alpha(orange, 0.3)}!important`,
    },
  },
  colorPrimary: {
    backgroundColor: `${alpha(blue, 0.3)}!important`,
  },
  colorSecondary: {
    backgroundColor: `${alpha(orangeDark, 0.3)}!important`,
  },
}))(IconButton)
