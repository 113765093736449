import FormStatusType from 'state/enums/form-status.enum'
import { RewardsState } from '../models'

const initialState: RewardsState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  balancePoints: 0,
  formItem: FormStatusType.CREATE,
  formProduct: FormStatusType.CREATE,
  formShop: FormStatusType.CREATE,
  formOrder: FormStatusType.CREATE,
  rewardShops: [],
  rewardProducts: [],
  rewardItems: [],
  rewardOrders: [],
  currentShop: undefined,
  currentProduct: undefined,
  currentItem: undefined,
  currentOrder: undefined,
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default initialState
