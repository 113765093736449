import { createReducer } from 'typesafe-actions'
import { TemplateActions, TemplateState } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import templateCreateHandler from './create.handler'
import templateGetOneHandler from './get-one.handler'

import templateUpdateHandler from './update.handler'
import templateUIHandler from './ui-action-handler'
import templateGetAllHandler from './get-all.handler'

const templateOnSiteReducer = createReducer<TemplateState, TemplateActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    templateCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    templateGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    templateUpdateHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], templateUIHandler)
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
    ],
    templateGetAllHandler,
  )

export default templateOnSiteReducer
