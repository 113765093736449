import LimitPeriod from 'state/enums/limit-period.enum'
import LimitType from 'state/enums/limit-type.enum'
import LimitUnit from 'state/enums/limit-unit.enum'

import { LimitsState } from '../models'

const LimitsInitialState: LimitsState = {
  allLimits: [],
  approvedLimits: [],
  pendingLimits: [],
  dailyLimits: [],
  limitTypes: [],
  limitPeriods: [],
  units: [],
  playerLimits: [],
  newPlayerLimit: 0,
  activeLimit: 0,
  netlossMaxLimit: 0,
  netlossPlayerLimit: 0,
  maxLimitDeposit: '0',
  isLoading: false,
  currentGamingLimit: {
    playerId: '',
    playerLimit: 0,
    limitTypeId: LimitType.NET_LOSS,
    limitPeriodId: LimitPeriod.MONTHLY,
    id: 0,
    used: 0,
    left: 0,
    activeLimit: 0,
    unitId: LimitUnit.DAY,
    maxLimit: 0,
  },
}

export default LimitsInitialState
