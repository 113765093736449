enum BonusStatus {
  ALL = 'ALL',
  ACCEPTED_AWAITING_EVENT = 'ACCEPTED_AWAITING_EVENT',
  ELIGIBLE = 'ELIGIBLE',
  GRANTED = 'GRANTED',
  GRANTED_PENDING_ACCEPT = 'GRANTED_PENDING_ACCEPT',
  GRANTED_PENDING_ACTIVATION = 'GRANTED_PENDING_ACTIVATION',
  GRANTED_FREE_GAMES = 'GRANTED_FREE_GAMES',
  FREE_GAME_ACTIVE = 'FREE_GAME_ACTIVE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CRITERIA_NOT_MET = 'CRITERIA_NOT_MET',
  DECLINED = 'DECLINED',
  ENDED = 'ENDED',
  EXCLUDED = 'EXCLUDED',
  EXPIRED = 'EXPIRED',
  GRANTED_EXPIRED = 'GRANTED_EXPIRED',
}

export default BonusStatus
