const RULES_ENGINE = {
  CREATE_RULE: 'createRule' as 'createRule',
  FIND_RULE: 'findRule' as 'findRule',
  UPDATE_RULE: 'updateRule' as 'updateRule',
  DELETE_RULE: 'archiveRule' as 'archiveRule',
  DUPLICATE_RULE: 'duplicateRule' as 'duplicateRule',
  FILTER_RULES: 'filterRules' as 'filterRules',
  GET_ZENDESK_AGENTS: 'zendeskAgents' as 'zendeskAgents',
  GET_SLACK_LIST: 'slackList' as 'slackList',
  SAVE_EVALUATION: 'saveEvaluation' as 'saveEvaluation',
  GET_EVALUATION: 'getEvaluationConfig' as 'getEvaluationConfig',
}

export default RULES_ENGINE
