import { UserLogActions, UserLogsState } from '../models'
import ActionTypes from '../constants'

const getAllUserLogsHandler = (
  state: UserLogsState,
  action: UserLogActions,
): UserLogsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_USER_LOG:
      return {
        ...state,
        isLoading: true,
      }
    case ActionTypes.GET_ALL_USER_LOG_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        userLogs: action.payload?.docs || [],
        pagination: {
          limit: action.payload?.limit || 25,
          page: action.payload?.page || 1,
          pages: action.payload?.totalPages || 1,
          total: action.payload?.totalDocs || 0,
        },
      }

    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    default:
      return state
  }
}

export default getAllUserLogsHandler
