import React from 'react'
import { Link } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'

import { MoreVert, Reply } from '@material-ui/icons'
import { ShortcutLinkType } from 'state/user/models'
import ShortcutType from 'state/enums/shortcut.enum'

import useStyles from '../../style'

type Props = {
  link: ShortcutLinkType
  handleDrawerClose: () => void
  handleShortcutMenuOpen: (
    event: React.MouseEvent<HTMLElement>,
    type: ShortcutType,
    _id: string,
  ) => void
  openDrawer: boolean
}

const ShortcutLinkComponent = ({
  link,
  handleDrawerClose,
  handleShortcutMenuOpen,
  openDrawer,
}: Props) => {
  const classes = useStyles()

  const isInternalLink =
    process.env.REACT_APP_SITE_DEV !== undefined &&
    link.url.includes(process.env.REACT_APP_SITE_DEV)

  return (
    <ListItem
      button
      disableGutters
      component={isInternalLink ? Link : 'a'}
      to={`/${link.url.replace(process.env.REACT_APP_SITE_DEV || '', '')}`}
      href={link.url}
      onClick={handleDrawerClose}
      className={classes.link}
    >
      <ListItemIcon>
        <Reply className={classes.flip} />
      </ListItemIcon>
      <ListItemText primary={link.name} className={classes.linkClosed} />
      <ListItemText primary={link.name} className={classes.linkOpen} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            handleShortcutMenuOpen(event, link.type, link._id)
          }
          className={classes.fadeIn}
        >
          <MoreVert />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ShortcutLinkComponent
