import { ApplicationState } from '../root.models'
import { TemplateState } from './models'

/**
 *
 * * Template On Site Notification selectors
 *
 */
export const root = ({ templateOnSite }: ApplicationState) => templateOnSite

export const templateOnSiteNamesSelector = ({ templates }: TemplateState) => {
  return templates
}

export default root
