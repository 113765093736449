import { createReducer } from 'typesafe-actions'
import { BonusReportState, BonusReportActions } from '../models'
import ActionTypes from '../constants'

import bonusEngineInitialState from './initial-state'
import offerHandler from './offer.handler'
import campaignHandler from './campaign.handler'

/**
 *
 * Bonus Engine main reducer
 *
 */
const BonusReportReducer = createReducer<BonusReportState, BonusReportActions>(
  bonusEngineInitialState,
)
  .handleAction(
    [
      ActionTypes.GET_BONUS_OFFERS_REPORT,
      ActionTypes.GET_BONUS_OFFERS_REPORT_SUBSCRIBER,
      ActionTypes.SET_OFFERS_REPORT_PAGINATION,
      ActionTypes.GET_BONUS_PLAYER_COUNT,
      ActionTypes.GET_BONUS_PLAYER_COUNT_SUBSCRIBER,
      ActionTypes.SET_OFFER_DRAWER_PAGINATION,
      ActionTypes.GET_STATE_REPORT,
      ActionTypes.GET_STATE_REPORT_SUBSCRIBER,
      ActionTypes.GET_DEPOSIT_TRIGGER_REPORT,
      ActionTypes.GET_DEPOSIT_TRIGGER_REPORT_SUBSCRIBER,
      ActionTypes.SET_LOADING_DRAWER,
      ActionTypes.RESET_DRAWER,
    ],
    offerHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT,
      ActionTypes.GET_BONUS_OFFERS_ON_CAMPAIGN_REPORT_SUBSCRIBER,
    ],
    campaignHandler,
  )

export default BonusReportReducer
