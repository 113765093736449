import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const manualTransactionsHandler = (
  state: WalletModels.State,
  action: WalletModels.SocketActions | WalletModels.Actions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.ADD_MANUAL_DEPOSIT:
      return {
        ...state,
        isLoadingFull: true,
      }

    case ActionTypes.ADD_MANUAL_WITHDRAWAL:
      return {
        ...state,
        isLoadingFull: true,
      }

    case ActionTypes.ADD_MANUAL_DEPOSIT_SUBSCRIBER:
      return {
        ...state,
        player: action.payload.playerCard,
        resolved: action.payload.resolved,
        isLoadingFull: false,
      }

    case ActionTypes.ADD_MANUAL_WITHDRAWAL_SUBSCRIBER:
      return {
        ...state,
        player: action.payload.playerCard,
        resolved: action.payload.resolved,
        isLoadingFull: false,
      }

    case ActionTypes.WALLET_RESET:
      return {
        ...state,
        resolved: false,
        error: '',
      }

    default:
      return state
  }
}

export default manualTransactionsHandler
