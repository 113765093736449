import { createReducer } from 'typesafe-actions'
import { CustomTriggersActions, CustomTriggersState } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import generalHandler from './general.handler'
import createCustomTriggerHandler from './create.handler'

import updateCustomTriggerHandler from './update.handler'
import getOneCustomTriggerHandler from './get-one.handler'
import getAllCustomTriggerHandler from './get-all.handler'

import archiveCustomTriggerHandler from './archive.handler'
import duplicateCustomTriggerHandler from './duplicate.handler'

/**
 * Creates a Custom Triggers Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const customTriggersReducer = createReducer<
  CustomTriggersState,
  CustomTriggersActions
>(initialState)
  .handleAction(
    [
      ActionTypes.SET_CUSTOM_TRIGGER_FILTERS,
      ActionTypes.RESET_CUSTOM_TRIGGERS,
      ActionTypes.RESET_CURRENT_CUSTOM_TRIGGER,
    ],
    generalHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    createCustomTriggerHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    updateCustomTriggerHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_CUSTOM_TRIGGER_REQUEST,
      ActionTypes.GET_ONE_CUSTOM_TRIGGER_SUCCESS,
      ActionTypes.GET_ONE_CUSTOM_TRIGGER_FAILURE,
    ],
    getOneCustomTriggerHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_CUSTOM_TRIGGER_REQUEST,
      ActionTypes.GET_ALL_CUSTOM_TRIGGER_SUCCESS,
      ActionTypes.GET_ALL_CUSTOM_TRIGGER_FAILURE,
    ],
    getAllCustomTriggerHandler,
  )
  .handleAction(
    [
      ActionTypes.ARCHIVE_REQUEST,
      ActionTypes.ARCHIVE_SUCCESS,
      ActionTypes.ARCHIVE_FAILURE,
    ],
    archiveCustomTriggerHandler,
  )
  .handleAction(
    [
      ActionTypes.DUPLICATE_REQUEST,
      ActionTypes.DUPLICATE_SUCCESS,
      ActionTypes.DUPLICATE_FAILURE,
    ],
    duplicateCustomTriggerHandler,
  )

export default customTriggersReducer
