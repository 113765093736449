import ActionTypes from '../constants'
import { GamingActivityActions, GamingActivityState } from '../models'

const filtersHandler = (
  state: GamingActivityState,
  action: GamingActivityActions,
): GamingActivityState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filtersGameSession: {
          ...state.filtersGameSession,
          ...action.payload,
        },
        paginationGameSessions: {
          ...state.paginationGameSessions,
          limit: action.payload.pageSize || state.paginationGameSessions.limit,
          page: action.payload.page || state.paginationGameSessions.page,
          pages: Math.ceil(state.paginationGameSessions.total / (action.payload.pageSize || state.paginationGameSessions.limit))
        }
      }

    default:
      return state
  }
}

export default filtersHandler
