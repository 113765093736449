import { ApplicationState } from 'state/root.models'
import { GlobalUiState } from './models'

export const snackbarSelector = ({ snackbar }: GlobalUiState) => snackbar

export const popoverConfirmSelector = ({ popoverConfirm }: GlobalUiState) =>
  popoverConfirm

export const root = ({ globalUi }: ApplicationState) => globalUi

export const isLoadingFullSelector = ({ globalUi }: ApplicationState) => {
  return globalUi.isLoadingFull
}

export const currentDialogSelector = (id: string) => ({
  globalUi,
}: ApplicationState) => {
  const [dialog] = globalUi?.dialogs?.filter(d => d.id === id)
  return dialog?.open ? [dialog] : [{ open: false }]
}

export default snackbarSelector
