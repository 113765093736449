import { createReducer } from 'typesafe-actions'
import { BlacklistState, BlacklistActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import blacklistHandler from './blacklist.handler'
import filtersHandler from './filters.handler'

import deleteBlacklistRecordHandler from './delete.handler'

/**
 * Creates a Blacklist Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */

const blacklistReducer = createReducer<BlacklistState, BlacklistActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_BLACKLIST_REPORT,
      ActionTypes.GET_BLACKLIST_REPORT_SUBSCRIBER,
      ActionTypes.GET_BLACKLIST_AUDIT_REPORT,
      ActionTypes.GET_BLACKLIST_AUDIT_REPORT_SUBSCRIBER,
    ],
    blacklistHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_BLACKLIST_RECORD,
      ActionTypes.DELETE_BLACKLIST_RECORD_SUBSCRIBER,
    ],
    deleteBlacklistRecordHandler,
  )
  .handleAction(
    [ActionTypes.SET_FILTERS, ActionTypes.SET_PAGINATION],
    filtersHandler,
  )

export default blacklistReducer
