/**
 * KYC Rest Actions
 */
import PlayerDocumentStatusType from 'state/enums/player-document-status.enum'
import { createAction, createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'

import {
  DownloadDocImagePayload,
  DownloadDocImageResponse,
  GetDocumentFilteredPayload,
  GetDocumentImagesPayload,
  GetDocumentImagesResponse,
  GetJumioDataPayload,
  GetJumioDataResponse,
  JumioType,
  UploadDocumentPayload,
  UploadDocumentResponse,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const getDocumentImagesAction = createAsyncAction(
  ActionTypes.GET_DOCUMENT_IMAGES_REQUEST,
  ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS,
  ActionTypes.GET_DOCUMENT_IMAGES_FAILURE,
)<GetDocumentImagesPayload, GetDocumentImagesResponse, Error>()

export const downloadDocumentImageAction = createAsyncAction(
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST,
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS,
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE,
)<DownloadDocImagePayload, DownloadDocImageResponse, Error>()

export const uploadDocumentsAction = createAsyncAction(
  ActionTypes.UPLOAD_DOCUMENT_REQUEST,
  ActionTypes.UPLOAD_DOCUMENT_SUCCESS,
  ActionTypes.UPLOAD_DOCUMENT_FAILURE,
)<UploadDocumentPayload, UploadDocumentResponse, Error>()

export const getJumioDataAction = createAsyncAction(
  ActionTypes.GET_JUMIO_DATA_REQUEST,
  ActionTypes.GET_JUMIO_DATA_SUCCESS,
  ActionTypes.GET_JUMIO_DATA_FAILURE,
)<GetJumioDataPayload, GetJumioDataResponse, Error>()

export const setCurrentImageUrl = createAction(
  ActionTypes.SET_CURRENT_IMAGE_URL,
  action => {
    return (url: string) => {
      return action(url)
    }
  },
)

export const setCurrentSecondImageUrl = createAction(
  ActionTypes.SET_CURRENT_SECOND_IMAGE_URL,
  action => {
    return (url: string) => {
      return action(url)
    }
  },
)

export const setJumioStatus = createAction(
  ActionTypes.SET_JUMIO_STATUS,
  action => {
    return (status: PlayerDocumentStatusType) => {
      return action(status)
    }
  },
)

export const setJumioInfo = createAction(ActionTypes.SET_JUMIO_INFO, action => {
  return (data: JumioType) => {
    return action(data)
  }
})

export const toggleLoadingAction = createAction(
  ActionTypes.TOGGLE_LOADING,
  action => {
    return (loading: boolean) => action(loading)
  },
)

export const setFilterDocumentsAction = createAction(
  ActionTypes.SET_DOCUMENT_FILTERS,
  actions => {
    return (filters?: GetDocumentFilteredPayload) => actions(filters)
  },
)
