import ActionTypes from '../constants'
import { TagState, TagActions } from '../models'

const updateCurrentCategoryHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      }

    default:
      return state
  }
}

export default updateCurrentCategoryHandler
