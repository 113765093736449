import { SegmentState } from '../models'

const initialState: SegmentState = {
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
  },
  isLoadingPartial: false,
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  segments: [],
  currentSegment: {
    tags: [],
    active: false,
    _id: '',
    name: '',
    description: '',
    type: 1,
    createdBy: '',
    companyId: '',
    rules: [],
    createdAt: '',
    updatedAt: '',
  },
  players: [],
  playersCount: 0,
}

export default initialState
