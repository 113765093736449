import * as LimitModels from '../models'
import ActionTypes from '../constants'

const setNewLimitHandler = (
  state: LimitModels.LimitsState,
  action: LimitModels.LimitActions,
): LimitModels.LimitsState => {
  switch (action.type) {
    case ActionTypes.SET_NEW_LIMIT_SUBSCRIBER:
      return {
        ...state,
        newPlayerLimit: action.payload,
      }

    case ActionTypes.SET_CURRENT_GAMING_LIMIT: {
      return {
        ...state,
        currentGamingLimit: { ...action.payload },
      }
    }

    default:
      return state
  }
}

export default setNewLimitHandler
