import { createReducer } from 'typesafe-actions'
import { TagActions, TagState } from '../models'
import { initialState } from './initial-state'

import ActionTypes from '../constants'
import updateTagDialogHandler from './set-dialog.handler'
import getAllCategoriesHandler from './get-all-categories.handler'

import getAllTagsHandler from './get-all-tags.handler'
import updateCurrentTagHandler from './update-current-tag.handler'
import updateCurrentCategoryHandler from './update-current-category.handler'

import bulkTagAssignHandler from './bulk-tag-assign.handler'
import filtersHandler from './filters.handler'
import updateTagTypeHandler from './update-tag-type.handler'

import getAllTagsByCategoryHandler from './get-all-tags-by-category.handler'
import getLinkedTagPlayersHandler from './get-linked-tag-players.handler'

/**
 * Creates a Tag Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const tagsReducer = createReducer<TagState, TagActions>(initialState)
  .handleAction([ActionTypes.SET_TAG_DIALOG], updateTagDialogHandler)
  .handleAction([ActionTypes.SET_CURRENT_TAG], updateCurrentTagHandler)
  .handleAction(
    [ActionTypes.SET_CURRENT_CATEGORY],
    updateCurrentCategoryHandler,
  )
  .handleAction(
    [ActionTypes.GET_ALL_CATEGORIES, ActionTypes.GET_ALL_CATEGORIES_SUBSCRIBER],
    getAllCategoriesHandler,
  )
  .handleAction(
    [ActionTypes.GET_ALL_TAGS, ActionTypes.GET_ALL_TAGS_SUBSCRIBER],
    getAllTagsHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_TAGS_BY_CATEGORY,
      ActionTypes.GET_ALL_TAGS_BY_CATEGORY_SUBSCRIBER,
    ],
    getAllTagsByCategoryHandler,
  )
  .handleAction(
    [
      ActionTypes.BULK_ASSIGN_TAG,
      ActionTypes.BULK_REMOVE_TAG,
      ActionTypes.BULK_TAG_ACTION_SUBSCRIBER,
    ],
    bulkTagAssignHandler,
  )
  .handleAction([ActionTypes.SET_TAG_TYPE], updateTagTypeHandler)
  .handleAction(
    [ActionTypes.SET_FILTERS, ActionTypes.SET_PLAYER_TAGS_PAGINATION],
    filtersHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_LINKED_TAG_PLAYERS,
      ActionTypes.GET_LINKED_TAG_PLAYERS_SUBSCRIBER,
      ActionTypes.GET_PLAYERS_BY_IDS,
      ActionTypes.GET_PLAYERS_BY_IDS_SUBSCRIBER,
      ActionTypes.SET_TAG_PLAYERS_FILTERS,
      ActionTypes.SET_LOADING,
      ActionTypes.FORMAT_TAG_PLAYERS,
      ActionTypes.SET_TAG_PLAYERS_ID,
    ],
    getLinkedTagPlayersHandler,
  )

export default tagsReducer
