import { UserLogActions, UserLogsState } from '../models'
import ActionTypes from '../constants'

const getProductsHandler = (
  state: UserLogsState,
  action: UserLogActions,
): UserLogsState => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTS:
      return {
        ...state,
      }
    case ActionTypes.GET_PRODUCTS_SUBSCRIBER:
      return {
        ...state,
        products:
          action.payload.products?.map(item => item.product) || state.products,
        subProducts:
          action.payload.products?.map(item => item.subProducts).flat() ||
          state.subProducts,
      }

    default:
      return state
  }
}

export default getProductsHandler
