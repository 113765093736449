import { TemplateState } from '../models'

const initialState: TemplateState = {
  isLoading: false,
  templates: [],
  pagination: {
    total: 0,
    pages: 1,
    page: 1,
    limit: 25,
  },
  currentTemplate: {
    createdAt: '',
    createdBy: '',
    description: '',
    isDeleted: false,
    name: '',
    templates: [],
    updatedAt: '',
    _id: '',
  },
  filters: {
    limit: 25,
    page: 1,
  },
}

export default initialState
