import { createReducer } from 'typesafe-actions'
import { SettingsState, SettingsActions } from '../models'
import initialState from './initial-state'

import ActionTypes from '../constants'
import filterUserSettingsHandler from './filter-user-settings.handler'
import createUserSettingsHandler from './create-user-settings.handler'

import columnTransferHandler from './column-transfer.handler'
import updateUserSettingsHandler from './update-settings.handler'
import applyUserSettingsHandler from './apply-settings.handler'

/**
 * Creates a User Settings Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const settingsReducer = createReducer<SettingsState, SettingsActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.FILTER_USER_SETTINGS_REQUEST,
      ActionTypes.FILTER_USER_SETTINGS_SUCCESS,
      ActionTypes.FILTER_USER_SETTINGS_FAILURE,
    ],
    filterUserSettingsHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_USER_SETTINGS_REQUEST,
      ActionTypes.CREATE_USER_SETTINGS_SUCCESS,
      ActionTypes.CREATE_USER_SETTINGS_FAILURE,
    ],
    createUserSettingsHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_USER_SETTINGS_REQUEST,
      ActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
      ActionTypes.UPDATE_USER_SETTINGS_FAILURE,
    ],
    updateUserSettingsHandler,
  )
  .handleAction(
    [ActionTypes.SELECT_COLUMNS, ActionTypes.UNSELECT_COLUMNS],
    columnTransferHandler,
  )
  .handleAction(
    [
      ActionTypes.APPLY_SETTINGS,
      ActionTypes.REMOVE_COLUMN_SETTINGS,
      ActionTypes.CHANGE_WITHDRAWAL_TYPE,
      ActionTypes.LOAD_SETTINGS,
      ActionTypes.RESET_SETTINGS,
    ],
    applyUserSettingsHandler,
  )

export default settingsReducer
