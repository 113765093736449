import { EmailBuilderState, LanguageSelectedProps } from './models'
import languagesData from '../../../constants/language-codes/index.constant'
import FormStatusType from '../../enums/form-status.enum'

const languagesCodes: LanguageSelectedProps[] = languagesData.map(codeItem => ({
  ...codeItem,
  selected: false,
}))

export const initialTemplateState = {
  id: '',
  emailsInfo: [],
  activeTab: '',
  emailTest: {
    error: '',
    value: '',
  },
}

const initialStateEmailBuilder: EmailBuilderState = {
  ...initialTemplateState,
  formMode: FormStatusType.CREATE,
  popover: {
    open: false,
    element: null,
    content: null,
  },
  dialog: {
    open: false,
    content: '',
  },
  languages: languagesCodes,
}

export default initialStateEmailBuilder
