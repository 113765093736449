import ActionTypes from '../constants'
import { RulesEngineState, RulesEngineActions } from '../models'

const updateRuleHandler = (
  state: RulesEngineState,
  action: RulesEngineActions,
): RulesEngineState => {
  switch (action.type) {
    case ActionTypes.UPDATE_RULE:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_RULE_SUBSCRIBER:
      return {
        ...state,
        currentRule: action.payload,
      }

    default:
      return state
  }
}

export default updateRuleHandler
