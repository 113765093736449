const role = {
  createRole: 'Create Role',
  newRole: 'New Role',
  editOr: 'Edit or',
  deleteRole: 'Delete Role',
  deleteRoleMessage:
    'This role cannot be removed because it is in use. Please remove the {{amount}} assigned users first.',
}

export default role
