import { takeLatest } from 'redux-saga/effects'
import {
  createTemplateEmailAction,
  updateTemplateEmailAction,
  updateTemplateEmailStatusAction,
  duplicateTemplateEmailAction,
  archiveTemplateEmailAction,
  getOneTemplateEmailAction,
  getLanguagesTemplateAction,
  getAllEmailTemplatesAction,
  sendEmailTestAction,
  sendEmailAction,
  sendEmailForgotPasswordAction,
} from '../actions/rest.actions'
import createTemplateWorker from './create.saga'

import updateTemplateWorker from './update.saga'
import updateStatusTemplateWorker from './update-status.saga'
import duplicateTemplateWorker from './duplicate.saga'

import archiveTemplateWorker from './archive.saga'
import getOneTemplateWorker from './get-one.saga'
import getLanguagesOneTemplateWorker from './get-languages-from-one.saga'

import getAllTemplatesWorker from './get-all.saga'
import sendEmailTestTemplateWorker from './send-email-test.saga'
import sendEmailToUserWorker from './send-email-user.saga'

import sendEmailForgotPasswordWorker from './send-email-forgot-password.saga'

/**
 * Main Saga for watching saga workers  in the Template state shape
 *
 * @generator
 */
function* templateSagasWatcher() {
  yield [
    yield takeLatest(createTemplateEmailAction.request, createTemplateWorker),
    yield takeLatest(updateTemplateEmailAction.request, updateTemplateWorker),
    yield takeLatest(
      updateTemplateEmailStatusAction.request,
      updateStatusTemplateWorker,
    ),
    yield takeLatest(
      duplicateTemplateEmailAction.request,
      duplicateTemplateWorker,
    ),
    yield takeLatest(archiveTemplateEmailAction.request, archiveTemplateWorker),
    yield takeLatest(getOneTemplateEmailAction.request, getOneTemplateWorker),
    yield takeLatest(
      getLanguagesTemplateAction.request,
      getLanguagesOneTemplateWorker,
    ),
    yield takeLatest(getAllEmailTemplatesAction.request, getAllTemplatesWorker),
    yield takeLatest(sendEmailTestAction.request, sendEmailTestTemplateWorker),
    yield takeLatest(sendEmailAction.request, sendEmailToUserWorker),
    yield takeLatest(
      sendEmailForgotPasswordAction.request,
      sendEmailForgotPasswordWorker,
    ),
  ]
}

export default templateSagasWatcher
