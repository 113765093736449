import LanguageProps from './types'

const languagesCodes: LanguageProps[] = [
  { code: 'EN', flag: 'gb' },
  { code: 'IT', flag: 'it' },
  { code: 'FR', flag: 'fr' },
  { code: 'DE', flag: 'de' },
]

// !VALIDATION: env & client
export default process.env.REACT_APP_CLIENT === 'HH'
  ? [...languagesCodes, { code: 'PT', flag: 'pt' }, { code: 'TR', flag: 'tr' }]
  : languagesCodes
