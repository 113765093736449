import IssueTypes from 'state/enums/issue-type.enum'
import RiskLevel from 'state/enums/risk-level-alerts.enum'
import TimelineEntrySubType from 'state/enums/timeline-entry-subtype.enum'

import { playerAlertRiskLevelList } from 'constants/player-card/index.constants'
import DocumentFileFormat from 'state/enums/document-file-format.enum'
import { IssueState, RiskLevelCount, CurrentIssue } from '../model'

export const dataRiskLevels: RiskLevelCount[] = [
  {
    id: RiskLevel.UNDEFINED,
    name: playerAlertRiskLevelList[RiskLevel.UNDEFINED].label,
    count: 0,
  },
  {
    id: RiskLevel.LOW,
    name: playerAlertRiskLevelList[RiskLevel.LOW].label,
    count: 0,
  },
  {
    id: RiskLevel.NORMAL,
    name: playerAlertRiskLevelList[RiskLevel.NORMAL].label,
    count: 0,
  },
  {
    id: RiskLevel.MEDIUM,
    name: playerAlertRiskLevelList[RiskLevel.MEDIUM].label,
    count: 0,
  },
  {
    id: RiskLevel.HIGH,
    name: playerAlertRiskLevelList[RiskLevel.HIGH].label,
    count: 0,
  },
  {
    id: RiskLevel.SEVERE,
    name: playerAlertRiskLevelList[RiskLevel.SEVERE].label,
    count: 0,
  },
]

export const dataCurrentIssue: CurrentIssue = {
  id: 0,
  playerId: '',
  issueType: IssueTypes.ALL,
  riskLevel: RiskLevel.UNDEFINED,
  comment: '',
  resolved: false,
  created: '',
  riskManagerId: 0,
  assignTo: [0],
  history: [],
  data: {},
  entrySubType: TimelineEntrySubType.ISSUES, // TODO : Set undefined value
  username: '',
}

const IssuesInitialState: IssueState = {
  areNotificationsOpen: false,
  issueIdFilter: 0,
  issues: [],
  documents: [],
  documentImages: [],
  currentIssue: dataCurrentIssue,
  issuesCountPlayerLast: '',
  issuesCountLast: [],
  issuesCount: [],
  currentDownloadedImage: '',
  currentDownloadedSecondImage: '',
  documentPreview: {
    format: DocumentFileFormat.IMG,
  },
  issuesCountByPlayer: [],
  riskLevelCount: dataRiskLevels,
  filters: {
    resolved: false,
  },
  dialog: {
    open: false,
    title: '',
    id: '',
    maxWidth: 'xs',
  },
  hasError: false,
  isLoading: false,
  isLoadingImg: false,
  error: '',
  wasDeleted: false,
  resolved: false,
  pagination: {
    page: 1,
    limit: 10,
    total: 0,
    pages: 0,
  },
  alertCodes: {
    alerts: [],
    fraudAlerts: [],
  },
}

export default IssuesInitialState
