const gamification = {
  REWARD_SHOP_PRODUCTS: '/reward-shop-products',
  CREATE_REWARD_SHOP_PRODUCTS: '/reward-shop-products/create',
  UPDATE_REWARD_SHOP_PRODUCTS: '/reward-shop-products/update',
  REWARD_SHOP_ITEMS: '/reward-shop-items',
  CREATE_REWARD_SHOP_ITEMS: '/reward-shop-items/create',
  UPDATE_REWARD_SHOP_ITEMS: '/reward-shop-items/update',
  REWARD_SHOP: '/reward-shop',
  CREATE_REWARD_SHOP: '/reward-shop/create',
  UPDATE_REWARD_SHOP: '/reward-shop/update',
  LEVELS: '/levels',
  CREATE_LEVELS: '/levels/create',
  UPDATE_LEVELS: '/levels/update'
}

export default gamification
