import { PaginationResponse, WSErrorException } from 'state/root.models'
import { PlayerSearchProps } from 'state/search/models'
import WS_NAMESPACES from 'state/websocket.namespaces'

import { createAction, createCustomAction } from 'typesafe-actions'
import { GetIssuesCountResponse } from 'state/issues/model'
import ActionTypes from '../constants'

import {
  CrifCheckAddressResponse,
  CheckIsSamePlayerPayload,
  CheckIsSamePlayerResponse,
  CreateAccountStatusPayload,
  CreateAccountStatusResponse,
  GetAccountStatusLogsPayload,
  GetAccountStatusLogsResponse,
  GetPlayerPayload,
  GetPlayerResponse,
  GetPlayersPayload,
  GetPlayersResponse,
  InsertIssueAdminPayload,
  InsertIssueAdminResponse,
  DisableTwoFactorPayload,
  DisableTwoFactorResponse,
  ResendEmailResponse,
  UpdatePlayerCardPayload,
  UpdatePlayerCardResponse,
  UpdatePaymentDataPayload,
  UpdatePaymentDataResponse,
  UpdateAccountStatusResponse,
  UpdateAccountStatusActivePayload,
  UpdateAccountStatusBlockPayload,
  UnlinkBarriereClubCardPayload,
  UnlinkBarriereClubCardResponse,
  UpdateAccountStatusClosePayload,
  UpdateAccountStatusFullyIdentifiedPayload,
  EmailUpdatePayload,
  EmailUpdateResponse,
  UpdateNewAccountStatusResponse,
  LinkBarriereClubCardPayload,
  LinkBarriereClubCardResponse,
} from '../models'
import WS_ROUTES from '../socket.routes'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const setPlayerFilter = createAction(
  ActionTypes.SET_PLAYER_FILTERS,
  action => {
    return (payload: PlayerSearchProps) => action(payload)
  },
)

export const resetPlayerInfo = createAction(ActionTypes.RESET_PLAYER_INFO)

export const sendEmailVerifyAction = createCustomAction(
  ActionTypes.RESEND_EMAIL,
  type => (
    payload: GetPlayerPayload,
    event: typeof WS_ROUTES.RESEND_EMAIL,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)
/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] RESEND EMAIL VERIFICATION
 *
 */

export const sendEmailVerificationSubscribe = createAction(
  ActionTypes.RESEND_EMAIL_SUBSCRIBER,
  action => {
    return (response: ResendEmailResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] RESEND SMS VERIFICATION
 *
 */
export const sendSMSVerificationAction = createCustomAction(
  ActionTypes.RESEND_SMS,
  type => (
    payload: GetPlayerPayload,
    event: typeof WS_ROUTES.RESEND_SMS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYER
 *
 */
export const getPlayer = createCustomAction(
  ActionTypes.GET_PLAYER,
  type => (
    payload: GetPlayerPayload,
    event: typeof WS_ROUTES.GET_PLAYER,
    handler?: (response: GetPlayerResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const getPlayerSubscribe = createAction(
  ActionTypes.GET_PLAYER_SUBSCRIBER,
  action => {
    return (response: GetPlayerResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYERS
 *
 */
export const getPlayers = createCustomAction(
  ActionTypes.GET_PLAYERS,
  type => (
    payload: GetPlayersPayload,
    event: typeof WS_ROUTES.GET_PLAYERS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.PLAYER_SEARCH,
    handler,
  }),
)
export const getPlayersSubscribe = createAction(
  ActionTypes.GET_PLAYERS_SUBSCRIBER,
  action => {
    return (response: GetPlayersResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYERS EXCEPTION
 *
 */

export const getPlayersExceptionSubscribe = createAction(
  ActionTypes.GET_PLAYERS_EXCEPTION_SUBSCRIBER,
  action => {
    return (response: WSErrorException) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET ACCOUNT STATUS
 *
 */
export const getAccountStatusLogs = createCustomAction(
  ActionTypes.GET_ACCOUNT_STATUS,
  type => (
    payload: GetAccountStatusLogsPayload,
    event: typeof WS_ROUTES.GET_ACCOUNT_STATUS_LOGS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
    overwrite: true,
  }),
)

export const getAccountStatusLogsSubscribe = createAction(
  ActionTypes.GET_ACCOUNT_STATUS_SUBSCRIBER,
  action => {
    return (response: GetAccountStatusLogsResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE ACCOUNT STATUS
 *
 */
export const createAccountStatus = createCustomAction(
  ActionTypes.CREATE_ACCOUNT_STATUS,
  type => (
    payload: CreateAccountStatusPayload,
    event: typeof WS_ROUTES.CREATE_ACCOUNT_STATUS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LOGS,
    handler,
  }),
)

export const createAccountStatusSubscribe = createAction(
  ActionTypes.CREATE_ACCOUNT_STATUS_SUBSCRIBER,
  action => {
    return (response: CreateAccountStatusResponse) => {
      return action(response)
    }
  },
)

export const createAccountStatusException = createAction(
  ActionTypes.CREATE_ACCOUNT_STATUS_EXCEPTION,
  action => {
    return (response: CreateAccountStatusResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE ACCOUNT STATUS - ACTIVE, BLOCK, CLOSE
 *
 */
export const updateAccountStatusActive = createCustomAction(
  ActionTypes.UPDATE_ACCOUNT_STATUS_ACTIVE,
  type => (
    payload: UpdateAccountStatusActivePayload,
    event: typeof WS_ROUTES.UPDATE_ACCOUNT_STATUS_ACTIVE,
    handler?: (data: UpdateNewAccountStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updateAccountStatusBlock = createCustomAction(
  ActionTypes.UPDATE_ACCOUNT_STATUS_BLOCK,
  type => (
    payload: UpdateAccountStatusBlockPayload,
    event: typeof WS_ROUTES.UPDATE_ACCOUNT_STATUS_BLOCK,
    handler?: (data: UpdateAccountStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updateAccountStatusClose = createCustomAction(
  ActionTypes.UPDATE_ACCOUNT_STATUS_CLOSE,
  type => (
    payload: UpdateAccountStatusClosePayload,
    event: typeof WS_ROUTES.UPDATE_ACCOUNT_STATUS_CLOSE,
    handler?: (data: UpdateAccountStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE PLAYER CARD legacy
 *
 */
export const updatePlayerCard = createCustomAction(
  ActionTypes.UPDATE_PLAYER_CARD,
  type => (
    payload: UpdatePlayerCardPayload,
    event: typeof WS_ROUTES.UPDATE_PLAYER_CARD,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updatePlayerCardSubscribe = createAction(
  ActionTypes.UPDATE_PLAYER_CARD_SUBSCRIBER,
  action => {
    return (response: UpdatePlayerCardResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE PAYMENT DATA
 *
 */
export const updatePaymentData = createCustomAction(
  ActionTypes.UPDATE_PAYMENT_DATA,
  type => (
    payload: UpdatePaymentDataPayload,
    event: typeof WS_ROUTES.UPDATE_PAYMENT_DATA,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updatePaymentDataSubscribe = createAction(
  ActionTypes.UPDATE_PAYMENT_DATA_SUBSCRIBER,
  action => {
    return (response: UpdatePaymentDataResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] SET ACCOUNT TEST
 *
 */
export const setAccountTest = createCustomAction(
  ActionTypes.SET_ACCOUNT_TEST,
  type => (
    payload: InsertIssueAdminPayload,
    event: typeof WS_ROUTES.INSERT_ISSUE_ADMIN,
    handler?: (response: InsertIssueAdminResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const setAccountTestSubscribe = createAction(
  ActionTypes.SET_ACCOUNT_TEST_SUBSCRIBER,
  action => {
    return (response: InsertIssueAdminResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYER CARD legacy
 *
 */
export const checkIsSamePlayer = createCustomAction(
  ActionTypes.CHECK_IS_SAME_PLAYER,
  type => (
    payload: CheckIsSamePlayerPayload,
    event: typeof WS_ROUTES.CHECK_IS_SAME_PLAYER,
    handler?: (data: CheckIsSamePlayerResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] EMAIL UPDATE
 *
 */
export const emailUpdate = createCustomAction(
  ActionTypes.EMAIL_UPDATE,
  type => (
    payload: EmailUpdatePayload,
    event: typeof WS_ROUTES.EMAIL_UPDATE,
    handler?: (data: EmailUpdateResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DISABLE TWO FACTOR ENABLED PLAYER CARD
 *
 */
export const disableTwoFactor = createCustomAction(
  ActionTypes.DISABLE_TWO_FACTOR,
  type => (
    payload: DisableTwoFactorPayload,
    event: typeof WS_ROUTES.DISABLE_TWO_FACTOR,
    handler?: (data: DisableTwoFactorResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const disableTwoFactorSubscriber = createAction(
  ActionTypes.DISABLE_TWO_FACTOR_SUBSCRIBER,
  action => {
    return (response: DisableTwoFactorResponse) => {
      return action(response)
    }
  },
)

export const setPlayerPagination = createAction(
  ActionTypes.SET_PLAYER_PAGINATION,
  action => {
    return (payload: PaginationResponse) => action(payload)
  },
)

export const crifCheckAddress = createAction(
  ActionTypes.CRIF_CHECK_ADDRESS_SUBSCRIBER,
  action => {
    return (response: CrifCheckAddressResponse) => {
      return action(response)
    }
  },
)

export const getPlayerIssueCountSubscribe = createAction(
  ActionTypes.GET_PLAYER_ISSUE_COUNT_SUBSCRIBER,
  action => (response: GetIssuesCountResponse) => action(response),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UNLINK BARRIERE CLUB CARD
 *
 */
export const unlinkBarriereClubCard = createCustomAction(
  ActionTypes.UNLINK_BARRIERE_CLUB_CARD,
  type => (
    payload: UnlinkBarriereClubCardPayload,
    event: typeof WS_ROUTES.UNLINK_BARRIERE_CLUB_CARD,
    handler?: (data: UnlinkBarriereClubCardResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] LINK BARRIERE CLUB CARD
 *
 */
export const linkBarriereClubCard = createCustomAction(
  ActionTypes.LINK_BARRIERE_CLUB_CARD,
  type => (
    payload: LinkBarriereClubCardPayload,
    event: typeof WS_ROUTES.LINK_BARRIERE_CLUB_CARD,
    handler?: (data: LinkBarriereClubCardResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] SET FULLY IDENTIFIED
 *
 */
export const updateAccountStatusFullyIdentified = createCustomAction(
  ActionTypes.UPDATE_ACCOUNT_STATUS_FULLY_IDENTIFIED,
  type => (
    payload: UpdateAccountStatusFullyIdentifiedPayload,
    event: typeof WS_ROUTES.UPDATE_ACCOUNT_STATUS_FULLY_IDENTIFIED,
    handler?: (data: UpdateAccountStatusResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)
