import ActionTypes from '../constants'
import { IssueState, IssuesActions } from '../model'

const getAllIssuesHandler = (
  state: IssueState,
  action: IssuesActions,
): IssueState => {
  const totalIssues =
    state.issuesCount.length > 0
      ? state.issuesCount
          .map(item => item.totalIssues)
          .reduce((prev, next) => prev + next)
      : state.pagination.total

  switch (action.type) {
    case ActionTypes.GET_ALL_ISSUES:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_ISSUES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        resolved: action.payload.resolved,
        issues:
          action.payload.resolved && action.payload.message
            ? action.payload.message
            : [],
        pagination: {
          ...state.pagination,
          total: totalIssues,
          pages: Math.ceil(totalIssues / (state.pagination.limit || 10)),
        },
      }

    case ActionTypes.GET_ISSUES_COUNT:
      return {
        ...state,
        issuesCountPlayerLast:
          action.payload?.filters?.playerId !== undefined &&
          state.issuesCountPlayerLast !== action.payload.filters.playerId
            ? action.payload.filters.playerId
            : state.issuesCountPlayerLast,
      }

    case ActionTypes.GET_ISSUES_COUNT_SUBSCRIBER:
      let finalCountCurrentIssues = 0 // eslint-disable-line
      action.payload?.message.forEach(issueCount => {
        finalCountCurrentIssues += issueCount.totalIssues
      })
      return {
        ...state,
        issuesCount: action.payload?.message,
        issuesCountLast: action.payload.addAlert
          ? action.payload?.message
          : state.issuesCount.length === 0
          ? action.payload?.message
          : state.issuesCountLast,
        pagination: {
          ...state.pagination,
          total: action.payload.updatePagination ? finalCountCurrentIssues : 1,
          pages: action.payload.updatePagination
            ? Math.ceil(
                finalCountCurrentIssues / (state.pagination.limit || 10),
              )
            : 1,
        },
      }

    case ActionTypes.UPDATE_ISSUES_COUNT_SUBSCRIBER:
      return {
        ...state,
        issuesCount: action.payload?.message,
        issuesCountLast: action.payload.addAlert
          ? action.payload?.message
          : state.issuesCount.length === 0
          ? action.payload?.message
          : state.issuesCountLast,
      }

    default:
      return state
  }
}

export default getAllIssuesHandler
