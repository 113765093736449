import CurrencyCode from 'state/enums/currency-code.enum'
import { PaycardModel } from './models'

const paycards: PaycardModel[] = [
  {
    id: '01',
    username: 'magicPlayer85',
    provider: 'SafeCharge',
    subprovider: 'Visa',
    paycardNumber: '12345678901234567890',
    totalDeposits: 15000,
    totalWithdrawals: 10000,
    firstUsed: '2020-03-25T04:00:00.000Z',
    currency: CurrencyCode.CHF,
  },
  {
    id: '02',
    username: 'magicPlayer854',
    provider: 'SafeCharge',
    subprovider: 'MasterCard',
    paycardNumber: '12345678901234567891',
    totalDeposits: 1000,
    totalWithdrawals: 500,
    firstUsed: '2019-01-31T13:00:00.000Z',
    currency: CurrencyCode.CHF,
  },
  {
    id: '03',
    username: 'magicPlayer854',
    provider: 'SafeCharge',
    subprovider: 'MasterCard',
    paycardNumber: '12345678901234567891',
    totalDeposits: 1000,
    totalWithdrawals: 500,
    firstUsed: '2019-01-31T13:00:00.000Z',
    currency: CurrencyCode.CHF,
  },
  {
    id: '04',
    username: 'magicPlayer854',
    provider: 'SafeCharge',
    subprovider: 'MasterCard',
    paycardNumber: '12345678901234567891',
    totalDeposits: 1000,
    totalWithdrawals: 500,
    firstUsed: '2019-01-31T13:00:00.000Z',
    currency: CurrencyCode.CHF,
  },
  {
    id: '05',
    username: 'magicPlayer854',
    provider: 'SafeCharge',
    subprovider: 'MasterCard',
    paycardNumber: '12345678901234567891',
    totalDeposits: 1000,
    totalWithdrawals: 500,
    firstUsed: '2019-01-31T13:00:00.000Z',
    currency: CurrencyCode.CHF,
  },
]

export default paycards
