import { Dispatch } from 'redux'
import WebSocketFacade from 'services/websockets/websocket.facade'
import { WebSocketsNamespaceModel } from 'state/root.models'

import { GatewayType } from './models'

type WSConstantType = string
type WSRouteType = string

export interface SocketAction {
  [extraProps: string]: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface DispatchSocket<A = SocketAction> {
  <T extends A>(action: T): T
}

/**
 * @function createSocketReaderAction
 *
 * @description
 * Helper function to create an action for socket io
 * so the socket redux middleware can read it.
 *
 * @example
 * ```ts
 *  const updateUserSubscriber = () =>
 *    createSocketReaderAction<UserResponse, typeof ActionTypes.UPDATE_SUBSCRIBER>(
 *      'updateUsers',
 *      ActionTypes.UPDATE_SUBSCRIBER,
 *    )
 * ```
 * @template R - The expected Response type
 * @template A - The action type
 *
 * @param {string} event
 * @param {A} type
 *
 */
export function createSocketReaderAction<R, A extends WSConstantType>(
  event: string,
  meta: string,
  type: A,
) {
  // TODO: improve this to reduce the amount of code
  return (dispatch: DispatchSocket) =>
    dispatch({
      event,
      meta,
      handle: (response: R) =>
        dispatch({
          type,
          payload: response,
        }),
    })
}

/**
 * @function createSocketEmitterAction
 *
 * @description
 * Helper function to create an action for socket io
 * so the socket redux middleware can emit payloads.
 *
 * @example
 * ```ts
 *   export const addManualDeposit = (deposit: ManualTransactionPayload) =>
 *     createSocketEmitterAction<
 *       ManualTransactionPayload,
 *       typeof ActionTypes.ADD_MANUAL_DEPOSIT
 *     >(WS_ROUTES.WALLET.DEPOSIT_MANUAL, ActionTypes.ADD_MANUAL_DEPOSIT, deposit)
 * ```
 * @template A - The action type
 *
 * @param {string} event
 * @param {A} type
 *
 */
export const createSocketEmitterAction = <P, WSConstant extends WSConstantType>(
  event: WSRouteType,
  type: WSConstant,
  payload?: P,
) => (dispatch: Dispatch) =>
  // TODO: improve this to reduce the amount of code
  dispatch({
    type,
    emit: true,
    event,
    payload,
  })

export const isGatewayRegistered = (
  gateways: GatewayType[],
  namespace: WebSocketsNamespaceModel,
) => {
  return gateways?.some(gateway => {
    return gateway.namespace === namespace
  })
}

export const isGatewayConnected = (gateways: GatewayType[]) => {
  return gateways?.some(gateway => {
    return gateway.isConnected
  })
}

export const isHandlerNotInstalled = (
  socketIoClient: WebSocketFacade['socketClient'],
  handler: string,
) => socketIoClient._callbacks[`$${handler}`] === undefined
