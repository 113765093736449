import TeledataStatusType from 'state/enums/teledata-status.enum'
import KycStatusType from 'state/enums/kyc-status.enum'
import {
  PlayerCardDocumentType,
  HistoryType,
  nonPepCheckType,
  TeleDataHistory,
  vetoCheckType,
  UpdateKycStatusType,
  verifiedAddressCheckType,
} from './models'

export const documents: PlayerCardDocumentType[] = [
  {
    isVisible: true,
    _id: '',
    createdAt: '2020-03-25T04:00:00.000Z',
    createdBy: 'Pasino Test',
    documenIssueDate: '2020-03-25T04:00:00.000Z',
    documentExpiryDate: '2020-03-25T04:00:00.000Z',
    documentNumber: '3r434grg4g34',
    documentType: 'ui',
    imageId: '01',
    issuuingAuthority: true,
    playerId: '1w123dbaga728ahahjas02ms',
    status: 'string',
    updatedAt: '2020-03-25T04:00:00.000Z',
  },
  {
    isVisible: true,
    _id: '',
    createdAt: '2020-03-25T04:00:00.000Z',
    createdBy: 'Pasino Test',
    documenIssueDate: '2020-03-25T04:00:00.000Z',
    documentExpiryDate: '2020-03-25T04:00:00.000Z',
    documentNumber: '3r434grg4g34',
    documentType: 'ui',
    imageId: '01',
    issuuingAuthority: true,
    playerId: '1w123dbaga728ahahjas02ms',
    status: 'string',
    updatedAt: '2020-03-25T04:00:00.000Z',
  },
]

export const vetoHistory: HistoryType[] = [
  {
    id: 'abc1234',
    date: '2020-03-25T04:00:00.000Z',
    result: 'The player did not appear in the Veto database',
    admin: 'andres.blanco',
  },
  {
    id: 'abc1235',
    date: '2020-03-26T04:00:00.000Z',
    result: 'The player did not appear in the Veto database',
    admin: 'andres.blanco',
  },
]

export const updateKyc: UpdateKycStatusType = {
  _id: '',
  kycStatus: KycStatusType.REQUESTED,
  playerId: '',
  createdAt: '',
  updatedAt: '',
  createdBy: '',
}

export const nonPepCheck: nonPepCheckType = {
  isPEP: null,
  pepCheckData: {
    decision: '',
    decisionText: '',
    subdecisions: [],
  },
}

export const nonPepHistory: HistoryType[] = [
  /* {
    id: 'abc1236',
    date: '2020-03-25T04:00:00.000Z',
    result: 'The player did not appear in the Non PEP database',
    admin: 'andres.blanco',
  },
  {
    id: 'abc1237',
    date: '2020-03-26T04:00:00.000Z',
    result: 'The player did not appear in the Non PEP database',
    admin: 'andres.blanco',
  }, */
]

export const vetoCheck: vetoCheckType = {
  resolved: false,
}

export const verifiedAddressCheck: verifiedAddressCheckType = {
  isPEP: false,
  pepCheckData: {
    decision: 'green',
    decisionText: 'green',
    subdecisions: [],
  },
}

export const teledataHistory: TeleDataHistory[] = [
  {
    id: 'abc1238',
    admin: 'andres.blanco',
    colorId: TeledataStatusType.GREEN,
    playerId: '',
    timestamp: '2020-03-25T04:00:00.000Z',
    comment: 'Lorem ipsum dolor sir amet.',
  },
  {
    id: 'abc1239',
    timestamp: '2020-03-26T04:00:00.000Z',
    colorId: TeledataStatusType.RED,
    admin: 'andres.blanco',
    playerId: '',
    comment: 'Lorem ipsum dolor sir amet.',
  },
  {
    id: 'abc12392',
    timestamp: '2020-03-26T04:00:00.000Z',
    colorId: TeledataStatusType.YELLOW,
    admin: 'andres.blanco',
    playerId: '',
    comment: 'Lorem ipsum dolor sir amet.',
  },
]
