import ActionTypes from '../constants'
import { RoleActions, RoleState } from '../models'

const filtersHandler = (state: RoleState, action: RoleActions): RoleState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
        },
      }

    default:
      return state
  }
}

export default filtersHandler
