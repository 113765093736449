import { LogState } from '../models'

export const initialState: LogState = {
  isLoadingTimeline: false,
  isLoadingTeledata: true,
  isLoadingTags: true,
  isLoadingAmlStatus: true,
  isLoadingAmlAnswer: false,
  isLoadingCoolOff: true,
  isLoadingLimit: true,
  timeline: [],
  teledata: [],
  tags: [],
  amlStatus: [],
  amlAnswer: [],
  coolOffs: [],
  limits: [],
  notes: [],
}

export default initialState
