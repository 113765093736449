import { CompanyState, CompanyActions } from '../models'
import ActionTypes from '../constants'

const getSettingsHandler = (
  state: CompanyState,
  action: CompanyActions,
): CompanyState => {
  switch (action.type) {
    case ActionTypes.GET_SETTINGS_SUBSCRIBER:
      return {
        ...state,
        settings: action.payload.message[0].data,
        accountStatusInfo:
          action.payload.message[0].data.general.players.accountStatus,
        paymentsInfo: action.payload.message[0].data.paymentInformation,
      }

    case ActionTypes.GET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER:
      return {
        ...state,
        bonusExpiry: action.payload.payload?.data || state.bonusExpiry,
      }

    case ActionTypes.SET_BONUS_EXPIRY_NOTIFICATION_SUBSCRIBER:
      return {
        ...state,
        bonusExpiry: action.payload,
      }

    case ActionTypes.GET_VISITS_CONFIG_SUBSCRIBER:
      return {
        ...state,
        visitsConfig: {
          config: action.payload.config || state.visitsConfig.config,
          frequencyCap: action.payload.frequencyCap || 0,
        },
      }

    case ActionTypes.GET_EVALUATION_SUBSCRIBER:
      return {
        ...state,
        riskEvaluation: action.payload || state.riskEvaluation,
      }

    default:
      return state
  }
}

export default getSettingsHandler
