/**
 *
 * KYC UI actions
 *
 */

import { createAction } from 'typesafe-actions'
import FormStatusType from 'state/enums/form-status.enum'
import AmlStatusType from 'state/enums/aml-status.enum'

import ActionTypes from '../constants'

export const setDocumentFormAction = createAction(
  ActionTypes.SET_DOCUMENT_FORM_MODE,
  action => {
    return (mode: FormStatusType) => action(mode)
  },
)

export const toggleDocumentDialogAction = createAction(
  ActionTypes.TOGGLE_DOCUMENT_DIALOG,
  action => {
    return (open: boolean) => action(open)
  },
)

export const toggleTeledataDialogAction = createAction(
  ActionTypes.TOGGLE_TELEDATA_DIALOG,
  action => {
    return (open: boolean) => action(open)
  },
)

export const toggleAmlDialogAction = createAction(
  ActionTypes.TOGGLE_AML_DIALOG,
  action => {
    return (open: boolean) => action(open)
  },
)

export const togglePEPDialogAction = createAction(
  ActionTypes.TOGGLE_PEP_DIALOG,
  action => {
    return (open: boolean) => action(open)
  },
)

export const toggleAmlEditAction = createAction(
  ActionTypes.TOGGLE_AML_EDIT,
  action => {
    return (state: {
      status?: AmlStatusType
      statusNew?: AmlStatusType
      isEditing?: boolean
    }) => action(state)
  },
)
