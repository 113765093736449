import { createReducer } from 'typesafe-actions'
import {
  GamingActivityState,
  GamingActivityActions,
  GamingActivitySocketActions,
} from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import filtersHandler from './filters.handler'
import getSessionsHandler from './get-session.handler'

/**
 * Creates a PlayerCardGamingActivity Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const playerCardGamingActivityReducer = createReducer<
  GamingActivityState,
  GamingActivityActions | GamingActivitySocketActions
>(initialState)
  .handleAction(
    [
      ActionTypes.GET_GAME_LIST_ADMIN_SUBSCRIBER,
      ActionTypes.GET_GAME_CATEGORY_LIST_SUBSCRIBER,
      ActionTypes.GET_GAME_PROVIDER_LIST_SUBSCRIBER,
      ActionTypes.GET_GAME_SESSIONS,
      ActionTypes.GET_GAME_SESSIONS_SUBSCRIBER,
      ActionTypes.GET_GAME_TRANSACTIONS,
      ActionTypes.GET_GAME_TRANSACTIONS_SUBSCRIBER,
    ],
    getSessionsHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], filtersHandler)

export default playerCardGamingActivityReducer
