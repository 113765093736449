enum LimitType {
  NET_LOSS = 1,
  WAGER = 2,
  DEPOSIT = 3,
  WIN = 4,
  LOGIN_SESSION = 5,
  PLAY_TIME = 6,
}

export default LimitType
