import { createReducer } from 'typesafe-actions'
import { FrequencyCapActions, FrequencyCapState } from '../models'
import { initialState } from './initial-state'

import ActionTypes from '../constants'
import frequencyCapGeneralHandler from './general.handler'
import frequencyCapGetAllHandler from './get-all.handler'

import frequencyCapCreateHandler from './create.handler'
import frequencyCapUpdateHandler from './update.handler'
import frequencyCapDeleteHandler from './delete.handler'

/**
 * Creates a Frequency Cap Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const frequencyCapsReducer = createReducer<
  FrequencyCapState,
  FrequencyCapActions
>(initialState)
  .handleAction(
    [
      ActionTypes.SET_FREQUENCY_CAP_DIALOG,
      ActionTypes.SET_CURRENT_FREQUENCY_CAP,
    ],
    frequencyCapGeneralHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
    ],
    frequencyCapGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    frequencyCapCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    frequencyCapUpdateHandler,
  )
  .handleAction(
    [
      ActionTypes.DELETE_REQUEST,
      ActionTypes.DELETE_SUCCESS,
      ActionTypes.DELETE_FAILURE,
    ],
    frequencyCapDeleteHandler,
  )

export default frequencyCapsReducer
