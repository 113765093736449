const common = {
  messages: {
    added: 'added',
    approved: 'approved',
    assign: 'assign',
    assigned: 'assigned',
    cancelled: 'cancelled',
    companyError: 'Please select a company.',
    completed: 'completed',
    confirmAction: 'Are you sure you want to {{action}} this {{item}}?',
    confirmActionCannotReverse: 'This action cannot be reversed.',
    confirmCheckIsSamePlayer:
      'There is already a player with the same name, last name and date of birth. Do you still want to save the changes?',
    converted: 'converted',
    copiedSuccessfully: 'Successfully copied.',
    created: 'created',
    createdSuccessfully: 'The {{item}} has been created successfully',
    declined: 'declined',
    deleted: 'deleted',
    deletedSuccessfully: 'The {{item}} has been deleted successfully',
    duplicated: 'duplicated',
    emailSizeError: 'The email provided is too long.',
    errorAction:
      'An error occurred while trying to {{action}} the {{item}}. Please try again later.',
    errorOccurred: 'An error has occurred. Please try again.',
    errorOccurredFields:
      'An error has occurred. Please check the fields and try again.',
    executed: 'executed',
    fileSizeError: 'The file provided is too big.',
    formatError: 'Please provide a valid {{field}}.',
    formatErrorPlural: 'Please provide valid {{field}}.',
    formatErrorUserList:
      'Incorrect list format, please separate items by commas.',
    generated: 'generated',
    valueRequired: 'A registered {{value}} is required for this action.',
    incompleteFields: 'Some required information is missing or incomplete.',
    inputCompleteLength:
      '{{field}} must have a min of {{min}} and a max of {{max}} characters.',
    inputMaxLength: '{{field}} must have a max of {{max}} characters.',
    invalidCredentials: 'Please verify your credentials.',
    leavePageConfirmation: 'Are you sure you want to leave this page?',
    linked: 'linked',
    load: 'load',
    maxFileSizeIs: 'Maximum file size: ',
    nameOrDescription: 'Name or description',
    noFreeRounds: 'No games were found that support Free Rounds',
    notRowsRange:
      'Could not get {{name}} in the selected range, please try another range',
    noResultsFound: 'No Results Found. Please try again',
    noSpaces: 'No spaces allowed',
    notAllowed: 'Character not allowed',
    oneOrMoreIncorrectEmails: 'One or more emails are incorrect.',
    processed: 'processed',
    received: 'received',
    recovered: 'recovered',
    rejected: 'rejected',
    reverted: 'reverted',
    reset: 'reset',
    saved: 'saved',
    selectAccountStatusFirst: 'You must select an Account Status first.',
    sent: 'sent',
    sessionEnded: 'Session ended.',
    successfully: 'The {{item}} has been {{what}} successfully.',
    successfullyPlural: 'The {{item}} have been {{what}} successfully.',
    successfullyCount: '{{count}} {{item}} has been {{what}} successfully.',
    successfullyCountPlural:
      '{{count}} {{item}} have been {{what}} successfully.',
    testEmailSuccessfully: 'The email test has been sent successfully.',
    unassign: 'unassign',
    unlinked: 'unlinked',
    updated: 'updated',
    updatedSuccessfully: 'The {{item}} has been updated successfully',
    uploaded: 'uploaded',
    willLoseInformation: 'In doing so, you will lose all unsaved changes.',
    approveAction: 'Are you sure you want to approve this {{item}}?',
    allFieldRequired: 'All fields are required *',
  },
  regexValidators: {
    REGEX_TEXTAREA: 'Invalid characters: ~, <, >, /, \\, {, |, }', // eslint-disable-line
    REGEX_NUMBERS: 'Please provide a valid number in the {{field}}',
  },
  session: {
    online: 'Online',
    offline: 'Offline',
  },
  languages: {
    EN: 'English',
    DE: 'German',
    IT: 'Italian',
    FR: 'French',
    PT: 'Portuguese',
    TR: 'Turkish',
  },
  changeTypeOptions: {
    INSERTED: 'Inserted',
    UPDATED: 'Updated',
    DELETED: 'Deleted',
  },
  dateRangeOptions: {
    TODAY: 'Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    THIS_YEAR: 'This Year',
    YESTERDAY: 'Yesterday',
    LAST_WEEK: 'Last Week',
    LAST_MONTH: 'Last Month',
    LAST_YEAR: 'Last Year',
    CHOOSE_DATE: 'Specific Date',
    CHOOSE_RANGE: 'Last __',
    SECOND_LAST_MONTH_NON_CALENDAR: 'Second Last Month Non Calendar',
  },
  datePeriodOptions: {
    h: 'Hours',
    d: 'Days',
    w: 'Weeks',
    m: 'Months',
    y: 'Years',
  },
  gameSessionDurationOptions: {
    h: 'Hours',
    m: 'Minutes',
  },
  noteEventOptions: {
    NOTE: 'Note',
    SCHEDULEDNOTE: 'Scheduled note',
    UPDATE: 'Update',
    OVERWRITE: 'Overwrite',
    STOP: 'Stop',
    DELETE: 'Delete',
  },
  logActionTypeOptions: {
    INSERT: 'Insert',
    UPDATE: 'Update',
    DELETE: 'Delete',
    LOG: 'Log',
  },
  actionSubtype: 'Action Subtype',
  actionType: 'Action Type / ID',
  add: 'Add',
  admin: 'Admin',
  agent: 'Agent',
  alertCode: 'Alert Code',
  all: 'All',
  author: 'Author',
  backToLogin: 'Back to Login',
  builder: 'Builder',
  bypass: 'Bypass',
  cancel: 'cancel',
  categories: 'Categories',
  category: 'Category',
  change: 'Change',
  changedOn: 'Changed On',
  changeType: 'Change Type',
  clickCreate: 'Click the “Create” button to create a new tag',
  clickToChangeButton: 'Click to change button settings.',
  clickToChangeImage: 'Click to change image settings.',
  companyName: 'Company Name',
  configComponent: '{{item}} Settings',
  copyToClipboard: 'Copy to clipboard',
  country: 'Country',
  create: 'Create',
  date: 'Date',
  day: 'Day',
  delete: 'delete',
  details: 'Details',
  document: 'document',
  duplicate: 'duplicate',
  duration: 'Duration',
  edit: 'Edit',
  editInformation: 'edit information',
  false: 'False',
  female: 'Female',
  fetch: 'fetch',
  fifth: 'Fifth',
  filterBy: 'Filter by {{field}}',
  first: 'First',
  fourth: 'Fourth',
  friday: 'Friday',
  goBack: 'Go Back',
  goButton: 'Go',
  group: 'group',
  image: 'image',
  ipAddress: 'IP Address',
  last: 'Last',
  lastDayOfMonth: 'Last Day of the Month',
  loginDate: 'Login Date',
  logoutDate: 'Logout Date',
  male: 'Male',
  message: 'Message',
  minute: 'Minute',
  minutes: 'Minutes',
  monday: 'Monday',
  more: 'More',
  newNote: 'New note',
  nextStep: 'Next Step',
  noInfoAvailable: 'No information available',
  none: 'None',
  noResultsFound: 'No results found',
  noResultsFoundCaption:
    'What you searched for was unfortunately not found. Please try another combination.',
  noTagsCreated: 'No tags created yet',
  noTagsResults: 'No results found',
  note: 'Note',
  performedBy: 'Performed By',
  phone: 'Phone',
  players: 'Players',
  previousStep: 'Previous Step',
  priority: 'Priority',
  proceed: 'Proceed',
  pressEnterToAdd: 'Press enter to add',
  range: 'Range',
  recover: 'recover',
  remove: 'remove',
  reports: 'Reports',
  reset: 'Reset',
  saturday: 'Saturday',
  save: 'save',
  saveProgress: 'Save Progress',
  saveAndEnable: 'Save And Enable',
  second: 'Second',
  selectOne: 'Select One',
  selectOneOrMore: 'Select one or more',
  stayOnThisPage: 'Stay On This Page',
  specificDate: 'Specific Date',
  stopEditing: 'Stop editing',
  sunday: 'Sunday',
  third: 'Third',
  thursday: 'Thursday',
  timeUnit: 'Time Unit',
  title: 'Title',
  true: 'True',
  tuesday: 'Tuesday',
  understood: 'Understood',
  viewLess: 'View Less',
  viewMore: 'View More',
  wednesday: 'Wednesday',
  selectImageFromDatabase: 'Select an image from the database',
  initCaption: 'Please apply the desired filters to run the report',
  position: 'position',
  lifetime: 'Lifetime',
  today: 'Today',
  last7days: 'Last 7 Days',
  last30days: 'Last 30 Days',
  dateRange: 'Date Range',
  noData: 'No Data',
  important: 'Important',
  userInfo: 'User Info',
  writeAndPressEnterToAddTheNote: 'Write and press enter to add the note',
}

export default common
