import Order from './types'

/**
 *
 * @description Simple Chainable query builder
 *
 * @example
 * ```ts
 * const providerQueries = new QueryBuilderService().limit('100')
 *                  .sort('id','ASC')
 *                  .query('status_in', 'active')
 *                  .query('status_in', 'preview')
 *                  .all
 * ```
 * @class QueryBuilder
 *
 */
class QueryBuilder {
  queries: string

  constructor() {
    this.queries = ''
  }

  /**
   *
   * Queries computation
   * @returns queries
   * @memberof QueryBuilderService
   */
  get all() {
    return this.queries
  }

  /**
   *
   * Query Limiter
   * @memberof QueryBuilderService
   */
  limit = (limit: string) => {
    const limitQuery = `_limit=${limit}&`
    this.queries = this.queries + limitQuery
    return this
  }

  /**
   *
   * Query Sorter
   * @memberof QueryBuilderService
   */
  sort = (prop: string, order: Order) => {
    const sortQuery = `_sort=${prop}:${order}&`
    this.queries = this.queries + sortQuery
    return this
  }

  /**
   *
   * Creates custom queries
   * @memberof QueryBuilderService
   */
  query = (prop: string, value: string, last?: boolean) => {
    const customQuery = last ? `${prop}=${value}` : `${prop}=${value}&`
    this.queries = this.queries + customQuery
    return this
  }
}

export default QueryBuilder
