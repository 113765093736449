import { alpha } from '@material-ui/core/styles/colorManipulator'
import { colors } from '../theme/colors.theme'

const {
  white,
  green,
  grayish,
  redDark,
  blue,
  teal,
  orangeLight,
  black,
  orange,
} = colors

export const typographyTheme = {
  root: {
    '&.text-black': {
      color: black,
    },
    '&.text-green': {
      color: green,
    },
    '&.text-teal': {
      color: teal,
    },
    '&.text-white': {
      color: white,
    },
    '&.text-whiteFade': {
      color: alpha(white, 0.7),
    },
    '&.text-gray': {
      color: grayish,
    },
    '&.text-red': {
      color: redDark,
    },
    '&.text-blue': {
      color: blue,
    },
    '&.text-orangeLight': {
      color: orangeLight,
    },
    '&.text-orange': {
      color: orange,
    },
    '&.fontWeight-900': {
      fontWeight: 900,
    },
    '&.fontWeight-700': {
      fontWeight: 700,
    },
    '&.fontWeight-500': {
      fontWeight: 500,
    },
    '&.fontWeight-400': {
      fontWeight: 400,
    },
    '&.fontWeight-300': {
      fontWeight: 300,
    },
    '&.uppercase': {
      textTransform: 'uppercase' as 'uppercase',
    },
    '&.lowercase': {
      textTransform: 'lowercase' as 'lowercase',
    },
    '&.capitalize': {
      textTransform: 'capitalize' as 'capitalize',
    },
    '&.white-space-pre-wrap': {
      whiteSpace: 'pre-wrap',
    },
    '&.underline': {
      textDecoration: 'underline',
    },
    '&.word-break-word': {
      wordBreak: 'break-word' as 'break-word',
    },
    '&.truncate-second-line': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
    },
    '&.truncate': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.body2Label': {
      background: 'rgba(255,255,255,0.1)',
      padding: '2px 5px',
      borderRadius: '3px',
      marginTop: '-2px',
    },
    '&.cursor-pointer': {
      cursor: 'pointer',
    },
    '&.italic': {
      fontStyle: 'italic',
    },
  },
  h1: {
    fontWeight: 700,
    fontSize: 32,
    textTransform: 'uppercase' as 'uppercase',
  },
}
