import { PlayerCardTagState } from '../models'

const initialState: PlayerCardTagState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  playerTags: [],
  tags: [],
  categories: [],
}

export default initialState
