import ActionTypes from '../constants'
import { LevelDefinitionActions, LevelsState } from '../models'

const levelsGeneralHandler = (
  state: LevelsState,
  action: LevelDefinitionActions,
): LevelsState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }

    default:
      return state
  }
}

export default levelsGeneralHandler
