export const colors = {
  black: '#00112B',
  blue: '#0178FF',
  blue3C: '#3C455D',
  blueDark: '#222A40',
  blueDarkNav: '#1B2239',
  blueGray: '#7EBAFF',
  blueMedium: '#263253',
  blueModal: '#202638',
  blueNav: '#2D3857',
  gray: '#707070',
  grayDark: '#585F75',
  grayish: '#8D8D8D',
  grayLight: '#3B4660',
  green: '#2CCC70',
  lightBlue: '#01CAFF',
  orange: '#E89806',
  orangeDark: '#C67D3B',
  orangeLight: '#F29E1A',
  orangeMedium: '#E74D3D',
  greenMedium: '#23a359',
  pink: '#FF00F5',
  purple: '#9964FA',
  red: '#E93C3C',
  redDark: '#FD014E',
  redLight: '#FD014C',
  teal: '#24CFB5',
  turquoise: '#00FFF5',
  white: '#FFFFFF',
  yellow: '#E8C722',
  // XML CODE THEME
  cyan: '#7fdbca',
  greenLight: '#addb67',
  purpleLight: '#c792ea',
  yellowLight: '#ffcb8b',
}
