const blacklist = {
  barrierId: 'Barrier ID',
  createdOnVeto: 'Created On Veto',
  responsibleProvider: 'Responsible Provider',
  insertedTs: 'Inserted Ts',
  deleteRecordDisclaimer:
    'If you delete this record you will be unable to get it back. Are you sure?',
  vetoInternalBlacklist: 'VETO Internal Blacklist',
  vetoInternalBlacklistAudit: 'VETO Internal Blacklist Audit',
  blacklistRecord: 'Blacklist record',
}

export default blacklist
