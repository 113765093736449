import { GameStoreState } from '../models'

const initialState: GameStoreState = {
  isLoading: false,
  gameList: [],
  providers: [],
  categories: [],
  currentGame: null,
  devices: [],
  gameFilters: {
    limit: 25,
    page: 1,
    images: true,
    bonusData: true,
  },
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  gameSelection: [],
  gameVersionData: [],
}

export default initialState
