import { call, put, select } from 'redux-saga/effects'
import Container from 'typedi'
import FileStorageService from 'services/file-storage/index.service'

import i18n from 'i18n'
import store from 'state'
import WS_ROUTES from 'state/websocket.routes'

import { setSnackbarAction } from 'state/ui/global/actions'
import defaultSnackbarError from 'constants/default-error-snackbar.constant'
import { toggleDocumentDialogAction } from 'state/ui/player-card-kyc/actions/index.actions'

import IssueTypes from 'state/enums/issue-type.enum'
import { getDocumentImagesAction } from '../actions/rest.actions'
import {
  RequestType,
  UploadedDocResponse,
  DocumentImageType,
  UploadDocumentPayload,
  CreateDocumentResponse,
  DocumentImageResponse,
  DocumentDataInsertResponse,
} from '../models'

import { PlayerCardKYCSelectors } from '..'
import {
  createDocument,
  createDocumentSubscribe,
  createDocumentException,
  documentDataInsert,
  documentDataInsertSubscribe,
} from '../actions/sockets-document.actions'
import insertDocumentIssueWorkflow from './insert-document-issue.workflow'

/**
 * @instance FileStorageService
 */
const fileStorageService: FileStorageService = Container.get(FileStorageService)

/**
 *
 * This saga gets all player documents
 *
 * @requires FileStorageService
 * @param {ReturnType<typeof getDocumentImagesAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getDocumentImagesWorker(
  action: ReturnType<typeof getDocumentImagesAction.request>,
) {
  // !VALIDATION: client
  const resolved = process.env.REACT_APP_CLIENT === 'PS'

  try {
    fileStorageService.requestParams = {
      method: 'POST',
      endpoint: '/files',
      body: {
        folderName: action.payload.folderName,
      },
    }

    const response: DocumentImageResponse = yield call(
      fileStorageService.sendData,
      'burek',
    )

    if (response.length > 0) {
      const [docs] = response

      yield put(
        getDocumentImagesAction.success({
          images: docs,
          requestType: action.payload.requestType,
        }),
      )
      const canCreateDocument =
        action.payload.requestType === RequestType.CREATE
      //  it's called when creating a document only
      if (canCreateDocument) {
        const {
          createdBy,
          documenIssueDate,
          documentExpiryDate,
          documentNumber,
          documentType,
          issuuingAuthority,
          playerId,
          status,
        } = action.payload.createDocPayload as UploadDocumentPayload

        const [doc] = action.payload?.image as UploadedDocResponse[]

        const uploadedImage: DocumentImageType = yield select(
          PlayerCardKYCSelectors.recentlyUploaded,
          doc.fieldname,
          docs,
        )

        if (
          action.payload.accountStatus &&
          action.payload.documentType &&
          action.payload.file &&
          action.payload.playerId
        ) {
          // responsible for inserting an issue based on the document type
          insertDocumentIssueWorkflow({
            accountStatus: action.payload.accountStatus,
            documentType: action.payload.documentType,
            fileName: action.payload.file.fieldname,
            playerId: action.payload.playerId,
            riskManagerId:
              action.payload.user?.currentOperator?.operatorId || 0,
            data: {
              documentId: uploadedImage.generation,
              utilityId: uploadedImage.generation,
            },
          })
        }

        yield put(
          createDocument(
            {
              createdBy,
              documenIssueDate,
              documentExpiryDate,
              documentNumber,
              documentType,
              imageId: uploadedImage?.generation,
              issuuingAuthority,
              playerId,
              status,
            },
            WS_ROUTES.KYC.CREATE_DOCUMENT,
            (createDocumentResponse: CreateDocumentResponse) => {
              if (createDocumentResponse.typeLog) {
                if (typeof createDocumentResponse.message === 'string') {
                  store.dispatch(
                    setSnackbarAction({
                      message: createDocumentResponse.message,
                      open: true,
                      variant: 'error',
                    }),
                  )
                } else {
                  defaultSnackbarError()
                }
                store.dispatch(createDocumentException(createDocumentResponse))
              } else {
                store.dispatch(
                  setSnackbarAction({
                    message: i18n.t('messages.successfully', {
                      item: i18n.t('document'),
                      what: i18n.t(`messages.created`),
                    }),
                    open: true,
                    variant: 'success',
                  }),
                )
                // store.dispatch(
                //   getDocumentImagesAction.request({
                //     folderName: playerId,
                //     requestType: RequestType.GET,
                //   }),
                // )

                store.dispatch(createDocumentSubscribe(createDocumentResponse))
                store.dispatch(toggleDocumentDialogAction(false))
              }
            },
          ),
        )

        yield put(
          documentDataInsert(
            {
              playerId,
              issueType: IssueTypes.UPLOAD_DOCUMENT,
              riskLevel: 0,
              operatorId: action.payload.user?.currentOperator?.operatorId || 0,
              documentData: {
                type: documentType.toLowerCase(),
                documentExpiry: documentExpiryDate,
                issueDate: documenIssueDate,
                documentId: uploadedImage?.generation,
                documentNumber,
                issuingAuthority: issuuingAuthority.toString(),
              },
              resolved,
            },
            WS_ROUTES.KYC.DOCUMENT_DATA_INSERT,
            (createDocumentResponse: DocumentDataInsertResponse) => {
              store.dispatch(
                documentDataInsertSubscribe(createDocumentResponse),
              )
            },
          ),
        )
      }
    } else {
      defaultSnackbarError()
    }
  } catch (error) {
    yield put(getDocumentImagesAction.failure(error))
  }
}

export default getDocumentImagesWorker
