/**
 *
 * Template REST actions
 *
 */
import { action, createAction, createAsyncAction } from 'typesafe-actions'
import { ApiResponseType } from 'services/models'
import ActionTypes from '../constants'

import {
  EmailTemplate,
  CreateTemplateEmailPayload,
  UpdateTemplateEmailPayload,
  DuplicateTemplateEmailPayload,
  GetOneLanguagesResponse,
  GetOneTemplateEmailPayload,
  UpdateStatusPayload,
  UpdateTemplateResponse,
  GetOneResponse,
  ArchiveTemplateEmailPayload,
  TemplatesResponse,
  SendEmailType,
  SendEmailTestPayload,
  TemplateEmailFilterProps,
} from '../models'

export const createTemplateEmailAction = createAsyncAction(
  ActionTypes.CREATE_EMAIL_REQUEST,
  ActionTypes.CREATE_EMAIL_SUCCESS,
  ActionTypes.CREATE_EMAIL_FAILURE,
)<CreateTemplateEmailPayload, EmailTemplate, Error>()

export const updateTemplateEmailAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateTemplateEmailPayload, EmailTemplate, Error>()

export const archiveTemplateEmailAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<ArchiveTemplateEmailPayload, UpdateTemplateResponse, Error>()

export const updateTemplateEmailStatusAction = createAsyncAction(
  ActionTypes.UPDATE_STATUS_REQUEST,
  ActionTypes.UPDATE_STATUS_SUCCESS,
  ActionTypes.UPDATE_STATUS_FAILURE,
)<UpdateStatusPayload, EmailTemplate, Error>()

export const duplicateTemplateEmailAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<DuplicateTemplateEmailPayload, EmailTemplate, Error>()

export const getOneTemplateEmailAction = createAsyncAction(
  ActionTypes.GET_ONE_EMAIL_REQUEST,
  ActionTypes.GET_ONE_EMAIL_SUCCESS,
  ActionTypes.GET_ONE_EMAIL_FAILURE,
)<GetOneTemplateEmailPayload, GetOneResponse, Error>()

export const getLanguagesTemplateAction = createAsyncAction(
  ActionTypes.GET_ONE_LANGUAGES_REQUEST,
  ActionTypes.GET_ONE_LANGUAGES_SUCCESS,
  ActionTypes.GET_ONE_LANGUAGES_FAILURE,
)<GetOneTemplateEmailPayload, GetOneLanguagesResponse, Error>()

export const getAllEmailTemplatesAction = createAsyncAction(
  ActionTypes.GET_ALL_EMAIL_REQUEST,
  ActionTypes.GET_ALL_EMAIL_SUCCESS,
  ActionTypes.GET_ALL_EMAIL_FAILURE,
  ActionTypes.RESET_EMAIL_TEMPLATES,
)<TemplateEmailFilterProps, TemplatesResponse, Error>()

export const sendEmailTestAction = createAsyncAction(
  ActionTypes.SEND_EMAIL_TEST_REQUEST,
  ActionTypes.SEND_EMAIL_TEST_SUCCESS,
  ActionTypes.SEND_EMAIL_TEST_FAILURE,
)<SendEmailTestPayload, ApiResponseType, Error>()

export const sendEmailAction = createAsyncAction(
  ActionTypes.SEND_EMAIL_REQUEST,
  ActionTypes.SEND_EMAIL_SUCCESS,
  ActionTypes.SEND_EMAIL_FAILURE,
)<SendEmailType, any, Error>()

export const sendEmailForgotPasswordAction = createAsyncAction(
  ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_REQUEST,
  ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_SUCCESS,
  ActionTypes.SEND_EMAIL_FORGOT_PASSWORD_FAILURE,
)<SendEmailType, any, Error>()

export const resetTemplatesAction = () =>
  action(ActionTypes.RESET_EMAIL_TEMPLATES)

export const setTemplateEmailFiltersAction = createAction(
  ActionTypes.SET_FILTERS,
  actions => {
    return (filters: TemplateEmailFilterProps) => actions(filters)
  },
)

export const resetTemplatesArchivedAction = () =>
  action(ActionTypes.RESET_TEMPLATES_ARCHIVED)
