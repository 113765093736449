import { GameStoreActions, GameStoreState } from '../models'
import ActionTypes from '../constants'

const getGameHandler = (
  state: GameStoreState,
  action: GameStoreActions,
): GameStoreState => {
  switch (action.type) {
    case ActionTypes.GET_GAME:
      return {
        ...state,
      }

    case ActionTypes.GET_GAME_SUBSCRIBER:
      return {
        ...state,
        currentGame: action.payload?.game || null,
      }

    default:
      return state
  }
}

export default getGameHandler
