/**
 *
 * Analytics  constants
 *
 */

enum ActionTypes {
  GET_ALL_REQUEST = '@analytics/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@analytics/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@analytics/GET_ALL_FAILURE',

  GET_ONE_REQUEST = '@analytics/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@analytics/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@analytics/GET_ONE_FAILURE',

  GET_ONE_GROUP_REQUEST = '@analytics/GET_ONE_GROUP_REQUEST',
  GET_ONE_GROUP_SUCCESS = '@analytics/GET_ONE_GROUP_SUCCESS',
  GET_ONE_GROUP_FAILURE = '@analytics/GET_ONE_GROUP_FAILURE',

  GET_CHANNEL_ACTIVITY_REQUEST = '@analytics/GET_CHANNEL_ACTIVITY_REQUEST',
  GET_CHANNEL_ACTIVITY_SUCCESS = '@analytics/GET_CHANNEL_ACTIVITY_SUCCESS',
  GET_CHANNEL_ACTIVITY_FAILURE = '@analytics/GET_CHANNEL_ACTIVITY_FAILURE',

  GET_ALL_CHANNEL_ACTIVITY_REQUEST = '@analytics/GET_ALL_CHANNEL_ACTIVITY_REQUEST',
  GET_ALL_CHANNEL_ACTIVITY_SUCCESS = '@analytics/GET_ALL_CHANNEL_ACTIVITY_SUCCESS',
  GET_ALL_CHANNEL_ACTIVITY_FAILURE = '@analytics/GET_ALL_CHANNEL_ACTIVITY_FAILURE',

  GET_CHANNEL_EVENTS_REQUEST = '@analytics/GET_CHANNEL_EVENTS_REQUEST',
  GET_CHANNEL_EVENTS_SUCCESS = '@analytics/GET_CHANNEL_EVENTS_SUCCESS',
  GET_CHANNEL_EVENTS_FAILURE = '@analytics/GET_CHANNEL_EVENTS_FAILURE',

  GET_EVENTS_DETAIL_REQUEST = '@analytics/GET_EVENTS_DETAIL_REQUEST',
  GET_EVENTS_DETAIL_SUCCESS = '@analytics/GET_EVENTS_DETAIL_SUCCESS',
  GET_EVENTS_DETAIL_FAILURE = '@analytics/GET_EVENTS_DETAIL_FAILURE',

  GET_CAMPAIGN_DATE_ANALYTIC_REQUEST = '@analytics/GET_CAMPAIGN_DATE_ANALYTIC_REQUEST',
  GET_CAMPAIGN_DATE_ANALYTIC_SUCCESS = '@analytics/GET_CAMPAIGN_DATE_ANALYTIC_SUCCESS',
  GET_CAMPAIGN_DATE_ANALYTIC_FAILURE = '@analytics/GET_CAMPAIGN_DATE_ANALYTIC_FAILURE',

  GET_CAMPAIGN_DATES_REQUEST = '@analytics/GET_CAMPAIGN_DATES_REQUEST',
  GET_CAMPAIGN_DATES_SUCCESS = '@analytics/GET_CAMPAIGN_DATES_SUCCESS',
  GET_CAMPAIGN_DATES_FAILURE = '@analytics/GET_CAMPAIGN_DATES_FAILURE',

  GET_CAMPAIGNS_BY_PLAYER_REQUEST = '@analytics/GET_CAMPAIGNS_BY_PLAYER_REQUEST',
  GET_CAMPAIGNS_BY_PLAYER_SUCCESS = '@analytics/GET_CAMPAIGNS_BY_PLAYER_SUCCESS',
  GET_CAMPAIGNS_BY_PLAYER_FAILURE = '@analytics/GET_CAMPAIGNS_BY_PLAYER_FAILURE',

  GET_TEMPLATE_PREVIEW_REQUEST = '@analytics/GET_TEMPLATE_PREVIEW_REQUEST',
  GET_TEMPLATE_PREVIEW_SUCCESS = '@analytics/GET_TEMPLATE_PREVIEW_SUCCESS',
  GET_TEMPLATE_PREVIEW_FAILURE = '@analytics/GET_TEMPLATE_PREVIEW_FAILURE',

  RESET_ANALYTICS = '@analytics/RESET_ANALYTICS',
  RESET_ANALYTIC = '@analytics/RESET_ANALYTIC',

  SET_DOWNLOAD_ANALYTIC_EVENTS = '@analytics/SET_DOWNLOAD_ANALYTIC_EVENTS',

  SET_FILTERS = '@analytics/SET_FILTERS',
  SET_FILTERS_EVENTS = '@analytics/SET_FILTERS_EVENTS',
  SET_FILTERS_CAMPAIGN_BY_PLAYER = '@analytics/SET_FILTERS_CAMPAIGN_BY_PLAYER',

  SET_IS_TABLE = '@analytics/SET_IS_TABLE',
  SET_TARGETS_GROUP = '@analytics/SET_TARGETS_GROUP',
}

export default ActionTypes
