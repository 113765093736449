import i18n from 'i18n'
import TransactionType from 'state/enums/transaction-type.enum'
import TransactionStatus from 'state/enums/transaction-status.enum'

import TransactionMethod from 'state/enums/transaction-method.enum'
import TransactionCompanyCard from 'state/enums/transaction-company-card.enum'

export const transactionTypeList = {
  [TransactionType.DEPOSIT]: {
    color: 'green',
    name: i18n.t('transactionTypes.DEPOSIT'),
    abbreviation: i18n.t('typeAbbreviation.DEPOSIT'),
  },
  [TransactionType.DEPOSIT_MANUAL]: {
    color: 'green',
    name: i18n.t('transactionTypes.DEPOSIT_MANUAL'),
    abbreviation: i18n.t('typeAbbreviation.DEPOSIT_MANUAL'),
  },
  [TransactionType.DEPOSIT_CANCEL]: {
    color: 'green',
    name: i18n.t('transactionTypes.DEPOSIT_CANCEL'),
    abbreviation: i18n.t('typeAbbreviation.DEPOSIT_CANCEL'),
  },
  [TransactionType.WITHDRAW]: {
    color: 'red',
    name: i18n.t('transactionTypes.WITHDRAW'),
    abbreviation: i18n.t('typeAbbreviation.WITHDRAW'),
  },
  [TransactionType.WITHDRAW_MANUAL]: {
    color: 'red',
    name: i18n.t('transactionTypes.WITHDRAW_MANUAL'),
    abbreviation: i18n.t('typeAbbreviation.WITHDRAW_MANUAL'),
  },
  [TransactionType.WITHDRAW_CANCEL]: {
    color: 'red',
    name: i18n.t('transactionTypes.WITHDRAW_CANCEL'),
    abbreviation: i18n.t('typeAbbreviation.WITHDRAW_CANCEL'),
  },
  [TransactionType.BONUS_ADD]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.BONUS_ADD'),
    abbreviation: i18n.t('typeAbbreviation.BONUS_ADD'),
  },
  [TransactionType.BONUS_REDEEM]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.BONUS_REDEEM'),
    abbreviation: i18n.t('typeAbbreviation.BONUS_REDEEM'),
  },
  [TransactionType.BONUS_CANCEL]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.BONUS_CANCEL'),
    abbreviation: i18n.t('typeAbbreviation.BONUS_CANCEL'),
  },
  [TransactionType.WAGER]: {
    color: 'blue',
    name: i18n.t('transactionTypes.WAGER'),
    abbreviation: i18n.t('typeAbbreviation.WAGER'),
  },
  [TransactionType.WAGER_CANCEL]: {
    color: 'blue',
    name: i18n.t('transactionTypes.WAGER_CANCEL'),
    abbreviation: i18n.t('typeAbbreviation.WAGER_CANCEL'),
  },
  [TransactionType.WIN]: {
    color: 'purple',
    name: i18n.t('transactionTypes.WIN'),
    abbreviation: i18n.t('typeAbbreviation.WIN'),
  },
  [TransactionType.WIN_CANCEL]: {
    color: 'purple',
    name: i18n.t('transactionTypes.WIN_CANCEL'),
    abbreviation: i18n.t('typeAbbreviation.WIN_CANCEL'),
  },
  [TransactionType.REAL_TO_BONUS]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.REAL_TO_BONUS'),
    abbreviation: i18n.t('typeAbbreviation.REAL_TO_BONUS'),
  },
  [TransactionType.BONUS_TO_REAL]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.BONUS_TO_REAL'),
    abbreviation: i18n.t('typeAbbreviation.BONUS_TO_REAL'),
  },
  [TransactionType.FREE_GAME_BONUS_ADD]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.FREE_GAME_BONUS_ADD'),
    abbreviation: i18n.t('typeAbbreviation.FREE_GAME_BONUS_ADD'),
  },
  [TransactionType.FREE_GAME_WAGER]: {
    color: 'blue',
    name: i18n.t('transactionTypes.FREE_GAME_WAGER'),
    abbreviation: i18n.t('typeAbbreviation.FREE_GAME_WAGER'),
  },
  [TransactionType.FREE_GAME_WIN]: {
    color: 'purple',
    name: i18n.t('transactionTypes.FREE_GAME_WIN'),
    abbreviation: i18n.t('typeAbbreviation.FREE_GAME_WIN'),
  },
  [TransactionType.FREE_GAME_BONUS]: {
    color: 'yellow',
    name: i18n.t('transactionTypes.FREE_GAME_BONUS'),
    abbreviation: i18n.t('typeAbbreviation.FREE_GAME_BONUS'),
  },
  [TransactionType.FREE_GAME_REAL]: {
    color: 'purple',
    name: i18n.t('transactionTypes.FREE_GAME_REAL'),
    abbreviation: i18n.t('typeAbbreviation.FREE_GAME_REAL'),
  },
}

export const transactionStatusList = {
  [TransactionStatus.APPROVED]: {
    name: i18n.t('approved'),
    color: 'green',
  },
  [TransactionStatus.REJECTED]: {
    name: i18n.t('rejected'),
    color: 'red',
  },
  [TransactionStatus.PENDING]: {
    name: i18n.t('pending'),
    color: 'orange',
  },
  [TransactionStatus.CANCELED]: {
    name: i18n.t('cancelled'),
    color: 'gray',
  },
  [TransactionStatus.EXECUTED]: {
    name: i18n.t('executed'),
    color: 'purple',
  },
  [TransactionStatus.COMPLETED]: {
    name: i18n.t('completed'),
    color: 'green',
  },
  [TransactionStatus.DECLINED]: {
    name: i18n.t('declined'),
    color: 'red',
  },
  [TransactionStatus.LATE_BONUS_WIN]: {
    name: i18n.t('lateBonusWin'),
    color: 'yellow',
  },
}

export const paymentMethodList = {
  [TransactionMethod.BANK_TRANSFER]: {
    name: i18n.t('paymentMethodOptions.BANK_TRANSFER'),
  },
  [TransactionMethod.TRUSTLY]: {
    name: i18n.t('paymentMethodOptions.TRUSTLY'),
  },
  [TransactionMethod.PAYSAFECARD]: {
    name: i18n.t('paymentMethodOptions.PAYSAFECARD'),
  },
  [TransactionMethod.APLAUZ]: {
    name: i18n.t('paymentMethodOptions.APLAUZ'),
  },
  [TransactionMethod.CC]: {
    name: i18n.t('paymentMethodOptions.CC_CARD'),
  },
  [TransactionMethod.TWINT]: {
    name: i18n.t('paymentMethodOptions.TWINT'),
  },
  [TransactionMethod.POSTFINANCE]: {
    name: i18n.t('paymentMethodOptions.POSTFINANCE'),
  },
  [TransactionMethod.POSTCARD]: {
    name: i18n.t('paymentMethodOptions.POSTCARD'),
  },
  [TransactionMethod.APMGW_MONEYBOOKERS]: {
    name: i18n.t('paymentMethodOptions.APMGW_MONEYBOOKERS'),
  },
  [TransactionMethod.APMGW_NETELLER]: {
    name: i18n.t('paymentMethodOptions.APMGW_NETELLER'),
  },
  [TransactionMethod.APMGW_PAYSAFECARD]: {
    name: i18n.t('paymentMethodOptions.PAYSAFECARD'),
  },
  [TransactionMethod.APMGW_POSTFINANCE_CARD]: {
    name: i18n.t('paymentMethodOptions.POSTFINANCE'),
  },
  [TransactionMethod.APMGW_POSTFINANCE_EFINANCE]: {
    name: i18n.t('paymentMethodOptions.APMGW_POSTFINANCE_EFINANCE'),
  },
  [TransactionMethod.APMGW_TWINT]: {
    name: i18n.t('paymentMethodOptions.TWINT'),
  },
  [TransactionMethod.CC_CARD]: {
    name: i18n.t('paymentMethodOptions.CC_CARD'),
  },
}

export const companyCardList = {
  [TransactionCompanyCard.VISA]: {
    name: i18n.t('cardCompanyOptions.VISA'),
  },
  [TransactionCompanyCard.MASTERCARD]: {
    name: i18n.t('cardCompanyOptions.MASTERCARD'),
  },
  [TransactionCompanyCard.VISA_2]: {
    name: i18n.t('cardCompanyOptions.VISA'),
  },
  [TransactionCompanyCard.MASTERCARD_2]: {
    name: i18n.t('cardCompanyOptions.MASTERCARD'),
  },
  [TransactionCompanyCard.AMEX]: {
    name: i18n.t('cardCompanyOptions.AMEX'),
  },
  [TransactionCompanyCard.UATP]: {
    name: i18n.t('cardCompanyOptions.UATP'),
  },
  [TransactionCompanyCard.DINERS]: {
    name: i18n.t('cardCompanyOptions.DINERS'),
  },
  [TransactionCompanyCard.MAESTRO]: {
    name: i18n.t('cardCompanyOptions.MAESTRO'),
  },
  [TransactionCompanyCard.SWITCH]: {
    name: i18n.t('cardCompanyOptions.SWITCH'),
  },
  [TransactionCompanyCard.DISCOVER]: {
    name: i18n.t('cardCompanyOptions.DISCOVER'),
  },
  [TransactionCompanyCard.ISRAELICARD]: {
    name: i18n.t('cardCompanyOptions.ISRAELICARD'),
  },
  [TransactionCompanyCard.MIR]: {
    name: i18n.t('cardCompanyOptions.MIR'),
  },
}
