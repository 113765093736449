enum WSStatusClosedCode {
  NormalClosure = 1000,
  GoingAway = 1001,
  UnsupportedData = 1003,
  NoStatusReceived = 1005,
  AbnormalClosure = 1006,
  AnErrorHasOcurred = 3000,
  NetworkIssues = 3001,
  RequestTimeout = 3002,
}

export default WSStatusClosedCode
