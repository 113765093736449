import { alpha } from '@material-ui/core/styles/colorManipulator'
import { Select, Theme } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { colors } from './colors.theme'

const { white, orangeDark, lightBlue, orange, teal } = colors

export const selectTheme = {
  root: {
    '.MuiFormControl-root:not(.label-float) &': {
      padding: '10px 32px 10px 10px!important',
      height: `36px!important`,
      border: '2px solid',
      'box-sizing': 'border-box',
    },
    '&.Mui-disabled': {
      '& + input + .MuiSelect-icon': {
        display: 'none',
      },
    },

    '.orange &, .teal &': {
      border: 'none!important',
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: 1.3,
    },
    '.orange &': {
      backgroundColor: orange,
      '&:focus': {
        backgroundColor: orange,
      },
    },
    '.teal &': {
      backgroundColor: teal,
      '&:focus': {
        backgroundColor: teal,
      },
    },
  },

  icon: {
    top: 'inherit',
    right: 6,
    color: alpha(white, 0.5),
  },
  select: {
    borderRadius: 4,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}

export const SelectSpecial = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '32px!important',
    '& > *': {
      maxWidth: '100%',
    },
  },
}))(Select)

export const ConditionalSelect = withStyles((theme: Theme) => ({
  root: {
    fontWeight: 'bold' as 'bold',
    borderStyle: 'solid',
    borderWidth: '1px',
    textTransform: 'uppercase' as 'uppercase',
    fontSize: '12px',
    lineHeight: '14px !important',
    '.and &': {
      backgroundColor: alpha(orangeDark, 0.3),
      borderColor: orangeDark,
      color: orangeDark,
    },
    '.or &': {
      backgroundColor: alpha(lightBlue, 0.3),
      borderColor: lightBlue,
      color: lightBlue,
    },
  },
  icon: {
    '.and &': {
      color: orangeDark,
    },
    '.or &': {
      color: lightBlue,
    },
  },
}))(Select)
