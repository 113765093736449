const sidebar = {
  sidebar: {
    addShortcut: 'Add Shortcut',
    addURLShortcut: 'Add URL Shortcut',
    addMenuShortcut: 'Or Add Menu Shortcut',
    addShortcutToMenu: 'Add shortcut to the menu',
    editProfile: 'Edit Profile',
    editShortcut: 'Edit Shortcut',
    emptyShortcutsMessage: 'You can add the services that you use frequently',
    help: 'Help',
    helpSupport: 'Help & Support',
    logout: 'Logout',
    shortcut: 'Shortcut',
    shortcutName: 'Shortcut Name',
    shortcuts: 'Shortcuts',
    yourTime: 'Your time',
  },
}

export default sidebar
