import ActionTypes from '../constants'
import { LogActions, LogState } from '../models'

const amlLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_AML_STATUS_LOGS:
      return {
        ...state,
        isLoadingAmlStatus: true,
      }

    case ActionTypes.GET_AML_STATUS_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingAmlStatus: false,
        amlStatus: action.payload?.message || [],
      }

    case ActionTypes.GET_AML_ANSWER_LOGS:
      return {
        ...state,
        isLoadingAmlAnswer: true,
      }

    case ActionTypes.GET_AML_ANSWER_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingAmlAnswer: false,
        // TODO: uncomment when endpoint is working properly
        // amlAnswer: action.payload?.message || [],
      }

    default:
      return state
  }
}

export default amlLogHandler
