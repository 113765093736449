import ActionTypes from '../constants'
import { AnalyticsState, AnalyticActions } from '../models'
import initialState from './initial-state'

const analyticsGetAllHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        analytics: action?.payload?.docs || [],
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_ANALYTICS:
      return {
        ...state,
        analytics: [],
      }

    case ActionTypes.RESET_ANALYTIC:
      return {
        ...state,
        AnalyticsGeneralStats: initialState.AnalyticsGeneralStats,
      }

    default:
      return state
  }
}

export default analyticsGetAllHandler
