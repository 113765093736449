import { Theme, Chip } from '@material-ui/core'
import {
  alpha,
  darken,
  lighten,
} from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/styles'

import { colors } from './colors.theme'

const {
  lightBlue,
  orangeLight,
  orange,
  purple,
  redDark,
  redLight,
  teal,
  white,
  yellow,
  pink,
  turquoise,
  grayish,
  green,
  blue,
  cyan,
  orangeDark,
  greenMedium,
} = colors

export const chipTheme = {
  root: {
    backgroundColor: alpha(white, 0.25),
    color: white,
    maxWidth: '100%',
    fontWeight: 500,
    paddingTop: 1,
    '&.smaller': {
      height: 25,
      cursor: 'pointer',
    },
    '&.large': {
      fontSize: 16,
      padding: '12px 16px',
      height: 40,
    },
    '&.reverse': {
      flexDirection: 'row-reverse',
      '& .MuiChip-icon': {
        marginRight: 5,
        marginLeft: -6,
      },
    },
    '&.Mui-disabled': {
      '& .MuiChip-deleteIcon': {
        display: 'none',
      },
    },
    '&.full': {
      width: '100%',
    },
    '&.xs-padding': {
      padding: '5px 8px 3px',
      fontSize: 12,
    },
    '&.margin': {
      margin: 4,
    },
    '&.bold': {
      fontWeight: 700,
    },
    '&.long': {
      whiteSpace: 'normal',
      height: 'auto',
      padding: '3px 0 1px',
    },
    '&.extra-small': {
      fontSize: 12,
      height: 19,
    },
    '&.green': {
      color: green,
      backgroundColor: alpha(green, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: green,
      },
      '&.opaque': {
        backgroundColor: darken(green, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: green,
      },
      '&.filled-transparent': {
        color: white,
        backgroundColor: alpha(green, 0.5),
      },
      '&$deletable:focus': {
        backgroundColor: alpha(green, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(green, 0.4),
      },
    },
    '&.green-medium': {
      color: greenMedium,
      backgroundColor: alpha(greenMedium, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: greenMedium,
      },
      '&.opaque': {
        backgroundColor: darken(greenMedium, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: greenMedium,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(greenMedium, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(greenMedium, 0.4),
      },
    },
    '&.cyan': {
      color: cyan,
      backgroundColor: alpha(cyan, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: cyan,
      },
      '&.opaque': {
        backgroundColor: darken(cyan, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: cyan,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(cyan, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(cyan, 0.4),
      },
    },
    '&.teal': {
      color: teal,
      backgroundColor: alpha(teal, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: teal,
      },
      '&.opaque': {
        backgroundColor: darken(teal, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: teal,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(teal, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(teal, 0.4),
      },
    },
    '&.blue': {
      color: blue,
      backgroundColor: alpha(blue, 0.2),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: blue,
      },
      '&.opaque': {
        backgroundColor: darken(blue, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: blue,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(blue, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(blue, 0.4),
      },
      '&:hover,&:focus': {
        backgroundColor: alpha(blue, 0.3),
      },
      '&.no-style-hover': {
        cursor: 'pointer',
        '&:hover,&:focus': {
          backgroundColor: alpha(blue, 1),
        },
      },
    },
    '&.lightBlue': {
      color: lighten(blue, 0.3),
      backgroundColor: alpha(blue, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: blue,
      },
      '&.opaque': {
        backgroundColor: darken(blue, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: blue,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(blue, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(blue, 0.4),
      },
    },
    '&.darkerBlue': {
      color: lightBlue,
      backgroundColor: darken(lightBlue, 0.6),
    },
    '&.yellow': {
      color: yellow,
      backgroundColor: alpha(yellow, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: yellow,
      },
      '&.opaque': {
        backgroundColor: darken(yellow, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: yellow,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(yellow, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(yellow, 0.4),
      },
    },
    '&.purple': {
      color: purple,
      backgroundColor: alpha(purple, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: purple,
      },
      '&.opaque': {
        backgroundColor: darken(purple, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: purple,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(purple, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(purple, 0.4),
      },
    },
    '&.pink': {
      color: pink,
      backgroundColor: alpha(pink, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: pink,
      },
      '&.opaque': {
        backgroundColor: darken(pink, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: pink,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(pink, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(pink, 0.4),
      },
    },
    '&.orange': {
      color: orange,
      backgroundColor: alpha(orange, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: orange,
      },
      '&.opaque': {
        backgroundColor: darken(orange, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: orange,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(orange, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(orange, 0.4),
      },
      '&.no-style-hover': {
        cursor: 'pointer',
        '&:hover,&:focus': {
          backgroundColor: alpha(orange, 1),
        },
      },
    },
    '&.orange-dark': {
      color: orangeDark,
      backgroundColor: alpha(orangeDark, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: orangeDark,
      },
      '&.opaque': {
        backgroundColor: darken(orangeDark, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: orangeDark,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(orangeDark, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(orangeDark, 0.4),
      },
    },
    '&.orange-light': {
      color: orangeLight,
      backgroundColor: alpha(orangeLight, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: orangeLight,
      },
      '&.opaque': {
        backgroundColor: darken(orangeLight, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: orangeLight,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(orangeLight, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(orangeLight, 0.4),
      },
    },
    '&.red': {
      color: redDark,
      backgroundColor: alpha(redDark, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: redDark,
      },
      '&.opaque': {
        backgroundColor: darken(redDark, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: redDark,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(redDark, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(redDark, 0.4),
      },
    },
    '&.red-light': {
      color: redLight,
      backgroundColor: alpha(redLight, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: redLight,
      },
      '&.opaque': {
        backgroundColor: darken(redLight, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: redLight,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(redLight, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(redLight, 0.4),
      },
    },
    '&.turquoise': {
      color: turquoise,
      backgroundColor: alpha(turquoise, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: turquoise,
      },
      '&.opaque': {
        backgroundColor: darken(turquoise, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: turquoise,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(turquoise, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(turquoise, 0.4),
      },
    },
    '&.gray': {
      color: grayish,
      backgroundColor: alpha(grayish, 0.25),
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        color: grayish,
      },
      '&.opaque': {
        backgroundColor: darken(grayish, 0.5),
      },
      '&.filled': {
        color: white,
        backgroundColor: grayish,
      },
      '&$deletable:focus': {
        backgroundColor: alpha(grayish, 0.25),
      },
      '&$clickable:hover': {
        backgroundColor: alpha(grayish, 0.4),
      },
    },
    '&.bg-transparent': {
      backgroundColor: 'transparent !important',
      fontWeight: 'bold',
    },
    '&.blueSolid': {
      color: white,
      backgroundColor: darken(blue, 0.4),
    },
    '&.redSolid': {
      color: white,
      backgroundColor: darken(orange, 0.4),
      '&.full': {
        width: '100%',
      },
    },
    '&.label-empty': {
      '& .MuiChip-label': {
        display: 'none',
      },
      '& .MuiChip-icon, & .MuiChip-deleteIcon': {
        margin: 0,
      },
    },
  },
  label: {
    lineHeight: 1,
    '.bold &': {
      fontWeight: 700,
    },
    '.long &': {
      overflow: 'initial',
      whiteSpace: 'normal',
      lineHeight: 1.4,
      wordBreak: 'break-word',
    },
    '.extra-small &': {
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  outlined: {
    '&.green': {
      borderColor: green,
    },
    '&.teal': {
      borderColor: teal,
    },
    '&.blue': {
      borderColor: blue,
    },
    '&.yellow': {
      borderColor: yellow,
    },
    '&.purple': {
      borderColor: purple,
    },
    '&.pink': {
      borderColor: pink,
    },
    '&.orange': {
      borderColor: orange,
    },
    '&.orange-light': {
      borderColor: orangeLight,
    },
    '&.red': {
      borderColor: redDark,
    },
    '&.turquoise': {
      borderColor: turquoise,
    },
    '&.gray': {
      borderColor: grayish,
    },
  },
  deletable: {
    '&:focus': {
      backgroundColor: alpha(white, 0.25),
    },
  },
}

export const SquareChip = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    padding: '5px 8px 3px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}))(Chip)

export const GroupChip = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 10,
    fontSize: 18,
    height: 45,
    width: 45,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}))(Chip)

export const ChipBg = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 360,
    padding: 1,
    paddingTop: 2,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&.green': {
      backgroundColor: green,
      color: white,
    },
    '&.teal': {
      backgroundColor: teal,
      color: white,
    },
    '&.blue': {
      backgroundColor: blue,
      color: white,
    },
    '&.yellow': {
      backgroundColor: yellow,
      color: white,
    },
    '&.purple': {
      backgroundColor: purple,
      color: white,
    },
    '&.pink': {
      backgroundColor: pink,
      color: white,
    },
    '&.orange': {
      backgroundColor: orange,
      color: white,
    },
    '&.orange-light': {
      backgroundColor: orangeLight,
      color: white,
    },
    '&.red': {
      backgroundColor: redDark,
      color: white,
    },
    '&.red-light': {
      backgroundColor: redLight,
      color: white,
    },
    '&.turquoise': {
      backgroundColor: turquoise,
      color: white,
    },
    '&.gray': {
      backgroundColor: grayish,
      color: white,
    },
  },
}))(Chip)

export const SquareChipBg = withStyles((theme: Theme) => ({
  root: {
    borderRadius: 10,
    padding: '5px 8px 3px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&.green': {
      backgroundColor: green,
      color: white,
    },
    '&.teal': {
      backgroundColor: teal,
      color: white,
    },
    '&.blue': {
      backgroundColor: blue,
      color: white,
    },
    '&.yellow': {
      backgroundColor: yellow,
      color: white,
    },
    '&.purple': {
      backgroundColor: purple,
      color: white,
    },
    '&.pink': {
      backgroundColor: pink,
      color: white,
    },
    '&.orange': {
      backgroundColor: orange,
      color: white,
    },
    '&.orange-light': {
      backgroundColor: orangeLight,
      color: white,
    },
    '&.red': {
      backgroundColor: redDark,
      color: white,
    },
    '&.turquoise': {
      backgroundColor: turquoise,
      color: white,
    },
    '&.gray': {
      backgroundColor: grayish,
      color: white,
    },
  },
}))(Chip)
