import ActionTypes from '../constants'
import { TemplateActions, TemplateState } from '../models'

const templateGetAllHandler = (
  state: TemplateState,
  action: TemplateActions,
): TemplateState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templates: action.payload.docs,
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default templateGetAllHandler
