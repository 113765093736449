import { select, call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Container from 'typedi'

import i18n from 'i18n'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import {
  setDialogAction,
  setFormModeAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import FormStatusType from 'state/enums/form-status.enum'
import { createCampaignAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the creation of a single campaign
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createCampaignAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createCampaignWorker(
  action: ReturnType<typeof createCampaignAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'campaigns/create',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (response._id) {
      yield put(createCampaignAction.success(response))
      yield put(setFormModeAction(FormStatusType.EDIT))
      yield put(push(`${routes.campaigns.EDIT}/${response._id}`))
      yield put(setDialogAction({ open: true }))
    } else {
      const message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')
      yield put(
        createCampaignAction.failure({
          name: response.status || '',
          message,
        }),
      )
      yield put(
        setSnackbarAction({
          message,
          open: true,
          variant: response._id ? 'success' : 'error',
        }),
      )
    }
  } catch (error) {
    yield put(createCampaignAction.failure(error))
  }
}

export default createCampaignWorker
