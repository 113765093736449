import ActionTypes from '../constants'
import { LevelDefinitionActions, LevelsState } from '../models'

const createLevelDefinitionHandler = (
  state: LevelsState,
  action: LevelDefinitionActions,
): LevelsState => {
  switch (action.type) {
    case ActionTypes.CREATE:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.CREATE_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentLevel: {
          ...state.currentLevel,
          id: action.payload.message,
        },
      }

    default:
      return state
  }
}

export default createLevelDefinitionHandler
