import { select, put, call } from 'redux-saga/effects'
import { Container } from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import {
  setDialogAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { updateCompanyAction } from '../actions/rest.actions'

import { UpdateCompanyResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the update of a single company
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateCompanyAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateCompanyWorker(
  action: ReturnType<typeof updateCompanyAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'companies/update/one',
      body: action.payload,
    }

    const { auth } = yield select()
    const response: UpdateCompanyResponse = yield call(
      apiService.updateData,
      auth.token,
    )

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: `${i18n.t('client')} ${response.result.name}`,
      what: i18n.t('updated'),
    })

    const success = response.status === 200

    if (success) {
      yield put(updateCompanyAction.success(response))

      yield put(setDialogAction({ open: false }))
    } else {
      message = i18n.t('messages.errorOccurred')

      yield put(
        updateCompanyAction.failure({
          name: response.status.toString(),
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: success ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))

    yield put(updateCompanyAction.failure(error))
  }
}

export default updateCompanyWorker
