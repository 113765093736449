import { CompanyState, CompanyActions } from '../models'
import ActionTypes from '../constants'

const getOneCompanyHandler = (
  state: CompanyState,
  action: CompanyActions,
): CompanyState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.GET_ONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        company: action.payload.result,
      }

    case ActionTypes.GET_ONE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default getOneCompanyHandler
