/**
 * Issues Rest Actions
 */
import { createAsyncAction, createAction } from 'typesafe-actions'
import {
  GetDocumentImagesResponse,
  GetDocumentImagesPayload,
} from 'state/player-card-kyc/models'
import ActionTypes from '../constants'

import {
  DownloadDocImagePayload,
  GetImagesPayload,
  GetImagesResponse,
} from '../model'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const getDocumentImagesAction = createAsyncAction(
  ActionTypes.GET_DOCUMENT_IMAGES_REQUEST,
  ActionTypes.GET_DOCUMENT_IMAGES_SUCCESS,
  ActionTypes.GET_DOCUMENT_IMAGES_FAILURE,
)<GetDocumentImagesPayload, GetDocumentImagesResponse, Error>()

export const downloadDocumentImageAction = createAsyncAction(
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_REQUEST,
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_SUCCESS,
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_FAILURE,
)<DownloadDocImagePayload, GetImagesResponse, Error>()

export const downloadDocumentSecondImageAction = createAsyncAction(
  ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_REQUEST,
  ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_SUCCESS,
  ActionTypes.DOWNLOAD_DOCUMENT_SECOND_IMAGE_FAILURE,
)<DownloadDocImagePayload, GetImagesResponse, Error>()

export const downloadImageAction = createAsyncAction(
  ActionTypes.DOWNLOAD_IMAGE_REQUEST,
  ActionTypes.DOWNLOAD_IMAGE_SUCCESS,
  ActionTypes.DOWNLOAD_IMAGE_FAILURE,
)<GetImagesPayload, GetImagesResponse, Error>()

export const clearImageState = createAction(
  ActionTypes.DOWNLOAD_DOCUMENT_IMAGE_CLEAR,
)
