import { select, call, put, delay } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import {
  getAllCampaignsAction,
  getAllSegmentNamesAction,
} from '../actions/index.actions'
import { CampaignsResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets all campaigns
 *
 * The following filters are available via query:
 *
 * @filter trigger
 * @filter statusCampaign
 *
 * * Default pagination:
 *
 *  . limit 17
 *  . page  1
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllCampaignsAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllCampaignsWorker(
  action: ReturnType<typeof getAllCampaignsAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'campaigns/get',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response: CampaignsResponse = yield call(
      apiService.getData,
      auth.token,
    )

    yield put(getAllCampaignsAction.success(response))
    yield delay(500)
    if (action.payload.page === 1) {
      yield put(getAllSegmentNamesAction.request())
    }
  } catch (error) {
    yield put(getAllCampaignsAction.failure(error))
  }
}

export default getAllCampaignsWorker
