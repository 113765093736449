import { takeLatest, debounce } from 'redux-saga/effects'
import {
  updateCompanyAction,
  getOneCompanyAction,
} from '../actions/rest.actions'
import updateCompanyWorker from './update.saga'

import getOneCompanyWorker from './get-one.saga'

/**
 * Main Saga for watching saga workers  in the Company state shape
 *
 * @generator
 */
function* companySagasWatcher() {
  yield [
    yield takeLatest(updateCompanyAction.request, updateCompanyWorker),
    yield debounce(600, getOneCompanyAction.request, getOneCompanyWorker),
  ]
}

export default companySagasWatcher
