import { createAsyncAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  FilterUserSettingsPayload,
  FilterUserSettingsResponse,
  CreateUserSettingsPayload,
  UpdateUserSettingsPayload,
  UserSettingsResponse,
  CreateUserSettingsResponse,
} from '../models'

/**
 * =======================
 * R E S T  A C T I O N S
 * =======================
 */

export const filterUserSettings = createAsyncAction(
  ActionTypes.FILTER_USER_SETTINGS_REQUEST,
  ActionTypes.FILTER_USER_SETTINGS_SUCCESS,
  ActionTypes.FILTER_USER_SETTINGS_FAILURE,
  ActionTypes.FILTER_USER_SETTINGS_CANCEL,
)<FilterUserSettingsPayload, FilterUserSettingsResponse, Error, string>()

export const createUserSettings = createAsyncAction(
  ActionTypes.CREATE_USER_SETTINGS_REQUEST,
  ActionTypes.CREATE_USER_SETTINGS_SUCCESS,
  ActionTypes.CREATE_USER_SETTINGS_FAILURE,
)<CreateUserSettingsPayload, CreateUserSettingsResponse, string>()

export const updateUserSettings = createAsyncAction(
  ActionTypes.UPDATE_USER_SETTINGS_REQUEST,
  ActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
  ActionTypes.UPDATE_USER_SETTINGS_FAILURE,
)<UpdateUserSettingsPayload, UserSettingsResponse, Error>()
