import { GameStoreActions, GameStoreState } from '../models'
import ActionTypes from '../constants'

const getGameVersionHandler = (
  state: GameStoreState,
  action: GameStoreActions,
): GameStoreState => {
  switch (action.type) {
    case ActionTypes.GET_GAME_VERSION:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_GAME_VERSION_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        gameVersionData: action.payload?.gameVersionData || [],
      }

    default:
      return state
  }
}

export default getGameVersionHandler
