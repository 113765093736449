export const ADMIN_LEGACY_NAMESPACE = 'admin-legacy' as 'admin-legacy'

export const BASIC_TABLES = {
  PROCESSED_PAYMENTS: 'processed_payments',
  SETTINGS: 'settings',
}

const WALLET = {
  GET_WITHDRAWALS: 'getWithdrawals' as 'getWithdrawals',
  DEPOSIT_MANUAL: 'wallet/deposit-manual-v2' as 'wallet/deposit-manual-v2',
  WITHDRAWAL_MANUAL: 'wallet/withdraw-manual-v2' as 'wallet/withdraw-manual-v2',

  GET: 'wallet-get' as 'wallet-get',
  GET_ALL_TRANSACTIONS: 'getTransactions' as 'getTransactions',
  GET_ONE_TRANSACTION: 'getTransaction' as 'getTransaction',

  GET_BASIC: 'basic-get' as 'basic-get',
  INSERT_BASIC: 'basic-insert' as 'basic-insert',
  UPDATE_BASIC: 'basic-update' as 'basic-update',

  COMPLETE_PAYMENT: 'transaction-status-update' as 'transaction-status-update',

  UPDATE_WITHDRAWALS_STATUS: 'withdrawals-status-change' as 'withdrawals-status-change',

  GET_GAME_CATEGORIES_PLAYER: 'getGameCategoriesByPlayer' as 'getGameCategoriesByPlayer',
  GET_GAME_PROVIDERS_PLAYER: 'getGameProvidersByPlayer' as 'getGameProvidersByPlayer',
  GET_GAMES_PLAYER: 'getGamesByPlayer' as 'getGamesByPlayer',

  GET_WITHDRAWAL_PAYMENTS: 'get-processed-payments' as 'get-processed-payments',
  CREATE_PAYMENT_FILE: 'adbe/save-payment-file' as 'adbe/save-payment-file',

  COMPLETE_LATE_BONUS_WIN: 'wallet/complete-late-bonus-win' as 'wallet/complete-late-bonus-win',
}

export default WALLET
