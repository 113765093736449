/**
 *
 * Rules Engine constants
 *
 */

enum ActionTypes {
  SET_FILTERS = '@rules-engine/SET_FILTERS',

  RESET_CURRENT_RULE = '@rules-engine/RESET_CURRENT_RULE',
  RESET_RULES = '@rules-engine/RESET_RULES',

  CREATE_RULE = '@rules-engine/CREATE_RULE',
  CREATE_RULE_SUBSCRIBER = '@rules-engine/CREATE_RULE_SUBSCRIBER',

  UPDATE_RULE = '@rules-engine/UPDATE_RULE',
  UPDATE_RULE_SUBSCRIBER = '@rules-engine/UPDATE_RULE_SUBSCRIBER',

  FIND_RULE = '@rules-engine/FIND_RULE',
  FIND_RULE_SUBSCRIBER = '@rules-engine/FIND_RULE_SUBSCRIBER',

  FILTER_RULES = '@rules-engine/FILTER_RULES',
  FILTER_RULES_SUBSCRIBER = '@rules-engine/FILTER_RULES_SUBSCRIBER',

  DELETE_RULE = '@rules-engine/DELETE_RULE',
  DELETE_RULE_SUBSCRIBER = '@rules-engine/DELETE_RULE_SUBSCRIBER',

  GET_ZENDESK_AGENTS = '@rules-engine/GET_ZENDESK_AGENTS',
  GET_ZENDESK_AGENTS_SUBSCRIBER = '@rules-engine/GET_ZENDESK_AGENTS_SUBSCRIBER',

  GET_SLACK_LIST = '@rules-engine/GET_SLACK_LIST',
  GET_SLACK_LIST_SUBSCRIBER = '@rules-engine/GET_SLACK_LIST_SUBSCRIBER',

  GET_BONUSES = '@rules-engine/GET_BONUSES',
  GET_BONUSES_SUBSCRIBER = '@rules-engine/GET_BONUSES_SUBSCRIBER',

  DUPLICATE_RULE = '@rules-engine/DUPLICATE_RULE',
  DUPLICATE_RULE_SUBSCRIBER = '@rules-engine/DUPLICATE_RULE_SUBSCRIBER',
}

export default ActionTypes
