import { useState, useCallback, createRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { sub, format, isValid } from 'date-fns'
import routes from 'routes/index.routes'

import {
  setPlayerSearchPropsAction,
  setSearchTabAction,
  setTransactionSearchPropsAction,
} from 'state/search/actions/index.actions'
import SearchType from 'state/enums/search.enum'
import { ApplicationState, WSErrorException } from 'state/root.models'

import TypeValue from 'state/enums/type-value.enum'
import WS_ROUTES from 'state/websocket.routes'
import {
  getPlayers,
  getPlayersExceptionSubscribe,
} from 'state/player-card/actions/index.actions'

import { TransactionsActions } from 'state/wallet/actions/index.action'
import { TransactionFilterType, PlayerSearchProps } from 'state/search/models'
import { TransactionsFilters } from 'state/wallet/models/transactions.model'

import { PlayerCardActions } from 'state/player-card'
import { GetPlayersResponse } from 'state/player-card/models'
import SortTransactionType from 'state/enums/sort-transaction.enum'

import SortType from 'state/enums/sort.enum'
import useSearchTransactionActions from 'views/search-transaction/hooks/use-search-transaction-actions.hook'

function useSearchBar() {
  const dispatch = useDispatch()

  const history = useHistory()

  const { playerSearchProps, transactionSearchProps, tab } = useSelector(
    ({ search }: ApplicationState) => search,
  )

  const {
    filters: filtersTransactions,
    isLoading: isLoadingTransactions,
  } = useSelector(
    ({ walletTransactions }: ApplicationState) => walletTransactions,
  )

  const { isLoading: isLoadingPlayers } = useSelector(
    ({ playerCard }: ApplicationState) => playerCard,
  )

  const maxDate = sub(new Date(new Date().setHours(0, 0, 0, 0)), {
    years: 18,
  })

  const minDate = sub(new Date(new Date().setHours(0, 0, 0, 0)), {
    years: 120,
  })

  const [stateUI, setStateUI] = useState({
    openFilters: false,
    openPicker: false,
  })

  const inputRef = createRef<HTMLInputElement>()

  const isLowerCase = ['email']

  const { getAllTransactionsAction } = useSearchTransactionActions()

  const setFiltersTransactions = (filtersNew: TransactionsFilters) => {
    dispatch(TransactionsActions.setTransactionFilter(filtersNew))
  }

  const setFiltersPlayers = (filtersNew: PlayerSearchProps) => {
    dispatch(PlayerCardActions.setPlayerFilter(filtersNew))
  }

  const handleChangeTab = (event: any, newValue: SearchType) => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    dispatch(
      setPlayerSearchPropsAction({
        ...playerSearchProps,
        exact: false,
        filter: 'username',
        filterValue: '',
      }),
    )
    dispatch(
      setTransactionSearchPropsAction({
        ...transactionSearchProps,
        filter: 'playerId',
      }),
    )
    setFiltersTransactions({
      playerId: undefined,
      transactionType: undefined,
      transactionStatus: undefined,
      sortField: SortTransactionType.date,
      sortType: SortType.Desc,
      isPlayerCard: false,
    })
    setFiltersPlayers({
      exact: false,
      typeValue: TypeValue.STRING,
      filterValue: '',
      filter: 'username',
      limit: 25,
      page: 1,
    })
    dispatch(setSearchTabAction(newValue))
  }

  const textValue = (value: string) => {
    if (value !== 'birthdate') {
      return playerSearchProps.filter !== 'birthdate'
        ? playerSearchProps.filterValue
        : ''
    }
    return maxDate
  }

  const handleChangeFilter = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
    if (tab === SearchType.PLAYER) {
      dispatch(
        setPlayerSearchPropsAction({
          ...playerSearchProps,
          exact:
            target.value === 'birthdate' ||
            target.value === 'email' ||
            target.value === 'phonenumber',
          typeValue:
            target.value === 'birthdate' ? TypeValue.DATE : TypeValue.STRING,
          filter: target.value,
          filterValue: textValue(target.value),
        }),
      )
    } else if (tab === SearchType.TRANSACTION) {
      dispatch(
        setTransactionSearchPropsAction({
          filter: target.value as TransactionFilterType,
        }),
      )
      setFiltersTransactions({
        playerId: undefined,
        transactionType: undefined,
        transactionStatus: undefined,
        sortField: SortTransactionType.date,
        sortType: SortType.Desc,
        isPlayerCard: false,
      })
    }
  }

  const handleChangeExact = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setPlayerSearchPropsAction({
        ...playerSearchProps,
        exact: target.checked,
      }),
    )
  }

  const handleChangePlayerSearch = ({
    currentTarget: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setPlayerSearchPropsAction({
        ...playerSearchProps,
        filterValue: isLowerCase.includes(playerSearchProps.filter)
          ? value.toLowerCase()
          : value,
      }),
    )
  }

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    if (date && isValid(date)) {
      dispatch(
        setPlayerSearchPropsAction({
          ...playerSearchProps,
          filter: 'birthdate',
          filterValue: format(date, 'MM/dd/yyyy'),
        }),
      )
      setStateUI({
        ...stateUI,
        openFilters: false,
      })
    }
  }

  const handleToggleFilters = (value: boolean) => {
    setStateUI({
      ...stateUI,
      openFilters: value,
    })
  }

  const handleTogglePicker = (value: boolean) => {
    setStateUI({
      ...stateUI,
      openPicker: value,
      openFilters: true,
    })
  }

  const getPlayersAction = useCallback(
    (newFilters: PlayerSearchProps) =>
      dispatch(
        getPlayers(
          newFilters,
          WS_ROUTES.PLAYER.GET_PLAYERS,
          (response: GetPlayersResponse & WSErrorException) => {
            if (response?.docs) {
              dispatch(PlayerCardActions.getPlayersSubscribe(response))
            }

            if (!response?.docs) {
              dispatch(getPlayersExceptionSubscribe(response))
            }
          },
        ),
      ),
    [dispatch], // eslint-disable-line
  )

  const handleSearch = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<
          HTMLTextAreaElement | HTMLInputElement | HTMLDivElement
        >,
  ) => {
    setStateUI({
      ...stateUI,
      openPicker: false,
      openFilters: false,
    })
    if (tab === SearchType.PLAYER) {
      getPlayersAction({
        ...playerSearchProps,
        limit: 25,
        page: 1,
      })
      setFiltersPlayers({
        ...playerSearchProps,
        limit: 25,
        page: 1,
      })
      history.push(routes.search.PLAYERS)
    } else if (tab === SearchType.TRANSACTION) {
      getAllTransactionsAction({
        ...filtersTransactions,
        limit: 25,
        page: 1,
        sortField: SortTransactionType.date,
        sortType: SortType.Desc,
        isPlayerCard: false,
      })
      setFiltersTransactions({
        ...filtersTransactions,
        limit: 25,
        page: 1,
        sortField: SortTransactionType.date,
        sortType: SortType.Desc,
        isPlayerCard: false,
      })
      history.push(routes.search.TRANSACTIONS)
    } else if (tab === SearchType.PAYCARD) {
      // TODO implement search paycards
      history.push(routes.search.PAYCARDS)
    }
  }

  const clearSearch = () => {
    dispatch(setSearchTabAction(SearchType.PLAYER))
    dispatch(
      setPlayerSearchPropsAction({
        exact: false,
        typeValue: TypeValue.STRING,
        filterValue: '',
        filter: 'username',
        limit: 25,
        page: 1,
      }),
    )
    dispatch(
      setTransactionSearchPropsAction({
        filter: 'playerId',
      }),
    )
  }

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.blur()
      }
      handleSearch(event)
    }
  }

  useEffect(() => {
    const searchInput = playerSearchProps.filterValue
    if (isLowerCase.includes(playerSearchProps.filter)) {
      dispatch(
        setPlayerSearchPropsAction({
          ...playerSearchProps,
          filterValue: searchInput.toLocaleString().toLowerCase(),
        }),
      )
    }
  }, [playerSearchProps.filter]) // eslint-disable-line

  return {
    playerSearchProps,
    transactionSearchProps,
    tab,
    stateUI,
    maxDate,
    minDate,
    handleChangePlayerSearch,
    handleChangeTab,
    handleChangeFilter,
    handleChangeDate,
    handleChangeExact,
    handleToggleFilters,
    handleTogglePicker,
    handleSearch,
    filtersTransactions,
    setFiltersTransactions,
    isLoadingTransactions,
    isLoadingPlayers,
    clearSearch,
    onKeyDown,
    inputRef,
  }
}

export default useSearchBar
