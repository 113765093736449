import { PlayerCardSummaryState, PlayerCardSummaryActions } from '../models'
import ActionTypes from '../constants'

const playerNotesHandler = (
  state: PlayerCardSummaryState,
  action: PlayerCardSummaryActions,
): PlayerCardSummaryState => {
  switch (action.type) {
    case ActionTypes.GET_PLAYER_NOTES:
      return { ...state, isLoading: true }

    case ActionTypes.GET_PLAYER_NOTES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        notes: action.payload?.docs || state.notes,
      }

    case ActionTypes.CREATE_PLAYER_NOTE_SUBSCRIBER:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      }

    case ActionTypes.UPDATE_PLAYER_NOTE_SUBSCRIBER:
      return {
        ...state,
        notes: state.notes.map(note =>
          note._id === action.payload._id ? action.payload : note,
        ),
      }

    case ActionTypes.DELETE_PLAYER_NOTE_SUBSCRIBER:
      return {
        ...state,
        notes: state.notes.filter(note => note._id !== action.payload._id),
      }

    default:
      return state
  }
}

export default playerNotesHandler
