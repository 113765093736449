import { takeLatest, debounce } from 'redux-saga/effects'
import { TemplateSmsActions } from 'state/template-sms'
import getAllTemplateSmsWorker from './get-all.saga'

import getOneTemplateSmsWorker from './get-one.saga'
import createTemplateSmsWorker from './create.saga'
import updateTemplateSmsWorker from './update.saga'

import archiveTemplateSmsWorker from './archive.saga'
import sendTestTemplateSmsWorker from './send-test.saga'
import duplicateTemplateSmsWorker from './duplicate.saga'

/**
 * Main Saga for watching saga workers in the Template SMS state shape
 *
 * @generator
 */
function* templateSmsSagasWatcher() {
  yield [
    yield debounce(
      500,
      TemplateSmsActions.getAllTemplateSmsAction.request,
      getAllTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.getOneTemplateSmsAction.request,
      getOneTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.createTemplateSmsAction.request,
      createTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.updateTemplateSmsAction.request,
      updateTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.archiveTemplateSmsAction.request,
      archiveTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.duplicateTemplateSmsAction.request,
      duplicateTemplateSmsWorker,
    ),
    yield takeLatest(
      TemplateSmsActions.sendTestTemplateSmsAction.request,
      sendTestTemplateSmsWorker,
    ),
  ]
}

export default templateSmsSagasWatcher
