import IssueTypes from 'state/enums/issue-type.enum'
import { PlayerCardKYCActions, PlayerCardKYCDocumentState } from '../models'
import ActionTypes from '../constants'

const getDocumentsHandler = (
  state: PlayerCardKYCDocumentState,
  action: PlayerCardKYCActions,
): PlayerCardKYCDocumentState => {
  switch (action.type) {
    case ActionTypes.GET_DOCUMENTS:
      return {
        ...state,
        isLoadingTable: true,
      }

    case ActionTypes.GET_DOCUMENTS_SUBSCRIBER:
      return {
        ...state,
        isLoadingTable: false,
        documents: !action.payload.docs ? state.documents : action.payload.docs,
      }

    case ActionTypes.GET_DOCUMENTS_EXCEPTION:
      return {
        ...state,
        isLoadingTable: false,
      }

    case ActionTypes.GET_ALERT_KYC_DOCS: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case ActionTypes.GET_ALERT_KYC_DOCS_SUBSCRIBER: {
      return {
        ...state,
        isLoading: false,
        alertsDocuments: action.payload.message.filter(
          doc =>
            doc.issueType === IssueTypes.UPLOAD_DOCUMENT ||
            doc.issueType === IssueTypes.UPLOAD_UTILITY,
        ),
        manualAlertDocuments: action.payload.message.filter(
          doc =>
            doc.issueType === IssueTypes.MANUAL_UPLOAD_DOCUMENT ||
            doc.issueType === IssueTypes.MANUAL_UPLOAD_UTILITY,
        ),
      }
    }

    default:
      return state
  }
}

export default getDocumentsHandler
