/**
 * @description extracts the text from an HTML tag
 * @param html
 * @returns {Promise<string>} text
 */
export const extractTextFromHTML = (html: string): string => {
  if (html.length > 0) {
    return html.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ')
  }
  return ''
}

/**
 * Create a regex with a min and max length
 *
 * @param {number} min
 * @param {number} max
 */
export const setRegexLength = (max: number, min?: number) => {
  if (!min) {
    return new RegExp('^.{' + 0 + ',' + max + '}$') // eslint-disable-line prefer-template
  }
  return new RegExp('^.{' + min + ',' + max + '}$') // eslint-disable-line prefer-template
}

export const capitalizedText = (text: string) =>
  text?.charAt(0)?.toUpperCase() + text?.slice(1)

export function sentenceCase(input: string, lowercaseBefore: boolean) {
  let text = input === undefined || input === null ? '' : input
  if (lowercaseBefore) {
    text = text.toLowerCase()
  }
  return text.toString().replace(/(^|\. *)([a-z])/g, function(match) {
    return match.toUpperCase()
  })
}

export const removeUnderscore = (text: string) => text?.replace(/_/g, ' ')
