import AmlStatusType from 'state/enums/aml-status.enum'
import TeledataStatusType from 'state/enums/teledata-status.enum'
import AmlSubstatusType from 'state/enums/aml-substatus.enum'

import KycStatusType from 'state/enums/kyc-status.enum'
import DocumentFileFormat from 'state/enums/document-file-format.enum'
import JumioVerificationStatus from 'state/enums/jumio-verification-status.enum'

import PlayerDocumentStatusType from 'state/enums/player-document-status.enum'
import UbiIDStatus from 'state/enums/ubiID-status.enum'
import PlayerIbansStatusType from 'state/enums/player-ibans-status.enum'

import PlayerIbansRejectStatusType from 'state/enums/player-ibans-reject-status.enum'
import {
  PlayerCardKYCDocumentState,
  PlayerCardKYCHistoryState,
  PlayerCardKYCStatusState,
} from '../models'
import {
  vetoHistory,
  nonPepHistory,
  nonPepCheck,
  vetoCheck,
  verifiedAddressCheck,
} from '../data-test'

export const initialStateDocument: PlayerCardKYCDocumentState = {
  documents: [],
  documentPreview: {
    format: DocumentFileFormat.IMG,
  },
  currentDocument: {
    _id: '',
    createdAt: '2020-03-25T04:00:00.000Z',
    createdBy: 'Pasino Test',
    documenIssueDate: '2020-03-25T04:00:00.000Z',
    documentExpiryDate: '2020-03-25T04:00:00.000Z',
    documentNumber: '3r434grg4g34',
    documentType: 'ui',
    imageId: '01',
    issuuingAuthority: true,
    playerId: '1w123dbaga728ahahjas02ms',
    status: 'string',
    updatedAt: '2020-03-25T04:00:00.000Z',
    isVisible: true,
  },
  documentImages: [],
  currentDocumentImage: {
    issuuingauthority: '',
    documentNumber: '',
    issueDate: '',
    documentExpiry: '',
    docType: 'IN_REVIEW',
    documentId: '',
    kind: '',
    id: '',
    selfLink: '',
    mediaLink: '',
    name: '',
    bucket: '',
    generation: '',
    metageneration: '',
    contentType: '',
    storageClass: '',
    size: '',
    md5Hash: '',
    crc32c: '',
    etag: '',
    timeCreated: '',
    updated: '',
    status: '1',
    timeStorageClassUpdated: '',
    isVisible: true,
  },
  currentDownloadedImage: '',
  currentDownloadedSecondImage: '',
  jumioData: [],
  jumioImages: {
    front: '',
    back: '',
  },
  isLoading: false,
  hasError: false,
  error: '',
  isLoadingTable: false,
  jumioDoc: {
    createdAtText: '',
    documentExpiryText: '',
    documentId: '',
    documentNumber: '',
    documentStatusText: 'Pending',
    documentType: 'Jumio',
    documentTypeText: '-',
    id: '',
    isVisible: true,
    issueDateText: '',
    issuuingauthority: '',
    status: 4,
    details: '',
  },
  jumioInfo: {
    status: JumioVerificationStatus.UNDEFINED,
    created: '',
    documentId: '',
    gamanzaStatus: 'PENDING',
    gamanzaId: '',
  },
  ubiIDDoc: [],
  ubiIDInfo: {
    status: PlayerDocumentStatusType.PENDING,
    caseIntegrityStatus: UbiIDStatus.NEEDS_MANUAL_REVIEW,
    caseId: '',
    playerId: '',
    playerFirstName: '',
    playerLastName: '',
    playerBirthdate: '',
    playerNationality: '',
    documentFirstName: '',
    documentLastName: '',
    documentBirthdate: '',
    documentNationality: '',
    rejectedReason: '',
    rejectedAdminId: '',
    confirmedAdminId: '',
  },
  caseFiles: [],
  playerIbans: [],
  playerBankAccountInfo: {
    ibanId: 0,
    beneficiary: '',
    iban: '',
    bic: '',
    inserted: '',
    ibanStatus: PlayerIbansStatusType.PENDING_VERIFICATION,
    status: PlayerDocumentStatusType.PENDING,
    rejectedReason: PlayerIbansRejectStatusType.WRONG_DOCUMENT,
  },
  metaData: {
    contentType: '',
    size: '',
  },
  paginationDocuments: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  alertsDocuments: [],
  manualAlertDocuments: [],
}

export const initialStateHistory: PlayerCardKYCHistoryState = {
  vetoCheck,
  nonPepCheck,
  nonPepCountIssues: [],
  verifiedAddressCheck,
  vetoHistory,
  nonPepHistory,
  teledataHistory: [],
  teledataStatus: TeledataStatusType.GREEN,
  isLoading: false,
  playerTeledataHistory: [],
}

export const initialStateStatus: PlayerCardKYCStatusState = {
  aml: {
    status: AmlStatusType.UNVERIFIED,
    substatus: AmlSubstatusType.UNTRIGGERED,
    answer: {
      playerId: '',
      status: AmlStatusType.UNVERIFIED,
      subStatus: AmlSubstatusType.UNTRIGGERED,
      lastUpdate: '',
      answeredBy: '',
      dateAnswered: '',
      name: '',
      lastName: '',
      birthday: new Date().toISOString(),
      zipCode: '',
      addressCountry: '',
      addressCity: '',
      addressStreet: '',
      addressNumber: '',
      nationality: '',
      beneficialOwner: false,
      identityOwner: {
        name: '',
        lastName: '',
        birthday: new Date().toISOString(),
        zipCode: '',
        addressCountry: '',
        addressCity: '',
        addressStreet: '',
        addressNumber: '',
        nationality: '',
        isPEP: false,
        occupation: '',
        salary: 0,
        currency: '',
        depositFunds: 0,
      },
      confirmAccuracy: false,
    },
  },
  resolved: false,
  isLoading: false,
  isAmlLoading: false,
  lastKycStatus: KycStatusType.UNVERIFIED,
  isStatusLoading: false,
}
