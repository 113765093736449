import { UserActions, UserState } from '../models'
import ActionTypes from '../constants'

const updateUserHandler = (
  state: UserState,
  action: UserActions,
): UserState => {
  switch (action.type) {
    case ActionTypes.UPDATE_REQUEST:
      return { ...state }

    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => {
          if (user._id === action.payload._id) {
            return {
              ...user,
              ...action?.payload,
            }
          }
          return user
        }),
      }

    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        hasError: true,
        error: action.payload,
      }

    default:
      return state
  }
}

export default updateUserHandler
