import { useState } from 'react'
import { useSelector } from 'react-redux'
import useForm from 'hooks/form/use-form.hook'

import ShortcutType from 'state/enums/shortcut.enum'
import { UserSelectors } from 'state/user'
import { globalUiSelectors } from 'state/ui/global'

import shortcutsSchema from '../components/add-shortcut/schema'
import shortcutsValidator from '../components/add-shortcut/validator'
import useSidebarActions from './use-sidebar-actions.hook'

export type PopperStateType = {
  anchorEl: HTMLElement | null
  isOpen: boolean
  id: string
  type?: ShortcutType
}

const useSidebarShortcuts = () => {
  const [shortcutMenuState, setShortcutMenuState] = useState<PopperStateType>({
    anchorEl: null,
    isOpen: false,
    id: '',
    type: ShortcutType.MENU,
  })

  const [shortcutPopperAnchor, setPopperAnchor] = useState<HTMLElement | null>(
    null,
  )

  const {
    state,
    handleOnChange,
    disable,
    setFormValues,
  } = useForm(shortcutsSchema, shortcutsValidator, () => {})

  const {
    deleteShortcut,
    createShortcut,
    updateShortcut,
    setShortcutPopper,
  } = useSidebarActions()

  const { shortcuts } = useSelector(UserSelectors.root)

  const { popover: shortcutPopper } = useSelector(globalUiSelectors.root)

  const handleResetAddShortcut = () => {
    setFormValues({
      id: {
        value: '',
        error: '',
      },
      name: {
        value: '',
        error: '',
      },
      url: {
        value: '',
        error: '',
      },
    })
  }

  const handleAddShortcut = (name: string, url: string, type: ShortcutType) => {
    const urlNew =
      type === ShortcutType.MENU
        ? `${process.env.REACT_APP_SITE_DEV?.slice(0, -1)}${url}`
        : url
    createShortcut({ url: urlNew, name, type })
  }

  const handleUpdateShortcut = () => {
    updateShortcut({
      id: state.id.value,
      url: state.url.value,
      name: state.name.value,
    })
  }

  const handleDeleteShortcut = (_id: string) => {
    setShortcutMenuState({
      ...shortcutMenuState,
      anchorEl: null,
      isOpen: false,
      id: '',
    })
    deleteShortcut(_id)
  }

  const handleShortcutMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    type: ShortcutType,
    _id: string,
  ) => {
    event.stopPropagation()
    setShortcutMenuState({
      ...shortcutMenuState,
      anchorEl: event.currentTarget,
      isOpen: true,
      id: _id,
      type,
    })
  }

  const handleShortcutMenuClose = () => {
    setShortcutMenuState({
      ...shortcutMenuState,
      isOpen: false,
      id: '',
    })
  }

  const handleShortcutEditOpen = (event: React.MouseEvent<HTMLElement>) => {
    handleShortcutMenuClose()
    setShortcutPopper({
      open: true,
      id: event.currentTarget.id,
    })
    setPopperAnchor(shortcutMenuState.anchorEl)
  }

  const handleShortcutAddOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleResetAddShortcut()
    setShortcutPopper({
      open: true,
      id: event.currentTarget.id,
    })
    setPopperAnchor(event.currentTarget)
  }

  const handleShortcutAddClose = () => {
    setShortcutPopper({
      open: false,
      id: '',
    })
    setPopperAnchor(null)
  }

  return {
    shortcuts,
    state,
    handleOnChange,
    setFormValues,
    disable,
    handleAddShortcut,
    handleUpdateShortcut,
    handleDeleteShortcut,
    handleResetAddShortcut,

    handleShortcutAddOpen,
    handleShortcutEditOpen,
    handleShortcutAddClose,
    shortcutPopper,
    shortcutPopperAnchor,
    handleShortcutMenuOpen,
    handleShortcutMenuClose,
    shortcutMenuState,
  }
}

export default useSidebarShortcuts
