import ActionTypes from '../constants'
import { TagActions, TagState } from '../models'

const getAllCategoriesHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_CATEGORIES:
      return {
        ...state,
        isLoadingCategories: true,
      }

    case ActionTypes.GET_ALL_CATEGORIES_SUBSCRIBER:
      return {
        ...state,
        isLoadingCategories: false,
        categories: action.payload?.docs || [],
      }

    default:
      return state
  }
}
export default getAllCategoriesHandler
