/**
 *
 * Bonus constants
 *
 */

enum ActionTypes {
  GET_BONUS_HISTORY = '@bonus/GET_BONUS_HISTORY',
  GET_BONUS_HISTORY_SUBSCRIBER = '@bonus/GET_BONUS_HISTORY_SUBSCRIBER',

  GET_FILTER_BONUS_HISTORY_SUBSCRIBER = '@bonus/GET_FILTER_BONUS_HISTORY_SUBSCRIBER',

  GET_BONUS_LOGS = '@bonus/GET_BONUS_LOGS',
  GET_BONUS_LOGS_SUBSCRIBER = '@bonus/GET_BONUS_LOGS_SUBSCRIBER',

  ADD_BONUS_PROGRAM = '@bonus/ADD_BONUS_PROGRAM',
  ADD_BONUS_PROGRAM_SUBSCRIBER = '@bonus/ADD_BONUS_PROGRAM_SUBSCRIBER',

  ADD_BONUS_FREE_ROUNDS = '@bonus/ADD_BONUS_FREE_ROUNDS',
  ADD_BONUS_FREE_ROUNDS_SUBSCRIBER = '@bonus/ADD_BONUS_FREE_ROUNDS_SUBSCRIBER',

  ADD_BONUS_STANDARD = '@bonus/ADD_BONUS_STANDARD',
  ADD_BONUS_STANDARD_SUBSCRIBER = '@bonus/ADD_BONUS_STANDARD_SUBSCRIBER',

  ADD_BONUS_DEPOSIT = '@bonus/ADD_BONUS_DEPOSIT',
  ADD_BONUS_DEPOSIT_SUBSCRIBER = '@bonus/ADD_BONUS_DEPOSIT_SUBSCRIBER',

  CANCEL_BONUS = '@bonus/CANCEL_BONUS',
  CANCEL_BONUS_SUBSCRIBER = '@bonus/CANCEL_BONUS_SUBSCRIBER',

  CHECK_DEPOSIT_HAS_BONUS = '@bonus/CHECK_DEPOSIT_HAS_BONUS',
  CHECK_DEPOSIT_HAS_BONUS_SUBSCRIBER = '@bonus/CHECK_DEPOSIT_HAS_BONUS_SUBSCRIBER',

  GET_BONUSES = '@bonus/GET_BONUSES',
  GET_BONUSES_SUBSCRIBER = '@bonus/GET_BONUSES_SUBSCRIBER',

  SET_PAGINATION_BONUS_ACTIVE = '@bonus/SET_PAGINATION_BONUS_ACTIVE',
  SET_PAGINATION_BONUS_COMPLETED = '@bonus/SET_PAGINATION_BONUS_COMPLETED',

  SET_FILTERS = '@bonus/SET_FILTERS',
}

export default ActionTypes
