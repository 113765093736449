import { colors } from '../theme/colors.theme'

const { blueNav } = colors

export const tooltipTheme = {
  tooltip: {
    borderRadius: 4,
    backgroundColor: blueNav,
    fontSize: 12,
    color: 'inherit',
    '&.extendWidth': {
      maxWidth: 430,
    },
  },
  arrow: {
    color: blueNav,
  },
  tooltipPlacementBottom: {
    margin: '8px 0 !important',
    boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)`,
  },
}
