import { makeStyles, createStyles, alpha } from '@material-ui/core/styles'
import { colors } from 'theme/colors.theme'

const useStyles = makeStyles(() =>
  createStyles({
    nav: {
      padding: 0,
      minWidth: 0,
      width: 30,
      height: 8,
      backgroundColor: alpha(colors.white, 0.3),
      borderRadius: 4,
      marginRight: 16,
      '&:hover': {
        backgroundColor: `${alpha(colors.blue, 0.3)}!important`,
      },
      '&.active': {
        backgroundColor: colors.blue,
      },
    },
  }),
)

export default useStyles
