import ActionTypes from '../constants'
import { RewardsState, RewardsActions } from '../models'

const rewardsFormHandler = (
  state: RewardsState,
  action: RewardsActions,
): RewardsState => {
  switch (action.type) {
    case ActionTypes.SET_ITEM_FORM_MODE:
      return {
        ...state,
        formItem: action.payload,
      }

    case ActionTypes.SET_PRODUCT_FORM_MODE:
      return {
        ...state,
        formProduct: action.payload,
      }

    case ActionTypes.SET_SHOP_FORM_MODE:
      return {
        ...state,
        formShop: action.payload,
      }

    default:
      return state
  }
}

export default rewardsFormHandler
