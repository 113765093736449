import keys from './keys'

type EnumType = { [s: number]: string }

function mapEnum(enumerable: EnumType, fn: Function): any[] {
  // get all the members of the enum
  const enumMembers: any[] = keys(enumerable).map(key => enumerable[key])

  // we are only interested in the numeric identifiers as these represent the values
  const enumValues: number[] = enumMembers.filter(v => {
    return typeof v === 'number'
  })

  // now map through the enum values)
  return enumValues.map(m => fn(m))
}

export default mapEnum
