import { ActionType } from 'typesafe-actions'
import PlayerDocumentStatusType from 'state/enums/player-document-status.enum'
import KycStatusType from 'state/enums/kyc-status.enum'

import AmlStatusType from 'state/enums/aml-status.enum'
import TeledataStatusType from 'state/enums/teledata-status.enum'
import AmlSubstatusType from 'state/enums/aml-substatus.enum'

import {
  WSGamanzaError,
  WSErrorException,
  PaginationResponse,
} from 'state/root.models'
import IssueTypes from 'state/enums/issue-type.enum'
import {
  InsertIssueAdminResponse,
  InsertIssueAdminPayload,
} from 'state/player-card/models'

import PlayerDocumentType from 'state/enums/player-document-type.enum'
import PlayerAccountStatusType from 'state/enums/player-account-status.enum'
import DocumentFileFormat from 'state/enums/document-file-format.enum'

import JumioVerificationStatus from 'state/enums/jumio-verification-status.enum'
import UbiIDStatus from 'state/enums/ubiID-status.enum'
import PlayerIbansStatusType from 'state/enums/player-ibans-status.enum'

import PlayerIbansRejectStatusType from 'state/enums/player-ibans-reject-status.enum'
import { Issue, IssueCount } from 'state/issues/model'
import { Operator } from 'state/user/models'

import RiskLevel from 'state/enums/risk-level.enum'
import * as actions from './actions/index.actions'
import ActionTypes from './constants'

/**
 * ==========================
 * D A T A   T Y P E S
 * ==========================
 */

export enum RequestType {
  GET,
  CREATE,
}

export interface DocumentTableColumnProps {
  plainCreatedAtText?: string
  createdAtText: string
  details: string
  documentExpiryText: string
  documentId: string
  documentNumber: string
  documentStatusText: string
  documentType: string
  documentTypeText: string
  id: string
  caseId?: string
  ibanId?: number
  ibanStatus?: PlayerIbansStatusType
  caseIntegrityStatus?: UbiIDStatus
  issueDateText: string
  issuuingauthority: string
  isVisible: boolean
  status: PlayerDocumentStatusType
  documentExpiryDate?: string
  documentIssueDate?: string
}

export type DocumentImageType = {
  documentType: string
  kind: string
  id: string
  selfLink: string
  mediaLink: string
  name: string
  bucket: string
  generation: string
  metageneration: string
  contentType: string
  storageClass: string
  size: string
  md5Hash: string
  crc32c: string
  etag: string
  timeCreated: string
  timeStorageClassUpdated: string
  updated: string
  // merged props from PlayerCardDocumentType
  documentId: string
  status: string
  isVisible: boolean
  docType: string
  issueDate: string
  documentExpiry: string
  documentNumber: string
  issuuingauthority: string
}

export type DocumentUtilityType = {
  type: string
  playerId: string
  fileNames: string[]
  utilityId: string
}

export type PlayerCardDocumentType = {
  _id: string
  createdAt: string
  createdBy: string
  documenIssueDate: string
  documentExpiryDate: string
  documentNumber: string
  documentType: string
  imageId: string
  issuuingAuthority: boolean | string
  playerId: string
  status: string
  updatedAt: string
  isVisible: boolean
}

export type PlayerDocumentTypeKey = keyof typeof PlayerDocumentType

export interface HistoryTableColumnProps extends HistoryType {
  dateText: string
}

export type TeledataHistoryTableColumnProps = TeleDataHistory

export type HistoryType = {
  id: string
  date: string
  result: string | TeledataStatusType
  admin: string
  comments?: string
}

export type TeleDataHistory = {
  id?: string
  admin?: string
  colorId: TeledataStatusType
  color?: string
  playerId: string
  timestamp: string
  comment: string
  inserted?: string
  adminId?: number
}

export interface TableTeledataHistory
  extends Omit<TeleDataHistory, 'timestamp'> {
  inserted: string
}

export type PlayerTeledataType = {
  teledata: TeledataStatusType
  assignedBy: string
  comments: string
}

export type HistoryFilterType = {
  id: string
  days: number
  date1: string
  date2: string
  page: number
  limit: number
}

export type AmlPlayerStatusType = {
  playerId: string
  status: AmlStatusType
  subStatus: AmlSubstatusType
  answer: number
  inserted: string
  updated: string
  manualTriggered: {
    adminId: string
    date: Date
    comment: string
  }
}

export type AmlPlayerAnswerType = {
  adminId?: number
  playerId: string
  status: AmlStatusType
  subStatus: AmlSubstatusType
  lastUpdate?: string
  answeredBy: string
  dateAnswered?: string
  name: string
  lastName: string
  birthday: string
  zipCode: string
  addressCountry: string
  addressCity: string
  addressStreet: string
  addressNumber: string
  nationality: string
  beneficialOwner: boolean
  identityOwner: {
    name?: string
    lastName?: string
    birthday?: string
    zipCode?: string
    addressCountry?: string
    addressCity?: string
    addressStreet?: string
    addressNumber?: string
    nationality?: string
    isPEP: boolean
    occupation?: string
    salary?: number
    currency: string
    depositFunds?: number
  }
  confirmAccuracy: boolean
  comment?: string
}

export type vetoCheckType = {
  resolved: boolean
}
export type PepSubdecision = {
  decision: string
  type: string
  infoText: string
  value: string
}

export type nonPepCheckType = {
  isPEP: null | boolean
  pepCheckData: {
    decision: string
    decisionText: string
    subdecisions: PepSubdecision[]
  }
}

export type verifiedAddressCheckType = {
  isPEP: boolean
  pepCheckData: {
    decision: string
    decisionText: string
    subdecisions: []
  }
}

export type UpdateKycStatusType = {
  _id: string
  kycStatus: KycStatusType
  playerId: string
  createdAt: string
  updatedAt: string
  createdBy: string
}

export type DocBuffer = {
  data: number[][][]
  type: string
}

export type UploadedDocResponse = {
  buffer: DocBuffer
  encoding: string
  fieldname: string
  mimetype: string
  originalname: string
  size: number
}

export type UtilityDocument = {
  documentExpiry: string
  documentId: string
  documentNumber: string
  issueDate: string
  issuuingAuthority: string
  playerId: string
  type: string
  utilityId: string
}

export type UploadDocumentData = {
  attachedUploads: string[]
  previousStatus: PlayerAccountStatusType
  verified: boolean
}
export type DocumentFormatType = {
  format: DocumentFileFormat
  url?: string
}

export type JumioType = {
  status: JumioVerificationStatus
  created: string
  documentId?: string
  gamanzaStatus: string
  gamanzaId: string
}
export interface DocumentUbiId {
  playerId: string
  caseId: string
  playerFirstName: string
  playerLastName: string
  playerBirthdate: string
  playerNationality: string
  documentFirstName: string
  documentLastName: string
  documentBirthdate: string
  documentNationality: string
}

export interface UbiIDType extends DocumentUbiId {
  status: PlayerDocumentStatusType
  caseIntegrityStatus: UbiIDStatus
  rejectedReason: string
  rejectedAdminId: string
  confirmedAdminId: string
}

export interface PlayerCases extends DocumentUbiId {
  caseIntegrityStatus: UbiIDStatus | string
  inserted: string
  updated: string
  rejectedReason: string
  rejectedAdminId: string
  confirmedAdminId: string
}

export interface CaseFile {
  fileId: string
  fileName: string
  fileSize: number
  mimeType: string
  documentPart?: string
}

export interface SpecificFile extends CaseFile {
  inserted: string
  documentId: number
  fileUrl: string
}

export interface PlayerIbans {
  ibanId: number
  beneficiary: string
  iban: string
  bic: string
  inserted: string
  ibanStatus: PlayerIbansStatusType | string
  status?: PlayerDocumentStatusType | string
  rejectedReason?: PlayerIbansRejectStatusType | string
}

export interface PlayerIbanDocument
  extends Omit<
    PlayerIbans,
    'inserted' | 'status' | 'ibanStatus' | 'rejectedReason'
  > {
  downloadUrl: string
  metadata?: MetaData
}

export type JumioImagesType = {
  front: string
  back: string
}

export type MetaData = {
  contentType: string
  size: string
}

/**
 * ==========================
 * S T A T E   T Y P E
 * ==========================
 */

export interface PlayerCardKYCDocumentState {
  readonly documents: PlayerCardDocumentType[]
  readonly documentPreview: DocumentFormatType
  readonly currentDocument: PlayerCardDocumentType
  readonly documentImages: DocumentImageType[]
  readonly currentDocumentImage: DocumentImageType | {}
  readonly currentDownloadedImage: string
  readonly currentDownloadedSecondImage: string
  readonly jumioData: number[]
  readonly jumioImages: JumioImagesType
  readonly jumioInfo: JumioType
  readonly jumioDoc: DocumentTableColumnProps
  readonly isLoading: boolean
  readonly hasError: boolean
  readonly error: string | Error
  readonly isLoadingTable: boolean
  readonly paginationDocuments: PaginationResponse
  readonly alertsDocuments: Issue[]
  readonly ubiIDDoc: PlayerCases[]
  readonly ubiIDInfo: UbiIDType
  readonly caseFiles: SpecificFile[]
  readonly manualAlertDocuments: Issue[]
  readonly playerIbans: PlayerIbans[]
  readonly playerBankAccountInfo: PlayerIbans
  readonly metaData: MetaData
}

export interface PlayerCardKYCHistoryState {
  readonly nonPepCheck: nonPepCheckType
  readonly verifiedAddressCheck: verifiedAddressCheckType
  readonly vetoCheck: vetoCheckType
  readonly vetoHistory: HistoryType[]
  readonly isLoading: boolean
  readonly nonPepHistory: HistoryType[]
  readonly nonPepCountIssues: IssueCount[]
  readonly teledataHistory: TeleDataHistory[]
  readonly teledataStatus: TeledataStatusType
  readonly playerTeledataHistory: TeleDataHistory[]
}

export interface PlayerCardKYCStatusState {
  readonly aml: {
    status: AmlStatusType
    substatus: AmlSubstatusType
    answer: AmlPlayerAnswerType
  }
  readonly isLoading: boolean
  readonly resolved: boolean
  readonly isAmlLoading: boolean
  readonly lastKycStatus: KycStatusType
  readonly isStatusLoading: boolean
}

/**
 * ==========================
 * P A Y L O A D   T Y P E S
 * ==========================
 */

export type UpdatePlayerKycStatusPayload = {
  playerId: string
  kycStatus: KycStatusType
  createdBy: string
}

export type GetPlayerKycStatusPayload = {
  playerId: string
}

export type GetNonPepHistoryPayload = {
  playerId: string
}

export type UpdatePlayerAmlStatusPayload = {
  id: string
  playerAmlStatus: AmlStatusType
}

export type UpdatePlayerTeledataPayload = {
  id: string
  playerTeleData: PlayerTeledataType
}

export type AddDocumentPayload = {
  id: string
  playerDocuments: Omit<
    PlayerCardDocumentType,
    '_id' | 'createdAt' | 'updatedAt' | 'documentStatus'
  >[]
}

export type UpdateDocument = Omit<
  PlayerCardDocumentType,
  'createdAt' | 'updatedAt' | 'isVisible' | '_id'
>

export interface UpdateDocumentPayload extends UpdateDocument {
  id: string
}

export type DeleteDocumentPayload = {
  id: string
  imageId: string
}

export type CrifCheckPayload = {
  playerId: string
  pep?: boolean
  address?: boolean
}

export type ManualCheckBlacklistPayload = {
  playerId: string
}

export type GetAmlDataPayload = {
  playerId: string
}

export type SetAmlDataPayload = AmlPlayerAnswerType

export type SetAmlStatusPayload = {
  playerId: string
  status: AmlStatusType
  subStatus: AmlSubstatusType
  adminId?: number
}

export type GetDocumentImagesPayload = {
  folderName: string
  requestType: RequestType
  image?: UploadDocumentResponse['storageResponse']
  createDocPayload?: UploadDocumentPayload
  file?: UploadedDocResponse
  documentType?: string
  accountStatus?: PlayerAccountStatusType
  playerId?: string
  user?: { currentOperator: Operator }
}

export type GetJumioDataPayload = {
  playerId: string
}

export type DownloadDocImagePayload = {
  file: string
  folderName: string
}

export type GetTeleDataHistoryPayload = {
  playerId: string
}

export type CreateTeleDataPayload = Omit<
  TeleDataHistory,
  'inserted' | 'id' | 'admin'
>

export type CreateDocumentPayload = Omit<
  PlayerCardDocumentType,
  'createdAt' | 'updatedAt' | '_id' | 'isVisible'
>

export interface DocumentDataInsertPayload {
  playerId: string
  issueType: IssueTypes
  riskLevel: RiskLevel
  operatorId: number
  comment?: string
  documentData: {
    type: string
    documentExpiry: string
    issueDate: string
    documentId: string
    documentNumber: string
    issuingAuthority: string
  }
  resolved: boolean
}

export interface UploadDocumentPayload
  extends Omit<CreateDocumentPayload, 'imageId'> {
  form: FormData
}

export type GetDocumentFilteredPayload = PaginationResponse

export type GetDocumentFilters = {
  imageId: string
  documentType: string
  status: string
  createdBy: string
  days: number
  dateStart: string
  dateEnd: string
  page: number
  limit: number
}

export interface GetDocumentsPayload extends Partial<GetDocumentFilters> {
  playerId: string
}

export interface CreateUtilityIssuePayload
  extends Omit<InsertIssueAdminPayload, 'data' | 'resolved'> {
  comment: string
  data: UploadDocumentData
  resolved?: boolean
}

export type CreateManualUploadPayload = CreateUtilityIssuePayload

export interface GetPlayerCasesPayload {
  playerId: string
}

export interface GetFailedIntegrityCheckPayload {
  page?: number
  size?: number
}

export interface GetCaseFilesPayload {
  caseId: string
  adminId: string
}

export interface GetFailedIntegrityCheckUpdatePayload {
  adminId: string
  ubiidCaseId: string
  firstName: string
  lastName: string
  birthdate: string
  nationality: string
}

export interface GetFailedIntegrityCheckRejectPayload {
  caseId: string
  adminId: string
  rejectedReason: string
}

export interface GetPlayerIbansPayload {
  playerId: string
}

export interface GetPlayerIbanDocumentPayload {
  ibanId: number
  adminId: string
}

export interface DocumentVerifyUpdatePayload {
  playerId: string
  issueType: IssueTypes
  riskLevel: RiskLevel
  operatorId: number
  comment?: string
  resolved: boolean
}

export interface UtilityVerifyUpdatePayload {
  playerId: string
  issueType: IssueTypes
  riskLevel: RiskLevel
  operatorId: number
  comment?: string
  verified: boolean
  resolved: boolean
}

export interface AddressVerifyUpdatePayload {
  playerId: string
  issueType: IssueTypes
  riskLevel: RiskLevel
  operatorId: number
  comment?: string
  verified: boolean
  resolved: boolean
}

export interface UpdatePlayerIbanPayload {
  ibanId: number
  adminId: string
  rejectedReason?: string
}

/**
 * ===========================
 * R E S P O N S E   T Y P E S
 * ===========================
 */

export type GetAmlStatusResponse = {
  response: boolean
  message: AmlPlayerStatusType
}

export type GetAmlDataResponse = {
  response: boolean
  message: AmlPlayerAnswerType
}

export type SetAmlDataResponse = {
  message: string
  resolved: boolean
}

export type SetAmlStatusResponse = {
  message: string
  resolved: boolean
}
export type DocumentImageResponse = [DocumentImageType[]]

export interface GetDocumentImagesResponse {
  images: DocumentImageType[]
  requestType: RequestType
}

export type CrifCheckResponse = {
  error?: WSGamanzaError
  message: nonPepCheckType | verifiedAddressCheckType
  resolved: boolean
}

export type ManualCheckBlacklistResponse = {
  message?: Issue
  error?: WSGamanzaError
  resolved: boolean
}

export type DataJumioType = {
  type: string
  data: number[]
}

export type GetJumioDataResponse = {
  resolved: boolean
  data: DataJumioType
  imageFront: DataJumioType
  imageBack: DataJumioType
}

export type DownloadDocImageResponse = {
  url: string
  type: DocumentFileFormat
}

export type UpdatePlayerKycStatusResponse = {
  createdAt: string
  createdBy: string
  kycStatus: KycStatusType
  playerId: string
  updatedAt: string
  _id: string
}

export type GetPlayerKycStatusResponse = {
  docs: {
    createdAt: string
    createdBy: string
    kycStatus: KycStatusType
    playerId: string
    updatedAt: string
    _id: string
  }[]
  limit: number
  page: number
  pages: number
  total: number
}

export interface GetTeleDataHistoryResponse {
  message: TeleDataHistory[] | null
  resolved: boolean
}

export interface CreateTeleDataResponseBase
  extends Omit<TeleDataHistory, 'timestamp'> {
  color: string
  inserted: string
}

export interface CreateTeleDataResponse {
  message: CreateTeleDataResponseBase
  resolved: boolean
  error: WSGamanzaError
}

export type UtilityDocResponse = {
  id: number
  playerId: string
  issueType: IssueTypes
  riskLevel: number
  resolved: boolean
  created: string
  comment: string
  assignTo: []
  riskManagerId: string
  history: []
  data: DocumentUtilityType
  entrySubType: number
}

export interface CreateDocumentResponse
  extends PlayerCardDocumentType,
    Omit<WSErrorException, 'status'> {
  wsStatus: WSErrorException['status']
}

export type UploadDocumentResponse = {
  msg: string
  storageResponse: UploadedDocResponse[]
}

export interface DeleteDocumentResponse
  extends WSErrorException,
    DeleteDocumentPayload {}

export interface GetDocumentsResponse extends PaginationResponse {
  docs: PlayerCardDocumentType[]
}

export interface CreateUtilityIssueResponse
  extends Omit<InsertIssueAdminResponse, 'error'> {
  error: WSGamanzaError
}
export interface UpdateDocumentResponse
  extends Omit<PlayerCardDocumentType, 'isVisible'>,
    Omit<WSErrorException, 'status'> {
  wsStatus: WSErrorException['status']
}

export interface GetPlayerCasesResponse {
  resolved: boolean
  documents: PlayerCases[]
}

export interface GetFailedIntegrityCheckResponse {
  resolved: boolean
  documents: DocumentUbiId[]
}

export interface GetCaseFilesResponse {
  resolved: boolean
  files: SpecificFile[]
  message?: string
}

export interface GetFailedIntegrityCheckUpdateResponse {
  resolved: boolean
  message: string
}

export interface GetFailedIntegrityCheckRejectResponse {
  resolved: boolean
  message: string
}

export interface GetPlayerIbansResponse {
  resolved: boolean
  requestId: string
  data: {
    message: string
    status: string
    details: {
      ibans: PlayerIbans[]
    }
  }
}

export interface GetPlayerIbanDocumentResponse {
  resolved: boolean
  requestId: string
  data: {
    message: string
    status: string
    details: {
      ibanDocument: PlayerIbanDocument
    }
  }
}

export interface UpdatePlayerIbanResponse {
  resolved: boolean
  requestId: string
  data: {
    message: string
    status: string
    details?: string
  }
}

export interface DocumentVerifyUpdateResponse {
  resolved: boolean
  message: string
  statusADBE: {
    resolved: boolean
    statusCode: number
    statusMessage: string
  }
  data: {
    message: string
    resolved: boolean
  }
}

export interface DocumentDataInsertResponse {
  resolved: boolean
  message: string
}

/**
 * ===============
 *  A C T I O N S
 * ===============
 */

interface AddDocumentSubscriber {
  type: ActionTypes.ADD_DOCUMENT_SUBSCRIBER
  payload: AddDocumentPayload
}

interface UpdateKycStatusSubscriber {
  type: ActionTypes.UPDATE_KYC_STATUS_SUBSCRIBER
  payload: UpdatePlayerKycStatusResponse
}

interface GetKycStatusSubscriber {
  type: ActionTypes.GET_KYC_STATUS_SUBSCRIBER
  payload: GetPlayerKycStatusResponse
}

interface UpdateAmlStatusSubscriber {
  type: ActionTypes.UPDATE_AML_STATUS_SUBSCRIBER
  payload: UpdatePlayerAmlStatusPayload
}

interface GetAmlDataSubscriber {
  type: typeof ActionTypes.AML_GET_ANSWERS_SUBSCRIBER
  payload: GetAmlDataResponse
}

interface SetAmlDataSubscriber {
  type: typeof ActionTypes.AML_SET_ANSWERS_SUBSCRIBER
  payload: SetAmlDataResponse
}

interface SetAmlStatusSubscriber {
  type: typeof ActionTypes.AML_SET_STATUS_SUBSCRIBER
  payload: SetAmlStatusResponse
}

interface GetFailedIntegrityCheckSubscriber {
  type: typeof ActionTypes.GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER
  payload: GetFailedIntegrityCheckResponse
}

interface GetPlayerCasesSubscriber {
  type: typeof ActionTypes.GET_PLAYER_CASES_SUBSCRIBER
  payload: GetPlayerCasesResponse
}

interface GetCaseFilesSubscriber {
  type: typeof ActionTypes.GET_CASE_FILES_SUBSCRIBER
  payload: GetCaseFilesResponse
}

interface FailedIntegrityCheckUpdateSubscriber {
  type: typeof ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER
  payload: GetFailedIntegrityCheckUpdateResponse
}

interface FailedIntegrityCheckRejectSubscriber {
  type: typeof ActionTypes.FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER
  payload: GetFailedIntegrityCheckRejectResponse
}

interface GetPlayerIbansSubscribe {
  type: typeof ActionTypes.GET_PLAYER_IBANS_SUBSCRIBER
  payload: GetPlayerIbansResponse
}

interface GetPlayerIbanDocumentSubscribe {
  type: typeof ActionTypes.GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER
  payload: GetPlayerIbanDocumentResponse
}

interface RejectPlayerIbanSubscribe {
  type: typeof ActionTypes.SET_IBAN_REJECTED_SUBSCRIBER
  payload: UpdatePlayerIbanResponse
}

interface VerifyPlayerIbanSubscribe {
  type: typeof ActionTypes.SET_IBAN_VERIFIED_SUBSCRIBER
  payload: UpdatePlayerIbanResponse
}

export type KYCSocketActions =
  | AddDocumentSubscriber
  | UpdateKycStatusSubscriber
  | UpdateAmlStatusSubscriber
  | GetAmlDataSubscriber
  | SetAmlDataSubscriber
  | SetAmlStatusSubscriber
  | GetKycStatusSubscriber
  | GetPlayerCasesSubscriber
  | GetFailedIntegrityCheckSubscriber
  | GetCaseFilesSubscriber
  | FailedIntegrityCheckUpdateSubscriber
  | FailedIntegrityCheckRejectSubscriber
  | GetPlayerIbansSubscribe
  | GetPlayerIbanDocumentSubscribe
  | RejectPlayerIbanSubscribe
  | VerifyPlayerIbanSubscribe

export type PlayerCardKYCActions = ActionType<typeof actions>
