const bonusCampaigns = {
  bonusCampaigns: 'Bonus Campaigns',
  bonusCampaign: 'Bonus Campaign',
  bonusCampaignName: 'Bonus campaign name',
  bonusCampaignDescription: 'Bonus campaign description',
  theSegmentDeterminesTheGroupOfPlayersInsideThisBonus:
    'The segment determines the group of players inside this bonus',
  selectBonus: 'Select Bonus',
  addBonus: 'Add Bonus',
  endBonusCampaignQuestion: 'Are you sure you want to end this bonus?',
  changeBonusStatusQuestion:
    'Are you sure you want to {{what}} this bonus now?',
  createBonus: 'Create Bonus',
  emptyBonusNameError: 'The bonus name cannot be empty.',
  nameBonusInvalidError: 'Please enter a valid bonus name.',
}

export default bonusCampaigns
