import React from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from 'routes/index.routes'

import Forgot from 'views/pages/forgot/index.page'
import Reset from 'views/pages/reset/index.page'
import Login from 'views/pages/login/index.page'

const UnAuthorizedApp = () => {
  return (
    <Route
      render={({ location }) => {
        return (
          <Switch location={location}>
            <Route exact path={routes.auth.FORGOT} component={Forgot} />
            <Route exact path={routes.auth.RESET} component={Reset} />
            <Route component={Login} />
          </Switch>
        )
      }}
    />
  )
}

export default UnAuthorizedApp
