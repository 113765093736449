import React, { useEffect, useCallback } from 'react'
import i18n from 'i18n'
import { getPlainTextFromHtmlString } from 'utils/wysiwyg/index.util'

import {
  StateSchema,
  ValidationSchema,
  FormCallback,
  PartialValidationSchema,
} from './models'

/**
 * @hook useForm
 *
 * @description Custom Hook to handle Forms state and validation
 *
 * @param {StateSchema} stateSchema
 * @param {ValidationSchema} validationSchema
 * @param {FormCallback} callback
 * @returns an Object containing the state, handleOnChange, handleOnSubmit and disable props
 */
function useForm(
  stateSchema: StateSchema,
  validationSchema: ValidationSchema,
  callback: FormCallback,
) {
  const [state, setState] = React.useState(stateSchema)
  const [validationState, setValidation] = React.useState(validationSchema)
  const [disable, setDisable] = React.useState(true)
  const [isDirty, setIsDirty] = React.useState(false)

  const updateValue = useCallback((newState: StateSchema) => {
    setState(prevState => ({
      ...prevState,
      ...newState,
    }))
  }, [])

  const updateValidationSchema = useCallback(
    (newValidationState: PartialValidationSchema) => {
      Object.keys(validationState).forEach(key => {
        setValidation(prevState => ({
          ...prevState,
          [key]: {
            validators: prevState[key]?.validators,
            required: newValidationState[key]?.required,
          },
        }))
      })
    },
    [validationState],
  )

  /**
   * This function sets the value for all attributes in the state with
   * the ones that receives as params
   * @param newState the new values for the state attributes
   */
  const setFormValues = React.useCallback(
    (newState: StateSchema) => {
      Object.keys(validationState).forEach(key => {
        setState(prevState => ({
          ...prevState,
          [key]: {
            value:
              typeof newState[key]?.value === 'string'
                ? newState[key]?.value.trimStart()
                : newState[key]?.value,
            error: newState[key]?.error,
          },
        }))
      })
    },
    [validationState],
  )

  /**
   * Sets the state to empty values
   */
  const resetForm = React.useCallback(() => {
    Object.keys(validationState).forEach(key => {
      setState(prevState => ({
        ...prevState,
        [key]: { value: '', error: '' },
      }))
    })
  }, [validationState])

  /**
   * Disable button in initial render.
   */
  useEffect(() => {
    setDisable(true)
  }, [])

  /**
   * Used to disable submit button if there's an error in state
   * or the required field in state has no value.
   *
   * Wrapped in useCallback to cached the function to avoid intensive memory leaked
   * in every re-render in component
   */
  const validateState = React.useCallback(() => {
    const hasErrorInState = Object.keys(validationState).some(key => {
      const isInputFieldRequired = validationState[key].required

      let stateValue = state[key] && state[key].value

      if (typeof state[key] === 'string') {
        stateValue =
          state[key] &&
          state[key].value &&
          state[key].value.toString().length > 0
      }

      const stateError = state[key] && state[key].error

      return (
        (isInputFieldRequired &&
          (stateValue === undefined ||
            (stateValue && stateValue.length === 0) ||
            stateValue === null ||
            stateValue === '')) ||
        stateError
      )
    })
    return hasErrorInState
  }, [state, validationState])

  /**
   * For every changed in our state this will be fired
   * To be able to disable the button
   */
  useEffect(() => {
    if (isDirty) {
      setDisable(validateState())
    }
  }, [state, isDirty, validateState])

  const handleOnChange = React.useCallback(
    (name: string, value: any, isHTML?: boolean) => {
      const newValue = isHTML ? getPlainTextFromHtmlString(value) : value

      setIsDirty(true)

      let error = ''
      if (validationState[name] && validationState[name].required) {
        if (
          newValue === undefined ||
          (newValue && newValue.length === 0) ||
          newValue === null ||
          newValue === ''
        ) {
          error = i18n.t('requiredField')
        }
      }

      if (
        validationState[name] &&
        validationState[name].validators !== null &&
        Array.isArray(validationState[name].validators)
      ) {
        validationState[name].validators.forEach((val, index) => {
          if (
            newValue &&
            validationState[name].validators[index].regExp !== undefined &&
            !validationState[name].validators[index].regExp?.test(
              typeof newValue === 'string'
                ? newValue.replace(/\r?\n|\r/g, ' ')
                : newValue,
            )
          ) {
            error = validationState[name].validators[index].error
          }

          if (
            newValue &&
            validationState[name].validators[index].regExpMatch !== undefined &&
            validationState[name].validators[index].regExpMatch?.test(newValue)
          ) {
            error = validationState[name].validators[index].error
          }

          if (
            newValue &&
            validationState[name].validators[index].conditional !== undefined &&
            !validationState[name].validators[index].conditional?.(
              newValue,
              state,
            )
          ) {
            error =
              validationState[name].validators[index].getError !== undefined
                ? validationState[name].validators[index].getError?.(state) ||
                  ''
                : validationState[name].validators[index].error
          }
        })
      }

      setState(prevState => ({
        ...prevState,
        [name]: {
          value: typeof value === 'string' ? value.trimStart() : value,
          error,
        },
      }))
    },
    [validationState, state],
  )

  /**
   * onChange for checkbox
   */
  const handleOnChangeChecked = ({
    currentTarget: { checked, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true)

    let error = ''

    if (validationState[name] && validationState[name].required) {
      if (!checked) {
        error = i18n.t('requiredField')
      }
    }

    if (
      validationState[name] &&
      validationState[name].validators !== null &&
      Array.isArray(validationState[name].validators)
    ) {
      validationState[name].validators.forEach((val, index) => {
        if (
          checked &&
          validationState[name].validators[index].regExp !== undefined &&
          !validationState[name].validators[index].regExp?.test(
            checked ? 'true' : 'false',
          )
        ) {
          error = validationState[name].validators[index].error
        }
        if (
          checked &&
          validationState[name].validators[index].conditional !== undefined &&
          !validationState[name].validators[index].conditional?.(
            checked ? 'true' : 'false',
          )
        ) {
          error = validationState[name].validators[index].error
        }
      })
    }

    setState(prevState => ({
      ...prevState,
      [name]: { value: checked, error },
    }))
  }

  const handleOnSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      if (!validateState()) {
        callback(state)
      }
    },
    [state, validateState, callback],
  )

  return {
    state,
    disable,
    handleOnChange,
    updateValidationSchema,
    handleOnChangeChecked,
    handleOnSubmit,
    resetForm,
    setFormValues,
    updateValue,
    setIsDirty,
    setDisable,
    isDirty,
  }
}

export default useForm
