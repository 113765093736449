import { createReducer } from 'typesafe-actions'
import {
  WalletModels,
  TransactionModels,
  PersonalizationEngineModels,
} from '../models/index.model'
import ActionTypes from '../constants'

import WalletInitialState from './initial-state/wallet-state'
import manualTransactionsHandler from './manual-transactions.handler'
import TransactionsInitialState from './initial-state/transactions-state'

import getWalletHandler from './get-wallet.handler'
import getPaymentsHandler from './get-payments.handler'
import getAllTransactionsHandler from './get-transactions.handler'

import updatePaymentsHandler from './complete-payments.handler'
import getWithdrawalsHandler from './get-withdrawals.handler'
import updateStatusHandler from './update-status.handler'

import createPaymentHandler from './create-payment.handler'
import getGamesByPlayerHandler from './get-games-by-player.handler'
import PersonalizationEngineState from './initial-state/personalization-engine-state'

import getGameCategoriesByPlayerHandler from './get-game-categories-by-player.handler'
import getGameProvidersByPlayerHandler from './get-game-providers-by-player.handler'

/**
 *
 * Wallet main reducer
 *
 */
export const walletReducer = createReducer<
  WalletModels.State,
  WalletModels.SocketActions | WalletModels.Actions
>(WalletInitialState)
  .handleAction(
    [
      ActionTypes.ADD_MANUAL_DEPOSIT,
      ActionTypes.ADD_MANUAL_DEPOSIT_SUBSCRIBER,
      ActionTypes.ADD_MANUAL_WITHDRAWAL,
      ActionTypes.ADD_MANUAL_WITHDRAWAL_SUBSCRIBER,
      ActionTypes.WALLET_RESET,
    ],
    manualTransactionsHandler,
  )
  .handleAction([ActionTypes.GET_WALLET_SUBSCRIBER], getWalletHandler)
  .handleAction(
    [
      ActionTypes.GET_WITHDRAWAL_PAYMENTS,
      ActionTypes.GET_WITHDRAWAL_PAYMENTS_SUBSCRIBER,
      ActionTypes.FILTER_PAYMENTS,
    ],
    getPaymentsHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_PAYMENT_FILE,
      ActionTypes.UPDATE_PAYMENT_FILE_SUBSCRIBER,
    ],
    updatePaymentsHandler,
  )
  .handleAction(
    [
      ActionTypes.SELECT_WITHDRAWALS,
      ActionTypes.FILTER_WITHDRAWALS,
      ActionTypes.GET_WITHDRAWALS,
      ActionTypes.GET_WITHDRAWALS_SUBSCRIBER,
      ActionTypes.GET_WITHDRAWALS_EXCEPTION,
      ActionTypes.SET_IS_DOWNLOADING,
      ActionTypes.GET_ALL_WITHDRAWALS_SUBSCRIBER,
    ],
    getWithdrawalsHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_WITHDRAWALS_STATUS,
      ActionTypes.UPDATE_WITHDRAWALS_STATUS_SUBSCRIBER,
    ],
    updateStatusHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_PAYMENT_FILE,
      ActionTypes.CREATE_PAYMENT_FILE_SUBSCRIBER,
    ],
    createPaymentHandler,
  )

export const transactionsReducer = createReducer<
  TransactionModels.State,
  TransactionModels.TransactionActions
>(TransactionsInitialState).handleAction(
  [
    ActionTypes.GET_ALL_TRANSACTIONS,
    ActionTypes.GET_ALL_TRANSACTIONS_SUBSCRIBER,
    ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS,
    ActionTypes.GET_ALL_DEPOSIT_TRANSACTIONS_SUBSCRIBER,
    ActionTypes.SET_TRANSACTION_FILTERS,
    ActionTypes.GET_ONE_TRANSACTION,
    ActionTypes.GET_ONE_TRANSACTION_SUBSCRIBER,
    ActionTypes.GET_TRANSACTION_EXCEPTION_SUBSCRIBER,
    ActionTypes.SET_TRANSACTION_PLAYER_FILTERS,
    ActionTypes.SET_DOWNLOAD_TRANSACTION_PLAYER,
  ],
  getAllTransactionsHandler,
)

export const personalizationEngineReducer = createReducer<
  PersonalizationEngineModels.PersonalizationEngineState,
  PersonalizationEngineModels.PersonalizationEngineActions
>(PersonalizationEngineState)
  .handleAction(
    [
      ActionTypes.GET_ALL_GAMES_PLAYER,
      ActionTypes.GET_ALL_GAMES_PLAYER_SUBSCRIBER,
      ActionTypes.GET_ALL_GAMES_PLAYER_EXCEPTION_SUBSCRIBER,
      ActionTypes.SET_PERSONALIZATION_ENGINE_FILTERS,
    ],
    getGamesByPlayerHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER,
      ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_SUBSCRIBER,
      ActionTypes.GET_ALL_GAMES_CATEGORIES_PLAYER_EXCEPTION_SUBSCRIBER,
    ],
    getGameCategoriesByPlayerHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER,
      ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_SUBSCRIBER,
      ActionTypes.GET_ALL_GAMES_PROVIDERS_PLAYER_EXCEPTION_SUBSCRIBER,
    ],
    getGameProvidersByPlayerHandler,
  )
