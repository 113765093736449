/* eslint @typescript-eslint/camelcase: 0 */
const segments = {
  ago: 'ago',
  createASegment: 'Create a Segment',
  createASegmentText: 'Start by filling in the information in step 1.',
  createSegment: 'Create Segment',
  createSegmentCSVText:
    'Upload a .csv file with player IDs to define the segment.',
  createSegmentRulesText:
    'Use the following criteria and filters to define the segment.',
  createTrigger: 'Create Trigger',
  criteria: 'Criteria',
  days: 'days',
  daysAgo: 'days ago',
  dynamic: 'Dynamic',
  dynamicTooltip:
    'Customers can enter or exit the segment dynamically based on the defined criteria and their changing values.',
  editSegment: 'Edit Segment',
  filterByCreator: 'Filter By Creator',
  filterByTypes: 'Filter By Types',
  goToSegments: 'Go To Segments',
  playerCountDefaultMessge: 'Pending select file',
  playersTooltip:
    'The segment of customers is created based on a list of player IDs.',
  rules: 'Rules',
  searchSegment: 'Search Segment',
  segment: 'segment',
  segmentCountDisclaimer:
    'If the segment is very large,  this may take up to a couple of minutes.',
  segments: 'Segments',
  segmentsArchive: 'Segments Archive',
  segmentsDetailsText:
    'Add the name, type, tags and description with which you want to save your segment.',
  segmentSettings: 'Segment Settings',
  segmentsLibrary: 'Segments Library',
  selectValue: 'Select Value',
  static: 'Static',
  staticTooltip:
    'The segment of customers is generated once according to the defined criteria and does not change.',
  theSegmentHas: 'The segment has ',
  typeData: 'Type Data',
  verifyApproveSegment: 'I have verified and approved to use this segment',
  withCategory: 'With Category',
  withMinAmount: 'With Min Amount',
  withObservationContext: 'With Observation Context',
  withPriority: 'With Priority',
  withType: 'With Type',
  cardCompanies: {
    AMEX: 'Amex',
    DINERS: 'Diners',
    DISCOVER: 'Discover',
    ISRAELI_CARD: 'IsraeliCard',
    MAESTRO: 'Maestro',
    MASTER_CARD: 'MasterCard',
    MIR: 'MIR',
    SWITCH: 'SWITCH',
    UATP: 'UATP',
    VISA: 'Visa',
  },
  criteriaOptions: {
    accountStatus: 'Account Status',
    action: 'Action',
    actionType: 'Action Type',
    activityDateRange: 'Activity Date Range',
    age: 'Age',
    allowsEmails: 'Allows Emails',
    allowsPhone: 'Allows Phone',
    allowsSMS: 'Allows SMS',
    amount: 'Amount',
    ballyID: 'Bally ID',
    barriereCard: 'Barriere Card',
    barriereCardLevel: 'Barriere Card Level',
    bonusAmount: 'Bonus Amount',
    bonusGrantedAmount: 'Bonus Granted Amount',
    bonusId: 'Bonus',
    bonusMoneyAmount: 'Bonus Money Amount',
    bonusMoneyBalance: 'Bonus Money Balance',
    bonusRedeemCount: 'Bonus Redeem Count',
    bonusReleasedAmount: 'Bonus Released Amount',
    bonusType: 'Bonus Type',
    bonusWelcomeRedeemed: 'Bonus Welcome Redeemed',
    category: 'Category',
    categoryId: 'Tag Category',
    categoryProviderId: 'Game Provider',
    city: 'City',
    context: 'Context',
    count: 'Count',
    countDistinctPaymentDevices: 'Count Distinct Payment Devices',
    country: 'Country',
    currency: 'Currency',
    dateBirth: 'Date Of Birth',
    differenceDaysLastTwoDeposits: 'Days In Last Deposits',
    differenceDaysLastTwoLogins: 'Days In Last Two Logins',
    depositRejectedCount: 'Deposit Rejected Count',
    depositRejectedCountSegment: 'Deposit Rejected Count - Segment',
    depositsWithdrawPAECCurrentRMBalance:
      'Deposits minus Withdrawals (PAEC) minus Current RM Balance',
    email: 'Email',
    firstDepositAmount: 'First Deposit Amount',
    firstDepositDate: 'First Deposit Date',
    firstName: 'First Name',
    freeSpinAmount: 'Free Spin Amount',
    game: 'Game',
    gameCategory: 'Game Category',
    gameCategoryId: 'Game Category',
    gameId: 'Game',
    gameProvider: 'Game Provider',
    games: 'Game',
    gamesession: 'Game Session Duration (Activity Date Range)',
    gamesessionCount: 'Game Session Count (Activity Date Range)',
    gamesessionSegment: 'Game Session Duration - Segment',
    gender: 'Gender',
    grantedBonusAmount: 'Granted Bonus Amount',
    grossGamingRevenue: 'Gross Gaming Revenue',
    grossGamingRevenueDateRange: 'GGR (Activity Date Range)',
    invitationCount: 'Invitation Count',
    documentExpiryDate: 'Document Expiry Date',
    isEmailVerified: 'Email Verified',
    isMobileVerified: 'Mobile Verified',
    issueId: 'Issue Id',
    language: 'Language',
    lastActivityDate: 'Last Activity Date',
    lastCardCompanyUsed: 'Last Card Company Used',
    lastDepositAmount: 'Last Deposit Amount',
    lastDepositDate: 'Last Deposit Date',
    lastLogin: 'Last Login',
    lastName: 'Last Name',
    lastPaymentMethodUsed: 'Last Payment Method Used',
    lastSelfAssessment: 'Last Self Assessment Date',
    lastWithdrawalAmount: 'Last Withdrawal Amount',
    lastWithdrawalDate: 'Last Withdrawal Date',
    limitType: 'Limit Type',
    method: 'Method',
    nameIdentity: 'Name Identity',
    nationality: 'Nationality',
    netLoss: 'Net Loss (Activity Date Range)',
    netLossMinusBonusConverted:
      'Net Loss Minus Bonus Converted (Activity Date Range)',
    netLossSegment: 'Net Loss - Segment',
    netLossMonthDifference: 'Net Loss Month Difference',
    newKYCStatus: 'New KYC Status',
    notesCategory: 'Notes Category',
    notesPriority: 'Notes Priority',
    numberOfActivityDays: 'Number Of Activity Days',
    numberSessions: 'Number Sessions',
    percentage: 'Percentage',
    periodId: 'Period Id',
    phoneNumber: 'Phone Number',
    playerLanguage: 'Player Language',
    playerTag: 'Player Tag',
    postalCode: 'Postal code',
    previousKYCStatus: 'Previous KYC Status',
    priority: 'Priority',
    realMoneyAmount: 'Real Money Amount',
    realMoneyBalance: 'Real Money Balance',
    realTimeNetLoss: 'Real Time Net Loss',
    registrationDate: 'Registration Date',
    remainingNetLoss: 'Remaining Net Loss',
    riskEvaluation: 'Risk Evaluation',
    rtp: 'RTP',
    score: 'Score',
    secondDepositAmount: 'Second Deposit Amount',
    secondDepositDate: 'Second Deposit Date',
    selfAssessmentCount: 'Self Assessment Count',
    selfAssesmentScore: 'Self Assessment Score',
    selfAssesmentScoreSegment: 'Self Assesment Score - Segment',
    subtractDepositsToWithdraws: 'Subtract Deposits From Withdrawals',
    sumDeposits: 'Sum of Deposits',
    sumDepositsSegment: 'Sum of Deposits - Segment',
    sumWithdraws: 'Sum of Withdrawals',
    sumWithdrawsSegment: 'Sum of Withdrawals - Segment',
    tag: 'Tag',
    tagId: 'Tag',
    thirdDepositAmount: 'Third Deposit Amount',
    thirdDepositDate: 'Third Deposit Date',
    totalBetsAmount: 'Total Bets Amount (Activity Date Range)',
    totalBetsCount: 'Total Bets Count (Activity Date Range)',
    totalCoolOff: 'Total Cool Off',
    totalCoolOffSegment: 'Total Cool Off - Segment',
    totalCoolOffWithCategory: 'Total Cool Off With Category',
    totalDailyDepositsCountWithMinAmount:
      'Total Daily Deposit Count With Min Amount',
    totalDailyDepositsCountWithMinAmount_GF:
      'Total Daily Deposit Count With Min Amount - GF',
    totalDailyWithdrawCountWithMinAmount:
      'Total Daily Withdrawal Count With Min Amount',
    totalDailyWithdrawCountWithMinAmount_GF:
      'Total Daily Withdrawal Count With Min Amount - GF',
    totalDepositAmount: 'Total Deposit Amount (Activity Date Range)',
    totalDepositAmount_GF: 'Total Deposit Amount - GF (Activity Date Range)',
    totalDepositAmountLifetime: 'Total Deposit Amount Lifetime',
    totalDepositCount: 'Total Deposit Count (Activity Date Range)',
    totalDepositCountLifetime: 'Total Deposit Count Lifetime',
    totalDepositCountWithMinAmount: 'Total Deposit Count With Min Amount',
    totalLandBaseObservation: 'Total Land Based Observation Requests',
    totalLandBaseObservationContext: 'Total Land Based Observation Context',
    totalLimits: 'Total Limits',
    totalLimitsSegment: 'Total Limits - Segment',
    totalLimitsWithType: 'Total Limits With Type',
    totalNotesCount: 'Total Notes Count',
    totalNotesWithCategory: 'Total Notes With Category',
    totalNotesWithPriority: 'Total Notes With Priority',
    totalVisits: 'Total Visits',
    totalWinsAmount: 'Total Wins Amount (Activity Date Range)',
    totalWinsCount: 'Total Wins Count (Activity Date Range)',
    totalWithdrawalAmount: 'Total Withdrawal Amount (Activity Date Range)',
    totalWithdrawalAmount_GF:
      'Total Withdrawal Amount - GF (Activity Date Range)',
    totalWithdrawalAmount_PAEC: 'PAEC - Total Withdrawal Amount',
    totalWithdrawalAmount_PAECR: 'PAECR - Total Withdrawal Amount',
    totalWithdrawalAmountLifetime: 'Total Withdrawal Amount Lifetime',
    totalWithdrawalCanceled: 'Total Withdrawal Cancelled',
    totalWithdrawalCanceledSegment: 'Total Withdrawal Cancelled - Segment',
    totalWithdrawalCount: 'Total Withdrawal Count (Activity Date Range)',
    totalWithdrawalCountLifetime: 'Total Withdrawal Count Lifetime',
    totalWithdrawalCountWithMinAmount: 'Total Withdrawal Count With Min Amount',
    transactionStatus: 'Transaction Status',
    transactionType: 'Transaction Type',
    turnover: 'Turnover',
    type: 'Type',
    username: 'Username',
    visits: 'Visits',
    wageringRequirement: 'Wagering Requirement',
  },
  criteriaOptionsTooltip: {
    grossGamingRevenue:
      'The GGR value cannot be real time due to performance. Its calculation is made at the end of each day.',
    totalWithdrawalAmount_PAEC:
      'This total amount includes withdrawals in the current statuses: Pending, Approved, Executed and Completed',
    totalWithdrawalAmount_PAECR:
      'This total amount includes withdrawals in the current statuses: Pending, Approved, Executed, Completed and Rejected',
    depositsWithdrawPAECCurrentRMBalance:
      'Total Deposits Amount - Total Withdrawals Amount (Pending, Approved, Executed, Completed) - Current Real Money Balance',
  },
  paymentMethodsSegment: {
    CREDIT_CARD: 'Credit card',
    POST_FINANCE: 'Post Finance',
    POST_FINANCE_EFINANCE: 'Post Finance Efinance',
    TWINT: 'Twint',
    NETELLER: 'Neteller',
    PAYSAFECARD: 'Paysafecard',
    MONEY_BOOKERS: 'Money Bookers',
  },
  segmentationTypeOptions: {
    STATIC: 'Static',
    DYNAMIC: 'Dynamic',
    PLAYERS: 'Players',
  },
  barriereCardLevelOptions: {
    PRIVILEGE: 'Privilege',
    OR: 'Or',
    PLATINE: 'Platine',
    PREMIUM: 'Premium',
    ULTIME: 'Ultime',
  },
}
export default segments
