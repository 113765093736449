/**
 *
 * Template Standard Actions
 *
 */

import { createAction, createCustomAction } from 'typesafe-actions'
import FormStatusType from 'state/enums/form-status.enum'
import ActionTypes from '../constants'

import {
  CurrentTemplateEmail,
  EmailTemplate,
  TemplateEmailFilterProps,
  PopoverProps,
} from '../models'

export const resetTemplatesAction = createAction(
  ActionTypes.RESET_EMAIL_TEMPLATES,
  action => {
    return (emailTemplates: EmailTemplate[]) => action(emailTemplates)
  },
)

export const resetTemplatesArchivedAction = createAction(
  ActionTypes.RESET_TEMPLATES_ARCHIVED,
  action => {
    return (emailTemplatesArchived: EmailTemplate[]) =>
      action(emailTemplatesArchived)
  },
)

export const setCreationModeAction = createAction(
  ActionTypes.SET_CREATION_MODE,
  action => {
    return (mode: string) => action(mode)
  },
)

export const setFormModeAction = createAction(
  ActionTypes.SET_FORM_MODE,
  action => {
    return (mode: FormStatusType) => action(mode)
  },
)

export const setFilterTemplateAction = createAction(
  ActionTypes.SET_FILTERS,
  action => {
    return (filters: TemplateEmailFilterProps) => action(filters)
  },
)

export const setCurrentTemplateAction = createAction(
  ActionTypes.SET_CURRENT_TEMPLATE,
  action => {
    return (currentTemplate: CurrentTemplateEmail) => action(currentTemplate)
  },
)

export const setPopoverAction = createAction(
  ActionTypes.SET_POPOVER,
  action => {
    return (popover: PopoverProps) => action(popover)
  },
)

export const setActiveLanguageAction = createAction(
  ActionTypes.SET_ACTIVE_LANGUAGE,
  action => {
    return (language: string) => action(language)
  },
)

export const setTemplateEmailDialogAction = createCustomAction(
  ActionTypes.SET_TEMPLATE_EMAIL_DIALOG,
  type => (payload: any) => ({
    type,
    payload,
  }),
)
