import { TemplateSmsState } from '../models'

const initialState: TemplateSmsState = {
  error: '',
  isLoading: false,
  templates: [],
  pagination: {
    total: 0,
    pages: 1,
    page: 1,
    limit: 25,
  },
  currentTemplate: {
    active: true,
    companyId: '',
    createdAt: '',
    createdBy: '',
    description: '',
    isDeleted: false,
    name: '',
    templateContents: [],
    updatedAt: '',
    _id: '',
  },
  filters: {
    page: 1,
    limit: 25,
  },
  languagesSelected: [],
}

export default initialState
