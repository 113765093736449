import TriggerType from 'state/enums/trigger.enum'
import { RulesEngineState } from '../models'

const initialState: RulesEngineState = {
  isLoading: false,
  hasError: false,
  wasDeleted: false,
  error: '',
  rules: [],
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    limit: 25,
    page: 1,
  },
  currentRule: {
    createdAt: '',
    createdBy: '',
    description: '',
    isActive: false,
    isDeleted: false,
    name: '',
    trigger: { event: TriggerType.RESET_PASS },
    updatedAt: '',
    _id: '',
    decisionTree: [],
  },
  zendeskAgents: [],
  bonuses: [],
  slackUsers: [],
  slackChannels: [],
}

export default initialState
