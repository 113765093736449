import { fork, all } from 'redux-saga/effects'
import { loginFlow } from '../sagas/login.saga'
import logoutAuthSagasWatcher from './logout.saga'

/**
 * Main Saga for watching saga workers  in the Auth state shape
 *
 * @generator
 */
function* authSagasWatcher() {
  yield all([fork(loginFlow), fork(logoutAuthSagasWatcher)])
}

export default authSagasWatcher
