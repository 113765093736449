import { select, call, put } from 'redux-saga/effects'
import i18n from 'i18n'
import { push } from 'connected-react-router'

import Container from 'typedi'
import ApiService from 'services/api/index.service'
import routes from 'routes/index.routes'

import { setDialogAction, setSnackbarAction } from 'state/ui/global/actions'
import FormStatusType from 'state/enums/form-status.enum'
import { createTemplateEmailAction } from '../actions/rest.actions'

import { setFormModeAction } from '../actions/standard.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the creation of a single email template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof createTemplateEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* createTemplateWorker(
  action: ReturnType<typeof createTemplateEmailAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'templates/email/create',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (response._id) {
      yield put(createTemplateEmailAction.success(response))

      yield put(setFormModeAction(FormStatusType.EDIT))

      yield put(push(`${routes.templates.EDIT}/${response._id}`))

      yield put(setDialogAction({ open: true }))
    } else {
      yield put(
        createTemplateEmailAction.failure({
          name: response.status,
          message: i18n.t('saveEmailError', {
            mode: i18n.t('create').toLocaleLowerCase(),
          }),
        }),
      )

      if (response.status === 404) {
        yield put(
          setSnackbarAction({
            message: i18n.t('templateAlreadyExists', {
              mode: i18n.t('create').toLocaleLowerCase(),
            }),
            open: true,
            variant: 'error',
          }),
        )
      } else if(response.status === 400){
        const errorMessage = Array.isArray(response.message) ?  i18n.t('requiredFieldsMessage', 
        { errors: response.message.toString() }) : i18n.t('requiredFieldsMessage', 
        { mode: i18n.t('create').toLocaleLowerCase()}) 

        yield put(
          setSnackbarAction({
            message: errorMessage,
            open: true,
            variant: 'error',
          }),
        )
      } else {
        yield put(
          setSnackbarAction({
            message: i18n.t('saveEmailError', {
              mode: i18n.t('create').toLocaleLowerCase(),
            }),
            open: true,
            variant: 'error',
          }),
        )
      }
    }
  } catch (error) {
    yield put(createTemplateEmailAction.failure(error))
  }
}

export default createTemplateWorker
