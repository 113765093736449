import { alpha, darken } from '@material-ui/core/styles/colorManipulator'
import { colors } from './colors.theme'

const {
  orangeDark,
  blue,
  redDark,
  green,
  white,
  yellow,
  grayDark,
  lightBlue,
  gray,
  purple,
  grayish,
  orange,
} = colors

export const buttonTheme = {
  root: {
    borderRadius: 20,
    textTransform: 'capitalize' as 'capitalize',
    padding: '6px 20px',
    fontWeight: 'bold' as 'bold',
    minWidth: 100,
    '&.extra-small': {
      minWidth: 0,
      padding: '0 12px',
      lineHeight: 1,
      fontSize: 12,
    },
    '&.square': {
      borderRadius: 0,
    },
    '&.noWidth': {
      minWidth: 0,
    },
    '&.margin-x': {
      marginLeft: 4,
      marginRight: 4,
    },
    '&.margin-y': {
      marginTop: 4,
      marginBottom: 4,
    },
    '&.link': {
      color: blue,
      minWidth: 0,
      padding: 0,
      '& .MuiButton-label': {
        textDecoration: 'underline',
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&.disabled': {
        color: grayDark,
      },
      '&.white': {
        color: alpha(white, 0.7),
      },
    },
    '&.nowrap .MuiButton-label': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
    },
    '&.group': {
      padding: '4px',
      fontWeight: 450,
      '&.left': {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '&.center': {
        borderRadius: 0,
      },
      '&.right': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
      },
    },
    '&.lowercase': {
      textTransform: 'lowercase' as 'lowercase',
    },
  },
  label: {
    wordBreak: 'break-word' as 'break-word',
  },
  text: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&.danger, &.red': {
      color: redDark,
      '&:hover': {
        color: darken(redDark, 0.2),
        backgroundColor: 'transparent',
      },
    },
    '&.success': {
      color: green,
      '&:hover': {
        color: darken(green, 0.2),
        backgroundColor: 'transparent',
      },
    },
    '&.warning': {
      color: yellow,
      '&:hover': {
        color: darken(yellow, 0.2),
        backgroundColor: 'transparent',
      },
    },
    '&.disabled, &$disabled': {
      color: alpha(grayDark, 0.5),
    },
    '&.helper': {
      padding: 0,
      fontWeight: 500,
      minWidth: 'auto',
    },
    '&.group': {
      '&.not-chosen': {
        color: alpha(grayDark, 0.5),
      },
    },
  },
  textPrimary: {
    '&:hover': {
      color: darken(blue, 0.2),
    },
  },
  textSecondary: {
    '&:hover': {
      color: darken(orangeDark, 0.2),
    },
  },
  contained: {
    '&:hover': {
      color: white + ' !important',
    },
    '&.danger, &.red': {
      backgroundColor: redDark,
      color: white,
      '&:hover': {
        backgroundColor: darken(redDark, 0.2),
      },
    },
    '&.success, &.green': {
      backgroundColor: green,
      color: white,
      '&:hover': {
        backgroundColor: darken(green, 0.2),
      },
    },
    '&.warning, &.yellow': {
      backgroundColor: yellow,
      color: white,
      '&:hover': {
        backgroundColor: darken(yellow, 0.2),
      },
    },
    '&.primary, &.blue': {
      backgroundColor: blue,
      color: white,
      '&:hover': {
        backgroundColor: darken(blue, 0.2),
      },
    },
    '&.gray': {
      backgroundColor: gray,
      color: white,
      '&:hover': {
        backgroundColor: darken(gray, 0.2),
      },
    },
    '&.disabled, &$disabled': {
      color: white,
      backgroundColor: grayDark,
      opacity: 0.5,
    },
    '&.action': {
      backgroundColor: lightBlue,
      color: white,
      '&:hover': {
        backgroundColor: darken(lightBlue, 0.2),
      },
    },
    '&.purple': {
      backgroundColor: purple,
      color: white,
      '&:hover': {
        backgroundColor: darken(purple, 0.2),
      },
    },
    '&.warning, &.orange': {
      backgroundColor: orangeDark,
      color: white,
      '&:hover': {
        backgroundColor: darken(orangeDark, 0.2),
      },
    },
    '&.group': {
      '&.not-chosen': {
        color: white,
        backgroundColor: grayDark,
        opacity: 0.5,
      },
      '&.disabled, &$disabled': {
        backgroundColor: blue,
        color: white,
        opacity: 1,
      },
    },
  },
  containedPrimary: {
    backgroundColor: blue,
    '&:hover': {
      backgroundColor: darken(blue, 0.2),
    },
  },
  containedSecondary: {
    backgroundColor: orangeDark,
    '&:hover': {
      backgroundColor: darken(orangeDark, 0.2),
    },
  },
  containedSizeSmall: {
    minWidth: 75,
  },
  outlined: {
    backdropFilter: 'blur(5px)',
    borderColor: alpha(white, 0.3),
    '&.danger, &.red': {
      border: '1px solid' + redDark,
      backgroundColor: alpha(redDark, 0.3),
      color: redDark,
      '&:hover': {
        backgroundColor: alpha(redDark, 0.2),
        border: '1px solid' + darken(redDark, 0.2),
      },
    },
    '&.success': {
      border: '1px solid' + green,
      backgroundColor: alpha(green, 0.3),
      color: green,
      '&:hover': {
        backgroundColor: alpha(green, 0.2),
        border: '1px solid' + darken(green, 0.2),
      },
    },
    '&.warning, &.orange': {
      border: '1px solid' + orange,
      backgroundColor: alpha(orange, 0.3),
      color: orange,
      '&:hover': {
        backgroundColor: alpha(orange, 0.2),
        border: '1px solid' + darken(orange, 0.2),
      },
    },
    '&.abort': {
      border: `1px solid ${grayish}`,
      backgroundColor: alpha(grayish, 0.3),
      color: grayish,
      '&:hover': {
        backgroundColor: `${alpha(grayish, 0.2)}`,
        border: `1px solid ${darken(grayish, 0.2)}`,
      },
    },
    '&.purple': {
      border: `1px solid ${purple}`,
      backgroundColor: alpha(purple, 0.3),
      color: purple,
      '&:hover': {
        backgroundColor: `${alpha(purple, 0.2)}`,
        border: `1px solid ${darken(purple, 0.2)}`,
      },
    },
    '&.primary, &.blue': {
      border: '1px solid' + blue,
      backgroundColor: alpha(blue, 0.3),
      color: blue,
      '&:hover': {
        backgroundColor: alpha(blue, 0.2),
        border: '1px solid' + darken(blue, 0.2),
      },
    },
    '&.disabled, &$disabled': {
      border: 'none',
      color: white,
      backgroundColor: grayDark,
      opacity: 0.5,
    },
    '&.action': {
      border: '1px solid' + lightBlue,
      backgroundColor: alpha(lightBlue, 0.3),
      color: lightBlue,
      '&:hover': {
        backgroundColor: alpha(lightBlue, 0.2),
        border: '1px solid' + darken(lightBlue, 0.2),
      },
      '& .MuiSvgIcon-root': {
        color: lightBlue,
        opacity: 1,
      },
    },
  },
  outlinedPrimary: {
    color: blue,
    background: alpha(blue, 0.3),
    '&:hover': {
      backgroundColor: alpha(blue, 0.2),
    },
  },
  outlinedSecondary: {
    color: orangeDark,
    background: alpha(orangeDark, 0.2),
    '&:hover': {
      backgroundColor: alpha(orangeDark, 0.2),
    },
  },
  outlinedSizeSmall: {
    minWidth: 75,
  },
  endIcon: {
    marginBottom: 2,
  },
}
