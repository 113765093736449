import TransactionStatus from 'state/enums/transaction-status.enum'
import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const formatTransactionId = (withdrawals: WalletModels.WithdrawalModel[]) => {
  return (
    withdrawals?.map(w => ({
      ...w,
      transactionId:
        typeof w.transactionId === 'number'
          ? w.transactionId
          : parseInt(w.transactionId, 10),
    })) || []
  )
}

const getWithdrawalsHandler = (
  state: WalletModels.State,
  action: WalletModels.Actions | WalletModels.SocketActions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.GET_WITHDRAWALS:
      return {
        ...state,
        isLoading: !state.isDownloading,
      }

    case ActionTypes.GET_WITHDRAWALS_SUBSCRIBER: {
      const newState = state.isDownloading
        ? {}
        : {
            withdrawals:
              formatTransactionId(action?.payload?.docs).filter(
                w => w.status !== TransactionStatus.CANCELED,
              ) || [],
            pending:
              formatTransactionId(action?.payload?.docs).filter(
                w => w.status === TransactionStatus.PENDING,
              ) || [],
            approved:
              formatTransactionId(action?.payload?.docs).filter(
                w => w.status === TransactionStatus.APPROVED,
              ) || [],
            pagination: {
              total: action.payload.total || 0,
              limit: action.payload.limit || 25,
              page: action.payload.page || 1,
              pages: action.payload.pages || 0,
            },
          }
      return {
        ...state,
        ...newState,
        isLoading: false,
        isLoadingFull: false,
      }
    }

    case ActionTypes.GET_WITHDRAWALS_EXCEPTION:
      return {
        ...state,
        withdrawals: [],
        pending: [],
        approved: [],
        isLoading: false,
        isLoadingFull: false,
      }

    case ActionTypes.FILTER_WITHDRAWALS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.SELECT_WITHDRAWALS:
      return {
        ...state,
        selected: action.payload.map(item =>
          typeof item === 'number' ? item : parseInt(item, 10),
        ),
      }

    case ActionTypes.SET_IS_DOWNLOADING: {
      return {
        ...state,
        isDownloading: action.payload,
      }
    }


    case ActionTypes.GET_ALL_WITHDRAWALS_SUBSCRIBER: {
      return {
        ...state,
        selected: action.payload.docs.map(transaction =>
          Number(transaction.transactionId),
        ),
        totalWithdrawals: action.payload.docs.length,
      }
    }
    
    default:
      return state
  }
}

export default getWithdrawalsHandler
