import { createReducer } from 'typesafe-actions'
import { TemplateSmsState, TemplateSmsActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import templateSmsGetOneHandler from './get-one.handler'
import templateSmsGetAllHandler from './get-all.handler'

import templateSmsCreateHandler from './create.handler'
import templateSmsUpdateHandler from './update.handler'

const templateSmsReducer = createReducer<TemplateSmsState, TemplateSmsActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.SET_FILTERS,
    ],
    templateSmsGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    templateSmsGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
      ActionTypes.SET_LANGUAGES_SELECTED,
    ],
    templateSmsCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    templateSmsUpdateHandler,
  )

export default templateSmsReducer
