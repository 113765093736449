import ActionTypes from '../constants'
import { TemplateEmailState, TemplateActions } from '../models'

const templateSendEmailHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.SEND_EMAIL_REQUEST:
      return { ...state, isLoading: true }

    case ActionTypes.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.SEND_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload,
      }
    default:
      return state
  }
}

export default templateSendEmailHandler
