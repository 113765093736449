import { select, call, put, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import Container from 'typedi'

import i18n from 'i18n'
import routes from 'routes/index.routes'
import ApiService from 'services/api/index.service'

import {
  setDialogAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { resetPasswordAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the reset password functionality
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof resetPasswordAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* resetPasswordWorker(
  action: ReturnType<typeof resetPasswordAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'auth/resetPassword',
      body: action.payload,
    }

    const { auth, router } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('password'),
      what: i18n.t('messages.reset'),
    })

    if (response?.status === '200') {
      yield put(resetPasswordAction.success(response))
      yield put(setDialogAction({ id: '', open: false }))
      if (router?.location?.pathname === routes.auth.RESET) {
        yield delay(1000)
        yield put(push(routes.auth.LOGIN))
      }
    } else {
      message = response?.message || i18n.t('messages.errorOccurred')

      yield put(
        resetPasswordAction.failure({
          name: response.status,
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response?.status === '200' ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(resetPasswordAction.failure(error))
    yield put(
      setSnackbarAction({
        message: i18n.t('messages.errorOccurred'),
        open: true,
        variant: 'error',
      }),
    )
  }
}

export default resetPasswordWorker
