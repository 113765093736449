import TransactionType from 'state/enums/transaction-type.enum'
import TransactionStatus from 'state/enums/transaction-status.enum'
import CurrencyCode from 'state/enums/currency-code.enum'

import SortType from 'state/enums/sort.enum'
import SortTransactionType from 'state/enums/sort-transaction.enum'
import { TransactionModels } from '../../models/index.model'

const TransactionsInitialState: TransactionModels.State = {
  isLoading: false,
  isLoadingPartial: false,
  isLoadingDrawer: false,
  transactions: [],
  depositTransactions: [],
  isDownloading: false,
  currentTransaction: {
    playerId: '',
    id: 0,
    transactionType: TransactionType.DEPOSIT,
    transactionStatus: TransactionStatus.PENDING,
    date: '',
    dateCompleted: '',
    balanceBefore: '',
    balanceAfter: '',
    currency: CurrencyCode.CHF,
    amount: '',
    number: '',
    method: '',
    cardCompany: '',
    category: '',
    username: '',
    name: '',
    IPAddress: '',
    error: '',
    extend: '',
    provider: '',
    paymentType: '',
    country: '',
    orderId: '',
    comment: '',
    admin: '',
  },
  filters: {
    limit: 10,
    page: 1,
    sortField: SortTransactionType.date,
    sortType: SortType.Desc,
  },
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 1,
  },
  playerFilters: {
    page: 1,
    limit: 10,
    sortField: SortTransactionType.date,
    sortType: SortType.Desc,
  },
}

export default TransactionsInitialState
