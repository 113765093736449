const gamingActivity = {
  gamingActivity: 'Gaming Activity',
  gamingDisplayBy: {
    ROUND: 'Round',
    GAME: 'Game',
    GAME_CATEGORY: 'Game Category',
    GAME_PROVIDER: 'Game Provider',
    GAME_SESSION: 'Game Session',
    PLAYER: 'Player',
  },
  gamingBalance: {
    REAL: 'Real Balance',
    BONUS: 'Bonus Balance',
  },
  gameCategoryOptions: {
    SLOTS: 'Slots',
    DICE_GAMES: 'Dice Games',
    BLACKJACK: 'Blackjack',
    ROULETTE: 'Roulette',
    BACCARAT: 'Baccarat',
    POKER: 'Poker',
    OTHER: 'Other',
  },
  gameProviderOptions: {
    GAMANZA_STUDIOS: 'Gamanza Studios',
    ISOFT_BET: 'iSoftBet',
    FAZI: 'Fazi',
    GREENTUBE: 'GreenTube/Novomatic',
    EVOLUTION_GAMING: 'Evolution Gaming',
    RED_TIGER_GAMING: 'Red Tiger Gaming',
    GAMING1: 'Gaming1',
    NET_ENT: 'NetEnt',
    MICROGAMING: 'Microgaming',
  },
  gamingStatus: {
    ONGOING: 'Ongoing',
    COMPLETED: 'Completed',
    EMPTY: 'Empty',
  },
  displayBy: 'Display by',
  gameCategory: 'Game Category',
  gameProvider: 'Game Provider',
  gameName: 'Game Name',
  gameCode: 'Game Code',
  game: 'Game',
  balance: 'Balance',
  walletType: 'Wallet Type',
  gameSessionId: 'Game Session ID + External Game Session ID',
  dateInitiated: 'Date Initiated',
  dateCompleted: 'Date Completed',
  realMoneyBet: 'Real Money Bet',
  realMoneyWin: 'Real Money Win',
  realMoneyGameWin: 'Real Money GameWin',
  realMoneyStartingBalance: 'Real Money Starting Balance',
  realMoneyEndingBalance: 'Real Money Ending Balance',
  bonusMoneyBet: 'Bonus Money Bet',
  bonusMoneyWin: 'Bonus Money Win',
  bonusMoneyGameWin: 'Bonus Money GameWin',
  bonusMoneyStartingBalance: 'Bonus Money Starting Balance',
  bonusMoneyEndingBalance: 'Bonus Money Ending Balance',
  roundId: 'Round ID',
  internalTransactionId: 'Internal Transaction ID',
  type: 'type',
  status: 'status',
  dateCreated: 'Date Created',
  realBalanceBefore: 'Real Balance Before',
  realBalanceAfter: 'Real Balance After',
  realBalanceAmount: 'Real Balance Amount',
  bonusBalanceBefore: 'Bonus Balance Before',
  bonusBalanceAfter: 'Bonus Balance After',
  bonusBalanceAmount: 'Bonus Balance Amount',
  gameStatus: 'Game Status',
  viewSessionTransactions: 'View session transactions',
  downloadGameSessions:
    'Game Sessions are downloaded from the newest to oldest',
}
export default gamingActivity
