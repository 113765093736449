import BonusStatus from 'state/enums/bonus-status'
import * as BonusModels from '../models'

const BonusInitialState: BonusModels.BonusState = {
  isLoadingDropdown: false,
  isLoadingLogs: false,
  isLoading: false,
  resolved: false,
  allBonuses: [],
  activeBonuses: [],
  completedBonuses: [],
  bonuses: [],
  bonusLogs: [],
  paginationActiveBonus: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  paginationCompletedBonus: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
  filters: {
    status: [BonusStatus.ALL],
  },
}

export default BonusInitialState
