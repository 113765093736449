import KycStatusType from 'state/enums/kyc-status.enum'
import ActionTypes from '../constants'
import {
  PlayerCardKYCStatusState,
  KYCSocketActions,
  PlayerCardKYCActions,
} from '../models'

const statusHandler = (
  state: PlayerCardKYCStatusState,
  action: KYCSocketActions | PlayerCardKYCActions,
): PlayerCardKYCStatusState => {
  switch (action.type) {
    case ActionTypes.UPDATE_KYC_STATUS:
      return {
        ...state,
        isStatusLoading: true,
      }

    case ActionTypes.UPDATE_KYC_STATUS_SUBSCRIBER:
      return {
        ...state,
        isStatusLoading: false,
        lastKycStatus: action.payload?.kycStatus || KycStatusType.UNVERIFIED,
      }

    case ActionTypes.UPDATE_KYC_STATUS_EXCEPTION:
      return {
        ...state,
        isStatusLoading: false,
      }

    case ActionTypes.GET_KYC_STATUS:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_KYC_STATUS_SUBSCRIBER:
      return {
        ...state,
        isLoading: true,
        lastKycStatus:
          action.payload.docs?.[0]?.kycStatus || KycStatusType.UNVERIFIED,
      }

    case ActionTypes.GET_KYC_STATUS_EXCEPTION:
      return {
        ...state,
        isLoading: false,
      }

    case ActionTypes.UPDATE_AML_STATUS_SUBSCRIBER:
      return {
        ...state,
        // TODO set state
      }

    case ActionTypes.AML_GET_ANSWERS_SUBSCRIBER:
      return {
        ...state,
        aml: {
          ...state.aml,
          answer: action.payload.message,
        },
      }

    case ActionTypes.AML_GET_STATUS_SUBSCRIBER:
      return {
        ...state,
        aml: {
          ...state.aml,
          answer: {
            ...state.aml.answer,
            ...action.payload.message,
          },
        },
      }

    case ActionTypes.AML_SET_ANSWERS:
      return {
        ...state,
        isAmlLoading: true,
      }

    case ActionTypes.AML_SET_ANSWERS_SUBSCRIBER:
      return {
        ...state,
        isAmlLoading: false,
        resolved: action.payload.resolved,
      }

    case ActionTypes.AML_SET_STATUS:
      return {
        ...state,
        isAmlLoading: true,
      }

    case ActionTypes.AML_SET_STATUS_SUBSCRIBER:
      return {
        ...state,
        isAmlLoading: false,
        resolved: action.payload.resolved,
      }

    default:
      return state
  }
}

export default statusHandler
