import WS_NAMESPACES from 'state/websocket.namespaces'
import WS_ROUTES from 'state/websocket.routes'
import { createAction, createCustomAction } from 'typesafe-actions'

import PlayerDocumentStatusType from 'state/enums/player-document-status.enum'
import ActionTypes from '../constants'
import * as PlayerCardKYCModels from '../models'

export const setUbiIDInfo = createAction(
  ActionTypes.SET_UBIID_INFO,
  action => (data: PlayerCardKYCModels.UbiIDType) => action(data),
)

export const setUbiIDStatus = createAction(
  ActionTypes.SET_UBIID_STATUS,
  action => {
    return (status: PlayerDocumentStatusType) => {
      return action(status)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYER CASES
 *
 */
export const getPlayerCases = createCustomAction(
  ActionTypes.GET_PLAYER_CASES,
  type => (
    payload: PlayerCardKYCModels.GetPlayerCasesPayload,
    event: typeof WS_ROUTES.KYC.GET_PLAYER_CASES,
    handler?: (data: PlayerCardKYCModels.GetPlayerCasesResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getPlayerCasesSubscribe = createAction(
  ActionTypes.GET_PLAYER_CASES_SUBSCRIBER,
  action => {
    return (response: PlayerCardKYCModels.GetPlayerCasesResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET FAILED INTEGRITY CHECK
 *
 */
export const getFailedIntegrityCheck = createCustomAction(
  ActionTypes.GET_FAILED_INTEGRITY_CHECK,
  type => (
    payload: PlayerCardKYCModels.GetFailedIntegrityCheckPayload,
    event: typeof WS_ROUTES.KYC.GET_FAILED_INTEGRITY_CHECK,
    handler?: (
      data: PlayerCardKYCModels.GetFailedIntegrityCheckResponse,
    ) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getFailedIntegrityCheckSubscribe = createAction(
  ActionTypes.GET_FAILED_INTEGRITY_CHECK_SUBSCRIBER,
  action => {
    return (response: PlayerCardKYCModels.GetFailedIntegrityCheckResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET CASE FILES
 *
 */
export const getCaseFiles = createCustomAction(
  ActionTypes.GET_CASE_FILES,
  type => (
    payload: PlayerCardKYCModels.GetCaseFilesPayload,
    event: typeof WS_ROUTES.KYC.GET_CASE_FILES,
    handler?: (data: PlayerCardKYCModels.GetCaseFilesResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getCaseFilesSubscribe = createAction(
  ActionTypes.GET_CASE_FILES_SUBSCRIBER,
  action => {
    return (response: PlayerCardKYCModels.GetCaseFilesResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] FAILED INTEGRITY CHECK UPDATE
 *
 */
export const getFailedIntegrityCheckUpdate = createCustomAction(
  ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE,
  type => (
    payload: PlayerCardKYCModels.GetFailedIntegrityCheckUpdatePayload,
    event: typeof WS_ROUTES.KYC.FAILED_INTEGRITY_CHECK_UPDATE,
    handler?: (
      data: PlayerCardKYCModels.GetFailedIntegrityCheckUpdateResponse,
    ) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getFailedIntegrityCheckUpdateSubscribe = createAction(
  ActionTypes.FAILED_INTEGRITY_CHECK_UPDATE_SUBSCRIBER,
  action => {
    return (
      response: PlayerCardKYCModels.GetFailedIntegrityCheckUpdateResponse,
    ) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] FAILED INTEGRITY CHECK REJECT
 *
 */
export const getFailedIntegrityCheckReject = createCustomAction(
  ActionTypes.FAILED_INTEGRITY_CHECK_REJECT,
  type => (
    payload: PlayerCardKYCModels.GetFailedIntegrityCheckRejectPayload,
    event: typeof WS_ROUTES.KYC.FAILED_INTEGRITY_CHECK_REJECT,
    handler?: (
      data: PlayerCardKYCModels.GetFailedIntegrityCheckRejectResponse,
    ) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getFailedIntegrityCheckRejectSubscribe = createAction(
  ActionTypes.FAILED_INTEGRITY_CHECK_REJECT_SUBSCRIBER,
  action => {
    return (
      response: PlayerCardKYCModels.GetFailedIntegrityCheckRejectResponse,
    ) => {
      return action(response)
    }
  },
)
