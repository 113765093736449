import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'
import initialState from './initial-state'

const channelEventsHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_CHANNEL_EVENTS_REQUEST:
      return {
        ...state,
        isLoadingEvents: true,
      }

    case ActionTypes.GET_CHANNEL_EVENTS_SUCCESS:
      return {
        ...state,
        isLoadingEvents: false,
        currentChannelEvents: action.payload,
        currentEventsDetail: [...state.currentEventsDetail, action.payload],
      }

    case ActionTypes.GET_CHANNEL_EVENTS_FAILURE:
      return {
        ...state,
        isLoadingEvents: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_ANALYTIC:
      return {
        ...state,
        currentChannelEvents: initialState.currentChannelEvents,
      }

    default:
      return state
  }
}

export default channelEventsHandler
