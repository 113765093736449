import ActionTypes from '../constants'
import { GlobalUiState, GlobalUiActions } from '../models'
import processDialogs from '../util'

const globalUiHandler = (
  state: GlobalUiState,
  action: GlobalUiActions,
): GlobalUiState => {
  switch (action.type) {
    case ActionTypes.SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
        },
      }

    case ActionTypes.SET_DIALOG:
      return {
        ...state,
        dialog: action.payload,
        dialogs: processDialogs(action.payload, state.dialogs),
      }

    case ActionTypes.SET_POPOVER:
      return {
        ...state,
        popover: {
          ...state.popover,
          ...action.payload,
        },
      }

    case ActionTypes.SET_POPOVER_CONFIRM:
      return {
        ...state,
        popoverConfirm: {
          ...state.popoverConfirm,
          ...action.payload,
        },
      }

    case ActionTypes.SET_FORM_MODE:
      return {
        ...state,
        formMode: action.payload,
      }

    case ActionTypes.SET_IS_LOADING_FULL:
      return {
        ...state,
        isLoadingFull: action.payload,
      }

    case ActionTypes.SET_DRAWER:
      return {
        ...state,
        drawer: action.payload,
      }

    case ActionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload,
      }

    case ActionTypes.SET_LANGUAGES_SELECTED:
      return {
        ...state,
        languagesSelected: action.payload,
      }

    case ActionTypes.SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        activeLanguage: action.payload,
      }

    case ActionTypes.SET_INIT:
      return {
        ...state,
        isInit: action.payload,
      }

    default:
      return state
  }
}

export default globalUiHandler
