import ActionTypes from '../constants'
import { SettingsState, SettingsActions } from '../models'

const updateUserSettingsHandler = (
  state: SettingsState,
  action: SettingsActions,
): SettingsState => {
  const { currentTab } = state

  switch (action.type) {
    case ActionTypes.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [currentTab]: {
            ...state.tabs[currentTab],
            currentUserSettings: action.payload,
            firstLoad: false,
          },
        },
      }

    default:
      return state
  }
}

export default updateUserSettingsHandler
