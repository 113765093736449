const tags = {
  tagEventTypeOptions: {
    ASSIGNMENT: 'Assignment',
    UNASSIGNMENT: 'Unassignment',
  },
  allTags: 'All tags',
  loadingTags: 'Loading...',
  noTagsAvailable: 'No tags available',
  tagAssigned: 'Assigned',
  tagUnassigned: 'Unassigned',
  addListToAssign: 'Add the {{what}}s separated by commas.',
}
export default tags
