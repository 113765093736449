import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState } from '../models'
import initialState from './initial-state'

// TODO: double-check store updates
const analyticsGetGroupHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_GROUP_REQUEST:
      return {
        ...state,
        isLoading: !state.isTableLoading,
        isTableLoading: true,
      }

    case ActionTypes.GET_ONE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        isTableLoading: false,
        AnalyticsStatsByGroup: action.payload || {},
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    case ActionTypes.GET_ONE_GROUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_ANALYTIC:
      return {
        ...state,
        AnalyticsStatsByGroup: initialState.AnalyticsStatsByGroup,
      }

      case ActionTypes.SET_IS_TABLE: {
        return {
          ...state,
          isTableLoading: action.payload,
        }
      }

    default:
      return state
  }
}

export default analyticsGetGroupHandler
