const campaigns = {
  channels: {
    EMAIL: 'Send Email',
    SMS: 'Send SMS',
    ON_SITE_NOTIFICATION: 'Onsite Notification',
  },
  campaignStatus: {
    INDESIGN: 'In Design',
    ACTIVE: 'Active',
    SCHEDULED: 'Scheduled',
    ENDED: 'Ended',
    PAUSED: 'Paused',
    PENDING: 'Pending',
    SENT: 'Sent',
    ABORTED: 'Aborted',
    ERROR: 'Error',
    ARCHIVED: 'Archived',
  },
  emailStatus: {
    PROCESSED: 'Processed',
    DROPPED: 'Dropped',
    DELIVERED: 'Delivered',
    DEFERRED: 'Deferred',
    BOUNCE: 'Bounce',
    OPENED: 'Opened',
    SPAM_REPORT: 'Spam Report',
    UNSUBSCRIBE: 'Unsubscribe',
    open: 'open',
    click: 'click',
  },
  recurrenceOptions: {
    DATE: 'Date',
    DAILY: 'Daily',
    WEEKLYDAY: 'Weekday',
    DAYOFMONTH: 'Day of Month',
  },
  triggerTimeOption: {
    now: 'Now',
    day: 'Specific Day',
    recurrence: 'Advanced Recurrence',
  },
  filterByTags: 'Filter By Tags',
  filterByTriggers: 'Filter By Triggers',
  filterByStatus: 'Filter By Status',
  abort: 'abort',
  activate: 'activate',
  addBonus: 'Add Bonus',
  addCampaign: 'Add Campaign',
  addControlGroup: 'Add Control Group',
  addedActions: 'Added Actions',
  addRecurrence: 'Add Recurrence',
  addSegment: 'Add Segment',
  addTrigger: 'Add Trigger',
  advancedRecurrence: 'Advanced Recurrence',
  bonus: 'Bonus',
  bonusChristmasGift: 'Christmas Gift',
  bonusHappyValentine: 'Happy Valentine',
  bonusNewPlayer: 'New player',
  bonusNoGameNoGain: 'No Game No Gain',
  campaigns: 'Campaigns',
  campaignsArchive: 'Campaigns Archive',
  campaign: 'campaign',
  campaignDetails: 'Campaign Details',
  campaignDetailsText:
    'Add the name, tags and description with which you want to save your campaign.',
  campaignId: 'Campaign ID',
  campaignIsCurrently: 'This campaign is currently ',
  campaignIsCurrentlyAction:
    '. If you want to edit it you need to {{action}} it.',
  campaignName: 'Campaign Name',
  campaignSettings: 'Campaign Settings',
  campaignSummary: 'Campaign Summary',
  cancelQuestion:
    'Are you sure you want to leave this page? \n All unsaved data will be lost.',
  captionAbort:
    'This will stop the campaign from being sent and you will not be able to activate it again.',
  captionPause: 'This will stop the campaign from sending further emails.',
  changeStatusQuestion: 'Are you sure you want to {{what}} this campaign now?',
  channel: 'Channel',
  clicksCount: 'Clicks Count',
  controlGroup: 'Control Group',
  controlGroupText:
    'The control group is the one that will not receive any action.  It is used to compare the effectiveness of the other target groups against the behaviour of this control group.',
  controlGroupPercentage: 'Control Group percentage',
  createACampaign: 'Create a campaign',
  createACampaignText: 'Start by filling in the information in step 1.',
  createCampaign: 'Create campaign',
  createNewEmail: 'Create a new email template',
  createTargetGroup: 'Create Target Group',
  currentStatus: 'Current Status',
  customTriggerEvent: 'Custom Trigger Event',
  customTrigger: 'Custom Trigger',
  customTriggerDisable:
    'The selected custom trigger does not exist or is inactive, please review step 2 before continuing.',
  customTriggerRequired:
    'If you want to change the custom trigger, please add or activate at least one in the custom triggers section.',
  dateSendError: "Start date can't be before current time.",
  datesError: "Start date can't be later than end date.",
  design: 'design',
  deleteAction: 'Delete Action',
  deleteGroup: 'Delete Group',
  deliveredTo: 'Delivered to',
  duplicateGroup: 'Duplicate Group',
  editCampaign: 'Edit Campaign',
  email: 'Email',
  emailActivity: 'Email Activity',
  emptyNameError: 'The campaign name cannot be empty.',
  emptySegmentError: 'Please select a segment.',
  emptyTargetError: 'Please add at least one target group.',
  emptyTriggerError: 'Please add an event trigger or a time trigger.',
  equalDatesError: "Start time can't be equal to or later than end time.",
  errorTargetGroupPercentageSum: 'The sum of all percentages must equal 100%.',
  errorTargetGroupTrigger: 'You can only add one target group (100%).',
  eventTrigger: 'Event Trigger',
  goToCampaigns: 'Go To Campaigns',
  lastEventTime: 'Last Event Time',
  launch: 'launch',
  launchNow: 'Launch Now',
  linkABonusOffer: 'Link a Bonus Offer (optional)',
  nameInvalidError: 'Please enter a valid campaign name',
  newCampaign: 'New campaign',
  noTemplates: 'No templates have been added',
  now: 'Now',
  opensCount: 'Opens Count',
  pause: 'pause',
  period: 'Period',
  periodText: 'Select the period for the campaign to remain active.',
  pOfSegment: '% of segment',
  previewTemplate: 'Preview template',
  pushNotification: 'Push Notification',
  removeBonus: 'Remove Bonus',
  removeRecurrence: 'Remove Recurrence',
  saveProgress: 'Save Progress',
  saveProgressConfirm: 'Campaign progress has been saved',
  scheduled: 'Scheduled',
  segmentText:
    'The segment determines the group of players inside the campaign. Once you add a segment, you can’t remove it later.',
  segmentTextNew:
    'The segment determines the group of players inside the campaign. ',
  selectAction: 'Select Action',
  selectActions: 'Select Actions',
  selectDay: 'Select Day',
  selectEventTrigger: 'Select Event Trigger',
  selectHour: 'Select Hour',
  selectOption: 'Select Option',
  selectRecurrence: 'Select Recurrence',
  selectSegment: 'Select Segment',
  selectSegmentOptional: 'Select Segment (Optional)',
  selectTemplate: 'Select Template',
  selectTrigger: 'Select Trigger',
  selectWeekday: 'Select Weekday',
  sendCampaignNow: 'Send Campaign Now',
  sendCampaignOn: 'Send Campaign On',
  sendEmail: 'Send an Email',
  sendSms: 'Send a SMS',
  shippingTime: 'Shipping Time',
  sms: 'Sms',
  specificDay: 'Specific Day',
  tags: 'tags',
  targetGroup: 'Target Group',
  targetGroups: 'Target Groups',
  targetGroupPercentages: 'Target Group percentages',
  targetText:
    'Create Target Groups for subdividing your segment with different actions.',
  templateNotFound: 'Template Not Found',
  thisCampaignWillBeSentOn:
    'This campaign will be sent on {{date}} at {{time}}',
  thisCampaignWillBeSentToTheSegment:
    'This campaign will be sent to the segment: "{{segmentName}}"',
  timeSendInvalidError: 'Please provide a valid send time.',
  timesInvalidError: 'Please provide a valid time format.',
  timeTrigger: 'Time Trigger',
  trigger: 'trigger',
  triggerEvent: 'Trigger Event',
  triggerText: 'An Event or Time Trigger determines when the campaign is sent.',
  triggerType: 'Trigger Type',
  triggerTime: 'TIME_TRIGGER',
  willNotBeSentTo: 'Will not be sent to',
  willBeSentTo: 'Will be sent to',
  messageStatus: 'Message Status',
  timelime: 'Timelime',
}

export default campaigns
