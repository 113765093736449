import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      '&:focus': {
        outline: 'none',
      },
    },
  }),
)

export default useStyles
