const ggrReport = {
  ggrReport: 'GGR Report',
  ggr: 'GGR',
  consolidation: 'Consolidation',
  ggrBonusTotals: 'GGR/Bonus Totals',
  calculation: 'Calculation',
  ggrDate: {
    YESTERDAY: 'Yesterday',
    CURRENT_MONTH: 'Current Month',
    LAST_MONTH: 'Last Month',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    DATE_RANGE: 'Date Range',
  },
  ggrPeriod: {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
    ENTIRE: 'Entire Period',
  },
  totalBetRealMoneyRNG: 'Total Bet Real Money (RNG)',
  totalWinRealMoneyRNG: 'Total Win Real Money (RNG)',
  totalBetRealMoneyLive: 'Total Bet Real Money (Live)',
  totalWinRealMoneyLive: 'Total Win Real Money (Live)',
  wanJackpotIncrements: 'WAN Jackpot Increments',
  jackpotHitsRNG: 'Jackpot Hits RNG',
  totalBetBonusMoney: 'Total Bet Bonus Money',
  totalWinBonusMoney: 'Total Win Bonus Money',
  convertedBonusMoneyAfterPlaythrough:
    'Converted Bonus Money After Playthrough',
  totalGGR: 'Total GGR',
  dateUTC: 'Date (UTC)',
  total: 'Total',
  depositsPSP: 'Deposits PSP',
  manualDeposits: 'Manual Deposits',
  withdrawsProcessed: 'Withdraws Processed',
  withdrawsPending: 'Withdraws Pending',
  manualAdjustments: 'Manual Adjustments',
  realMoneyWalletStartingBalance: 'Real Money Wallet Starting Balance',
  realMoneyWalletEndingBalance: 'Real Money Wallet Ending Balance',
  realMoneyWalletMovement: 'Real Money Wallet Movement',
  tooltipConverted:
    'Bonus Money converts to Real Money after Playthrough is reached. The converted amount is deducted from GGR.',
  tooltipManualAdjustments:
    'Manual Adjustments lead to a difference in the GGR check and need to be recorded and documented for each case.',
  difference: 'Difference',
  bonusAwarded: 'Bonus Awarded',
  canceledBonus: 'Cancelled Bonus',
  bonusStartingBalance: 'Bonus Starting Balance',
  bonusEndingBalance: 'Bonus Ending Balance',
  filterByYear: 'Filter By Year',
  realMoneyWallet: 'Real Money Wallet',
  deposits: 'Deposits',
  totalCasinoResults: 'Total Casino Results',
  betsRNG: 'Bets RNG',
  winRNG: 'Win RNG',
  convertedBonusMoney: 'Converted Bonus Money',
  jackpotsHits: 'Jackpots Hits',
  manualAdjustmentsAsPerSeparateDocumentation:
    'Manual Adjustments As Per Separate Documentation',
  monthName: {
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
  },
  dayName: {
    SUNDAY: 'Sun.',
    MONDAY: 'Mon.',
    TUESDAY: 'Tue.',
    WEDNESDAY: 'Wed.',
    THURSDAY: 'Thu.',
    FRIDAY: 'Fri.',
    SATURDAY: 'Sat.',
  },
  start: 'Start',
  end: 'End',
  delta: 'Delta',
}

export default ggrReport
