import ActionTypes from '../constants'
import { TeledataState, TeledataActions } from '../models'

const teledataHandler = (
  state: TeledataState,
  action: TeledataActions,
): TeledataState => {
  switch (action.type) {
    case ActionTypes.GET_TELEDATA_REPORT:
      return {
        ...state,
        isLoading: true,
      }

    case ActionTypes.GET_TELEDATA_REPORT_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        reports: action.payload?.message?.reports || [],
        pagination: {
          total: action.payload?.message?.pagination?.total || 0,
          limit: action.payload?.message?.pagination?.limit || 25,
          page: action.payload?.message?.pagination?.page || 1,
          pages: action.payload?.message?.pagination?.pages || 0,
        },
      }

    default:
      return state
  }
}

export default teledataHandler
