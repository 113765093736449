import { max, isEqual } from 'date-fns'
import removeDuplicates from 'utils/state/remove-duplicates.util'
import PlayerDocumentType from 'state/enums/player-document-type.enum'

import {
  DocumentImageType,
  PlayerCardDocumentType,
  RequestType,
  PlayerDocumentTypeKey,
} from './models'

/**
 * @description isDocumentUtility
 * * Type guard
 * for checking document of type Utility
 *
 * @param document
 */
export const isDocumentUtility = (
  document: any,
): document is PlayerDocumentTypeKey => {
  return (
    document === PlayerDocumentType[PlayerDocumentType.UTILITY_BILL] ||
    document === PlayerDocumentType[PlayerDocumentType.BANK_STATEMENT] ||
    document === PlayerDocumentType[PlayerDocumentType.LOCAL_AUTHORITY_TAX_BILL]
  )
}

/**
 * * mergeDocumentImages
 *
 * @description
 * Utility function to keep track on deleted documents,
 * to avoid displaying an image that has reference to a document
 * since we can't delete an image right now.
 *
 * @param state - current images state
 * @param docs - docs
 * @param imagesResponse - images
 * @param requestType - either GET or CREATE
 *
 */

export const mergeDocumentImages = (
  state: Partial<DocumentImageType>[],
  docs: PlayerCardDocumentType[],
  imagesResponse: Partial<DocumentImageType>[],
  requestType: RequestType,
) => {
  const dates = imagesResponse.map(doc => new Date(doc.timeCreated as string))

  // get recent date
  const mostRecentDate = max(dates)

  // find the image that matches with the recent date
  const lastImageEntry = imagesResponse?.find(image =>
    isEqual(mostRecentDate, new Date(image.timeCreated as string)),
  )

  if (state.length === 0 && requestType === RequestType.CREATE) {
    return [lastImageEntry]
  }

  const visibleImages = imagesResponse.map(doc => {
    const found = docs?.find(d => {
      return d.imageId === doc.generation
    })

    if (found) {
      return {
        ...doc,
        documentId: found._id,
        isVisible: true,
        status: found.status,
        documentType: found.documentType,
        issueDate: found.documenIssueDate,
        documentExpiry: found.documentExpiryDate,
        documentNumber: found.documentNumber,
        issuuingauthority: found.issuuingAuthority,
      }
    }
    return doc
  })

  return removeDuplicates([...state, ...visibleImages], 'generation')
}

export const mergeDocuments = () => {}
