const BONUS = {
  GET_BONUS_HISTORY: 'bonus/get-player-bonus-history' as 'bonus/get-player-bonus-history',
  ADD_BONUS_PROGRAM: '' as '',
  ADD_BONUS_FREE_ROUNDS: '' as '',
  ADD_BONUS_STANDARD: 'bonus/bonus-add-manual' as 'bonus/bonus-add-manual',
  CANCEL_BONUS: 'bonus/admin/cancel-bonus' as 'bonus/admin/cancel-bonus',
  CHECK_DEPOSIT_HAS_BONUS: 'bonus/check-if-deposit-has-bonus' as 'bonus/check-if-deposit-has-bonus',
  GET_BONUSES: 'bonus/get-bonuses' as 'bonus/get-bonuses',
  ADD_BONUS_DEPOSIT: 'bonus/bonus-add-manual-custom' as 'bonus/bonus-add-manual-custom',
  GET_BONUS_LOGS: 'bonus/get-bonus-logs' as 'bonus/get-bonus-logs',
  GET_EXPIRY_NOTIFICATION: 'bonus/get-expiry-notification-times' as 'get-expiry-notification-times',
  SET_EXPIRY_NOTIFICATION: 'bonus/set-expiry-notification-times' as 'set-expiry-notification-times',
}

export default BONUS
