import { takeLatest } from 'redux-saga/effects'
import { TemplateActions } from 'state/template-onsite'
import sendTestTemplateWorker from './send-test.saga'

import createTemplateOnSiteWorker from './create.saga'
import getOneTemplateWorker from './get-one.sage'
import updateTemplateWorker from './update.saga'

import getAllTemplatesWorker from './get-all.saga'
import archiveTemplateWorker from './archive.saga'
import duplicateTemplateWorker from './duplicate.saga'

function* templateOnSiteSagasWatcher() {
  yield [
    yield takeLatest(
      TemplateActions.sendTestTemplateAction.request,
      sendTestTemplateWorker,
    ),
    yield takeLatest(
      TemplateActions.createTemplateAction.request,
      createTemplateOnSiteWorker,
    ),
    yield takeLatest(
      TemplateActions.getOneTemplateAction.request,
      getOneTemplateWorker,
    ),
    yield takeLatest(
      TemplateActions.updateTemplateAction.request,
      updateTemplateWorker,
    ),
    yield takeLatest(
      TemplateActions.getAllTemplatesAction.request,
      getAllTemplatesWorker,
    ),
    yield takeLatest(
      TemplateActions.archiveTemplateAction.request,
      archiveTemplateWorker,
    ),
    yield takeLatest(
      TemplateActions.duplicateTemplateAction.request,
      duplicateTemplateWorker,
    ),
  ]
}

export default templateOnSiteSagasWatcher
