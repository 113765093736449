import ActionTypes from '../constants'
import { RoleState, RoleActions } from '../models'

const getAllRolesHandler = (
  state: RoleState,
  action: RoleActions,
): RoleState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_REQUEST:
      return {
        ...state,
      }

    case ActionTypes.GET_ALL_SUCCESS:
      return {
        ...state,
      }

    case ActionTypes.GET_ALL_FAILURE:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default getAllRolesHandler
