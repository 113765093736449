import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import removeEmptyProp from 'utils/state/remove-empty-prop.util'
import {
  duplicateCampaignAction,
  getAllCampaignsAction,
} from '../actions/index.actions'

import {
  setLoadingFullAction,
  setSnackbarAction,
} from '../../ui/global/actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the duplication of a single campaign
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof duplicateCampaignAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* duplicateCampaignWorker(
  action: ReturnType<typeof duplicateCampaignAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'POST',
      endpoint: 'campaigns/duplicate/one',
      body: action.payload,
    }

    const { auth, campaign } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('campaign'),
      what: i18n.t('messages.duplicated'),
    })

    if (response._id) {
      yield put(duplicateCampaignAction.success(response))

      yield put(
        getAllCampaignsAction.request(removeEmptyProp(campaign.filters)),
      )
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        duplicateCampaignAction.failure({
          name: response.status,
          message: response.message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(duplicateCampaignAction.failure(error))
  }
}

export default duplicateCampaignWorker
