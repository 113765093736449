import { createReducer } from 'typesafe-actions'
import { TemplatePushState, TemplatePushActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import templatePushGetOneHandler from './get-one.handler'
import templatePushGetAllHandler from './get-all.handler'

import templatePushCreateHandler from './create.handler'
import templatePushUpdateHandler from './update.handler'

const templatePushReducer = createReducer<
  TemplatePushState,
  TemplatePushActions
>(initialState)
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.SET_FILTERS,
    ],
    templatePushGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
    ],
    templatePushGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    templatePushCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    templatePushUpdateHandler,
  )

export default templatePushReducer
