import React from 'react'
import { Box, Backdrop, CircularProgress } from '@material-ui/core'

type Props = {
  open: boolean
}

const FullPageLoader = ({ open }: Props) => {
  return (
    <Box clone zIndex="1500!important">
      <Backdrop open={open}>
        <CircularProgress />
      </Backdrop>
    </Box>
  )
}

export default FullPageLoader
