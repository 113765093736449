import { GamingActivityState } from '../models'

const initialState: GamingActivityState = {
  isLoading: false,
  gameSessions: [],
  gameTransactions: [],
  filtersGameSession: {
    playerId: '',
    pageSize: 10,
    page: 1,
  },
  providerNames: [],
  gameNames: [],
  paginationGameTransaction: {
    totalNumOfItems: 0,
  },
  paginationGameSessions: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 1,
  },
}

export default initialState
