import { createCustomAction, createAction } from 'typesafe-actions'
import WS_ROUTES from 'state/websocket.routes'
import WS_NAMESPACES from 'state/websocket.namespaces'

import { WalletModels, PaymentModels } from '../models/index.model'
import ActionTypes from '../constants'
import { GetWithdrawalsPayload } from '../models/wallet.model'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DEPOSIT MANUAL
 *
 */
export const addManualDepositAction = createCustomAction(
  ActionTypes.ADD_MANUAL_DEPOSIT,
  type => (
    payload: WalletModels.ManualTransactionPayload,
    event: typeof WS_ROUTES.WALLET.DEPOSIT_MANUAL,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const addManualDepositSubscribe = createAction(
  ActionTypes.ADD_MANUAL_DEPOSIT_SUBSCRIBER,
  action => {
    return (response: WalletModels.AddManualDepositResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] WITHDRAWAL MANUAL
 *
 */
export const addManualWithdrawalAction = createCustomAction(
  ActionTypes.ADD_MANUAL_WITHDRAWAL,
  type => (
    payload: WalletModels.ManualTransactionPayload,
    event: typeof WS_ROUTES.WALLET.WITHDRAWAL_MANUAL,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const addManualWithdrawalSubscribe = createAction(
  ActionTypes.ADD_MANUAL_WITHDRAWAL_SUBSCRIBER,
  action => {
    return (response: WalletModels.AddManualDepositResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET WITHDRAWALS
 *
 */
export const getWithdrawals = createCustomAction(
  ActionTypes.GET_WITHDRAWALS,
  type => (
    payload: WalletModels.GetWithdrawalsPayload,
    event: typeof WS_ROUTES.WALLET.GET_WITHDRAWALS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    overwrite: true,
    payload,
    meta: WS_NAMESPACES.WALLET,
    handler,
  }),
)

export const getWithdrawalsSubscribe = createAction(
  ActionTypes.GET_WITHDRAWALS_SUBSCRIBER,
  action => {
    return (response: WalletModels.GetWithdrawalsResponse) => {
      return action(response)
    }
  },
)

export const getWithdrawalsException = createAction(
  ActionTypes.GET_WITHDRAWALS_EXCEPTION,
  action => {
    return (response: WalletModels.GetWithdrawalsResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE WITHDRAWALS STATUS
 *
 */
export const updateWithdrawalsStatus = createCustomAction(
  ActionTypes.UPDATE_WITHDRAWALS_STATUS,
  type => (
    payload: WalletModels.UpdateWithdrawalsStatusPayload,
    event: typeof WS_ROUTES.WALLET.UPDATE_WITHDRAWALS_STATUS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    payload,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    handler,
  }),
)

export const updateWithdrawalsStatusSubscribe = createAction(
  ActionTypes.UPDATE_WITHDRAWALS_STATUS_SUBSCRIBER,
  action => {
    return (response: PaymentModels.ChangeWithdrawalsStatusResponse) => {
      return action(response)
    }
  },
)

export const filterWithdrawals = createAction(
  ActionTypes.FILTER_WITHDRAWALS,
  action => {
    return (filterCriteria: GetWithdrawalsPayload) => {
      return action(filterCriteria)
    }
  },
)

export const selectWithdrawals = createAction(
  ActionTypes.SELECT_WITHDRAWALS,
  action => {
    return (withdrawalIds: (number | string)[]) => {
      return action(withdrawalIds)
    }
  },
)

export const setIsDownloadingAction = createAction(
  ActionTypes.SET_IS_DOWNLOADING,
  action => {
    return (isDownloading: boolean) => action(isDownloading)
  },
)

export const getAllWithdrawals = createCustomAction(
  ActionTypes.GET_ALL_WITHDRAWALS,
  type => (
    payload: WalletModels.GetWithdrawalsPayload,
    event: typeof WS_ROUTES.WALLET.GET_WITHDRAWALS,
    handler?: (data: any) => void,
  ) => ({
    type,
    event,
    emit: true,
    overwrite: true,
    payload,
    meta: WS_NAMESPACES.WALLET,
    handler,
  }),
)

export const getAllWithdrawalsSubscribe = createAction(
  ActionTypes.GET_ALL_WITHDRAWALS_SUBSCRIBER,
  action => {
    return (response: WalletModels.GetWithdrawalsResponse) => {
      return action(response)
    }
  },
)