import { createReducer } from 'typesafe-actions'
import * as LimitModels from '../models'
import LimitsInitialState from './initial-state'

import ActionTypes from '../constants'
import getAllLimitsHandler from './get-all-limits.handler'
import getPendingLimitsHandler from './get-pending-limits.handler'

import setNewLimitHandler from './set-new-limit.handler'
import cancelPendingLimitHandler from './cancel-pending-limit.handler'
import maxLimitsDepositHandler from './max-limits-deposit.handler'

import removeLimitHandler from './remove-limit.handler'

/**
 *
 * Limits main reducer
 *
 */

const limitReducer = createReducer<
  LimitModels.LimitsState,
  LimitModels.LimitActions
>(LimitsInitialState)
  .handleAction(
    [
      ActionTypes.GET_ALL_LIMITS,
      ActionTypes.GET_ALL_LIMITS_SUBSCRIBER,
      ActionTypes.GET_LIMITS_SETTING_LIMITS,
      ActionTypes.GET_LIMITS_SETTING_LIMITS_SUBSCRIBER,
    ],
    getAllLimitsHandler,
  )
  .handleAction(
    [ActionTypes.GET_PENDING_LIMITS, ActionTypes.GET_PENDING_LIMITS_SUBSCRIBER],
    getPendingLimitsHandler,
  )
  .handleAction(
    [ActionTypes.CANCEL_PENDING_LIMIT_SUBSCRIBER],
    cancelPendingLimitHandler,
  )
  .handleAction(
    [
      ActionTypes.SET_NEW_LIMIT_SUBSCRIBER,
      ActionTypes.SET_CURRENT_GAMING_LIMIT,
    ],
    setNewLimitHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_MAX_LIMITS_DEPOSIT,
      ActionTypes.GET_MAX_LIMITS_DEPOSIT_SUBSCRIBER,
    ],
    maxLimitsDepositHandler,
  )
  .handleAction(
    [
      ActionTypes.REMOVE_LIMIT,
      ActionTypes.REMOVE_LIMIT_SUBSCRIBER,
      ActionTypes.SET_GAMING_LIMITS,
    ],
    removeLimitHandler,
  )

export default limitReducer
