import { TagActions, TagState } from '../models'
import ActionTypes from '../constants'

const filtersHandler = (state: TagState, action: TagActions): TagState => {
  switch (action.type) {
    case ActionTypes.SET_FILTERS:
      return {
        ...state,
        filters: { ...action.payload },
      }

    case ActionTypes.SET_PLAYER_TAGS_PAGINATION: {
      return {
        ...state,
        tagPlayersPagination: {
          ...state.tagPlayersPagination,
          ...action.payload,
        },
      }
    }
    default:
      return state
  }
}

export default filtersHandler
