import { StateSchema } from 'hooks/form/models'

const loginSchema: StateSchema = {
  email: {
    value: '',
    error: '',
  },
}

export default loginSchema
