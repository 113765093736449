import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import QueryBuilder from 'services/query-builder/index.service'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'

import removeEmptyProp from 'utils/state/remove-empty-prop.util'
import { deleteUserAction, getAllUserAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the delete of a single user
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof deleteUserAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* deleteUserWorker(
  action: ReturnType<typeof deleteUserAction.request>,
) {
  const { payload } = action

  const queries = new QueryBuilder().query(
    'username',
    `${payload.username}`,
    true,
  ).all

  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'users/delete',
      queries,
    }

    const { auth, user } = yield select()
    const response = yield call(apiService.deleteData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: `${i18n.t('user')} ${payload.username}`,
      what: i18n.t('deleted'),
    })

    if (response.status === '200') {
      yield put(deleteUserAction.success(response))

      yield put(getAllUserAction.request(removeEmptyProp(user.filters)))
    } else {
      message = i18n.t('messages.errorOccurred')

      yield put(
        deleteUserAction.failure({
          name: response?.status,
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response.status === '200' ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(deleteUserAction.failure(error))
  }
}

export default deleteUserWorker
