import { select, call, put } from 'redux-saga/effects'
import i18n from 'i18n'
import { Container } from 'typedi'

import ApiService from 'services/api/index.service'
import { setSnackbarAction } from 'state/ui/global/actions'
import { updateRoleAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga updates a role
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateRoleAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateRoleWorker(
  action: ReturnType<typeof updateRoleAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'users/roles/update',
      body: action.payload,
    }

    const { auth } = yield select()
    const response = yield call(apiService.sendData, auth.token)

    if (response._id) {
      yield put(updateRoleAction.success(response))
      yield put(
        setSnackbarAction({
          message: i18n.t('messages.successfully', {
            item: i18n.t('role'),
            what: i18n.t('updated'),
          }),
          open: true,
          variant: 'success',
        }),
      )
    } else {
      yield put(
        updateRoleAction.failure({
          name: response.status,
          message: response.message,
        }),
      )
      yield put(
        setSnackbarAction({
          message: response.message,
          open: true,
          variant: 'error',
        }),
      )
    }
  } catch (error) {
    yield put(updateRoleAction.failure(error))
  }
}

export default updateRoleWorker
