import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import {
  setDialogAction,
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { getLoggedInAction, updateUserAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the update of a single user
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateUserAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateUserWorker(
  action: ReturnType<typeof updateUserAction.request>,
) {
  yield put(setLoadingFullAction(true))
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'users/update',
      body: action.payload,
    }
    const userSession = sessionStorage.getItem('user')
    let idSession = ''
    if (userSession) {
      idSession = JSON.parse(userSession)._id
    }
    const { auth } = yield select()
    const response = yield call(apiService.updateData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: `${i18n.t('user')} ${action.payload.username}`,
      what: i18n.t('updated'),
    })

    if (response.username) {
      yield put(updateUserAction.success(response))

      if (idSession === response._id) {
        sessionStorage.setItem('user', JSON.stringify(response))
        yield put(getLoggedInAction.success(response))
      }

      yield put(setDialogAction({ open: false }))
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        updateUserAction.failure({
          name: response.status,
          message,
        }),
      )
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response.username ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(updateUserAction.failure(error))
  }
}

export default updateUserWorker
