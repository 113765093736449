import account from './account/index.routes'
import database from './database/index.routes'
import templates from './templates/index.routes'

import styleGuide from './styleguide/index.routes'
import auth from './auth/index.routes'
import users from './users/index.routes'

import clients from './clients/index.routes'
import segments from './segments/index.routes'
import campaigns from './campaigns/index.routes'

import playerCard from './player-card/index.routes'
import search from './search/index.routes'
import withdrawals from './withdrawals/index.routes'

import issues from './issues/index.routes'
import bonusEngine from './bonus-engine/index.routes'
import roles from './roles/index.routes'

import rules from './rules/index.routes'
import reports from './reports/index.routes'
import gamification from './gamification/index.routes'

import userLogs from './user-logs/index.routes'
import referFriend from './refer-friend/index.routes'
import gameStore from './game-store/index.routes'

import ggrReport from './ggr-report/index.routes'
import customTrigger from './custom-trigger/index.routes'
import frequencyCap from './frequency-cap/index.routes'

import bonusReport from './bonus-report/index.routes'
import playerReport from './player-report/index.routes'
import missions from './missions/index.routes'

export default {
  HOME: '/',
  error: '/404',
  account,
  auth,
  bonusEngine,
  bonusReport,
  campaigns,
  customTrigger,
  clients,
  database,
  gamification,
  gameStore,
  ggrReport,
  issues,
  playerCard,
  playerReport,
  referFriend,
  roles,
  rules,
  search,
  segments,
  styleGuide,
  templates,
  users,
  userLogs,
  withdrawals,
  reports,
  frequencyCap,
  missions,
}
