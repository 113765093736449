import ActionTypes from '../constants'
import { AnalyticActions, AnalyticsState, PreviewTemplate } from '../models'
import initialState from './initial-state'

const previewTemplateHandler = (
  state: AnalyticsState,
  action: AnalyticActions,
): AnalyticsState => {
  switch (action.type) {
    case ActionTypes.GET_TEMPLATE_PREVIEW_REQUEST: {
      const previewData = state.currentPreviewTemplates.find(
        (template: PreviewTemplate) =>
          action.payload.messageId === template?.messageId,
      )
      if (previewData) return { ...state }
      return {
        ...state,
        isLoadingTemplatePreview: true,
      }
    }

    case ActionTypes.GET_TEMPLATE_PREVIEW_SUCCESS:
      return {
        ...state,
        isLoadingTemplatePreview: false,
        currentPreviewTemplates: [
          ...state.currentPreviewTemplates,
          action.payload,
        ],
      }

    case ActionTypes.GET_TEMPLATE_PREVIEW_FAILURE:
      return {
        ...state,
        isLoadingTemplatePreview: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_ANALYTIC:
      return {
        ...state,
        currentPreviewTemplates: initialState.currentPreviewTemplates,
      }

    default:
      return state
  }
}

export default previewTemplateHandler
