enum ActionTypes {
  GET_ONE_REQUEST = '@template-push/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@template-push/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@template-push/GET_ONE_FAILURE',

  GET_ALL_REQUEST = '@template-push/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@template-push/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@template-push/GET_ALL_FAILURE',

  CREATE_REQUEST = '@template-push/CREATE_REQUEST',
  CREATE_SUCCESS = '@template-push/CREATE_SUCCESS',
  CREATE_FAILURE = '@template-push/CREATE_FAILURE',

  UPDATE_REQUEST = '@template-push/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@template-push/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@template-push/UPDATE_FAILURE',

  ARCHIVE_REQUEST = '@template-push/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@template-push/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@template-push/ARCHIVE_FAILURE',

  DUPLICATE_REQUEST = '@template-push/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@template-push/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@template-push/DUPLICATE_FAILURE',

  SET_FILTERS = '@template-push/SET_FILTERS',
}

export default ActionTypes
