import { alpha } from '@material-ui/core/styles/colorManipulator'
import { drawerClosedWidth } from 'constants/sidebar/sidebar.constant'
import { colors } from './colors.theme'

const { blueDarkNav, white } = colors

export const appBarTheme = {
  root: {
    '& .MuiToolbar-gutters': {
      paddingLeft: drawerClosedWidth + 25,
      paddingRight: 25,
    },
  },
  colorPrimary: {
    backgroundColor: blueDarkNav,
    borderBottom: `1px solid ${alpha(white, 0.2)}`,
  },
}
