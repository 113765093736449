import ActionTypes from '../constants'
import { SettingsState, SettingsActions } from '../models'

const columnTransferHandler = (
  state: SettingsState,
  action: SettingsActions,
): SettingsState => {
  switch (action.type) {
    case ActionTypes.SELECT_COLUMNS:
      return {
        ...state,
        transferColumns: {
          ...state.transferColumns,
          right: action.payload,
        },
      }

    case ActionTypes.UNSELECT_COLUMNS:
      return {
        ...state,
        transferColumns: {
          ...state.transferColumns,
          left: action.payload,
        },
      }

    default:
      return state
  }
}

export default columnTransferHandler
