export const AvenirLTStdBlack = {
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 800,
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-Black.otf)
  `,
}
export const AvenirLTStdBlackOblique = {
  fontFamily: 'Avenir',
  fontWeight: 800,
  fontStyle: 'italic',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-BlackOblique.otf)
  `,
}
export const AvenirLTStdBook = {
  fontFamily: 'Avenir',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-Book.otf)
  `,
}
export const AvenirLTStBookOblique = {
  fontFamily: 'Avenir',
  fontWeight: 400,
  fontStyle: 'italic',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-BookOblique.otf)
  `,
}
export const AvenirLTStdHeavy = {
  fontFamily: 'Avenir',
  fontWeight: 700,
  fontStyle: 'normal',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-Heavy.otf)
  `,
}
export const AvenirLTStdHeavyOblique = {
  fontFamily: 'Avenir',
  fontWeight: 700,
  fontStyle: 'italic',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-HeavyOblique.otf)
  `,
}

export const AvenirLTStdLight = {
  fontFamily: 'Avenir',
  fontWeight: 300,
  fontStyle: 'normal',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-Light.otf)
  `,
}
export const AvenirLTStdLightOblique = {
  fontFamily: 'Avenir',
  fontWeight: 300,
  fontStyle: 'italic',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-LightOblique.otf)
  `,
}
export const AvenirLTStdMedium = {
  fontFamily: 'Avenir',
  fontWeight: 500,
  fontStyle: 'normal',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-Medium.otf)
  `,
}
export const AvenirLTStdMediumOblique = {
  fontFamily: 'Avenir',
  fontWeight: 500,
  fontStyle: 'italic',
  src: `
    url(${process.env.PUBLIC_URL}/fonts/AvenirLTStd-MediumOblique.otf)
  `,
}
