import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import { setSnackbarAction } from 'state/ui/global/actions'
import { updateTemplateEmailAction } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga handles the updates of a single email template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof updateTemplateEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateTemplateWorker(
  action: ReturnType<typeof updateTemplateEmailAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'templates/email/update/one ',
      body: action.payload,
    }

    const { auth } = yield select()

    const response = yield call(apiService.sendData, auth.token)

    const { status } = response

    if (status === 500 || status === 400 || status === 404) {
      yield put(
        updateTemplateEmailAction.failure({
          name: status,
          message: i18n.t('messages.errorOccurred'),
        }),
      )
      yield put(
        setSnackbarAction({
          message: i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
    } else if (response._id) {
      yield put(updateTemplateEmailAction.success(response))
      yield put(
        setSnackbarAction({
          message: i18n.t('messages.updatedSuccessfully', {
            item: 'template',
          }),
          open: true,
          variant: 'success',
        }),
      )
    }
  } catch (error) {
    yield put(updateTemplateEmailAction.failure(error))
  }
}

export default updateTemplateWorker
