import initialState from 'state/bonus-engine/reducers/initial-state'
import BonusEngineBonusType from 'state/enums/be-bonus-type.enum'
import { BonusEngineActions, BonusEngineState } from '../models'

import ActionTypes from '../constants'

const bonusHandler = (
  state: BonusEngineState,
  action: BonusEngineActions,
): BonusEngineState => {
  const isBonusCampaignView = window.location.pathname.includes(
    '/bonus-engine/campaigns',
  )
  switch (action.type) {
    case ActionTypes.GET_BONUSES:
      return {
        ...state,
        isLoading: !isBonusCampaignView,
      }

    case ActionTypes.GET_BONUSES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        bonuses:
          action.payload.resolved && action.payload.payload.data
            ? action.payload.payload.data.filter(
                offer =>
                  offer.bonusType === BonusEngineBonusType.MONEY ||
                  offer.bonusType === BonusEngineBonusType.FREE_GAMES,
              )
            : [],
      }

    case ActionTypes.SAVE_OFFER_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        currentBonus: {
          ...state.currentBonus,
          freeGameProvider: state.currentBonus.freeGameProvider || 0,
        },
      }

    case ActionTypes.DELETE_OFFER_SUBSCRIBER:
      return {
        ...state,
        bonuses: state.bonuses.filter(
          bonus => bonus.bonusId !== action.payload.payload.data.id,
        ),
      }

    case ActionTypes.SET_CURRENT_BONUS_OFFER:
      return {
        ...state,
        isLoading: false,
        currentBonus: {
          ...(state.bonuses.find(bonus => bonus.bonusId === action.payload) ||
            initialState.currentBonus),
          gameContribution: [], // TODO: Remove when the game contributions do not get in the get-bonuses route
        },
      }

    case ActionTypes.UPDATE_CURRENT_BONUS_OFFER:
      return {
        ...state,
        isLoading: false,
        currentBonus: {
          ...action.payload,
          bonusId: action.payload.id,
          freeGameProvider:
            action.payload.freeGameProvider ||
            state.currentBonus.freeGameProvider ||
            0,
        },
      }

    case ActionTypes.GET_BONUS_ENGINE_GAMES_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        gamesList: action.payload.freeRounds
          ? state.gamesList
          : action.payload.gameList,
        freeGamesList: action.payload.freeRounds
          ? action.payload.gameList.filter(
              game => game.bonusData.supportFreeGames === true,
            )
          : state.gamesList,
      }

    case ActionTypes.GET_GAME_CATEGORIES_SUBSCRIBER:
      const filteredCategories = action.payload.resolved // eslint-disable-line
        ? action.payload.gameCategories.filter(cat => cat.categoryId !== 0)
        : []
      return {
        ...state,
        categoriesList: action.payload.resolved
          ? filteredCategories.map(cat => ({
              ...cat,
              bonusData: {
                ...cat.bonusData,
                wageringContribution: cat.bonusData.wageringContribution * 100,
              },
            }))
          : [],
      }

    case ActionTypes.GET_GAME_PROVIDERS_SUBSCRIBER:
      return {
        ...state,
        providersList: action.payload.resolved
          ? action.payload.providerList.filter(prov => prov.providerId !== 0)
          : [],
      }

    case ActionTypes.GET_WAGERING_CONTRIBUTIONS_SUBSCRIBER:
      return {
        ...state,
        currentBonus: {
          ...state.currentBonus,
          gameContribution: action.payload.payload.data[0].gameContribution,
        },
      }

    case ActionTypes.SET_OFFER_PAGINATION: {
      return {
        ...state,
        offersPagination: {
          ...state.offersPagination,
          ...action.payload,
        },
      }
    }

    case ActionTypes.GET_FREE_GAMES_SETTINGS_SUBSCRIBER:
      return {
        ...state,
        defaultFreeGamesSettings: action.payload.resolved
          ? action.payload.payload.data
          : [],
      }

    case ActionTypes.SET_LANGUAGES_SELECTED:
      return {
        ...state,
        languagesSelected: action.payload,
      }

    case ActionTypes.RESET_CURRENT_BONUS:
      return {
        ...state,
        currentBonus: { ...initialState.currentBonus },
      }

    default:
      return state
  }
}

export default bonusHandler
