import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'
import { updateCustomTriggerAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)
/**
 *
 * This saga update a Custom Trigger
 *
 *
 * @param {ReturnType<typeof updateCustomTriggerAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* updateCustomTriggerWorker(
  action: ReturnType<typeof updateCustomTriggerAction.request>,
) {
  try {
    yield put(setLoadingFullAction(true))

    apiService.requestParams = {
      method: 'PUT',
      endpoint: 'campaigns/triggers/update/one',
      body: action.payload,
    }

    const { auth } = yield select()

    const response = yield call(apiService.updateData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.updatedSuccessfully', {
      item: i18n.t('pages.customTrigger'),
    })

    if (response.id) {
      yield put(updateCustomTriggerAction.success(response))
    } else {
      message = response.message
      yield put(updateCustomTriggerAction.failure(response))
    }

    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response.id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(updateCustomTriggerAction.failure(error))
  }
}

export default updateCustomTriggerWorker
