import { ReferFriendState } from '../models'

const initialState: ReferFriendState = {
  hasError: false,
  isLoading: false,
  error: '',
  wasDeleted: false,
  allInvitations: [],
  pendingInvitations: [],
  approvedInvitations: [],
  rejectedInvitations: [],
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default initialState
