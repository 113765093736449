import { select, put, call } from 'redux-saga/effects'
import Container from 'typedi'
import i18n from 'i18n'

import ApiService from 'services/api/index.service'
import objectToQueryString from 'utils/query-string'
import {
  setLoadingFullAction,
  setSnackbarAction,
} from 'state/ui/global/actions'

import removeEmptyProp from 'utils/state/remove-empty-prop.util'
import {
  archiveTemplateEmailAction,
  getAllEmailTemplatesAction,
} from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga deletes a single email template
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof archiveTemplateEmailAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* archiveTemplateWorker(
  action: ReturnType<typeof archiveTemplateEmailAction.request>,
) {
  yield put(setLoadingFullAction(true))

  try {
    apiService.requestParams = {
      method: 'DELETE',
      endpoint: 'templates/email/archive/one',
      queries: objectToQueryString(action.payload),
    }

    const { auth, templateEmail } = yield select()
    const response = yield call(apiService.deleteData, auth.token)

    yield put(setLoadingFullAction(false))

    let message = i18n.t('messages.successfully', {
      item: i18n.t('template'),
      what: i18n.t(action.payload.isDeleted ? 'deleted' : 'recovered'),
    })

    if (response._id) {
      yield put(archiveTemplateEmailAction.success(response))

      yield put(
        getAllEmailTemplatesAction.request(
          removeEmptyProp(templateEmail.filters),
        ),
      )
    } else {
      message =
        typeof response.message === 'string'
          ? response.message
          : i18n.t('messages.errorOccurred')

      yield put(
        archiveTemplateEmailAction.failure({
          name: response.status,
          message: response.message,
        }),
      )
    }
    yield put(
      setSnackbarAction({
        message,
        open: true,
        variant: response._id ? 'success' : 'error',
      }),
    )
  } catch (error) {
    yield put(setLoadingFullAction(false))
    yield put(archiveTemplateEmailAction.failure(error))
  }
}

export default archiveTemplateWorker
