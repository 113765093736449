import ActionTypes from '../constants'
import { FrequencyCapState, FrequencyCapActions } from '../models'

const frequencyCapGeneralHandler = (
  state: FrequencyCapState,
  action: FrequencyCapActions,
): FrequencyCapState => {
  switch (action.type) {
    case ActionTypes.SET_FREQUENCY_CAP_DIALOG:
      return {
        ...state,
        dialog: action?.payload,
      }

    case ActionTypes.SET_CURRENT_FREQUENCY_CAP:
      return {
        ...state,
        currentFrequencyCap: action.payload || state.currentFrequencyCap,
      }

    default:
      return state
  }
}

export default frequencyCapGeneralHandler
