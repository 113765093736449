import store from 'state'
import PlayerAccountStatusType from 'state/enums/player-account-status.enum'
import i18n from 'i18n'

import WS_ROUTES from 'state/websocket.routes'
import IssueTypes from 'state/enums/issue-type.enum'
import { setSnackbarAction } from 'state/ui/global/actions'

import defaultSnackbarError from 'constants/default-error-snackbar.constant'
import { toggleDocumentDialogAction } from 'state/ui/player-card-kyc/actions/index.actions'
import {
  CreateUtilityIssuePayload,
  CreateUtilityIssueResponse,
} from '../models'

import { isDocumentUtility } from '../utils'
import {
  createUtilityDocument,
  createUtilityDocumentSubscribe,
} from '../actions/sockets-document.actions'
import { setCurrentImageUrl } from '../actions/rest.actions'

type UploadWorkflowPayload = {
  accountStatus: PlayerAccountStatusType
  documentType: string
  fileName: string
  playerId: string
  riskManagerId?: number
  data: {
    documentId?: string
    utilityId?: string
  }
}

const getPayload = (
  { accountStatus, fileName, playerId, riskManagerId }: UploadWorkflowPayload,
  type: string,
) => {
  const text = isDocumentUtility(type) ? 'utility' : 'standard'
  const issueType = isDocumentUtility(type)
    ? IssueTypes.MANUAL_UPLOAD_UTILITY
    : IssueTypes.MANUAL_UPLOAD_DOCUMENT

  // !VALIDATION: client
  const resolved = process.env.REACT_APP_CLIENT === 'PS'

  return {
    comment: i18n.t(`uploadComments.${text}`),
    data: {
      attachedUploads: [fileName],
      previousStatus: accountStatus,
      verified: false,
    },
    issueType,
    playerId,
    resolved,
    ...(isDocumentUtility(type) && { resolved: false }),
    riskLevel: 1,
    riskManagerId: riskManagerId ?? 221,
  }
}

/**
 * @function insertManualUploadUtility
 * @description Creates an issue of type:
 *
 * * IssueTypes.MANUAL_UPLOAD_UTILITY | IssueTypes.MANUAL_UPLOAD_DOCUMENT
 *
 * @param {CreateUtilityIssuePayload} payload
 */
const insertManualUploadUtility = (payload: CreateUtilityIssuePayload) => {
  store.dispatch(
    createUtilityDocument(
      payload,
      WS_ROUTES.KYC.CREATE_UTILITY_ISSUE_DOCUMENT,
      (response: CreateUtilityIssueResponse) => {
        if (response.resolved) {
          store.dispatch(createUtilityDocumentSubscribe(response))
          store.dispatch(
            setSnackbarAction({
              message: i18n.t('messages.successfully', {
                item: i18n.t('uploadComments.issue'),
                what: i18n.t(`messages.created`),
              }),
              open: true,
              variant: 'success',
            }),
          )
          store.dispatch(setCurrentImageUrl(''))
          store.dispatch(toggleDocumentDialogAction(false))
        }

        if (!response.resolved) {
          if (response.error) {
            store.dispatch(
              setSnackbarAction({
                message: response.error.message,
                open: true,
                variant: 'error',
              }),
            )
          } else {
            defaultSnackbarError()
          }
        }
      },
    ),
  )
}

/**
 * @description
 * @param
 */
const insertDocumentIssueWorkflow = (
  uploadWorkflowPayload: UploadWorkflowPayload,
) => {
  const payload = getPayload(
    uploadWorkflowPayload,
    uploadWorkflowPayload.documentType,
  )

  insertManualUploadUtility({
    ...payload,
    data: {
      ...payload.data,
      ...uploadWorkflowPayload.data,
    },
  })
}

export default insertDocumentIssueWorkflow
