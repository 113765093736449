import { createReducer } from 'typesafe-actions'
import { LogActions, LogState } from '../models'
import { initialState } from './initial-state'

import ActionTypes from '../constants'
import timelineLogHandler from './timeline.handler'
import teledataLogHandler from './teledata.handler'

import tagLogHandler from './tag.handler'
import amlLogHandler from './aml.handler'
import coolOffLogHandler from './cool-off.handler'

import limitLogHandler from './limit.handler'
import noteLogHandler from './note.handler'

/**
 * Creates a Log Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
const logReducer = createReducer<LogState, LogActions>(initialState)
  .handleAction(
    [
      ActionTypes.GET_PLAYER_TIMELINE,
      ActionTypes.GET_PLAYER_TIMELINE_SUBSCRIBER,
    ],
    timelineLogHandler,
  )
  .handleAction(
    [ActionTypes.GET_TELEDATA_LOGS, ActionTypes.GET_TELEDATA_LOGS_SUBSCRIBER],
    teledataLogHandler,
  )
  .handleAction(
    [ActionTypes.GET_TAG_LOGS, ActionTypes.GET_TAG_LOGS_SUBSCRIBER],
    tagLogHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_AML_STATUS_LOGS,
      ActionTypes.GET_AML_STATUS_LOGS_SUBSCRIBER,
      ActionTypes.GET_AML_ANSWER_LOGS,
      ActionTypes.GET_AML_ANSWER_LOGS_SUBSCRIBER,
    ],
    amlLogHandler,
  )
  .handleAction(
    [ActionTypes.GET_COOLOFF_LOGS, ActionTypes.GET_COOLOFF_LOGS_SUBSCRIBER],
    coolOffLogHandler,
  )
  .handleAction(
    [ActionTypes.GET_LIMIT_LOGS, ActionTypes.GET_LIMIT_LOGS_SUBSCRIBER],
    limitLogHandler,
  )
  .handleAction(
    [ActionTypes.GET_NOTE_LOGS, ActionTypes.GET_NOTE_LOGS_SUBSCRIBER],
    noteLogHandler,
  )

export default logReducer
