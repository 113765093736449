import { createReducer } from 'typesafe-actions'
import { UserLogsState, UserLogActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getAllUserLogsHandler from './get-all.handler'
import getProductsHandler from './get-products.handler'

/**
 * Creates a User logs Reducer with the:
 *
 * * createReducer factory method
 *
 * @see {@link https://github.com/piotrwitek/typesafe-actions#createreducer|createReducer}
 */
// TODO: Working on separating the actions into different handlers
const userLogsReducer = createReducer<UserLogsState, UserLogActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.GET_ALL_USER_LOG,
      ActionTypes.GET_ALL_USER_LOG_SUBSCRIBER,
      ActionTypes.SET_FILTERS,
    ],
    getAllUserLogsHandler,
  )
  .handleAction(
    [ActionTypes.GET_PRODUCTS, ActionTypes.GET_PRODUCTS_SUBSCRIBER],
    getProductsHandler,
  )

export default userLogsReducer
