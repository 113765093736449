import ActionTypes from '../constants'
import { SettingsState, SettingsActions } from '../models'
import { emptyUserSettings } from '../utils/get-latest-updated-config.util'

import initialState from './initial-state'

const applyUserSettingsHandler = (
  state: SettingsState,
  action: SettingsActions,
): SettingsState => {
  const { currentTab } = state

  switch (action.type) {
    case ActionTypes.APPLY_SETTINGS:
      return {
        ...state,
        isLoading: false,
        tabs: {
          ...state.tabs,
          [currentTab]: {
            currentUserSettings: emptyUserSettings,
            columnNames: action.payload,
            firstLoad: false,
          },
        },
      }

    case ActionTypes.LOAD_SETTINGS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [currentTab]: {
            currentUserSettings: action.payload,
            columnNames:
              action.payload.name !== ''
                ? action.payload.list?.map(item => item.filter) || []
                : state.tabs[currentTab].columnNames,
            firstLoad: false,
          },
        },
      }

    case ActionTypes.REMOVE_COLUMN_SETTINGS:
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [currentTab]: {
            currentUserSettings: emptyUserSettings,
            columnNames: state.tabs[currentTab].columnNames.filter(listItem => {
              return listItem !== action.payload
            }),
            firstLoad: false,
          },
        },
      }

    case ActionTypes.CHANGE_WITHDRAWAL_TYPE:
      return {
        ...state,
        currentTab: action.payload,
      }

    case ActionTypes.RESET_SETTINGS:
      return initialState

    default:
      return state
  }
}

export default applyUserSettingsHandler
