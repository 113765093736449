/**
 *
 * Email Builder Action constants
 *
 * @action UPDATE
 * @action VALIDATIONS
 */

enum ActionTypes {
  UPDATE_EMAILS_INFO = '@ui-email-template/UPDATE_EMAILS_INFO',

  UPDATE_ACTIVE_TAB = '@ui-email-template/UPDATE_ACTIVE_TAB',
  UPDATE_EMAIL_TEST = '@ui-email-template/UPDATE_EMAIL_TEST',
  UPDATE_SNACKBAR = '@ui-email-template/UPDATE_SNACKBAR',

  UPDATE_DIALOG = '@ui-email-template/UPDATE_DIALOG',
  UPDATE_POPOVER = '@ui-email-template/UPDATE_POPOVER',
  UPDATE_LANGUAGES = '@ui-email-template/UPDATE_LANGUAGES',

  SET_ERROR_EMAILS_INFO = '@ui-email-template/SET_ERROR_EMAILS_INFO',
  SET_ERROR_EMAIL_CONFIG = '@ui-email-template/SET_ERROR_EMAILS_CONFIG',
  SET_ERROR_ACTIVE_TAB = '@ui-email-template/SET_ERROR_ACTIVE_TAB',

  SET_ERROR_SNACKBAR = '@ui-email-template/SET_ERROR_SNACKBAR',
  SET_ERROR_EMAIL_TEST = '@ui-email-template/SET_ERROR_EMAIL_TEST',
  VALIDATE = '@ui-email-template/VALIDATE',

  SET_INVALID = '@ui-email-template/SET_INVALID',
  SET_VALID = '@ui-email-template/SET_VALID',
  SUBMIT = '@ui-email-template/SUBMIT',

  SUBMITTING = '@ui-email-template/SUBMITTING',
  SUBMITTED = '@ui-email-template/SUBMITTED',
  SET_FORM_MODE = '@ui-email-template/SET_FORM_MODE',
  RESET_TEMPLATE = '@ui-email-template/RESET_TEMPLATE',
  UPDATE_ID = '@ui-email-template/UPDATE_ID',
}

export default ActionTypes
