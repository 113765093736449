import { createReducer } from 'typesafe-actions'
import { GameStoreState, GameStoreActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import filtersHandler from './filters.handler'
import getGameListHandler from './get-game-list.handler'

import getProvidersHandler from './get-providers.handler'
import getGameHandler from './get-game.handler'
import getGameVersionHandler from './get-game-version.handler'

/**
 *
 * Game Store main reducer
 *
 */
const gameStoreReducer = createReducer<GameStoreState, GameStoreActions>(
  initialState,
)
  .handleAction(
    [
      ActionTypes.SET_FILTERS,
      ActionTypes.SET_GAME_SELECTION,
      ActionTypes.SET_CURRENT_GAME,
      ActionTypes.SET_PAGINATION,
      ActionTypes.GET_GAME_CATEGORIES,
      ActionTypes.GET_GAME_CATEGORIES_SUBSCRIBER,
      ActionTypes.GET_GAME_DEVICES,
      ActionTypes.GET_GAME_DEVICES_SUBSCRIBER,
    ],
    filtersHandler,
  )
  .handleAction(
    [ActionTypes.GET_GAME_LIST, ActionTypes.GET_GAME_LIST_SUBSCRIBER],
    getGameListHandler,
  )
  .handleAction(
    [ActionTypes.GET_GAME_PROVIDERS, ActionTypes.GET_GAME_PROVIDERS_SUBSCRIBER],
    getProvidersHandler,
  )
  .handleAction(
    [ActionTypes.GET_GAME, ActionTypes.GET_GAME_SUBSCRIBER],
    getGameHandler,
  )
  .handleAction(
    [ActionTypes.GET_GAME_VERSION, ActionTypes.GET_GAME_VERSION_SUBSCRIBER],
    getGameVersionHandler,
  )

export default gameStoreReducer
