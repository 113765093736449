enum ActionTypes {
  CREATE_REQUEST = '@segment/CREATE_REQUEST',
  CREATE_SUCCESS = '@segment/CREATE_SUCCESS',
  CREATE_FAILURE = '@segment/CREATE_FAILURE',

  CREATE_CSV_REQUEST = '@segment/CREATE_CSV_REQUEST',
  CREATE_CSV_SUCCESS = '@segment/CREATE_CSV_SUCCESS',
  CREATE_CSV_FAILURE = '@segment/CREATE_CSV_FAILURE',

  GET_ALL_REQUEST = '@segment/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@segment/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@segment/GET_ALL_FAILURE',

  GET_ONE_REQUEST = '@segment/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@segment/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@segment/GET_ONE_FAILURE',

  UPDATE_REQUEST = '@segment/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@segment/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@segment/UPDATE_FAILURE',

  UPDATE_STATUS_REQUEST = '@segment/UPDATE_STATUS_REQUEST',
  UPDATE_STATUS_SUCCESS = '@segment/UPDATE_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE = '@segment/UPDATE_STATUS_FAILURE',

  SET_FILTERS = '@segment/SET_FILTERS',

  CREATE_SUBSCRIBER = '@segment/CREATE_SUBSCRIBER',
  DELETE_SUBSCRIBER = '@segment/DELETE_SUBSCRIBER',
  UPDATE_SUBSCRIBER = '@segment/UPDATE_SUBSCRIBER',

  ARCHIVE_REQUEST = '@segment/ARCHIVE_REQUEST',
  ARCHIVE_SUCCESS = '@segment/ARCHIVE_SUCCESS',
  ARCHIVE_FAILURE = '@segment/ARCHIVE_FAILURE',

  UPDATE_STATUS_SUBSCRIBER = '@segment/UPDATE_STATUS_SUBSCRIBER',

  GET_ALL_ARCHIVE_REQUEST = '@segment/GET_ALL_ARCHIVE_REQUEST',
  GET_ALL_ARCHIVE_SUCCESS = '@segment/GET_ALL_ARCHIVE_SUCCESS',
  GET_ALL_ARCHIVE_FAILURE = '@segment/GET_ALL_ARCHIVE_FAILURE',

  DUPLICATE_REQUEST = '@segment/DUPLICATE_REQUEST',
  DUPLICATE_SUCCESS = '@segment/DUPLICATE_SUCCESS',
  DUPLICATE_FAILURE = '@segment/DUPLICATE_FAILURE',

  RESET_SEGMENTS = '@segment/RESET_SEGMENTS',
  RESET_CURRENT_SEGMENT = '@segment/RESET_CURRENT_SEGMENT',

  PLAYERS_COUNT_REQUEST = '@segment/PLAYERS_COUNT_REQUEST',
  PLAYERS_COUNT_SUCCESS = '@segment/PLAYERS_COUNT_SUCCESS',
  PLAYERS_COUNT_FAILURE = '@segment/PLAYERS_COUNT_FAILURE',
  PLAYERS_COUNT_CANCEL = '@segment/PLAYERS_COUNT_CANCEL',

  SET_PLAYER_COUNT = '@segment/SET_PLAYER_COUNT',
}

export default ActionTypes
