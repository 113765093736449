import ActionTypes from '../constants'
import { PlayerCardState, PlayerCardActions } from '../models'

const setAccountTestHandler = (
  state: PlayerCardState,
  action: PlayerCardActions,
): PlayerCardState => {
  switch (action.type) {
    case ActionTypes.SET_ACCOUNT_TEST:
      return {
        ...state,
      }

    case ActionTypes.SET_ACCOUNT_TEST_SUBSCRIBER:
      return {
        ...state,
        player: {
          ...state.player,
          testAccount: action.payload?.resolved
            ? action.payload?.resolved
            : state.player.testAccount,
        },
      }

    default:
      return state
  }
}

export default setAccountTestHandler
