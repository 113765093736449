import * as TemplatePushReducer from './reducers/index.reducer'
import * as TemplatePushSagas from './sagas/index.saga'
import * as TemplatePushTypes from './constants'

import * as TemplatePushSelectors from './selectors'
import * as TemplatePushModels from './models'
import * as TemplatePushActions from './actions/index.actions'

export {
  TemplatePushSagas,
  TemplatePushTypes,
  TemplatePushSelectors,
  TemplatePushModels,
  TemplatePushActions,
}

export default TemplatePushReducer
