import { createAsyncAction, createAction } from 'typesafe-actions'
import ActionTypes from '../constants'
import {
  CreateSegmentPayload,
  Segmentation,
  UpdateSegmentPayload,
  UpdateSegmentStatusPayload,
  GetAllSegmentFilteredPayload,
  ArchiveSegmentPayload,
  SegmentsResponse,
  DuplicateSegmentPayload,
  GetOneSegmentPayload,
  PlayersCountSegmentPayload,
  PlayersCountSegmentResponse,
} from '../models'

export const createSegmentAction = createAsyncAction(
  ActionTypes.CREATE_REQUEST,
  ActionTypes.CREATE_SUCCESS,
  ActionTypes.CREATE_FAILURE,
)<CreateSegmentPayload, Segmentation, Error>()

export const createSegmentCsvAction = createAsyncAction(
  ActionTypes.CREATE_CSV_REQUEST,
  ActionTypes.CREATE_CSV_SUCCESS,
  ActionTypes.CREATE_CSV_FAILURE,
)<CreateSegmentPayload, Segmentation, Error>()

export const getAllSegmentAction = createAsyncAction(
  ActionTypes.GET_ALL_REQUEST,
  ActionTypes.GET_ALL_SUCCESS,
  ActionTypes.GET_ALL_FAILURE,
)<GetAllSegmentFilteredPayload, SegmentsResponse, Error>()

export const getOneSegmentAction = createAsyncAction(
  ActionTypes.GET_ONE_REQUEST,
  ActionTypes.GET_ONE_SUCCESS,
  ActionTypes.GET_ONE_FAILURE,
)<GetOneSegmentPayload, Segmentation, Error>()

export const updateSegmentAction = createAsyncAction(
  ActionTypes.UPDATE_REQUEST,
  ActionTypes.UPDATE_SUCCESS,
  ActionTypes.UPDATE_FAILURE,
)<UpdateSegmentPayload, Segmentation, Error>()

export const updateStatusSegmentAction = createAsyncAction(
  ActionTypes.UPDATE_STATUS_REQUEST,
  ActionTypes.UPDATE_STATUS_SUCCESS,
  ActionTypes.UPDATE_STATUS_FAILURE,
)<UpdateSegmentStatusPayload, Segmentation, Error>()

export const archiveSegmentAction = createAsyncAction(
  ActionTypes.ARCHIVE_REQUEST,
  ActionTypes.ARCHIVE_SUCCESS,
  ActionTypes.ARCHIVE_FAILURE,
)<ArchiveSegmentPayload, Segmentation, Error>()

export const getAllSegmentsArchiveAction = createAsyncAction(
  ActionTypes.GET_ALL_ARCHIVE_REQUEST,
  ActionTypes.GET_ALL_ARCHIVE_SUCCESS,
  ActionTypes.GET_ALL_ARCHIVE_FAILURE,
)<GetAllSegmentFilteredPayload, SegmentsResponse, Error>()

export const duplicateSegmentAction = createAsyncAction(
  ActionTypes.DUPLICATE_REQUEST,
  ActionTypes.DUPLICATE_SUCCESS,
  ActionTypes.DUPLICATE_FAILURE,
)<DuplicateSegmentPayload, Segmentation, Error>()

export const playersCountSegmentAction = createAsyncAction(
  ActionTypes.PLAYERS_COUNT_REQUEST,
  ActionTypes.PLAYERS_COUNT_SUCCESS,
  ActionTypes.PLAYERS_COUNT_FAILURE,
  ActionTypes.PLAYERS_COUNT_CANCEL,
)<PlayersCountSegmentPayload, PlayersCountSegmentResponse, Error, {}>()

export const resetSegmentsAction = createAction(ActionTypes.RESET_SEGMENTS)

export const resetSegmentAction = createAction(
  ActionTypes.RESET_CURRENT_SEGMENT,
)

export const setFilterSegmentAction = createAction(
  ActionTypes.SET_FILTERS,
  actions => {
    return (filters: GetAllSegmentFilteredPayload) => actions(filters)
  },
)

export const setPlayerCount = createAction(
  ActionTypes.SET_PLAYER_COUNT,
  action => {
    return (payload: string[]) => action(payload)
  },
)
