import { createReducer } from 'typesafe-actions'
import { SegmentState, SegmentActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import segmentCreateHandler from './create.handler'
import segmentGetAllHandler from './get-all.handler'

import segmentGetOneHandler from './get-one.handler'
import segmentUpdateHandler from './update.handler'
import segmentUpdateStatusHandler from './update-status.handler'

import segmentGetAllArchiveHandler from './get-all-archive.handler'
import segmentGeneralHandler from './general.handler'
import segmentPlayersCountHandler from './players-count.handler'

const segmentReducer = createReducer<SegmentState, SegmentActions>(initialState)
  .handleAction(
    [
      ActionTypes.CREATE_REQUEST,
      ActionTypes.CREATE_SUCCESS,
      ActionTypes.CREATE_FAILURE,
    ],
    segmentCreateHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_REQUEST,
      ActionTypes.GET_ALL_SUCCESS,
      ActionTypes.GET_ALL_FAILURE,
      ActionTypes.RESET_SEGMENTS,
    ],
    segmentGetAllHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_REQUEST,
      ActionTypes.GET_ONE_SUCCESS,
      ActionTypes.GET_ONE_FAILURE,
      ActionTypes.RESET_CURRENT_SEGMENT,
    ],
    segmentGetOneHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_REQUEST,
      ActionTypes.UPDATE_SUCCESS,
      ActionTypes.UPDATE_FAILURE,
    ],
    segmentUpdateHandler,
  )
  .handleAction(
    [
      ActionTypes.UPDATE_STATUS_REQUEST,
      ActionTypes.UPDATE_STATUS_SUCCESS,
      ActionTypes.UPDATE_STATUS_FAILURE,
    ],
    segmentUpdateStatusHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ALL_ARCHIVE_REQUEST,
      ActionTypes.GET_ALL_ARCHIVE_SUCCESS,
      ActionTypes.GET_ALL_ARCHIVE_FAILURE,
    ],
    segmentGetAllArchiveHandler,
  )
  .handleAction(
    [ActionTypes.SET_FILTERS, ActionTypes.SET_PLAYER_COUNT],
    segmentGeneralHandler,
  )
  .handleAction(
    [
      ActionTypes.PLAYERS_COUNT_REQUEST,
      ActionTypes.PLAYERS_COUNT_SUCCESS,
      ActionTypes.PLAYERS_COUNT_FAILURE,
      ActionTypes.PLAYERS_COUNT_CANCEL,
    ],
    segmentPlayersCountHandler,
  )

export default segmentReducer
