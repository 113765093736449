import ActionTypes from '../constants'
import { PlayerCardLoginActions, PlayerCardLoginState } from '../models'

const playerCardLoginGetAllHandler = (
  state: PlayerCardLoginState,
  action: PlayerCardLoginActions,
): PlayerCardLoginState => {
  switch (action.type) {
    case ActionTypes.GET_ALL:
      return { ...state, isLoading: true }
    case ActionTypes.GET_ALL_SUBSCRIBER:
      return {
        ...state,
        isLoading: false,
        playerLogins: action.payload.docs || [],
        pagination: {
          total: action.payload.total || 0,
          limit: action.payload.limit || 10,
          page: action.payload.page || 1,
          pages: action.payload.pages || 0,
        },
      }
    default:
      return state
  }
}

export default playerCardLoginGetAllHandler
