/**
 *
 * Role constants
 *
 */

enum ActionTypes {
  SET_FILTERS = '@role/SET_FILTERS',

  GET_ONE_REQUEST = '@role/GET_ONE_REQUEST',
  GET_ONE_SUCCESS = '@role/GET_ONE_SUCCESS',
  GET_ONE_FAILURE = '@role/GET_ONE_FAILURE',

  GET_ALL_REQUEST = '@role/GET_ALL_REQUEST',
  GET_ALL_SUCCESS = '@role/GET_ALL_SUCCESS',
  GET_ALL_FAILURE = '@role/GET_ALL_FAILURE',

  CREATE_REQUEST = '@role/CREATE_REQUEST',
  CREATE_SUCCESS = '@role/CREATE_SUCCESS',
  CREATE_FAILURE = '@role/CREATE_FAILURE',

  DELETE_REQUEST = '@role/DELETE_REQUEST',
  DELETE_SUCCESS = '@role/DELETE_SUCCESS',
  DELETE_FAILURE = '@role/DELETE_FAILURE',

  UPDATE_REQUEST = '@role/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@role/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@role/UPDATE_FAILURE',

  GET_ROLE_USERS_REQUEST = '@role/GET_ROLE_USERS_REQUEST',
  GET_ROLE_USERS_SUCCESS = '@role/GET_ROLE_USERS_SUCCESS',
  GET_ROLE_USERS_FAILURE = '@role/GET_ROLE_USERS_FAILURE',
}

export default ActionTypes
