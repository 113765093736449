import ActionTypes from '../constants'
import { createSocketReaderAction } from '../../middleware/sockets/utils'
import {
  UserResponse,
  DeleteSubscriberResponse,
  UpdateStatusSubscriberResponse,
} from '../models'

import WS_ROUTES from '../../websocket.routes'
import WS_NAMESPACES from '../../websocket.namespaces'

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] CREATE
 *
 */
export const createUserSubscriber = () =>
  createSocketReaderAction<UserResponse, typeof ActionTypes.CREATE_SUBSCRIBER>(
    WS_ROUTES.USER.CREATE,
    WS_NAMESPACES.USER,
    ActionTypes.CREATE_SUBSCRIBER,
  )

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE
 *
 */
export const updateUserSubscriber = () =>
  createSocketReaderAction<UserResponse, typeof ActionTypes.UPDATE_SUBSCRIBER>(
    WS_ROUTES.USER.UPDATE,
    WS_NAMESPACES.USER,
    ActionTypes.UPDATE_SUBSCRIBER,
  )

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] UPDATE_STATUS
 *
 */
export const updateStatusUserSubscriber = () =>
  createSocketReaderAction<
    UpdateStatusSubscriberResponse,
    typeof ActionTypes.UPDATE_STATUS_SUBSCRIBER
  >(
    WS_ROUTES.USER.UPDATE_STATUS,
    WS_NAMESPACES.USER,
    ActionTypes.UPDATE_STATUS_SUBSCRIBER,
  )

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] DELETE
 *
 */
export const deleteUserSubscriber = () =>
  createSocketReaderAction<
    DeleteSubscriberResponse,
    typeof ActionTypes.DELETE_SUBSCRIBER
  >(WS_ROUTES.USER.DELETE, WS_NAMESPACES.USER, ActionTypes.DELETE_SUBSCRIBER)
