import { PlayerCardLoginState } from '../models'

const initialState: PlayerCardLoginState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  isLoadingPartial: false,
  isLoadingFull: false,
  playerLogins: [],
  pagination: {
    total: 0,
    limit: 10,
    page: 1,
    pages: 0,
  },
}

export default initialState
