import { createReducer } from 'typesafe-actions'
import AnnexeInitialState from './initial-state'
import setFiltersHandler from './set-filters.handler'

import ActionTypes from '../constants'
import { AnnexeState, AnnexeActions } from '../models'
import getAnnexeInfoHandler from './get-annexe-info.handler'

import annexeHandler from './annexe.handler'

/**
 *
 * Annexe main reducer
 *
 */

const AnnexeReducer = createReducer<AnnexeState, AnnexeActions>(
  AnnexeInitialState,
)
  .handleAction(
    [ActionTypes.SET_ANNEXE_FILTERS, ActionTypes.SET_LOADING],
    setFiltersHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_BY_ID_ANNEXE,
      ActionTypes.GET_ONE_BY_ID_ANNEXE_SUBSCRIBER,
      ActionTypes.GET_ALL_ANNEXES,
      ActionTypes.GET_ALL_ANNEXES_SUBSCRIBER,
      ActionTypes.SET_CURRENT_ANNEXE,
    ],
    getAnnexeInfoHandler,
  )
  .handleAction(
    [
      ActionTypes.CREATE_ANNEXE,
      ActionTypes.CREATE_ANNEXE_SUBSCRIBER,
      ActionTypes.GET_CREATE_ANNEXE,
      ActionTypes.GET_CREATE_ANNEXE_SUBSCRIBER,
      ActionTypes.GET_ONE_ANNEXE,
      ActionTypes.GET_ONE_ANNEXE_SUBSCRIBER,
      ActionTypes.UPDATE_ANNEXE,
      ActionTypes.UPDATE_ANNEXE_SUBSCRIBER,
      ActionTypes.SET_FORM_MODE,
      ActionTypes.SET_ANNEXE_PAGINATION,
    ],
    annexeHandler,
  )

export default AnnexeReducer
