export enum RoleType {
  SUPER_ADMIN = 0,
  CRM = 1,
  PLAYER_CARD = 2,
  WITHDRAWALS = 3,
  REPORTS = 5,
  ALERTS = 4,
  RULES = 6,
  USER_MANAGEMENT = 7,
  TAGS = 8,
  GAMIFICATION = 9,
  BONUS = 10,
  GAME_STORE = 11,
}

export default RoleType
