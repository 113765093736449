import { FrequencyCapState } from '../models'

export const currentEmptyFrequencyCap = {
  id: '',
  channel: 0,
  period: '',
  limit: 0,
}

export const initialState: FrequencyCapState = {
  hasError: false,
  isLoading: false,
  error: '',
  wasDeleted: false,
  isLoadingFull: false,
  frequenciesCap: [],
  currentFrequencyCap: {
    _id: '',
    channel: 0,
    period: 0,
    limit: 0,
    createdBy: '',
    companyId: '',
    createdAt: '',
    updatedAt: '',
  },
  dialog: {
    open: false,
    title: '',
    width: 'xs',
    content: null,
  },
}
