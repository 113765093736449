import EmailTemplateType from 'state/enums/email-template-type.enum'
import ActionTypes from '../constants'
import { TemplateActions, TemplateEmailState } from '../models'

const templateGetOneHandler = (
  state: TemplateEmailState,
  action: TemplateActions,
): TemplateEmailState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_EMAIL_REQUEST:
      return {
        ...state,
        isLoadingFull: true,
      }

    case ActionTypes.GET_ONE_EMAIL_SUCCESS:
      return {
        ...state,
        isLoadingFull: false,
        currentTemplate: {
          ...action.payload,
          creationMode: action.payload.creationMode || EmailTemplateType.CODE,
        },
      }

    case ActionTypes.GET_ONE_EMAIL_FAILURE:
      return {
        ...state,
        isLoadingFull: false,
      }

    default:
      return state
  }
}

export default templateGetOneHandler
