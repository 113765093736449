/**
 *
 * Auth actions
 *
 */

import { createAsyncAction, createAction } from 'typesafe-actions'
import SidebarId from 'state/enums/sidebar-id.enum'
import ActionTypes from './constants'

import { LoginPayload, LoginResponse } from '../user/models'
import { UserSession } from './models'

export const loginAction = createAsyncAction(
  ActionTypes.LOGIN_REQUEST,
  ActionTypes.LOGIN_SUCCESS,
  ActionTypes.LOGIN_FAILURE,
  ActionTypes.LOGIN_CANCEL,
)<LoginPayload, LoginResponse, Error | string, string>()

export const logoutAction = createAction(ActionTypes.LOGOUT_REQUEST)

export const removeTokenAction = createAction(ActionTypes.REMOVE_TOKEN)

export const saveTokenAction = createAction(ActionTypes.SAVE_TOKEN, action => {
  return (token: string) => action(token)
})

export const setUserSessionAction = createAction(
  ActionTypes.SET_SESSION,
  action => {
    return (userSession: UserSession | string | object | undefined) =>
      action(userSession)
  },
)

export const setLegacyTokenAction = createAction(
  ActionTypes.SET_LEGACY_TOKEN,
  action => {
    return (legacyToken: string | undefined) => action(legacyToken)
  },
)

export const removeLegacyTokenAction = createAction(
  ActionTypes.REMOVE_LEGACY_TOKEN,
)

export const setSidebarIdsAction = createAction(
  ActionTypes.SET_SIDEBAR_IDS,
  action => {
    return (sidebarIds: SidebarId[]) => action(sidebarIds)
  },
)
