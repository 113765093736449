import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { setLoadingFullAction } from 'state/ui/global/actions'
import { getAllCustomTriggerAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)
/**
 *
 * This saga gets all Custom Trigger
 *
 *
 * @param {ReturnType<typeof getAllCustomTriggerAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllCustomTriggerWorker(
  action: ReturnType<typeof getAllCustomTriggerAction.request>,
) {
  try {
    yield put(setLoadingFullAction(true))

    apiService.requestParams = {
      method: 'GET',
      endpoint: 'campaigns/triggers/get',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()

    const response = yield call(apiService.getData, auth.token)

    yield put(setLoadingFullAction(false))

    yield put(getAllCustomTriggerAction.success(response))
  } catch (error) {
    yield put(getAllCustomTriggerAction.failure(error))
  }
}

export default getAllCustomTriggerWorker
