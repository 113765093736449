import i18n from 'i18n'
import {
  REGEX_TEXTAREA,
  REGEX_CONSECUTIVE_WHITESPACE,
  REGEX_URL,
} from 'constants/regex/index.constant'
import { ValidationSchema } from 'hooks/form/models'

import { setRegexLength } from 'utils/text/index.utils'

const shortcutsValidator: ValidationSchema = {
  id: {
    required: false,
    validators: [],
  },
  name: {
    required: true,
    validators: [
      {
        regExp: REGEX_TEXTAREA,
        error: i18n.t('regexTextarea'),
      },
      {
        regExpMatch: REGEX_CONSECUTIVE_WHITESPACE,
        error: i18n.t('consecutiveWhitespace'),
      },
      {
        regExp: setRegexLength(50),
        error: i18n.t('messages.inputMaxLength', {
          field: i18n.t('name'),
          max: '50',
        }),
      },
    ],
  },
  url: {
    required: true,
    validators: [
      {
        regExp: REGEX_URL,
        error: i18n.t('regexURL'),
      },
    ],
  },
}

export default shortcutsValidator
