import { WalletModels } from '../models/index.model'
import ActionTypes from '../constants'

const updatePaymentsHandler = (
  state: WalletModels.State,
  action: WalletModels.SocketActions | WalletModels.Actions,
): WalletModels.State => {
  switch (action.type) {
    case ActionTypes.UPDATE_PAYMENT_FILE:
      return {
        ...state,
      }

    case ActionTypes.UPDATE_PAYMENT_FILE_SUBSCRIBER:
      return {
        ...state,
        resolved: action.payload?.resolved,
      }

    default:
      return state
  }
}

export default updatePaymentsHandler
