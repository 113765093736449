const missions = {
  missionsLabels: {
    searchName: 'Search Name',
    tags: 'Tags',
    rewards: 'Rewards',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    superHigh: 'Super High',
    categoryLow: 'Category Low',
    categoryMedium: 'Category Medium',
    categoryHigh: 'Category High',
    categorySuperHigh: 'Category Super High',
  },
  missionBonusOffer: {
    GAMIFICATION: 'Gamification',
    DEPOSIT: 'Deposit',
  },
  missionEvent: {
    COMPLETED_DEPOSIT: 'Completed Deposit',
    COMPLETED_WITHDRAWAL: 'Completed Withdrawal',
    WAGER: 'Wager',
    WIN: 'Win',
    REFER_FRIEND: 'Refer a friend',
    LOGIN: 'Login',
    ACTIVATE_ACCOUNT: 'Activate Account',
    ACCOUNT_VERIFICATION: 'Account Verification',
    SELF_ASSESSMENT_TEST: 'Self Assessment Test',
    OPT_IN: 'Opt In',
    LEVEL_UP: 'Level Up',
  },
  missionGodsonAccountStatus: {
    INITIAL: 'Initial',
    PROVISIONAL: 'Provisional',
    ACTIVE: 'Active',
    FULLY_IDENTIFIED: 'Fully Identified',
    BLOCKED_FULL: 'Blocked Full',
    BLOCKED_PARTIAL: 'Blocked Partial',
    CLOSED_PROVISIONAL: 'Closed Provisional',
    CLOSED_ACTIVE: 'Closed Active',
    INACTIVE: 'Inactive',
  },
  missionNextLevel: {
    ANY: 'Any',
    SPECIFIC_LEVEL: 'Specific Level',
  },
  missionReward: {
    BONUS_OFFER: 'Bonus Offer',
    VIRTUAL_CURRENCY: 'Tokens',
    LEVEL_BOOSTER: 'Level Booster',
    MISSION_BOOSTER: 'Mission Booster',
    UNKNOWN: 'Unknown',
  },
  missionTime: {
    PERIOD: 'Period',
    SPECIFIC_DATE: 'Specific Date',
    ANY_TIME: 'Any Time',
  },
  wagerMissionCondition: {
    QUANTITY: 'Quantity',
    TOTAL_BET_AMOUNT: 'Total Bet Amount',
  },
  winMissionCondition: {
    QUANTITY: 'Quantity',
    SUCCESSIVE_QUANTITY: 'Successive Quantity',
    TOTAL_WIN_AMOUNT: 'Total Win Amount',
  },
}

export default missions
