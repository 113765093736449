import RoleType from 'state/enums/role.enum'
import SidebarId from 'state/enums/sidebar-id.enum'

export const drawerOpenWidth = 280

export const drawerClosedWidth = 100

const SidebarIds: { [id: number]: { ids: SidebarId[] } } = {
  [RoleType.CRM]: {
    ids: [
      SidebarId.HOME,
      SidebarId.CRM,
      SidebarId.CAMPAIGNS,
      SidebarId.CAMPAIGNS_CREATE,
      SidebarId.CAMPAIGNS_LIBRARY,
      SidebarId.CAMPAIGNS_ANALYTICS,
      SidebarId.FREQUENCY_CAP,
      SidebarId.CAMPAIGNS_ARCHIVE,
      SidebarId.CUSTOM_TRIGGERS,
      SidebarId.CUSTOM_TRIGGERS_ARCHIVE,
      SidebarId.CUSTOM_TRIGGERS_CREATE,
      SidebarId.CUSTOM_TRIGGERS_LIBRARY,
      SidebarId.SEGMENTS,
      SidebarId.SEGMENTS_CREATE,
      SidebarId.SEGMENTS_LIBRARY,
      SidebarId.SEGMENTS_ARCHIVE,
      SidebarId.TEMPLATES,
      SidebarId.TEMPLATES_EMAIL,
      SidebarId.TEMPLATES_SMS,
      SidebarId.TEMPLATES_LIBRARY_EMAIL,
      SidebarId.TEMPLATES_LIBRARY_SMS,
      SidebarId.TEMPLATES_ARCHIVE_EMAIL,
      SidebarId.TEMPLATES_ARCHIVE_SMS,
      SidebarId.TEMPLATES_CREATE_EMAIL,
      SidebarId.TEMPLATES_CREATE_SMS,
      SidebarId.TEMPLATES_LIBRARY_ONSITE,
      SidebarId.MEDIA_DATABASE,
      SidebarId.CLIENT_SETTINGS,
    ],
  },
  [RoleType.PLAYER_CARD]: {
    ids: [SidebarId.HOME],
  },
  [RoleType.WITHDRAWALS]: {
    ids: [SidebarId.HOME, SidebarId.WITHDRAWALS],
  },
  [RoleType.REPORTS]: {
    ids: [
      SidebarId.HOME,
      SidebarId.REFER_FRIEND,
      SidebarId.GGR_REPORT,
      SidebarId.TELEDATA,
      SidebarId.REPORTS,
      SidebarId.TRANSACTIONS,
      SidebarId.VETO_INTERNAL_BLACKLIST,
      SidebarId.VETO_INTERNAL_BLACKLIST_AUDIT,
      SidebarId.BONUS_REPORT,
      SidebarId.PLAYER_REPORT,
    ],
  },
  [RoleType.ALERTS]: {
    ids: [SidebarId.HOME, SidebarId.ACCOUNT_STORE, SidebarId.ISSUES],
  },
  [RoleType.RULES]: {
    ids: [
      SidebarId.HOME,
      SidebarId.RULES_ENGINE,
      SidebarId.RULES_CREATE,
      SidebarId.RULES_ARCHIVE,
      SidebarId.RULES_LIBRARY,
    ],
  },
  [RoleType.TAGS]: {
    ids: [
      SidebarId.HOME,
      SidebarId.TAGS,
      SidebarId.PLAYER_TAGS,
      SidebarId.CRM_TAGS,
    ],
  },
  [RoleType.GAMIFICATION]: {
    ids: [
      SidebarId.HOME,
      SidebarId.GAMIFICATION,
      SidebarId.REWARD_SHOP_PRODUCTS,
      SidebarId.REWARD_SHOP_ITEMS,
      SidebarId.REWARD_SHOP,
      SidebarId.REWARD_SHOP_CREATE,
    ],
  },
  [RoleType.BONUS]: {
    ids: [
      SidebarId.HOME,
      SidebarId.BONUS_ENGINE,
      SidebarId.BONUS_OFFERS,
      SidebarId.BONUS_OFFERS_CREATE,
      SidebarId.BONUS_CAMPAIGNS,
      SidebarId.BONUS_CAMPAIGNS_CREATE,
    ],
  },
  [RoleType.USER_MANAGEMENT]: {
    ids: [
      SidebarId.HOME,
      SidebarId.CLIENT_SETTINGS,
      SidebarId.TEMPLATES,
      SidebarId.USER_MANAGEMENT,
      SidebarId.SETTINGS,
    ],
  },
  [RoleType.GAME_STORE]: {
    ids: [
      SidebarId.HOME,
      SidebarId.GAME_STORE,
      SidebarId.GAME_STORE_GAMES,
      SidebarId.GAME_STORE_PROVIDERS,

    ],
  },
  [RoleType.SUPER_ADMIN]: {
    ids: [
      SidebarId.HOME,
      SidebarId.SETTINGS,
      SidebarId.USER_MANAGEMENT,
      SidebarId.CRM,
      SidebarId.CAMPAIGNS,
      SidebarId.CAMPAIGNS_CREATE,
      SidebarId.CAMPAIGNS_LIBRARY,
      SidebarId.CAMPAIGNS_ANALYTICS,
      SidebarId.CAMPAIGNS_ARCHIVE,
      SidebarId.FREQUENCY_CAP,
      SidebarId.CUSTOM_TRIGGERS,
      SidebarId.CUSTOM_TRIGGERS_ARCHIVE,
      SidebarId.CUSTOM_TRIGGERS_CREATE,
      SidebarId.CUSTOM_TRIGGERS_LIBRARY,
      SidebarId.SEGMENTS,
      SidebarId.SEGMENTS_CREATE,
      SidebarId.SEGMENTS_LIBRARY,
      SidebarId.SEGMENTS_ARCHIVE,
      SidebarId.TEMPLATES,
      SidebarId.TEMPLATES_EMAIL,
      SidebarId.TEMPLATES_SMS,
      SidebarId.TEMPLATES_LIBRARY_EMAIL,
      SidebarId.TEMPLATES_LIBRARY_SMS,
      SidebarId.TEMPLATES_ARCHIVE_EMAIL,
      SidebarId.TEMPLATES_ARCHIVE_SMS,
      SidebarId.TEMPLATES_CREATE_EMAIL,
      SidebarId.TEMPLATES_CREATE_SMS,
      SidebarId.TEMPLATES_LIBRARY_ONSITE,
      SidebarId.MEDIA_DATABASE,
      SidebarId.CLIENT_SETTINGS,
      SidebarId.TAGS,
      SidebarId.PLAYER_TAGS,
      SidebarId.CRM_TAGS,
      SidebarId.ACCOUNT_STORE,
      SidebarId.WITHDRAWALS,
      SidebarId.TELEDATA,
      SidebarId.ISSUES,
      SidebarId.REPORTS,
      SidebarId.TRANSACTIONS,
      SidebarId.VETO_INTERNAL_BLACKLIST,
      SidebarId.VETO_INTERNAL_BLACKLIST_AUDIT,
      SidebarId.BONUS_ENGINE,
      SidebarId.BONUS_OFFERS,
      SidebarId.BONUS_OFFERS_CREATE,
      SidebarId.BONUS_CAMPAIGNS,
      SidebarId.BONUS_CAMPAIGNS_CREATE,
      SidebarId.BONUS_REPORT,
      SidebarId.PLAYER_REPORT,
      SidebarId.GAMIFICATION,
      SidebarId.REWARD_SHOP_PRODUCTS,
      SidebarId.REWARD_SHOP_ITEMS,
      SidebarId.REWARD_SHOP,
      SidebarId.REWARD_SHOP_CREATE,
      SidebarId.LEVELS,
      SidebarId.RULES_ENGINE,
      SidebarId.RULES_CREATE,
      SidebarId.RULES_ARCHIVE,
      SidebarId.RULES_LIBRARY,
      SidebarId.REFER_FRIEND,
      SidebarId.GGR_REPORT,
      SidebarId.GAME_STORE,
      SidebarId.GAME_STORE_GAMES,
      SidebarId.GAME_STORE_PROVIDERS,
      SidebarId.USER_LOGS,
    ],
  },
}

export default SidebarIds
