import ActionTypes from '../constants'
import { TemplateActions, TemplateState } from '../models'

const templateCreateHandler = (
  state: TemplateState,
  action: TemplateActions,
): TemplateState => {
  switch (action.type) {
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        currentTemplate: action.payload,
      }
    default:
      return state
  }
}

export default templateCreateHandler
