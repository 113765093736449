/* eslint-disable @typescript-eslint/camelcase */
import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { getOneAnalyticsAction } from '../actions/index.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a single analytic
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOneAnalyticsAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getOneAnalyticsWorker(
  action: ReturnType<typeof getOneAnalyticsAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'analytics/get/stats/general',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()

    const response = yield call(apiService.getData, auth.token)

    yield put(getOneAnalyticsAction.success(response))
  } catch (error) {
    yield put(getOneAnalyticsAction.failure(error))
  }
}

export default getOneAnalyticsWorker
