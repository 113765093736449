import TimeZoneType from 'state/enums/timezone.enum'
import RoleType from 'state/enums/role.enum'
import { UserState, UserResponse } from '../models'

export const initialUser: UserResponse = {
  _id: '',
  firstName: '',
  lastName: '',
  username: '',
  role: [RoleType.CRM],
  email: '',
  phoneNumber: '',
  companyId: '',
  timezone: TimeZoneType.GMT_0,
  passwordResetToken: '',
  isActive: true,
  failedLoginAttempts: 0,
  isDark: false,
  createdAt: '',
  updatedAt: '',
}

const initialState: UserState = {
  isLoading: false,
  hasError: false,
  error: '',
  wasDeleted: false,
  wasPasswordReset: false,
  currentUser: initialUser,
  loggedInUser: initialUser,
  userResetPasswordToken: initialUser,
  users: [],
  allUsers: [],
  operators: [],
  shortcuts: [],
  currentOperator: {
    _id: '',
    username: '',
    operatorId: 0,
  },
  pagination: {
    total: 0,
    limit: 25,
    page: 1,
    pages: 1,
  },
  filters: {
    page: 1,
    limit: 25,
  },
}

export default initialState
