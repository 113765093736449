import ActionTypes from '../constants'
import { CampaignActions, CampaignState } from '../models'

const campaignGetOneSegmentHandler = (
  state: CampaignState,
  action: CampaignActions,
): CampaignState => {
  switch (action.type) {
    case ActionTypes.GET_ONE_SEGMENT_REQUEST:
      return {
        ...state,
        isLoadingPartial: true,
      }

    case ActionTypes.GET_ONE_SEGMENT_SUCCESS:
      return {
        ...state,
        isLoadingPartial: false,
        currentSegment: action.payload,
      }

    case ActionTypes.GET_ONE_SEGMENT_FAILURE:
      return {
        ...state,
        isLoadingPartial: false,
        hasError: true,
        error: action.payload,
      }

    case ActionTypes.RESET_SEGMENT:
      return {
        ...state,
        currentSegment: null,
      }

    default:
      return state
  }
}

export default campaignGetOneSegmentHandler
