import TriggerType from 'state/enums/trigger.enum'
import ActionTypes from '../constants'
import { CustomTriggersState, CustomTriggersActions } from '../models'

const generalHandler = (
  state: CustomTriggersState,
  action: CustomTriggersActions,
): CustomTriggersState => {
  switch (action.type) {
    case ActionTypes.SET_CUSTOM_TRIGGER_FILTERS:
      return {
        ...state,
        filters: action.payload,
      }

    case ActionTypes.RESET_CUSTOM_TRIGGERS:
      return {
        ...state,
        customTriggers: [],
      }

    case ActionTypes.RESET_CURRENT_CUSTOM_TRIGGER:
      return {
        ...state,
        currentCustomTrigger: {
          createdAt: '',
          createdBy: {
            userId: '',
            username: '',
          },
          description: '',
          status: 0,
          name: '',
          trigger: TriggerType.RESET_PASS,
          rules: [],
          filterType: '',
          updatedAt: '',
          id: '',
        },
      }

    default:
      return state
  }
}

export default generalHandler
