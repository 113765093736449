const IssuesCodeFilters = {
  JP: {
    ACCUMULATED_DEPOSITS_100K: 'ACCUMULATED_DEPOSITS_100K',
  },
  PS: {
    CS_A_24H_W3_CANCEL: 'CS_A_24h_W*3_Cancel',
    CS_A_5H_PLAY: 'CS_A_5h_Play',
    CS_A_DSM5_4: 'CS_A_DSM5⩽4',
    CS_A_MONTH_X2COOLOFF: 'CS_A_Month_x2CoolOff',
    CS_A_MONTH_X2LIMITINCREASE: 'CS_A_Month_x2LimitIncrease',
    CS_B_AX10_MONTH: 'CS_B_Ax10_Month',
    CS_B_MONTH_X3COOLOFF: 'CS_B_Month_x3CoolOff',
    CS_B_MONTH_X3LIMITINCREASE: 'CS_B_Month_x3LimitIncrease',
    CS_B_MONTH_X3LIMITTIME: 'CS_B_Month_x3LimitTime',
    CS_C_DSM5_5: 'CS_C_DSM5⩾5',
    CS_C_DSM5_5CONFIRM: 'CS_C_DSM5⩾5confirm',
    LBA_ANSWERED: 'LBA_ANSWERED',
    LBA_DEP20000_30DAYS: 'LBA_DEP20000_30days',
    LBA_DEP4000_24H: 'LBA_DEP4000_24h',
    LBA_W4000_24H: 'LBA_W4000_24h',
    LBA_W50000_30DAYS: 'LBA_W50000_30days',
    NETLOSS_75_PERCENT_REACHED: 'NETLOSS_75_PERCENT_REACHED',
    ACCOUNT_ADMIN_DEP: 'Account_Admin_DEP',
    ACCOUNT_ADMIN_W: 'ACCOUNT_ADMIN_W',
    ACCOUNT_DW_LOWPLAY: 'Account_D&W_LowPlay',
    ACCOUNT_DW_NOPLAY: 'Account_D&W_NoPlay',
    ACCOUNT_LOWVOLUME: 'Account_LowVolume',
    DEP_PROXYUSED: 'DEP_ProxyUsed',
    DEP_REJECTED_DEPOSIT: 'DEP_Rejected_Deposit',
    DEPX3__100_10MINS: 'DEPx3_⩾100_10mins',
    DEPX3_24H: 'DEPx3_24h',
    DEPX3_CC: 'DEPx3_CC',
    HIGHROLLER250K_180DAYS: 'HighRoller250K_180days',
    CS_BLACKLIST_CRONJOB: 'CS_Blacklist_Cronjob',
    CS_BLACKLIST_EXCLUSION: 'CS_Blacklist_Exclusion',
    CS_BLACKLIST_LOGIN: 'CS_Blacklist_Login',
    CS_BLACKLIST_MANUAL: 'CS_Blacklist_Manual',
    CS_BLACKLIST_REGISTRATION: 'CS_Blacklist_Registration',
    CS_BLACKLIST_VETO: 'CS_Blacklist_VETO',
    CS_BLACKLIST_WHITELISTED: 'CS_Blacklist_Whitelisted',
    CS_MONTH_NETLOSS_1000: 'CS_Month_NetLoss_1000',
    CS_MONTH_NETLOSS_2500: 'CS_Month_NetLoss_2500',
    CS_MONTH_NETLOSS_500: 'CS_Month_NetLoss_500',
    CS_MONTH_X2LIMITTIME: 'CS_Month_x2LimitTime',
    CS_SELFEXCLUSION: 'CS_SelfExclusion',
  },
  HH: {
    LARGE_DEPOSIT: 'LARGE_DEPOSIT',
    LARGE_WITHDRAWAL: 'LARGE_WITHDRAWAL',
    CALLBACK_REQUEST: 'CALLBACK_REQUEST',
    RG_TEST_1_4: 'RG_TEST_1-4',
    RG_TEST_5_PLUS: 'RG_TEST_5+',
    PLAYING_5H_24H: 'PLAYING_5h_24h',
    PLAYING_7H_24H: 'PLAYING_7h_24h',
    THREE_X_CANCEL_24H: '3x_CANCEL_24h',
    TWO_X_COOLOFF_MONTH: '2x_COOLOFF_MONTH',
    THREE_X_COOLOFF_MONTH: '3x_COOLOFF_MONTH',
    THREE_X_CC_BLOCKED: '3x_CC_BLOCKED',
    DEP_X_3_CC: 'DEPx3_CC',
    NETLOSS_INCREASE_3X_1M: 'NETLOSS_INCREASE_3x_1M',
    PLAYTIME_LIM_INCREASED_3X_1M: 'PLAYTIME_LIM_INCREASED_3x_1M',
    DEPOSIT_AFTER_COOLOFF_1K_PLUS: 'DEPOSIT_AFTER_COOLOFF_1k+',
    HIGH_LIMIT_SET: 'HIGH_LIMIT_SET',
    SELFEXCLUSION: 'SELFEXCLUSION',
    BLACKLIST_CRONJOB: 'BLACKLIST_CRONJOB',
    BLACKLIST_EXCLUSION: 'BLACKLIST_EXCLUSION',
    BLACKLIST_LOGIN: 'BLACKLIST_LOGIN',
    BLACKLIST_MANUAL: 'BLACKLIST_MANUAL',
    BLACKLIST_REGISTRATION: 'BLACKLIST_REGISTRATION',
    BLACKLIST_VETO: 'BLACKLIST_VETO',
    BLACKLIST_WHITELISTED: 'BLACKLIST_WHITELISTED',
    SINGLE_WIN_1M: 'SINGLE_WIN_1M',
    MAX_BALANCE: 'MAX_BALANCE',
  },
}

export default IssuesCodeFilters
