import { StateSchema } from 'hooks/form/models'

const shortcutsSchema: StateSchema = {
  id: {
    value: '',
    error: '',
  },
  name: {
    value: '',
    error: '',
  },
  url: {
    value: '',
    error: '',
  },
}

export default shortcutsSchema
