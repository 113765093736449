enum IssueTypes {
  ALL,
  RISK = 100,
  RETAIL,
  FRAUD_ALERTS = 110,
  DEPOSITS_BELOW_100,
  TOTAL_DEPOSIT_W_10_DAYS,
  WIN_RATIO_BETS,
  IP_NOT_REGISTRATION,
  IP_NOT_EU,
  IP_EU_NOTS,
  PROXY_FOR_DEPOSIT,
  LOW_GAMBLING_ACTIVITY,
  TOTAL_WITHDRAWAL_10_DAYS,
  UNUSUAL_BONUS,
  OTHER_1 = 199,
  RESPONSIBLE_GAMING = 200,
  CALLBACK_REQUEST,
  PERMANENT_EXCLUSION,
  SELF_ASSESSMENT,
  COOLOFFS_CHANGED,
  LIMITS_CHANGED,
  BLACKLIST_VETO = 210,
  BLACKLIST_REGISTRATION,
  BLACKLIST_EXCLUSION,
  BLACKLIST_LOGIN,
  BLACKLIST_CRON_JOB,
  BLACKLIST_MANUAL,
  BLACKLIST_WHITELISTED,
  DASHBOARD_BACKEND = 250,
  DEPOSIT_METHODS,
  DEPOSIT_COOLOFF,
  CANCELLED_WITHDRAWALS,
  GAMING_TIME,
  NET_LOSS_PER_HOUR,
  NET_LOSS_THIRTY_DAYS,
  NET_LOSS_TWELVE_MONTHS,
  INITIATED_COOLOFFS,
  INCREASED_NET_LOSS,
  OTHER_2 = 299,
  KYC = 300,
  UPLOAD_DOCUMENT,
  UPLOAD_UTILITY,
  VERIFIED_DOCUMENT,
  VERIFIED_UTILITY,
  VERIFIED_EMAIL,
  CHANGED_EMAIL,
  VERIFIED_ADDRESS,
  CHANGED_PHONE_NUMBER,
  CHANGED_BANKING_DETAILS,
  SAME_PLAYER,
  CHANGED_NATIONALITY,
  CHANGED_TEST_ACCOUNT,
  MANUAL_UPLOAD_DOCUMENT,
  MANUAL_UPLOAD_UTILITY,
  VERIFIED_MOBILE,
  CHANGED_USERNAME,
  REGISTRATION_INFORMATION,
  TEMPORARY_ACCOUNT = 320,
  PLAYER_ACTIVITY = 330,
  MONEY_STUFF = 340,
  LARGE_DEPOSIT,
  LARGE_WITHDRAWAL,
  MAXIMUM_BALANCE,
  MANUAL_WITHDRAWAL,
  MANUAL_DEPOSIT,
  ACCOUNT_STATUS_CHANGE = 350,
  ACCOUNT_CLOSED = 360,
  ACCOUNT_CLOSED_PROVISIONAL,
  ACCOUNT_CLOSED_ACTIVE,
  CRIF = 369,
  CRIF_CHECK_MISMATCH,
  CRIF_CHECK_LOGIN,
  CRIF_CHECK_REGISTRATION,
  CRIF_ADDRESS_CHANGED_PLAYER,
  CRIF_ADDRESS_CHANGED_OPERATOR,
  PEP_CHECK_LOGIN,
  PEP_CHECK_REGISTRATION,
  PEP_CHECK_NAME_CHANGED,
  PEP_CHECK_BENEFICIARY_NAME,
  PEP_CHANGED_OPERATOR,
  BALLY = 380,
  BALLY_REGISTRATION_SUCCESS,
  BALLY_REGISTRATION_FAILED,
  BANK_ACCOUNT,
  OTHER_3 = 399,
  GAMIFICATION = 400,
  BONUSES = 410,
  BONUS_ADD,
  BONUS_ACTIVATE,
  BONUS_CANCEL,
  BONUS_CANCEL_ALL,
  BONUS_ADD_MANUAL,
  BONUS_ADD_MANUAL_BONUS_CODE,
  BONUS_ACTIVATE_MANUAL,
  BONUS_CANCEL_MANUAL,
  BONUS_CANCEL_ALL_MANUAL,
  OTHER_4 = 499,
}

export default IssueTypes
