import ActionTypes from '../constants'
import { TagActions, TagState } from '../models'

const getAllTagsByCategoryHandler = (
  state: TagState,
  action: TagActions,
): TagState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TAGS_BY_CATEGORY:
      return { ...state }

    case ActionTypes.GET_ALL_TAGS_BY_CATEGORY_SUBSCRIBER:
      return {
        ...state,
        tagNames: action.payload.tags || [],
      }

    default:
      return state
  }
}

export default getAllTagsByCategoryHandler
