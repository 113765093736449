import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import jsonToQueryString from 'utils/query-string'
import { getAllUserAction } from '../actions/rest.actions'
import { UsersResponse } from '../models'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a list of  users
 *
 * The following filter values are available:
 *
 * * Via searchParam query key
 *
 * @filter firstName
 * @filter lastName
 * @filter username
 * @filter email
 * @filter companyId
 *
 * * Default pagination:
 *
 *  . limit 17
 *  . page  1
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getAllUserAction.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllUserWorker(
  action: ReturnType<typeof getAllUserAction.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'users/getUsersByFilter',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response: UsersResponse = yield call(apiService.getData, auth.token)

    yield put(getAllUserAction.success(response))
  } catch (error) {
    yield put(getAllUserAction.failure(error))
  }
}

export default getAllUserWorker
