const rules = {
  INDEX: '/rules',
  CREATE: '/rules/create',
  EDIT: '/rules/edit',
  ARCHIVE: '/rules/archive',
  ANALYTICS: '/rules/analytics',
  ANALYTICS_DETAIL: '/rules/analytics/:id',
  ANALYTICS_ACTIVITY: '/rules/activity',
}

export default rules
