import { createReducer } from 'typesafe-actions'
import { LevelsState, LevelDefinitionActions } from '../models'
import ActionTypes from '../constants'

import initialState from './initial-state'
import getLevelsDefinitionHandler from './get-all-level-definition.handler'
import levelsGeneralHandler from './general.handler'

import getOneLevelDefinitionHandler from './get-one-level-definition.handler'
import createLevelDefinitionHandler from './create.handler'
import updateLevelDefinitionHandler from './update.handler'

import deleteLevelDefinitionHandler from './delete.handler'

const levelDefinitionReducer = createReducer<
  LevelsState,
  LevelDefinitionActions
>(initialState)
  .handleAction(
    [
      ActionTypes.GET_ALL_LEVEL_DEFINITION,
      ActionTypes.GET_ALL_LEVEL_DEFINITION_SUBSCRIBER,
      ActionTypes.GET_ALL_LEVEL_DEFINITION_EXCEPTION_SUBSCRIBER,
    ],
    getLevelsDefinitionHandler,
  )
  .handleAction(
    [
      ActionTypes.GET_ONE_LEVEL_DEFINITION,
      ActionTypes.GET_ONE_LEVEL_DEFINITION_SUBSCRIBER,
    ],
    getOneLevelDefinitionHandler,
  )
  .handleAction(
    [ActionTypes.CREATE, ActionTypes.CREATE_SUBSCRIBER],
    createLevelDefinitionHandler,
  )
  .handleAction(
    [ActionTypes.UPDATE, ActionTypes.UPDATE_SUBSCRIBER],
    updateLevelDefinitionHandler,
  )
  .handleAction(
    [ActionTypes.DELETE, ActionTypes.DELETE_SUBSCRIBER],
    deleteLevelDefinitionHandler,
  )
  .handleAction([ActionTypes.SET_FILTERS], levelsGeneralHandler)

export default levelDefinitionReducer
