const customTrigger = {
  customTriggerItem: 'custom trigger',
  customTriggerDetailsText:
    'Add the name, label and description with which you want to save your custom trigger.',
  customTriggerSettings: 'Custom Trigger Settings',
  createCustomTrigger: 'Create Custom Trigger',
  createACustomTrigger: 'Create a Custom Trigger',
  createACustomTriggerText: 'Start by filling in the information in step 1.',
  triggerName: 'Trigger Name',
  segmentationRulesText: 'If it matches these additional criteria',
  goToCustomTriggers: 'Go to Custom Triggers',
  customTriggerRules: 'Trigger Rules',
  customTriggerRulesText: 'When the event contains the following criteria',
  noCustomTriggerAdded: 'No Custom Trigger Added',
  changeEventCustomTriggerCaption:
    'Doing so will clear the Custom Trigger Rules already added.',
  changeEventCustomTriggerDisclaimer:
    'Are you sure you want to change the Event Trigger?',
  editCustomTrigger: 'Edit Custom Trigger',
  isToPlayer: 'The Player',
  customTriggerArchive: 'Custom Trigger Archive',
  customTriggerSearchStatus: {
    ALL: 'All',
    INDESING: 'In Design',
    ACTIVE: 'Active',
  },
}

export default customTrigger
