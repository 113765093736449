enum TransactionCompanyCard {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  VISA_2 = 'Visa',
  MASTERCARD_2 = 'MasterCard',
  AMEX = 'Amex',
  UATP = 'UATP',
  DINERS = 'Diners',
  MAESTRO = 'Maestro',
  SWITCH = 'SWITCH',
  DISCOVER = 'Discover',
  MIR = 'Mir',
  ISRAELICARD = 'IsraeliCard',
}

export default TransactionCompanyCard
