import ActionTypes from '../constants'
import { LogActions, LogState } from '../models'

const limitLogHandler = (state: LogState, action: LogActions): LogState => {
  switch (action.type) {
    case ActionTypes.GET_LIMIT_LOGS:
      return {
        ...state,
        isLoadingLimit: true,
      }

    case ActionTypes.GET_LIMIT_LOGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingLimit: false,
        limits:
          typeof action.payload.message === 'string'
            ? []
            : action.payload.message,
      }

    default:
      return state
  }
}

export default limitLogHandler
