import { select, call, put } from 'redux-saga/effects'
import Container from 'typedi'
import ApiService from 'services/api/index.service'

import i18n from 'i18n'
import jsonToQueryString from 'utils/query-string'
import { setSnackbarAction } from 'state/ui/global/actions'

import { getOperators } from '../actions/rest.actions'

/**
 * @instance ApiService
 */
const apiService: ApiService = Container.get(ApiService)

/**
 *
 * This saga gets a list of  operators
 *
 * * Default pagination:
 *
 *  . limit 25
 *  . page  1
 *
 *
 * @requires ApiService
 * @param {ReturnType<typeof getOperators.request>} action
 * @export
 * @throws Will throw an error if the request is not successful.
 */
function* getAllOperatorsWorker(
  action: ReturnType<typeof getOperators.request>,
) {
  try {
    apiService.requestParams = {
      method: 'GET',
      endpoint: 'users/getOperators',
      queries: jsonToQueryString(action.payload),
    }

    const { auth } = yield select()
    const response = yield call(apiService.getData, auth.token)

    if (response.error) {
      yield put(getOperators.failure(response.error))
      yield put(
        setSnackbarAction({
          message: i18n.t('messages.errorOccurred'),
          open: true,
          variant: 'error',
        }),
      )
    }

    yield put(getOperators.success(response))
  } catch (error) {
    yield put(getOperators.failure(error))
  }
}

export default getAllOperatorsWorker
