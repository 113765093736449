import ActionTypes from '../constants'
import { TagActions, TagState } from '../models'

const getAllTagsHandler = (state: TagState, action: TagActions): TagState => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TAGS:
      return {
        ...state,
        isLoadingTags: true,
      }

    case ActionTypes.GET_ALL_TAGS_SUBSCRIBER:
      return {
        ...state,
        isLoadingTags: false,
        tags: action.payload?.docs || [],
        pagination: {
          total: action.payload.total,
          limit: action.payload.limit,
          page: action.payload.page,
          pages: action.payload.pages,
        },
      }

    default:
      return state
  }
}

export default getAllTagsHandler
