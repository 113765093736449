import WS_NAMESPACES from 'state/websocket.namespaces'
import WS_ROUTES from 'state/websocket.routes'
import { createAction, createCustomAction } from 'typesafe-actions'

import ActionTypes from '../constants'
import * as PlayerCardKYCModels from '../models'

export const setPlayerBankAccountInfo = createAction(
  ActionTypes.SET_PLAYER_BANK_ACCOUNT_INFO,
  action => (data: PlayerCardKYCModels.PlayerIbans) => action(data),
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET PLAYER IBANS
 *
 */
export const getPlayerIbans = createCustomAction(
  ActionTypes.GET_PLAYER_IBANS,
  type => (
    payload: PlayerCardKYCModels.GetPlayerIbansPayload,
    event: typeof WS_ROUTES.KYC.GET_PLAYER_IBANS,
    handler?: (data: PlayerCardKYCModels.GetPlayerIbansResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getPlayerIbansSubscribe = createAction(
  ActionTypes.GET_PLAYER_IBANS_SUBSCRIBER,
  action => {
    return (response: PlayerCardKYCModels.GetPlayerIbansResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] GET IBAN DOCUMENT
 *
 */
export const getPlayerIbanDocument = createCustomAction(
  ActionTypes.GET_PLAYER_IBAN_DOCUMENT,
  type => (
    payload: PlayerCardKYCModels.GetPlayerIbanDocumentPayload,
    event: typeof WS_ROUTES.KYC.GET_PLAYER_IBAN_DOCUMENT,
    handler?: (data: PlayerCardKYCModels.GetPlayerIbanDocumentResponse) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const getPlayerIbanDocumentSubscribe = createAction(
  ActionTypes.GET_PLAYER_IBAN_DOCUMENT_SUBSCRIBER,
  action => {
    return (response: PlayerCardKYCModels.GetPlayerIbanDocumentResponse) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] REJECT PLAYER IBAN
 *
 */
export const rejectPlayerIban = createCustomAction(
  ActionTypes.SET_IBAN_REJECTED,
  type => (
    payload: PlayerCardKYCModels.UpdatePlayerIbanPayload,
    event: typeof WS_ROUTES.KYC.SET_IBAN_REJECTED,
    handler?: (
      data: PlayerCardKYCModels.UpdatePlayerIbanResponse,
    ) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const rejectPlayerIbanSubscribe = createAction(
  ActionTypes.SET_IBAN_REJECTED_SUBSCRIBER,
  action => {
    return (
      response: PlayerCardKYCModels.UpdatePlayerIbanResponse,
    ) => {
      return action(response)
    }
  },
)

/**
 * @description
 * Listen for the socket:
 *
 * * [EVENT] VERIFY PLAYER IBAN
 *
 */
export const verifyPlayerIban = createCustomAction(
  ActionTypes.SET_IBAN_VERIFIED,
  type => (
    payload: PlayerCardKYCModels.UpdatePlayerIbanPayload,
    event: typeof WS_ROUTES.KYC.SET_IBAN_VERIFIED,
    handler?: (
      data: PlayerCardKYCModels.UpdatePlayerIbanResponse,
    ) => void,
  ) => ({
    type,
    event,
    emit: true,
    meta: WS_NAMESPACES.ADMIN_LEGACY,
    payload,
    handler,
  }),
)

export const verifyPlayerIbanSubscribe = createAction(
  ActionTypes.SET_IBAN_VERIFIED_SUBSCRIBER,
  action => {
    return (
      response: PlayerCardKYCModels.UpdatePlayerIbanResponse,
    ) => {
      return action(response)
    }
  },
)
